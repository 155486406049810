import {Component, inject, OnInit} from '@angular/core';
import {SearchAllService} from "../../../services/search-all/search-all.service";
import {UserInfoService} from "../../../services/user-info/user-info.service";
import {ChatService} from "../../../services/chat/chat.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-right-bar',
  templateUrl: './right-bar.component.html',
  styleUrls: ['./right-bar.component.scss']
})
export class RightBarComponent implements OnInit {
  searchAllService = inject(SearchAllService);
  userInforService = inject(UserInfoService);
  chatService = inject(ChatService);
  router = inject(Router);
  users: any = [];
  ngOnInit() {
    this.getUsers();
  }

  async getUsers() {
    let filters: any = {
      limit: 10,
      offset: 0,
    }
    filters['friends'] = 1;
    let users = await this.searchAllService.getUsers(filters).toPromise() as any;
    delete filters['friends'];
    filters['following'] = 1;
    let usersF = await this.searchAllService.getUsers(filters).toPromise() as any;

// Concatenate the users and usersF arrays
    let combinedUsers = users.concat(usersF);

// Remove duplicates using reduce operation
    let uniqueUsers = combinedUsers.reduce((acc: any, current: any) => {
      const x = acc.find((item: any) => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

// Limit the array to 5 items
    this.users = uniqueUsers.slice(0, 5);
  }
  async openChat(user: any) {
    let userFound = await this.userInforService.getById(user.id)
    this.chatService.changeUser(userFound)
    this.router.navigateByUrl('/messages')
  }
  getName(user: any) {
    return this.userInforService.getNameByUser(user)
  }
}
