<div *ngIf="address">
  <div class="flex items-center justify-between">
    <p class="text-[#000] text-[17px] font-Montserrat font-bold my-4" [ngClass]="[classTitle]">{{ title }}</p>
    <p *ngIf="viewEditAddress && address.id=='' && useAddressVerified"
       class="text-[#519935] text-[10px] font-Montserrat cursor-pointer" (click)="editAddress()">Edit address</p>
    <p *ngIf="viewEditAddress && address.id==' ' && useAddressVerified"
       class="text-[#519935] text-[10px] font-Montserrat cursor-pointer" (click)="useVerifyAddress()">Use verified
      Address</p>
  </div>
  <div *ngIf="!viewEditForm"
       class="min-h-[58px] bg-[#F8F8F8] border border-[#519935] flex items-center justify-between py-[11px] px-[11px] sm:bg-white">
    <div>
      <p class="text-[#519935] font-Montserrat font-bold text-[13px]">
        {{ address.address }}
      </p>
      <p
        class="text-[#262628] font-Montserrat text-[15px]">{{ address.isVerified ? 'Verified Address' : '' }}</p>
    </div>
    <span *ngIf="address.isVerified"
          class="material-symbols-outlined flex items-center justify-center bg-[#87D255] w-[24px] h-[24px] text-white rounded-full">check</span>
  </div>
  <div *ngIf="viewEditForm">
    <div class="relative pt-[10px] mt-[14px] sm:mt-[10px]" [class]="classField">
      <div class="flex  w-full items-center gap-4">
        <div class="relative w-full">
          <textarea placeholder="Address"
                    class="w-full mx-auto border focus:outline-none border-[rgba(15,13,35,0.2)] focus:border-[#519935] focus:outline-0 rounded-[12px] h-[145px] p-[24px]"
                    [(ngModel)]="address.address"
                    (click)="openEditAddress(false)"
                    [class]="{
           '!border-red-400' : enableRequiredInput && address.address.trim()==''
        }"
          ></textarea>
          <span (click)="openEditAddress()"
                class="material-symbols-outlined
                cursor-pointer
                absolute
                top-[10px]
                right-[10px]
                bg-green-700
                text-white
                font-bold
                p-[5px]
                text-[16px]
                rounded-full
            ">pin_drop</span>
        </div>
      </div>
      <label class="absolute left-4 top-[-1px] text-[#AAAAAA] text-[14px] font-Montserrat px-[4px] z-[15]"
             [class]="{
           '!text-red-500' : enableRequiredInput && address.address.trim()==''
           }"
      >
        Address
      </label>
      <hr class="border border-white absolute top-[10px] w-[70px] left-3 z-10">
    </div>

  </div>
  <app-dialog #addressDialog title="Delivery Address"
              [classHeaderDefault]="'justify-center pt-5 pb-3 text-lg'"
              [classModal]="('sm:!min-w-[500px] sm:!w-[500px]')+' bg-black/80 sm:bg-white text-white sm:text-black sm:relative pt-[var(--sat)]'"
              classBody="">
    <app-maps-locations [locationDefault]="location" (accept)="setAccept($event)" *ngIf="openMap"></app-maps-locations>
  </app-dialog>
</div>
