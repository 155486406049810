<div class="reset-password-container om-container">
  <div class="arrow-container hidden sm:flex absolute sm:bg-arrow w-16 h-16 flex items-center justify-center mt-0
            sm:mt-[calc(var(--w-height)*9)] sm:ml-[calc(var(--w-width)*4.5)]">
    <ng-icon name="matArrowBack" class="mx-4 mt-[calc((var(--w-height)*2)+var(--sat))] sm:mt-0 absolute
        text-white cursor-pointer text-4xl z-10 bg-none sm:text-5xl sm:rounded-full" (click)="previousStep()"></ng-icon>
  </div>
  <div class=" md:flex h-full">
    <div class="w-1/2 hidden md:flex  items-center h-full overflow-hidden	">
      <img src="assets/home/home-desktop.png" alt="background" class="h-full w-auto">
      <img alt="logo" src="assets/logo.svg" class="w-[15vw] md:ml-[-10vw] md:mt-[1vh]">
    </div>
    <div class="w-full sm:w-1/2 md:flex justify-end h-full">
      <app-reset-your-password *ngIf="step == 1" [emailDefault]="data.email" (sendData)="receiveData($event)"></app-reset-your-password>
      <app-otp-verification *ngIf="step == 2" title="OTP code Verification"
                            [dataDefault]="{
                              email:data.email,
                              username:'forgot-password'
                            }" (sendData)="receiveData($event)" (previousStep)="previousStep()"></app-otp-verification>
      <app-new-password *ngIf="step == 3" [data]="data" (sendData)="receiveData($event)"></app-new-password>
    </div>
  </div>
</div>
