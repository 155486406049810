import {Component, ElementRef, HostListener, inject, Input, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute} from "@angular/router";
import {UserInfoService} from "../../../../services/user-info/user-info.service";
import {SearchAllService} from "../../../../services/search-all/search-all.service";
import {CatalogsService} from "../../../../services/catalogs/catalogs.service";

@Component({
  selector: 'app-sharables',
  templateUrl: './sharables.component.html',
  styleUrls: ['./sharables.component.scss']
})
export class SharablesComponent implements OnInit {
  private http = inject(HttpClient);
  private catalogsService = inject(CatalogsService);
  private searchAllService = inject(SearchAllService);
  @ViewChild('filterList') filterList: ElementRef | undefined;
  @ViewChild('containerFilterList') containerFilterList: ElementRef | undefined;
  showFilters = false
  showList = false
  sharables = [];
  selected = 'All'
  @Input() userId = ''
  search = ''
  filterTab = 'sort'
  filterType = 'Relevance'
  filters: { [key: string]: string } = {
    sort: 'relevance',
    category: 'flower',
    type: 'all',
  }
  id:string = '';
  sorts: { label: string, value: string }[] = [
    {label: 'Relevance', value: 'relevance'},
    {label: 'Top Sales', value: 'top-sales'},
    {label: 'Most Recent', value: 'most-recent'},
    {label: 'Price Low to High', value: 'Price-Low-to-High'},
    {label: 'Price High to Low', value: 'Price-High-to-Low'},
  ]
  categories: { label: string, value: string }[] = [{label: 'All Category', value: 'all'},]
  types: { label: string, value: string }[] = [{label: 'All Types', value: 'all'},]
  limit = 10;
  offset = 0;
  username:string = '';

  ngOnInit() {
    window.scrollTo(0, 1);
    this.id = this.route.snapshot.params['username'];
    this.username = this.route.snapshot.params['username'];
    this.getCategories();
    this.getTypes();
    this.getSharables()
  }

  getTypes() {
    this.catalogsService.getAll('product-type', '').subscribe({
      next: (response: any) => {
        this.types = [...this.types, ...response.records.map((x: any) => {
          return {
            value: x.name.toLowerCase(),
            label: x.name,
          };
        })]
      }
    })
  }

  getCategories() {
    this.catalogsService.getAll('categories', '').subscribe({
      next: (response: any) => {
        this.categories = [...this.categories, ...response.records.map((x: any) => {
          return {
            value: x.name.toLowerCase(),
            label: x.name,
          };
        })]
      }
    })
  }

  constructor(private elementRef: ElementRef, private route:ActivatedRoute,private userInfo: UserInfoService) {
  }
  isMe(){
    return this.userInfo.isMe(this.id)
  }

  async getSharables() {
    this.sharables = [];
    this.sharables = await this.searchAllService.getProducts({
      limit: this.limit,
      offset: this.offset,
      in: 'og',
      user: this.userId,
      ...(this.search ? {
        name: this.search,
        description: this.search,
      } : {}),
        ...(this.filters['category'] !== 'all' ? {
          category: this.filters['category']
        } : {}),
        ...(this.filters['type'] !== 'all' ? {
          type: this.filters['type']
        } : {}),
      isOwn: true
    }).toPromise() as any;
  }

  getCatalogFilter() {
    let catalog: typeof this.categories = [];
    switch (this.filterTab) {
      case 'sort':
        catalog = this.sorts;
        break;
      case 'category':
        catalog = this.categories;
        break;
      case 'type':
        catalog = this.types;
        break;
    }
    return catalog;
  }

  setSort(key: string) {
    this.filterTab = key;
  }

  setFilterType(key: string) {
    this.filters[this.filterTab] = key;
    this.getSharables();
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!(this.filterList as ElementRef).nativeElement.contains(event.target) && this.showFilters) {
      this.showFilters = false;
    }
  }
}
