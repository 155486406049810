
<div class="post-full-image relative">
  <img [src]="medios[0].url">
  <div class="absolute bottom-4 right-3 sm:right-5 sm:bottom-3">
    <img [src]="avatar" class="avatar outline outline-white outline-2">
    <span *ngIf="verifiedAccount" class="material-symbols-outlined verifiedAccount absolute outline outline-white outline-2 right-0 bottom-0">
        Done
        </span>
  </div>
</div>
<div class="p-4 pt-3 pb-0">
  <p class="text-green-native mt-1 sm:mt-0">
    <span *ngFor="let hashtag of hashtags;">
      #{{hashtag}}
    </span>
  </p>
  <p class="font-SF-Pro-Text text-lg font-semibold sm:font-Poppins text-black my-1 title-post">{{title}}</p>
  <p class="font-SF-Pro-Text sm:font-Poppins text-[#77838F] sm:text-black description-post">{{description}}</p>
  <hr class="hidden sm:block my-2  border-[#EEEEEE]">
  <app-reactions-bar
    class="block py-3 sm:py-0"
    [seeLabelsReactions]="seeLabelsReactions"
    [numberReactions]="{
      numberLikes:10,
      numberComments:110,
      numberShares:1,
      numberSaved:5,
  }" ></app-reactions-bar>
  <app-liked-bar class="block sm:hidden"></app-liked-bar>
</div>
