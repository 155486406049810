import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {CatalogsService} from "../../../../services/catalogs/catalogs.service";
import {debounceTime, Subject} from "rxjs";

@Component({
  selector: 'app-filters-products',
  templateUrl: './filters-products.component.html',
  styleUrls: ['./filters-products.component.scss']
})
export class FiltersProductsComponent implements OnInit {
  [key: string]: any;

  updateFiltersSubject = new Subject<void>();
  @Output() filters = new EventEmitter<any>();
  @Input() filtersDefault: Partial<IFiltersGifts> = {};
  catalogsService = inject(CatalogsService)
  priceInit = 0;
  priceEnd = 0;
  thcInit: number = 0;
  thcEnd: number = 100;
  sorts: { label: string, value: string }[] = [
    {label: 'Relevance', value: 'relevance'},
    {label: 'Top Sales', value: 'top-sales'},
    {label: 'Most Recent', value: 'most-recent'},
    {label: 'Price Low to High', value: 'Price-Low-to-High'},
    {label: 'Price High to Low', value: 'Price-High-to-Low'},
  ]
  categories: { label: string, value: string, selected: boolean }[] = []
  effects: { label: string, value: string, selected: boolean }[] = []
  terpenes: { label: string, value: string, selected: boolean }[] = []
  types: { label: string, value: string, selected: boolean }[] = []
  typesGrowers: { label: string, value: string, selected: boolean }[] = [
    {label: 'Local', value: 'local', selected: false},
    {label: 'Verified', value: 'verified', selected: false},
    {label: 'Featured', value: 'featured', selected: false},
    {label: 'Trending', value: 'trending', selected: false},
    {label: 'New', value: 'new', selected: false},
  ]
  popular: { label: string, value: string, selected: boolean }[] = [
    {label: 'Trending', value: 'Trending', selected: false},
    {label: 'Most popular', value: 'Most popular', selected: false},
    {label: 'Recently added', value: 'Recently added', selected: false},
  ]
  ranked: { label: string, value: number, selected: boolean }[] = [
    {label: '', value: 1, selected: false},
    {label: '', value: 2, selected: false},
    {label: '', value: 3, selected: false},
    {label: '', value: 4, selected: false},
    {label: '', value: 5, selected: false},
  ]

  setFilters(filters: Partial<IFiltersGifts>) {
    Object.entries(filters).forEach(([key, value]) => {
      if (this.hasOwnProperty(key)) {
        if (Array.isArray(value)) {
          value.forEach(filterValue => {
            const filterIndex = this[key].findIndex((filter: any) => filter.value === filterValue);
            if (filterIndex !== -1) {
              this[key][filterIndex].selected = true;
            }
          });
        } else {
          this[key] = value;
        }
      }
    });
  }

  updateFilters() {
    this.filters.emit({
      priceInit: this.priceInit,
      priceEnd: this.priceEnd,
      thcInit: this.thcInit,
      thcEnd: this.thcEnd,
      categories: this.categories.filter(c => c.selected).map(c => c.value),
      effects: this.effects.filter(e => e.selected).map(e => e.value),
      terpenes: this.terpenes.filter(t => t.selected).map(t => t.value),
      types: this.types.filter(t => t.selected).map(t => t.value),
      typesGrowers: this.typesGrowers.filter(t => t.selected).map(t => t.value),
      popular: this.popular.filter(p => p.selected).map(p => p.value),
      ranked: this.ranked.filter(p => p.selected).map(p => p.value),
    } as Partial<IFiltersGifts>);
  }

  async getCategories() {
    try {
      const response: any = await this.catalogsService.getAll('categories', '').toPromise();
      this.categories = [
        ...this.categories,
        ...response.records.map((x: any) => {
          return {
            value: x.name.toLowerCase(),
            label: x.name,
            selected: false,
          };
        }),
      ];
    } catch (error) {
      console.error(error);
    }
  }

  async getEffects() {
    try {
      const response: any = await this.catalogsService.getAll('effects', '').toPromise();

      this.effects = [
        ...this.effects,
        ...response.records.map((x: any) => {
          return {
            value: x.name.toLowerCase(),
            label: x.name,
            selected: false,
          };
        }),
      ];
    } catch (error) {
      console.error(error);
    }
  }

  async getTerpenes() {
    try {
      const response: any = await this.catalogsService.getAll('terpenes', '').toPromise();
      this.terpenes = [
        ...this.terpenes,
        ...response.records.map((x: any) => {
          return {
            value: x.name.toLowerCase(),
            label: x.name,
            selected: false,
          };
        }),
      ];
    } catch (error) {
      console.error(error);
    }
  }

  async getTypes() {
    try {
      const response: any = await this.catalogsService.getAll('product-type', '').toPromise();

      this.types = [
        ...this.types,
        ...response.records.map((x: any) => {
          return {
            value: x.name.toLowerCase(),
            label: x.name,
            selected: false,
          };
        }),
      ];
    } catch (error) {
      console.error(error);
    }
  }

  async ngOnInit(): Promise<void> {
    await this.getCategories()
    await this.getEffects();
    await this.getTerpenes();
    await this.getTypes();
    this.updateFiltersSubject.pipe(debounceTime(500)).subscribe(() => this.updateFilters());
    this.setFilters(this.filtersDefault)
  }

  resetFilters() {
    this.priceInit = 0;
    this.priceEnd = 500;
    this.thcInit = 0;
    this.thcEnd = 100;
    for (const key in this.categories) this.categories[key].selected = false;
    for (const key in this.effects) this.effects[key].selected = false;
    for (const key in this.terpenes) this.terpenes[key].selected = false;
    for (const key in this.types) this.types[key].selected = false;
    for (const key in this.typesGrowers) this.typesGrowers[key].selected = false;
    for (const key in this.popular) this.popular[key].selected = false;
    for (const key in this.ranked) this.ranked[key].selected = false;
    this.filters.emit({} as Partial<IFiltersGifts>);
  }

}
