<div>
  <div class="flex justify-between items-center px-3">
    <p class="font-Poppins font-[600] text-[10px] text-[#171725] ">About Me</p>
    <span class="material-symbols-outlined text-[#a9abad] cursor-pointer"> more_horiz </span>
  </div>
  <hr class="hidden sm:block border-[#EEEEEE] my-2">
  <div class="px-3">
    <p class="text-[#44444F] text-[10px] font-Roboto" [innerHTML]="user.about_me"></p>
    <div class="flex">
      <button *ngIf="isMe()" routerLink="/settings/profile" class="w-full bg-[#CFD0D1] text-[10px] px-4 py-2 font-Montserrat font-medium rounded-l-full my-3">Edit profile</button>
      <button *ngIf="isMe()" (click)="showMySaved()" (touchend)="showMySaved()" class="w-full bg-[#CFD0D1] text-[10px] px-4 py-2 font-Montserrat font-medium border-l-2 rounded-r-full my-3">My Saved</button>
    </div>
  </div>
  <hr class="hidden sm:block border-[#EEEEEE]">
  <div class="p-3 text-[10px] text-[#171725] info">
    <div class="flex items-center">
      <p class="w-[30px]">
        <span class="material-symbols-outlined">location_on</span>
      </p>
      <p class="font-Poppins">{{user?.countryName}}{{user.region ? ',' :  ''}} {{user.region || ''}}</p>
    </div>
    <div class="flex items-center">
      <p class="w-[30px]">
        <span class="material-symbols-outlined">language</span>
      </p>
      <p class="font-Poppins">{{user.language || ''}}</p>
    </div>
    <div class="flex items-center">
      <p class="w-[30px]">
        <span class="material-symbols-outlined">event_note</span>
      </p>
      <p class="font-Poppins">Joined {{DateTime.fromMillis(user.createDate).toISODate() || ''}}</p>
    </div>
    <div class="flex items-center" *ngIf="user.company">
      <p class="w-[30px]">
        <span class="material-symbols-outlined">work</span>
      </p>
      <p class="font-Poppins">Working at {{user.company || ''}}</p>
    </div>
    <div class="flex items-center" *ngIf="user?.relationship">
      <p class="w-[30px]">
        <span class="material-symbols-outlined">ecg_heart</span>
      </p>
      <p class="font-Poppins">In relationship with Gal Gadot</p>
    </div>
  </div>
</div>
