<app-sidebar-layout title="Course" [useHeaderEdit]="true" cls="pt-[var(--sat)+12px]">
  <router-header>
    <span class="material-symbols-outlined text-[30px] sm:text-[30px] text-[#707070] cursor-pointer mr-[10px]"
          (click)="goBack()">arrow_back</span>
  </router-header>
  <app-dialog #changePic [title]="'Banner picture'"
              [classHeaderDefault]="'justify-center pt-5 pb-3 text-lg'"
              [classModal]="('sm:!min-w-[800px] sm:!w-[800px]')+' bg-black/80 sm:bg-white text-white sm:text-black sm:relative'"
              classBody="">
    <div class="mx-[35px]">
      <image-cropper
        class="w-full !px-0"
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="index === -1 ? 19/6 : 1"
        [resizeToWidth]="index === -1 ? 1400 : 500"
        format="png"
        output="base64"
        (imageCropped)="imageCropped($event)"
      ></image-cropper>
    </div>
    <div class="flex items-center justify-end gap-4 py-2 mx-[35px]">
      <button (click)="cropperReady()"
              class="flex justify-center w-[100px] !py-[6px] mb-2 h-fit gap-2 items-center font-Poppins font-normal bg-[#519935] text-white rounded-xl shadow-sm text-base">
        Save
      </button>
      <button (click)="closeCropper()"
              class="flex justify-center w-[100px] !py-[6px] border-2 border-[#DCDCDC] mb-2 h-fit gap-2 items-center font-Poppins font-normal bg-[#fff] text-[#202020] rounded-xl shadow-sm text-base">
        Discard
      </button>
    </div>
  </app-dialog>
  <div class="bg-white sm:shadow-sm m-0 sm:mx-12 px-6 pb-6 sm:my-8 sm:p-6 rounded-md relative w-full ">
    <div class="flex flex-col sm:flex-row items-start justify-start">
      <div class="sm:hidden">
        <ng-icon name="matArrowBack" class="sm:mt-0 absolute sm:flex left-2
        text-black cursor-pointer text-4xl z-10 bg-none sm:text-3xl sm:rounded-full"
                 (click)="router.navigateByUrl('/profile')"></ng-icon>
        <p class="font-poppins w-[82vw] font-semibold text-[#202020] text-center sm:hidden text-[18px] pt-2">Course</p>
      </div>
      <div class="sm:pl-0">
        <p class="font-poppins font-medium text-[#202020] text-[18px] pt-10 sm:pt-0">Upload New Course</p>
        <p class="font-poppins font-normal my-2 text-[#898989] text-[14px]">Please provide details of this Course.</p>
      </div>
    </div>
    <div class="flex flex-wrap sm:flex-nowrap w-full gap-4 items-center">
      <div class="flex items-start w-full gap-4">
        <div class="flex flex-col gap-4 w-3/5">
          <div class="w-full">
            <label for="category" class="font-poppins font-normal text-[#202020] !text-[14px] mb-2 block">Title</label>
            <app-om-input color="white" [(ngModel)]="data.name"
                          inputClass="!bg-white !py-[9px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px]"
                          placeholder="Type your product name"
                          class="w-[60%]">
            </app-om-input>
          </div>
          <div class="flex gap-4">
            <div class="w-full sm:w-1/2">
              <label for="category"
                     class="font-poppins font-regular text-[#202020] text-[14px] mb-2 block">Language</label>
              <app-om-select id="Language"
                             containerClass="!bg-white !py-[4.5px] !font-poppins !font-medium bw-1 border-gray !text-[14px]"
                             containerItemsClass="!max-h-fit"
                             itemsClass="bg-white border-[#EEEEEE] !text-black hover:bg-white hover:!text-green4-native !py-1"
                             color="gray" [options]="languages" [(ngModel)]="data.language" required
                             #selectedNav="ngModel" [default]="data.language" placeholder="Please select"
              />
            </div>
            <div class="w-full sm:w-1/2">
              <label for="category" class="font-poppins font-regular text-[#202020] text-[14px] mb-2 block">Skill
                level</label>
              <app-om-select id="skillLevel"
                             containerClass="!bg-white !py-[4.5px] !font-poppins !font-medium bw-1 border-gray !text-[14px]"
                             containerItemsClass="!max-h-fit"
                             itemsClass="bg-white border-[#EEEEEE] text-black hover:bg-white hover:!text-green4-native !py-1"
                             color="gray" [options]="skillLevels" [(ngModel)]="data.skill_level" required
                             #selectedNav="ngModel" [default]="data.skill_level" placeholder="Please select"
              />
            </div>
            <div class="w-full sm:w-1/2">
              <label for="category"
                     class="font-poppins font-regular text-[#202020] text-[14px] mb-2 block">Category</label>
              <app-om-select id="category"
                             containerClass="!bg-white !py-[4.5px] !font-poppins !font-medium bw-1 border-gray !text-[14px]"
                             containerItemsClass="!max-h-fit"
                             itemsClass="bg-white border-[#EEEEEE] text-black hover:bg-white hover:!text-green4-native !py-1"
                             color="gray" [options]="category" [(ngModel)]="data.category" required
                             #selectedNav="ngModel" [default]="data.category" placeholder="Please select"
              />
            </div>
          </div>
        </div>
        <div class="w-2/5">
          <input type="file" (change)="onChangePic()" class="opacity-0 w-0 h-0" name="change-pic" accept="image/*" #inputChangePic>
          <img *ngIf="data.banner" (click)="selectImagePic(-1)"
               class="border-2 border-[#CCEABB] border-dashed rounded-xl"
               [src]="data.banner" alt="Profile Image">
          <div *ngIf="!data.banner" (click)="selectImagePic(-1)"
               class="border-2 border-[#CCEABB] border-dashed rounded-xl bg-[#F5F9F280] flex items-center justify-center gap-8 py-12">
            <div class="bg-white h-12 w-12 px-4 rounded-full flex items-center justify-center">
              <app-image-add-icon [width]="24" [height]="24" color="#99C183"></app-image-add-icon>
            </div>
            <div>
              <p class="font-poppins font-semibold text-sm text-[#99C183]">Upload Cover Image (19:6)</p>
              <p class="font-poppins font-normal text-sm text-[#99C183]">Drop your file here or browse</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex w-full sm:pr-12 lg:pr-48 gap-4 items-center">
      <div class="mt-4 w-full">
        <label for="category" class="font-poppins font-normal text-[#202020] text-[14px] mb-2 block">Course
          Description</label>
        <ckeditor
          [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic', 'Underline', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'undo', 'redo' ] }"
          [editor]="Editor" [(ngModel)]="data.description" data="<p>Hello, world!</p>"></ckeditor>
      </div>
    </div>
    <div class="flex flex-col w-full sm:pr-12 lg:pr-48 gap-4 justify-center">
      <p class="font-poppins font-medium text-[#202020] text-[18px] pt-4 sm:pt-4">Course Content</p>
      <div *ngFor="let chapter of data.chapters; index as idx" class="flex flex-col gap-4">
        <div class="shadow flex items-center gap-4 w-full px-6 py-4 rounded-lg">
          <p class="text-[12px] font-semibold w-1/5 font-Montserrat text-[#3F3F44]/20">Chapter {{ idx + 1 }}</p>
          <div class="flex gap-4 items-center w-6/12">
            <p class="text-[12px] font-normal font-Montserrat text-[#3F3F44]">Title</p>
            <div class="w-[calc(100%-70px)]">
              <app-om-input [(ngModel)]="chapter.title" color="gray" inputClass="!py-3"></app-om-input>
            </div>
          </div>
        </div>
        <div class="pl-32" *ngFor="let classItem of data.chapters[idx].classes; index as idxClass">
          <div class="shadow flex items-center gap-4 w-full px-6 py-4 rounded-lg">
            <p class="text-[12px] font-semibold w-2/12 font-Montserrat text-[#3F3F44]/20">Class {{ idxClass + 1 }}</p>
            <div class="flex gap-4 items-center w-6/12">
              <p class="text-[12px] font-normal font-Montserrat text-[#3F3F44]">Title</p>
              <div class="w-[calc(100%-70px)]">
                <app-om-input [(ngModel)]="classItem.title" color="gray" inputClass="!py-3"></app-om-input>
              </div>
            </div>
            <div>
              <div *ngIf="classItem.status === 'uploading' && classItem.progress">
                <div class="flex items-center justify-between">
                  <p class="text-[#3F3F44] text-[10px] font-Montserrat font-light">Uploading...</p>
                  <p class="text-[#99C183] text-[10px] font-Montserrat font-semibold">{{classItem.progress}}%</p>
                </div>
                <progress [value]="classItem.progress" class="accent-[#99C183] rounded-full overflow-hidden" max="100"></progress>
              </div>
              <div *ngIf="classItem.status === 'processing' && classItem.progress">
                <div class="flex items-center justify-between">
                  <p class="text-[#3F3F44] text-[12px] font-Montserrat font-normal">Processing...</p>
                </div>
              </div>
              <div *ngIf="classItem.status === 'finish'">
                <div class="flex items-center justify-between">
                  <p class="text-[#3F3F44] text-[12px] font-Montserrat font-normal">Finished uploading</p>
                </div>
              </div>
              <button *ngIf="!classItem.progress" type="button" #btn (click)="openSelectFile(btn, idx, idxClass)"
                      class="bg-[#81cb525e] items-center text-[#131313] text-[12px] font-Montserrat font-semibold flex gap-4 rounded-lg px-6 py-3">
                <app-video-courses-icon [width]="22" [height]="22"></app-video-courses-icon>
                Upload Course
              </button>
              <input type="file" class="w-0 h-0 opacity-0 absolute" #inputVideo accept="video/*" (change)="uploadVideo($event)">
            </div>
          </div>
        </div>
        <div class="pl-32">
          <button
            class="px-4 !py-[10.5px] gap-2 flex items-center justify-center font-semibold text-xs w-fit bg-[#81CB52] text-[#3F3F44] rounded-md shadow-sm"
            (click)="addClass(idx)">
            <app-plus-icon [width]="16" [height]="16" color="#3F3F44" class="mr-2"></app-plus-icon>
            Add Class
          </button>
        </div>
      </div>
      <button
        class="px-4 !py-[10.5px] gap-2 flex items-center font-semibold text-xs w-fit bg-[#81CB52] text-[#3F3F44] rounded-md shadow-sm"
        (click)="addChapter()">
        <app-plus-icon [width]="16" [height]="16" color="#3F3F44" class="mr-2"></app-plus-icon>
        Add Chapter
      </button>
    </div>
    <div class="flex flex-col w-full sm:pr-12 lg:pr-48 gap-4 justify-center pt-4">
      <label for="Effects" class="font-poppins font-medium text-[#202020] !text-[14px] mb-0 block">What you’ll
        teach</label>
      <div class="relative max-w-[350px]">
        <app-autocomplete [(keyword)]="teach" (onChange)="setTeach($event)"
                          [options]="teaching" [allowNews]="true"
                          inputClass="!bg-white !py-[9.5px] !pl-8 bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px]"></app-autocomplete>
        <app-search-icon class="bg-white absolute top-3 left-2" [width]="20"
                         [height]="20"></app-search-icon>
      </div>
      <ul>
        <li *ngFor="let t of data.teaching" class="flex gap-4"><input type="checkbox" class="accent-omgreen-50 " checked disabled readonly >{{ t }}</li>
      </ul>
    </div>
    <div class="flex w-full sm:pr-12 lg:pr-48 gap-4 items-center">
      <div class="mt-4 w-full">
        <label for="category"
               class="font-poppins font-normal text-[#202020] text-[14px] mb-2 block">Requirements</label>
        <ckeditor
          [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic', 'Underline', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'undo', 'redo' ] }"
          [editor]="Editor" [(ngModel)]="data.requirements" data="<p>Hello, world!</p>"></ckeditor>
      </div>
    </div>
    <div class="flex w-full sm:pr-12 lg:pr-48 overflow-auto gap-4 items-center">
      <div class="mt-4 w-full">
        <p class="font-poppins font-semibold text-[#202020] text-[14px]">Course photos</p>
        <p class="font-poppins font-normal my-2 text-[#898989] text-[14px]">Please provide at least three photos of your
          course</p>
        <div class="flex gap-4 w-full">
          <div class="min-w-[150px] min-h-[150px] max-w-[150px] max-h-[150px]" *ngFor="let i of [0,1,2,3,4]">
            <img *ngIf="data.imagenesPreview[i]" (click)="selectImagePic(i)"
                 class="border-2 border-[#CCEABB] border-dashed rounded-xl"
                 [src]="data.imagenesPreview[i]" alt="img1">
            <div *ngIf="!data.imagenesPreview[i]" (click)="selectImagePic(i)"
                 class="border-2 border-green-native gap-2 flex overflow-hidden items-center rounded-lg py-4 px-4 h-full">
              <svg [class]="{'opacity-0': data.file1?.name}" id="vuesax_bold_gallery"
                   data-name="vuesax/bold/gallery"
                   xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <g id="gallery">
                  <path id="Vector"
                        d="M.442,1.65l-.017.017A5.156,5.156,0,0,1,0,0,5.118,5.118,0,0,0,.442,1.65Z"
                        transform="translate(1.708 14.192)" fill="#000"/>
                  <path id="Vector-2" data-name="Vector"
                        d="M3.967,1.983A1.983,1.983,0,1,1,1.983,0,1.983,1.983,0,0,1,3.967,1.983Z"
                        transform="translate(5.517 4.683)" fill="#000"/>
                  <path id="Vector-3" data-name="Vector"
                        d="M11.825,0H4.842A4.447,4.447,0,0,0,0,4.842v6.983a5.6,5.6,0,0,0,.467,2.367,4.439,4.439,0,0,0,4.375,2.475h6.983a4.447,4.447,0,0,0,4.842-4.842V4.842A4.447,4.447,0,0,0,11.825,0Zm3.483,8.75a1.858,1.858,0,0,0-2.35,0L9.492,11.725a1.858,1.858,0,0,1-2.35,0l-.283-.233a1.851,1.851,0,0,0-2.2-.117L1.542,13.467a4.444,4.444,0,0,1-.292-1.642V4.842c0-2.35,1.242-3.592,3.592-3.592h6.983c2.35,0,3.592,1.242,3.592,3.592v4Z"
                        transform="translate(1.667 1.667)" fill="#000"/>
                  <path id="Vector-4" data-name="Vector" d="M0,0H20V20H0Z" fill="none"
                        opacity="0"/>
                </g>
              </svg>
              <ng-icon [class]="{'opacity-0': data.file1?.name}" name="matPlus"
                       class="text-green4-native cursor-pointer text-lg z-10"></ng-icon>
              <p [class]="{'opacity-0': data.file1?.name}" class="text-green4-native text-[18px]">
                New</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex w-full flex-col sm:pr-12 lg:pr-48 gap-4 items-center">
      <div class="w-full flex-col gap-4 pt-4">
        <label for="initialCost" class="font-poppins font-medium text-[#202020] !text-[14px] mb-0 block">Initial Cost</label>
        <div class="relative w-full">
          <div class="absolute top-0 left-0 bw-1 rounded-l-lg z-20 p-[11px]"><app-price-icon [width]="18" [height]="18"></app-price-icon></div>
          <app-om-input id="initialCost" color="white" [(ngModel)]="data.initial_cost" (keyuptext)="changePublishCost($event)"
                        inputClass="!bg-white !py-[9px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px] !pl-14"
                        placeholder="Initial cost"
                        class="w-[60%]">
          </app-om-input>
        </div>
        <span class="text-[12px]">Initial cost is required before taxes and a 20% commission.</span>
      </div>
      <div class="w-full flex-col gap-4 pt-4">
        <label for="initialCost" class="font-poppins font-medium text-[#202020] !text-[14px] mb-0 block">Publish Cost</label>
        <div class="relative w-full">
          <div class="absolute top-0 left-0 bw-1 rounded-l-lg z-20 p-[11px]"><app-price-icon [width]="18" [height]="18"></app-price-icon></div>
          <app-om-input id="cost" color="white" [(ngModel)]="data.publish_cost" [readonly]="true"
                        inputClass="!bg-white !py-[9px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px] !pl-14"
                        placeholder="Publish cost"
                        class="w-[60%]">
          </app-om-input>
        </div>
      </div>
    </div>
    <div class="w-[170px] mt-4">
      <button
        class="px-4 !py-[10.5px] gap-2 flex items-center font-semibold text-xs bg-[#519935] text-white rounded-md shadow-sm"
        (click)="save()">
        Create Course
      </button>
    </div>
  </div>
</app-sidebar-layout>
