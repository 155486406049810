  <app-gift-send-layout [useHeaderEdit]="true">
    <router-header-gift>
      <div class="flex mx-3.5 sm:mx-0 sm:mt-[0px] relative  sm:justify-center sm:mb-0" *ngIf="tapActive!='list-gifts'">
        <div class="w-full sm:w-[1260px] flex gap-4">
          <span class=" material-symbols-outlined text-4xl my-2 cursor-pointer" (click)="regresar()">arrow_back</span>
          <!--        <span class="hidden sm:block material-symbols-outlined cursor-pointer" (click)="regresar()">arrow_back</span>-->
          <!--        <span class="sm:hidden material-symbols-outlined cursor-pointer" (click)="regresar2()">arrow_back</span>-->
          <!--        <p class="sm:hidden sm:text-[18px] text-[#202020] sm:font-Montserrat font-medium w-[90%] left-[10%] text-center">Gift Detail</p>-->
        </div>
      </div>
        <div class="flex items-center gap-2 mx-3.5 sm:ml-0" *ngIf="tapActive=='list-gifts'">
          <span class=" material-symbols-outlined text-4xl my-2 cursor-pointer" (click)="deviceInfo.goBack()">arrow_back</span>
        </div>
    </router-header-gift>
  <div class="w-full bg-[#FAFAFA]  sm:min-h-screen sm:pb-[20px]">
    <app-gift-list  *ngIf="tapActive=='list-gifts'" (sendData)="setGifts($event)"></app-gift-list>
    <div *ngIf="tapActive=='sumary'" class="sm:flex justify-center sm:gap-8">
      <div class="sm:w-5/12" *ngIf="data.user.id==''">
        <div class="flex items-center gap-2 mb-[15px] mx-4 sm:mx-0 bg-white sm:bg-[#ffffff00] sticky top-[60px] z-30">
          <span class=" material-symbols-outlined text-4xl my-2 cursor-pointer sm:hidden " (click)="regresar()">arrow_back</span>
          <p class="text-[19px] sm:text-[22px] font-Montserrat font-bold sm:font-semibold px-4 sm:px-0">Select Recipient</p>
        </div>
        <app-gift-recipient  (sendData)="setUser($event)"></app-gift-recipient>
      </div>

      <div *ngIf="data.user.id!='' && !deskPay" class="sm:w-5/12">
        <div class="flex items-center gap-2 mb-[15px] mx-4 sm:mx-0 bg-white sm:bg-[#ffffff00] sticky top-[60px] z-30">
          <span *ngIf="!movilPay" class=" material-symbols-outlined text-4xl my-2 cursor-pointer sm:hidden " (click)="regresar()">arrow_back</span>
          <span *ngIf="movilPay" class=" material-symbols-outlined text-4xl my-2 cursor-pointer sm:hidden " (click)="regresar2()">arrow_back</span>
          <p class="hidden sm:block text-[19px] sm:text-[22px] font-Montserrat font-semibold sm:font-semibold">Select Recipient</p>
          <p *ngIf="!movilPay" class="text-[19px] font-Montserrat font-semibold sm:hidden">Gift Summary</p>
          <p *ngIf="movilPay" class="text-[19px] font-Montserrat font-semibold sm:hidden">Order details</p>
        </div>
        <div class="mx-4 sm:mx-0" >

          <p class="text-[#77838F] text-[14px] font-SF-Pro-Text mt-5 mb-0 sm:ml-1">Recipient</p>
          <div class="flex items-center justify-between bg-white h-[82px] rounded-2xl mb-[16px] p-[14px] mb-[20px] shadow-[0px_3px_6px_#00000029]" >
            <div class="flex items-center">
              <img class="w-[54px] h-[54px] rounded-full object-cover" [src]="data.user.avatar || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'" >
              <div class="ml-[14px]">
                <p class="text-[#1E2022] text-[14px] font-SF-Pro-Text font-medium">{{data.user.name}}</p>
                <p class="text-[#77838F] text-[14px] font-SF-Pro-Text font-medium">@{{data.user.username}}</p>
              </div>
            </div>
            <span class="material-symbols-outlined flex items-center justify-center bg-[#87D255] w-[37px] h-[37px] text-white font-bold rounded-full">check</span>
          </div>
          <p class="text-[#77838F] text-[14px] font-SF-Pro-Text mt-5 mb-0 sm:hidden">Gift</p>
          <div *ngIf=" data.gifts.length>0" class="sm:hidden">
            <app-card-gift class="gift-send block w-[100%] sm:w-[48%] mb-[24px]" *ngFor="let item of data.gifts" [data2]="item" [quantity]="item.quantity" [enableActions]="false"  [enableThcCdb]="false" [enableType]="false"></app-card-gift>
          </div>
          <div *ngIf="movilPay" class="mt-19px px-[20px] py-[17px] sm:px-[34px] sm:py-[57px] mb-[16px] rounded-3xl bg-white shadow-[0px_3px_6px_#00000029] sm:shadow-none">
            <app-cost-list [subTotal]="getSubTotal()"
                           [shipping]="shipping"
                           [total]="getTotal()"
                           [isSend]="router.url.split('?')[0].split('#')[0].includes('/gift-send')"></app-cost-list>
          </div>
          <div class="bg-white flow-root rounded-3xl shadow-[0px_3px_6px_#00000029] pb-5  px-0">
            <app-delivery-address [(ngModel)]="data.address" [classTitle]="movilPay?'text-[15px] font-Poppins':'text-[13px]'" class="block  mx-0"></app-delivery-address>

            <textarea *ngIf="!movilPay" class="sm:hidden w-full bg-[#dfdfdf29] border border-[#cbcbcb29] h-[100px] rounded-2xl p-[10px] mt-[13px]" placeholder="Write a message" [(ngModel)]="data.message"></textarea>
            <p class="text-[12px] text-red-500" *ngIf="isError && !data.message && movilPay">Message is required.</p>
            <div *ngIf="!movilPay" class="mt-19px px-[13px] mb-[16px] sm:hidden">
              <app-cost-list [subTotal]="getSubTotal()" [shipping]="shipping" [total]="getTotal()"
                             [isSend]="router.url.split('?')[0].split('#')[0].includes('/gift-send')"></app-cost-list>
              <hr class="mt-[16px] mb-[18px]">
              <button class="text-white bg-[#87D255] font-Montserrat font-bold text-[17px] rounded-2xl h-[50px] flex items-center justify-center w-[calc(100%-36px)] mx-[18px]" (click)="checkout()">Send</button>
            </div>
            <div *ngIf="movilPay" class="mb-[35px]">
              <div class="flex items-center justify-between sm:px-3">
                <p class="text-[#262628] text-[12px] font-Poppins font-bold sm:mx-[11px] mt-[16px] mb-[17px]">PAYMENT METHOD</p>
                <p class="text-[#519935] text-[12px] font-Montserrat hidden">Add new card</p>
              </div>
              <app-checkout *ngIf="movilPay" [data]="data" [shipping]="shipping" [isEnableElement]="true" [enableInfoAddress]="false" class=" bg-white"></app-checkout>
              <button class="text-white bg-[#87D255] font-Montserrat font-bold text-[17px] rounded-2xl h-[50px] flex items-center justify-center w-[calc(100%-36px)] mx-[18px]" (click)="checkout()">Send</button>
            </div>
          </div>
        </div>
      </div>

      <div class="hidden sm:block sm:w-7/12" *ngIf="!deskPay">

        <div class="shadow-[0px_3px_6px_#00000029] rounded-[43px] sm:bg-white py-[35px] px-[29px]">
          <p class="sm:text-[#000000] sm:text-[24px] font-Montserrat font-medium">Send Gift</p>
          <div class="list-products sm:mt-[47px] mx-4">
            <p class="text-[#77838F] text-[14px] font-SF-Pro-Text ml-[-1rem] mt-5 mb-0 hidden sm:block">Gift</p>
            <div class="flex items-center mb-1" *ngFor="let item of data.gifts">
              <div class="w-[100px] h-[100px] p-3 flex items-center justify-center">
                <img class="w-full h-full object-contain" [src]="item.images[0]">
              </div>
              <div>
                <p class="sm:text-[19px] sm:text-[#000000] font-Montserrat font-semibold">{{item.name}}</p>
                <p class="sm:text-[14px] sm:text-[#AAAAAA] font-Montserrat leading-none">{{item.genetics}}</p>
                <p class="sm:text-[14px] sm:text-[#AAAAAA] font-Montserrat leading-none">{{item.quantity}} X ${{item.total}}</p>
              </div>
            </div>
          </div>
          <div class="sm:mt-[58px]">
            <p class="sm:text-[16px] sm:text-[#000000] font-Montserrat font-medium mb-[19px]">Message</p>
            <div class="sm:mt-[24px]">
              <textarea class="w-full mx-auto bg-[#dfdfdf29] border border-[rgba(15,13,35,0.2)] rounded-[12px] h-[145px] p-[24px] mt-[13px]" placeholder="Write a message" [(ngModel)]="data.message"></textarea>
              <p class="text-[12px] text-red-500" *ngIf="isError && !data.message">Message is required.</p>
            </div>
          </div>
          <div class="sm:mt-[51px]">
            <app-cost-list [subTotal]="getSubTotal()" [shipping]="shipping" [total]="getTotal()" [classFont]="'font-Montserrat'"
                           [isSend]="router.url.split('?')[0].split('#')[0].includes('/gift-send')"></app-cost-list>
          </div>
          <button class="flex items-center mx-auto justify-center sm:w-full sm:h-[54px] sm:text-[25px] bg-[#81CB52] text-white sm:mt-[42px] rounded-2xl" (click)="nextPay2()" >
            Continue
          </button>
        </div>
      </div>
    </div>
    <app-checkout *ngIf="deskPay" [data]="data" [shipping]="shipping" [isPayable]="false"  [enableInfoAddress]="false" class=" bg-white"></app-checkout>
  </div>
</app-gift-send-layout>
