  <div class="flex gap-4 w-full sm:justify-between app-reactions-bar">
      <div class="flex items-center">
        <svg class="icon w-[22px] h-[22px] sm:w-[26px] sm:h-[26px] cursor-pointer" (click)="clickIconLike.emit()"   [class]="classIcons+' '+(like?'active':'')" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.858 19.32">
          <g id="Like" transform="translate(0.479 0.052)">
            <path class="" id="Shape" d="M18.327,1.531a5.225,5.225,0,0,0-7.391,0L9.929,2.538,8.922,1.531A5.226,5.226,0,0,0,1.531,8.922L2.538,9.929,9.929,17.32,17.32,9.929l1.007-1.007a5.225,5.225,0,0,0,0-7.391Z" transform="translate(0.521 0.948)" fill="none" stroke="#77838f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
          </g>
        </svg>
        <label *ngIf="seeLabelsReactions" class="ml-2 hidden sm:block" [class]="classLabels">{{numberReactions.numberLikes}} Likes</label>
      </div>
      <div class="flex items-center">
        <svg class="icon w-[22px] h-[22px] sm:w-[26px] sm:h-[26px] cursor-pointer"  [class]="classIcons" (touchstart)="changeOpenComment()" (touchend)="changeOpenComment()" (click)="changeOpenComment()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 19.002">
          <path id="Path" d="M5.144,19A1.539,1.539,0,0,1,3.608,17.47l0-2.316a4.727,4.727,0,0,1-2.579-1.649A4.679,4.679,0,0,1,0,10.564V4.721A4.719,4.719,0,0,1,4.706,0H15.294A4.719,4.719,0,0,1,20,4.721v5.844a4.718,4.718,0,0,1-4.706,4.72h-4.78l-4.449,3.4A1.507,1.507,0,0,1,5.144,19ZM4.706,2A2.717,2.717,0,0,0,2,4.721v5.844a2.713,2.713,0,0,0,.767,1.9,2.685,2.685,0,0,0,1.863.822.994.994,0,0,1,.972,1l0,2.237,3.962-3.029a1.007,1.007,0,0,1,.608-.205h5.118A2.716,2.716,0,0,0,18,10.564V4.721A2.717,2.717,0,0,0,15.294,2Z" transform="translate(0 0)" fill="#92929d"/>
        </svg>
        <label *ngIf="seeLabelsReactions" class="ml-2 hidden sm:block" [class]="classLabels">{{numberReactions.numberComments}} Comments</label>
      </div>
      <div class="flex items-center">
        <app-share [infoPost]="infoPost" [seeLabelsReactions]="seeLabelsReactions" [numberShares]="numberReactions.numberShares" [classLabel]="classLabels" [classIcon]="classIcons" (clickIconShare)="clickIconShare.emit()"></app-share>
      </div>
    <div class="flex items-center ml-auto sm:ml-0">
      <svg class="icon w-[22px] h-[22px] sm:w-[26px] sm:h-[26px] cursor-pointer" [class]="classIcons+' '+(save?'active':'')"  (click)="clickIconSave.emit()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.778 21">
        <g id="Save" transform="translate(1 1)">
          <path id="Shape" d="M14.778,19,7.389,13.722,0,19V2.111A2.111,2.111,0,0,1,2.111,0H12.667a2.111,2.111,0,0,1,2.111,2.111Z" fill="none" stroke="#77838f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
        </g>
      </svg>
      <label *ngIf="seeLabelsReactions" class="ml-2 hidden sm:block"  [class]="classLabels">{{numberReactions.numberSaved}} Saved</label>
    </div>
  </div>
