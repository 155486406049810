import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-product-grow-info',
  templateUrl: './product-grow-info.component.html',
  styleUrls: ['./product-grow-info.component.scss']
})
export class ProductGrowInfoComponent {
  @Input() grow_information='';
}
