import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";

interface Countries {
  code: string
  code3: string
  name: string
  number: string
}

@Injectable({
  providedIn: 'root'
})
export class CatalogsService {

  constructor(private http: HttpClient) {
  }
  getUsers(filters: {[key: string]: any}) {
    return this.http.get('/users/search', {params: filters});
  }
  getTypesUsers() {
    return this.http.get('/common/user-descriptions');
  }

  getAll(type: string, lastKey: string) {
    return this.http.get('/catalogs/' + type, {params: {lastKey}});
  }

  getProducts() {
    return this.http.get('/productscatalog/all');
  }

  countries: Countries [] = [
    {code: "AF", code3: "AFG", name: "Afghanistan", number: "004"},
    {code: "AL", code3: "ALB", name: "Albania", number: "008"},
    {code: "DZ", code3: "DZA", name: "Algeria", number: "012"},
    {code: "AS", code3: "ASM", name: "American Samoa", number: "016"},
    {code: "AD", code3: "AND", name: "Andorra", number: "020"},
    {code: "AO", code3: "AGO", name: "Angola", number: "024"},
    {code: "AI", code3: "AIA", name: "Anguilla", number: "660"},
    {code: "AQ", code3: "ATA", name: "Antarctica", number: "010"},
    {code: "AG", code3: "ATG", name: "Antigua and Barbuda", number: "028"},
    {code: "AR", code3: "ARG", name: "Argentina", number: "032"},
    {code: "AM", code3: "ARM", name: "Armenia", number: "051"},
    {code: "AW", code3: "ABW", name: "Aruba", number: "533"},
    {code: "AU", code3: "AUS", name: "Australia", number: "036"},
    {code: "AT", code3: "AUT", name: "Austria", number: "040"},
    {code: "AZ", code3: "AZE", name: "Azerbaijan", number: "031"},
    {code: "BS", code3: "BHS", name: "Bahamas (the)", number: "044"},
    {code: "BH", code3: "BHR", name: "Bahrain", number: "048"},
    {code: "BD", code3: "BGD", name: "Bangladesh", number: "050"},
    {code: "BB", code3: "BRB", name: "Barbados", number: "052"},
    {code: "BY", code3: "BLR", name: "Belarus", number: "112"},
    {code: "BE", code3: "BEL", name: "Belgium", number: "056"},
    {code: "BZ", code3: "BLZ", name: "Belize", number: "084"},
    {code: "BJ", code3: "BEN", name: "Benin", number: "204"},
    {code: "BM", code3: "BMU", name: "Bermuda", number: "060"},
    {code: "BT", code3: "BTN", name: "Bhutan", number: "064"},
    {code: "BO", code3: "BOL", name: "Bolivia (Plurinational State of)", number: "068"},
    {code: "BQ", code3: "BES", name: "Bonaire, Sint Eustatius and Saba", number: "535"},
    {code: "BA", code3: "BIH", name: "Bosnia and Herzegovina", number: "070"},
    {code: "BW", code3: "BWA", name: "Botswana", number: "072"},
    {code: "BV", code3: "BVT", name: "Bouvet Island", number: "074"},
    {code: "BR", code3: "BRA", name: "Brazil", number: "076"},
    {code: "IO", code3: "IOT", name: "British Indian Ocean Territory (the)", number: "086"},
    {code: "BN", code3: "BRN", name: "Brunei Darussalam", number: "096"},
    {code: "BG", code3: "BGR", name: "Bulgaria", number: "100"},
    {code: "BF", code3: "BFA", name: "Burkina Faso", number: "854"},
    {code: "BI", code3: "BDI", name: "Burundi", number: "108"},
    {code: "CV", code3: "CPV", name: "Cabo Verde", number: "132"},
    {code: "KH", code3: "KHM", name: "Cambodia", number: "116"},
    {code: "CM", code3: "CMR", name: "Cameroon", number: "120"},
    {code: "CA", code3: "CAN", name: "Canada", number: "124"},
    {code: "KY", code3: "CYM", name: "Cayman Islands (the)", number: "136"},
    {code: "CF", code3: "CAF", name: "Central African Republic (the)", number: "140"},
    {code: "TD", code3: "TCD", name: "Chad", number: "148"},
    {code: "CL", code3: "CHL", name: "Chile", number: "152"},
    {code: "CN", code3: "CHN", name: "China", number: "156"},
    {code: "CX", code3: "CXR", name: "Christmas Island", number: "162"},
    {code: "CC", code3: "CCK", name: "Cocos (Keeling) Islands (the)", number: "166"},
    {code: "CO", code3: "COL", name: "Colombia", number: "170"},
    {code: "KM", code3: "COM", name: "Comoros (the)", number: "174"},
    {code: "CD", code3: "COD", name: "Congo (the Democratic Republic of the)", number: "180"},
    {code: "CG", code3: "COG", name: "Congo (the)", number: "178"},
    {code: "CK", code3: "COK", name: "Cook Islands (the)", number: "184"},
    {code: "CR", code3: "CRI", name: "Costa Rica", number: "188"},
    {code: "HR", code3: "HRV", name: "Croatia", number: "191"},
    {code: "CU", code3: "CUB", name: "Cuba", number: "192"},
    {code: "CW", code3: "CUW", name: "Curaçao", number: "531"},
    {code: "CY", code3: "CYP", name: "Cyprus", number: "196"},
    {code: "CZ", code3: "CZE", name: "Czechia", number: "203"},
    {code: "CI", code3: "CIV", name: "Côte d'Ivoire", number: "384"},
    {code: "DK", code3: "DNK", name: "Denmark", number: "208"},
    {code: "DJ", code3: "DJI", name: "Djibouti", number: "262"},
    {code: "DM", code3: "DMA", name: "Dominica", number: "212"},
    {code: "DO", code3: "DOM", name: "Dominican Republic (the)", number: "214"},
    {code: "EC", code3: "ECU", name: "Ecuador", number: "218"},
    {code: "EG", code3: "EGY", name: "Egypt", number: "818"},
    {code: "SV", code3: "SLV", name: "El Salvador", number: "222"},
    {code: "GQ", code3: "GNQ", name: "Equatorial Guinea", number: "226"},
    {code: "ER", code3: "ERI", name: "Eritrea", number: "232"},
    {code: "EE", code3: "EST", name: "Estonia", number: "233"},
    {code: "SZ", code3: "SWZ", name: "Eswatini", number: "748"},
    {code: "ET", code3: "ETH", name: "Ethiopia", number: "231"},
    {code: "FK", code3: "FLK", name: "Falkland Islands (the) [Malvinas]", number: "238"},
    {code: "FO", code3: "FRO", name: "Faroe Islands (the)", number: "234"},
    {code: "FJ", code3: "FJI", name: "Fiji", number: "242"},
    {code: "FI", code3: "FIN", name: "Finland", number: "246"},
    {code: "FR", code3: "FRA", name: "France", number: "250"},
    {code: "GF", code3: "GUF", name: "French Guiana", number: "254"},
    {code: "PF", code3: "PYF", name: "French Polynesia", number: "258"},
    {code: "TF", code3: "ATF", name: "French Southern Territories (the)", number: "260"},
    {code: "GA", code3: "GAB", name: "Gabon", number: "266"},
    {code: "GM", code3: "GMB", name: "Gambia (the)", number: "270"},
    {code: "GE", code3: "GEO", name: "Georgia", number: "268"},
    {code: "DE", code3: "DEU", name: "Germany", number: "276"},
    {code: "GH", code3: "GHA", name: "Ghana", number: "288"},
    {code: "GI", code3: "GIB", name: "Gibraltar", number: "292"},
    {code: "GR", code3: "GRC", name: "Greece", number: "300"},
    {code: "GL", code3: "GRL", name: "Greenland", number: "304"},
    {code: "GD", code3: "GRD", name: "Grenada", number: "308"},
    {code: "GP", code3: "GLP", name: "Guadeloupe", number: "312"},
    {code: "GU", code3: "GUM", name: "Guam", number: "316"},
    {code: "GT", code3: "GTM", name: "Guatemala", number: "320"},
    {code: "GG", code3: "GGY", name: "Guernsey", number: "831"},
    {code: "GN", code3: "GIN", name: "Guinea", number: "324"},
    {code: "GW", code3: "GNB", name: "Guinea-Bissau", number: "624"},
    {code: "GY", code3: "GUY", name: "Guyana", number: "328"},
    {code: "HT", code3: "HTI", name: "Haiti", number: "332"},
    {code: "HM", code3: "HMD", name: "Heard Island and McDonald Islands", number: "334"},
    {code: "VA", code3: "VAT", name: "Holy See (the)", number: "336"},
    {code: "HN", code3: "HND", name: "Honduras", number: "340"},
    {code: "HK", code3: "HKG", name: "Hong Kong", number: "344"},
    {code: "HU", code3: "HUN", name: "Hungary", number: "348"},
    {code: "IS", code3: "ISL", name: "Iceland", number: "352"},
    {code: "IN", code3: "IND", name: "India", number: "356"},
    {code: "ID", code3: "IDN", name: "Indonesia", number: "360"},
    {code: "IR", code3: "IRN", name: "Iran (Islamic Republic of)", number: "364"},
    {code: "IQ", code3: "IRQ", name: "Iraq", number: "368"},
    {code: "IE", code3: "IRL", name: "Ireland", number: "372"},
    {code: "IM", code3: "IMN", name: "Isle of Man", number: "833"},
    {code: "IL", code3: "ISR", name: "Israel", number: "376"},
    {code: "IT", code3: "ITA", name: "Italy", number: "380"},
    {code: "JM", code3: "JAM", name: "Jamaica", number: "388"},
    {code: "JP", code3: "JPN", name: "Japan", number: "392"},
    {code: "JE", code3: "JEY", name: "Jersey", number: "832"},
    {code: "JO", code3: "JOR", name: "Jordan", number: "400"},
    {code: "KZ", code3: "KAZ", name: "Kazakhstan", number: "398"},
    {code: "KE", code3: "KEN", name: "Kenya", number: "404"},
    {code: "KI", code3: "KIR", name: "Kiribati", number: "296"},
    {code: "KP", code3: "PRK", name: "Korea (the Democratic People's Republic of)", number: "408"},
    {code: "KR", code3: "KOR", name: "Korea (the Republic of)", number: "410"},
    {code: "KW", code3: "KWT", name: "Kuwait", number: "414"},
    {code: "KG", code3: "KGZ", name: "Kyrgyzstan", number: "417"},
    {code: "LA", code3: "LAO", name: "Lao People's Democratic Republic (the)", number: "418"},
    {code: "LV", code3: "LVA", name: "Latvia", number: "428"},
    {code: "LB", code3: "LBN", name: "Lebanon", number: "422"},
    {code: "LS", code3: "LSO", name: "Lesotho", number: "426"},
    {code: "LR", code3: "LBR", name: "Liberia", number: "430"},
    {code: "LY", code3: "LBY", name: "Libya", number: "434"},
    {code: "LI", code3: "LIE", name: "Liechtenstein", number: "438"},
    {code: "LT", code3: "LTU", name: "Lithuania", number: "440"},
    {code: "LU", code3: "LUX", name: "Luxembourg", number: "442"},
    {code: "MO", code3: "MAC", name: "Macao", number: "446"},
    {code: "MG", code3: "MDG", name: "Madagascar", number: "450"},
    {code: "MW", code3: "MWI", name: "Malawi", number: "454"},
    {code: "MY", code3: "MYS", name: "Malaysia", number: "458"},
    {code: "MV", code3: "MDV", name: "Maldives", number: "462"},
    {code: "ML", code3: "MLI", name: "Mali", number: "466"},
    {code: "MT", code3: "MLT", name: "Malta", number: "470"},
    {code: "MH", code3: "MHL", name: "Marshall Islands (the)", number: "584"},
    {code: "MQ", code3: "MTQ", name: "Martinique", number: "474"},
    {code: "MR", code3: "MRT", name: "Mauritania", number: "478"},
    {code: "MU", code3: "MUS", name: "Mauritius", number: "480"},
    {code: "YT", code3: "MYT", name: "Mayotte", number: "175"},
    {code: "MX", code3: "MEX", name: "Mexico", number: "484"},
    {code: "FM", code3: "FSM", name: "Micronesia (Federated States of)", number: "583"},
    {code: "MD", code3: "MDA", name: "Moldova (the Republic of)", number: "498"},
    {code: "MC", code3: "MCO", name: "Monaco", number: "492"},
    {code: "MN", code3: "MNG", name: "Mongolia", number: "496"},
    {code: "ME", code3: "MNE", name: "Montenegro", number: "499"},
    {code: "MS", code3: "MSR", name: "Montserrat", number: "500"},
    {code: "MA", code3: "MAR", name: "Morocco", number: "504"},
    {code: "MZ", code3: "MOZ", name: "Mozambique", number: "508"},
    {code: "MM", code3: "MMR", name: "Myanmar", number: "104"},
    {code: "NA", code3: "NAM", name: "Namibia", number: "516"},
    {code: "NR", code3: "NRU", name: "Nauru", number: "520"},
    {code: "NP", code3: "NPL", name: "Nepal", number: "524"},
    {code: "NL", code3: "NLD", name: "Netherlands (the)", number: "528"},
    {code: "NC", code3: "NCL", name: "New Caledonia", number: "540"},
    {code: "NZ", code3: "NZL", name: "New Zealand", number: "554"},
    {code: "NI", code3: "NIC", name: "Nicaragua", number: "558"},
    {code: "NE", code3: "NER", name: "Niger (the)", number: "562"},
    {code: "NG", code3: "NGA", name: "Nigeria", number: "566"},
    {code: "NU", code3: "NIU", name: "Niue", number: "570"},
    {code: "NF", code3: "NFK", name: "Norfolk Island", number: "574"},
    {code: "MP", code3: "MNP", name: "Northern Mariana Islands (the)", number: "580"},
    {code: "NO", code3: "NOR", name: "Norway", number: "578"},
    {code: "OM", code3: "OMN", name: "Oman", number: "512"},
    {code: "PK", code3: "PAK", name: "Pakistan", number: "586"},
    {code: "PW", code3: "PLW", name: "Palau", number: "585"},
    {code: "PS", code3: "PSE", name: "Palestine, State of", number: "275"},
    {code: "PA", code3: "PAN", name: "Panama", number: "591"},
    {code: "PG", code3: "PNG", name: "Papua New Guinea", number: "598"},
    {code: "PY", code3: "PRY", name: "Paraguay", number: "600"},
    {code: "PE", code3: "PER", name: "Peru", number: "604"},
    {code: "PH", code3: "PHL", name: "Philippines (the)", number: "608"},
    {code: "PN", code3: "PCN", name: "Pitcairn", number: "612"},
    {code: "PL", code3: "POL", name: "Poland", number: "616"},
    {code: "PT", code3: "PRT", name: "Portugal", number: "620"},
    {code: "PR", code3: "PRI", name: "Puerto Rico", number: "630"},
    {code: "QA", code3: "QAT", name: "Qatar", number: "634"},
    {code: "MK", code3: "MKD", name: "Republic of North Macedonia", number: "807"},
    {code: "RO", code3: "ROU", name: "Romania", number: "642"},
    {code: "RU", code3: "RUS", name: "Russian Federation (the)", number: "643"},
    {code: "RW", code3: "RWA", name: "Rwanda", number: "646"},
    {code: "RE", code3: "REU", name: "Réunion", number: "638"},
    {code: "BL", code3: "BLM", name: "Saint Barthélemy", number: "652"},
    {code: "SH", code3: "SHN", name: "Saint Helena, Ascension and Tristan da Cunha", number: "654"},
    {code: "KN", code3: "KNA", name: "Saint Kitts and Nevis", number: "659"},
    {code: "LC", code3: "LCA", name: "Saint Lucia", number: "662"},
    {code: "MF", code3: "MAF", name: "Saint Martin (French part)", number: "663"},
    {code: "PM", code3: "SPM", name: "Saint Pierre and Miquelon", number: "666"},
    {code: "VC", code3: "VCT", name: "Saint Vincent and the Grenadines", number: "670"},
    {code: "WS", code3: "WSM", name: "Samoa", number: "882"},
    {code: "SM", code3: "SMR", name: "San Marino", number: "674"},
    {code: "ST", code3: "STP", name: "Sao Tome and Principe", number: "678"},
    {code: "SA", code3: "SAU", name: "Saudi Arabia", number: "682"},
    {code: "SN", code3: "SEN", name: "Senegal", number: "686"},
    {code: "RS", code3: "SRB", name: "Serbia", number: "688"},
    {code: "SC", code3: "SYC", name: "Seychelles", number: "690"},
    {code: "SL", code3: "SLE", name: "Sierra Leone", number: "694"},
    {code: "SG", code3: "SGP", name: "Singapore", number: "702"},
    {code: "SX", code3: "SXM", name: "Sint Maarten (Dutch part)", number: "534"},
    {code: "SK", code3: "SVK", name: "Slovakia", number: "703"},
    {code: "SI", code3: "SVN", name: "Slovenia", number: "705"},
    {code: "SB", code3: "SLB", name: "Solomon Islands", number: "090"},
    {code: "SO", code3: "SOM", name: "Somalia", number: "706"},
    {code: "ZA", code3: "ZAF", name: "South Africa", number: "710"},
    {code: "GS", code3: "SGS", name: "South Georgia and the South Sandwich Islands", number: "239"},
    {code: "SS", code3: "SSD", name: "South Sudan", number: "728"},
    {code: "ES", code3: "ESP", name: "Spain", number: "724"},
    {code: "LK", code3: "LKA", name: "Sri Lanka", number: "144"},
    {code: "SD", code3: "SDN", name: "Sudan (the)", number: "729"},
    {code: "SR", code3: "SUR", name: "Suriname", number: "740"},
    {code: "SJ", code3: "SJM", name: "Svalbard and Jan Mayen", number: "744"},
    {code: "SE", code3: "SWE", name: "Sweden", number: "752"},
    {code: "CH", code3: "CHE", name: "Switzerland", number: "756"},
    {code: "SY", code3: "SYR", name: "Syrian Arab Republic", number: "760"},
    {code: "TW", code3: "TWN", name: "Taiwan", number: "158"},
    {code: "TJ", code3: "TJK", name: "Tajikistan", number: "762"},
    {code: "TZ", code3: "TZA", name: "Tanzania, United Republic of", number: "834"},
    {code: "TH", code3: "THA", name: "Thailand", number: "764"},
    {code: "TL", code3: "TLS", name: "Timor-Leste", number: "626"},
    {code: "TG", code3: "TGO", name: "Togo", number: "768"},
    {code: "TK", code3: "TKL", name: "Tokelau", number: "772"},
    {code: "TO", code3: "TON", name: "Tonga", number: "776"},
    {code: "TT", code3: "TTO", name: "Trinidad and Tobago", number: "780"},
    {code: "TN", code3: "TUN", name: "Tunisia", number: "788"},
    {code: "TR", code3: "TUR", name: "Turkey", number: "792"},
    {code: "TM", code3: "TKM", name: "Turkmenistan", number: "795"},
    {code: "TC", code3: "TCA", name: "Turks and Caicos Islands (the)", number: "796"},
    {code: "TV", code3: "TUV", name: "Tuvalu", number: "798"},
    {code: "UG", code3: "UGA", name: "Uganda", number: "800"},
    {code: "UA", code3: "UKR", name: "Ukraine", number: "804"},
    {code: "AE", code3: "ARE", name: "United Arab Emirates (the)", number: "784"},
    {code: "GB", code3: "GBR", name: "United Kingdom of Great Britain and Northern Ireland (the)", number: "826"},
    {code: "UM", code3: "UMI", name: "United States Minor Outlying Islands (the)", number: "581"},
    {code: "US", code3: "USA", name: "United States of America (the)", number: "840"},
    {code: "UY", code3: "URY", name: "Uruguay", number: "858"},
    {code: "UZ", code3: "UZB", name: "Uzbekistan", number: "860"},
    {code: "VU", code3: "VUT", name: "Vanuatu", number: "548"},
    {code: "VE", code3: "VEN", name: "Venezuela (Bolivarian Republic of)", number: "862"},
    {code: "VN", code3: "VNM", name: "Viet Nam", number: "704"},
    {code: "VG", code3: "VGB", name: "Virgin Islands (British)", number: "092"},
    {code: "VI", code3: "VIR", name: "Virgin Islands (U.S.)", number: "850"},
    {code: "WF", code3: "WLF", name: "Wallis and Futuna", number: "876"},
    {code: "EH", code3: "ESH", name: "Western Sahara", number: "732"},
    {code: "YE", code3: "YEM", name: "Yemen", number: "887"},
    {code: "ZM", code3: "ZMB", name: "Zambia", number: "894"},
    {code: "ZW", code3: "ZWE", name: "Zimbabwe", number: "716"},
    {code: "AX", code3: "ALA", name: "Åland Islands", number: "248"}
  ];

  timeZones = [
    {name: "International Date Line West", gmt: "-12:00"},
    {name: "Coordinated Universal Time-11", gmt: "-11:00"},
    {name: "Midway", gmt: "-11:00"},
    {name: "Niue", gmt: "-11:00"},
    {name: "Pago_Pago", gmt: "-11:00"},
    {name: "Samoa", gmt: "-11:00"},
    {name: "Adak", gmt: "-10:00"},
    {name: "Rarotonga", gmt: "-10:00"},
    {name: "Tahiti", gmt: "-10:00"},
    {name: "Hawaii", gmt: "-10:00"},
    {name: "Marquesas Islands", gmt: "-09:30"},
    {name: "Alaska", gmt: "-09:00"},
    {name: "Gambier", gmt: "-09:00"},
    {name: "Juneau", gmt: "-08:00"},
    {name: "Nome", gmt: "-08:00"},
    {name: "Metlakatla", gmt: "-08:00"},
    {name: "Sitka", gmt: "-08:00"},
    {name: "Yakutat", gmt: "-08:00"},
    {name: "Baja California", gmt: "-08:00"},
    {name: "Pacific Time (US & Canada)", gmt: "-08:00"},
    {name: "Pitcairn", gmt: "-08:00"},
    {name: "Creston", gmt: "-07:00"},
    {name: "Chihuahua, Mazatlan", gmt: "-07:00"},
    {name: "Dawson", gmt: "-07:00"},
    {name: "Dawson Creek", gmt: "-07:00"},
    {name: "Mountain Time (US & Canada)", gmt: "-07:00"},
    {name: "Ensenada", gmt: "-07:00"},
    {name: "Fort Nelson", gmt: "-07:00"},
    {name: "Hermosillo", gmt: "-07:00"},
    {name: "Vancouver", gmt: "-07:00"},
    {name: "Whitehorse", gmt: "-07:00"},
    {name: "Arizona", gmt: "-07:00"},
    {name: "Bahia Banderas", gmt: "-06:00"},
    {name: "Belize", gmt: "-06:00"},
    {name: "Boise", gmt: "-06:00"},
    {name: "Costa Rica", gmt: "-06:00"},
    {name: "Central Time (US & Canada)", gmt: "-06:00"},
    {name: "Edmonton", gmt: "-06:00"},
    {name: "El Salvador", gmt: "-06:00"},
    {name: "Central America", gmt: "-06:00"},
    {name: "Managua", gmt: "-06:00"},
    {name: "Mazatlan", gmt: "-06:00"},
    {name: "Ojinaga", gmt: "-06:00"},
    {name: "Swift Current", gmt: "-06:00"},
    {name: "Tegucigalpa", gmt: "-06:00"},
    {name: "Guadalajara, Mexico City, Monterrey", gmt: "-06:00"},
    {name: "Saskatchewan", gmt: "-06:00"},
    {name: "Galapagos", gmt: "-06:00"},
    {name: "Atikokan", gmt: "-05:00"},
    {name: "Bogota, Lima, Quito", gmt: "-05:00"},
    {name: "Cancun", gmt: "-05:00"},
    {name: "Cayman", gmt: "-05:00"},
    {name: "Coral Harbour", gmt: "-05:00"},
    {name: "Eirunepe", gmt: "-05:00"},
    {name: "Guayaquil", gmt: "-05:00"},
    {name: "Indiana (East)", gmt: "-05:00"},
    {name: "Jamaica", gmt: "-05:00"},
    {name: "Lima", gmt: "-05:00"},
    {name: "Matamoros", gmt: "-05:00"},
    {name: "Menominee", gmt: "-05:00"},
    {name: "Merida", gmt: "-05:00"},
    {name: "Monterrey", gmt: "-05:00"},
    {name: "Nipigon", gmt: "-05:00"},
    {name: "Panama", gmt: "-05:00"},
    {name: "Rainy River", gmt: "-05:00"},
    {name: "Rio Branco", gmt: "-05:00"},
    {name: "Thunder Bay", gmt: "-05:00"},
    {name: "Winnipeg", gmt: "-05:00"},
    {name: "Eastern Time (US & Canada)", gmt: "-05:00"},
    {name: "Easter", gmt: "-05:00"},
    {name: "Caracas", gmt: "-04:30"},
    {name: "Anguilla", gmt: "-04:00"},
    {name: "Antigua", gmt: "-04:00"},
    {name: "Aruba", gmt: "-04:00"},
    {name: "Asuncion", gmt: "-04:00"},
    {name: "Barbados", gmt: "-04:00"},
    {name: "Boa Vista", gmt: "-04:00"},
    {name: "Campo Grande", gmt: "-04:00"},
    {name: "Curacao", gmt: "-04:00"},
    {name: "Cuiaba", gmt: "-04:00"},
    {name: "Detroit", gmt: "-04:00"},
    {name: "Dominica", gmt: "-04:00"},
    {name: "Grand Turk", gmt: "-04:00"},
    {name: "Grenada", gmt: "-04:00"},
    {name: "Guadeloupe", gmt: "-04:00"},
    {name: "Guyana", gmt: "-04:00"},
    {name: "Atlantic Time (Canada)", gmt: "-04:00"},
    {name: "Havana", gmt: "-04:00"},
    {name: "Georgetown, La Paz, Manaus, San Juan", gmt: "-04:00"},
    {name: "Manaus", gmt: "-04:00"},
    {name: "Martinique", gmt: "-04:00"},
    {name: "Montreal", gmt: "-04:00"},
    {name: "Montserrat", gmt: "-04:00"},
    {name: "Nassau", gmt: "-04:00"},
    {name: "Port of Spain", gmt: "-04:00"},
    {name: "Porto Velho", gmt: "-04:00"},
    {name: "Puerto Rico", gmt: "-04:00"},
    {name: "Santo Domingo", gmt: "-04:00"},
    {name: "St. Kitts", gmt: "-04:00"},
    {name: "St. Lucia", gmt: "-04:00"},
    {name: "St. Thomas", gmt: "-04:00"},
    {name: "St. Vincent", gmt: "-04:00"},
    {name: "Toronto", gmt: "-04:00"},
    {name: "Tortola", gmt: "-04:00"},
    {name: "Santiago", gmt: "-04:00"},
    {name: "Newfoundland", gmt: "-03:30"},
    {name: "Araguaina", gmt: "-03:00"},
    {name: "Bahia", gmt: "-03:00"},
    {name: "Belem", gmt: "-03:00"},
    {name: "Buenos Aires", gmt: "-03:00"},
    {name: "Cayenne, Fortaleza", gmt: "-03:00"},
    {name: "Fortaleza", gmt: "-03:00"},
    {name: "Glace Bay", gmt: "-03:00"},
    {name: "Goose Bay", gmt: "-03:00"},
    {name: "Greenland", gmt: "-03:00"},
    {name: "Maceio", gmt: "-03:00"},
    {name: "Moncton", gmt: "-03:00"},
    {name: "Paramaribo", gmt: "-03:00"},
    {name: "Punta Arenas", gmt: "-03:00"},
    {name: "Recife", gmt: "-03:00"},
    {name: "Rosario", gmt: "-03:00"},
    {name: "Santarem", gmt: "-03:00"},
    {name: "Thule", gmt: "-03:00"},
    {name: "Montevideo", gmt: "-03:00"},
    {name: "Brasilia", gmt: "-03:00"},
    {name: "Rothera", gmt: "-03:00"},
    {name: "Bermuda", gmt: "-03:00"},
    {name: "Stanley", gmt: "-03:00"},
    {name: "Miquelon", gmt: "-02:00"},
    {name: "Noronha", gmt: "-02:00"},
    {name: "South Georgia", gmt: "-02:00"},
    {name: "Coordinated Universal Time-02", gmt: "-02:00"},
    {name: "Azores", gmt: "-01:00"},
    {name: "Cape Verde", gmt: "-01:00"},
    {name: "Default", gmt: "+00:00"},
    {name: "Danmarkshavn", gmt: "+00:00"},
    {name: "Scoresbysund", gmt: "+00:00"},
    {name: "Accra", gmt: "+00:00"},
    {name: "Abidjan", gmt: "+00:00"},
    {name: "Bamako", gmt: "+00:00"},
    {name: "Banjul", gmt: "+00:00"},
    {name: "Bissau", gmt: "+00:00"},
    {name: "Conakry", gmt: "+00:00"},
    {name: "Dakar", gmt: "+00:00"},
    {name: "El Aaiun", gmt: "+00:00"},
    {name: "Freetown", gmt: "+00:00"},
    {name: "Lome", gmt: "+00:00"},
    {name: "Monrovia", gmt: "+00:00"},
    {name: "Nouakchott", gmt: "+00:00"},
    {name: "Ouagadougou", gmt: "+00:00"},
    {name: "Sao Tome", gmt: "+00:00"},
    {name: "Timbuktu", gmt: "+00:00"},
    {name: "Troll", gmt: "+00:00"},
    {name: "St. Helena", gmt: "+00:00"},
    {name: "Monrovia, Reykjavik", gmt: "+00:00"},
    {name: "Greenwich Mean Time, Dublin, Edinburgh, Lisbon, London", gmt: "+00:00"},
    {name: "Bangui", gmt: "+01:00"},
    {name: "Casablanca", gmt: "+01:00"},
    {name: "Algiers", gmt: "+01:00"},
    {name: "Brazzaville", gmt: "+01:00"},
    {name: "Douala", gmt: "+01:00"},
    {name: "Kinshasa", gmt: "+01:00"},
    {name: "Libreville", gmt: "+01:00"},
    {name: "Luanda", gmt: "+01:00"},
    {name: "Malabo", gmt: "+01:00"},
    {name: "Ndjamena", gmt: "+01:00"},
    {name: "Niamey", gmt: "+01:00"},
    {name: "Tunis", gmt: "+01:00"},
    {name: "West Central Africa", gmt: "+01:00"},
    {name: "Canary", gmt: "+01:00"},
    {name: "Faroe", gmt: "+01:00"},
    {name: "Madeira", gmt: "+01:00"},
    {name: "Belfast", gmt: "+01:00"},
    {name: "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna", gmt: "+01:00"},
    {name: "Belgrade, Bratislava, Budapest, Ljubljana, Prague", gmt: "+01:00"},
    {name: "Dublin", gmt: "+01:00"},
    {name: "Guernsey", gmt: "+01:00"},
    {name: "Isle of Man", gmt: "+01:00"},
    {name: "Jersey", gmt: "+01:00"},
    {name: "Lisbon", gmt: "+01:00"},
    {name: "Brussels, Copenhagen, Madrid, Paris", gmt: "+01:00"},
    {name: "Sarajevo, Skopje, Warsaw, Zagreb", gmt: "+01:00"},
    {name: "Blantyre", gmt: "+02:00"},
    {name: "Bujumbura", gmt: "+02:00"},
    {name: "Cairo", gmt: "+02:00"},
    {name: "Ceuta", gmt: "+02:00"},
    {name: "Johannesburg", gmt: "+02:00"},
    {name: "Gaborone", gmt: "+02:00"},
    {name: "Harare", gmt: "+02:00"},
    {name: "Khartoum", gmt: "+02:00"},
    {name: "Kigali", gmt: "+02:00"},
    {name: "Lubumbashi", gmt: "+02:00"},
    {name: "Lusaka", gmt: "+02:00"},
    {name: "Maputo", gmt: "+02:00"},
    {name: "Maseru", gmt: "+02:00"},
    {name: "Mbabane", gmt: "+02:00"},
    {name: "Tripoli", gmt: "+02:00"},
    {name: "Windhoek", gmt: "+02:00"},
    {name: "Amman", gmt: "+02:00"},
    {name: "Beirut", gmt: "+02:00"},
    {name: "Damascus", gmt: "+02:00"},
    {name: "Jerusalem", gmt: "+02:00"},
    {name: "Amsterdam", gmt: "+02:00"},
    {name: "Andorra", gmt: "+02:00"},
    {name: "Belgrade", gmt: "+02:00"},
    {name: "Brussels", gmt: "+02:00"},
    {name: "Copenhagen", gmt: "+02:00"},
    {name: "Gibraltar", gmt: "+02:00"},
    {name: "Athens, Bucharest, Istanbul", gmt: "+02:00"},
    {name: "Kaliningrad", gmt: "+02:00"},
    {name: "Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius", gmt: "+02:00"},
    {name: "Ljubljana", gmt: "+02:00"},
    {name: "Luxembourg", gmt: "+02:00"},
    {name: "Madrid", gmt: "+02:00"},
    {name: "Malta", gmt: "+02:00"},
    {name: "Monaco", gmt: "+02:00"},
    {name: "Minsk", gmt: "+02:00"},
    {name: "Oslo", gmt: "+02:00"},
    {name: "Prague", gmt: "+02:00"},
    {name: "Rome", gmt: "+02:00"},
    {name: "Sarajevo", gmt: "+02:00"},
    {name: "Skopje", gmt: "+02:00"},
    {name: "Stockholm", gmt: "+02:00"},
    {name: "Tirane", gmt: "+02:00"},
    {name: "Vaduz", gmt: "+02:00"},
    {name: "Vienna", gmt: "+02:00"},
    {name: "Zagreb", gmt: "+02:00"},
    {name: "Zurich", gmt: "+02:00"},
    {name: "Addis Ababa", gmt: "+03:00"},
    {name: "Asmara", gmt: "+03:00"},
    {name: "Dar es Salaam", gmt: "+03:00"},
    {name: "Djibouti", gmt: "+03:00"},
    {name: "Juba", gmt: "+03:00"},
    {name: "Kampala", gmt: "+03:00"},
    {name: "Mogadishu", gmt: "+03:00"},
    {name: "Nairobi", gmt: "+03:00"},
    {name: "Aden", gmt: "+03:00"},
    {name: "Bahrain", gmt: "+03:00"},
    {name: "Baghdad", gmt: "+03:00"},
    {name: "Famagusta", gmt: "+03:00"},
    {name: "Gaza", gmt: "+03:00"},
    {name: "Hebron", gmt: "+03:00"},
    {name: "Kuwait", gmt: "+03:00"},
    {name: "Nicosia", gmt: "+03:00"},
    {name: "Qatar", gmt: "+03:00"},
    {name: "Tel Aviv", gmt: "+03:00"},
    {name: "Kuwait, Riyadh", gmt: "+03:00"},
    {name: "Athens", gmt: "+03:00"},
    {name: "Bucharest", gmt: "+03:00"},
    {name: "Chisinau", gmt: "+03:00"},
    {name: "Helsinki", gmt: "+03:00"},
    {name: "Kirov", gmt: "+03:00"},
    {name: "Moscow, St. Petersburg, Volgograd", gmt: "+03:00"},
    {name: "Riga", gmt: "+03:00"},
    {name: "Simferopol", gmt: "+03:00"},
    {name: "Sofia", gmt: "+03:00"},
    {name: "Tallinn", gmt: "+03:00"},
    {name: "Tiraspol", gmt: "+03:00"},
    {name: "Uzhgorod", gmt: "+03:00"},
    {name: "Vilnius", gmt: "+03:00"},
    {name: "Volgograd", gmt: "+03:00"},
    {name: "Zaporozhye", gmt: "+03:00"},
    {name: "Antananarivo", gmt: "+03:00"},
    {name: "Comoro", gmt: "+03:00"},
    {name: "Mayotte", gmt: "+03:00"},
    {name: "Tehran", gmt: "+03:30"},
    {name: "Baku", gmt: "+04:00"},
    {name: "Muscat", gmt: "+04:00"},
    {name: "Abu Dhabi, Muscat", gmt: "+04:00"},
    {name: "Tbilisi", gmt: "+04:00"},
    {name: "Yerevan", gmt: "+04:00"},
    {name: "Astrakhan", gmt: "+04:00"},
    {name: "Samara", gmt: "+04:00"},
    {name: "Saratov", gmt: "+04:00"},
    {name: "Ulyanovsk", gmt: "+04:00"},
    {name: "Mahe", gmt: "+04:00"},
    {name: "Port Louis", gmt: "+04:00"},
    {name: "Reunion", gmt: "+04:00"},
    {name: "Kabul", gmt: "+04:30"},
    {name: "Mawson", gmt: "+05:00"},
    {name: "Aqtau", gmt: "+05:00"},
    {name: "Aqtobe", gmt: "+05:00"},
    {name: "Ashgabat", gmt: "+05:00"},
    {name: "Atyrau", gmt: "+05:00"},
    {name: "Dushanbe", gmt: "+05:00"},
    {name: "Oral", gmt: "+05:00"},
    {name: "Samarkand", gmt: "+05:00"},
    {name: "Islamabad, Karachi", gmt: "+05:00"},
    {name: "Tashkent", gmt: "+05:00"},
    {name: "Yekaterinburg", gmt: "+05:00"},
    {name: "Maldives", gmt: "+05:00"},
    {name: "Chennai, Kolkata, Mumbai, New Delhi", gmt: "+05:30"},
    {name: "Sri Jayawardenepura", gmt: "+05:30"},
    {name: "Kolkata", gmt: "+05:30"},
    {name: "Kathmandu", gmt: "+05:45"},
    {name: "Astana", gmt: "+06:00"},
    {name: "Bishkek", gmt: "+06:00"},
    {name: "Kashgar", gmt: "+06:00"},
    {name: "Omsk", gmt: "+06:00"},
    {name: "Qyzylorda", gmt: "+06:00"},
    {name: "Thimphu", gmt: "+06:00"},
    {name: "Urumqi", gmt: "+06:00"},
    {name: "Dhaka", gmt: "+06:00"},
    {name: "Novosibirsk", gmt: "+06:00"},
    {name: "Chagos", gmt: "+06:00"},
    {name: "Yangon", gmt: "+06:30"},
    {name: "Yangon (Rangoon)", gmt: "+06:30"},
    {name: "Cocos", gmt: "+06:30"},
    {name: "Davis", gmt: "+07:00"},
    {name: "Barnaul", gmt: "+07:00"},
    {name: "Bangkok, Hanoi, Jakarta", gmt: "+07:00"},
    {name: "Ho Chi Minh", gmt: "+07:00"},
    {name: "Hovd", gmt: "+07:00"},
    {name: "Jakarta", gmt: "+07:00"},
    {name: "Novokuznetsk", gmt: "+07:00"},
    {name: "Phnom Penh", gmt: "+07:00"},
    {name: "Pontianak", gmt: "+07:00"},
    {name: "Tomsk", gmt: "+07:00"},
    {name: "Vientiane", gmt: "+07:00"},
    {name: "Krasnoyarsk", gmt: "+07:00"},
    {name: "Christmas", gmt: "+07:00"},
    {name: "Casey", gmt: "+08:00"},
    {name: "Brunei", gmt: "+08:00"},
    {name: "Choibalsan", gmt: "+08:00"},
    {name: "Chongqing", gmt: "+08:00"},
    {name: "Harbin", gmt: "+08:00"},
    {name: "Hong Kong", gmt: "+08:00"},
    {name: "Kuala Lumpur", gmt: "+08:00"},
    {name: "Kuching", gmt: "+08:00"},
    {name: "Macau", gmt: "+08:00"},
    {name: "Makassar", gmt: "+08:00"},
    {name: "Manila", gmt: "+08:00"},
    {name: "Irkutsk", gmt: "+08:00"},
    {name: "Beijing, Chongqing, Hong Kong, Urumqi", gmt: "+08:00"},
    {name: "Kuala Lumpur, Singapore", gmt: "+08:00"},
    {name: "Taipei", gmt: "+08:00"},
    {name: "Ulaanbaatar", gmt: "+08:00"},
    {name: "Perth", gmt: "+08:00"},
    {name: "Pyongyang", gmt: "+08:30"},
    {name: "Eucla", gmt: "+08:30"},
    {name: "Chita", gmt: "+09:00"},
    {name: "Dili", gmt: "+09:00"},
    {name: "Jayapura", gmt: "+09:00"},
    {name: "Khandyga", gmt: "+09:00"},
    {name: "Osaka, Sapporo, Tokyo", gmt: "+09:00"},
    {name: "Seoul", gmt: "+09:00"},
    {name: "Yakutsk", gmt: "+09:00"},
    {name: "Palau", gmt: "+09:00"},
    {name: "Adelaide", gmt: "+09:30"},
    {name: "Broken Hill", gmt: "+09:30"},
    {name: "Darwin", gmt: "+09:30"},
    {name: "Brisbane", gmt: "+10:00"},
    {name: "Vladivostok", gmt: "+10:00"},
    {name: "Hobart", gmt: "+10:00"},
    {name: "Lindeman", gmt: "+10:00"},
    {name: "Chuuk", gmt: "+10:00"},
    {name: "Saipan", gmt: "+10:00"},
    {name: "Guam, Port Moresby", gmt: "+10:00"},
    {name: "Lord Howe Island", gmt: "+10:30"},
    {name: "Lord Howe Island", gmt: "+10:30"},
    {name: "Sakhalin", gmt: "+11:00"},
    {name: "Srednekolymsk", gmt: "+11:00"},
    {name: "Magadan, Solomon Is., New Caledonia", gmt: "+11:00"},
    {name: "Currie", gmt: "+11:00"},
    {name: "Melbourne", gmt: "+11:00"},
    {name: "Sydney", gmt: "+11:00"},
    {name: "Efate", gmt: "+11:00"},
    {name: "Bougainville", gmt: "+11:00"},
    {name: "Guadalcanal", gmt: "+11:00"},
    {name: "Kosrae", gmt: "+11:00"},
    {name: "Norfolk", gmt: "+11:00"},
    {name: "Noumea", gmt: "+11:00"},
    {name: "Pohnpei", gmt: "+11:00"},
    {name: "Anadyr", gmt: "+12:00"},
    {name: "Petropavlovsk-Kamchatsky", gmt: "+12:00"},
    {name: "Coordinated Universal Time+12", gmt: "+12:00"},
    {name: "Funafuti", gmt: "+12:00"},
    {name: "Kwajalein", gmt: "+12:00"},
    {name: "Majuro", gmt: "+12:00"},
    {name: "Nauru", gmt: "+12:00"},
    {name: "Tarawa", gmt: "+12:00"},
    {name: "Wake", gmt: "+12:00"},
    {name: "Wallis", gmt: "+12:00"},
    {name: "Auckland, Wellington", gmt: "+12:00"},
    {name: "Fiji", gmt: "+12:00"},
    {name: "Chatham Islands", gmt: "+12:45"},
    {name: "Phoenix Islands, Tokelau, Tonga", gmt: "+13:00"},
    {name: "Fakaofo", gmt: "+13:00"},
    {name: "Tongatapu", gmt: "+13:00"},
    {name: "Apia", gmt: "+14:00"},
    {name: "Line Islands", gmt: "+14:00"},
  ]
  currencies = [
    'USD - United States Dollar',
    'CAD - Canadian Dollar',
    'EUR - Euro',
    'MXN - Mexican Peso',
    'JPY - Japanese Yen',
    'GBP - British Pound',
    'AUD - Australian Dollar',
    'CHF - Swiss Franc',
    'CNY - Chinese Yuan',
    'SEK - Swedish Krona',
    'NZD - New Zealand Dollar',
    'SGD - Singapore Dollar',
    'HKD - Hong Kong Dollar',
    'NOK - Norwegian Krone',
    'KRW - South Korean Won',
    'TRY - Turkish Lira',
    'RUB - Russian Ruble',
    'INR - Indian Rupee',
    'BRL - Brazilian Real',
  ]
  countriesFlags = [
    {
      "name": "Afghanistan",
      "flag": "🇦🇫",
      "code": "AF",
      "dial_code": "+93"
    },
    {
      "name": "Åland Islands",
      "flag": "🇦🇽",
      "code": "AX",
      "dial_code": "+358"
    },
    {
      "name": "Albania",
      "flag": "🇦🇱",
      "code": "AL",
      "dial_code": "+355"
    },
    {
      "name": "Algeria",
      "flag": "🇩🇿",
      "code": "DZ",
      "dial_code": "+213"
    },
    {
      "name": "American Samoa",
      "flag": "🇦🇸",
      "code": "AS",
      "dial_code": "+1684"
    },
    {
      "name": "Andorra",
      "flag": "🇦🇩",
      "code": "AD",
      "dial_code": "+376"
    },
    {
      "name": "Angola",
      "flag": "🇦🇴",
      "code": "AO",
      "dial_code": "+244"
    },
    {
      "name": "Anguilla",
      "flag": "🇦🇮",
      "code": "AI",
      "dial_code": "+1264"
    },
    {
      "name": "Antarctica",
      "flag": "🇦🇶",
      "code": "AQ",
      "dial_code": "+672"
    },
    {
      "name": "Antigua and Barbuda",
      "flag": "🇦🇬",
      "code": "AG",
      "dial_code": "+1268"
    },
    {
      "name": "Argentina",
      "flag": "🇦🇷",
      "code": "AR",
      "dial_code": "+54"
    },
    {
      "name": "Armenia",
      "flag": "🇦🇲",
      "code": "AM",
      "dial_code": "+374"
    },
    {
      "name": "Aruba",
      "flag": "🇦🇼",
      "code": "AW",
      "dial_code": "+297"
    },
    {
      "name": "Australia",
      "flag": "🇦🇺",
      "code": "AU",
      "dial_code": "+61"
    },
    {
      "name": "Austria",
      "flag": "🇦🇹",
      "code": "AT",
      "dial_code": "+43"
    },
    {
      "name": "Azerbaijan",
      "flag": "🇦🇿",
      "code": "AZ",
      "dial_code": "+994"
    },
    {
      "name": "Bahamas",
      "flag": "🇧🇸",
      "code": "BS",
      "dial_code": "+1242"
    },
    {
      "name": "Bahrain",
      "flag": "🇧🇭",
      "code": "BH",
      "dial_code": "+973"
    },
    {
      "name": "Bangladesh",
      "flag": "🇧🇩",
      "code": "BD",
      "dial_code": "+880"
    },
    {
      "name": "Barbados",
      "flag": "🇧🇧",
      "code": "BB",
      "dial_code": "+1246"
    },
    {
      "name": "Belarus",
      "flag": "🇧🇾",
      "code": "BY",
      "dial_code": "+375"
    },
    {
      "name": "Belgium",
      "flag": "🇧🇪",
      "code": "BE",
      "dial_code": "+32"
    },
    {
      "name": "Belize",
      "flag": "🇧🇿",
      "code": "BZ",
      "dial_code": "+501"
    },
    {
      "name": "Benin",
      "flag": "🇧🇯",
      "code": "BJ",
      "dial_code": "+229"
    },
    {
      "name": "Bermuda",
      "flag": "🇧🇲",
      "code": "BM",
      "dial_code": "+1441"
    },
    {
      "name": "Bhutan",
      "flag": "🇧🇹",
      "code": "BT",
      "dial_code": "+975"
    },
    {
      "name": "Bolivia, Plurinational State of bolivia",
      "flag": "🇧🇴",
      "code": "BO",
      "dial_code": "+591"
    },
    {
      "name": "Bosnia and Herzegovina",
      "flag": "🇧🇦",
      "code": "BA",
      "dial_code": "+387"
    },
    {
      "name": "Botswana",
      "flag": "🇧🇼",
      "code": "BW",
      "dial_code": "+267"
    },
    {
      "name": "Bouvet Island",
      "flag": "🇧🇻",
      "code": "BV",
      "dial_code": "+47"
    },
    {
      "name": "Brazil",
      "flag": "🇧🇷",
      "code": "BR",
      "dial_code": "+55"
    },
    {
      "name": "British Indian Ocean Territory",
      "flag": "🇮🇴",
      "code": "IO",
      "dial_code": "+246"
    },
    {
      "name": "Brunei Darussalam",
      "flag": "🇧🇳",
      "code": "BN",
      "dial_code": "+673"
    },
    {
      "name": "Bulgaria",
      "flag": "🇧🇬",
      "code": "BG",
      "dial_code": "+359"
    },
    {
      "name": "Burkina Faso",
      "flag": "🇧🇫",
      "code": "BF",
      "dial_code": "+226"
    },
    {
      "name": "Burundi",
      "flag": "🇧🇮",
      "code": "BI",
      "dial_code": "+257"
    },
    {
      "name": "Cambodia",
      "flag": "🇰🇭",
      "code": "KH",
      "dial_code": "+855"
    },
    {
      "name": "Cameroon",
      "flag": "🇨🇲",
      "code": "CM",
      "dial_code": "+237"
    },
    {
      "name": "Canada",
      "flag": "🇨🇦",
      "code": "CA",
      "dial_code": "+1"
    },
    {
      "name": "Cape Verde",
      "flag": "🇨🇻",
      "code": "CV",
      "dial_code": "+238"
    },
    {
      "name": "Cayman Islands",
      "flag": "🇰🇾",
      "code": "KY",
      "dial_code": "+345"
    },
    {
      "name": "Central African Republic",
      "flag": "🇨🇫",
      "code": "CF",
      "dial_code": "+236"
    },
    {
      "name": "Chad",
      "flag": "🇹🇩",
      "code": "TD",
      "dial_code": "+235"
    },
    {
      "name": "Chile",
      "flag": "🇨🇱",
      "code": "CL",
      "dial_code": "+56"
    },
    {
      "name": "China",
      "flag": "🇨🇳",
      "code": "CN",
      "dial_code": "+86"
    },
    {
      "name": "Christmas Island",
      "flag": "🇨🇽",
      "code": "CX",
      "dial_code": "+61"
    },
    {
      "name": "Cocos (Keeling) Islands",
      "flag": "🇨🇨",
      "code": "CC",
      "dial_code": "+61"
    },
    {
      "name": "Colombia",
      "flag": "🇨🇴",
      "code": "CO",
      "dial_code": "+57"
    },
    {
      "name": "Comoros",
      "flag": "🇰🇲",
      "code": "KM",
      "dial_code": "+269"
    },
    {
      "name": "Congo",
      "flag": "🇨🇬",
      "code": "CG",
      "dial_code": "+242"
    },
    {
      "name": "Congo, The Democratic Republic of the Congo",
      "flag": "🇨🇩",
      "code": "CD",
      "dial_code": "+243"
    },
    {
      "name": "Cook Islands",
      "flag": "🇨🇰",
      "code": "CK",
      "dial_code": "+682"
    },
    {
      "name": "Costa Rica",
      "flag": "🇨🇷",
      "code": "CR",
      "dial_code": "+506"
    },
    {
      "name": "Cote d'Ivoire",
      "flag": "🇨🇮",
      "code": "CI",
      "dial_code": "+225"
    },
    {
      "name": "Croatia",
      "flag": "🇭🇷",
      "code": "HR",
      "dial_code": "+385"
    },
    {
      "name": "Cuba",
      "flag": "🇨🇺",
      "code": "CU",
      "dial_code": "+53"
    },
    {
      "name": "Cyprus",
      "flag": "🇨🇾",
      "code": "CY",
      "dial_code": "+357"
    },
    {
      "name": "Czech Republic",
      "flag": "🇨🇿",
      "code": "CZ",
      "dial_code": "+420"
    },
    {
      "name": "Denmark",
      "flag": "🇩🇰",
      "code": "DK",
      "dial_code": "+45"
    },
    {
      "name": "Djibouti",
      "flag": "🇩🇯",
      "code": "DJ",
      "dial_code": "+253"
    },
    {
      "name": "Dominica",
      "flag": "🇩🇲",
      "code": "DM",
      "dial_code": "+1767"
    },
    {
      "name": "Dominican Republic",
      "flag": "🇩🇴",
      "code": "DO",
      "dial_code": "+1849"
    },
    {
      "name": "Ecuador",
      "flag": "🇪🇨",
      "code": "EC",
      "dial_code": "+593"
    },
    {
      "name": "Egypt",
      "flag": "🇪🇬",
      "code": "EG",
      "dial_code": "+20"
    },
    {
      "name": "El Salvador",
      "flag": "🇸🇻",
      "code": "SV",
      "dial_code": "+503"
    },
    {
      "name": "Equatorial Guinea",
      "flag": "🇬🇶",
      "code": "GQ",
      "dial_code": "+240"
    },
    {
      "name": "Eritrea",
      "flag": "🇪🇷",
      "code": "ER",
      "dial_code": "+291"
    },
    {
      "name": "Estonia",
      "flag": "🇪🇪",
      "code": "EE",
      "dial_code": "+372"
    },
    {
      "name": "Ethiopia",
      "flag": "🇪🇹",
      "code": "ET",
      "dial_code": "+251"
    },
    {
      "name": "Falkland Islands (Malvinas)",
      "flag": "🇫🇰",
      "code": "FK",
      "dial_code": "+500"
    },
    {
      "name": "Faroe Islands",
      "flag": "🇫🇴",
      "code": "FO",
      "dial_code": "+298"
    },
    {
      "name": "Fiji",
      "flag": "🇫🇯",
      "code": "FJ",
      "dial_code": "+679"
    },
    {
      "name": "Finland",
      "flag": "🇫🇮",
      "code": "FI",
      "dial_code": "+358"
    },
    {
      "name": "France",
      "flag": "🇫🇷",
      "code": "FR",
      "dial_code": "+33"
    },
    {
      "name": "French Guiana",
      "flag": "🇬🇫",
      "code": "GF",
      "dial_code": "+594"
    },
    {
      "name": "French Polynesia",
      "flag": "🇵🇫",
      "code": "PF",
      "dial_code": "+689"
    },
    {
      "name": "French Southern Territories",
      "flag": "🇹🇫",
      "code": "TF",
      "dial_code": "+262"
    },
    {
      "name": "Gabon",
      "flag": "🇬🇦",
      "code": "GA",
      "dial_code": "+241"
    },
    {
      "name": "Gambia",
      "flag": "🇬🇲",
      "code": "GM",
      "dial_code": "+220"
    },
    {
      "name": "Georgia",
      "flag": "🇬🇪",
      "code": "GE",
      "dial_code": "+995"
    },
    {
      "name": "Germany",
      "flag": "🇩🇪",
      "code": "DE",
      "dial_code": "+49"
    },
    {
      "name": "Ghana",
      "flag": "🇬🇭",
      "code": "GH",
      "dial_code": "+233"
    },
    {
      "name": "Gibraltar",
      "flag": "🇬🇮",
      "code": "GI",
      "dial_code": "+350"
    },
    {
      "name": "Greece",
      "flag": "🇬🇷",
      "code": "GR",
      "dial_code": "+30"
    },
    {
      "name": "Greenland",
      "flag": "🇬🇱",
      "code": "GL",
      "dial_code": "+299"
    },
    {
      "name": "Grenada",
      "flag": "🇬🇩",
      "code": "GD",
      "dial_code": "+1473"
    },
    {
      "name": "Guadeloupe",
      "flag": "🇬🇵",
      "code": "GP",
      "dial_code": "+590"
    },
    {
      "name": "Guam",
      "flag": "🇬🇺",
      "code": "GU",
      "dial_code": "+1671"
    },
    {
      "name": "Guatemala",
      "flag": "🇬🇹",
      "code": "GT",
      "dial_code": "+502"
    },
    {
      "name": "Guernsey",
      "flag": "🇬🇬",
      "code": "GG",
      "dial_code": "+44"
    },
    {
      "name": "Guinea",
      "flag": "🇬🇳",
      "code": "GN",
      "dial_code": "+224"
    },
    {
      "name": "Guinea-Bissau",
      "flag": "🇬🇼",
      "code": "GW",
      "dial_code": "+245"
    },
    {
      "name": "Guyana",
      "flag": "🇬🇾",
      "code": "GY",
      "dial_code": "+592"
    },
    {
      "name": "Haiti",
      "flag": "🇭🇹",
      "code": "HT",
      "dial_code": "+509"
    },
    {
      "name": "Heard Island and Mcdonald Islands",
      "flag": "🇭🇲",
      "code": "HM",
      "dial_code": "+672"
    },
    {
      "name": "Holy See (Vatican City State)",
      "flag": "🇻🇦",
      "code": "VA",
      "dial_code": "+379"
    },
    {
      "name": "Honduras",
      "flag": "🇭🇳",
      "code": "HN",
      "dial_code": "+504"
    },
    {
      "name": "Hong Kong",
      "flag": "🇭🇰",
      "code": "HK",
      "dial_code": "+852"
    },
    {
      "name": "Hungary",
      "flag": "🇭🇺",
      "code": "HU",
      "dial_code": "+36"
    },
    {
      "name": "Iceland",
      "flag": "🇮🇸",
      "code": "IS",
      "dial_code": "+354"
    },
    {
      "name": "India",
      "flag": "🇮🇳",
      "code": "IN",
      "dial_code": "+91"
    },
    {
      "name": "Indonesia",
      "flag": "🇮🇩",
      "code": "ID",
      "dial_code": "+62"
    },
    {
      "name": "Iran, Islamic Republic of Persian Gulf",
      "flag": "🇮🇷",
      "code": "IR",
      "dial_code": "+98"
    },
    {
      "name": "Iraq",
      "flag": "🇮🇶",
      "code": "IQ",
      "dial_code": "+964"
    },
    {
      "name": "Ireland",
      "flag": "🇮🇪",
      "code": "IE",
      "dial_code": "+353"
    },
    {
      "name": "Isle of Man",
      "flag": "🇮🇲",
      "code": "IM",
      "dial_code": "+44"
    },
    {
      "name": "Israel",
      "flag": "🇮🇱",
      "code": "IL",
      "dial_code": "+972"
    },
    {
      "name": "Italy",
      "flag": "🇮🇹",
      "code": "IT",
      "dial_code": "+39"
    },
    {
      "name": "Jamaica",
      "flag": "🇯🇲",
      "code": "JM",
      "dial_code": "+1876"
    },
    {
      "name": "Japan",
      "flag": "🇯🇵",
      "code": "JP",
      "dial_code": "+81"
    },
    {
      "name": "Jersey",
      "flag": "🇯🇪",
      "code": "JE",
      "dial_code": "+44"
    },
    {
      "name": "Jordan",
      "flag": "🇯🇴",
      "code": "JO",
      "dial_code": "+962"
    },
    {
      "name": "Kazakhstan",
      "flag": "🇰🇿",
      "code": "KZ",
      "dial_code": "+7"
    },
    {
      "name": "Kenya",
      "flag": "🇰🇪",
      "code": "KE",
      "dial_code": "+254"
    },
    {
      "name": "Kiribati",
      "flag": "🇰🇮",
      "code": "KI",
      "dial_code": "+686"
    },
    {
      "name": "Korea, Democratic People's Republic of Korea",
      "flag": "🇰🇵",
      "code": "KP",
      "dial_code": "+850"
    },
    {
      "name": "Korea, Republic of South Korea",
      "flag": "🇰🇷",
      "code": "KR",
      "dial_code": "+82"
    },
    {
      "name": "Kosovo",
      "flag": "🇽🇰",
      "code": "XK",
      "dial_code": "+383"
    },
    {
      "name": "Kuwait",
      "flag": "🇰🇼",
      "code": "KW",
      "dial_code": "+965"
    },
    {
      "name": "Kyrgyzstan",
      "flag": "🇰🇬",
      "code": "KG",
      "dial_code": "+996"
    },
    {
      "name": "Laos",
      "flag": "🇱🇦",
      "code": "LA",
      "dial_code": "+856"
    },
    {
      "name": "Latvia",
      "flag": "🇱🇻",
      "code": "LV",
      "dial_code": "+371"
    },
    {
      "name": "Lebanon",
      "flag": "🇱🇧",
      "code": "LB",
      "dial_code": "+961"
    },
    {
      "name": "Lesotho",
      "flag": "🇱🇸",
      "code": "LS",
      "dial_code": "+266"
    },
    {
      "name": "Liberia",
      "flag": "🇱🇷",
      "code": "LR",
      "dial_code": "+231"
    },
    {
      "name": "Libyan Arab Jamahiriya",
      "flag": "🇱🇾",
      "code": "LY",
      "dial_code": "+218"
    },
    {
      "name": "Liechtenstein",
      "flag": "🇱🇮",
      "code": "LI",
      "dial_code": "+423"
    },
    {
      "name": "Lithuania",
      "flag": "🇱🇹",
      "code": "LT",
      "dial_code": "+370"
    },
    {
      "name": "Luxembourg",
      "flag": "🇱🇺",
      "code": "LU",
      "dial_code": "+352"
    },
    {
      "name": "Macao",
      "flag": "🇲🇴",
      "code": "MO",
      "dial_code": "+853"
    },
    {
      "name": "Macedonia",
      "flag": "🇲🇰",
      "code": "MK",
      "dial_code": "+389"
    },
    {
      "name": "Madagascar",
      "flag": "🇲🇬",
      "code": "MG",
      "dial_code": "+261"
    },
    {
      "name": "Malawi",
      "flag": "🇲🇼",
      "code": "MW",
      "dial_code": "+265"
    },
    {
      "name": "Malaysia",
      "flag": "🇲🇾",
      "code": "MY",
      "dial_code": "+60"
    },
    {
      "name": "Maldives",
      "flag": "🇲🇻",
      "code": "MV",
      "dial_code": "+960"
    },
    {
      "name": "Mali",
      "flag": "🇲🇱",
      "code": "ML",
      "dial_code": "+223"
    },
    {
      "name": "Malta",
      "flag": "🇲🇹",
      "code": "MT",
      "dial_code": "+356"
    },
    {
      "name": "Marshall Islands",
      "flag": "🇲🇭",
      "code": "MH",
      "dial_code": "+692"
    },
    {
      "name": "Martinique",
      "flag": "🇲🇶",
      "code": "MQ",
      "dial_code": "+596"
    },
    {
      "name": "Mauritania",
      "flag": "🇲🇷",
      "code": "MR",
      "dial_code": "+222"
    },
    {
      "name": "Mauritius",
      "flag": "🇲🇺",
      "code": "MU",
      "dial_code": "+230"
    },
    {
      "name": "Mayotte",
      "flag": "🇾🇹",
      "code": "YT",
      "dial_code": "+262"
    },
    {
      "name": "Mexico",
      "flag": "🇲🇽",
      "code": "MX",
      "dial_code": "+52"
    },
    {
      "name": "Micronesia, Federated States of Micronesia",
      "flag": "🇫🇲",
      "code": "FM",
      "dial_code": "+691"
    },
    {
      "name": "Moldova",
      "flag": "🇲🇩",
      "code": "MD",
      "dial_code": "+373"
    },
    {
      "name": "Monaco",
      "flag": "🇲🇨",
      "code": "MC",
      "dial_code": "+377"
    },
    {
      "name": "Mongolia",
      "flag": "🇲🇳",
      "code": "MN",
      "dial_code": "+976"
    },
    {
      "name": "Montenegro",
      "flag": "🇲🇪",
      "code": "ME",
      "dial_code": "+382"
    },
    {
      "name": "Montserrat",
      "flag": "🇲🇸",
      "code": "MS",
      "dial_code": "+1664"
    },
    {
      "name": "Morocco",
      "flag": "🇲🇦",
      "code": "MA",
      "dial_code": "+212"
    },
    {
      "name": "Mozambique",
      "flag": "🇲🇿",
      "code": "MZ",
      "dial_code": "+258"
    },
    {
      "name": "Myanmar",
      "flag": "🇲🇲",
      "code": "MM",
      "dial_code": "+95"
    },
    {
      "name": "Namibia",
      "flag": "🇳🇦",
      "code": "NA",
      "dial_code": "+264"
    },
    {
      "name": "Nauru",
      "flag": "🇳🇷",
      "code": "NR",
      "dial_code": "+674"
    },
    {
      "name": "Nepal",
      "flag": "🇳🇵",
      "code": "NP",
      "dial_code": "+977"
    },
    {
      "name": "Netherlands",
      "flag": "🇳🇱",
      "code": "NL",
      "dial_code": "+31"
    },
    {
      "name": "Netherlands Antilles",
      "flag": "",
      "code": "AN",
      "dial_code": "+599"
    },
    {
      "name": "New Caledonia",
      "flag": "🇳🇨",
      "code": "NC",
      "dial_code": "+687"
    },
    {
      "name": "New Zealand",
      "flag": "🇳🇿",
      "code": "NZ",
      "dial_code": "+64"
    },
    {
      "name": "Nicaragua",
      "flag": "🇳🇮",
      "code": "NI",
      "dial_code": "+505"
    },
    {
      "name": "Niger",
      "flag": "🇳🇪",
      "code": "NE",
      "dial_code": "+227"
    },
    {
      "name": "Nigeria",
      "flag": "🇳🇬",
      "code": "NG",
      "dial_code": "+234"
    },
    {
      "name": "Niue",
      "flag": "🇳🇺",
      "code": "NU",
      "dial_code": "+683"
    },
    {
      "name": "Norfolk Island",
      "flag": "🇳🇫",
      "code": "NF",
      "dial_code": "+672"
    },
    {
      "name": "Northern Mariana Islands",
      "flag": "🇲🇵",
      "code": "MP",
      "dial_code": "+1670"
    },
    {
      "name": "Norway",
      "flag": "🇳🇴",
      "code": "NO",
      "dial_code": "+47"
    },
    {
      "name": "Oman",
      "flag": "🇴🇲",
      "code": "OM",
      "dial_code": "+968"
    },
    {
      "name": "Pakistan",
      "flag": "🇵🇰",
      "code": "PK",
      "dial_code": "+92"
    },
    {
      "name": "Palau",
      "flag": "🇵🇼",
      "code": "PW",
      "dial_code": "+680"
    },
    {
      "name": "Palestinian Territory, Occupied",
      "flag": "🇵🇸",
      "code": "PS",
      "dial_code": "+970"
    },
    {
      "name": "Panama",
      "flag": "🇵🇦",
      "code": "PA",
      "dial_code": "+507"
    },
    {
      "name": "Papua New Guinea",
      "flag": "🇵🇬",
      "code": "PG",
      "dial_code": "+675"
    },
    {
      "name": "Paraguay",
      "flag": "🇵🇾",
      "code": "PY",
      "dial_code": "+595"
    },
    {
      "name": "Peru",
      "flag": "🇵🇪",
      "code": "PE",
      "dial_code": "+51"
    },
    {
      "name": "Philippines",
      "flag": "🇵🇭",
      "code": "PH",
      "dial_code": "+63"
    },
    {
      "name": "Pitcairn",
      "flag": "🇵🇳",
      "code": "PN",
      "dial_code": "+64"
    },
    {
      "name": "Poland",
      "flag": "🇵🇱",
      "code": "PL",
      "dial_code": "+48"
    },
    {
      "name": "Portugal",
      "flag": "🇵🇹",
      "code": "PT",
      "dial_code": "+351"
    },
    {
      "name": "Puerto Rico",
      "flag": "🇵🇷",
      "code": "PR",
      "dial_code": "+1939"
    },
    {
      "name": "Qatar",
      "flag": "🇶🇦",
      "code": "QA",
      "dial_code": "+974"
    },
    {
      "name": "Romania",
      "flag": "🇷🇴",
      "code": "RO",
      "dial_code": "+40"
    },
    {
      "name": "Russia",
      "flag": "🇷🇺",
      "code": "RU",
      "dial_code": "+7"
    },
    {
      "name": "Rwanda",
      "flag": "🇷🇼",
      "code": "RW",
      "dial_code": "+250"
    },
    {
      "name": "Reunion",
      "flag": "🇷🇪",
      "code": "RE",
      "dial_code": "+262"
    },
    {
      "name": "Saint Barthelemy",
      "flag": "🇧🇱",
      "code": "BL",
      "dial_code": "+590"
    },
    {
      "name": "Saint Helena, Ascension and Tristan Da Cunha",
      "flag": "🇸🇭",
      "code": "SH",
      "dial_code": "+290"
    },
    {
      "name": "Saint Kitts and Nevis",
      "flag": "🇰🇳",
      "code": "KN",
      "dial_code": "+1869"
    },
    {
      "name": "Saint Lucia",
      "flag": "🇱🇨",
      "code": "LC",
      "dial_code": "+1758"
    },
    {
      "name": "Saint Martin",
      "flag": "🇲🇫",
      "code": "MF",
      "dial_code": "+590"
    },
    {
      "name": "Saint Pierre and Miquelon",
      "flag": "🇵🇲",
      "code": "PM",
      "dial_code": "+508"
    },
    {
      "name": "Saint Vincent and the Grenadines",
      "flag": "🇻🇨",
      "code": "VC",
      "dial_code": "+1784"
    },
    {
      "name": "Samoa",
      "flag": "🇼🇸",
      "code": "WS",
      "dial_code": "+685"
    },
    {
      "name": "San Marino",
      "flag": "🇸🇲",
      "code": "SM",
      "dial_code": "+378"
    },
    {
      "name": "Sao Tome and Principe",
      "flag": "🇸🇹",
      "code": "ST",
      "dial_code": "+239"
    },
    {
      "name": "Saudi Arabia",
      "flag": "🇸🇦",
      "code": "SA",
      "dial_code": "+966"
    },
    {
      "name": "Senegal",
      "flag": "🇸🇳",
      "code": "SN",
      "dial_code": "+221"
    },
    {
      "name": "Serbia",
      "flag": "🇷🇸",
      "code": "RS",
      "dial_code": "+381"
    },
    {
      "name": "Seychelles",
      "flag": "🇸🇨",
      "code": "SC",
      "dial_code": "+248"
    },
    {
      "name": "Sierra Leone",
      "flag": "🇸🇱",
      "code": "SL",
      "dial_code": "+232"
    },
    {
      "name": "Singapore",
      "flag": "🇸🇬",
      "code": "SG",
      "dial_code": "+65"
    },
    {
      "name": "Slovakia",
      "flag": "🇸🇰",
      "code": "SK",
      "dial_code": "+421"
    },
    {
      "name": "Slovenia",
      "flag": "🇸🇮",
      "code": "SI",
      "dial_code": "+386"
    },
    {
      "name": "Solomon Islands",
      "flag": "🇸🇧",
      "code": "SB",
      "dial_code": "+677"
    },
    {
      "name": "Somalia",
      "flag": "🇸🇴",
      "code": "SO",
      "dial_code": "+252"
    },
    {
      "name": "South Africa",
      "flag": "🇿🇦",
      "code": "ZA",
      "dial_code": "+27"
    },
    {
      "name": "South Sudan",
      "flag": "🇸🇸",
      "code": "SS",
      "dial_code": "+211"
    },
    {
      "name": "South Georgia and the South Sandwich Islands",
      "flag": "🇬🇸",
      "code": "GS",
      "dial_code": "+500"
    },
    {
      "name": "Spain",
      "flag": "🇪🇸",
      "code": "ES",
      "dial_code": "+34"
    },
    {
      "name": "Sri Lanka",
      "flag": "🇱🇰",
      "code": "LK",
      "dial_code": "+94"
    },
    {
      "name": "Sudan",
      "flag": "🇸🇩",
      "code": "SD",
      "dial_code": "+249"
    },
    {
      "name": "Suriname",
      "flag": "🇸🇷",
      "code": "SR",
      "dial_code": "+597"
    },
    {
      "name": "Svalbard and Jan Mayen",
      "flag": "🇸🇯",
      "code": "SJ",
      "dial_code": "+47"
    },
    {
      "name": "Eswatini",
      "flag": "🇸🇿",
      "code": "SZ",
      "dial_code": "+268"
    },
    {
      "name": "Sweden",
      "flag": "🇸🇪",
      "code": "SE",
      "dial_code": "+46"
    },
    {
      "name": "Switzerland",
      "flag": "🇨🇭",
      "code": "CH",
      "dial_code": "+41"
    },
    {
      "name": "Syrian Arab Republic",
      "flag": "🇸🇾",
      "code": "SY",
      "dial_code": "+963"
    },
    {
      "name": "Taiwan",
      "flag": "🇹🇼",
      "code": "TW",
      "dial_code": "+886"
    },
    {
      "name": "Tajikistan",
      "flag": "🇹🇯",
      "code": "TJ",
      "dial_code": "+992"
    },
    {
      "name": "Tanzania, United Republic of Tanzania",
      "flag": "🇹🇿",
      "code": "TZ",
      "dial_code": "+255"
    },
    {
      "name": "Thailand",
      "flag": "🇹🇭",
      "code": "TH",
      "dial_code": "+66"
    },
    {
      "name": "Timor-Leste",
      "flag": "🇹🇱",
      "code": "TL",
      "dial_code": "+670"
    },
    {
      "name": "Togo",
      "flag": "🇹🇬",
      "code": "TG",
      "dial_code": "+228"
    },
    {
      "name": "Tokelau",
      "flag": "🇹🇰",
      "code": "TK",
      "dial_code": "+690"
    },
    {
      "name": "Tonga",
      "flag": "🇹🇴",
      "code": "TO",
      "dial_code": "+676"
    },
    {
      "name": "Trinidad and Tobago",
      "flag": "🇹🇹",
      "code": "TT",
      "dial_code": "+1868"
    },
    {
      "name": "Tunisia",
      "flag": "🇹🇳",
      "code": "TN",
      "dial_code": "+216"
    },
    {
      "name": "Turkey",
      "flag": "🇹🇷",
      "code": "TR",
      "dial_code": "+90"
    },
    {
      "name": "Turkmenistan",
      "flag": "🇹🇲",
      "code": "TM",
      "dial_code": "+993"
    },
    {
      "name": "Turks and Caicos Islands",
      "flag": "🇹🇨",
      "code": "TC",
      "dial_code": "+1649"
    },
    {
      "name": "Tuvalu",
      "flag": "🇹🇻",
      "code": "TV",
      "dial_code": "+688"
    },
    {
      "name": "Uganda",
      "flag": "🇺🇬",
      "code": "UG",
      "dial_code": "+256"
    },
    {
      "name": "Ukraine",
      "flag": "🇺🇦",
      "code": "UA",
      "dial_code": "+380"
    },
    {
      "name": "United Arab Emirates",
      "flag": "🇦🇪",
      "code": "AE",
      "dial_code": "+971"
    },
    {
      "name": "United Kingdom",
      "flag": "🇬🇧",
      "code": "GB",
      "dial_code": "+44"
    },
    {
      "name": "United States",
      "flag": "🇺🇸",
      "code": "US",
      "dial_code": "+1"
    },
    {
      "name": "Uruguay",
      "flag": "🇺🇾",
      "code": "UY",
      "dial_code": "+598"
    },
    {
      "name": "Uzbekistan",
      "flag": "🇺🇿",
      "code": "UZ",
      "dial_code": "+998"
    },
    {
      "name": "Vanuatu",
      "flag": "🇻🇺",
      "code": "VU",
      "dial_code": "+678"
    },
    {
      "name": "Venezuela, Bolivarian Republic of Venezuela",
      "flag": "🇻🇪",
      "code": "VE",
      "dial_code": "+58"
    },
    {
      "name": "Vietnam",
      "flag": "🇻🇳",
      "code": "VN",
      "dial_code": "+84"
    },
    {
      "name": "Virgin Islands, British",
      "flag": "🇻🇬",
      "code": "VG",
      "dial_code": "+1284"
    },
    {
      "name": "Virgin Islands, U.S.",
      "flag": "🇻🇮",
      "code": "VI",
      "dial_code": "+1340"
    },
    {
      "name": "Wallis and Futuna",
      "flag": "🇼🇫",
      "code": "WF",
      "dial_code": "+681"
    },
    {
      "name": "Yemen",
      "flag": "🇾🇪",
      "code": "YE",
      "dial_code": "+967"
    },
    {
      "name": "Zambia",
      "flag": "🇿🇲",
      "code": "ZM",
      "dial_code": "+260"
    },
    {
      "name": "Zimbabwe",
      "flag": "🇿🇼",
      "code": "ZW",
      "dial_code": "+263"
    }
  ]
}
