import {Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {GiftService} from "../../../../services/gift/gift.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-cards-filter',
  templateUrl: './cards-filter.component.html',
  styleUrls: ['./cards-filter.component.scss']
})
export class CardsFilterComponent implements OnInit, OnDestroy {
  private http = inject(HttpClient);
  giftService = inject(GiftService);

  @Input() set filters(value: any) {
    if (value !== this._filters) {
      this._filters = value;
      this.getSharables(); // Call the method that depends on the filters when a change is detected
    }
  }

  get filters(): any {
    return this._filters;
  }

  private _filters = {}
  @Input() search = '';
  @Input() title = 'Featured';
  @Input() classTitle: any[] = [];
  @Output() selectGift = new EventEmitter<string>();
  sharables: any = []
  request: Subscription | undefined;

  ngOnInit() {
    this.getSharables();
  }

  claimgift(data: any) {
    this.giftService.claimGift(data)
  }

  getSharables() {
    this.sharables = [];
    let newFilters: { [key: string]: any } = {...this.filters};
    if (newFilters['category'] === 'all')
      delete newFilters['category'];
    if (newFilters['type'] === 'all')
      delete newFilters['type'];
    if (newFilters['effect'] === 'all')
      delete newFilters['effect'];
    if (!newFilters['search'])
      delete newFilters['search'];
    if (!newFilters['featured'])
      delete newFilters['featured'];

    this.request = this.http.get('/products/general/filters', {
      params: {...newFilters}
    }).subscribe({
      next: (response: any) => {
        this.sharables = response?.records || response

      }
    });
  }

  ngOnDestroy() {
    this.request?.unsubscribe()
  }

  protected readonly length = length;

  selectedGift($event: string) {
    this.selectGift.emit($event);
  }
}
