
  <p class="font-Poppins font-medium text-[16px] mt-6">Gifts</p>
  <div class="checkbox-group flex items-center md:items-center mt-2" *ngFor="let typeGift of typesGifts; let i = index">
        <span>
          <input type="checkbox" id="type_grower{{i}}"  [(ngModel)]="typeGift.selected" class="accent-omgreen-50">
        </span>
    <label for="type_grower{{i}}" class="label-checkbox font-poppins ml-4 !font-light !text-[14px]">
      {{ typeGift.label }}</label>
  </div>
  <p class="font-Poppins font-medium text-[16px] mt-6">Rating</p>
  <div id="filter-rating">
    <div class="checkbox-group flex items-center md:items-center mt-2" >
          <span>
            <input type="checkbox" id="rating1"   class="accent-omgreen-50">
          </span>
      <label for="rating1" class="label-checkbox font-poppins ml-4 !font-light !text-[14px]">
        <app-star-rating [disabled]="true" [defaultRating]="1"></app-star-rating>
      </label>
    </div>
    <div class="checkbox-group flex items-center md:items-center mt-1" >
          <span>
            <input type="checkbox" id="rating2"   class="accent-omgreen-50">
          </span>
      <label for="rating2" class="label-checkbox font-poppins ml-4 !font-light !text-[14px]">
        <app-star-rating [disabled]="true" [defaultRating]="2"></app-star-rating>
      </label>
    </div>
    <div class="checkbox-group flex items-center md:items-center mt-1" >
          <span>
            <input type="checkbox" id="rating3"   class="accent-omgreen-50">
          </span>
      <label for="rating3" class="label-checkbox font-poppins ml-4 !font-light !text-[14px]">
        <app-star-rating [disabled]="true" [defaultRating]="3"></app-star-rating>
      </label>
    </div>
    <div class="checkbox-group flex items-center md:items-center mt-1" >
          <span>
            <input type="checkbox" id="rating4"   class="accent-omgreen-50">
          </span>
      <label for="rating4" class="label-checkbox font-poppins ml-4 !font-light !text-[14px]">
        <app-star-rating [disabled]="true" [defaultRating]="4"></app-star-rating>
      </label>
    </div>
    <div class="checkbox-group flex items-center md:items-center mt-1" >
          <span>
            <input type="checkbox" id="rating5"   class="accent-omgreen-50">
          </span>
      <label for="rating5" class="label-checkbox font-poppins ml-4 !font-light !text-[14px]">
        <app-star-rating [disabled]="true" [defaultRating]="5"></app-star-rating>
      </label>
    </div>
  </div>


  <p class="font-Poppins font-medium text-[16px] mt-6">Courses</p>
  <div class="checkbox-group flex items-center md:items-center mt-2" >
        <span>
          <input type="checkbox" id="Courses"   class="accent-omgreen-50">
        </span>
    <label for="Courses" class="label-checkbox font-poppins ml-4 !font-light !text-[14px]">Yes</label>
  </div>
  <p class="font-Poppins font-medium text-[16px] mt-6">Grower</p>
  <div class="checkbox-group flex items-center md:items-center mt-2" *ngFor="let typeGrower of typesGrowers; let i = index">
        <span>
          <input type="checkbox" id="type_grower{{i}}"  [(ngModel)]="typeGrower.selected" class="accent-omgreen-50">
        </span>
    <label for="type_grower{{i}}" class="label-checkbox font-poppins ml-4 !font-light !text-[14px]">
      {{ typeGrower.label }}</label>
  </div>


  <p class="font-Poppins font-medium text-[18px] mt-8">Location</p>
  <app-om-select id="select-pickup"
                 containerClass="!bg-white !py-[2px] sm:!py-[3px] !font-poppins !font-base shadow-md mt-4"
                 containerItemsClass="!max-h-fit"
                 itemsClass="nav-select-item !py-1 hover:!bg-white hover:!text-green4-native"
                 color="gray" [options]="[]" [(ngModel)]="selectedLocation"
                 #selectedNav="ngModel" [default]="''" placeholder="Select ..."
  />
  <div class="flex justify-center mt-8">
    <button class="py-3 px-5 text-white bg-[#81CB52] rounded-xl font-medium text-[12px]">Reset Filters</button>
  </div>
