import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input, OnDestroy, OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnDestroy {
  @ViewChild('divElement') private divContent: ElementRef | undefined;
  @Output() endContent = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();
  @Input() title?: string = '';
  @Input() classBody?: string = '';
  @Input() classModal?: string = '';
  @Input() classHeaderDefault?: string = 'my-2 ml-6';
  @Input() classTitle?: string = '';
  @Input() classCloseButton: string = '';
  @Input() visable?: boolean = false;
  @Input() useTemplateHeader?: boolean = false;
  closeB() {
    this.visable=false;
    this.close.emit(false);
    document.body.classList.remove('no-scroll');
  }
  open() {
    this.visable=true;
    document.body.classList.add('no-scroll');
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['visable']) {
      if(changes['visable'].currentValue) document.body.classList.add('no-scroll');
    }
  }
  endScroll() {
    if (!this.divContent) return;
    const element = this.divContent.nativeElement;
    const scrollTop = element.scrollTop || element.scrollTop === 0 ? element.scrollTop : element.parentElement.scrollTop;
    const clientHeight = element.clientHeight || element.parentElement.clientHeight;
    const scrollHeight = element.scrollHeight || element.parentElement.scrollHeight;
    const marginOfError = 1;
    if (scrollTop + clientHeight >= scrollHeight - marginOfError-200) {
      this.endContent.emit();
    }
  }
  scrollToBottom(): void {
    try {
      if(this.divContent){
        this.divContent.nativeElement.scrollTop = this.divContent.nativeElement.scrollHeight;
      }
    } catch (err) {
      console.error('Error scrolling to bottom', err);
    }
  }
  ngOnDestroy() {
    document.body.classList.remove('no-scroll');
  }
}
