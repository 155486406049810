import {Component, inject, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-coaching-trending',
  templateUrl: './coaching-trending.component.html',
  styleUrls: ['./coaching-trending.component.scss']
})
export class CoachingTrendingComponent implements OnChanges, OnDestroy {
  private http = inject(HttpClient);
  @Input() type = 'featured';
  data: any = [];

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['type']) {
      this.data = [];
      this.getData();
    }
  }

  subscriptionData: Subscription | undefined;

  getData() {
    this.subscriptionData = this.http.get('/courses', {
      params: {offset: this.data.length, limit: 6, type: this.type, sortKey: 'val_ranked', sortType: 'desc'}
    }).subscribe({
      next: (response: any) => {
        this.data = [...this.data, ...response.records];
        this.subscriptionData?.unsubscribe();
      }
    });

  }

  ngOnDestroy() {
    this.subscriptionData?.unsubscribe();
  }
}
