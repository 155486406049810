import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class OmAlertService {

  customConfigSubject = new BehaviorSubject<any>(null);
  isOKPressedSubject = new BehaviorSubject<boolean | null>(null);
  private defaultConfig: any = {
    closeOnPress: true,
    type:'omnigram',
    title: '',
    description: '',
    labelConfirm: 'Ok',
    labelCancel: 'Cancel',
    activeButtonCancel: true,
    activeButtonConfirm: true,
    containerClass: '',
    btnOkClass: '',
    btnCancelClass: '',
  };
  setCustomConfig(config: any) {
    this.customConfigSubject.next({ ...this.defaultConfig, ...config });
  }

  getIsOKPressed(): Observable<boolean | null> {
    return this.isOKPressedSubject.asObservable();
  }

  showCustomDialog() {
    this.isOKPressedSubject.next(null); // Restablecer el estado
  }
  closeDialog() {
    try {
      if (this.customConfigSubject) this.customConfigSubject.next(null);
    }catch (error){
      console.log('Error Alert Close')
      console.log(error)
    }
  }
}
