import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-liked-bar',
  templateUrl: './liked-bar.component.html',
  styleUrls: ['./liked-bar.component.scss']
})
export class LikedBarComponent {
  @Input() numberReactions : {
    numberLikes: number,
    numberComments: number,
    lastLikes: any[],
    numberShares: number,
    numberSaved: number,
  } = {
    numberLikes:0,
    numberComments:0,
    lastLikes:[],
    numberShares:0,
    numberSaved:0,
  };

  getArr() {
    return [...this.numberReactions.lastLikes]
  }
}
