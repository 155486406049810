<div >
  <app-register-publication  (savedPost)="addPost($event)" [communityId]="communityId" [visibleTitle]="true" [containnerClass]="'style-profile !block'" class="block my-3"></app-register-publication>
  <div class="posts style-profile">
    <app-publication *ngFor="let item of posts"
                     class="block sm:mt-2"
                     [isProfile]="true"
                     [isNotification]="false"
                     [data]="{
                     is_founder:item.is_founder===1,
                       pk:item.pk,
          postId:item.postId,
          postType:item.postType,
          avatar:item.avatar,
          name:item.name,
          title:item.postTitle,
          date:item.createDate,
          description:item.postContent,
          medios:item.postUrls,
          lastLikes:item.lastLikes || [],
          verifiedAccount:true,
          like:item.like,
          postLikesCount:item.postLikesCount,
          postCommentsCount:item.postCommentsCount,
          postLocation:item.postLocation || null,
          postHashtack:item.postHashtack,
          postFiltersByImage:item.postFiltersByImage,
          save:item['save']

        }">

    </app-publication>

  </div>
</div>
