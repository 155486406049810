import { Component } from '@angular/core';
import {FriendsService} from "../../../../services/friends/friends.service";

@Component({
  selector: 'app-growers2',
  templateUrl: './growers2.component.html',
  styleUrls: ['./growers2.component.scss']
})
export class Growers2Component {
  users = [];
  startCount=4.6;
  constructor(private friends: FriendsService) {
  }
  async ngOnInit() {
    this.users = await this.friends.getUsers();
  }

}
