
<div class="bg-white relative  shadow-[0px_3px_6px_#00000029] rounded-lg friend-card">
    <div class="h-24 sm:h-28 overflow-hidden" routerLink="/profile/{{user.id}}">
      <img [src]="user['banner'] || 'assets/demo/paisaje.webp'" class="w-full h-full rounded-t-lg object-cover" alt="">
    </div>
    <div class="rounded-b-lg flex justify-between px-[10px] relative">
      <div class="mt-[-45px] min-w-[90px] relative">
        <div class="relative">
          <img
            [src]="user['image_profile'] || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'"
            alt="" class="w-[85px] h-[85px] border-[7px] border-[#fff] rounded-full object-cover	cursor-pointer" routerLink="/profile/{{user.id}}">
          <div *ngIf="user['is_founder']===1" class="absolute bottom-0 right-0 w-[20px] h-[20px] sm:w-[40%] sm:h-[40%] p-[5px] rounded-[15px] sm:rounded-full bg-white">
            <img src="assets/ogfc.png" alt="" class="object-cover w-full">
          </div>
        </div>
        <span  *ngIf="user['is_grower_verified']===1" class="material-symbols-outlined flex justify-center items-center bg-green-native text-white rounded-full
         h-[20px] w-[20px] text-[16px] absolute top-[60px] right-[calc(50%-35px)] pt-[1px] font-bold">
         Done
         </span>
      </div>
      <div class="pt-[10px]" >
        <div class="flex gap-2 justify-between items-center">
          <div class="w-[100px]" routerLink="/profile/{{user.id}}">
            <h1 class="text-[14px] font-semibold text-[#171725] font-Poppins overflow-hidden whitespace-nowrap flex-nowrap overflow-ellipsis">{{ getName() }}</h1>
            <p class="hidden text-[12px] text-[#696974]">Verified Grower</p>
            <p class="text-[12px] text-[#519935]">{{countFollowers()}} Followers</p>
          </div>
          <div>
            <div class="flex items-center justify-center w-full gap-1 pr-2">
              <ng-container *ngIf="!isFollowing">
                <p (click)="goToMessages()"
                   class="cursor-pointer text-[10px] bg-[#519935] font-Poppins font-medium sm:text-[10px] text-[#FFFFFF] bw-1 border-[#87D255] rounded-2xl px-2 py-1">
                  Message</p>
                <p (click)="follow()"
                   class="cursor-pointer text-[10px] bg-[#FFFFFF] font-Poppins font-medium sm:text-[10px] text-[#519935] bw-1 border-[#519935] rounded-2xl px-2 py-1">
                  Follow</p>
              </ng-container>
              <ng-container *ngIf="isFollowing">
                <p (click)="goToMessages()"
                   class="cursor-pointer text-[10px] bg-[#519935] font-Poppins font-medium sm:text-[10px] text-[#FFFFFF] bw-1 border-[#87D255] rounded-2xl px-2 py-1">
                  Message</p>
                <p (click)="unfollow()"
                  class="cursor-pointer text-[10px] bg-[#FFFFFF] font-Poppins font-medium sm:text-[10px] text-[#519935] bw-1 border-[#519935] rounded-2xl px-2 py-1">
                  Following</p>
              </ng-container>
            </div>
          </div>
        </div>
        <p class="text-[12px] text-[#92929D] font-Poppins mt-[10px] min-h-[36px] max-h-[36px] overflow-hidden" routerLink="/profile/{{user.id}}" [innerHTML]="user.about_me.slice(0, 80) + '...'">
        </p>
        <div class="flex gap-2 items-center text-[12px] text-[#92929D] font-Poppins my-[10px]">
          <p class="flex items-center gap-1">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="22.019" height="18.495" viewBox="0 0 22.019 18.495">
              <defs>
                <clipPath id="clip-path">
                  <rect id="Rectángulo_303" data-name="Rectángulo 303" width="18.821" height="13.331" fill="#666"/>
                </clipPath>
              </defs>
              <g id="Grupo_195" data-name="Grupo 195" transform="matrix(0.951, 0.309, -0.309, 0.951, 4.115, 0.015)">
                <rect id="Rectángulo_297" data-name="Rectángulo 297" width="1.568" height="5.489" transform="translate(3.921 7.042)" fill="#666"/>
                <rect id="Rectángulo_298" data-name="Rectángulo 298" width="0.784" height="5.489" transform="translate(14.9 7.042)" fill="#666"/>
                <rect id="Rectángulo_299" data-name="Rectángulo 299" width="1.568" height="3.921" transform="translate(2.353 3.121)" fill="#666"/>
                <rect id="Rectángulo_300" data-name="Rectángulo 300" width="1.568" height="3.921" transform="translate(13.331 3.121)" fill="#666"/>
                <rect id="Rectángulo_301" data-name="Rectángulo 301" width="0.784" height="2.353" transform="translate(5.489 0.769)" fill="#666"/>
                <rect id="Rectángulo_302" data-name="Rectángulo 302" width="0.784" height="2.353" transform="translate(14.9 0.769)" fill="#666"/>
                <g id="Grupo_194" data-name="Grupo 194" transform="translate(0 -0.015)">
                  <g id="Grupo_193" data-name="Grupo 193" clip-path="url(#clip-path)">
                    <path id="Trazado_207" data-name="Trazado 207" d="M15.994,15.332H10.666a.666.666,0,1,1,0-1.332h5.328a.666.666,0,1,1,0,1.332" transform="translate(-3.341 -4.665)" fill="#666"/>
                    <path id="Trazado_208" data-name="Trazado 208" d="M18.649,16.659H2.666A.666.666,0,0,1,2,15.994V10.666A.666.666,0,0,1,2.666,10H18.649a.666.666,0,0,1,.666.666v5.328a.666.666,0,0,1-.666.666M3.332,15.328H17.983v-4H3.332Z" transform="translate(-0.668 -3.328)" fill="#666"/>
                    <path id="Trazado_209" data-name="Trazado 209" d="M16.649,9.328H.666A.666.666,0,0,1,0,8.662v-4A.666.666,0,0,1,.666,4H16.649a.666.666,0,0,1,.666.666v4a.666.666,0,0,1-.666.666M1.332,8H15.983V5.332H1.332Z" transform="translate(0 -1.324)" fill="#666"/>
                    <path id="Trazado_210" data-name="Trazado 210" d="M19.317,4H4.666A.666.666,0,0,1,4,3.33V.666A.666.666,0,0,1,4.666,0H19.317a.666.666,0,0,1,.666.666V3.33A.666.666,0,0,1,19.317,4M5.332,2.664H18.651V1.332H5.332Z" transform="translate(-1.336 0.012)" fill="#666"/>
                  </g>
                </g>
              </g>
            </svg>
            ({{ user.countCoaching }})
          </p>
          <div class="flex items-center">
            <app-star-rating [disabled]="true" [defaultRating]="user.general_ranked" [styleLabel]="{'margin':'0px'}"></app-star-rating>
            ({{user.general_ranked}})
          </div>
          <p class="flex items-center gap-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="13.375" height="14.661" viewBox="0 0 13.375 14.661">
              <g id="Grupo_399" data-name="Grupo 399" transform="translate(0 0)">
                <g id="Grupo_48" data-name="Grupo 48" transform="translate(0 0)">
                  <path id="Rectángulo_1" data-name="Rectángulo 1" d="M1.78,0H10.5a1.781,1.781,0,0,1,1.781,1.781v6.2A1.781,1.781,0,0,1,10.5,9.758H1.78A1.78,1.78,0,0,1,0,7.978V1.78A1.78,1.78,0,0,1,1.78,0Z" transform="translate(0.548 4.32)" fill="none" stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                  <path id="Trazado_1" data-name="Trazado 1" d="M13.183,5.737s-.591-4.063-3.234-3.716C7.712,2.315,10.477,5.945,13.183,5.737Z" transform="translate(-6.626 -1.416)" fill="none" stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                  <path id="Trazado_2" data-name="Trazado 2" d="M23.915,5.737s.591-4.063,3.234-3.716C29.386,2.315,26.621,5.945,23.915,5.737Z" transform="translate(-17.358 -1.416)" fill="none" stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                  <line id="Línea_1" data-name="Línea 1" x1="4.997" transform="translate(7.83 6.612)" fill="none" stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                  <line id="Línea_2" data-name="Línea 2" x1="4.737" transform="translate(0.548 6.612)" fill="none" stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                  <rect id="Rectángulo_2" data-name="Rectángulo 2" width="2.546" height="9.749" transform="translate(5.284 4.329)" fill="none" stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                  <g id="Componente_32_30" data-name="Componente 32 – 30">
                    <rect id="Rectángulo_3" data-name="Rectángulo 3" width="13.375" height="14.661" fill="none"/>
                  </g>
                </g>
              </g>
            </svg>
            ({{ user.countSharables }})
          </p>
        </div>
      </div>
    </div>
  </div>
