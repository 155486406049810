import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-card-gift',
  templateUrl: './card-gift.component.html',
  styleUrls: ['./card-gift.component.scss']
})
export class CardGiftComponent {
  @Input() data2 = {}
  data = {
    cdb: 0,
    updateDate: '',
    flavors: [],
    terpenes: [],
    grow_information: '',
    thc: 0,
    name: '',
    effects: [],
    createDate: '',
    genetics: '',
    category: '',
    images: [],
    description: '',
    id: '',
    type: '',
    amount: 0,
    val_reviews: 0,
    val_ranked: 0,
    count_reviews: 0,
    publish_cost: 0,
    pk: '',
    like: false
  }
  @Input() enableActions = true;
  @Input() enableType = true;
  @Input() enableThcCdb = true;
  @Input() quantity = 0;
  @Input() quantityDefault = 0;
  @Input() limit = 1;
  @Input() price = 0;
  @Output() sendData = new EventEmitter<any>();
  @Input() disabledAdd: boolean = false;

  ngOnInit() {
    window.scrollTo(0, 1);
    this.data = {...this.data, ...this.data2};
  }

  addQuantity() {
    if (this.limit === this.quantity || this.disabledAdd) return;
    this.quantity++;
    this.emitData();
  }

  minusQuantity() {
    if (this.quantity == 0) return;
    this.quantity--;
    this.emitData();
  }

  emitData() {
    let data: IProductCart = {
      name: this.data.name,
      genetics: this.data.genetics,
      quantity: this.quantity,
      price: this.data.publish_cost,
      total: this.data.publish_cost * this.quantity,
      id: this.data.id,
      images: this.data.images,
      pk: this.data.pk,

    };
    this.sendData.emit(data);
  }
}
