<app-settings-layout>
  <div class="w-full sm:max-w-[1450px] p-4 sm:p-6">
    <div class=" sm:hidden">
      <app-arrow-left-icon [width]="35" [height]="35" color="#00000066" class="cursor-pointer" (click)="goBack()"/>
    </div>
    <!--    <div class="px-6 my-2">-->
    <!--      <app-arrow-left-icon [width]="35" [height]="35" color="#00000066" class="cursor-pointer" (click)="router.navigateByUrl('/profile')"/>-->
    <!--    </div>-->
    <h2 class="font-poppins font-medium text-[#202020] text-[22px] pb-4">Support</h2>
    <div class="grid grid-cols-1 sm:grid-cols-2 w-full gap-4">
      <div class="rounded-2xl bg-white sm:shadow-lg p-4 sm:p-8">
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 gap-y-8">
          <div class="flex flex-col gap-2">
            <p class="font-poppins font-medium text-[16px] text-[#202020]">Name</p>
            <div class="flex w-full items-center gap-4">
              <app-om-input color="white" [(ngModel)]="data.name"
                            inputClass="!bg-white !py-[9px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px]"
                            placeholder="Full Name"
                            class="w-[100%]">
              </app-om-input>
            </div>
          </div>
          <div class="flex flex-col gap-2">
            <p class="font-poppins font-medium text-[16px] text-[#202020]">Email Address</p>
            <div class="flex w-full items-center gap-4">
              <app-om-input color="white" [(ngModel)]="data.email"
                            inputClass="!bg-white !py-[9px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px]"
                            placeholder="Email Address"
                            class="w-[100%]">
              </app-om-input>
            </div>
          </div>
          <div class="flex flex-col gap-2">
            <p class="font-poppins font-medium text-[16px] text-[#202020]">Phone Number</p>
            <div class="flex w-full items-center gap-4">
              <app-om-input color="white" [(ngModel)]="data.phone"
                            inputClass="!bg-white !py-[9px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px]"
                            placeholder="+12 3456 7890"
                            class="w-[100%]">
              </app-om-input>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-2 pt-8">
          <p class="font-poppins font-medium text-[16px]  text-[#202020]">Message</p>
          <div class="flex w-full items-center gap-4">
            <textarea placeholder="How can we help you?" [(ngModel)]="data.message" class="w-full bw-1 rounded-lg p-4 textarea"></textarea>
          </div>
        </div>
        <input type="file" #fileInput (change)="onFileSelected($event)"
               accept="image/*" style="display:none">
        <p class="font-poppins font-medium text-[16px]  text-[#202020] my-4">Files</p>
        <div class="flex gap-2">
          <div class="max-w-[356px] h-[148px] relative" *ngFor="let item of files; let i = index">
            <img [src]="item.src" class="max-w-full max-h-full  object-contain rounded-lg" alt="">
            <span (click)="deleteFile(i)" class="absolute top-[10px] right-[10px] material-symbols-outlined bg-black text-white rounded-full p-[5px] cursor-pointer">delete</span>
          </div>
          <div *ngIf="files.length!=limit" (click)="fileInput.click()" class="bg-[#cceabb1a] border-[#CCEABB] border-[1px] w-[180px] h-[120px] flex justify-center items-center flex-col rounded-lg cursor-pointer">
            <span  class="w-[60px] h-[60px] flex justify-center items-center bg-white rounded-full">
              <svg xmlns="http://www.w3.org/2000/svg" width="33.959" height="30.361" viewBox="0 0 33.959 30.361">
                <path id="Vector" d="M29.328,18.217v4.554h4.631v3.036H29.328v4.554H26.241V25.807H21.61V22.771h4.631V18.217ZM29.341,0a1.52,1.52,0,0,1,1.531,1.507V15.181H27.785V3.036H3.087V24.287L18.523,9.108l4.631,4.554v4.295L18.523,13.4,7.451,24.289H18.523v3.036H1.531a1.546,1.546,0,0,1-1.083-.442A1.5,1.5,0,0,1,0,25.818V1.507A1.506,1.506,0,0,1,.452.445,1.557,1.557,0,0,1,1.531,0ZM9.262,6.072a3.114,3.114,0,0,1,2.183.889,3,3,0,0,1,0,4.294,3.124,3.124,0,0,1-4.366,0,3,3,0,0,1,0-4.294,3.114,3.114,0,0,1,2.183-.889Z" fill="#99c183"/>
              </svg>
            </span>
            <p class="text-[#99C183] mt-[0px]">Click here to upload</p>
          </div>
        </div>
        <p class="font-light font-Poppins text-sm text-[#77838F] mt-4" [class]="{'text-red-900':errorSize}">Size (5mb)</p>
        <div class="pt-6">
          <button
            (click)="save()"
            class="flex px-4 !py-4 mb-2 h-fit gap-2 items-center font-normal text-xs bg-omgreen-600 text-white rounded-lg shadow-sm">
            Send Message
          </button>
        </div>
      </div>
      <div>
        <div class="rounded-2xl bg-white sm:shadow-lg p-4 sm:p-8 mb-6">
          <div class="flex items-center justify-start gap-8">
            <div class="rounded-full bg-[#fd6d211a] p-4">
              <span class="material-symbols-outlined text-green4-native">
              mail
              </span>
            </div>
            <div class="flex flex-col gap-2">
              <p class="font-poppins font-medium text-[16px] text-[#202020]">Contact us by email</p>
              <p class="font-poppins text-[16px] text-[#d0d0d0]">support@omnigram.ca</p>
            </div>
          </div>
        </div>
        <div class="rounded-2xl bg-white sm:shadow-lg p-4 sm:p-8 mb-6 hidden">
          <div class="flex items-center justify-start gap-8">
            <div class="rounded-full bg-[#fd6d211a] p-4">
              <span class="material-symbols-outlined text-green4-native">
              phone
              </span>
            </div>
            <div class="flex flex-col gap-2">
              <p class="font-poppins font-medium text-[16px] text-[#202020]">Give us a call</p>
              <p class="font-poppins text-[16px] text-[#d0d0d0]">+12 3456 7890</p>
            </div>
          </div>
        </div>
        <div class="rounded-2xl bg-white sm:shadow-lg p-4 sm:p-8 mb-6 hidden">
          <div class="flex items-center justify-start gap-8">
            <div class="rounded-full bg-[#fd6d211a] p-4">
              <span class="material-symbols-outlined text-green4-native">
              message
              </span>
            </div>
            <div class="flex flex-col gap-2">
              <p class="font-poppins font-medium text-[16px] text-[#202020]">Let’s chat</p>
              <p class="font-poppins text-[16px] text-[#d0d0d0]">Open live chat</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="loading" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div class="text-white text-center">
      <p class="text-xl font-bold">Sending message</p>
    </div>
  </div>
  <app-dialog-alert class="absolute z-[70]"></app-dialog-alert>
</app-settings-layout>
