import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, isDevMode, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import * as Sentry from "@sentry/angular-ivy";
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {LoginComponent} from './auth/components/login/login.component';
import {UserComponent} from './components/user/user.component';
import {HomeComponent} from './home/home.component';
import {NgIconsModule} from "@ng-icons/core";
import { PickerComponent } from '@ctrl/ngx-emoji-mart';
import {
  matArrowBack,
  matArrowDropDown,
  matArrowRightAlt,
  matKeyboardArrowDown,
  matNotificationsNone,
  matPlus,
  matChecklistRtl
} from '@ng-icons/material-icons/baseline';
import {StoreModule} from '@ngrx/store';
import {HomeComponent as HomeComponentAuth} from './components/home/home.component';
import {NgOtpInputModule} from 'ng-otp-input';
import {ApiPrefixInterceptor} from './interceptors/api-prefix.interceptor';
import {OmSelectComponent} from './forms/om-select/om-select.component';
import {YouLocateComponent} from './auth/components/register/you-locate/you-locate.component';
import {CreateAccountComponent} from './auth/components/register/create-account/create-account.component';
import {OtpVerificationComponent} from './auth/components/register/otp-verification/otp-verification.component';
import {EnableLocationComponent} from './auth/components/register/enable-location/enable-location.component';
import {GiftAndShareComponent} from './auth/components/register/gift-and-share/gift-and-share.component';
import {DescribesYouComponent} from './auth/components/register/describes-you/describes-you.component';
import {InterestedInComponent} from './auth/components/register/interested-in/interested-in.component';
import {RegisterHomeComponent} from './auth/components/register/register-home/register-home.component';
import {OmInputComponent} from './forms/om-input/om-input.component';
import {OmButtonComponent} from './forms/om-button/om-button.component';
import {TermsAndConditionsComponent} from './auth/components/terms-and-conditions/terms-and-conditions.component';
import {
  ResetPasswordHomeComponent
} from './auth/components/recovery-password/reset-password-home/reset-password-home.component';
import {
  ResetYourPasswordComponent
} from './auth/components/recovery-password/reset-your-password/reset-your-password.component';
import {NewPasswordComponent} from './auth/components/recovery-password/new-password/new-password.component';
import {NavbarComponent} from './common/components/navbar/navbar.component';
import {SideBarComponent} from './common/components/side-bar/side-bar.component';
import {HomepageComponent} from './homepage/homepage.component';
import {StoryComponent} from "./components/stories/story/story.component";
import {StoryCardComponent} from './components/stories/story-card/story-card.component';
import {PublicationComponent} from './components/publications/publication/publication.component';
import {
  RegisterPublicationComponent
} from './components/publications/register-publication/register-publication.component';
import {HomePublicationsComponent} from './components/publications/home-publications/home-publications.component';
import {CommentComponent} from './components/publications/comment/comment.component';
import {RightBarComponent} from './common/components/right-bar/right-bar.component';
import {WithoutImageComponent} from './components/publications/views/without-image/without-image.component';
import {WithImagesComponent} from './components/publications/views/with-images/with-images.component';
import {WithImageComponent} from './components/publications/views/with-image/with-image.component';
import {ReactionsBarComponent} from './components/publications/parts/reactions-bar/reactions-bar.component';
import {LikedBarComponent} from './components/publications/parts/liked-bar/liked-bar.component';
import {EnvelopIconComponent} from './icons/envelop-icon/envelop-icon.component';
import {BellIconComponent} from './icons/bell-icon/bell-icon.component';
import {MenuIconComponent} from './icons/menu-icon/menu-icon.component';
import {HamburgerMenuComponent} from './common/components/hamburger-menu/hamburger-menu.component';
import {CloseIconComponent} from './icons/close-icon/close-icon.component';
import {ShopIconComponent} from './icons/shop-icon/shop-icon.component';
import {CoachingIconComponent} from './icons/coaching-icon/coaching-icon.component';
import { LayoutComponent } from './components/layout/layout.component';
import { ProfileComponent } from './components/profile/profile.component';
import { DialogAlertComponent } from './components/general/dialog-alert/dialog-alert.component';
import { CardGalleryComponent } from './components/profile/parts/card-gallery/card-gallery.component';
import { AbouteMeComponent } from './components/profile/parts/aboute-me/aboute-me.component';
import { PostsComponent } from './components/profile/parts/posts/posts.component';
import { SharablesComponent } from './components/profile/parts/sharables/sharables.component';
import { CoachingComponent } from './components/profile/parts/coaching/coaching.component';
import { AccesoriesComponent } from './components/profile/parts/accesories/accesories.component';
import { FriendsComponent } from './components/friends/friends.component';
import { CompleateProfileComponent } from './components/profile/parts/compleate-profile/compleate-profile.component';
import { SettingsOptionsComponent } from './common/components/settings-options/settings-options.component';
import { NewProductComponent } from './components/sharables/new-product/new-product.component';
import { RangeDirective } from './directive/range.directive';
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import { CardMiniComponent } from './components/sharables/card-mini/card-mini.component';
import { StarRatingComponent } from './components/general/star-rating/star-rating.component';
import { SearchIconComponent } from './icons/search-icon/search-icon.component';
import { FiltersIconComponent } from './icons/filters-icon/filters-icon.component';
import { ChevronDownIconComponent } from './icons/chevron-down-icon/chevron-down-icon.component';
import { ChevronUpIconComponent } from './icons/chevron-up-icon/chevron-up-icon.component';
import { RightCheckIconComponent } from './icons/right-check-icon/right-check-icon.component';
import { DialogComponent } from './components/general/dialog/dialog.component';
import { DetailComponent } from './components/sharables/detail/detail.component';
import { CarouselComponent } from './components/general/carousel/carousel.component';
import { MessageIconComponent } from './icons/message-icon/message-icon.component';
import { ArrowLeftIconComponent } from './icons/arrow-left-icon/arrow-left-icon.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { GiftSendHomeComponent } from './components/gift/gift-send-home/gift-send-home.component';
import { GiftListComponent } from './components/gift/gift-list/gift-list.component';
import { CardGiftComponent } from './components/gift/parts/card-gift/card-gift.component';
import { GiftRecipientComponent } from './components/gift/gift-recipient/gift-recipient.component';
import {APP_BASE_HREF, NgOptimizedImage} from "@angular/common";
import { ClaimGiftComponent } from './components/gift/claim-gift/claim-gift.component';
import { GiftCenterComponent } from './components/gift/gift-center/gift-center.component';
import { FilterIconComponent } from './icons/filter-icon/filter-icon.component';
import { CalendarIconComponent } from './icons/calendar-icon/calendar-icon.component';
import { DoubleAngleIconComponent } from './icons/double-angle-icon/double-angle-icon.component';
import { DeliveryAddressComponent } from './components/gift/parts/delivery-address/delivery-address.component';
import { CostListComponent } from './components/gift/parts/cost-list/cost-list.component';
import { PersonalDetailSendComponent } from './components/gift/parts/personal-detail-send/personal-detail-send.component';
import {GoogleLoginProvider, GoogleSigninButtonModule, SocialLoginModule} from "@abacritt/angularx-social-login";
import { GiftIconComponent } from './icons/gift-icon/gift-icon.component';
import { ProfileFormComponent } from './components/profile/settings/profile-form/profile-form.component';
import { OverviewComponent } from './components/profile/settings/overview/overview.component';
import { SignInMethodComponent } from './components/profile/settings/sign-in-method/sign-in-method.component';
import { NotificationsComponent } from './components/profile/settings/notifications/notifications.component';
import { DesactivateAccountComponent } from './components/profile/settings/desactivate-account/desactivate-account.component';
import { BillingComponent } from './components/profile/settings/billing/billing.component';
import { SettingsLayoutComponent } from './components/layouts/layout-settings/settings-layout.component';
import { ImageAddIconComponent } from './icons/image-add-icon/image-add-icon.component';
import { SecureIconComponent } from './icons/secure-icon/secure-icon.component';
import { HomeLayoutComponent } from './components/layouts/home-layout/home-layout.component';
import { GiftSendLayoutComponent } from './components/layouts/gift-send-layout/gift-send-layout.component';
import { HomeOtherLayoutComponent } from './components/layouts/home-other-layout/home-other-layout.component';
import { ArticleComponent } from './components/publications/views/article/article.component';
import { ClickOutsideDirective } from './directive/click-outside.directive';
import { OgMarketplaceComponent } from './icons/og-marketplace/og-marketplace.component';
import { OgCoachingComponent } from './icons/og-coaching/og-coaching.component';
import { MenuBlockIconComponent } from './icons/menu-block-icon/menu-block-icon.component';
import { GifSearchComponent } from './components/publications/gif-search/gif-search.component';
import { SlidePostComponent } from './components/publications/parts/slide-post/slide-post.component';
import { SupportComponent } from './components/profile/settings/support/support.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { VideoPlayerComponent } from './common/components/video-player/video-player.component';
import { CommentsListComponent } from './components/publications/parts/comments-list/comments-list.component';
import { ShareComponent } from './components/publications/parts/share/share.component';
import { FriendCardComponent } from './components/friends/parts/friend-card/friend-card.component';
import { FriendsForHomeComponent } from './components/friends/friends-for-home/friends-for-home.component';
import { CommentFormComponent } from './components/publications/parts/comment-form/comment-form.component';
import { CommentInfoComponent } from './components/publications/parts/comment-info/comment-info.component';
import { HomeMarketPlaceComponent } from './components/market-place/home-market-place/home-market-place.component';
import { CategoriesComponent } from './components/market-place/parts/categories/categories.component';
import { CardsFilterComponent } from './components/market-place/parts/cards-filter/cards-filter.component';
import { BannersComponent } from './components/market-place/parts/banners/banners.component';
import { EffectsComponent } from './components/market-place/parts/effects/effects.component';
import { GrowersComponent } from './components/market-place/parts/growers/growers.component';
import {SideBarLayoutComponent} from "./components/layouts/sidebar-layout/sidebar-layout.component";
import { CardsEffectsComponent } from './components/market-place/parts/cards-effects/cards-effects.component';
import { CategoryHomeComponent } from './components/market-place/category-home/category-home.component';
import { MarketSearchComponent } from './components/market-place/market-search/market-search.component';
import { CategoryBannerComponent } from './components/market-place/parts/category-banner/category-banner.component';
import { GrowersSearchComponent } from './components/market-place/growers-search/growers-search.component';
import { GrowerBannerComponent } from './components/market-place/parts/grower-banner/grower-banner.component';
import { GrowersTypeComponent } from './components/market-place/parts/growers-type/growers-type.component';
import { CustomCarouselComponent } from './common/components/custom-carousel/custom-carousel.component';
import { GrowersHomeComponent } from './components/market-place/growers-home/growers-home.component';
import { GrowerCardComponent } from './components/market-place/parts/grower-card/grower-card.component';
import { ClearLayoutComponent } from './components/layouts/clear-layout/clear-layout.component';
import { GiftsSearchComponent } from './components/market-place/gifts-search/gifts-search.component';
import { RangePriceComponent } from './forms/range-price/range-price.component';
import {NgxSliderModule} from "ngx-slider-v2";
import { ReviewComponent } from './components/general/review/review.component';
import { EffectHomeComponent } from './components/market-place/effect-home/effect-home.component';
import { Growers2Component } from './components/market-place/parts/growers2/growers2.component';
import {GrowerIconComponent} from "./icons/grower-icon/grower-icon.component";
import { AllProductsComponent } from './components/market-place/parts/all-products/all-products.component';
import { FiltersProductsComponent } from './components/market-place/parts/filters-products/filters-products.component';
import { FiltersGrowersComponent } from './components/market-place/parts/filters-growers/filters-growers.component';
import { ProductDetailComponent } from './components/sharables/product-detail/product-detail.component';
import { ProductEffectsComponent } from './components/sharables/parts/product-effects/product-effects.component';
import { ProductFlavoursTerpenesComponent } from './components/sharables/parts/product-flavours-terpenes/product-flavours-terpenes.component';
import { ProductDescriptionComponent } from './components/sharables/parts/product-description/product-description.component';
import { ProductHeaderComponent } from './components/sharables/parts/product-header/product-header.component';
import { ProductGrowInfoComponent } from './components/sharables/parts/product-grow-info/product-grow-info.component';
import { ProductReviewComponent } from './components/sharables/parts/product-review/product-review.component';
import { ProductGrowerComponent } from './components/sharables/parts/product-grower/product-grower.component';
import { HomeMessagesComponent } from './components/messages/home-messages/home-messages.component';
import { CardUserMessageComponent } from './components/messages/parts/card-user-message/card-user-message.component';
import { MessageComponent } from './components/messages/parts/message/message.component';
import { MessagesLayoutComponent } from './components/layouts/messages-layout/messages-layout.component';
import { FormMessageComponent } from './components/messages/parts/form-message/form-message.component';
import { UserOnlineComponent } from './components/messages/parts/user-online/user-online.component';
import {EditIconComponent} from "./icons/edit-icon/edit-icon.component";
import {CameraIconComponent} from "./icons/camera-icon/camera-icon.component";
import {ImageGifIconComponent} from "./icons/image-gif-icon/image-gif-icon.component";
import {HistoryIIconComponent} from "./icons/history-i-icon/history-i-icon.component";
import { PublicationTypesComponent } from './components/publications/register-publication/post/publication-types/publication-types.component';
import { PostComponent } from './components/publications/register-publication/post/post.component';
import { MediaAddComponent } from './components/publications/register-publication/post/media-add/media-add.component';
import {CutIconComponent} from "./icons/cut-icon/cut-icon.component";
import { PostTagPeopleComponent } from './components/publications/register-publication/post/post-tag-people/post-tag-people.component';
import { PostSettingsComponent } from './components/publications/register-publication/post/post-settings/post-settings.component';
import { PostCategoriesComponent } from './components/publications/register-publication/post/post-categories/post-categories.component';
import { PostAddLocationComponent } from './components/publications/register-publication/post/post-add-location/post-add-location.component';
import {ChevronRightIconComponent} from "./icons/chevron-right-icon/chevron-right-icon.component";
import { IncommingCallComponent } from './components/messages/parts/incomming-call/incomming-call.component';
import { EndCallComponent } from './components/messages/parts/end-call/end-call.component';
import { ToastComponent } from './common/components/toast/toast.component';
import { CoachingHomeComponent } from './components/coaching/coaching-home/coaching-home.component';
import { CoachingCategoriesComponent } from './components/coaching/parts/coaching-categories/coaching-categories.component';
import { CoachingFeatureComponent } from './components/coaching/parts/coaching-feature/coaching-feature.component';
import { CoachingTrendingComponent } from './components/coaching/parts/coaching-trending/coaching-trending.component';
import { CoachingGrowCoursesComponent } from './components/coaching/parts/coaching-grow-courses/coaching-grow-courses.component';
import { CoachingTopCoachesComponent } from './components/coaching/parts/coaching-top-coaches/coaching-top-coaches.component';
import { CoachingOneToOneComponent } from './components/coaching/parts/coaching-one-to-one/coaching-one-to-one.component';
import { NotificationCenterComponent } from './components/home/notification-center/notification-center.component';
import {AutocompleteComponent} from "./forms/autocomplete/autocomplete.component";
import { PayGiftComponent } from './components/gift/pay-gift/pay-gift.component';
import { CardCourseComponent } from './components/coaching/parts/card-course/card-course.component';
import { CardCourse2Component } from './components/coaching/parts/card-course2/card-course2.component';
import {NotificationService} from "./services/notifications/notification.service";
import { NewCourseComponent } from './components/coaching/new-course/new-course.component';
import {PlusIconComponent} from "./icons/plus-icon/plus-icon.component";
import {VideoCoursesIconComponent} from "./icons/video-course-icon/video-courses-icon.component";
import { CoursesSearchComponent } from './components/coaching/courses-search/courses-search.component';
import { CoachesComponent } from './components/coaching/coaches/coaches.component';
import {PriceIconComponent} from "./icons/price-icon/price-icon.component";
import { MyCoursesComponent } from './components/coaching/my-courses/my-courses.component';
import { CardMyCouserComponent } from './components/coaching/card-my-couser/card-my-couser.component';
import { CourseComponent } from './components/coaching/course/course.component';
import { PayItemsComponent } from './components/general/pay-items/pay-items.component';
import { CourseDetailsComponent } from './components/coaching/course-details/course-details.component';
import { HomeCommunitiesComponent } from './components/communities/home-communities/home-communities.component';
import { NewCommunityComponent } from './components/communities/new-community/new-community.component';
import { CommunityCardComponent } from './components/communities/parts/community-card/community-card.component';
import { CommunityPopularCardComponent } from './components/communities/parts/community-popular-card/community-popular-card.component';
import { NewStoryComponent } from './components/stories/new-story/new-story.component';
import {GalleryIconComponent} from "./icons/gallery-icon/gallery-icon.component";
import {TextIconComponent} from "./icons/text-icon/text-icon.component";
import {TextFormatIconComponent} from "./icons/text-format-icon/text-format-icon.component";
import {PalletIconComponent} from "./icons/pallet-icon/pallet-icon.component";
import { StoryViewerComponent } from './components/stories/story-viewer/story-viewer.component';
import {SendIconComponent} from "./icons/send-icon/send-icon.component";
import {HeartIconComponent} from "./icons/heart-icon/heart-icon.component";
import { ReviewsListComponent } from './components/general/reviews-list/reviews-list.component';
import { ReviewCardComponent } from './components/general/review-card/review-card.component';
import { CommunityComponent } from './components/communities/community/community.component';
import { AboutGroupComponent } from './components/communities/parts/about-group/about-group.component';
import { PostCommunityComponent } from './components/communities/parts/post-community/post-community.component';
import { MiniCardCommunityComponent } from './components/communities/parts/mini-card-community/mini-card-community.component';
import { SearchAllComponent } from './components/searchs/search-all/search-all.component';
import { NotFoundComponent } from './common/components/404/not-found/not-found.component';
import { OwnCoursesComponent } from './components/profile/parts/own-courses/own-courses.component';
import { OwnAccesoriesComponent } from './components/profile/parts/own-accesories/own-accesories.component';
import { CommunityRequestComponent } from './components/communities/parts/community-request/community-request.component';
import { MembersComponent } from './components/communities/parts/members/members.component';
import { LoadingComponent } from './common/components/404/loading/loading.component';
import { ProductDialogComponent } from './components/general/product-dialog/product-dialog.component';
import { MapLocationComponent } from './components/publications/parts/map-location/map-location.component';
import {Router} from "@angular/router";
import {ScrollingModule, VIRTUAL_SCROLL_STRATEGY, VIRTUAL_SCROLLABLE} from "@angular/cdk/scrolling";
import { ZoomComponent } from './components/messages/parts/zoom/zoom.component';
import { Sidebar2LayoutComponent } from './components/layouts/sidebar2-layout/sidebar2-layout.component';
import { CommunitiesPopularComponent } from './components/communities/parts/communities-popular/communities-popular.component';
import { CommunitiesFeaturedComponent } from './components/communities/parts/communities-featured/communities-featured.component';
import { GMapsComponent } from './components/general/g-maps/g-maps.component';
import { MapsLocationsComponent } from './components/general/maps-locations/maps-locations.component';
import { FounderRegisterComponent } from './founders/founder-register/founder-register.component';
import {TrashIconComponent} from "./icons/trash-icon/trash-icon.component";
import { HomeReceivedGiftComponent } from './components/gift/received-gift/home-received-gift/home-received-gift.component';
import { HomeSendedGiftComponent } from './components/gift/sended-gift/home-sended-gift/home-sended-gift.component';
import { StatusGiftComponent } from './components/gift/sended-gift/status-gift/status-gift.component';
// import {BlockScrollStrategy, Overlay} from "@angular/cdk/overlay";
// export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
//   return () => overlay.scrollStrategies.block();
// }

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UserComponent,
    HomeComponent,
    HomeComponentAuth,
    OmSelectComponent,
    YouLocateComponent,
    CreateAccountComponent,
    EnableLocationComponent,
    GiftAndShareComponent,
    DescribesYouComponent,
    InterestedInComponent,
    RegisterHomeComponent,
    OmInputComponent,
    OtpVerificationComponent,
    OmButtonComponent,
    TermsAndConditionsComponent,
    ResetPasswordHomeComponent,
    ResetYourPasswordComponent,
    NewPasswordComponent,
    HomepageComponent,
    StoryComponent,
    StoryCardComponent,
    PublicationComponent,
    RegisterPublicationComponent,
    HomePublicationsComponent,
    CommentComponent,
    NavbarComponent,
    SideBarComponent,
    RightBarComponent,
    WithoutImageComponent,
    WithImagesComponent,
    WithImageComponent,
    ReactionsBarComponent,
    LikedBarComponent,
    EnvelopIconComponent,
    BellIconComponent,
    MenuIconComponent,
    HamburgerMenuComponent,
    CloseIconComponent,
    ShopIconComponent,
    CoachingIconComponent,
    NavbarComponent,
    SideBarComponent,
    LayoutComponent,
    ProfileComponent,
    DialogAlertComponent,
    CardGalleryComponent,
    AbouteMeComponent,
    PostsComponent,
    SharablesComponent,
    CoachingComponent,
    AccesoriesComponent,
    FriendsComponent,
    CompleateProfileComponent,
    SettingsOptionsComponent,
    NewProductComponent,
    RangeDirective,
    CardMiniComponent,
    StarRatingComponent,
    SearchIconComponent,
    FiltersIconComponent,
    ChevronDownIconComponent,
    ChevronUpIconComponent,
    RightCheckIconComponent,
    DialogComponent,
    DetailComponent,
    CarouselComponent,
    MessageIconComponent,
    ArrowLeftIconComponent,
    CheckoutComponent,
    GiftSendHomeComponent,
    GiftListComponent,
    CardGiftComponent,
    GiftRecipientComponent,
    ClaimGiftComponent,
    GiftCenterComponent,
    FilterIconComponent,
    CalendarIconComponent,
    DoubleAngleIconComponent,
    DeliveryAddressComponent,
    CostListComponent,
    PersonalDetailSendComponent,
    GiftIconComponent,
    ProfileFormComponent,
    OverviewComponent,
    SignInMethodComponent,
    NotificationsComponent,
    DesactivateAccountComponent,
    BillingComponent,
    SettingsLayoutComponent,
    SideBarLayoutComponent,
    ImageAddIconComponent,
    SecureIconComponent,
    HomeLayoutComponent,
    GiftSendLayoutComponent,
    HomeOtherLayoutComponent,
    ArticleComponent,
    ClickOutsideDirective,
    OgMarketplaceComponent,
    OgCoachingComponent,
    MenuBlockIconComponent,
    GifSearchComponent,
    SlidePostComponent,
    SupportComponent,
    VideoPlayerComponent,
    CommentsListComponent,
    ShareComponent,
    FriendCardComponent,
    FriendsForHomeComponent,
    CommentFormComponent,
    CommentInfoComponent,
    HomeMarketPlaceComponent,
    CategoriesComponent,
    CardsFilterComponent,
    BannersComponent,
    EffectsComponent,
    GrowersComponent,
    CardsEffectsComponent,
    CategoryHomeComponent,
    MarketSearchComponent,
    CategoryBannerComponent,
    GrowersSearchComponent,
    GrowerBannerComponent,
    GrowersTypeComponent,
    GrowersHomeComponent,
    GrowerCardComponent,
    ClearLayoutComponent,
    GiftsSearchComponent,
    RangePriceComponent,
    ReviewComponent,
    EffectHomeComponent,
    Growers2Component,
    GrowerIconComponent,
    AllProductsComponent,
    FiltersProductsComponent,
    FiltersGrowersComponent,
    ProductDetailComponent,
    ProductEffectsComponent,
    ProductFlavoursTerpenesComponent,
    ProductDescriptionComponent,
    ProductHeaderComponent,
    ProductGrowInfoComponent,
    ProductReviewComponent,
    ProductGrowerComponent,
    HomeMessagesComponent,
    CardUserMessageComponent,
    MessageComponent,
    MessagesLayoutComponent,
    FormMessageComponent,
    UserOnlineComponent,
    EditIconComponent,
    CutIconComponent,
    CameraIconComponent,
    ImageGifIconComponent,
    HistoryIIconComponent,
    PublicationTypesComponent,
    PostComponent,
    MediaAddComponent,
    PostTagPeopleComponent,
    PostSettingsComponent,
    PostCategoriesComponent,
    PostAddLocationComponent,
    ChevronRightIconComponent,
    IncommingCallComponent,
    EndCallComponent,
    ToastComponent,
    CoachingHomeComponent,
    CoachingCategoriesComponent,
    CoachingFeatureComponent,
    CoachingTrendingComponent,
    CoachingGrowCoursesComponent,
    CoachingTopCoachesComponent,
    CoachingOneToOneComponent,
    NotificationCenterComponent,
    AutocompleteComponent,
    PayGiftComponent,
    CardCourseComponent,
    CardCourse2Component,
    NewCourseComponent,
    PlusIconComponent,
    VideoCoursesIconComponent,
    PriceIconComponent,
    CoursesSearchComponent,
    CoachesComponent,
    MyCoursesComponent,
    CardMyCouserComponent,
    CourseComponent,
    PayItemsComponent,
    CourseDetailsComponent,
    HomeCommunitiesComponent,
    NewCommunityComponent,
    CommunityCardComponent,
    CommunityPopularCardComponent,
    NewStoryComponent,
    GalleryIconComponent,
    TextIconComponent,
    TextFormatIconComponent,
    PalletIconComponent,
    StoryViewerComponent,
    SendIconComponent,
    HeartIconComponent,
    ReviewsListComponent,
    ReviewCardComponent,
    CommunityComponent,
    AboutGroupComponent,
    PostCommunityComponent,
    MiniCardCommunityComponent,
    SearchAllComponent,
    NotFoundComponent,
    OwnCoursesComponent,
    OwnAccesoriesComponent,
    CommunityRequestComponent,
    MembersComponent,
    LoadingComponent,
    ProductDialogComponent,
    MapLocationComponent,
    ZoomComponent,
    Sidebar2LayoutComponent,
    CommunitiesPopularComponent,
    CommunitiesFeaturedComponent,
    GMapsComponent,
    MapsLocationsComponent,
    FounderRegisterComponent,
    TrashIconComponent,
    HomeReceivedGiftComponent,
    HomeSendedGiftComponent,
    StatusGiftComponent,
  ],
    imports: [
        BrowserModule,
        // SocketIoModule.forRoot(config),
        CKEditorModule,
        HttpClientModule,
        AppRoutingModule,
        SocialLoginModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        NgIconsModule.withIcons({
            matArrowRightAlt,
            matArrowBack,
            matArrowDropDown,
            matKeyboardArrowDown,
            matNotificationsNone,
            matPlus,
            matChecklistRtl,
        }),
        StoreModule.forRoot({}, {}),
        ReactiveFormsModule,
        NgOtpInputModule,
        FormsModule,
        GoogleSigninButtonModule,
        PickerComponent,
        ImageCropperModule,
        NgxSliderModule,
        CustomCarouselComponent,
        NgOptimizedImage,
        ScrollingModule,
    ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    // {
    //   provide: VIRTUAL_SCROLLABLE,
    //   useFactory: scrollFactory,
    //   deps: [Overlay],
    // },
    NotificationService,
    { provide: APP_BASE_HREF, useValue: '/' },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false, //keeps the user signed in
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('574660758525-gelsrrvu2mtenp2lngtibttlk3iepu23.apps.googleusercontent.com', {oneTapEnabled: false}) // your client id
          }
        ]
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiPrefixInterceptor,
      multi: true,
    },
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: false,
    //   }),
    // }, {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => {},
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
