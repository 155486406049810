<div class="bg-[url('assets/general/wave-m.webp')] bg-[#262628]
        md:bg-black relative w-full om-container bg-cover bg-center
        md:bg-no-repeat md:bg-left-center">
  <div class="arrow-container hidden sm:flex absolute sm:bg-arrow w-16 h-16 flex items-center justify-center mt-0
            sm:mt-[calc(var(--w-height)*9)] sm:ml-[calc(var(--w-width)*4.5)]" routerLink="/">
    <ng-icon name="matArrowBack" class="mx-4 mt-[calc((var(--w-height)*2)+var(--sat))] sm:mt-0 absolute
        text-white cursor-pointer text-4xl z-10 bg-none sm:text-5xl sm:rounded-full"></ng-icon>
  </div>
  <div class=" md:flex h-full">
    <div class="w-1/2 hidden md:flex  items-center h-full overflow-hidden	">
      <img src="assets/home/home-desktop.png" alt="background" class="h-full w-auto">
      <img alt="logo" src="assets/logo.svg" class="w-[15vw] md:ml-[-10vw] md:mt-[1vh]">
    </div>
    <div class="w-full sm:w-1/2 flex justify-end h-full">
      <div class="px-4 pl-6  w-full flex flex-col justify-between
      pt-[var(--sat)]
      bg-[#262628] bg-cover bg-[url('assets/general/wave-m.webp')] sm:bg-none bg-center
      sm:h-[80vh] sm:w-[36.6vw] sm:bg-[#2e2e2e]
      sm:mt-[calc(var(--w-height)*8)]
      sm:pt-[calc(var(--w-height)*6)]
      sm:pb-[calc(var(--w-height)*4)]
      sm:mr-[calc(var(--w-height)*15)]
      sm:px-[calc(var(--w-width)*5)]
      sm:rounded-[2vw]">
        <div>
          <ng-icon name="matArrowBack" class="text-white my-4 text-4xl inline-block sm:!hidden" routerLink="/"></ng-icon>
          <p class="title-form text-[calc(var(--mw-height)*4.5)] sm:text-[calc(var(--w-height)*4.5)]">
            Welcome back</p>
          <p
            class="text-[#C3C3C3] mt-[calc(var(--mw-height)*1)] sm:mt-[calc(var(--mw-height)*2)] sm:text-white
                  text-[calc(var(--mw-height)*1.7)] sm:text-[calc(var(--w-height)*2)]">
            Please enter your email & password to sign in.</p>
          <form (ngSubmit)="login()" autocomplete="off"></form>
          <div class="mt-[calc(var(--mw-height)*8)] sm:mt-[calc(var(--mw-height)*6)]">
            <app-om-input label="Email" placeholder="Email" id="email" type="email" inputMode="email"
                          [(ngModel)]="loginForm.email" #email="ngModel" required></app-om-input>
            <p *ngIf="email.invalid && email.touched"
               class="text-red-500 text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*2)]">
              Email is required.</p>
          </div>
          <div class="mt-[calc(var(--mw-height)*2)] sm:mt-[calc(var(--mw-height)*4)]">
            <app-om-input label="Password" placeholder="Password" id="password" type="password"
                          [(ngModel)]="loginForm.password" #password="ngModel" required></app-om-input>
            <p *ngIf="password.invalid && password.touched"
               class="text-red-500 text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*2)]">
              Password is required.</p>
          </div>
          <div class=" flex justify-between items-center mt-[calc(var(--mw-height)*2)] sm:mt-[calc(var(--mw-height)*4)]">
            <div class="checkbox-group flex items-center md:items-center">
              <span>
                <input type="checkbox" id="active_recommendations" [(ngModel)]="loginForm.remember" #rememberIn="ngModel"
                       class="accent-omgreen-50 h-[calc(var(--mw-height)*0.8)] w-[calc(var(--mw-height)*0.8)]">
              </span>
              <label for="active_recommendations" class="label-checkbox text-white ml-2 !p-0
              text-[calc(var(--mw-height)*1.6)] pt-[calc(var(--mw-height)*1.7)] sm:text-[calc(var(--mw-height)*1.6)]">
                Remember me.</label>
            </div>
            <p class="text-green-native text-[calc(var(--mw-height)*1.6)]
                sm:text-[calc(var(--w-height)*1.6)] cursor-pointer " routerLink="/forgot-password">Forgot Password?</p>
          </div>
        </div>
        <div class="w-full items-center mb-[100px]">
          <app-om-button [text]="'Sign in'"
                         class="w-full"
                         [height]="{xs:'7',sm:'6'}"
                         [fontSize]="{xs:'3',sm:'2.6'}"
                         [iconPosition]="'right'"
                         (click)="login()">
          </app-om-button>

          <hr class="border-[#707070] h-0.5 md:hidden my-[calc(var(--mw-height)*5)] mt-[calc(var(--mw-height)*6.5)]">
          <p
            class="text-white text-center my-[calc(var(--mw-height)*3)] sm:mb-[calc(var(--mw-height)*1.5)] text-[calc(var(--mw-height)*1.4)]
          block sm:absolute sm:w-[36.6vw] sm:right-[calc(var(--w-height)*15)] sm:bottom-0 sm:mb-[calc(var(--w-height)*4)] sm:text-[calc(var(--w-height)*2)]"
          >Don't have an account? <span
            class="text-green-native cursor-pointer underline" (click)="openExternalUrl()" RRouterLink="/register">Join now.</span></p>
        </div>
      </div>
    </div>
  </div>
</div>
