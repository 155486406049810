import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DeviceInfoService} from "../../../services/device-info/device-info.service";

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.scss']
})
export class HomeLayoutComponent {
  @Output() newPost = new EventEmitter<any>();
  @Input() typePost = '';
  @Output() typePostChange = new EventEmitter<any>();
  constructor(public deviceInfo: DeviceInfoService) {
  }
  openFormPost(event:any){
    this.newPost.emit()
  }
  ngOnInit(){
    this.deviceInfo.scrollTop();
  }
  toggleMenu(){
    this.deviceInfo.toggleMenu()
  }
  updateType(evt: any) {
    this.typePost = evt
    this.typePostChange.emit(evt)
  }
  protected readonly event = event;
}
