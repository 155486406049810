<div class="flex justify-end" *ngIf="type === 'posts'">
  <article class="hidden sm:block side w-[30%]">
    <div class=" mt-4" [class]="{'!hidden': isMe()}">
      <div class="shadow-md rounded-lg flex w-full">
        <div *ngIf="!isMyFriend && !friendRequestSend.success && !friendRequestReceived.success" (click)="clickAddFriendRequest()"
             class=" cursor-pointer rounded-l-lg bg-white text-[11px] w-1/2 text-omgreen-600 text-center px-2 py-2 border-r-[1px]">
          Send Request
        </div>
        <div *ngIf="!isMyFriend && friendRequestReceived.success" (click)="updateFriendRequest('accepted')"
             class=" cursor-pointer rounded-l-lg bg-white text-[11px] w-1/2 text-omgreen-600 text-center px-2 py-2 border-r-[1px]">
          Accept request
        </div>
        <div *ngIf="!isMyFriend && friendRequestReceived.success" (click)="updateFriendRequest('rejected')"
             class=" cursor-pointer rounded-l-lg bg-white text-[11px] w-1/2 text-red-600 text-center px-2 py-2 border-r-[1px]">
          Reject request
        </div>
        <div *ngIf="!isMyFriend && friendRequestSend.success" (click)="clickDeleteFriendRequest('Are you sure you want to remove this request?')"
             class=" cursor-pointer rounded-l-lg bg-white text-[11px] w-1/2 text-red-600 text-center px-2 py-2 border-r-[1px]">
          Delete Request
        </div>
        <div *ngIf="isMyFriend" (click)="clickDeleteFriendRequest('Are you sure you want to remove this friend?')"
             class="cursor-pointer rounded-l-lg bg-white text-[11px] w-1/2 text-omgreen-600 text-center px-2 py-2 border-r-[1px]">
          Delete Friend
        </div>
        <div *ngIf="!(!isMyFriend && friendRequestReceived.success)" (click)="openChat()"
             class="cursor-pointer rounded-r-lg bg-white text-[11px] w-1/2 text-omgreen-600 text-center px-2 py-2">Send
          Message
        </div>
      </div>
    </div>
    <section>
      <app-compleate-profile></app-compleate-profile>
    </section>
    <section class="bg-white rounded-2xl pt-2 mt-4">
      <app-aboute-me [user]="userProfile"></app-aboute-me>
    </section>
    <section class="bg-white rounded-2xl pt-2 mt-4" *ngIf="postsMedia?.length">
      <app-card-gallery [data]="postUrls" (seeAllMedia)="setTypeView($event)"></app-card-gallery>
    </section>
  </article>
  <article class="w-full sm:w-[68%] sm:px-4">
    <app-register-publication *ngIf="isMe()" (savedPost)="addPost($event)" [visibleTitle]="true"
                              [containnerClass]="'style-profile !block'" class="block my-3"></app-register-publication>
    <div class="posts style-profile">
      <app-publication *ngFor="let item of posts"
                       (deletePost)="deletePost($event)"
                       class="block sm:mt-2"
                       [isProfile]="true"
                       [isNotification]="false"
                       [data]="{
                       is_founder:item.is_founder===1,
                       pk:item.pk,
      postId:item.postId,
      postType:item.postType,
      avatar:item.avatar,
      name:item.name,
      title:item.postTitle,
      date:item.createDate,
      description:item.postContent,
      medios:item.postUrls,
      lastLikes:item.lastLikes || [],
      verifiedAccount:true,
      like:item.like,
      postLikesCount:item.postLikesCount,
      postSavedCount:item.postSavedCount||0,
      postCommentsCount:item.postCommentsCount,
      postLocation:item.postLocation || null,
      postHashtack:item.postHashtack,
      postFiltersByImage:item.postFiltersByImage,
      save:item['save']

    }">

      </app-publication>

    </div>
  </article>
</div>
<div *ngIf="type === 'media'">
  <app-publication #onMedia [isNotification]="true"></app-publication>
  <div class="grid grid-cols-1 sm:grid-cols-3 gap-4">
    <div class="rounded-2xl overflow-hidden relative group" *ngFor="let media of postsMedia"
         (click)="showCommentDesk(media)">
      <div *ngIf="deviceInfoService.getTypeFile(media.postUrls[0]) === 'image'"
           class="w-full h-[50vh] sm:h-[250px] bg-cover sm:bg-cover bg-no-repeat bg-top sm:bg-center bg-black/70 rounded-lg"
           [class]="media.postFiltersByImage[0]"
           [class.!bg-center]="true"
           [class.!rounded-none]="true"
           [class.sm:!h-[250px]]="true"
           [style.background-image]="'url(' + media.postUrls[0] + ')'"></div>
      <!--  <img *ngIf="getTypeFile(files[currentImageIndex]) === 'image'" [src]="files[currentImageIndex]" alt="Current Image" class="block sm:hidden w-full cover h-[50vh] rounded-lg">-->
      <div *ngIf="deviceInfoService.getTypeFile(media.postUrls[0]) === 'video'"
           class="sm:h-[400px] bg-black/70 sm:flex sm:justify-center sm:items-center"
           [class.sm:!h-full]="true"
      >
        <app-video-player [classVideo]="['w-full', 'rounded-lg']" [classFilter]="media.postFiltersByImage[0]"
                          [videoUrl]="media.postUrls[0]"></app-video-player>
      </div>
      <div
        class="absolute bg-gradient-to-b from-transparent via-transparent to-black/90 w-full h-full left-0 top-0"></div>
      <div class="absolute bottom-2 sm:hidden sm:group-hover:block w-full px-4 ocultarScroll">
        <div class="flex gap-2 w-full justify-between">
          <div class="flex items-center">
            <svg class="icon w-[20px] h-[20px] sm:w-[15px] sm:h-[15px] cursor-pointer"
                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.858 19.32">
              <g id="Like" transform="translate(0.479 0.052)">
                <path class="" id="Shape"
                      d="M18.327,1.531a5.225,5.225,0,0,0-7.391,0L9.929,2.538,8.922,1.531A5.226,5.226,0,0,0,1.531,8.922L2.538,9.929,9.929,17.32,17.32,9.929l1.007-1.007a5.225,5.225,0,0,0,0-7.391Z"
                      transform="translate(0.521 0.948)" fill="none" stroke="#92929D" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="2"/>
              </g>
            </svg>
            <label class="ml-2 text-[12px] sm:text-[8px] text-white">{{ media.postLikesCount }} Likes</label>
          </div>
          <div class="flex items-center">
            <svg class="icon w-[20px] h-[20px] sm:w-[15px] sm:h-[15px] cursor-pointer"
                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 19.002">
              <path id="Path"
                    d="M5.144,19A1.539,1.539,0,0,1,3.608,17.47l0-2.316a4.727,4.727,0,0,1-2.579-1.649A4.679,4.679,0,0,1,0,10.564V4.721A4.719,4.719,0,0,1,4.706,0H15.294A4.719,4.719,0,0,1,20,4.721v5.844a4.718,4.718,0,0,1-4.706,4.72h-4.78l-4.449,3.4A1.507,1.507,0,0,1,5.144,19ZM4.706,2A2.717,2.717,0,0,0,2,4.721v5.844a2.713,2.713,0,0,0,.767,1.9,2.685,2.685,0,0,0,1.863.822.994.994,0,0,1,.972,1l0,2.237,3.962-3.029a1.007,1.007,0,0,1,.608-.205h5.118A2.716,2.716,0,0,0,18,10.564V4.721A2.717,2.717,0,0,0,15.294,2Z"
                    transform="translate(0 0)" fill="#92929D"/>
            </svg>
            <label class="ml-2 text-[12px] sm:text-[8px] text-white">{{ media.postCommentsCount }} Comments</label>
          </div>
          <div class="flex items-center">
            <div class="flex items-center">
              <svg class="icon w-[20px] h-[20px] sm:w-[15px] sm:h-[15px]" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 21 21">
                <g id="Send" transform="translate(1 1)">
                  <path id="Shape" d="M0,9,19,0,10,19,8,11,0,9Z" fill="none" stroke="#92929D" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2"/>
                </g>
              </svg>
              <label class="ml-2 text-[12px] sm:text-[8px] text-white">{{ media.postShareCount || 0 }} Share</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
