<div>
  <div class="flex justify-between items-center px-3">
    <p class="font-Poppins font-[600] text-[10px] text-[#171725] ">Photos and Videos</p>
    <span class="font-Poppins text-[10px] text-[#519935] cursor-pointer" (click)="seeAll()">SEE ALL</span>
  </div>
  <hr class="border-[#EEEEEE] my-2">
  <div class="p-2 text-[10px] text-[#171725] flex justify-between flex-wrap gap-2" (click)="seeAll()">
    <ng-container *ngFor="let medio of data.slice(0, 6);">
      <img *ngIf="getTypeFile(medio) === 'image'" [src]="medio" alt="img" class="w-[60px] h-[60px] object-cover rounded-xl	">
      <video *ngIf="getTypeFile(medio) === 'video'" [src]="medio" class="w-[60px] h-[60px] object-cover rounded-xl"></video>
    </ng-container>
  </div>
</div>
