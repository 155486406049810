import {Component, ElementRef, inject, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {DeviceInfoService} from "../../../services/device-info/device-info.service";
import {CatalogsService} from "../../../services/catalogs/catalogs.service";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {UserInfoService} from "../../../services/user-info/user-info.service";
import Editor from "@ckeditor/ckeditor5-build-classic";
import {DialogComponent} from "../../general/dialog/dialog.component";

@Component({
  selector: 'app-new-course',
  templateUrl: './new-course.component.html',
  styleUrls: ['./new-course.component.scss']
})
export class NewCourseComponent implements OnInit {
  http = inject(HttpClient);
  router = inject(Router);
  key: number = 0;
  private deviceInfo = inject(DeviceInfoService);
  catalogService = inject(CatalogsService);
  @ViewChildren('video') video: ElementRef | undefined;
  @ViewChildren("inputVideo") inputVideo: QueryList<ElementRef> | undefined;
  @ViewChild('changePic', {static: false}) changePic!: DialogComponent;
  @ViewChild('inputChangePic') inputChangePic: ElementRef | undefined;
  imageChangedEvent: any = null;
  index = 0;
  indexChapter = 0;
  indexClass = 0;
  user: any = {};
  data: any = {
    name: '',
    language: '',
    skill_level: '',
    category: '',
    banner: '',
    imagenesPreview: [],
    teaching: [],
    description: '',
    requirements: '',
    images: [],
    initial_cost: null,
    publish_cost: null,
    chapters: [
      {
        title: '',
        classes: [
          {title: '', url: ''}
        ]
      }
    ]
  };
  teach = ''
  Editor = ClassicEditor;
  Editor2 = ClassicEditor;
  category: { label: string, value: string }[] = []
  languages: { label: string, value: string }[] = []
  skillLevels: { label: string, value: string }[] = []
  teaching: string[] = []

  ngOnInit() {
    window.scrollTo(0, 1);
    this.user = this.userInfo.me().user;
    this.getLanguages();
    this.getSkillLevels();
    this.getCourseCategories();
    this.getCourseTeaching();
    this.key = new Date().getTime();
  }

  getData() {
    return JSON.stringify(this.data)
  }

  async save() {
    if(!this.data.bannerFile){
      alert('Banner is required')
      return;
    }
    if((this.data.images?.length || 0) < 2){
      alert('Images is required')
      return;
    }
    let newData = Object.assign({}, this.data);
    delete newData.imagenesPreview;
    delete newData.images;
    delete newData.banner;
    delete newData.bannerFile;
    let formData: FormData = new FormData();
    formData.append("key", this.key.toString());
    for (let item in newData) {
      if(item === 'chapters'){
        formData.append("chapters", JSON.stringify(this.data.chapters))
      } else if(item === 'teaching'){
        this.data.teaching.forEach((t: any) => {
          formData.append('teaching', t);
        })
      } else {
        formData.append(item, this.data[item]);
      }
    }
    for (let image of this.data.images) {
      if (image?.name) {
        formData.append(`images`, image, image.name);
      }
    }
    if (this.data.bannerFile?.name) {
      formData.append(`images`, this.data.bannerFile, this.data.bannerFile.name);
    }

    //let data = await fetch('https://omnigram-api.camezit.mx/product/add-edit', requestOptions)
    this.http.post('/course', formData).subscribe({
      next: (response: any) => {
        const {success} = response
        if (success) {
          this.deviceInfo.goBack();
          // this.router.navigateByUrl('/profile/'+this.user.id)
        } else {
          alert("Error al crear producto")
        }
      }
    });
  }

  constructor(private userInfo: UserInfoService) {
  }

  setTeach(name: string) {
    this.data.teaching.push(name);
    this.teach = '';
  }

  getLanguages() {
    this.catalogService.getAll('languages', '').subscribe({
      next: (response: any) => {
        this.languages = response.records.map((r: any) => {
          return {label: r.name, value: r.name}
        });
      }
    })
  }

  getSkillLevels() {
    this.catalogService.getAll('skill-levels', '').subscribe({
      next: (response: any) => {
        this.skillLevels = response.records.map((r: any) => {
          return {label: r.name, value: r.name}
        });
      }
    })
  }

  getCourseCategories() {
    this.catalogService.getAll('courses-categories', '').subscribe({
      next: (response: any) => {
        this.category = response.records.map((r: any) => {
          return {label: r.name, value: r.name}
        });
      }
    })
  }

  getCourseTeaching() {
    this.catalogService.getAll('course-teaching', '').subscribe({
      next: (response: any) => {
        this.teaching = response.records.map((r: any) => {
          return r.name
        });
      }
    })
  }

  addChapter() {
    this.data.chapters.push({
      title: '',
      classes: [
        {title: '', url: ''}
      ]
    })
  }

  addClass(index: number) {
    this.data.chapters[index].classes.push({title: '', url: ''})
  }

  selectImagePic(index: number) {
    this.index = index
    if (this.inputChangePic?.nativeElement?.files?.length)
      this.inputChangePic.nativeElement.value = null;

    this.inputChangePic?.nativeElement.click();
  }

  goBack() {
    this.deviceInfo.goBack();
  }

  uploadVideo(e: any) {
    if (e.target?.files.length) {
      this.userInfo.uploadVideo(e.target.files[0], this.data.chapters, this.indexChapter, this.indexClass, this.key);
    }
  }

  openSelectFile(element: any, index: number, indexClass: number) {
    element.nextSibling.nextSibling.click();
    this.indexChapter = index;
    this.indexClass = indexClass;
  }

  imageCropped(event: any) {
    if (event.base64 != null) {
      if (this.index === -1)
        this.data.banner = event.base64;
      else this.data.imagenesPreview[this.index] = event.base64;
    }
  }

  cropperReady() {
    if (this.data.banner === null && this.index === -1) return;
    if (this.data.imagenesPreview[this.index] === null) return;
    let base64 = this.index === -1 ? this.data.banner : this.data.imagenesPreview[this.index];
    const ext = this.deviceInfo.extractContentTypeFromBase64(base64).split('/')[1];
    let name = (Math.random() * 1000).toFixed(0) + `imag.${['gif', 'png', 'jpg', 'jpeg', 'mp4'].includes(ext) ? ext : 'png'}`;
    if (this.index === -1)
      this.data.bannerFile = this.deviceInfo.dataURLtoFile(base64.replace('data:', ''), name)
    else
      this.data.images[this.index] = this.deviceInfo.dataURLtoFile(base64.replace('data:', ''), name)
    this.imageChangedEvent = '';
    this.changePic.closeB();
  }

  closeCropper() {
    this.imageChangedEvent = '';
    this.changePic.closeB();
  }

  onChangePic() {
    this.imageChangedEvent = {target: {files: this.inputChangePic?.nativeElement.files}}
    this.changePic.open()
  }
  changePublishCost(cost: any) {
    this.data.publish_cost = Number(cost) * 1.2
  }
  protected readonly JSON = JSON;
}
