import { Component } from '@angular/core';

@Component({
  selector: 'app-filters-growers',
  templateUrl: './filters-growers.component.html',
  styleUrls: ['./filters-growers.component.scss']
})
export class FiltersGrowersComponent {
  selectedLocation='';
  typesGrowers: { label: string, value: string, selected:boolean }[] = [
    {label: 'Local', value: 'local', selected:false },
    {label: 'Verified', value: 'verified', selected:false },
    {label: 'Featured', value: 'featured', selected:false },
    {label: 'Trending', value: 'trending', selected:false },
    {label: 'New', value: 'new', selected:false },
  ]
  typesGifts: { label: string, value: string, selected:boolean }[] = [
    {label: 'Sativa', value: 'sativa', selected:false },
    {label: 'Hybrid', value: 'hybrid', selected:false },
    {label: 'Indica', value: 'indica', selected:false },
    {label: 'CBD', value: 'cbd', selected:false },
  ]
}
