<app-sidebar-layout title="Courses" routeHeader="/coaching" [useHeaderEdit]="true">
  <div class="flex w-full" *ngIf="data.sk">
    <div class="w-[calc(100%-40px)] sm:w-[65%] mx-[20px]">
      <div class="flex gap-4 items-center">
        <span class=" sm:hidden material-symbols-outlined text-[30px] sm:text-[30px] text-[#707070] cursor-pointer"
              (click)="goBack()">arrow_back</span>
        <p class="font-Poppins text-[20px] sm:text-[24px] font-medium">{{ title }}</p>
      </div>
      <div [innerHTML]="description"
           class="font-Poppins text-[14px] text-[#84818A] overflow-hidden h-[45px] mt-[30px] sm:mt-[5px]"></div>
      <div class="flex gap-[15px] items-center mt-[10px]">
        <button
          class="text-[#519935] text-[17px] font-medium h-[25px] w-[100px] text-center border-[1px] border-[#519935] rounded-lg">{{ data?.skill_level }}
        </button>
        <div class="flex gap-[5px] justify-center items-baseline">
          <span class="text-[14px] text-[#202020] font-Poppins font-medium">{{ data.val_ranked }}</span>
          <app-star-rating [defaultRating]="data.val_ranked" [disabled]="true" [styleLabel]="{
          'margin':'0 0.5px',
          'font-size':'14px'
        }"></app-star-rating>
          <span class="text-[14px] text-[#202020] font-Poppins font-medium">({{ data.count_reviews }} ratings)</span>
        </div>
        <p class="font-Poppins text-[14px] text-[#84818A]">{{ data.count_solded || 0 }} students</p>
      </div>
      <img alt="" [src]="data.banner||''" class="w-full rounded-[15px] mt-[20px] sm:mt-[45px]">


      <p *ngIf="!data.purchased" (click)="openPay()"
         class="cursor-pointer sm:hidden mt-[15px] font-Poppins font-mediumtext-[14px] text-[#202020] flex items-center justify-center gap-[10px] w-full h-[40px] border border-[#009580] rounded-xl">
        ${{ data.publish_cost }} Add to cart
        <span class="material-symbols-outlined text-[#519935]">check_circle</span>
      </p>
      <p *ngIf="data.purchased" [routerLink]="'/course'" [queryParams]="{ id: data.sk }"
         class="cursor-pointer sm:hidden mt-[15px] font-Poppins font-mediumtext-[14px] text-[#202020] flex items-center justify-center gap-[10px] w-full h-[40px] border border-[#009580] rounded-xl">
        Go to course
      </p>
      <div class="sm:hidden bg-white rounded-xl border shadow mt-[20px] p-[15px]">
        <p class="font-Poppins font-medium text-[16px] mt-[0px]">This course includes:</p>
        <p class="font-Poppins font-medium text-[13px] mt-[10px] text-[#47464A] flex items-center gap-[10px]">
          <span class="material-symbols-outlined text-[#84818A] text-[14px]">
          smart_display
          </span>
          {{ totalTime }} on-demand video
        </p>
        <p class="font-Poppins font-medium text-[13px] mt-[10px] text-[#47464A] flex items-center gap-[10px]">
          <span class="material-symbols-outlined text-[#84818A] text-[12px]">
            sync
          </span>
          Full lifetime access
        </p>
      </div>
      <div class="sm:hidden bg-white rounded-xl border shadow mt-[20px] p-[15px]">
        <app-product-grower [user]="user"></app-product-grower>
      </div>

      <div class="bg-white rounded-xl border shadow mt-[30px] p-[20px] px-[15px] sm:px-[30px]">
        <p class="font-Poppins text-[18px] font-medium">What you'll learn</p>
        <div class="flex mt-[20px]">
          <div class="w-1/2" *ngFor="let rows of splitTeaching()">
            <p *ngFor="let item of rows" class="flex items-center gap-[20px] mb-[5px]">
              <svg xmlns="http://www.w3.org/2000/svg" width="17.59" height="13.41" viewBox="0 0 17.59 13.41">
                <path id="Shape" d="M5.59,10.58,1.42,6.41,0,7.82l5.59,5.59,12-12L16.18,0Z" fill="#009512"/>
              </svg>
              <span class="font-Poppins text-[14px] text-[#47464A]">
              {{ item }}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div class="bg-white rounded-xl border shadow mt-[20px] p-[20px] px-[15px] sm:px-[30px]">
        <div class="flex items-center justify-between">
          <p class="font-Poppins text-[16px] font-medium">Description</p>
          <span class="material-symbols-outlined cursor-pointer" (click)="openDescription=!openDescription">
                    {{ openDescription ? 'expand_less' : 'keyboard_arrow_down' }}
          </span>
        </div>
        <div [innerHTML]="description" class="font-Poppins text-[14px] text-[#47464A] mt-[15px]"
             [class]="{'hidden':!openDescription}"></div>
      </div>
      <div class="bg-white rounded-xl border shadow mt-[20px] py-[20px] px-[15px] sm:px-[30px]">
        <div class="flex items-center justify-between">
          <p class="font-Poppins text-[16px] font-medium">Requirements</p>
          <span class="material-symbols-outlined cursor-pointer" (click)="openRequirements=!openRequirements">
                    {{ openRequirements ? 'expand_less' : 'keyboard_arrow_down' }}
          </span>
        </div>
        <div [innerHTML]="data.requirements" class="font-Poppins text-[14px] text-[#47464A] mt-[15px]"
             [class]="{'hidden':!openRequirements}"></div>
      </div>
      <div>
        <p class="font-Poppins text-[16px] font-medium mt-[20px]">Course content</p>
        <div class="flex justify-between mt-[15px]">
          <p class="font-Poppins text-[14px] text-[#47464A]">{{ topics.length }} sections • {{ totalTime }} total
            length</p>
          <p class="font-Poppins text-[14px] text-[#47464A] cursor-pointer" (click)="expandAll()">Expand all</p>
        </div>
        <div class="flex gap-[5px]"></div>
        <div>
          <div *ngFor="let item of topics; let index = index"
               class="bg-white rounded-xl border shadow mt-[15px] py-[10px]">
            <div
              [class]="'w-full flex justify-between items-center h-[40px] px-[15px] sm:px-[30px] '+(item.is_open?'border-b mb-[15px]':'')">
              <div class="flex gap-[20px] items-center">
                  <span class="material-symbols-outlined cursor-pointer" (click)="item.is_open=!item.is_open">
                    {{ item.is_open ? 'expand_less' : 'keyboard_arrow_down' }}
                  </span>
                <div>
                  <p class="text-[14px] sm:text-[16px] font-Poppins font-semibold">{{ item.title }}</p>
                </div>
              </div>
              <p class="text-[12px] sm:text-[14px]">{{ item.classes.length }} videos • {{ item.time }}</p>
            </div>
            <div *ngIf="item.is_open" class="transition-all duration-500 px-[15px] sm:px-[30px]">
              <div *ngFor="let chapter of item.classes; let index2 = index"
                   class="flex items-center justify-between cursor-pointer mb-[20px]"
                   (click)="openChapter(index,index2)">
                <div>
                  <p class="text-[14px] sm:text-[16px] text-[#47464A] font-Poppins flex items-center gap-[20px]">
                    <span class="material-symbols-outlined">play_circle</span>
                    <span class="underline">{{ chapter.title }}</span>
                  </p>
                </div>
                <div>
                  <p class="text-[12px] font-Poppins font-light">
                    {{ chapter.time }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full mt-[40px] sm:mt-[24px] sm:mx-0 mb-4 sm:mb-2">
        <app-reviews-list classHeader="!mb-[0px] !mb-[10px] sm:!mb-[10px] !px-0"
                          classContainer="w-[calc(100vw-40px)] sm:w-[calc(100%-0px)] pr-[15px] sm:pr-0"
                          [pk]="data.sk" class="block w-full"></app-reviews-list>
      </div>
    </div>
    <div class="hidden sm:block sm:w-[calc(35%-40px)]">
      <div class="bg-white rounded-xl border shadow mt-[20px] p-[15px]">
        <div class="relative rounded-xl">
          <img [src]="data.banner||''" alt="" class="rounded-xl">
          <div class="absolute top-[0px] p-[10px] bg-[#02020261] w-full h-full rounded-xl">
            <p class="text-white">{{ title }}</p>
          </div>
        </div>
        <p *ngIf="!data.purchased" class="font-Poppins text-[24px] font-medium my-[10px]">${{ data.publish_cost }}</p>
        <p *ngIf="!data.purchased" (click)="openPay()"
           class="cursor-pointer font-Poppins font-mediumtext-[14px] text-[#202020] flex items-center justify-center gap-[10px] w-full h-[40px] border border-[#009580] rounded-xl">
          Add to cart
          <span class="material-symbols-outlined text-[#519935]">check_circle</span>
        </p>
        <p *ngIf="data.purchased" [routerLink]="'/course'" [queryParams]="{ id: data.sk }"
           class="cursor-pointer font-Poppins font-mediumtext-[14px] text-[#202020] flex items-center justify-center gap-[10px] w-full h-[40px] border border-[#009580] rounded-xl mt-[15px]">
          Go to course
        </p>
        <p class="font-Poppins font-medium text-[16px] mt-[40px]">This course includes:</p>
        <p class="font-Poppins font-medium text-[13px] mt-[10px] text-[#47464A] flex items-center gap-[10px]">
          <span class="material-symbols-outlined text-[#84818A] text-[14px]">
          smart_display
          </span>
          {{ totalTime }} on-demand video
        </p>
        <p class="font-Poppins font-medium text-[13px] mt-[10px] text-[#47464A] flex items-center gap-[10px]">
          <span class="material-symbols-outlined text-[#84818A] text-[12px]">
            sync
          </span>
          Full lifetime access
        </p>
      </div>
      <div class="bg-white rounded-xl border shadow mt-[20px] p-[10px]">
        <app-product-grower [user]="user"></app-product-grower>
      </div>
      <div class="bg-white rounded-xl border shadow mt-[20px] flex gap-[10px] overflow-auto ocultarScroll p-[10px]">
        <img [src]="image" alt="" class="rounded-xl h-[90px]  border-[0.5px]" *ngFor="let image of data.images">
      </div>
    </div>
  </div>
</app-sidebar-layout>
