import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-filter-icon',
  templateUrl: './filter-icon.component.svg',
  styleUrls: ['./filter-icon.component.scss']
})
export class FilterIconComponent {
  @Input() color = '#292D32';
  @Input() width = 24;
  @Input() height = 24;
}
