import {Component, ElementRef, inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {DeviceInfoService} from "../../../../services/device-info/device-info.service";
import {Router} from "@angular/router";
import {DialogComponent} from "../../../general/dialog/dialog.component";
import {UserInfoService} from "../../../../services/user-info/user-info.service";
import {CatalogsService} from "../../../../services/catalogs/catalogs.service";
import Editor from "@ckeditor/ckeditor5-build-classic";
import {ToastService} from "../../../../services/toast/toast.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss']
})
export class ProfileFormComponent implements OnInit, OnDestroy {
  router = inject(Router);
  http = inject(HttpClient);
  toastService = inject(ToastService);
  catalogService = inject(CatalogsService);
  @ViewChild('changePic', {static: false}) changePic!: DialogComponent;
  @ViewChild('addressDialog', {static: false}) addressDialog!: DialogComponent;
  @ViewChild('inputChangePic') inputChangePic: ElementRef | undefined;
  subscriptionFetch: Subscription | undefined;
  subscriptionCountries: Subscription | undefined;
  constructor(public deviceInfo: DeviceInfoService, private userInfo: UserInfoService) {
  }

  countSharables: number = 0;
  type: string = '';
  imageChangedEvent: any = null;
  aspectRatio: number = 1
  widthImage: number = 1
  croppedImage: string | null = null;
  cat_best_describes: any = [];
  data: any = {
    image_profile: '',
    banner: '',
    about_me: '',
    name: '',
    lastname: '',
    company: '',
    site: '',
    dial_code: '',
    phone: '',
    country: '',
    region: '',
    language: '',
    time_zone: '',
    currency: '',
    communication: [],
    best_describes: [],
    email_communication: false,
    phone_communication: false,
    allow_marketing: '',
    address: {
      fullName: "",
      dialCode: "",
      phone: "",
      streetNumber: "",
      streetName: "",
      apartmentOrUnit: "",
      postalCode: "",
      city: "",
      province: "",
      country: "",
    },
  }
  languages: { label: string, value: string }[] = []
  timeZones: string[] = []
  timeZone = '';
  currencies: string[] = []
  currency = '';
  countriesPhone: any[] = []
  dial_code = '';
  dial_code_address = '';
  countries: any[] = []
  regions: any[] = []
  country = '';
  region = '';
  address = '';

  ngOnInit() {
    this.getLanguages();
    this.getTimeZones();
    this.getCountriesPhone();
    this.getCountries();
    this.currencies = this.catalogService.currencies;
    let user = this.userInfo.me();
    this.data.id = user?.user.id;
    this.data.image_profile = user?.user.image_profile;
    this.data.banner = user?.user.banner;
    this.data.about_me = user?.user.about_me || '';
    this.data.name = user?.user.name;
    this.data.lastname = user?.user.lastname;
    this.data.company = user?.user.company;
    this.data.site = user?.user.site;
    this.dial_code = this.data.dial_code = user?.user?.dial_code || '';
    this.data.phone = user?.user.phone;
    this.data.country = user?.user.country || '';
    this.data.region = user?.user.region || '';
    this.data.language = user?.user.language || '';
    this.timeZone = this.data.time_zone = user?.user.time_zone || '';
    this.data.currency = user?.user.currency;
    this.currency = this.data.currency = user?.user.currency || '';
    this.data.communication = user?.user.communication;
    this.data.email_communication = user?.user.email_communication;
    this.data.phone_communication = user?.user.phone_communication;
    this.data.allow_marketing = user?.user.allow_marketing;
    this.data.address = user?.user.delivery_address || {};
    this.data.best_describes = user?.user.best_describes || [];
    this.dial_code_address = user?.user?.delivery_address?.dial_code || '';
    this.address = `${user?.user?.delivery_address?.address || ''}`
    this.location = user?.user?.delivery_address || null;
    this.countSharables = user?.user?.countSharables || 0;
    this.cat_best_describes = [];
    this.subscriptionFetch=this.http.get('/common/user-descriptions').subscribe({
      next: (response: any) => {
        this.cat_best_describes = response;
        this.subscriptionFetch?.unsubscribe()
      }
    });
  }

  goBack() {
    this.deviceInfo.goBack();
  }

  getTimeZones() {
    (this.catalogService.timeZones as any[]).forEach((tz: any) => {
      this.timeZones.push(`${tz.name} (${tz.gmt})`);
    });
  }

  getCountries() {
    this.subscriptionCountries=this.http.get('/common/countries').subscribe({
      next: async (response: any) => {
        this.countries = response as ICountry[];
        for (const cc of this.countries) {
          const index = this.countries.indexOf(cc);
          this.countries[index].regions = await this.http.get(`/common/regions/${cc.pk}`).toPromise();
        }
        let countryFound = this.countries.find(c => c.codeCountry === this.data.country)
        if (countryFound) this.country = countryFound.nameCountry;
        setTimeout(() => {
          if (countryFound.regions?.length) {
            this.regions = countryFound.regions;
            let regionFound = countryFound.regions.find((r: IRegion) => r.codeRegion === this.data.region)
            if (regionFound) this.region = regionFound.nameRegion;
          }
          this.subscriptionCountries?.unsubscribe();
        }, 500)
      }
    });
  }

  getCountriesPhone() {
    (this.catalogService.countriesFlags as any[]).forEach((tz: any) => {
      this.countriesPhone.push({
        flag: tz.flag,
        label: tz.name,
        dial_code: tz.dial_code,
        labelTemplate: `${tz.flag} ${tz.dial_code}`
      });
    });
  }

  setCountryPhone(val: any) {
    this.dial_code = val.dial_code;
    this.data.dial_code = val.dial_code;
  }

  setCountryPhoneAddress(val: any) {
    this.dial_code_address = val.dial_code;
    this.data.address.dial_code = val.dial_code;
  }

  setCountry(val: any) {
    this.country = val.nameCountry;
    this.data.country = val.codeCountry;
    this.data.region = '';
    this.regions = val.regions || []
  }

  setRegion(val: any) {
    this.region = val.nameRegion;
    this.data.region = val.codeRegion;
  }

  setTimeZone(val: string) {
    this.timeZone = val;
    this.data.time_zone = val;
  }

  setCurrency(val: string) {
    this.currency = val;
    this.data.currency = val;
  }

  subscriptionLanguages: Subscription | undefined;
  getLanguages() {
    this.subscriptionLanguages=this.catalogService.getAll('languages', '').subscribe({
      next: (response: any) => {
        this.languages = response.records.map((r: any) => {
          return {label: r.name, value: r.name}
        });
        this.subscriptionLanguages?.unsubscribe();
      }
    })
  }

  updateProfile() {
    this.userInfo.updateProfile(this.data).then(r => {
      this.toastService.show('Profile updated');
    });
  }

  selectImagePic(type: string) {
    this.type = type;
    this.aspectRatio = this.type === 'image_profile' ? 1 : 19 / 6;
    this.widthImage = this.type === 'image_profile' ? 120 : 500;
    this.inputChangePic?.nativeElement.click();
  }

  onChangePic() {
    this.imageChangedEvent = {target: {files: this.inputChangePic?.nativeElement.files}}
    this.changePic.open()
  }

  openMap = false;
  location: { lng: number, lat: number, address: string } | null = null;

  setAccept(data: any) {
    this.location = data;
    if (data) {
      this.updateAddress()
    }
    this.openMap = false;
    this.addressDialog.closeB()
  }

  openEditAddress() {
    this.addressDialog.open()
    this.openMap = true;
  }

  imageCropped(event: any) {
    if (event.base64 != null) {
      this.croppedImage = event.base64;
    }
  }

  saveProfilePic() {
    this.userInfo.updateProfileImage(this.data.image_profile).then((r: any) => {
      if (r?.user?.image_profile)
        this.data.image_profile = r.user.image_profile

      this.toastService.show('Image Profile updated successfully');
    });
    this.changePic.closeB();
  }

  async saveBannerPic() {
    await this.userInfo.updateBannerImage(this.data.banner).then((r: any) => {
      if (r?.user?.banner)
        this.data.banner = r.user.banner

      this.toastService.show('Banner Profile updated successfully');
    });
    this.changePic.closeB();
  }

  cropperReady() {
    if (this.croppedImage === null) return;
    const ext = this.deviceInfo.extractContentTypeFromBase64(this.croppedImage).split('/')[1];
    let name = (Math.random() * 1000).toFixed(0) + `imag.${['gif', 'png', 'jpg', 'jpeg', 'mp4'].includes(ext) ? ext : 'png'}`;
    this.data[this.type] = this.deviceInfo.dataURLtoFile(this.croppedImage.replace('data:', ''), name)
    this.croppedImage = '';
    this.imageChangedEvent = '';
    if (this.type === 'image_profile') {
      this.saveProfilePic()
    }
    if (this.type === 'banner') {
      this.saveBannerPic()
    }
  }

  closeCropper() {
    this.croppedImage = '';
    this.imageChangedEvent = '';
    this.changePic.closeB();
  }

  updateAddress() {
    if (!this.location) return;

    this.userInfo.updateAddress(this.location).then((r: any) => {
      this.toastService.show("Address Updated");
      console.error(r)
      this.address = this.location?.address || '--'
    }).finally(() => {
      this.addressDialog.closeB();
    });
  }

  inBestDescription(id: string) {
    return this.data.best_describes.includes(id);
  }

  cancelUpdateAddress() {
    this.addressDialog.closeB();
    this.data.address = this.userInfo.me()?.user.delivery_address;
  }

  onCheckboxChangeBest(event: any, id: string) {
    if (event.target.checked) this.data.best_describes.push(id);
    else this.data.best_describes = this.data.best_describes.filter((item: any) => item !== id);
  }

  countTypeOfGrower() {
    return (this.cat_best_describes.filter((item: any) => this.data.best_describes.includes(item.id) && item.name.toLowerCase().includes('grower'))).length;
  }

  protected readonly Editor = Editor;
  characterLimit = 500;

  onEditorChange({editor}: any) {
    const data = editor.getData();
    if (data.length > this.characterLimit) {
      // If data is longer than limit, slice it to the limit and update model
      editor.setData(data.slice(0, this.characterLimit));
    }
  }
  ngOnDestroy() {
    this.subscriptionFetch?.unsubscribe();
    this.subscriptionCountries?.unsubscribe();
    this.subscriptionLanguages?.unsubscribe();
  }
}
