<div
  #imageContainer
  (touchstart)="onTouchStart($event)"
  (touchmove)="onTouchMove($event)"
  (touchend)="onTouchEnd($event)"
  class="slide-post-container sm:hidden relative"
  [class]="{
  'sm:!block':useSlide!='default',
  'sm:!h-full': isViewFile,
  }"
>
  <div  *ngIf="getTypeFile(files[currentImageIndex]) === 'image'" class="w-full h-[50vh] sm:h-[400px] bg-cover sm:bg-contain bg-no-repeat bg-top sm:bg-center bg-black/70 rounded-lg"
        [class]="filters[currentImageIndex]"
        [class.!bg-contain]="isView"
        [class.!bg-center]="isView"
        [class.!rounded-none]="isView"
        [class.!h-[60vh]]="isView"
        [class.sm:!h-[400px]]="isView"
        [class.sm:!h-full]="isViewFile"
       [style.background-image]="'url(' + files[currentImageIndex] + ')'"></div>
<!--  <img *ngIf="getTypeFile(files[currentImageIndex]) === 'image'" [src]="files[currentImageIndex]" alt="Current Image" class="block sm:hidden w-full cover h-[50vh] rounded-lg">-->
  <div *ngIf="getTypeFile(files[currentImageIndex]) === 'video'" class="sm:h-[400px] bg-black/70 sm:flex sm:justify-center sm:items-center"
       [class.sm:!h-full]="isViewFile"
       >
    <app-video-player [classVideo]="['w-full', 'rounded-lg']" [classFilter]="filters[currentImageIndex]" [videoUrl]="files[currentImageIndex]"></app-video-player>
  </div>
  <div *ngIf="files.length>1" class="hidden sm:block absolute top-[25%] w-auto h-[50%]">
    <div class="flex justify-center items-center h-full">
      <button  class="flex justify-center items-center text-xl rounded-full bg-[#c5c5c570] text-white h-10 w-10" (click)="showPreviousImage()">
      <span class="material-symbols-outlined">
      chevron_left
      </span>
      </button>
    </div>
  </div>
  <div *ngIf="files.length>1" class="hidden sm:block absolute top-[25%] w-auto right-0 h-[50%]">
    <div class="flex justify-center items-center h-full">
      <button  class="flex justify-center items-center text-xl rounded-full bg-[#c5c5c570] text-white h-10 w-10" (click)="showNextImage()">
      <span class="material-symbols-outlined">
      chevron_right
      </span>
      </button>
    </div>
  </div>
</div>
<div class="flex justify-center items-center mt-2 sm:hidden" *ngIf="files.length>1 && useSlide=='default'">
  <div class="h-2 w-2 mx-1 bg-green2-native rounded-full" *ngFor="let medio of files; "></div>
</div>
<div  *ngIf="useSlide=='default'" class="hidden sm:flex sm:px-4 justify-between gap-2 h-[500px] sm:h-[490px]" [class]="{
  'sm:h-[290px]':isProfile,
  'sm:!px-0':files.length==1
  }">
  <div class="w-full sm:w-1/2"
       [class]="{
      '!w-full':files.length==1
    }"
  >
    <img  (click)="clickFile()" *ngIf="getTypeFile(files[0]) === 'image'"  [src]="files[0]" class="h-full w-full object-cover rounded-lg " [class]="filters[0]" alt="">
    <app-video-player (openFile)="clickFile()" *ngIf="getTypeFile(files[0]) === 'video'" [classFilter]="filters[0]" [classVideo]="['h-full', 'w-full', 'object-cover', 'rounded-lg']" class="block h-full {{filters[0]}}" [videoUrl]="files[0]"></app-video-player>
  </div>
  <div class="w-1/2 h-full flex flex-col gap-2 pb-2"
       [class]="{
      '!hidden':files.length==1
    }"
  >
    <div *ngFor="let medio of files.slice(1, 3); let indexOfelement=index;" class="relative h-1/2"
      [class]="{
      'h-full': files.length==2
      }">
      <img  (click)="clickFile()" *ngIf="getTypeFile(medio) === 'image'" [src]="medio" class="h-full w-full object-cover rounded-lg {{filters[indexOfelement+1]}}" alt="">
      <app-video-player  (openFile)="clickFile()" *ngIf="getTypeFile(medio) === 'video'" [classVideo]="['h-full', 'w-full', 'object-cover', 'rounded-lg']" [classFilter]="filters[indexOfelement+1]" [videoUrl]="medio"></app-video-player>
      <div *ngIf="(files.length-3>0) && indexOfelement==1" class="absolute top-0 w-full h-full flex justify-center items-center">
        <span class="bg-[#00000094] text-white font-bold rounded-3xl py-5 px-5 ">+ {{files.length-3}} </span>
      </div>
    </div>
  </div>
</div>
