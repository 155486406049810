import {Component, inject, OnInit} from '@angular/core';
import {UserInfoService} from "../../../../services/user-info/user-info.service";

@Component({
  selector: 'app-compleate-profile',
  templateUrl: './compleate-profile.component.html',
  styleUrls: ['./compleate-profile.component.scss']
})
export class CompleateProfileComponent implements OnInit {
  private userInfoService = inject(UserInfoService);
  percent = 0;
  ngOnInit() {
    this.percent = this.userInfoService.getPercentComplete();
  }
}
