import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {UserInfoService} from "../../../../services/user-info/user-info.service";
import {ToastService} from "../../../../services/toast/toast.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy{
  userInfoService = inject(UserInfoService);
  toastService = inject(ToastService);
  router = inject(Router);
  data = {
    newGift: true,
    giftClaimed: true,
    tags: true,
    comments: true,
    likesReactions: true,
    postShare: true,
    followAndFriendRequest: true,
    message: true,
    communityActivity: true,
  }
  subscriptionUserInfo: Subscription | undefined;
  ngOnInit() {
    this.subscriptionUserInfo = this.userInfoService.currentUserInfo.subscribe(async data => {
      if (data?.user?.id && data?.user?.notifications)
        this.data =  data.user.notifications;
    });
  }
  ngOnDestroy() {
    this.subscriptionUserInfo?.unsubscribe();
  }

  cancel() {
    this.router.navigateByUrl('/settings/profile');
  }

  save() {
    this.userInfoService.updateNotifications(this.data).then(r => {
      this.toastService.show("Updated notifications");
    }).catch(_=> {
      this.toastService.show("Error updating notifications");
    })
  }
}
