// custom-carousel.component.ts
import {Component, inject, Input, OnDestroy, OnInit} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {SearchAllService} from "../../../services/search-all/search-all.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-custom-carousel',
  templateUrl: './custom-carousel.component.html',
  standalone: true,
  imports: [
    NgForOf,
    NgIf
  ],
  styleUrls: ['./custom-carousel.component.scss']
})

export class CustomCarouselComponent implements OnInit, OnDestroy {
  searchAllService = inject(SearchAllService);
  private _filters = {}
  @Input() isMini = false;
  @Input() set filters(value: any) {
    if (value !== this._filters) {
      this._filters = value;
      this.getBanners();
    }
  }

  get filters(): any {
    return this._filters;
  }

  subscriptionBanner: Subscription | undefined;
  async getBanners() {
    this.subscriptionBanner = this.searchAllService.getFeatured({
      banner: 1
    }).subscribe({
      next: (response: any) => {
        this.slides = response.map((s: any) => {
          return {
            url: s.banner,
            text: s.description,
            action: s.action,
          }
        })
      }
    })
  }
  @Input() enableBtn = true;
  @Input() clsDiv = 'bg-gradient-to-r from-black to-transparent';
  @Input() slides:any = [];

  currentIndex: number = 0;
  timeoutId?: number;

  ngOnInit(): void {
    this.resetTimer();
    this.getBanners();
  }
  ngOnDestroy() {
    this.subscriptionBanner?.unsubscribe()
    window.clearTimeout(this.timeoutId);
  }
  resetTimer() {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }
    this.timeoutId = window.setTimeout(() => this.goToNext(), 5000);
  }

  goToPrevious(): void {
    const isFirstSlide = this.currentIndex === 0;
    const newIndex = isFirstSlide
      ? this.slides.length - 1
      : this.currentIndex - 1;

    this.resetTimer();
    this.currentIndex = newIndex;
  }

  goToNext(): void {
    const isLastSlide = this.currentIndex === this.slides.length - 1;
    const newIndex = isLastSlide ? 0 : this.currentIndex + 1;

    this.resetTimer();
    this.currentIndex = newIndex;
  }

  goToSlide(slideIndex: number): void {
    this.resetTimer();
    this.currentIndex = slideIndex;
  }

  getCurrentSlideUrl() {
    if(!this.slides[this.currentIndex]?.url) return '';

    return this.slides[this.currentIndex].url;
  }

  getCurrentSlideText() {
    if(!this.slides[this.currentIndex]?.text) return '';

    return this.slides[this.currentIndex].text;
  }
}
