<div class="flex px-[20px] sm:px-0 sm:block justify-between items-end">
  <div class="sm:mb-[10px] pt-4 sm:pt-0">
    <p class="text-[#292D2B] font-Poppins sm:font-Poppins font-semibold text-[24px] sm:text-[41px] leading-[1]">{{name}}</p>
    <div class="flex flex-col-reverse sm:flex-col">
      <div class="flex items-center sm:mt-1 mb-3 sm:mb-0" *ngIf="!isInfo">
        <p class="text-12px sm:text-[21px] text-[#9A9291] font-SF-Pro-Text">{{val_reviews}}</p>
        <app-star-rating
          [disabled]="true"
          [defaultRating]="val_reviews||0"
          class="text-[19px]"
          [styleLabel]="{
                'font-size':'17px'
              }"
        ></app-star-rating>
        <p class="text-[12px] sm:text-[21px] text-[#9A9291] font-SF-Pro-Text">({{count_reviews}})</p>
      </div>
      <p class="text-[10px] sm:text-[17px] font-Poppins text-[#7E7E7E] sm:my-2">{{genetics}}</p>
    </div>
  </div>
  <div class="flex flex-col">
    <div>
      <span class="block text-black font-Montserrat font-semibold text-center mr-[20px] sm:mr-0 text-[12px] sm:text-[22px] py-1 px-4 border border-[#AAAAAA] sm:h-[40px] w-auto sm:w-[126px] rounded-2xl capitalize">
        {{type}}
      </span>
    </div>
    <p class="my-1 sm:my-3">
      <span class="text-[#292D2B] text-[12px] sm:text-[20px] font-Montserrat font-medium mr-4">THC {{thc}}%</span>
      <span class="text-[#292D2B] text-[12px] sm:text-[20px] font-Montserrat font-medium">CBD {{cdb}}%</span>
    </p>
  </div>
</div>
