<app-sidebar-layout title="New story" [useHeaderEdit]="true" [showNavbar]="!deviceInfo.detectMobile()"
                    cls="!bg-[#777] sm:!bg-white">
  <div class="flex flex-col sm:flex-row relative items-start justify-between h-screen w-full sm:min-h-full sm:h-auto">
    <div
      class="w-full h-[90px] sm:w-[280px] sm:max-w-[280px] sm:min-w-[280px] sm:h-full sm:max-h-full sm:min-h-full bg-[#777] sm:bg-white rounded-t-2xl flex flex-col sm:gap-16 fixed z-10 overflow-auto bottom-0 sm:bottom- sm:sticky sm:top-[100px]">
      <div class="w-full">
        <!-- Profile Section -->
        <div class="hidden sm:flex items-center justify-between p-4 border-b">
          <div class="flex items-center">
            <span class="ml-3 font-semibold text-md text-gray-700">{{ getName() }}</span>
          </div>
<!--          <button>-->
<!--            <img src="https://img.icons8.com/ios-filled/50/000000/settings.png" alt="Settings"-->
<!--                 class="h-6 w-6 text-gray-600"/>-->
<!--          </button>-->
        </div>
        <!-- Story Creation Form -->
        <div class="px-4 py-2 hidden sm:block relative">
          <div class="absolute text-gray-600 px-4 pt-2 text-[14px] bg-white w-[calc(100%-40px)] left-5 top-2.5">Text
          </div>
          <div [contentEditable]="true" id="story"
               [(ngModel)]="story"
               (keyup)="updateStory($event)"
               (input)="updateStory($event)"
               class="bw-1 max-w-full overflow-auto focus:border-[#81CB52] h-[100px] rounded-lg p-[10px] outline-0 pt-7"
          ></div>
        </div>

        <!-- Text Style Dropdown -->
        <div class="px-4 py-2">
          <app-om-select id="category" class="hidden sm:block"
                         containerClass="!bg-white !py-[8px] !font-poppins !font-medium bw-1 border-gray !text-[14px]"
                         containerItemsClass="!max-h-fit"
                         itemsClass="bg-white border-[#EEEEEE] !text-black hover:bg-white hover:!text-green4-native !py-1 !text-[12px]"
                         color="gray" [options]="fonts" [(ngModel)]="font" required
                         #categorySelect="ngModel" [default]="font" placeholder="Select font type"
          />
        </div>

        <!-- Color Palette -->
        <div class="flex-wrap w-full gap-2 items-center p-4 hidden sm:flex" *ngIf="type === 'text'">
          <div *ngFor="let i of [].constructor(15); let i2 = index" class="h-6 w-6 rounded-full gradient-og-{{i2+1}}"
               (click)="setBg('gradient-og-'+(i2+1))"></div>
          <!-- More colors if needed -->
        </div>
      </div>
      <!--      <div class="flex gap-4 sm:hidden items-center justify-center">-->
      <!--        <p *ngFor="let f of fonts" class="{{f.value}}" (click)="font = f.value">{{ f.label }}</p>-->
      <!--      </div>-->
      <div class="flex justify-between">
        <div class="w-full relative flex gap-4 sm:hidden px-4 pt-1 ">
          <div class="rounded-full bg-black/40 p-3" (click)="openText()">
            <app-edit-icon color="#fff"></app-edit-icon>
          </div>
          <div class="rounded-full bg-black/40 p-3">
            <div class="absolute left-0 -translate-y-1/2 w-full bg-white rounded-2xl p-4"
                 *ngIf="showText">
              <div class="flex gap-4 sm:hidden items-center justify-center">
                <p *ngFor="let f of fonts" class="{{f.value}}"
                   (click)="font = f.value; showText = false;">{{ f.label }}</p>
              </div>
            </div>
            <app-text-format-icon color="#fff" (click)="showText = true"></app-text-format-icon>
          </div>
          <div class="rounded-full bg-black/40 p-3" *ngIf="type === 'text'">
            <div class="fixed left-0 bottom-0 min-h-[50px] w-full bg-white rounded-2xl p-4"
                 *ngIf="type === 'text' && showBgs">
              <div class="flex flex-wrap w-full gap-2 items-center p-4 pt-6 sm:block">
                <div *ngFor="let i of [].constructor(15); let i2 = index"
                     class="h-6 w-6 rounded-full gradient-og-{{i2+1}}"
                     (click)="setBg('gradient-og-'+(i2+1), false)"></div>
                <!-- More colors if needed -->
              </div>
            </div>
            <app-pallet-icon color="#fff" (click)="showBgs = true"></app-pallet-icon>
          </div>
        </div>
        <div class="flex gap-4 px-4 sm:hidden">
          <button type="button" routerLink="/home"
                  class="py-2 px-4 bg-gray-100 text-gray-800 rounded-full shadow-sm text-sm font-medium focus:outline-none cursor-pointer">
            <app-close-icon/>
          </button>
          <button type="button" (click)="saveStory()"
                  class="ml-3 py-2 px-4 bg-[#519935] text-white rounded-full shadow-sm text-sm font-medium focus:outline-none cursor-pointer">
            <app-plus-icon color="#fff"/>
          </button>
        </div>
      </div>
      <!-- Action Buttons -->
      <div class="sm:mt-24 px-4 py-3 text-right sm:px-6 hidden sm:block">
        <button type="button" routerLink="/home"
                class="py-2 px-4 bg-gray-100 text-gray-800 rounded-md shadow-sm text-sm font-medium focus:outline-none cursor-pointer">
          Discard
        </button>
        <button type="button" (click)="saveStory()"
                class="ml-3 py-2 px-4 bg-[#519935] text-white rounded-md shadow-sm text-sm font-medium focus:outline-none cursor-pointer">
          Add story
        </button>
      </div>
    </div>
    <div class="w-full pb-8 bg-[#777] sm:bg-white relative">
      <div class="absolute z-10 sm:relative sm:flex flex-col w-full items-center justify-center pt-4">
        <div class="flex items-center justify-center pt-4">
          <div class="rounded-2xl p-3 mb-2 {{getBg('img')}}" (click)="setType('img')">
            <app-gallery-icon [width]="30" [height]="30" [color]="getColor('img')"></app-gallery-icon>
          </div>
          <hr class="rotate-90 w-16">
          <div class="rounded-2xl p-3 mb-2 {{getBg('text')}}" (click)="setType('text')">
            <app-text-icon [width]="30" [height]="30" [color]="getColor('text')"></app-text-icon>
          </div>
        </div>
        <hr class="w-full sm:w-52">
      </div>
      <div class="w-full flex items-center justify-center mb-[100px] sm:mb-0 sm:p-10">
        <input type="file" (change)="onChangePic()" id="inputChangePic" class="opacity-0 w-0 h-0" name="change-pic"
               accept="image/*" #inputChangePic>
        <div
          class="w-full relative flex items-center justify-center sm:w-[40%] aspect-[9/16] bg-slate-300 bg-cover rounded-2xl {{bg}}"
          [style.background-image]="imgPreview ? 'url('+imgPreview+')' : 'revert-layer'" (click)="choosePhoto()">
          <div *ngIf="imgPreview" class="z-0 rounded-2xl absolute w-full h-full bg-gradient-to-b from-black/20 to-black/20"></div>
          <div
            class="z-0 text-white text-center font-Montserrat overflow-hidden max-h-full break-all p-4 text-[36px] pt-[calc(var(--sat)+16px)] {{font}}"
            [innerHTML]="story"></div>
        </div>
      </div>
    </div>
  </div>
  <app-dialog #changePic [title]="'Crop picture'"
              [classHeaderDefault]="'justify-center pt-5 pb-3 text-lg'"
              [classModal]="'sm:!min-w-[800px] sm:!w-[800px] bg-black/80 sm:bg-white text-white sm:text-black sm:relative'"
              classBody="">
    <div class="mx-[35px]">
      <image-cropper
        class="w-full !px-0"
        [resizeToWidth]="widthImage"
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="aspectRatio"
        format="png"
        output="base64"
        (imageCropped)="imageCropped($event)"
      ></image-cropper>
    </div>
    <div class="flex items-center justify-end gap-4 py-2 mx-[35px]">
      <button (click)="cropperReady()"
              class="flex justify-center w-[100px] !py-[6px] mb-2 h-fit gap-2 items-center font-Poppins font-normal bg-[#519935] text-white rounded-xl shadow-sm text-base">
        Save
      </button>
      <button (click)="closeCropper()"
              class="flex justify-center w-[100px] !py-[6px] border-2 border-[#DCDCDC] mb-2 h-fit gap-2 items-center font-Poppins font-normal bg-[#fff] text-[#202020] rounded-xl shadow-sm text-base">
        Discard
      </button>
    </div>
  </app-dialog>
  <app-dialog #editContent [title]="'Text'"
              [classHeaderDefault]="'justify-center pt-5 pb-3 text-lg'"
              [classModal]="'sm:!min-w-[800px] sm:!w-[800px] bg-black/80 sm:bg-white text-white sm:text-black sm:relative'"
              classBody="">
    <div class="mx-[35px]">
      <div [contentEditable]="true" #story1
           [(ngModel)]="storyTemp"
           (keyup)="updateStory($event, true)"
           (input)="updateStory($event, true)"
           class="bw-1 max-w-full !text-[#777] overflow-auto focus:border-[#81CB52] h-[100px] rounded-lg p-[10px] outline-0 pt-7"
      ></div>
    </div>
    <div class="flex items-center justify-end gap-4 py-2 mx-[35px]">
      <button (click)="saveText()"
              class="flex justify-center w-[100px] !py-[6px] mb-2 h-fit gap-2 items-center font-Poppins font-normal bg-[#519935] text-white rounded-xl shadow-sm text-base">
        Save
      </button>
      <button (click)="closeText()"
              class="flex justify-center w-[100px] !py-[6px] border-2 border-[#DCDCDC] mb-2 h-fit gap-2 items-center font-Poppins font-normal bg-[#fff] text-[#202020] rounded-xl shadow-sm text-base">
        Discard
      </button>
    </div>
  </app-dialog>
</app-sidebar-layout>
