<div class="scroll-smooth !h-auto flex flex-col">
  <div class="sticky bg-white sm:relative top-0">
    <div class="bg-white">
      <div class="bg-[#1b1b1b] sm:bg-black pt-[calc((var(--mw-height)*3)+var(--sat))]
        pb-[calc((var(--mw-height)*3))]
        sm:pt-[calc((var(--w-height)*10))]
        sm:pb-[calc((var(--w-height)*5))]
        rounded-br-[calc((var(--mw-height)*5))]
        rounded-bl-[calc((var(--mw-height)*5))]
        flex items-center justify-center">
        <img alt="logo" src="assets/LogoOG.svg"
             class="sm:hidden  w-[calc(var(--mw-width)*22)]">
        <img alt="logo" src="assets/logo_desk.svg"
             class="hidden sm:block sm:w-[calc(var(--w-height)*30)]">
        <div class="arrow-container left-0 top-0 absolute sm:bg-arrow
            w-[calc(var(--w-width)*4)] h-[calc(var(--w-width)*4)]
             flex items-center justify-center mt-0
            sm:mt-[calc(var(--w-height)*2)] sm:ml-[calc(var(--w-width)*2)]" (click)="goBack()">
          <ng-icon name="matArrowBack" class="mt-[calc((var(--mw-height)*8)+var(--sat))]
                  sm:mt-0 absolute sm:relative text-white cursor-pointer
                  text-4xl sm:text-[calc(var(--w-width)*3)] ml-[calc((var(--mw-width)*15))]
                  sm:ml-0 z-10 bg-none sm:text-5xl sm:rounded-full"></ng-icon>
        </div>
      </div>
    </div>
    <div class="pl-[calc((var(--mw-height)*3))] pr-[calc((var(--mw-height)*1.5))]
          pl-[calc((var(--w-width)*5))] pr-[calc((var(--w-width)*3))]">
      <h1 class="mt-[calc((var(--mw-height)*3))] text-[#1b1b1b]
        text-[calc((var(--mw-height)*3.5))] sm:text-[calc((var(--mw-height)*4))]">
        Terms & Conditions
      </h1>
      <p class="w-fit px-[calc((var(--mw-height)*1.4))] py-[calc((var(--mw-height)*1.5))]
        rounded-lg bg-[#81cc53] text-white
        sm:px-[calc((var(--mw-height)*1.6))] sm:py-[calc((var(--mw-height)*1))]
        text-[calc((var(--mw-height)*1.5))] sm:text-[calc((var(--mw-height)*1.5))]">
        Last update: 03 May 2024
      </p>
      <hr class="border-[#ececec] h-0.5 my-[calc(var(--mw-height)*3)] sm:w-[calc(var(--w-width)*60)]">
    </div>
  </div>
  <div class="pl-[calc((var(--mw-height)*3))] pr-[calc((var(--mw-height)*1.5))]
          sm:pl-[calc((var(--w-width)*5))] sm:pr-[calc((var(--w-width)*3))] flex">
    <div class="sm:w-[calc(var(--w-width)*80)]">
      <p class="t-subtitle" id="item1">1. Introduction</p>
      <div class="t-subcontent">
        <p class="text-[#8d949e]">By continuing to use the OmniGram platform you are agreeing to all the following
          statements. If you do not agree you should discontinue use of the Omnigram platform. These terms and
          conditions and privacy policies will be updated on a regular basis and continued use of the platform indicates
          your agreement to any updated terms.</p>
      </div>
      <p class="t-subtitle" id="item2">
        2. Community Standards
      </p>
      <div class="t-subcontent">
        <p class="text-[#8d949e]">
          I understand that the intention of the OmniGram site is to create a legal, friendly, engaging, giving
          environment where friends participate in cannabis culture and community. I will act in ways that follow this
          statement and if I operate outside of the confines of this intention, my OmniGram account can be suspended or
          blocked entirely. I will not engage in conduct or interactions that could be considered inflammatory. For
          example, religion, race, sexual misconduct, and / or politics do not have a home at OmniGram. We are trying to
          build a worldwide community where like-minded friends can bond.
        </p>
      </div>
      <p class="t-subtitle" id="item3">
        3. Legal Jurisdiction
      </p>
      <div class="t-subcontent">
        <p class="text-[#8d949e]"> I understand that the OmniGram platform is solely intended for the lawful use of
          cannabis enthusiasts and home growers. I understand OmnigGram is a Canadian company, and that some features of
          the platform will only be available to Canadian residents. I am at least 18 years old. I agree to ensure I
          understand the laws governing cannabis by conduction my own research, including reviewing guidance from the
          Government of Canada or the laws in my jurisdiction:
        </p>
        <a target="_blank"
           href="https://www.canada.ca/en/health-canada/services/drugs-medication/cannabis/laws-regulations/provinces-territories.php">https://www.canada.ca/en/health-canada/services/drugs-medication/cannabis/laws-regulations/provinces-territories.php</a>
        <a target="_blank"
           href="https://www.canada.ca/en/health-canada/services/drugs-medication/cannabis/laws-regulations.html">https://www.canada.ca/en/health-canada/services/drugs-medication/cannabis/laws-regulations.html</a>
        <p class="text-[#8d949e]">
          I understand that all disputes will be managed under Canadian and Alberta law in courts located in Edmonton
          Alberta and that OmniGram has not made any attempt to ensure the legality of the OmniGram platform outside of
          Canada. Omnigram has informed me that they do not encourage, recommend, or support the usage of the OmniGram
          platform outside of Canada. I understand that ultimately it is my responsibility to ensure I am within the
          law.
        </p>
      </div>
      <p class="t-subtitle" id="item4">4. Omnigram use of My Personal Information</p>
      <div class="t-subcontent">
        <p class="text-[#8d949e]">I understand that OmniGram keeps the personal information I provide on the platform
          and that because members share cannabis with other members, OmniGram may need to release this information if
          certain circumstances arise, such as, but not limited to: i) giving or receiving cannabis that is defective or
          harmful to others; ii) “giftors” communicating in ways that could be seen as illegal, such as suggesting the
          sales of black market cannabis while gifting to other members; and iii) hateful or harmful conduct.
        </p>
        <p class="text-[#8d949e]">I understand that I own all my data. I further provide Omnigram and its affiliates
          with an unlimited and unrestricted license to use my license to my data for their business purposes.
        </p>
        <p class="text-[#8d949e]">I acknowledge that by participating in the gifting portion of the platform, that
          unless I, the receiver of the gift make arrangements to access a Canada Post drop off box from Canada Post to
          receive cannabis (as a gift from the “giftor”), and I choose to have the gift delivered via regular mail
          services, the “giftor” will have knowledge of the “giftees” home address for delivery. As the “giftor” my
          address is not disclosed to the “giftee”. Return address is the Omnigram facilities.
        </p>
        <p class="text-[#8d949e]">I acknowledge that I will not post any copyrighted material on the platform.</p>
      </div>
      <p class="t-subtitle" id="item5">5. If I Chose to Participate in Gifting of Cannabis</p>
      <div class="t-subcontent">
        <p class="text-[#8d949e]">I understand OmniGram is a platform where friends, in real life, or by way of online
          presence (virtual), can legally gift cannabis and that if I choose to do so within the OmniGram site, it will
          be done lawfully and in good practice. As the “giftor”, the person gifting the cannabis to my friend, in real
          life or virtual, I do not expect anything in return that could be construed as “compensation” for my gift. As
          the “giftee”, the person receiving the gift, I acknowledge that the money paid to OmniGram is for services
          including but not limited to website hosting, envelopes, packaging, stamps, and shipping labour. The “giftee”
          may and is encouraged, but is not required to, give feedback on the “giftors” growing skills but providing
          feedback public or privately. This feedback is voluntary and not required to receive a gift. I understand the
          quality of the cannabis may vary.</p>
        <p class="text-[#8d949e]">I understand that only licensed dispensaries are permitted to sell cannabis in Canada
          and that I am prohibited to receive any compensation (for example monetary, exchange, trade, favours, etc.)
          for providing Cannabis in Canada to my friend network.</p>
        <p class="text-[#8d949e]">I understand that it is prohibited to provide cannabis to an individual who is under
          18-years of age.
        </p>
        <p class="text-[#8d949e]">I understand that Canadian law allows me to provide up to 30 grams of my legally
          home-grown cannabis to individuals in my friend network if the above conditions are met. I understand that the
          legislation can be reviewed at the link below and that I have reviewed Section 9(1) Distribution and 10(1)
          :</p>
        <a target="_blank" href="https://laws-lois.justice.gc.ca/eng/acts/C-24.5/">https://laws-lois.justice.gc.ca/eng/acts/C-24.5/</a>
      </div>
      <p class="t-subtitle" id="item6">6. If I Chose to Participate in Coaching</p>
      <div class="t-subcontent">
        <p class="text-[#8d949e]">I understand that by engaging in coaching or mentoring services, I accept that the
          service is provided by a coach or mentor who is not an employee of OmniGram, but as a contractor. I have the
          ability to rate the coach on his skills during and after the session. My feedback is a helpful way for the
          coach to become better as well.</p>
        <p class="text-[#8d949e]">I understand as a provider of coaching services on the OmnigGram platform that:</p>
        <ul class="list-disc text-[#8d949e] ml-6">
          <li>I am acting as an independent contractor and am responsible for my own taxes</li>
          <li>I am representing Omnigram and will act responsibly and in a positive manner. I will not abuse students,
            nor will I accept abuse from students.
          </li>
          <li>I acknowledge that the coach / student relationship is only possible because of the Omnigram platform and
            that Omnigram is keeping 25% of the revenue from my coaching courses and videos for facilitating this
            relationship between student and coach.
          </li>
          <li>I also acknowledge that if I, the coach, coach or mentor Omnigram members outside of the platform without
            written consent by Omnigram, I can be suspended or removed from Omnigram platform.
          </li>
        </ul>
      </div>
      <p class="t-subtitle" id="item7">7. Electronic Communications</p>
      <div class="t-subcontent">
        <p class="text-[#8d949e]">I understand that by accessing or using the Websites or Services, I consent to
          receiving communications from Onnigram electronically. Omnigram will communicate with me in a variety of ways,
          including, without limitation, by e-mail, text, in-app push notices, or by posting notices and messages on the
          Websites or through any of the Services. By accessing or using the Websites or Services, I willingly and
          knowingly agree that all contracts, notices, disclosures, agreements, and other communications provided
          electronically satisfy any legal requirement that such communications be in writing.</p>
      </div>
      <p class="t-subtitle" id="item8">8. Indemnification and Release</p>
      <div class="t-subcontent">
        <p class="text-[#8d949e]">If there is a dispute between users you understand and agree that Omnigram and its
          affiliates are under no obligation to become involved in such a dispute.</p>
        <p class="text-[#8d949e]">You agree to indemnify and hold Omnigram and its affiliates (and their respective
          owners, directors, managers, officers, partners, employees, independent contractors, agents, successors and
          assigns) harmless from any and all losses, damages, liabilities, claims, actions, judgments, awards,
          penalties, fines, costs, and expenses (including but not limited to attorneys’ fees and court costs) known and
          unknown, suspected and unsuspected, disclosed and undisclosed arising out of or relating to: (i) your use or
          misuse of the Websites or Services; (ii) your User Content; (iii) your breach or violation of these Terms, the
          Additional Terms, or the Privacy Policy; or (iv) your violation of applicable laws or regulations.
        </p>
        <p class="text-[#8d949e]">Omnigram will use reasonable efforts to notify you of any such claim for which
          Omnigram seeks indemnification hereunder upon becoming aware of it; provided, that the failure to provide such
          notice shall not relieve you of your obligation to provide indemnification hereunder. Omnigram reserves the
          right, at your expense, to assume the exclusive defense and control of any such matter for which you are
          required to indemnify us or any other indemnity hereunder, and you agree to cooperate with our defense of such
          claims. You agree not to settle any such matter without the prior written consent of Omnigram.
        </p>
        <p class="text-[#8d949e]">You hereby release and forever discharge Omnigram (and its owners, directors,
          managers, officers, partners, employees, independent contractors, agents, and successors and assigns of itself
          or its affiliates) from, and hereby waive and relinquish your rights with respect to, each and every past,
          present, and future dispute, claim, controversy, demand, right, obligation, liability, action, and cause of
          action of every kind and nature (including personal injuries, death, and property damage), arising out of or
          relating to any interactions with, or act or omission of, other Websites or Services or Third-Party Materials.
        </p>
      </div>
      <p class="t-subtitle" id="item9">9. Disclaimer Of Warranties</p>
      <div class="t-subcontent">
        <p class="text-[#8d949e]">You expressly acknowledge, understand, and agree that:</p>
        <ul class="list-disc text-[#8d949e]">
          <li>To the maximum extent permitted by law, the entire risk arising out of your access to and use of the
            Websites and Services, is assumed by you and remains with you.
          </li>
          <li>THE WEBSITES AND SERVICES ARE PROVIDED ON AN "AS IS", “WITH ALL FAULTS”, AND "AS AVAILABLE" BASIS.
            Omnigram and its affiliates, officers, directors, managers, shareholders, owners, employees, contractors,
            consultants, and agents expressly disclaim all warranties of any kind, whether express or implied,
            including, but not limited to the implied warranties of merchantability, fitness for a particular purpose,
            title and non-infringement and quality of service, or any other potential or actual warranty of any kind.
          </li>
          <li>Omnigram and its affiliates, officers, directors, managers, shareholders, owners, employees, contractors,
            consultants, and agents make no guarantee or warranty that: (a) the Websites and Services will satisfy your
            needs and requirements or will be compatible with your equipment; (b) the Websites and Services, and all
            affiliated websites, features, services, communications, and applications, will be comprehensive,
            uninterrupted, timely, secure, or error-free, or be free from loss, destruction, corruption, online attack,
            viruses, worms, or other invasive, harmful, or corrupted or other related intrusions; (c) the information,
            data, or results realized or obtained from your use of the Websites will be accurate, up to date,
            satisfactory or reliable or fit or useful for any specific purpose; (d) the quality or value of any
            properties, services, products, information, or other materials purchased or obtained by you through the
            Websites and Services will meet your expectations; (e) that any offer made or message sent will be
            successfully transmitted, received, and processed; and (f) any errors in the guidelines, software, or
            protocols will be corrected or resolved.
          </li>
          <li>Omnigram is unable to guarantee consistent, continuous, or secure access to its websites, applications,
            services, programs, content, and network, and operation of the Websites and Services may be interfered with
            or delayed by numerous potential factors outside of Omnigrams’ control.

          </li>
          <li>Any product or program downloaded or otherwise obtained through the use of the Websites and Services is
            accessed at your own discretion and risk, and you will be solely responsible and fully liable for any damage
            to your computer system or mobile device, loss of data, or any other loss or damage that results from the
            download or use of any such product, material, application, feature, or other program.

          </li>
          <li>Any product or program downloaded or otherwise obtained through the use of the Websites and Services is
            accessed at your own discretion and risk, and you will be solely responsible and fully liable for any damage
            to your computer system or mobile device, loss of data, or any other loss or damage that results from the
            download or use of any such product, material, application, feature, or other program.

          </li>
          <li>No information, including medical or legal information, strategy, guidelines, or related content, whether
            oral or written, obtained by you from Omnigram or from the Websites, Services, or relevant social media
            pages shall create any warranty, guarantee, or strategy not expressly stated in these Terms.
          </li>
          <li>Omnigram cannot and does not control any User Content and, as such, does not guarantee the accuracy,
            integrity, quality, safety, legality, morality, and/or authenticity of such content, the truth or accuracy
            of users’ content, the ability of listings to confirm product availability or pricing, or the ability of
            users to confirm experiences from using or purchasing specific products discussed or sold on the Websites or
            Services.
          </li>
        </ul>
      </div>
      <p class="t-subtitle" id="item10">10. No Waiver</p>
      <div class="t-subcontent">
        <p class="text-[#8d949e]">The failure of Omnigram to enforce any right or provision of these Terms will not constitute a waiver of future enforcement of that right or provision. The waiver of any such right or provision will be effective only if in writing and signed by a duly authorized representative of Omnigram. Except as expressly set forth in these Terms, the exercise by either party of any of its remedies under these Terms will be without prejudice to its other remedies under these Terms or otherwise.</p>
      </div>
    </div>
    <div class="hidden sm:block sm:sticky sm:top-[calc(var(--w-height)*5)] sm:h-[90vh] overflow-auto ocultarScroll w-[calc(var(--w-width)*20)]
          sm:pl-[calc(var(--w-width)*4)] sm:pr-[calc(var(--w-width)*2)]
          sm:text-xs
          text-[#81898d]">
      <ol class="list-decimal flex flex-col gap-4">
        <li><a [routerLink]="['/terms-and-conditions']" fragment="item1">Introduction</a></li>
        <li><a [routerLink]="['/terms-and-conditions']" fragment="item2">Community Standards</a></li>
        <li><a [routerLink]="['/terms-and-conditions']" fragment="item3">Legal Jurisdiction</a></li>
        <li><a [routerLink]="['/terms-and-conditions']" fragment="item4">Omnigram use of My Personal Information</a></li>
        <li><a [routerLink]="['/terms-and-conditions']" fragment="item5">If I Chose to Participate in Gifting of Cannabis</a></li>
        <li><a [routerLink]="['/terms-and-conditions']" fragment="item6">If I Chose to Participate in Coaching</a></li>
        <li><a [routerLink]="['/terms-and-conditions']" fragment="item7">Electronic Communications</a></li>
        <li><a [routerLink]="['/terms-and-conditions']" fragment="item8">Indemnification and Release</a></li>
        <li><a [routerLink]="['/terms-and-conditions']" fragment="item9">Disclaimer Of Warranties</a></li>
        <li><a [routerLink]="['/terms-and-conditions']" fragment="item10">No Waiver</a></li>
<!--        <li><a href="#11">Review/Feedback Policy</a></li>-->
<!--        <li><a href="#12">Indemnification and Release</a></li>-->
<!--        <li><a href="#13">Dispute Resolution</a></li>-->
<!--        <li><a href="#14">Disclaimer Of Warranties</a></li>-->
<!--        <li><a href="#15">Limitation Of Liability</a></li>-->
<!--        <li><a href="#16">Choice Of Law; Venue</a></li>-->
<!--        <li><a href="#17">Entire Agreement</a></li>-->
<!--        <li><a href="#18">No Waiver</a></li>-->
<!--        <li><a href="#19">No Third-Party Beneficiaries</a></li>-->
<!--        <li><a href="#20">Support Or Maintenance</a></li>-->
<!--        <li><a href="#21">United States Export & Foreign Assets Control Regulations</a></li>-->
<!--        <li><a href="#22">Severability</a></li>-->
<!--        <li><a href="#23">No Right Of Survivorship And Non-Transferability</a></li>-->
<!--        <li><a href="#24">Captions</a></li>-->
<!--        <li><a href="#25">Contact Us</a></li>-->
      </ol>
    </div>
  </div>
</div>
