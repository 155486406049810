import {
  Component,
  ElementRef, EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnChanges, OnInit,
  Output,
  Renderer2,
  SimpleChanges, ViewChild
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-om-input',
  templateUrl: './om-input.component.html',
  styleUrls: ['./om-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OmInputComponent),
      multi: true,
    },
  ],
})

export class OmInputComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() inputClass?: string = '';
  @Input() readonly ?: boolean = false;
  @Input() label: string = '';
  @Input() list: string = '';
  @Input() maxlength: number = 250;
  @Input() color?: string = 'black';
  @Input() inputMode?: string = 'text';
  @Input() id: string = 'id';
  @Input() type: string = 'text';
  @Input() placeholder: string = '';
  @Input() defaults = '';
  @Input() useIcon: boolean = false;
  @Output() keyuptext = new EventEmitter<string>();
  @Output() keyEnter = new EventEmitter<string>();

  value: string | number | undefined | null = '';
  @ViewChild('container', {static: true}) container?: ElementRef;

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  onKeyup(e: any) {
    this.keyuptext.emit((e.target as HTMLInputElement).value);
  }

  onKeyupEnter(e: any) {
    this.keyEnter.emit((e.target as HTMLInputElement).value);
  }

  ngAfterViewInit() {
    const inputEl = this.el.nativeElement.querySelector('input');
    this.renderer.setAttribute(inputEl, 'inputmode', this.inputMode || 'text');
    this.renderer.setAttribute(inputEl, 'autocomplete', 'off');
    if (this.list)
      this.renderer.setAttribute(inputEl, 'list', this.list || '');
  }

  onChange = (value: string | number) => {
  };
  onTouched = () => {
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes['inputModel']?.currentValue) {
      this.ngAfterViewInit();
    }
    this.value = changes['default']?.currentValue;
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  touch() {
    this.onTouched();
  }

  setValue(e: any) {
    this.writeValue((e.target as HTMLInputElement).value);
    this.onChange(this.value as any)
  }

  getInputClass(): { [key: string]: boolean } {
    let cssClasses: { [key: string]: boolean } = {
      'om-input-gray': this.color === 'gray',
      'om-input-white': this.color === 'white',
      'placeholder:!text-white/80': this.color === 'black',
    };

    if (this.inputClass) {
      this.inputClass.split(' ').forEach(s => {
        cssClasses[s] = true;
      })
    }
    if (this.useIcon) {
      cssClasses['icon-input'] = true;
    }
    return cssClasses;
  }

  getName() {
    return 'n' + Math.random();
  }
  allowOnlyNumbers(event: KeyboardEvent) {
    if (event.key === 'Backspace' || event.key === 'Delete' || event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      return;
    }
    const charCode = event.key.charCodeAt(0);
    if ((charCode < 48 || charCode > 57) && this.type==='number' ) {
      event.preventDefault();
    }
    const input = event.target as HTMLInputElement;
    if (input.value.length >= this.maxlength) {
      input.value = input.value.slice(0, this.maxlength-1);
    }
  }
  ngOnInit(): void {
  }
}
