<!--<div class="flex items-center gap-2 mx-4 mt-2 pl-[40px]">-->
<!--  <div *ngFor="let item of files; let i = index" class="relative">-->
<!--    <span class="material-symbols-outlined absolute top-1 right-1 text-[1rem] cursor-pointer rounded-full bg-white" (click)="removeImage(i)">close</span>-->
<!--    <img *ngIf="item.type === 'image'" [src]="item.src" class="h-[180px] object-cover rounded-2xl" alt="">-->
<!--    <video *ngIf="item.type === 'video'" class="h-[90px] object-cover rounded-2xl" controls>-->
<!--      <source [src]="item.src" type="video/mp4"> Tu navegador no soporta el tag de video.-->
<!--    </video>-->
<!--  </div>-->
<!--</div>-->
<input type="file" #fileInput (change)="onFileSelected($event)" multiple style="display:none">
<div class="w-full absolute sm:relative bottom-[40px] sm:bottom-0">
  <div class="flex justify-center sm:justify-normal sm:mx-14" *ngIf="visibleEmojis">
    <emoji-mart [darkMode]="false" [showPreview]="false" class="sm:absolute z-[50] sm:ml-[70px] sm:bottom-0"
                (emojiClick)="addEmoji($event)" [excludeElementClass]="['container-exclude']"
                (appClickOutside)="onClickOutside(0)"></emoji-mart>
  </div>
  <div class="flex justify-center sm:justify-normal sm:mx-14" *ngIf="visibleGif">
    <app-gif-search class="sm:absolute z-[50] sm:ml-[70px] sm:bottom-1 w-5/6 sm:w-[400px]" (sendGif)="getGif($event)"
                    [excludeElementClass]="['container-exclude']"
                    (appClickOutside)="onClickOutsideGif(0)"></app-gif-search>
  </div>
</div>
<div class="flex gap-1 items-center w-full sm:px-3" (appClickOutside)="isFocused = false" (focus)="isFocused = true">
  <span class="hidden sm:flex items-center justify-center border-[1px] border-[#bdc0c4] rounded-full">
    <svg id="vuesax-linear-add" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"
         (click)="fileInput.click()">
      <g id="vuesax_linear_add" data-name="vuesax/linear/add">
        <g id="add">
          <path id="Vector" d="M0,0H12" transform="translate(5 12)" fill="none" stroke="#bdc0c4" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="1.5"/>
          <path id="Vector-2" data-name="Vector" d="M0,12V0" transform="translate(11 6)" fill="none" stroke="#bdc0c4"
                stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
        </g>
      </g>
    </svg>
  </span>
  <span class="hidden sm:block" (click)="fileInput.click()">
    <svg xmlns="http://www.w3.org/2000/svg" width="19.334" height="19.334" viewBox="0 0 19.334 19.334">
      <path id="Vector"
            d="M13.717,0h-8.1C2.1,0,0,2.1,0,5.616v8.1a6.5,6.5,0,0,0,.541,2.745c.831,1.837,2.61,2.871,5.075,2.871h8.1c3.519,0,5.616-2.1,5.616-5.616v-8.1C19.334,2.1,17.236,0,13.717,0Zm4.041,10.15a2.155,2.155,0,0,0-2.726,0L11.011,13.6a2.155,2.155,0,0,1-2.726,0l-.329-.271A2.147,2.147,0,0,0,5.4,13.2L1.788,15.622a5.155,5.155,0,0,1-.338-1.9v-8.1C1.45,2.89,2.89,1.45,5.616,1.45h8.1c2.726,0,4.166,1.44,4.166,4.166v4.64Z"
            fill="#bdc0c4"/>
    </svg>
  </span>
  <span class="hidden sm:block">
    <svg class="container-exclude" (click)="openGif(0)" width="20" height="20" viewBox="0 0 48 48"
         xmlns="http://www.w3.org/2000/svg">
      <defs>
        <style>
          .a {
            fill: none;
            stroke: #000000;
            stroke-linecap: round;
            stroke-linejoin: round;
          }
        </style>
      </defs>
      <path class="a" d="M40.5,42.5H7.5a2,2,0,0,1-2-2V7.5a2,2,0,0,1,2-2h33a2,2,0,0,1,2,2v33A2,2,0,0,1,40.5,42.5Z"/>
      <line class="a" x1="25.21" y1="17.03" x2="25.21" y2="30.97"/>
      <path class="a"
            d="M20.82,21.7A4.66,4.66,0,0,0,15.91,17,4.84,4.84,0,0,0,11.5,22V26.3A4.67,4.67,0,0,0,16.16,31h0a4.66,4.66,0,0,0,4.66-4.67H16.16"/>
      <line class="a" x1="29.53" y1="17.03" x2="36.5" y2="17.03"/>
      <line class="a" x1="29.53" y1="24" x2="34.06" y2="24"/>
      <line class="a" x1="29.53" y1="17.03" x2="29.53" y2="30.97"/>
    </svg>
  </span>
  <div #inputContainer
       class="relative w-full flex px-4 py-2 justify-between input bg-[#FAFAFA] rounded-[10px] font-poppins cursor-pointer"
       [class]="{
          'rounded-full': (!comment.trim() && files.length==0 && !isFocused)
        }">
    <div class="flex items-center gap-2 sm:hidden">
      <div class="icon cursor-pointer">
        <span class="flex items-center justify-center border-[1px] border-[#bdc0c4] rounded-full">
          <svg id="vuesax-linear-add" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"
               (click)="fileInput.click()">
            <g id="vuesax_linear_add" data-name="vuesax/linear/add">
              <g id="add">
                <path id="Vector" d="M0,0H12" transform="translate(5 12)" fill="none" stroke="#bdc0c4"
                      stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                <path id="Vector-2" data-name="Vector" d="M0,12V0" transform="translate(11 6)" fill="none"
                      stroke="#bdc0c4" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
              </g>
            </g>
          </svg>
        </span>
      </div>
      <div class="icon cursor-pointer">
        <svg class="container-exclude" (click)="openGif(0)" width="20" height="20" viewBox="0 0 48 48"
             xmlns="http://www.w3.org/2000/svg">
          <defs>
            <style>
              .a {
                fill: none;
                stroke: #000000;
                stroke-linecap: round;
                stroke-linejoin: round;
              }
            </style>
          </defs>
          <path class="a" d="M40.5,42.5H7.5a2,2,0,0,1-2-2V7.5a2,2,0,0,1,2-2h33a2,2,0,0,1,2,2v33A2,2,0,0,1,40.5,42.5Z"/>
          <line class="a" x1="25.21" y1="17.03" x2="25.21" y2="30.97"/>
          <path class="a"
                d="M20.82,21.7A4.66,4.66,0,0,0,15.91,17,4.84,4.84,0,0,0,11.5,22V26.3A4.67,4.67,0,0,0,16.16,31h0a4.66,4.66,0,0,0,4.66-4.67H16.16"/>
          <line class="a" x1="29.53" y1="17.03" x2="36.5" y2="17.03"/>
          <line class="a" x1="29.53" y1="24" x2="34.06" y2="24"/>
          <line class="a" x1="29.53" y1="17.03" x2="29.53" y2="30.97"/>
        </svg>
      </div>
      <div class="icon cursor-pointer">
        <svg (click)="fileInput.click()" xmlns="http://www.w3.org/2000/svg" width="19.334" height="19.334"
             viewBox="0 0 19.334 19.334">
          <path id="Vector"
                d="M13.717,0h-8.1C2.1,0,0,2.1,0,5.616v8.1a6.5,6.5,0,0,0,.541,2.745c.831,1.837,2.61,2.871,5.075,2.871h8.1c3.519,0,5.616-2.1,5.616-5.616v-8.1C19.334,2.1,17.236,0,13.717,0Zm4.041,10.15a2.155,2.155,0,0,0-2.726,0L11.011,13.6a2.155,2.155,0,0,1-2.726,0l-.329-.271A2.147,2.147,0,0,0,5.4,13.2L1.788,15.622a5.155,5.155,0,0,1-.338-1.9v-8.1C1.45,2.89,2.89,1.45,5.616,1.45h8.1c2.726,0,4.166,1.44,4.166,4.166v4.64Z"
                fill="#bdc0c4"/>
        </svg>
      </div>
    </div>
    <textarea #emojiTextarea [(ngModel)]="comment" (keyup.enter)="saveComment()" class="input-comment
              ml-[5px] sm:ml-0
              container-exclude
              w-[calc(100%-110px)]
              focus-visible:outline-none
              input
              bg-[#FAFAFA]
              resize-none" [class]="{
                'w-full': (comment.trim() || files.length>0 || isFocused),
              }" rows="1" placeholder="Write a message..." (focus)="isFocused = true"></textarea>
    <div class="flex items-center gap-2 justify-end" [class]="{
                'justify-between': (comment.trim() || files.length>0 || isFocused),
              }">
      <div class="flex items-center">
        <div class="icon cursor-pointer hidden sm:block">
          <svg class="container-exclude" width="20" height="20" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg"
               (click)="openEmoji(0)">
            <defs>
              <style>
                .cls-1 {
                  fill: #2e2f42;
                }
              </style>
            </defs>
            <g data-name="Layer 2" id="Layer_2">
              <g id="Export">
                <path class="cls-1"
                      d="M64,3A61,61,0,1,1,3,64,61.06,61.06,0,0,1,64,3m0-3a64,64,0,1,0,64,64A64,64,0,0,0,64,0Z"/>
                <path class="cls-1"
                      d="M85.57,61.33a1.5,1.5,0,0,1-1.5-1.5v-9a1.5,1.5,0,0,1,3,0v9A1.51,1.51,0,0,1,85.57,61.33Z"/>
                <path class="cls-1"
                      d="M42.43,61.33a1.51,1.51,0,0,1-1.5-1.5v-9a1.5,1.5,0,0,1,3,0v9A1.5,1.5,0,0,1,42.43,61.33Z"/>
                <path class="cls-1"
                      d="M64,94.81c-.93,0-1.87,0-2.84-.1a40.4,40.4,0,0,1-20.23-7.22A1.5,1.5,0,1,1,42.6,85a37.39,37.39,0,0,0,18.72,6.69A37.93,37.93,0,0,0,85.41,85a1.5,1.5,0,1,1,1.7,2.46A41.09,41.09,0,0,1,64,94.81Z"/>
              </g>
            </g>
          </svg>
        </div>
        <div class="icon flex sm:hidden items-center cursor-pointer">
          <span class="material-symbols-outlined text-[#92929d]" (click)="saveComment()">send</span>
        </div>
      </div>
    </div>
  </div>
  <div class="icon hidden sm:flex items-center cursor-pointer">
    <span class="material-symbols-outlined text-[#92929d]" (click)="saveComment()">send</span>
  </div>
</div>
