import {Component, inject, OnInit} from '@angular/core';
import {UserInfoService} from "../../../../services/user-info/user-info.service";

@Component({
  selector: 'app-sign-in-method',
  templateUrl: './sign-in-method.component.html',
  styleUrls: ['./sign-in-method.component.scss']
})
export class SignInMethodComponent implements OnInit {
  userInfo = inject(UserInfoService);
  user: any = {};
  ngOnInit() {
    this.user = this.userInfo.me()?.user;
  }
}
