<app-carousel *ngIf="sharables?.length" class="w-full" [isStrainFinder]="true" [moveAmount]="0.33">
  <header class="flex justify-between sm:justify-normal gap-2 sm:gap-4 items-center mb-[10px] sm:mb-[-10px]">
    <h1 class="text-[20px] sm:text-[25px] font-semibold font-Montserrat" [class]="classTitle">{{title}}</h1>
    <p class="flex items-center text-green2-native mt-[3px] text-[15px] font-medium font-Montserrat" routerLink="/gifts-search">
      <span class="sm:hidden ">View All</span>
      <span class="sm:hidden material-symbols-outlined  items-center justify-center border-green4-native w-[18px] h-[18px] ml-[-2px] text-[18px] sm:text-[16px]  sm:w-auto rounded-full border-0">navigate_next</span>
    </p>
  </header>
  <header-action>
    <span class="block text-[#519935] sm:text-black cursor-pointer font-semibold" routerLink="/gifts-search">See All</span>
  </header-action>
  <app-card-mini class="block w-[85%] mr-[22px] sm:mr-0 sm:w-[385px]" [isInfo]="item.user.username === 'Info strain'" (selectGift)="selectedGift($event)" [btndetailVisable]="false" [isStrainFinder]="true" [useTemplateFooter]="true" [mine]="true" *ngFor="let item of sharables" [data]="item">
    <footer class="block w-full px-[10px] sm:px-[14px]">
      <div class="flex justify-between">
        <div class="flex gap-4 container-avatar">
          <div class="relative">
            <img *ngIf="item.user.username !== 'Info strain'" [src]="item.user.image_profile || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'" class="w-[35px] h-[35px] sm:w-[50px] sm:h-[50px] rounded-full object-cover " alt="">
            <img *ngIf="item.user.username === 'Info strain'" src="/assets/logo.png" class="w-[35px] h-[35px] sm:w-[50px] sm:h-[50px] object-cover " alt="">
            <div *ngIf="item.user.is_founder===1" class="absolute bottom-0 right-0 w-[20px] h-[20px] sm:w-[45%] sm:h-[45%] p-[2px] rounded-[15px] sm:rounded-full bg-white">
              <img src="assets/ogfc.png" alt="" class="object-cover w-full">
            </div>
          </div>
          <div class="flex flex-col justify-center">
            <p class="text-[14px] text-[#FFFFFF] font-bold truncate max-w-[90px]">{{ item.user.name||item.user.username }}</p>
            <p class="text-[12px] text-[#FFFFFF]" *ngIf="item.user.username !== 'Info strain'">{{item.user.region}} Grower</p>
          </div>
        </div>
        <div class="flex items-center gap-2" *ngIf="item.user.username !== 'Info strain'">
          <button class="text-[15px] font-semibold sm:text-[13px] text-[#FFFFFF] flex justify-center items-center w-fit px-6 h-[35px] rounded-full bg-[#81CB52]" (click)="claimgift(item)">{{item.publish_cost ? 'Add to Cart' : 'Claim'}}</button>
        </div>
      </div>
    </footer>
  </app-card-mini>
</app-carousel>
