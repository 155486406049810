import {ApplicationRef, Component, computed, effect, HostListener, inject, OnDestroy, OnInit} from '@angular/core';
import { AuthService } from './auth/services/auth.service';
import { AuthStatus } from './auth/interfaces';
import { Router } from '@angular/router';
import {SwUpdate} from "@angular/service-worker";
import {concat, first, interval} from "rxjs";
import {ChatService} from "./services/chat/chat.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'omni-gram-app';
  private authService = inject(AuthService);
  private chatService = inject(ChatService);
  private appRef = inject(ApplicationRef);
  private updates = inject(SwUpdate);
  private urlGuest=['/','/login','/register','/terms-and-conditions','/founders-club'];
  private router= inject(Router);
  public currentUrl='';
  public mwHeight = 0;
  public mwWidth = 0;
  public wHeight = 0;
  public wWidth = 0;
  constructor() {
    // Enable immediate synchronization with the server.
    this.appRef.isStable.pipe(first(stable => stable === true)).subscribe(() => this.updates.checkForUpdate());

    // When an update is available, call our method to show the update prompt.
    this.updates.available.subscribe(event => {
      this.promptUserToUpdate(() => this.updates.activateUpdate().then(() => document.location.reload()));
    });
  }
  @HostListener('window:unload', ['$event'])
  unloadHandler(event: Event) {
    event.preventDefault();
    event.returnValue = false;
    this.chatService.disconnect()
    return false;
  }

  ngOnDestroy() {
    this.chatService.disconnect()
  }

  promptUserToUpdate(onConfirm: () => void): void {
    // Display the update prompt to the user and call `onConfirm` if they agree to update.
    // Implement this method according to your UI requirements.
    if (window.confirm('There is a new version available. Do you want to update?')) {
      onConfirm();
    }
  }

  setPropsElement() {
    document.documentElement.style.setProperty('--mw-height', (this.mwHeight/100).toString()+'px');
    document.documentElement.style.setProperty('--mw-width', (this.mwWidth/100).toString()+'px');
    document.documentElement.style.setProperty('--w-width', (this.wWidth/100).toString()+'px');
    document.documentElement.style.setProperty('--w-height', (this.wHeight/100).toString()+'px');
  }
  setSize () {
    this.wWidth = window.innerWidth;
    this.wHeight = window.innerHeight;
  }
  ngOnInit() {
    if ('Notification' in window && Notification.permission !== 'granted' && Notification?.requestPermission) {
      Notification.requestPermission().then((permission) => {});
    }
    this.mwHeight = window.innerHeight;
    this.mwWidth = window.innerWidth;
    setTimeout(() => {
      this.setSize();
      this.setPropsElement();
    }, 100)
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.setSize();
    this.setPropsElement();
  }
  public finishedAuthCheck = computed<boolean>(() => {
    const authStatus = this.authService.authStatus()();
    if (authStatus === AuthStatus.checking) return false;
    return true;
  });
  getAuthStatus(){
    return this.authService.authStatus()();
  }
  authStatusChangeEffect = effect(() => {
    this.currentUrl=location.pathname
    switch (this.authService.authStatus()()) {
      case AuthStatus.checking:
        return;
      case AuthStatus.authenticated:
        if (this.urlGuest.includes(this.currentUrl) && !this.currentUrl.includes('founders-club')) this.router.navigateByUrl('/home');
        return;
      case AuthStatus.notAuthenticated:
        if (!this.urlGuest.includes(this.currentUrl)) this.router.navigateByUrl('/');
        return;
    }
  });

  disabledLayout() {
    let disabledLayout = false;
    const currentUrlWithoutParams = this.router.url.split('?')[0].split('#')[0];
    if(['/settings/profile','/friends','/settings/overview',
      '/settings/sign-in-method','/settings/notifications',
      '/settings/support','/home', '/gift-send', '/claim-gift',
      '/gift-center','/strain-finder','/categories-effects',
      '/market-search','/growers-search','/growers-home','/gifts-search','/effect','/messages',
      '/coaching', '/add-product', '/add-course', '/coaching-all','/mentors','/course','/course/details','/communities',
      '/add-story','/community','/add-community','/search-og','/founders-club', '/terms-and-conditions-app'
    ].includes(currentUrlWithoutParams) || currentUrlWithoutParams.includes('product')){
      disabledLayout = true;
    }

    return disabledLayout;
  }
}
