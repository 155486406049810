import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-reactions-bar',
  templateUrl: './reactions-bar.component.html',
  styleUrls: ['./reactions-bar.component.scss']
})
export class ReactionsBarComponent {
  @Output() clickIconComment = new EventEmitter<any>();
  @Output() clickIconLike = new EventEmitter<any>();
  @Output() clickIconSave = new EventEmitter<any>();
  @Output() clickIconShare = new EventEmitter<any>();
  openComment=false;
  @Input() infoPost: any = {};
  @Input() like: boolean = false;
  @Input() save: boolean = false;
  @Input() seeLabelsReactions: boolean = false;
  @Input() classLabels=''
  @Input() classIcons=''
  @Input() numberReactions= {
    numberLikes:0,
    numberComments:0,
    numberShares:0,
    numberSaved:0,
  };
  changeOpenComment() {
    this.openComment=!this.openComment;
    this.clickIconComment.emit(this.openComment);
  }

  protected readonly JSON = JSON;
}
