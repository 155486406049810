import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-about-group',
  templateUrl: './about-group.component.html',
  styleUrls: ['./about-group.component.scss']
})
export class AboutGroupComponent {
  @Input() data: any = {};
}
