import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import {Observable, of} from 'rxjs';
import { environment } from "src/environments/environment";

@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let apiUrl = environment.baseUrl;
    if(req.url.includes('tenor')) return next.handle(req.clone());
    if(req.url.includes('/common/')) apiUrl = environment.baseUrlCommon;
    else if(req.url.includes('/auth/')) apiUrl = environment.baseUrlAuth;
    let token=localStorage.getItem('token')||'';
    const headers = req.headers.set('Authorization', `Bearer ${token}`);
    const apiReq = req.clone({ url: apiUrl + req.url, headers });
    return next.handle(apiReq);
  }
}
