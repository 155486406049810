import {Component, ElementRef, inject, OnInit} from '@angular/core';
import {StoriesService} from "../../../services/stories/stories.service";

@Component({
  selector: 'app-story',
  templateUrl: './story.component.html',
  styleUrls: ['./story.component.scss']
})
export class StoryComponent implements OnInit {
  storiesService = inject(StoriesService);
  private scrollContainer: HTMLElement | undefined;
  private content: HTMLElement | undefined;
  stories: any[] = []
  showViewer: boolean = false;
  constructor(private elementRef: ElementRef) {}

  async ngOnInit() {
    this.stories = await this.storiesService.getStories(true) as any;
  }

  ngAfterViewInit() {
    this.scrollContainer = this.elementRef.nativeElement.querySelector('.scroll-container');
    this.content = this.elementRef.nativeElement.querySelector('.content');
  }

  scrollLeft() {
    if (this.scrollContainer)
    this.scrollContainer.scrollLeft -= 140; // Puedes ajustar la cantidad de desplazamiento
  }

  scrollRight() {
    if (this.scrollContainer)
    this.scrollContainer.scrollLeft += 140; // Puedes ajustar la cantidad de desplazamiento
  }

  protected readonly JSON = JSON;
}
