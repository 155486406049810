import {Component, EventEmitter, HostListener, inject, Input, NgZone, OnInit, Output} from '@angular/core';
import {environment} from "../../../../../environments/environment";
import * as mapboxgl from 'mapbox-gl';
import * as MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import {LocationService} from "../../../../services/location/location.service";

@Component({
  selector: 'app-map-location',
  templateUrl: './map-location.component.html',
  styleUrls: ['./map-location.component.scss']
})
export class MapLocationComponent implements OnInit {
  ngZone = inject(NgZone);
  locationService = inject(LocationService);
  marker: mapboxgl.Marker | undefined;
  map: mapboxgl.Map | undefined;
  geocoder: MapboxGeocoder | undefined;
  style = 'mapbox://styles/mapbox/streets-v12';
  lat: number = 30.2672;
  lng: number = -97.7431;
  location: any;
  @Input() locationDefault:{ lng: number, lat: number, address: string } | null = null;
  @Output() accept = new EventEmitter<{ lng: number, lat: number, address: string } | null>();

  ngOnInit() {
    setTimeout(() => {
      try {
        this.ngZone.runOutsideAngular(() => {
          this.map = new mapboxgl.Map({
            accessToken: environment.mapboxToken,
            container: 'map',
            style: this.style,
            zoom: 13,
            center: [this.lng, this.lat]
          });
          this.geocoder = new MapboxGeocoder({
            accessToken: environment.mapboxToken,
            mapboxgl: mapboxgl,
            marker: false
          });
          // Add click event listener
          this.map.on('click', (event) => {
            const coordinates = event.lngLat;
            this.addMarker(coordinates.lng, coordinates.lat);
          });

          // // Setup geocoder result event
          // this.geocoder.on('result', (event) => {
          //   const {geometry} = event.result;
          //   this.addMarker(geometry.coordinates[0], geometry.coordinates[1]);
          // });
          if (this.map)
            this.map.addControl(this.geocoder);

          this.map.addControl(new mapboxgl.NavigationControl());
          if(this.locationDefault){
            this.addMarker(this.locationDefault.lng, this.locationDefault.lat)
            this.map?.setCenter({
              lng: this.locationDefault.lng,
              lat: this.locationDefault.lat
            })
          } else {
            this.locationService.getLocation()
              .then((position) => {
                this.location = position;
                this.addMarker(this.location.coords.longitude, this.location.coords.latitude)
                this.map?.setCenter({
                  lng: this.location.coords.longitude,
                  lat: this.location.coords.latitude
                })
              })
              .catch((error) => {
                console.error('Error getting location:', error);
              }).finally(() => {
            });
          }
        })
      } catch (e) {
        console.log(e)
      }
    }, 500)
  }

  private addMarker(lng: number, lat: number): void {
    this.ngZone.runOutsideAngular(() => {
    try {
      if (!this.map) return;
      // Remove existing marker if one exists
      if (this.marker) {
        this.marker.remove();
      }

      // Create a new draggable marker
      this.marker = new mapboxgl.Marker({draggable: true, color: '#519935'})
        .setLngLat([lng, lat])
        .addTo(this.map);

      // Optional: Listen to the drag end event
      this.marker.on('dragend', () => {
        const lngLat = this.marker!.getLngLat();
        console.log(`Marker dropped at longitude: ${lngLat.lng}, latitude: ${lngLat.lat}`);
      });
    } catch (e) {
      console.log(e)
    }
    });
  }

  async acceptSave() {
    this.accept.emit(await this.getMarkerCoordinates());
  }

  cancel() {
    this.accept.emit(null);
  }

  getMarkerCoordinates(): Promise<{ lat: number, lng: number, address: string } | null> {
    return new Promise((resolve, reject) => {
      if (this.marker) {
        const coordinates = this.marker.getLngLat();
        fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${coordinates.lng},${coordinates.lat}.json?access_token=${environment.mapboxToken}`)
          .then(response => response.json())
          .then(data => {
            let address = data.features[0].place_name;
            return resolve({
              lat: coordinates.lat,
              lng: coordinates.lng,
              address: address
            })
          })
          .catch(error => {
            alert(error)
            reject(null)
          })
      } else {
        return reject(null);
      }
    })
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if(!this.map) return;

    this.map.resize();
  }
}
