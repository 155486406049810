<div class="flex px-4 py-2 border-t-[1px]  border-[#70707080] justify-between" (click)="onOpenChange(!open)">
  <p class="font-Montserrat font-medium sm:font-normal text-[16px] text-[#1E2022]">Categories</p>
  <app-chevron-right-icon *ngIf="!open" color="#707070"/>
  <app-chevron-down-icon *ngIf="open" color="#707070"/>
</div>
<div class="px-4 pb-4" *ngIf="open">
  <app-om-input [useIcon]="true" color="white" [(ngModel)]="search" (keyuptext)="filterCategories($event)"
                inputClass="rounded-full sm:rounded-md !bg-[#F8F8F8] !text-[#77838F] sm:!bg-white !py-[5.5px] shadow-md !font-poppins !font-medium relative !text-lg"
                placeholder="">
    <app-search-icon class="" color="#519935" [width]="18" [height]="18"/>
  </app-om-input>
</div>
<div class="px-4 mb-4 overflow-auto ocultarScroll w-full" *ngIf="open">
  <div class="flex gap-4">
    <div *ngFor="let category of filtered" class="rounded-full px-2 bw-1 border-omgreen-50 text-omgreen-50"
    [class]="{'bg-omgreen-600': selected.includes(category), 'text-white': selected.includes(category)}"
    (click)="selectedCategory(category)">
      #{{category}}
    </div>
  </div>
</div>
