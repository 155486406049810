import {Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent {
  scrollContainer: HTMLElement | undefined;
  content: HTMLElement | undefined;
  @Input() enableArrow: boolean = true;
  @Input() newData: boolean = false;
  isAtStart: boolean = true;
  isAtEnd: boolean = false;
  @Input() moveAmount = 0.45;
  @Input() isStrainFinder = false;
  @Input() styleContainer: string[] = ['gap-4'];
  @Output() endScroll = new EventEmitter<any>();

  @HostListener('scroll', ['$event'])
  onScroll(event: Event): void {
    this.checkScrollPosition();
  }

  constructor(private elementRef: ElementRef) {
  }

  ngAfterViewInit() {
    this.scrollContainer = this.elementRef.nativeElement.querySelector('.scroll-container');
    this.content = this.elementRef.nativeElement.querySelector('.content');
  }

  async updatedData() {
    this.newData = true;
    this.checkScrollPosition()
    this.newData = false
  }

  moveLeft() {
    // @ts-ignore
    const scrollAmount = this.scrollContainer.offsetWidth * this.moveAmount;
    if (this.scrollContainer)
      this.scrollContainer.scrollLeft -= scrollAmount; // Puedes ajustar la cantidad de desplazamiento
  }

  moveRight() {
    // @ts-ignore
    const scrollAmount = this.scrollContainer.offsetWidth * this.moveAmount;
    if (this.scrollContainer)
      this.scrollContainer.scrollLeft += scrollAmount; // Puedes ajustar la cantidad de desplazamiento
  }

  checkScrollPosition(): void {
    if (this.scrollContainer) {
      this.isAtStart = this.scrollContainer.scrollLeft === 0;
      this.isAtEnd = this.scrollContainer.scrollLeft + this.scrollContainer.clientWidth === this.scrollContainer.scrollWidth;
      if (this.isAtEnd && !this.newData) {
        this.newData = true
        this.endScroll.emit();
      }
    }
  }
}
