import {Component, inject, Input, OnDestroy, OnInit} from '@angular/core';
import {FriendsService} from "../../../../services/friends/friends.service";
import {FollowAndFriendService} from "../../../../services/follow-and-friend/follow-and-friend.service";
import {Router} from "@angular/router";
import {CatalogsService} from "../../../../services/catalogs/catalogs.service";
import {UserInfoService} from "../../../../services/user-info/user-info.service";
import {ChatService} from "../../../../services/chat/chat.service";

@Component({
  selector: 'app-growers',
  templateUrl: './growers.component.html',
  styleUrls: ['./growers.component.scss']
})
export class GrowersComponent implements OnInit, OnDestroy {
  private followAndFriends = inject(FollowAndFriendService);
  private catalogsService = inject(CatalogsService);
  private userInfoService = inject(UserInfoService);
  private chatService = inject(ChatService);
  private router = inject(Router);
  @Input() classTitle: any[] = [];
  @Input() fieldRanked: string = 'products_ranked';

  @Input() set filters(value: any) {
    if (value !== this._filters) {
      this._filters = value;
      this.getGrowers(); // Call the method that depends on the filters when a change is detected
    }
  }

  get filters(): any {
    return this._filters;
  }

  private _filters = {}
  users: any[] = [];
  typeUsers = '';

  constructor() {
  }

  async ngOnInit() {
    let typeUsers: any = await this.catalogsService.getTypesUsers().toPromise()
    this.typeUsers = typeUsers.filter((x: any) => x.name.toLowerCase().includes('grower') || x.name.toLowerCase().includes('influencer')).map((x: any) => x.id).join('|')
    await this.getGrowers();
    this.users.forEach(u => {
      this.validIsFollowing(u.id);
    })
  }

  ngOnDestroy() {
  }

  getName(user: any) {
    return this.userInfoService.getNameByUser(user)
  }

  async getGrowers() {
    let filters: { best_describes: string, type_product?: string, top_growers_product?: number } = {
      best_describes: this.typeUsers,
    };
    filters.type_product = this.filters['category'];
    filters.top_growers_product = 1;
    this.users = await this.catalogsService.getUsers(filters).toPromise() as any
  }

  async validIsFollowing(id: string) {
    let userIndex = this.users.findIndex((x: any) => x.id === id);
    this.users[userIndex].isFollowing = await this.followAndFriends.isFollowing(id);
  }

  async follow(id: string) {
    await this.followAndFriends.add(id);
    await this.followAndFriends.getFollowings(true);
    this.validIsFollowing(id);
  }

  async unfollow(id: string) {
    await this.followAndFriends.delete(id);
    await this.followAndFriends.getFollowings(true);
    this.validIsFollowing(id);
  }

  async goToMessages(id: string) {
    let user = await this.userInfoService.getById(id)
    this.chatService.changeUser(user)
    this.router.navigateByUrl('/messages')
  }
}
