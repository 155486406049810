<div class="sm:max-h-[90vh] overflow-auto pb-12">
  <div class="mx-[0] sm:flex sm:mx-[70px] sm:mt-[40px]">
    <div class="flex flex-col-reverse sm:flex-row w-full sm:w-[55%] pt-0 sm:pt-[40px]">
      <div
        class="block sm:block overflow-auto overflow-auto overflow-hidden"
        [ngClass]="{
      'hidden':data.images.length==1,
      }"
      >
        <div class="inline-flex gap-2 sm:gap-0 sm:block">
          <div class="flex sm:block sm:mb-4 w-[100px] h-[72px] sm:w-[112px] sm:h-[85px] rounded-2xl border border-[#707070]" *ngFor="let img of data.images; let indexOfelement=index;">
            <div class="flex items-center justify-center w-full h-full rounded-2xl cursor-pointer" (click)="imgOpen=indexOfelement">
              <img class="w-full h-full object-contain rounded-2xl" [src]="img" />
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-center w-full sm:w-[365px] h-[263px] sm:w-[80%] sm:h-[479px] px-6">
        <img class="w-full h-full object-contain" [src]="data.images[imgOpen]" />
      </div>
    </div>
  </div>
  <div class="bg-[#FAFAFA] sm:bg-white rounded-[30px] sm:rounded-none px-[21px] sm:px-0">
    <div class="w-full sm:w-[45%] border-[#979797] border-1 sm:border-none sm:bg-white sm:rounded-none relative">
            <span class="absolute right-0 top-4 p-[2px] sm:top-0 border-[1px] border-[#2A9B2A] flex items-center justify-center rounded-full w-[30px] h-[30px] sm:w-[37px] sm:h-[37px]">
                <svg class="h-[17px] sm:h-[16px]" xmlns="http://www.w3.org/2000/svg" width="21.858" height="19.32" viewBox="0 0 21.858 19.32">
                    <g id="Like" transform="translate(0.479 0.052)">
                        <path
                          id="Shape"
                          d="M18.327,1.531a5.225,5.225,0,0,0-7.391,0L9.929,2.538,8.922,1.531A5.226,5.226,0,0,0,1.531,8.922L2.538,9.929,9.929,17.32,17.32,9.929l1.007-1.007a5.225,5.225,0,0,0,0-7.391Z"
                          transform="translate(0.521 0.948)"
                          fill="none"
                          stroke="#2A9B2A"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                    </g>
                </svg>
            </span>
      <div class="flex px-[20px] sm:px-0 sm:block justify-between items-end mt-[22px] sm:mt-0">
        <div class="sm:mb-[10px] pt-4 sm:pt-0">
          <p class="text-[#292D2B] font-Montserrat sm:font-Poppins font-semibold text-[24px] sm:text-[41px] leading-[1.2]">{{data.name}}</p>
          <div class="flex flex-col-reverse sm:flex-col">
            <div class="flex items-center sm:mt-1 mb-3 sm:mb-0">
              <p class="text-12px sm:text-[21px] text-[#9A9291] font-SF-Pro-Text">{{data.val_reviews}}</p>
              <app-star-rating
                [disabled]="true"
                [defaultRating]="data.val_reviews||0"
                class="text-[19px]"
                [styleLabel]="{
                'font-size':'17px'
              }"
              ></app-star-rating>
              <p class="text-[12px] sm:text-[21px] text-[#9A9291] font-SF-Pro-Text">({{data.count_reviews}})</p>
            </div>
            <p class="text-[10px] sm:text-[17px] font-Poppins text-[#7E7E7E] sm:my-2">{{data.genetics}}</p>
          </div>
        </div>
        <div class="flex flex-col">
          <div>
                        <span class="block text-black font-Montserrat font-semibold text-center mr-[20px] sm:mr-0 text-[12px] sm:text-[22px] py-1 px-4 border border-[#AAAAAA] sm:h-[40px] w-auto sm:w-[126px] rounded-2xl capitalize">
                            {{data.type}}
                        </span>
          </div>
          <p class="my-1 sm:my-3">
            <span class="text-[#292D2B] text-[12px] sm:text-[20px] font-Montserrat font-medium mr-4">THC {{data.thc}}%</span>
            <span class="text-[#292D2B] text-[12px] sm:text-[20px] font-Montserrat font-medium">CBD {{data.cdb}}%</span>
          </p>
        </div>
      </div>
      <div>
        <div class="hidden sm:block">
          <p class="text-black text-[20px] font-Montserrat font-semibold">Amount</p>
          <p class="text-[#292D2B] text-[20px] font-Montserrat font-medium mb-2">{{data.amount}} grams</p>
        </div>
        <p class="text-[#7E7E7E] text-[12px] font-Poppins sm:text-[#393939] sm:text-[19px] sm:font-Montserrat sm:font-medium sm:hidden mt-[32px]" [innerHTML]="data.description"></p>
        <div class="sm:block mt-1">
          <p class="text-black text-[17px] sm:text-[20px] font-Montserrat font-semibold mt-6 mb-[16px]">Top Effects</p>
          <div class="flex px-[10px] sm:px-0 sm:justify-start gap-16">
            <!--
  [
  {img:'assets/icons-sax/weed.svg',text:'Relaxed'},
  {img:'assets/icons-sax/euphoric.svg',text:'Euphoric'},
  {img:'assets/icons-sax/creative.svg',text:'Creative'},
  ]
  -->
            <div *ngFor="let efect of data.effects">
              <div class="flex items-center justify-center w-[51px] h-[51px] sm:w-[76px] sm:h-[76px] p-2 sm:p-4 rounded-full shadow-[0px_5px_6px_#0000007A]">
                <img [src]="'assets/icons-sax/creative.svg'" />
              </div>
              <p class="text-center font-Montserrat font-medium text-[11px] sm:text-[17px] mt-2">{{efect}}</p>
            </div>
          </div>
          <div class="flex sm:block justify-center" *ngIf="!mine">
            <button
              class="mt-7 sm:ml-10 flex justify-center items-center bg-green-native text-white rounded-full text-center w-[200px] sm:w-[304px] h-[54px] text-[17px] sm:text-[25px] font-Montserrat font-bold"
              (click)="claimGift()"
            >
              Claim Gift
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="sm:hidden px-[21px] sm:px-0 w-full p-[13px] py-[33px] sm:shadow-[0px_3px_6px_#00000029] sm:rounded-2xl sm:bg-white">
      <p class="font-Montserrat font-semibold text-[24px] ">Meet your Grower</p>
      <div class="mt-[25px] sm:mt-[20px]">
        <div class="flex gap-4 container-avatar">
          <img [src]="'assets/demo/avatar2.png'" class="w-[79px] h-[79px] rounded-full object-cover" />
          <div class="flex flex-col justify-center sm:justify-between avatar-info">
            <p class="ml-[15px] sm:ml-0 text-[20px] font-semibold font-Montserrat">Andrea Newman</p>
            <p class="ml-[15px] sm:ml-0 text-[15px] text-[#00000099] font-Poppins">Verified Grower</p>
            <div class="flex gap-1 mt-[10px] sm:mt-0">
              <span *ngIf="!mine" class="flex items-center justify-center text-[14px] font-Montserrat font-bold text-[#519935] border border-[#81CB52] w-[90px] h-[29px] rounded-full cursor-pointer">Follow</span>
              <span class="flex items-center justify-center text-[14px] text-white font-Montserrat font-bold  bg-[#81CB52] w-[90px] h-[29px] rounded-full cursor-pointer">View</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mx-0 sm:mx-[110px] bg-[#fafafa] sm:bg-white sm:rounded-2xl sm:sm:rounded-none pt-[51px] sm:pt-0">
      <div class="sm:mb-4 hidden sm:block">
        <p class="text-[24px] font-Montserrat font-semibold mb-2">Description</p>
        <p class="text-[#7E7E7E] text-[12px] font-Poppins sm:text-[#393939] sm:text-[19px] sm:font-Montserrat sm:font-medium" [innerHTML]="data.description"></p>
      </div>
      <div class="sm:flex gap-4">
        <div class="hidden sm:block p-[13px] px-[21px] sm:px-[13px] w-full sm:w-[598px] py-[33px] sm:shadow-[0px_3px_6px_#00000029] sm:rounded-2xl bg-white">
          <p class="font-Montserrat font-semibold text-[24px] mb-2 hidden sm:block">Grower Info</p>

          <div class="sm:flex">
            <div class="hidden sm:block sm:w-1/2">
              <div class="flex gap-4 container-avatar mt-[20px] sm:mt-0">
                <img [src]="'assets/demo/avatar2.png'" class="w-[79px] h-[79px] rounded-full object-cover" />
                <div class="flex flex-col justify-center sm:justify-between avatar-info">
                  <p class="text-[20px] font-semibold font-Montserrat">Andrea Newman</p>
                  <p class="text-[15px] text-[#00000099] font-Poppins">Verified Grower</p>
                  <div class="flex gap-1 mt-[5px] sm:mt-0">
                    <span *ngIf="!mine" class="flex items-center justify-center text-[14px] font-Montserrat font-bold text-[#519935] border border-[#81CB52] w-[90px] h-[29px] rounded-full cursor-pointer">Follow</span>
                    <span class="flex items-center justify-center text-[14px] text-white font-Montserrat font-bold text-[#519935] bg-[#81CB52] w-[90px] h-[29px] rounded-full cursor-pointer">View</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full sm:w-[270px] pl-4 hidden sm:block">
              <p class="text-[13px] font-Montserrat font-medium" [innerHTML]="data.grow_information"></p>
            </div>
          </div>
        </div>
        <div class="sm:w-5/12 p-0 sm:py-[33px]">
          <div class=" sm:hidden bg-white rounded-2xl sm:rounded-none p-2 sm:p-0 sm:w-full mx-[16px] sm:mx-2 mt-4 sm:mt-0">
            <p class="font-Montserrat font-semibold text-[17px] mb-2 sm:hidden">Grower Information</p>
            <p class="text-[13px] font-Montserrat font-medium" [innerHTML]="data.grow_information"></p>
          </div>
          <div class="px-[35px] sm:p-0 mt-[43px] sm:mt-0 sm:pl-[44px]">
            <p class="text-[17px] sm:text-[24px] font-Montserrat font-semibold">Flavours:</p>
            <div class="flex gap-6">
              <div *ngFor="let flower of data.flavors">
                <div class="flex items-center justify-center w-[54px] h-[54px] sm:w-[85px] sm:h-[85px] rounded-full p-2 sm:p-4 shadow-[0px_2px_48px_#0000000A]">
                  <img [src]="'assets/icons-sax/pine.svg'" />
                </div>
                <p class="text-center text-[11px] sm:text-[20px] font-Montserrat font-medium">{{flower}}</p>
              </div>
            </div>
            <p class="text-[17px] sm:text-[24px] font-Montserrat font-semibold my-5">Terpenes</p>
            <div class="flex gap-6">
              <div class="flex items-center" *ngFor="let terpen of data.terpenes;">
                <img class="h-[20px] sm:h-[27px]" src="assets/demo/diagonal1.png" />
                <p class="text-[16px] sm:text-[20px] font-Montserrat font-medium">{{terpen}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="shadow-[0px_3px_6px_#00000029] p-4 w-[calc(100%-32px)] sm:w-full mt-[66px] sm:mt-[24px] mx-[16px] sm:mx-0 rounded-2xl mt-4 rounded-2xl bg-white">
        <p class="font-Montserrat font-semibold text-[17px] sm:text-[24px] mb-2">Reviews</p>
        <div class="flex justify-between sm:block">
          <div class="flex gap-4 container-avatar">
            <img [src]="'assets/demo/avatar2.png'" class="w-[79px] h-[79px] rounded-full object-cover" />
            <div class="flex flex-col justify-center">
              <p class="text-[12px] sm:text-[18px] font-medium font-Montserrat text-[#202020]">Charlotte Davis</p>
              <p class="text-[12px] sm:text-[16px] text-[#47464A] font-Poppins">Cannabis enthusiast</p>
            </div>
          </div>
          <p class="flex items-center gap-2 sm:hidden">
            <label class="star active text-[20px]" style="font-size: 19px;"></label>
            <span class="text-[#202020] font-Poppins font-medium text-[14px]">4.8</span>
          </p>
        </div>
        <div class="sm:mx-6">
          <div class="flex gap-6 my-4">
            <p class="flex items-center gap-2 hidden sm:block">
              <label class="star active text-[20px]" style="font-size: 19px;"></label>
              <span class="text-[#202020] font-Poppins font-medium text-[14px]">4.8</span>
            </p>
            <div
              *ngFor="let efect of [
                  {img:'assets/icons-sax/weed.svg',text:'Relaxed'},
                  {img:'assets/icons-sax/euphoric.svg',text:'Euphoric'},
                  {img:'assets/icons-sax/creative.svg',text:'Creative'},
                ]"
              class="flex items-center gap-4"
            >
              <div class="flex items-center justify-center w-[26px] h-[26px] sm:w-[34px] sm:h-[34px] p-1 sm:p-2 rounded-full shadow-[0px_5px_6px_#0000007A]">
                <img [src]="efect.img" />
              </div>
              <p class="text-center font-Montserrat font-medium text-[13px] sm:text-[17px] mt-2">{{efect.text}}</p>
            </div>
          </div>
          <p class="text-[12px] sm:text-[18px] font-Poppins text-[#47464A]">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Fringilla ut morbi tincidunt augue interdum. Vestibulum sed arcu non odio euismod lacinia at
            quis risus.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Fringilla ut morbi tincidunt augue interdum. Vestibulum sed arcu non odio euismod
            lacinia at quis risus.
          </p>
          <p class="text-[14px] font-Poppins text-[#519935] mt-4">Show more reviews</p>
        </div>
      </div>
      <div class="mx-0 mt-0 sm:mt-[150px] w-[95%] ml-[2.5%] sm:ml-0 sm:w-full">
        <app-carousel *ngIf="sharables.length>0">
          <header>
            <p class="hidden sm:block flex items-center gap-4 text-[25px] font-Montserrat font-semibold">Similar Strains <span class="text-[12px] text-[#519935] font-Poppins">See more ></span></p>
          </header>
          <div class="w-full sm:w-[45%]" *ngFor="let item of sharables">
            <app-card-mini id="detail-sharable-card" class="" [data]="item" [useTemplateFooter]="true">
              <footer>
                <div class="flex items-center gap-2">
                  <div class="flex gap-4 container-avatar">
                    <img [src]="'assets/demo/avatar2.png'" class="w-[50px] h-[50px] rounded-full object-cover" />
                    <div class="flex flex-col justify-center">
                      <p class="text-[12px] text-[#FFFFFF]">Request</p>
                      <p class="text-[14px] text-[#FFFFFF] font-bold">Michael</p>
                    </div>
                  </div>
                  <div class="flex items-center gap-2">
                    <button class="text-[11px] text-[#FFFFFF] flex justify-center items-center w-[84px] h-[35px] rounded-full border border-[#81CB52]">View details</button>
                    <button class="text-[11px] text-[#FFFFFF] flex justify-center items-center w-[84px] h-[35px] rounded-full bg-[#81CB52]">Request</button>
                  </div>
                </div>
              </footer>
            </app-card-mini>
          </div>
        </app-carousel>
      </div>
    </div>
  </div>
</div>
