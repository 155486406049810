import { Component } from '@angular/core';

@Component({
  selector: 'app-friends-for-home',
  templateUrl: './friends-for-home.component.html',
  styleUrls: ['./friends-for-home.component.scss']
})
export class FriendsForHomeComponent {

}
