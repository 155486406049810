<div class="relative inline-block text-left w-full mb-0">
  <div>
    <div class="cursor-pointer" (click)="toggleOptions()">
      <ng-content select="buttonAction"></ng-content>
    </div>
    <button *ngIf="!buttonTemplate" type="button"
              class="text-[calc(var(--mw-height)*1.8)] py-[calc(var(--mw-height)*1)]
                sm:text-[calc(var(--w-height)*1.8)] sm:py-[calc(var(--w-height)*0.9)]"
              [class]="getContainerClass()"
              (click)="toggleOptions()">
        {{ selectedOption[keyLabel] || placeholder }}
        <ng-icon *ngIf="color === 'black'" name="matArrowDropDown"
                 class="text-[3.5vh] text-white" [class]="getIconClass()"></ng-icon>
        <ng-icon *ngIf="color === 'gray'" name="matKeyboardArrowDown"
                 class="text-[3.5vh] text-[#6F6E6E]" [class]="getIconClass()"></ng-icon>
      </button>

  </div>
  <div *ngIf="showOptions" #optionsList  [class.open]="showOptions"
       class="origin-top-right absolute right-0 mt-0.5 min-w-full rounded-md shadow-lg bg-ominput-600
            ring-1 ring-black ring-opacity-5 z-50 max-h-[100px] overflow-auto custom-scrollbar"
       [class]="getContainerItemsClass()">
    <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
      <div *ngFor="let option of options"
           (click)="selectOption(option)"
           class="border-solid border-b border-black last:border-b-0 block px-4 py-3
           text-[1.5vh] text-white hover:bg-ominput-600/80 hover:text-gray-100 flex items-center gap-1"
           [class]="getItemsClass()"
           role="menuitem">
        <span *ngIf="option.icon" class="material-symbols-outlined text-base">{{option.icon}}</span>
        {{ option[keyLabel] || 'Not Yet' }}
      </div>
      <div *ngIf="!options?.length"
           class="border-solid border-b border-black last:border-b-0 block px-4 py-3
           text-[1.5vh] text-slate-200 hover:bg-ominput-600/80 hover:text-gray-100">
        Empty options
      </div>
    </div>
  </div>
</div>
