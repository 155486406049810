import {Component, ElementRef, HostListener, inject, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {CatalogsService} from "../../../services/catalogs/catalogs.service";
import {DeviceInfoService} from "../../../services/device-info/device-info.service";

@Component({
  selector: 'app-market-search',
  templateUrl: './market-search.component.html',
  styleUrls: ['./market-search.component.scss']
})
export class MarketSearchComponent implements OnInit {
  router = inject(Router);
  deviceInfoService = inject(DeviceInfoService);
  catalogsService = inject(CatalogsService);
  @ViewChild('filterList') filterList: ElementRef | undefined;
  @ViewChild('containerFilterList') containerFilterList: ElementRef | undefined;
  showFilters = false
  sharables = [];
  userId = ''
  search = ''
  type = ''
  indexCategory = 0
  filterTab = 'sort'
  filterType = 'Relevance'
  indexType: any = null;
  filters: { [key: string]: string } = {
    sort: 'relevance',
    category: '',
    type: 'all',
  }

  sorts: { label: string, value: string }[] = [
    {label: 'Relevance', value: 'relevance'},
    {label: 'Top Sales', value: 'top-sales'},
    {label: 'Most Recent', value: 'most-recent'},
    {label: 'Price Low to High', value: 'Price-Low-to-High'},
    {label: 'Price High to Low', value: 'Price-High-to-Low'},
  ]
  categoriesObjs: ICatalogView[] = [];
  categories: { label: string, value: string }[] = [{label: 'All Category', value: 'all'},]
  types: { label: string, value: string }[] = [{label: 'All Types', value: 'all'},]

  async ngOnInit() {
    await this.getCategories()
    await this.getTypes()
    this.route.queryParams.subscribe(params => {
      if (params['type']) {
        this.filters['category'] = params['type'];
        if (params['type2']) this.filters['type'] = params['type2']
        const indexfindObject = this.categories.findIndex(object => object.value === this.filters['category']);

        if (indexfindObject !== -1) {
          this.indexCategory = indexfindObject
        }
      }

      window.scrollTo(0, 1);
      this.getSharables()
    });
  }

  getTypes() {
    return new Promise((resolve, reject) => {
      try {
        this.catalogsService.getAll('product-type', '').subscribe({
          next: (response: any) => {
            this.types = [...this.types, ...response.records.map((x: any) => {
              return {
                value: x.name.toLowerCase(),
                label: x.name,
              };
            })]
            resolve(true);
          }
        })
      } catch (e) {
        reject(e)
      }
    })
  }
  goBack() {
    this.deviceInfoService.goBack();
  }
  getCategories() {
    return new Promise((resolve, reject) => {
      try {
        this.catalogsService.getAll('categories', '').subscribe({
          next: (response: any) => {
            this.categoriesObjs = [...this.categoriesObjs, ...response.records.map((x: any) => {
              return {
                name: x.name.toLowerCase(),
                title: x.name,
                img: x.short_image,
                banner: x.long_image,
                route: '/market-search'
              };
            })]
            this.categories = [...this.categories, ...response.records.map((x: any) => {
              return {
                value: x.name.toLowerCase(),
                label: x.name,
              };
            })]
            resolve(true)
          }
        })
      } catch (e) {
        reject(e)
      }
    })
  }

  getSharables() {
    this.sharables = [];
  }

  setSort(key: string) {
    this.filterTab = key;
  }

  setFilters() {
    this.router.navigateByUrl("/market-search?type=" + this.filters['category'] + "&type2=" + this.filters['type']);
  }

  setFilterType(key: string) {
    this.filters[this.filterTab] = key;
    this.setFilters();
    this.getSharables();
  }

  getCatalogFilter() {
    let catalog: typeof this.categories = [];
    switch (this.filterTab) {
      case 'sort':
        catalog = this.sorts;
        break;
      case 'category':
        catalog = this.categories;
        break;
      case 'type':
        catalog = this.types;
        break;
    }
    return catalog;
  }

  constructor(private route: ActivatedRoute) {
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    const targetElement = event.target as HTMLElement;
    if (this.showFilters && this.filterList && !this.filterList.nativeElement.contains(targetElement)) {
      this.showFilters = false;
    }
  }
}
