import {Component, ElementRef, HostListener, inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {CatalogsService} from "../../../services/catalogs/catalogs.service";
import {FollowAndFriendService} from "../../../services/follow-and-friend/follow-and-friend.service";
import {HttpClient} from "@angular/common/http";
import {SearchAllService} from "../../../services/search-all/search-all.service";

@Component({
  selector: 'app-coaches',
  templateUrl: './coaches.component.html',
  styleUrls: ['./coaches.component.scss']
})
export class CoachesComponent implements OnInit, OnDestroy {
  @ViewChild('filterList') filterList: ElementRef | undefined;
  @ViewChild('containerFilterList') containerFilterList: ElementRef | undefined;
  private followAndFriends = inject(FollowAndFriendService);
  searchAllService = inject(SearchAllService);
  private http = inject(HttpClient);
  router = inject(Router);
  catalogService = inject(CatalogsService);
  showFilters = false
  index: number | null = null
  statusFetch = false;
  search = ''
  selected = '';
  filters: { [key: string]: string } = {
    category: '',
  }
  couches: any = [];
  options: { label: string, value: string, img: string, banner: string }[] = []

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    window.scrollTo(0, 1);
    this.getCourseCategories();
  }

  subscriptionQueryParams: Subscription | undefined;

  setTypeParams() {
    this.subscriptionQueryParams = this.route.queryParams.subscribe(params => {
      this.index = 0
      if (params['type']) {
        this.filters['category'] = params['type'];
        this.selected = params['type'];
        const indexfindObject = this.options.findIndex(object => object.value === this.filters['category']);

        if (indexfindObject !== -1) {
          this.index = indexfindObject
          this.selected = params['type'];
          this.couches = [];
          console.log('ejecutando ' + params['type'])
          this.getData(params['type']);
        } else {
          this.router.navigateByUrl("/mentors?type=" + this.options[0].value);
        }
      } else {
        this.router.navigateByUrl("/mentors?type=" + this.options[0].value);
      }
      this.subscriptionQueryParams?.unsubscribe();
    });
  }

  subscriptionCategories: Subscription | undefined;

  async getCourseCategories() {
    this.subscriptionCategories = this.catalogService.getAll('courses-categories', '').subscribe({
      next: (response: any) => {
        for (const category of response.records) {
          this.options.push({
            label: category.name,
            value: category.name,
            img: category.long_image,
            banner: category.long_image
          });
        }
        this.setTypeParams();
        this.subscriptionCategories?.unsubscribe()
      }
    })
  }

  setFilters(type: string = '') {
    this.couches = [];
    this.selected = type || this.selected;
    this.router.navigateByUrl("/mentors?type=" + (type || this.selected)).then(() => {
      this.setTypeParams();
    });
  }

  resetFilter() {
    this.couches = [];
    this.getData(this.selected)
  }

  subscriptionData: Subscription | undefined;

  async getData(type: string) {
    if (this.statusFetch) return;
    this.statusFetch = true;
    let filters: any = {
      limit: 20,
      offset: this.couches.length,
      type: type,
      sortKey: 'courses_ranked',
      sort: 'desc',
    }
    if (this.search) filters.search = this.search;
    filters['coach'] = 1;
    const records = await this.searchAllService.getUsers(filters).toPromise() as any;
    for (const index in records) {
      records[index]['isFollowing'] = await this.followAndFriends.isFollowing(records[index]['id']);
    }
    this.couches = [...this.couches, ...records];
    this.statusFetch = false;
  }

  @HostListener('window:scroll', ['$event'])
  endScroll(): void {
    if (this.statusFetch) return;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    if (scrollTop + clientHeight >= scrollHeight - 100) {
      this.getData(this.selected);
    }
  }

  ngOnDestroy() {
    this.subscriptionData?.unsubscribe();
    this.subscriptionCategories?.unsubscribe();
    this.subscriptionQueryParams?.unsubscribe();
  }
}
