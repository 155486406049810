import {Component, Input} from '@angular/core';
import {DeviceInfoService} from "../../../services/device-info/device-info.service";

@Component({
  selector: 'app-clear-layout',
  templateUrl: './clear-layout.component.html',
  styleUrls: ['./clear-layout.component.scss']
})
export class ClearLayoutComponent {
  @Input() useHeaderEdit = false;
  @Input() routeHeader = '';
  @Input() cls = '';
  @Input() title = '';
  constructor(public deviceInfo: DeviceInfoService) {
  }
  ngOnInit(){
    this.deviceInfo.scrollTop();
  }
  toggleMenu(){
    this.deviceInfo.toggleMenu()
  }
  getClass() {
    let classes: {[key:string]: boolean} = {};

    const mapClassToBoolean = (cl: string) => ({ [cl]: true });

    if(this.cls){
      const arrcls = this.cls.split(' ');
      classes = Object.assign({}, ...arrcls.map(mapClassToBoolean));
    }

    return classes;
  }
}
