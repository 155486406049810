<div class="overflow-none bg-gray-800 relative w-full om-container bg-[url('assets/home/iphone13.webp')] bg-full-custom bg-center sm:bg-[url('assets/home/home.webp')] sm:home-bg-position">
  <div class="h-full bg-cover home-bg-position-bottom  bg-[url('assets/home/home-mancha-m.webp')] sm:gbg-black-to-transparent">
    <div class="z-10 h-full overflow-auto w-full flex flex-col sm:block justify-between">
      <div>
        <img alt="logo" src="assets/logo_mobile.svg" class="hidden sm:hidden  w-[calc(var(--mw-width)*30)]  mt-[calc((var(--mw-height)*12.27))]">
        <img alt="logo" src="assets/logo_desk.svg" class="hidden sm:block sm:w-[calc(var(--w-height)*30)]
              sm:ml-[calc(var(--w-height)*10.61)] sm:mt-[calc(var(--w-height)*3.94)]">
        <h1 class="hidden block sm:hidden text-white font-bold font-futura pl-[calc(var(--mw-height)*3.3)]  text-[calc(var(--mw-height)*4.8)] mt-[calc(var(--mw-height)*7.3)] leading-[calc(var(--mw-height)*6.5)]">
          {{getProp('--sat')}}
          {{getProp('--mw-height')}}
          {{getProp('--mw-width')}}
          Connect,<br>Learn & <br>Share <br>Cannabis</h1>
      </div>
      <div class="mb-[calc(var(--mw-height)*2)]">
        <h1 class="hidden sm:block text-white font-futura
        sm:ml-[calc(var(--w-height)*15)]
        sm:leading-[calc(var(--w-width)*6.5)]
        sm:text-[calc(var(--w-width)*5.8)]
        sm:mt-[calc(var(--w-height)*2)]">Learn, <br>Connect & <br>Share Cannabis</h1>
        <div class="flex flex-col  w-full sm:ml-[calc(var(--w-height)*15)]
                  mb-[calc(var(--w-height)*1.5)] sm:mb-0  m-auto sm:w-[80vw]">
          <p class="block sm:hidden text-white font-semibold text-center text-[calc(var(--w-height)*2.5)]"
          >Not signed up? <a (click)="openExternalUrl()" RRouterLink="/register" class="text-green-native font-extrabold">Join now</a></p>
          <app-om-button [text]="'Sign in'" class="w-[86%] sm:w-[calc(var(--w-height)*50)]  m-auto sm:ml-0 mt-[calc(var(--mw-height)*1.3)] sm:mt-[calc(var(--w-height)*3.3)]"
                         [height]="{xs:'7',sm:'8'}"
                         [fontSize]="{xs:'3',sm:'4'}"
                         [iconPosition]="'right'"
                         [iconClass]="'sm:!hidden'"
                         routerLink="/login">
            <img alt="flecha" [class]="'h-4/12 px-3'" src="assets/general/flecha.png">
          </app-om-button>
          <app-om-button [text]="'Join now'" class="hidden sm:block w-[86%] sm:w-[calc(var(--w-height)*50)]  m-auto sm:ml-0
          sm:ml-0 mt-[calc(var(--mw-height)*1.3)] sm:mt-[calc(var(--w-height)*2.5)]"
                         [height]="{xs:'7',sm:'8'}"
                         [fontSize]="{xs:'3',sm:'4'}"
                         [classButton]="'border-green-native'"
                         [stylesButton]="{'border':'calc(var(--w-height) * 0.2) solid #81CB52'}"
                         [bgColor]="'#ffffff00'"  (click)="openExternalUrl()" RRouterLink="/register">

          </app-om-button>
        </div>
        <div class="sm:w-[calc(var(--w-height)*38.51)] sm:ml-[calc(var(--w-height)*15)]
                mt-[calc(var(--mw-height)*4.1)] sm:mt-[calc(var(--w-height)*3.3)] w-[86%]
                sm:w-[calc(var(--w-height)*50)]  m-auto sm:ml-0">
          <p class="text-[calc(var(--mw-height)*1.6)] sm:text-[calc(var(--w-height)*1.85)] text-white font-semibold text-center pb-0">By using our platform, you agree to our</p>
          <p class="text-[calc(var(--mw-height)*1.6)] sm:text-[calc(var(--w-height)*1.85)] text-green-native text-center font-extrabold cursor-pointer" routerLink="/terms-and-conditions">Terms of Service.</p>
        </div>
      </div>
    </div>
  </div>
</div>
