<app-dialog #communityRequest [title]="title"
            [classHeaderDefault]="'px-1 pt-5 pb-3 text-lg'"
            [classModal]="'sm:!min-w-[200px] sm:!w-[500px] bg-black/80 sm:bg-white text-white sm:text-black sm:relative'">
  <div class="px-[10px]">
    <app-community-card *ngIf="data!=null" [data]="data.community" [enableActions]="false"></app-community-card>
    <div *ngIf="data!=null && data.status==='pending'" class="flex justify-between gap-4 mb-4 px-2">
      <button (click)="updateRequest('accepted')"
              class="bg-[#519935] text-white font-Montserrat font-semibold text-[12px] w-full px-4 py-2 mt-2 rounded-lg" >
        Accept
      </button>
      <button (click)="updateRequest('rejected')"
              class="bg-[#F1F1F5] text-[#696974] font-Montserrat font-semibold text-[12px] w-full px-4 py-2 mt-2 rounded-lg" >
        Decline
      </button>
    </div>
  </div>
</app-dialog>

