import {Component, computed, EventEmitter, inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {AuthService} from "../../../auth/services/auth.service";
import {DeviceInfoService} from "../../../services/device-info/device-info.service";
import {UserInfoService} from "../../../services/user-info/user-info.service";
import {PublicationComponent} from "../../../components/publications/publication/publication.component";
import {SearchAllService} from "../../../services/search-all/search-all.service";
import {
  CommunityRequestComponent
} from "../../../components/communities/parts/community-request/community-request.component";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @ViewChild(PublicationComponent, { static: false }) publicationComponent: PublicationComponent | undefined;
  @ViewChild(CommunityRequestComponent, { static: false }) communityRequestComponent: CommunityRequestComponent | undefined;
  router = inject(Router);
  route = inject(ActivatedRoute);
  deviceInfo = inject(DeviceInfoService);
  searchAllService = inject(SearchAllService);
  constructor(private userInfo: UserInfoService) {}
  @Input() routeHeader = '';
  @Input() useHeaderEdit = false;
  @Input() filterPost = false;
  @Input() filterSearch = false;
  @Input() bottomNavigation = false;
  @Output() toggleMenu = new EventEmitter<any>();
  @Output() newPost = new EventEmitter<any>();
  @Input() typePost = '';
  @Output() typePostChange: EventEmitter<string> = new EventEmitter<string>();
  options: {label: string, value: string}[] = [
    {label: 'Following', value: 'Following'},
    {label: 'For you', value: 'For you'},
    {label: 'Lastest', value: 'Lastest'},
    {label: 'Trending', value: 'Trending'},
  ]
  search = '';
  user:any={};
  selected: string = 'Following'
  openFormPost(){
    this.newPost.emit();
  }
  goTo() {
    if(this.routeHeader === 'goBack')
      this.deviceInfo.goBack();
    else
      this.router.navigateByUrl(this.routeHeader);
  }
  goToSearch(evt: any){
    this.searchAllService.changeSearch(evt);
    this.router.navigateByUrl('/search-og')
  }
  toggle() {
    this.toggleMenu.emit();
  }
  goToAny(path: string) {
    this.router.navigateByUrl(path);
  }
  openPublication(id:any){
    this.publicationComponent?.openBynotification(id)
  }
  openRequestC(data:any){
    this.communityRequestComponent?.openRequest(data.sk)
  }
  postSubscription: Subscription | undefined;
  ngOnInit() {
    this.user = this.userInfo.me().user;
    this.postSubscription = this.route.queryParams.subscribe(
      async (params: Params) => {
        setTimeout(() => {
          let url = this.router.url.split('?')[0]
          console.log("params", params)
          if(url === '/home' && params?.["id"]){
            console.log('abireindo modal')
            console.log(params["id"])
            this.publicationComponent?.openBynotification(params["id"]);
          }
        }, 100)
      })
  }
  ngAfterViewInit() {
    let url = this.router.url.split('?')[0]
    const id = new URLSearchParams(window.location.search).get(
      "id"
    );

  }
  updateType(evt: any) {
    this.typePostChange.emit(evt)
  }
  goToProfile(url: string){
    this.router.navigateByUrl(url)
  }
}
