<app-settings-layout>
  <div class="w-full sm:max-w-[1050px] p-6">
    <!--    <div class="px-6 my-2">-->
    <!--      <app-arrow-left-icon [width]="35" [height]="35" color="#00000066" class="cursor-pointer" (click)="router.navigateByUrl('/profile')"/>-->
    <!--    </div>-->
    <div class="rounded-2xl flex flex-col bg-white sm:shadow-lg w-full p-4 sm:p-8">
      <h2 class="font-poppins font-medium text-[#202020] text-[36px]">Notifications</h2>
      <hr class="bw-1 border-[##00000014] h-0.5 z-10 my-6">
      <p class="font-Montserrat font-semibold mb-2 text-[18px] text-[#202020]">Send me notifications:</p>
      <div class="flex flex-col w-[calc(100%-150px)] items-start gap-4 mb-8">
        <label for="newGift" class="flex items-start justify-center text-sm gap-2">
          <input type="checkbox" id="newGift" [(ngModel)]="data.newGift" #newGift="ngModel"
                 class="all checked:!bg-[#519935] checked:border-[#519935] !border-[#898989] h-[calc(var(--mw-height)*0.8)] w-[calc(var(--mw-height)*0.8)] sm:!mt-1"
                 required>
          New Gift
        </label>
        <label for="giftClaimed" class="flex items-start justify-center text-sm gap-2">
          <input type="checkbox" id="giftClaimed" [(ngModel)]="data.giftClaimed" #giftClaimed="ngModel"
                 class="all checked:!bg-[#519935] checked:border-[#519935] !border-[#898989] h-[calc(var(--mw-height)*0.8)] w-[calc(var(--mw-height)*0.8)] sm:!mt-1"
                 required>
          Gift Claimed
        </label>
        <label for="tags" class="flex items-start justify-center text-sm gap-2">
          <input type="checkbox" id="tags" [(ngModel)]="data.tags" #tags="ngModel"
                 class="all checked:!bg-[#519935] checked:border-[#519935] !border-[#898989] h-[calc(var(--mw-height)*0.8)] w-[calc(var(--mw-height)*0.8)] sm:!mt-1"
                 required>
          Tags
        </label>
        <label for="comments" class="flex items-start justify-center text-sm gap-2">
          <input type="checkbox" id="comments" [(ngModel)]="data.comments" #comments="ngModel"
                 class="all checked:!bg-[#519935] checked:border-[#519935] !border-[#898989] h-[calc(var(--mw-height)*0.8)] w-[calc(var(--mw-height)*0.8)] sm:!mt-1"
                 required>
          Comments
        </label>
        <label for="likesReactions" class="flex items-start justify-center text-sm gap-2">
          <input type="checkbox" id="likesReactions" [(ngModel)]="data.likesReactions"
                 #likesReactions="ngModel"
                 class="all checked:!bg-[#519935] checked:border-[#519935] !border-[#898989] h-[calc(var(--mw-height)*0.8)] w-[calc(var(--mw-height)*0.8)] sm:!mt-1"
                 required>
          Likes/Reactions
        </label>
        <label for="postShare" class="flex items-start justify-center text-sm gap-2">
          <input type="checkbox" id="postShare" [(ngModel)]="data.postShare" #postShare="ngModel"
                 class="all checked:!bg-[#519935] checked:border-[#519935] !border-[#898989] h-[calc(var(--mw-height)*0.8)] w-[calc(var(--mw-height)*0.8)] sm:!mt-1"
                 required>
          Post Shares
        </label>
        <label for="followAndFriendRequest" class="flex items-start justify-center text-sm gap-2">
          <input type="checkbox" id="followAndFriendRequest" [(ngModel)]="data.followAndFriendRequest"
                 #followAndFriendRequest="ngModel"
                 class="all checked:!bg-[#519935] checked:border-[#519935] !border-[#898989] h-[calc(var(--mw-height)*0.8)] w-[calc(var(--mw-height)*0.8)] sm:!mt-1"
                 required>
          Follows/Friend Requests
        </label>
        <label for="message" class="flex items-start justify-center text-sm gap-2">
          <input type="checkbox" id="message" [(ngModel)]="data.message" #message="ngModel"
                 class="all checked:!bg-[#519935] checked:border-[#519935] !border-[#898989] h-[calc(var(--mw-height)*0.8)] w-[calc(var(--mw-height)*0.8)] sm:!mt-1"
                 required>
          Messages/Direct Messages
        </label>
        <label for="communityActivity" class="flex items-start justify-center text-sm gap-2">
          <input type="checkbox" id="communityActivity" [(ngModel)]="data.communityActivity"
                 #communityActivity="ngModel"
                 class="all checked:!bg-[#519935] checked:border-[#519935] !border-[#898989] h-[calc(var(--mw-height)*0.8)] w-[calc(var(--mw-height)*0.8)] sm:!mt-1"
                 required>
          Community Activity
        </label>
      </div>


      <hr class="bw-1 border-[##00000014] h-0.5 z-10 my-6">
      <div class="flex items-center justify-end gap-4 px-8">
        <button (click)="cancel()"
          class="flex px-4 !py-[10.5px] border-2 border-[#DCDCDC] mb-2 h-fit gap-2 items-center font-normal text-xs bg-[#fff] text-[#202020] rounded-xl shadow-sm">
          Discard
        </button>
        <button (click)="save()"
          class="flex px-4 !py-[10.5px] mb-2 h-fit gap-2 items-center font-normal text-xs bg-[#519935] text-white rounded-xl shadow-sm">
          Save changes
        </button>
      </div>
    </div>
  </div>
</app-settings-layout>
