import {Component, OnInit} from '@angular/core';
import {ToastService} from "../../../services/toast/toast.service";

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {
  show = false;
  message = '';

  constructor(private toastService: ToastService) { }

  ngOnInit() {
    this.toastService.toastStatus.subscribe(status => {
      this.show = status.show;
      this.message = status.message;

      if (status.show) {
        setTimeout(() => this.toastService.hide(), 3000);
      }
    });
  }
}
