<app-carousel class="w-full" [isStrainFinder]="true" [moveAmount]="0.23" [styleContainer]="['gap-4']">
  <header-left>
    <div class="flex gap-1 sm:gap-4 items-center mb-4">
      <h1 class="text-[20px] sm:text-[25px] font-semibold font-Montserrat">{{title}}</h1>
      <p class="flex items-center text-green2-native mt-[3px] text-[15px] font-medium font-Montserrat"  [routerLink]="route">
        <span class="hidden sm:block cursor-pointer">View All</span>
        <span class="material-symbols-outlined flex items-center justify-center border-[1.5px] border-green4-native w-[18px] h-[18px] pl-[1px] text-[20px] sm:text-[16px] sm:w-auto rounded-full sm:border-0">navigate_next</span>
      </p>
    </div>
  </header-left>
  <header-action>
  </header-action>
  <app-grower-card class="block w-[350px] mr-[20px] sm:mr-[15px] sm:w-[370px]"  *ngFor="let user of users" [user]="user"></app-grower-card>
</app-carousel>
