<div *ngIf="user">
  <div class="relative pt-[10px] mt-0 sm:mt-[26px]" [class]="classField">
    <input class="pl-4 w-full sm:w-full h-[40px] sm:h-[56px] border focus:outline-none border-[rgba(15,13,35,0.2)] focus:border-[#519935] rounded-[6px] text-[#07122E] font-DM-Sans"
           [(ngModel)]="user.name" [disabled]="!disabledInput"
           [class]="{
           '!border-red-400' : enableRequiredInput && user.name.trim()==''
           }"
    >
    <hr class="border border-white absolute top-[10px] w-[75px] left-3 z-10">
    <label class="absolute left-4 top-[-1px] text-[#AAAAAA] text-[14px] font-Montserrat px-[4px] z-10"
           [class]="{
           '!text-red-500' : enableRequiredInput && user.name.trim()==''
           }"
    >Full Name</label>
  </div>
  <div *ngIf="enableInfoAddress" class="relative pt-[10px] mt-[14px] sm:mt-[32px]" [class]="classField">
    <input class="pl-4 w-full sm:w-full h-[40px] sm:h-[56px] border focus:outline-none border-[rgba(15,13,35,0.2)] focus:border-[#519935] rounded-[6px] text-[#07122E] font-DM-Sans"
           [(ngModel)]="user.email" [disabled]="!disabledInput"
           [class]="{
           '!border-red-400' : enableRequiredInput && user.email.trim()==''
           }"
    >
    <hr class="border border-white absolute top-[10px] w-[105px] left-3 z-10">
    <label class="absolute left-4 top-[-1px] text-[#AAAAAA] text-[14px] font-Montserrat px-[4px] z-10"
           [class]="{
           '!text-red-500' : enableRequiredInput && user.email.trim()==''
           }"
    >Email Address</label>
  </div>
  <div *ngIf="enableInfoAddress" class="relative pt-[10px] mt-[14px] sm:mt-[32px]"  [class]="classField">
    <input class="pl-4 w-full sm:w-full h-[40px] sm:h-[56px] border focus:outline-none border-[rgba(15,13,35,0.2)] focus:border-[#519935] rounded-[6px] text-[#07122E] font-DM-Sans"
           [(ngModel)]="user.phoneNumber" [disabled]="!disabledInput"
           [class]="{
           '!border-red-400' : enableRequiredInput && user.phoneNumber.trim()==''
           }"
    >
    <hr class="border border-white absolute top-[10px] w-[110px] left-3 z-10">
    <label class="absolute left-4 top-[-1px] text-[#AAAAAA] text-[14px] font-Montserrat px-[4px] z-10"
           [class]="{
           '!text-red-500' : enableRequiredInput && user.phoneNumber.trim()==''
           }"
    >Phone number</label>
  </div>

</div>
