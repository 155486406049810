import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {FormBuilder} from "@angular/forms";

@Component({
  selector: 'app-interested-in',
  templateUrl: './interested-in.component.html',
  styleUrls: ['./interested-in.component.scss']
})
export class InterestedInComponent {
  cat_interests: IInterest[] = [];
  private http = inject(HttpClient);
  @Output() sendData = new EventEmitter<any>();
  @Input() interests:string[]=[]
  @Output() previousStep = new EventEmitter<any>();
  previous() {
    this.previousStep.emit();
  }
  sendDataC() {
    this.sendData.emit({interests:this.interests});
  }
  isboxSelected(text: string, item: string) {
    const index = this.interests.indexOf(text);
    if (index === -1) return false;
    else return true;
  }
  toggleInterests(interest: string) {
    const index = this.interests.indexOf(interest);
    if (index === -1) {
      if (this.interests.length < 3) this.interests.push(interest);
    }
    else this.interests.splice(index, 1);
  }
  getInterests() {
    this.cat_interests = [];
    this.http.get('/common/interests').subscribe({
      next: (response: any) => {
        this.cat_interests = response;
      }
    });
  }
  constructor() {
    this.getInterests();

  }
}
