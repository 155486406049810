import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-plus-icon',
  templateUrl: './plus-icon.component.svg',
  styleUrls: ['./plus-icon.component.scss']
})
export class PlusIconComponent {
  @Input() color = '#77838f';
  @Input() width = 24;
  @Input() height = 24;
}
