<div class="px-4 pl-6 h-full w-full flex flex-col justify-between
      pt-[var(--sat)]
      bg-[#262628] bg-cover bg-[url('assets/general/wave-m.webp')] sm:bg-none bg-center
      sm:h-[88vh] sm:w-[36.6vw] sm:bg-[#2e2e2e]
      sm:mt-[calc(var(--w-height)*5)]
      sm:pt-[calc(var(--w-height)*6)]
      sm:pb-[calc(var(--w-height)*4)]
      sm:mr-[calc(var(--w-height)*15)]
      sm:px-[calc(var(--w-width)*5)]
      sm:rounded-[2vw]">
  <div>
    <ng-icon name="matArrowBack" class="text-white mt-4 mb-1 text-4xl inline-block sm:!hidden" (click)="previous()"></ng-icon>
    <p class="title-form text-[calc(var(--mw-height)*4.5)] sm:text-[calc(var(--w-height)*4.5)]">
      Create account</p>
    <p class="text-[#C3C3C3] mt-[calc(var(--mw-height)*1)] sm:mt-[calc(var(--mw-height)*2)] sm:text-white text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*2)]">
      Sign up to unlock the world of cannabis.</p>
    <div class="mt-[calc(var(--mw-height)*4)] sm:mt-[calc(var(--mw-height)*2)]">
      <app-om-input label="Username" placeholder="Username" id="username" type="text"
                    [(ngModel)]="data.username" [default]="data.username" #username="ngModel" required></app-om-input>
      <p *ngIf="username.invalid && username.touched"
         class="text-red-500 text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*1.5)]">
        Username is required.</p
      ><p *ngIf="exist_user"
         class="text-red-500 text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*1.5)]">
        Username already exists.</p>
    </div>
    <div class="mt-[calc(var(--mw-height)*2)]">
      <app-om-input label="Email" placeholder="Email" id="email" type="email" [inputMode]="'email'"
                    [readonly]="isFounder"
                      [(ngModel)]="data.email"  [default]="data.email" #email="ngModel" required></app-om-input>
      <p *ngIf="email.invalid && email.touched"
         class="text-red-500 text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*1.5)]">
        Email is required.</p>
      <p *ngIf="exist_email"
         class="text-red-500 text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*1.5)]">
        Email exists.</p>
    </div>
    <div class="mt-[calc(var(--mw-height)*2)]">
      <app-om-input label="Password" placeholder="Password" id="password" type="password"
                    [(ngModel)]="data.password" [default]="data.password" #password="ngModel" required></app-om-input>
      <p class="text-gray-400 text-[calc(var(--mw-height)*1.4)] sm:text-[calc(var(--w-height)*1.5)]"
         [class]="{'text-red-500': password.invalid && password.touched}">
        8 characters or more</p>
    </div>
    <div class="checkbox-group flex items-center md:items-center mt-[calc(var(--mw-height)*3)]">
              <span>
                <input type="checkbox" id="is_older" [(ngModel)]="data.is_older" #isOlder="ngModel"
                       class="accent-omgreen-50 h-[calc(var(--mw-height)*0.8)] w-[calc(var(--mw-height)*0.8)]" required/>
              </span>
      <label for="is_older" class="label-checkbox text-white ml-2 !p-0
              text-[calc(var(--mw-height)*1.5)] pt-[calc(var(--mw-height)*1.7)] sm:text-[calc(var(--mw-height)*1.4)]"
             [class]="{'!text-red-500': !data.is_older && isOlder.touched}">
        I affirm that I am
        {{ ageCountry }} years of age or older.</label>
    </div>
    <div class="flex tems-center mt-[calc(var(--mw-height)*1.5)] sm:mb-[calc(var(--mw-height)*3)]">
              <span>
                <input type="checkbox" id="terms_conditions" [(ngModel)]="data.terms_conditions" #terms_conditions="ngModel"
                       class="accent-omgreen-50 h-[calc(var(--mw-height)*0.8)] w-[calc(var(--mw-height)*0.8)]" required>
              </span>
      <label for="terms_conditions" class="label-checkbox text-white ml-2 !p-0
              text-[calc(var(--mw-height)*1.5)] pt-[calc(var(--mw-height)*1.7)] sm:text-[calc(var(--mw-height)*1.4)]" [class]="{'!text-red-500': !data.terms_conditions && terms_conditions.touched}">
        I agree the
        <span class="text-green-native font-extrabold underline underline-offset-1 cursor-pointer" routerLink="/terms-and-conditions">Terms & Conditions.</span>
      </label>

    </div>
    <hr class="border-[#707070] h-0.5 md:hidden my-[calc(var(--mw-height)*3)]">
  </div>
  <div class="w-full items-start  mb-[100px]">

    <app-om-button [text]="'Sign up'"
                   class="w-full"
                   [height]="{xs:'7',sm:'6'}"
                   [fontSize]="{xs:'3',sm:'2.6'}"
                   [iconPosition]="'right'"
                   (click)="validateEmailUserExists()">
    </app-om-button>

    <p
      class="text-white text-center m-[calc(var(--mw-height)*3)] sm:mb-[calc(var(--mw-height)*1.5)] text-[calc(var(--mw-height)*1.4)]
          block sm:absolute sm:w-[36.6vw] sm:right-[calc(var(--w-height)*15)] sm:bottom-0 sm:mt-[calc(var(--w-height)*3)] sm:text-[calc(var(--w-height)*2)]"
    >Have an account? <span
      class="text-green-native cursor-pointer underline" routerLink="/login">Sign in now.</span></p>
  </div>
</div>
