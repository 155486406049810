<div class="relative flex gap-[10px] w-full mt-[15px]" [class]="{'justify-end':position==='right',}">
  <p *ngIf="!message.includes('OG')" class="ml-[50px] sm:ml-[25px] text-[14px] font-SF-Pro-Text sm:font-Roboto  py-[15px] px-[20px] rounded-[20px] sm:rounded-3xl sm:rounded-tl-3xl max-w-[calc(100%-150px)] sm:max-w-[calc(100%-175px)]" [class]="{
    'rounded-tl-none':position!=='right',
    'bg-[#E4E6EC]':position!=='right',
    'bg-[#519935]':position==='right',
    'text-[#E2E2EA]':position==='right',
    'rounded-tr-none':position==='right',

  }">{{message}}</p>
  <div *ngIf="message.includes('OG-post:')" class="ml-[50px] cursor-pointer sm:ml-[25px] text-[14px] font-SF-Pro-Text sm:font-Roboto rounded-[20px] sm:rounded-3xl sm:rounded-tl-3xl max-w-[calc(100%-150px)] sm:max-w-[calc(100%-275px)]" [class]="{
    'rounded-tl-none':position!=='right',
    'bg-[#E4E6EC]':position!=='right',
    'bg-[#519935]':position==='right',
    'text-[#E2E2EA]':position==='right',
    'rounded-tr-none':position==='right',

  }">
    <div class="flex w-fit">
      <div class="min-w-2/12" *ngIf="postData?.media?.length">
        <img [src]="postData?.media[0]" class="rounded-2xl" alt="img">
      </div>
      <div class="min-w-1/3" *ngIf="!postData?.media?.length && postData.avatar">
        <img [src]="postData.avatar" class="rounded-2xl" alt="img">
      </div>
      <div class="p-4">
        <h2 *ngIf="postData.name" class="font-semibold">{{ postData.name }}</h2>
        <h3 *ngIf="postData.title" class="font-semibold">{{ postData.title }}</h3>
        <div *ngIf="postData.description" [innerHTML]="postData.description"></div>
      </div>
    </div>
    <div class="bg-[#E4E6EC] text-[#777] p-4 rounded-b-2xl" (click)="gotoPost('/home?id='+postData.id)"><p>{{ getUrl() }}</p></div>
  </div>
  <img *ngIf="message.includes('OG-img:')" class="rounded-2xl w-full sm:w-1/3" [src]="message.split('OG-img:')[1]" alt=""
   [class]="{'ml-4': position!=='right'}">
  <span class="mt-[15px] text-[#92929D] text-[9px] sm:text-[14px] font-SF-Pro-Text sm:font-Roboto">{{getFormattedTimeOrDate(dateMessage)}}</span>
  <img *ngIf="useAvatar" [src]="avatar || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'" alt="" class="absolute left-0 top-[9px] object-cover h-[35px] w-[35px] sm:h-[32px] sm:w-[32px] border-[2px] border-white rounded-full">
</div>
