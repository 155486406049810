<div *ngIf="config" class="w-screen px-4 h-screen fixed top-0 right-0 bg-[#171725]/30 z-50 flex items-center justify-center">
  <div class="p-4 bg-white shadow-lg rounded-lg text-center w-full" [ngClass]="config.containerClass">
    <div class="flex justify-center">
      <img *ngIf="config.type=='omnigram'" src="assets/logo_black.png" class="w-[83px]" alt="">
    </div>
    <p *ngIf="config.title" class="title text-xl font-Montserrat my-3">{{config.title}}</p>
    <p *ngIf="config.description" class="description text-base font-Montserrat">{{config.description}}</p>
    <div class="mt-7">
      <p *ngIf="config.activeButtonConfirm" class="confirm cursor-pointer" [ngClass]="config.btnOkClass"
         (click)="onClickBtn()">{{config.labelConfirm}}</p>
      <p *ngIf="config.activeButtonCancel" class="cancel cursor-pointer" [ngClass]="config.btnCancelClass"
         (click)="onClickBtn(false)">{{config.labelCancel}}</p>
    </div>
  </div>
</div>
