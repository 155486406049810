<div>
<div class="pl-6 pr-2 mt-10 mb-auto">
  <div routerLink="/settings/profile" class="cursor-pointer" [class]="{'item-settings': true, 'active': isRouteActive('/settings/profile')}">Profile</div>
  <div class="px-2 py-1 font-Montserrat font-normal font-[16px] rounded-lg">Settings</div>
  <div class="pl-6">
    <div routerLink="/settings/overview" class="cursor-pointer" [class]="{'item-settings': true, '!my-1': true, 'active': isRouteActive('/settings/overview')}">
      Overview
    </div>
    <div routerLink="/settings/sign-in-method" class="cursor-pointer" [class]="{'item-settings': true, '!my-1': true, 'active': isRouteActive('/settings/sign-in-method')}">
      Sign in method
    </div>
    <div routerLink="/settings/notifications" class="cursor-pointer" [class]="{'item-settings': true, '!my-1': true, 'active': isRouteActive('/settings/notifications')}">
      Notifications
    </div>
  </div>
</div>
  <div class="flex flex-col text-[16px] text-green4-native gap-4 py-8 md:pt-8">
    <div routerLink="/settings/support"
      class="cursor-pointer flex items-center h-fit px-6 w-full font-poppins font-normal justify-start border-l-4 hover:border-green4-native hover:text-green4-native border-white">
      <img src="assets/icons-sax/question.svg" class="mr-4 w-6 rounded-md opacity-70" alt="test">
      <p>Support</p>
    </div>
  </div>
</div>
