<app-sidebar-layout title="All Courses" routeHeader="/coaching" [useHeaderEdit]="true">
  <div class="flex sm:bg-[#FAFAFA] w-full">
    <div class="w-full  px-4 sm:px-[20px]">
      <span class="sm:hidden material-symbols-outlined text-[30px] sm:text-[30px] text-[#707070] mx-[40px] mt-2 cursor-pointer" routerLink="/coaching">arrow_back</span>
      <div class="px-4 w-full">
        <div class="flex sm:pt-6 items-center justify-between relative w-full gap-4">
          <div class="w-[40%] sm:w-[220px]">
            <app-om-select *ngIf="options.length>1" id="select-nav"
                           containerClass="!bg-white !py-[4.5px] sm:!py-[8px] !font-poppins !font-medium shadow-md !text-lg"
                           containerItemsClass="!max-h-fit"
                           itemsClass="nav-select-item !py-1 hover:!bg-white hover:!text-green4-native"
                           color="gray" [options]="options" [(ngModel)]="selected" required
                           (ngModelChange)="resetFilter()"
                           #selectedNav="ngModel" [default]="selected" placeholder="Filter"
            />
          </div>
          <div class="relative w-[60%] sm:w-[calc(100%-220px)]">
            <app-om-input [useIcon]="true" color="white" [(ngModel)]="search"
                          (ngModelChange)="resetFilter()"
                          inputClass="rounded-full sm:rounded-md !bg-[#fff] !text-[#77838F] sm:!bg-white !py-[5.5px] sm:!py-[10px] shadow-md !font-poppins !font-medium relative placeholder:!text-lg !text-lg"
                          placeholder="Search">
              <app-search-icon class="hidden sm:inline-block" color="#77838F" [width]="18" [height]="18"/>
              <app-search-icon class="inline-block sm:hidden" color="#519935" [width]="18" [height]="18"/>
            </app-om-input>
          </div>
        </div>
        <app-not-found message="No courses found :(" *ngIf="!data?.length"></app-not-found>
        <div class="sm:grid grid-cols-3 gap-4 lg:gap-[40px] mt-[20px] sm:mt-[30px]">
          <ng-container *ngFor="let item of data; let i = index">
            <app-card-course [course]="item" class="block mb-1" ></app-card-course>
          </ng-container>
        </div>
      </div>
    </div>
  </div>


</app-sidebar-layout>
