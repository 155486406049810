<div *ngIf=" template==='card'" class="block shadow-[0px_3px_6px_#00000029] p-4 w-[calc(100%-32px)] sm:w-full mt-[30px] sm:mt-[40px] mx-[16px] sm:mx-0 rounded-2xl bg-white">
  <p class="font-Montserrat font-semibold mb-2 text-[17px] sm:text-[20px] px-[20px] sm:px-0">Reviews</p>
  <app-review-card *ngIf="reviews[0]" [data]="reviews[0]" [cat_interests]="cat_interests"></app-review-card>
  <p class="text-[14px] font-Poppins text-[#519935] mt-4 cursor-pointer" (click)="moreReviews()" *ngIf="reviews.length>1">Show more reviews</p>
</div>
<app-carousel *ngIf="reviews?.length && template==='carousel'"
              class="w-full"
              [enableArrow]="reviews.length>1"
              [isStrainFinder]="true"
              [moveAmount]="1"
              (endScroll)="getReviews()"
              [styleContainer]="['gsp-[15px]','sm:gap-4']"
>
  <header class="flex justify-between sm:justify-normal gap-2 sm:gap-4 items-center mb-[-20px] sm:mb-[10px] px-[20px] sm:px-0" [class]="classHeader">
    <h1 class="sm:text-[25px] font-semibold font-Montserrat !sm:text-[20px] !text-[17px]" [class]="[]">Reviews</h1>
  </header>
  <div [class]="classContainer" *ngFor="let review of reviews">
    <app-review-card
                     [data]="review"
                     [cat_interests]="cat_interests"
                     class="block shadow-[0px_3px_6px_#00000029] p-4 w-full rounded-2xl bg-white">
    </app-review-card>
  </div>
</app-carousel>
<app-dialog #dialogReviews (endContent)="getReviews()" [title]="'Reviews'" [classModal]="'sm:!min-w-[600px] sm:!max-w-[600px] sm:!min-h-[50vh] relative pt-[var(--sat)]'" >
  <app-review-card *ngFor="let review of reviews"
                   [data]="review"
                   [cat_interests]="cat_interests"
                   class="mb-2 block shadow-[0px_3px_6px_#00000029] p-4 w-[calc(100%-32px)] sm:mt-[40px] mx-[16px] rounded-2xl bg-white">
  </app-review-card>
</app-dialog>
