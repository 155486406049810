<div [ngClass]="classContainer">
  <div class="flex items-center justify-between pt-4 mb-4 bg-white px-4">
    <div class="flex gap-4 container-avatar">
      <img [src]="avatar" class="avatar">
      <div class="flex flex-col justify-center avatar-info">
        <p class="username">{{name}}
<!--          <span *ngIf="verifiedAccount" class="material-symbols-outlined verifiedAccount">-->
<!--          Done-->
<!--          </span>-->
        </p>
        <p class="font-poppins font-light hidden sm:block date">{{calculateDateDifference(date)}}</p>
        <p class="font-SF-Pro-Text text-[#77838F] sm:hidden description">@{{username}}</p>
      </div>
    </div>
    <span class="hidden sm:block material-symbols-outlined text-[#a9abad] cursor-pointer more-post hover:text-[#519935]">
      more_horiz
    </span>
    <span class="block sm:hidden material-symbols-outlined text-[#a9abad] text-[1.7rem] cursor-pointer hover:text-[#519935]">
      more_vert
    </span>
  </div>
  <hr class="hidden sm:block my-2 mx-4border-[#EEEEEE]">
  <div class="relative">
    <p class="hidden sm:block px-4 py-4">{{title}}</p>

    <div class="flex sm:px-4 justify-between gap-2 h-[500px] sm:h-[490px]">
      <div class="w-full sm:w-1/2">
        <img [src]="medios[0].url" class="h-full w-full object-cover rounded-lg">
      </div>
      <div class="hidden sm:block w-1/2 h-full">
        <div *ngFor="let medio of medios.slice(1, 3); let indexOfelement=index;" class="relative h-1/2">
          <img [src]="medio.url" class="h-full w-full object-cover rounded-lg">
          <div *ngIf="(medios.length-3>0) && indexOfelement==2" class="absolute w-full top-0">
            <span class="bg-[#00000094] py-3 px-4">+{{medios.length-3}}</span>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="flex justify-center sm:hidden pt-2">
    <svg id="Slider" xmlns="http://www.w3.org/2000/svg" width="36" height="8" viewBox="0 0 36 8">
      <circle id="Oval" cx="4" cy="4" r="4" fill="#29aced"/>
      <circle id="Oval-2" data-name="Oval" cx="4" cy="4" r="4" transform="translate(14)" fill="#b3e6ff"/>
      <circle id="Oval-3" data-name="Oval" cx="4" cy="4" r="4" transform="translate(28)" fill="#b3e6ff"/>
    </svg>
  </div>
  <hr class="hidden sm:block my-2 border-[#EEEEEE] mx-4">
  <app-reactions-bar
    class="block py-2 px-4"
    [seeLabelsReactions]="true"
    [numberReactions]="{
      numberLikes:10,
      numberComments:110,
      numberShares:1,
      numberSaved:5,
  }" ></app-reactions-bar>
  <app-liked-bar class="block sm:hidden px-4"></app-liked-bar>
  <p class=" sm:hidden px-4 pt-4 font-medium">{{name}}</p>
  <p class=" sm:hidden px-4 pb-4">{{title}}</p>
</div>
