<app-settings-layout>
  <div class="w-full sm:max-w-[1050px] p-6">
    <!--    <div class="px-6 my-2">-->
    <!--      <app-arrow-left-icon [width]="35" [height]="35" color="#00000066" class="cursor-pointer" (click)="router.navigateByUrl('/profile')"/>-->
    <!--    </div>-->
    <div class="rounded-2xl flex flex-col bg-white sm:shadow-lg w-full p-4 sm:p-8">
      <h2 class="font-poppins font-medium text-[#202020] text-[36px]">Sign in method</h2>
      <hr class="bw-1 border-[##00000014] h-0.5 z-10 my-6">
      <div class="flex justify-between items-center">
        <div class="flex items-center gap-4">
          <div class="flex flex-col gap-2">
            <p class="font-poppins font-normal text-[#202020] text-[16px] flex justify-between">Email Address
            </p>
            <p class="font-poppins font-normal text-[#898989] text-[14px]">{{user['email']}}</p>
          </div>
        </div>
        <div class="flex flex-col items-end">
          <button class="flex px-4 !py-[8.5px] border-2 border-green-native mb-2 h-fit gap-2 items-center font-normal text-xs bg-[#fff] text-[#202020] rounded-xl shadow-sm">
            Change email
          </button>
        </div>
      </div>
      <hr class="bw-1 border-[##00000014] h-0.5 z-10 my-6">
      <div class="flex justify-between items-center">
        <div class="flex items-center gap-4">
          <div class="flex flex-col gap-2">
            <p class="font-poppins font-normal text-[#202020] text-[16px] flex justify-between">Password
            </p>
            <p class="font-poppins font-normal text-[#898989] text-[14px]">*********</p>
          </div>
        </div>
        <div class="flex flex-col items-end">
          <button class="flex px-4 !py-[8.5px] border-2 border-green-native mb-2 h-fit gap-2 items-center font-normal text-xs bg-[#fff] text-[#202020] rounded-xl shadow-sm">
            Change password
          </button>
        </div>
      </div>
      <hr class="bw-1 border-[##00000014] h-0.5 z-10 my-6">
      <div class="bg-[#F9F9F9] rounded-lg p-4 mt-8 flex items-stretch justify-start gap-2">
        <app-secure-icon [width]="30" [height]="30" color="#519935"></app-secure-icon>
        <div class="flex flex-col gap-2">
          <p class="font-Montserrat font-normal text-[#202020] text-[16px]">
            Secure Your Account
          </p>
          <p class="font-Montserrat font-normal text-[#202020] text-[14px] text-[#5A5A5A]">
            Two-factor authentication adds an extra layer of security to your account. To log in, in addition you'll need to provide a 6 digit code.
            <a href="#" class="text-[#519935] underline">Learn more.</a>
          </p>
        </div>
        <div class="flex items-center">
          <button class="flex px-4 !py-[10.5px] mb-2 h-fit gap-2 items-center font-normal text-xs bg-[#519935] text-white rounded-xl shadow-sm">
            Enable
          </button>
        </div>
      </div>
    </div>
  </div>
</app-settings-layout>
