<div class="flex w-full gap-4 items-center">
  <div class="rounded-lg bg-cover bg-center w-24 h-24 cursor-pointer"
       [style.background-image]="'url(' + data.communityImgProfile + ')'" [routerLink]="'/community'" [queryParams]="{ id: data.communityId }"></div>
  <div class="flex flex-col sm:flex-row bw-1 rounded-lg w-[calc(100%-96px)] p-2 sm:p-4 gap-2 items-center justify-between">
    <div class="w-full sm:w-2/3">
      <p class="font-semibold font-Montserrat text-[14px] cursor-pointer" [routerLink]="'/community'" [queryParams]="{ id: data.communityId }">{{data.communityName}}</p>
      <div class="flex gap-4 mt-[5px]">
        <p class="w-[40%] sm:w-1/2 font-normal font-Montserrat text-[14px] text-[#92929D]">{{ data.communityCategory }}</p>
        <p class="w-[60%] sm:w-1/2 font-normal font-Montserrat text-[14px] text-[#92929D]">{{ data.communityMembersCount }} Members</p>
      </div>
      <div class="flex gap-4 mt-[10px] sm:mt-[5px]">
        <div class="flex items-center gap-2 w-[40%] sm:w-1/2">
          <img *ngFor="let item of data.friends" [src]="item.image_profile" class="h-[19px] w-[19px] rounded-full object-cover" alt="">
          <span class="h-[18px] w-[18px] flex items-center justify-center border-[0.5px] border-[#E2E2EA] rounded-full text-[7px] text-[#696974]">+ {{data.communityMembersCount}}</span>
        </div>
        <p class="w-[60%] sm:w-1/2 font-normal font-Montserrat text-[14px] text-[#92929D]">{{ data.countFriends}} Friends Connect</p>
      </div>
    </div>
    <button
      *ngIf="data.isMember===1"
      class="bg-[#F1F1F5] text-[#696974] font-Montserrat font-semibold text-[12px] px-10 py-2 h-fit mt-2 w-full sm:w-auto rounded-lg" [routerLink]="'/community'" [queryParams]="{ id: data.communityId }">
      Go to community
    </button>
    <button
      *ngIf="data.isMember===0 && (data.communityPrivacy==='Public' || isMe())"
      class="bg-[#F1F1F5] text-[#696974] font-Montserrat font-semibold text-[12px] px-10 py-2 h-fit mt-2 w-full sm:w-auto rounded-lg" (click)="joinCommunity()">
      Join
    </button>
    <button
      *ngIf="data.isMember===0 && data.communityPrivacy!='Public' && !isMe()"
      class="bg-[#F1F1F5] text-[#696974] font-Montserrat font-semibold text-[12px] px-10 py-2 h-fit mt-2 w-full sm:w-auto rounded-lg" (click)="applyJoin()">
      Apply to Join
    </button>
  </div>
</div>
