import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input, OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {CommentComponent} from "../comment/comment.component";
import {HttpClient} from "@angular/common/http";
import {UserInfoService} from "../../../services/user-info/user-info.service";
import {FollowAndFriendService} from "../../../services/follow-and-friend/follow-and-friend.service";
import {DomSanitizer} from "@angular/platform-browser";
import * as mapboxgl from "mapbox-gl";
import {environment} from "../../../../environments/environment";
import {ChatService} from "../../../services/chat/chat.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-publication',
  templateUrl: './publication.component.html',
  styleUrls: ['./publication.component.scss']
})
export class PublicationComponent implements OnInit, OnDestroy {
  private sanitizer = inject(DomSanitizer)
  chatService = inject(ChatService)
  @ViewChild(CommentComponent, {static: false}) commentComponent: CommentComponent | undefined;
  showFormComment = false;
  @Input() isNotification = false;
  @Input() isProfile = false;
  @Output() deletePost = new EventEmitter<string>();
  http = inject(HttpClient);
  followAndFriendService = inject(FollowAndFriendService);
  subscriptionComments: Subscription | undefined;
  postData: IPostView = {
    pk: '',
    postId: '',
    postType: 'news',
    avatar: '',
    username: '',
    name: '',
    verifiedAccount: false,
    is_founder: false,
    description: '',
    userDescription: '',
    date: '',
    title: '',
    hashtags: [],
    medios: [],
    postFiltersByImage: [],
    postTagPeople: [],
    postLocation: null,
    postHashtack: [],
    comments: [],
    postLikesCount: 0,
    postShareCount: 0,
    postSavedCount: 0,
    postCommentsCount: 0,
    lastLikes: [],
    like: false,
    saved: false
  }
  @Input() data = {};

  async ngOnInit() {
    this.postData = {...this.postData, ...this.data};
    if (!this.isNotification) this.getComments();

    let isFollow = await this.followAndFriendService.isFollowing(this.postData.pk.split('up#')[1])
    if (isFollow && !this.isProfile) {
      this.optionsPost.push({icon: 'bookmark', label: 'Unfollow', value: 'unfollow'},)
    } else if (!this.isProfile) {
      this.optionsPost.push({icon: 'bookmark', label: 'Follow', value: 'follow'},)
    }
    if (this.isMe()) {
      this.optionsPost.push({icon: 'delete_forever', label: 'Delete Post', value: 'deletePost'},)
    }
    if (this.postData.postId && !this.isNotification) {
      this.chatService.connectPost(this.postData.postId)
      console.log(this.postData.postId)
      this.subscriptionComments = this.chatService.currentComment.subscribe(async data => {
        if (data !== null) {
          if (data.comment && data.comment.pk.replace('post#', '') === this.postData.postId) {
            this.postData.comments.push({
              ...data.comment,
              avatar: data.comment.avatar,
              name: data.comment.name,
            });
            setTimeout(() => {
              this.commentComponent?.buttonScroll();
            }, 1000);
          }
          const objIncrement = data.objIncrement?.post || data.objIncrement?.data || data.objIncrement || null
          if (objIncrement && objIncrement.postId === this.postData.postId) {
            this.postData.postCommentsCount = objIncrement.postCommentsCount;
            this.postData.postLikesCount = objIncrement.postLikesCount;
            this.postData.postShareCount = objIncrement.postShareCount;
          }
        }
      });

    }
  }

  formatAddress() {
    if (!this.postData.postLocation?.address) return '';

    let addressSplit = this.postData.postLocation.address.split(',')
    return `${addressSplit[addressSplit.length - 2]},${addressSplit[addressSplit.length - 1]}`
  }

  constructor(private userInfo: UserInfoService) {
  }

  isMe() {
    return this.userInfo.isMe(this.postData.pk.split('up#')[1])
  }

  subscriptionCommentsGet: Subscription | undefined

  getComments() {
    this.subscriptionCommentsGet = this.http.get('/comments/' + this.postData.postId, {
      params: {
        limit: 20,
        offset: this.postData.comments.length||0,
      }
    }).subscribe({
      next: (response: any) => {
        const comments=response.filter((object: any) => (object.commentContent && object.commentContent.trim() !== '') || (object.commentUrls && object.commentUrls.length > 0))
        this.postData.comments = [
          ...comments,
          ...this.postData.comments
        ]
        this.subscriptionCommentsGet?.unsubscribe();
      }
    });
  }

  showMapLocation = false;
  @ViewChild('mapLocation') mapLocation: ElementRef | undefined;

  showMap() {
    this.showMapLocation = true;
    setTimeout(() => {
      const map = new mapboxgl.Map({
        accessToken: environment.mapboxToken,
        container: 'mapLocation',
        style: 'mapbox://styles/mapbox/streets-v12',
        zoom: 13,
        center: [this.postData.postLocation?.lng || 0, this.postData.postLocation?.lat || 0]
      });
      new mapboxgl.Marker({draggable: true, color: '#519935'})
        .setLngLat([this.postData.postLocation?.lng || 0, this.postData.postLocation?.lat || 0])
        .addTo(map);
    }, 500)
  }

  openInMedia(data: any) {
    this.postData = data;
    this.getComments();
    this.showCommentDesk()
  }

  calculateDateDifference(date: string): string {
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const inputDate = new Date(date)
    const currentDate = new Date();
    const timeDifferenceInMilliseconds = inputDate.getTime() - currentDate.getTime();
    const seconds = Math.floor(Math.abs(timeDifferenceInMilliseconds) / 1000);

    if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      return `${minutes}m`;
    } else if (seconds < 86400) {
      const hours = Math.floor(seconds / 3600);
      return `${hours}h`;
    } else if (seconds < 691200) {
      const days = Math.floor(seconds / 86400);
      return `${days}d`;
    } else {
      const day = inputDate.getDate();
      const month = inputDate.getMonth(); // Month is zero-based, so add 1
      const year = inputDate.getFullYear();
      return `${monthNames[month]} ${day.toString().padStart(2, '0')}, ${year}`;
    }
  }

  showComment() {
    this.commentComponent?.openForm(false);

  }

  subscriptionUpdateLike: Subscription | undefined

  updateLike() {
    this.subscriptionUpdateLike = this.http.post('/like', {
      'idpost': this.postData.postId,
      'idcomment': '',
      'iduserPost': this.postData.pk.replace('up#', ''),
      'val': (!this.postData.like ? '1' : '0')
    }).subscribe({
      next: (response: any) => {
        const {success} = response
        if (success) {
          this.postData.like = !this.postData.like;
          //if(this.postData.like) this.postData.postLikesCount++
          //else this.postData.postLikesCount--
        } else {
          alert("Error")
        }
      }
    });
  }

  subscriptionUpdateShare: Subscription | undefined

  updateShare() {
    this.subscriptionUpdateShare = this.http.post('/shared', {
      'idContent': this.postData.postId,
      'type': 'post',
      'iduserPost': this.postData.pk.replace('up#', ''),
      'val': '1'
    }).subscribe({
      next: (response: any) => {
        const {success} = response
        if (success) {
        } else {
          alert("Error")
        }
      }
    });
  }

  subscriptionUpdateSave: Subscription | undefined

  updateSave() {
    this.subscriptionUpdateSave = this.http.post('/saved', {
      'idContent': this.postData.postId,
      'type': 'post',
      'iduserPost': this.postData.pk.replace('up#', ''),
      'val': (!this.postData.saved ? '1' : '0')
    }).subscribe({
      next: (response: any) => {
        const {success} = response
        if (success) {
          this.postData.saved = !this.postData.saved;
          if (this.postData.saved) this.postData.postSavedCount++
          else this.postData.postSavedCount--
        } else {
          alert("Error")
        }
      }
    });
  }

  randomName(num: number) {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let result = "";
    let ch;
    while (result.length < num) {
      ch = characters.charAt(Math.floor(Math.random() * charactersLength));
      if (!result.includes(ch)) result += ch;
    }
    return result;
  }

  subscriptionAddComment: Subscription | undefined

  addComments(dataC: any) {
    const user = this.userInfo.me().user;
    if (dataC.comment == '' && dataC.files.length == 0) return;
    let data = {
      idpost: this.postData.postId,
      iduserPost: this.postData.pk.replace('up#', ''),
      content: dataC.comment
    };


    let formData: FormData = new FormData();
    formData.append('idpost', this.postData.postId);
    formData.append('iduserPost', this.postData.pk.replace('up#', ''));
    formData.append('content', dataC.comment);
    for (let file of dataC.files) {
      const ext = file.type.split('/')[1];
      const name = this.randomName(10) + `imag${ext ? '.' + ext : '.png'}`;
      formData.append('images', file, name);
    }
    this.subscriptionAddComment = this.http.post('/comment/add', formData).subscribe({
      next: (response: any) => {
        const {success, comment} = response
        console.log(response)
      }
    });
  }

  showCommentDesk() {
    this.commentComponent?.openFormDesk();
  }

  convertHashtagsToTags() {
    let hashtags = '';
    (this.postData.postHashtack ?? []).forEach(x => {
      hashtags += `<a href="/search?hashtag=${x}" class="text-green2-native">#${x}</a> `;
    })
    return this.sanitizer.bypassSecurityTrustHtml(`${this.postData.description} ${hashtags}`);
  }

  subscriptionOpenByNotification: Subscription | undefined

  openBynotification(id: string) {
    try {
      const userId = '';
      let lastPostKey = '';
      this.subscriptionOpenByNotification = this.http.get('/posts', {
        params: {userId, lastPostKey, postId: id}
      }).subscribe({
        next: (response: any) => {
          console.log(response)

          this.postData =
            {
              ...this.postData,
              ...{
                pk: response.posts[0].pk,
                postId: response.posts[0].postId,
                postType: response.posts[0].postType,
                avatar: response.posts[0].avatar,
                name: response.posts[0].name,
                date: response.posts[0].createDate,
                description: response.posts[0].postContent,
                medios: response.posts[0].postUrls,
                verifiedAccount: true,
                like: response.posts[0].like,
                postLikesCount: response.posts[0].postLikesCount,
                postCommentsCount: response.posts[0].postCommentsCount,
                save: response.posts[0]['save']
              }
            }
          this.commentComponent?.openFormDesk();
          this.getComments();
          this.chatService.connectPost('dialog-post-' + this.postData.postId);
          this.subscriptionComments = this.chatService.currentComment.subscribe(async data => {
            if (data !== null) {
              if (data.comment.pk.replace('post#', '') === this.postData.postId) {
                this.postData.comments.push({
                  ...data.comment,
                  avatar: data.comment.avatar,
                  name: data.comment.name,
                });
                this.postData.postCommentsCount++
              }
            }
          });
        }
      });
    } catch (e) {
      console.log(e)
    }
  }

  option = '';
  optionsPost: any = []

  async changeOptionPost() {
    switch (this.option) {
      case 'unfollow':
        let resp = await this.followAndFriendService.delete(this.postData.pk.split('up#')[1])
        this.optionsPost = [{icon: 'bookmark', label: 'Follow', value: 'follow'}];
        await this.followAndFriendService.getFollowings(true);
        break
      case 'follow':
        let resp1 = await this.followAndFriendService.add(this.postData.pk.split('up#')[1])
        this.optionsPost = [{icon: 'bookmark', label: 'Unfollow', value: 'unfollow'}];
        await this.followAndFriendService.getFollowings(true);
        break;
      case "deletePost":
        this.http.delete('/post/delete', {
          params: {
            id: this.postData.postId,
          }
        }).subscribe({
          next: () => {
            this.deletePost.emit(this.postData.postId);
          }
        });
        break;
    }
  }

  protected readonly JSON = JSON;

  ngOnDestroy() {
    if (this.subscriptionComments)
      this.chatService.disconnectPost(this.postData.postId);
  }

  destroyConection() {
    if (this.subscriptionComments && this.isNotification)
      this.chatService.disconnectPost('dialog-post-' + this.postData.postId);

    this.subscriptionUpdateLike?.unsubscribe()
    this.subscriptionUpdateSave?.unsubscribe()
    this.subscriptionUpdateShare?.unsubscribe()
    this.subscriptionAddComment?.unsubscribe()
    this.subscriptionOpenByNotification?.unsubscribe()
    this.subscriptionCommentsGet?.unsubscribe()
  }
}
