<app-carousel *ngIf="users?.length" class="w-full" [isStrainFinder]="true" [moveAmount]="0.23" [styleContainer]="['gap-6']" [enableArrow]="false">
  <header-left>
    <div class="flex gap-1 sm:gap-4 items-center mb-4">
      <h1 class="text-[20px] sm:text-[25px] font-semibold font-Montserrat" [class]="classTitle">Top Growers</h1>
      <p class="flex items-center text-green2-native mt-[3px] text-[15px] font-medium font-Montserrat"  routerLink="/growers-home">
        <span class="hidden sm:block cursor-pointer">View All</span>
        <span class="material-symbols-outlined flex items-center justify-center border-[1.5px] border-green4-native w-[18px] h-[18px] pl-[1px] text-[20px] sm:text-[16px] sm:w-auto rounded-full sm:border-0">navigate_next</span>
      </p>
    </div>
  </header-left>
  <header-action>
  </header-action>
  <div class="bg-white relative border-[#707070] shadow-[0px_3px_6px_#00000029] sm:shadow-none rounded-lg friend-card mr-[15px] sm:mr-0 w-[196px] sm:w-[254px] mb-1 min-h-[210px] sm:h-auto" *ngFor="let user of users">
    <div class="h-[64px] sm:h-[124px] overflow-hidden sm:pt-[8px] sm:mx-[8px]" [routerLink]="'/profile/'+(user['id'])">
      <img [src]="user['banner'] || 'assets/demo/paisaje.webp'" class="object-cover w-full h-full rounded-t-lg" alt="">
    </div>
    <div class="rounded-b-lg flex flex-col items-center justify-center relative">
      <div class="absolute top-[-45px] sm:top-[-64px] flex w-full justify-center">
        <div class="relative">
          <img
            [src]="user['image_profile'] || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'"
            alt="" class="w-[90px] h-[90px] sm:w-[107px] sm:h-[107px] border-[6px] border-[#fff] rounded-full object-cover	" [routerLink]="'/profile/'+(user['id'])">
          <div *ngIf="user['is_founder']===1" class="absolute bottom-0 right-0 w-[20px] h-[20px] sm:w-[30%] sm:h-[30%] p-[5px] rounded-[15px] sm:rounded-full bg-white">
            <img src="assets/ogfc.png" alt="" class="object-cover w-full">
          </div>
        </div>
         <span *ngIf="user['is_grower_verified']===1" class="material-symbols-outlined flex justify-center items-center bg-green-native text-white rounded-full
         h-[18px] w-[18px] text-[15px] sm:h-[25px] sm:w-[25px] sm:text-[20px] absolute bottom-[5px] right-[calc(50%-35px)] sm:right-[calc(50%-48px)] pt-[1px] font-bold">
         Done
         </span>
      </div>
      <div class="w-full pt-[40px] sm:pt-[45px]">
        <div class="flex items-center justify-center w-full">
          <div class="flex flex-col items-center justify-center w-full px-4" [routerLink]="'/profile/'+(user['id'])">
            <p class="text-[18px] font-semibold text-[#171725] whitespace-nowrap overflow-hidden text-center overflow-ellipsis w-full font-Montserrat">{{ getName(user) }}</p>
            <p class="text-[12px] text-[#696974] pt-1">{{ user['countryName'] }}</p>
            <p class="text-[12px] text-[#696974]">{{ user[fieldRanked] }}
            <span class="rating">
              <label class="active"></label>
            </span>
            </p>
          </div>
        </div>
      </div>
      <div class="hidden sm:flex items-center justify-center w-full gap-3 pt-[10px] pb-[20px]">
        <ng-container *ngIf="user['isFollowing']">
          <p (click)="unfollow(user['id'])" class="text-[10px] font-Montserrat bg-[#FFFFFF] font-poppins font-medium sm:text-[12px] text-[#519935] bw-1 border-[#81CB52] rounded-2xl flex justify-center items-center w-[75px] h-[24px]">
            Following</p>
          <p (click)="goToMessages(user['id'])" class="text-[10px] font-Montserrat bg-[#87D255] font-poppins font-medium sm:text-[12px] text-[#FFFFFF] bw-1 border-[#87D255] rounded-2xl flex justify-center items-center w-[75px] h-[24px]">
            Message</p>
        </ng-container>
        <ng-container *ngIf="!user['isFollowing']">
          <p (click)="follow(user['id'])" class="text-[10px] font-Montserrat bg-[#FFFFFF] font-poppins font-medium sm:text-[12px] text-[#519935] bw-1 border-[#81CB52] rounded-2xl flex justify-center items-center w-[75px] h-[24px]">
            Follow</p>
          <p (click)="goToMessages(user['id'])" class="text-[10px] font-Montserrat bg-[#87D255] font-poppins font-medium sm:text-[12px] text-[#FFFFFF] bw-1 border-[#87D255] rounded-2xl flex justify-center items-center w-[75px] h-[24px]">
            Message</p>
        </ng-container>
      </div>
      <hr class="border-[#EEEEEE] border-[1px] mt-[10px] sm:hidden w-full">
      <div class="flex sm:hidden items-center justify-center w-full py-[10px] pb-1">
        <ng-container *ngIf="!user['isFollowing']">
        <p (click)="unfollow(user['id'])"
          class="text-[12px] font-Montserrat bg-[#FFFFFF] m-0 font-poppins font-semibold text-[#519935] px-3">
          Follow</p>
        </ng-container>
        <ng-container *ngIf="user['isFollowing']">
        <p (click)="follow(user['id'])"
          class="text-[12px] font-Montserrat bg-[#FFFFFF] m-0 font-poppins font-semibold text-[#519935] px-3">
          Following</p>
        </ng-container>
      </div>
    </div>
  </div>
</app-carousel>
