import {Component, inject, OnInit} from '@angular/core';
import {DeviceInfoService} from "../../../services/device-info/device-info.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
  deviceInfoService = inject(DeviceInfoService)
  route = inject(ActivatedRoute)
  ngOnInit() {
    window.scrollTo(0, 1);
    this.route.fragment.subscribe(f => {
      const element = document.querySelector("#" + f)
      if (element) element.scrollIntoView({ behavior: 'smooth' })
    });
  }
  goBack(){
    this.deviceInfoService.goBack()
  }
}
