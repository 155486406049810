  <p class="text-[17px] mt-[30px] sm:mt-0 sm:text-[24px] font-Montserrat font-semibold">Flavours:</p>
  <div class="px-[20px] sm:px-0 flex gap-6 ocultarScrollXNoVisible">
    <div *ngFor="let flower of flavors">
      <div class="flex items-center justify-center w-[54px] h-[54px] sm:w-[85px] sm:h-[85px] rounded-full p-2 sm:p-4 shadow-[0px_2px_48px_#0000000A]">
        <img [src]="'assets/icons-sax/pine.svg'" alt=""/>
      </div>
      <p class="text-center text-[11px] sm:text-[20px] font-Montserrat font-medium">{{flower}}</p>
    </div>
  </div>
  <p class="text-[17px]  mt-[30px] sm:mt-0 sm:text-[24px] font-Montserrat font-semibold my-5">Terpenes</p>
  <div class="px-[20px] sm:px-0 flex ocultarScrollXNoVisible">
    <div class="flex items-center gap-[5px] mr-[35px]" *ngFor="let terpen of terpenes;">
      <img class="h-[20px] sm:h-[27px]" src="assets/demo/diagonal1.png" alt=""/>
      <p class="text-[16px] sm:text-[20px] font-Montserrat font-medium">{{terpen}}</p>
    </div>
  </div>
