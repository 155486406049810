<app-sidebar-layout [title]="categories[indexCategory].label" routeHeader="goBack"  [useHeaderEdit]="true">
  <div class="px-4 w-full">
    <span class=" sm:hidden material-symbols-outlined text-[30px] sm:text-[30px] text-[#707070] mt-4 cursor-pointer" (touchend)="goBack()">arrow_back</span>

    <div class="flex pt-6 items-center justify-between relative w-full gap-2">
      <div class="w-[180px] hidden sm:block">
        <app-om-select id="select-nav"
                       containerClass="!bg-white !py-[4.5px] sm:!py-[12px] !font-poppins !font-medium shadow-md !text-[16px]"
                       containerItemsClass="!max-h-[150px]"
                       itemsClass="nav-select-item !py-1 hover:!bg-white hover:!text-green4-native"
                       color="gray" [options]="categories" [(ngModel)]="filters['category']" required
                       (ngModelChange)="setFilters()"
                       #selectedNav="ngModel" [default]="filters['category']" placeholder="Filter"
        />
      </div>
      <div class="relative w-[calc(100%-20px)] sm:w-[calc(100%-360px)]">
        <app-om-input [useIcon]="true" color="white" [(ngModel)]="search"
                      inputClass="rounded-full placeholder:!text-sm sm:rounded-md !bg-[#fff] text-[#77838F] sm:!bg-white !py-[5.5px] sm:!py-[12px] shadow-md !font-poppins !font-medium relative placeholder:!text-lg !text-[16px]"
                      placeholder="Search">
          <app-search-icon class="hidden sm:inline-block" color="#77838F" [width]="18" [height]="18"/>
          <app-search-icon class="inline-block sm:hidden" color="#519935" [width]="18" [height]="18"/>
        </app-om-input>
        <div class="absolute bg-[#d0d0d0] rounded-full p-1 right-[5px] sm:right-2 top-1 sm:top-2">
          <div class="relative" #filterList>
            <app-filters-icon [width]="24" [height]="24" (click)="showFilters = !showFilters"></app-filters-icon>
            <div #containerFilterList *ngIf="showFilters" class="absolute bg-white rounded-sm z-20 right-0 bw-1 border-[#d0d0d0] top-[28px]">
              <div class="py-2 px-4 border-[#d0d0d0] border-b flex gap-2 sm:gap-4 ">
                <div (click)="setSort('sort')" class="bg-[#fff] flex w-fit rounded-full items-center bw-1 border-[#efefef] gap-2 font-poppins text-[12px] py-1 px-3 sm:px-4"
                     [class]="{'!bg-[#EFEFEF]': filterTab === 'sort'}">
                  Sort
                  <app-chevron-up-icon *ngIf="filterTab === 'sort'" [width]="16" [height]="16"/>
                  <app-chevron-down-icon *ngIf="filterTab !== 'sort'" [width]="16" [height]="16"/>
                </div>
                <div (click)="setSort('category')" class="bg-[#fff] flex w-fit rounded-full items-center bw-1 border-[#efefef] gap-2 font-poppins text-[12px] py-1 px-3 sm:px-4"
                     [class]="{'!bg-[#EFEFEF]': filterTab === 'category'}">
                  Category
                  <app-chevron-up-icon *ngIf="filterTab === 'category'" [width]="16" [height]="16"/>
                  <app-chevron-down-icon *ngIf="filterTab !== 'category'" [width]="16" [height]="16"/>
                </div>
                <div (click)="setSort('type')" class="bg-[#fff] flex w-fit rounded-full items-center bw-1 border-[#efefef] gap-2 font-poppins text-[12px] py-1 px-3 sm:px-4"
                     [class]="{'!bg-[#EFEFEF]': filterTab === 'type'}">
                  Type
                  <app-chevron-up-icon *ngIf="filterTab === 'type'" [width]="16" [height]="16"/>
                  <app-chevron-down-icon *ngIf="filterTab !== 'type'" [width]="16" [height]="16"/>
                </div>
              </div>
              <div class="py-2 px-4 border-[#d0d0d0] border-b flex gap-4 " *ngFor="let item of getCatalogFilter()" (click)="setFilterType(item.value)" >
                <div [class]="{'!text-[#000]': filters[filterTab] === item.value, '!font-medium ': filters[filterTab] === item.value}"
                     class="flex rounded-full items-center justify-between w-full gap-2 font-poppins text-[#75838D] font-normal text-[14px] py-1 px-1">
                  {{ item.label }}
                  <app-right-check-icon *ngIf="filters[filterTab] === item.value" [width]="18" [height]="18" color="#000"/>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="w-fit sm:w-[160px]">
        <button
          class="p-1.5 w-full sm:px-4 sm:!py-[14px] gap-2 flex items-center font-semibold text-[14px] bg-[#519935] text-white rounded-full sm:rounded-md shadow-sm"
          routerLink="/add-product">
          <ng-icon name="matPlus" class="text-white cursor-pointer text-lg z-10"></ng-icon>
          <span class="hidden sm:flex">New Product</span>
        </button>
      </div>
    </div>
    <app-category-banner [categories]="categoriesObjs" class="block my-[20px] mt-[18px] sm:mt-[20px]"></app-category-banner>
    <app-cards-filter class="block mt-[33px] sm:mt-[33px]" [filters]="{
    category: this.filters['category'], type: this.filters['type'], sortKey: 'createDate', limit: 15
    }"></app-cards-filter>
    <app-cards-filter title="Trending"  class="block mt-[33px] sm:mt-[49px]" [filters]="{
    category: this.filters['category'], type: this.filters['type'], sortKey: 'createDate', limit: 15
    }"></app-cards-filter>
    <app-growers class="block mt-[33px] sm:mt-[33px]"  [filters]="{
    category: this.filters['category'], type: this.filters['type']
    }"></app-growers>
    <app-custom-carousel class="block mt-[20px] sm:mt-[42px]"></app-custom-carousel>
    <app-cards-effects class="block mt-[20px] sm:mt-[33px]"></app-cards-effects>
    <app-cards-filter class="block pt-[33px]" title="High THC" [filters]="{
    category: this.filters['category'], type: this.filters['type'], sortKey: 'thc', sort: 'desc', limit: 15
    }"/>
    <app-cards-filter class="block pt-[33px]" title="Recently Added" [filters]="{
    category: this.filters['category'], type: this.filters['type'], sortKey: 'createDate', sort: 'desc', limit: 15
    }"/>
    <app-cards-filter class="block pt-[33px]" title="Indica" [filters]="{
    category: this.filters['category'], type: 'indica', sortKey: 'createDate', sort: 'desc', limit: 15
    }"/>
    <app-cards-filter class="block pt-[33px]" title="Sativa" [filters]="{
    category: this.filters['category'], type: 'sativa', sortKey: 'createDate', limit: 15
    }"></app-cards-filter>
    <app-cards-filter class="block pt-[33px]" title="CDB" [filters]="{
    category: this.filters['category'], type: 'cdb', sortKey: 'createDate', limit: 15
    }"></app-cards-filter>
    <app-all-products class="block mt-[33px] mb-4" [filters]="{
    category: this.filters['category'], type: this.filters['type'], sortKey: 'createDate', limit: 10
    }"></app-all-products>
  </div>
</app-sidebar-layout>
