<div *ngIf="loading" class="relative min-h-screen w-full">
  <app-loading></app-loading>
</div>
<div class="h-screen top-0 z-20 sm:z-0 sm:w-full w-full sm:h-auto xs:bg-white sm:bg-[#FAFAFA]">
  <div *ngIf="!loading" class="bg-white rounded-b-[calc(var(--w-width)*2)] h-auto z-30">
    <div class="relative">
      <div class="cover-page">
        <img [src]="user['banner'] || 'assets/demo/paisaje.webp'" alt="">
        <div
          class="bg-gradient-to-b sm:bg-gradient-to-t absolute top-0 from-gray-900 sm:via-transparent to-transparent sm:rounded-b-[2rem] h-[200px] w-full"></div>
      </div>
      <div
        class="flex w-full absolute top-0 pt-[calc(0.5rem+var(--sat))] sm:pt-4 px-6 items-center justify-between sm:justify-end">
        <span class="material-symbols-outlined cursor-pointer text-white text-4xl sm:hidden" (touchend)="goBack()" >arrow_left_alt</span>
        <p class="text-white text-[16px] sm:hidden">Profile</p>
        <div class="flex gap-2">
          <span
            class="cursor-pointer text-[20px] p-auto bg-white text-[#333333] h-[30px] w-[30px] rounded-xl flex justify-center items-center"
            routerLink="/settings/profile" [class]="{'!hidden': !isMe()}">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20">
              <g id="Icon" transform="translate(1 1)">
                <circle id="Oval" cx="2.455" cy="2.455" r="2.455" transform="translate(6.545 6.545)" fill="none"
                        stroke="#333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <path id="Shape"
                      d="M15.055,11.455a1.35,1.35,0,0,0,.27,1.489l.049.049a1.637,1.637,0,1,1-2.315,2.315l-.049-.049a1.361,1.361,0,0,0-2.307.965v.139a1.636,1.636,0,1,1-3.273,0V16.29a1.35,1.35,0,0,0-.884-1.235,1.35,1.35,0,0,0-1.489.27l-.049.049a1.637,1.637,0,1,1-2.315-2.315l.049-.049A1.361,1.361,0,0,0,1.775,10.7H1.636a1.636,1.636,0,0,1,0-3.273H1.71a1.35,1.35,0,0,0,1.235-.884,1.35,1.35,0,0,0-.27-1.489l-.049-.049A1.637,1.637,0,1,1,4.942,2.692l.049.049a1.35,1.35,0,0,0,1.489.27h.065a1.35,1.35,0,0,0,.818-1.235V1.636a1.636,1.636,0,0,1,3.273,0V1.71a1.361,1.361,0,0,0,2.307.965l.049-.049a1.637,1.637,0,1,1,2.315,2.315l-.049.049a1.35,1.35,0,0,0-.27,1.489v.065a1.35,1.35,0,0,0,1.235.818h.139a1.636,1.636,0,1,1,0,3.273H16.29A1.35,1.35,0,0,0,15.055,11.455Z"
                      fill="none" stroke="#333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
            </svg>
          </span>
          <span
            (touchend)="openChat()"
            class="cursor-pointer text-[20px] p-auto bg-white text-[#333333] h-[30px] w-[30px] rounded-xl flex justify-center items-center sm:hidden"
            [class]="{'!hidden': isMe()}">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 19.035 15">
              <g id="Icon" transform="translate(1.393 1)">
                <path id="Shape"
                      d="M1.625,0h13A1.63,1.63,0,0,1,16.25,1.625v9.75A1.63,1.63,0,0,1,14.625,13h-13A1.63,1.63,0,0,1,0,11.375V1.625A1.63,1.63,0,0,1,1.625,0Z"
                      fill="none" stroke="#333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <path id="Shape-2" data-name="Shape" d="M16.25,0,8.125,5.688,0,0" transform="translate(0 1.625)"
                      fill="none" stroke="#333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
            </svg>
          </span>
        </div>
      </div>
      <div class="flex absolute top-[180px] sm:top-[150px] gap-2 right-0 pr-4 ">
        <div class="text-[9px] cursor-pointer bg-[#87D255] font-poppins font-medium sm:text-[12px] text-[#FFFFFF] rounded-2xl px-4 py-3"
           (click)="typeAccounts = 'follower';setTab('friends')"
           (touchend)="setTab('friends')" (touchstart)="typeAccounts = 'follower'">{{ followersCount }} Followers</div>
        <div class="text-[9px] cursor-pointer bg-[#FFFFFF] font-poppins font-medium sm:text-[12px] text-[#519935] rounded-2xl px-4 py-3"
           (click)="typeAccounts = 'following';setTab('friends')"
           (touchend)="setTab('friends')" (touchstart)="typeAccounts = 'following'">{{ followingsCount }} Following</div>
      </div>
    </div>
    <div class="flex relative w-fit items-start mt-[-90px] sm:mt-[-63px] ml-4">
      <div class="relative">
        <div class="relative">
          <img
            [src]="user['image_profile'] || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'"
            alt="" class="w-[120px] h-[120px] sm:border-[5px] sm:border-[#87D255] rounded-3xl object-cover">
          <div *ngIf="user['is_founder']===1" class="absolute bottom-0 right-0 w-[30px] h-[30px] sm:w-[45px] sm:h-[45px] p-[2.5px] rounded-[15px] sm:rounded-full bg-white">
            <img src="assets/ogfc.png" alt="" class="object-cover w-full">
          </div>
        </div>

        <div class=" absolute  right-0 bottom-[5px] mr-[10px] sm:hidden w-full" [class]="{'!hidden': isMe()}">
          <div class="flex gap-1 justify-end w-full">
            <div *ngIf="!isMyFriend && !friendRequestSend.success && !friendRequestReceived.success" (click)="addFriendRequest()"
                 class=" cursor-pointer rounded-l-lg text-[11px] text-white text-center">
              <span class="material-symbols-outlined p-[5px] rounded-full bg-omgreen-600 text-[18px]">person_add</span>
            </div>
            <div *ngIf="!isMyFriend && friendRequestReceived.success" (click)="updateFriendRequest('accepted')"
                 class=" cursor-pointer rounded-l-lg text-[11px] text-white text-center">
              <span class="material-symbols-outlined  p-[5px] rounded-full bg-omgreen-600 text-[18px]">person_check</span>
            </div>
            <div *ngIf="!isMyFriend && friendRequestReceived.success" (click)="updateFriendRequest('rejected')"
                 class=" cursor-pointer rounded-l-lg text-[11px] text-white text-center">
              <span class="material-symbols-outlined p-[5px] rounded-full bg-red-600 text-[18px]">person_remove</span>
            </div>
            <div *ngIf="!isMyFriend && friendRequestSend.success" (click)="deleteFriendRequest('Are you sure you want to remove this request?')"
                 class=" cursor-pointer rounded-l-lg text-[11px] text-white text-center">
              <span class="material-symbols-outlined p-[5px] rounded-full bg-red-600 text-[18px]">person_remove</span>
            </div>
            <div *ngIf="isMyFriend" (click)="deleteFriendRequest('Are you sure you want to remove this friend?')"
                 class="cursor-pointer rounded-l-lg text-[11px] text-white text-center">
              <span class="material-symbols-outlined p-[5px] rounded-full bg-red-600 text-[18px]">person_remove</span>
            </div>
          </div>
        </div>
      </div>


      <div class="ml-4 sm:ml-6 mt-24 sm:mt-5 sm:leading-none	">
        <p
          class="text-[#333333] font-SF-Pro-Text text-[16px] font-medium sm:font-Poppins sm:font-semibold sm:text-[#FAFAFB] sm:text-[18px]">{{ getName() }}</p>
        <p class="text-[#77838F] font-SF-Pro-Text sm:font-Montserrat sm:text-[#D5D5DC] text-[12px] sm:text-[11px]"></p>
        <!--AQUI VA LO DE VERIFIED GROWER-->
      </div>
    </div>
    <div class="sm:hidden text-[#77838F] text-[14px] font-SF-Pro-Text px-[17px] w-full mt-3 sm:mt-6"
         [innerHTML]="getAboutMe()"
         [class]="{
      'hidden': tab === 'sharables',
      }">
    </div>
    <hr class="sm:hidden mx-3">
    <div class="nav-profile relative z-5">
      <div class="hidden sm:flex absolute top-4 gap-2 left-[125px]"
           [class]="{'!hidden': isMe()}">
        <button *ngIf="!isFollowing" (click)="follow()"
                class="hidden sm:flex pr-4 px-2 !py-2 h-fit gap-1 items-center font-semibold text-xs bg-omgreen-600 text-white rounded-md shadow-sm"
        >
          <ng-icon name="matPlus" class="text-white cursor-pointer text-lg z-10"></ng-icon>
          Follow
        </button>
        <button *ngIf="isFollowing" (click)="unfollow()"
                class="hidden sm:flex px-2 !py-2 h-fit gap-1 items-center justify-center font-semibold text-xs bw-1 border-omgreen-600 text-omgreen-600 rounded-md shadow-sm"
        >
          Following
        </button>
        <div class="bw-1 border-omgreen2-600 rounded-full p-1" routerLink="/gift-send">
          <app-gift-icon [width]="22" [height]="22" color="#519935"></app-gift-icon>
        </div>
      </div>
      <p (click)="setTab('posts')"
         class="cursor-pointer flex flex-col items-center justify-center !min-w-min	 sm:!min-w-[74px]" [ngClass]="{
      'active':tab === 'posts'
      }">
        <span class="pt-1 sm:py-2">Posts</span>
        <span class="quantity hidden font-semibold sm:block">{{ user['countPost'] }}</span>
        <span class="block sm:hidden dot"></span>
      </p>
      <p (click)="setTab('sharables')" class="cursor-pointer flex flex-col items-center justify-center" [ngClass]="{
      'active':tab === 'sharables'
      }">
        <span class="pt-1 sm:py-2">Sharables</span>
        <span class="quantity hidden font-semibold sm:block">{{ user['countSharables'] }}</span>
        <span class="block sm:hidden dot"></span>
      </p>
      <p (click)="setTab('coaching')" class="cursor-pointer flex flex-col items-center justify-center" [ngClass]="{
      'active':tab === 'coaching'
      }">
        <span class="pt-1 sm:py-2">Coaching</span>
        <span class="quantity hidden font-semibold sm:block">{{ user['countCoaching'] }}</span>
        <span class="block sm:hidden dot"></span>
      </p>
<!--      <p (click)="setTab('accessories')" class="cursor-pointer flex flex-col items-center justify-center" [ngClass]="{-->
<!--      'active':tab === 'accessories'-->
<!--      }">-->
<!--        <span class="pt-1 sm:py-2">Accessories</span>-->
<!--        <span class="quantity hidden font-semibold sm:block">{{ user['countAccessories'] }}</span>-->
<!--        <span class="block sm:hidden dot"></span>-->
<!--      </p>-->
      <p (click)="typeAccounts = 'friends'; setTab('friends')" (touchend)="setTab('friends')"
         (touchstart)="typeAccounts = 'friends'" class="cursor-pointer flex flex-col items-center justify-center"
         [ngClass]="{
      'active':tab === 'friends'
      }">
        <span class="pt-1 sm:py-2">Friends</span>
        <span class="quantity hidden font-semibold sm:block">{{ user['countFriends'] }}</span>
        <span class="block sm:hidden dot"></span>
      </p>
      <button *ngIf="isMe()"
              class="hidden sm:flex px-4 !py-[10.5px] mb-2 h-fit gap-2 items-center font-semibold text-xs bg-[#519935] text-white rounded-md shadow-sm"
              [class]="{
              'opacity-0': ['sharables', 'coaching', 'accessories', 'friends'].includes(tab)
              }"
              (click)="newPost()">
        <ng-icon name="matPlus" class="text-white cursor-pointer text-lg z-10"></ng-icon>
        New Post
      </button>
    </div>
  </div>
  <div *ngIf="!loading" class="">
    <app-posts *ngIf="tab === 'posts'" class="block px-[14px] bg-white sm:bg-[#FAFAFA]" [userProfile]="user"
               [userId]="id" [(type)]="type" [isMyFriend]="isMyFriend" [friendRequestReceived]="friendRequestReceived" [friendRequestSend]="friendRequestSend" (addFriendRequest)="addFriendRequest()"
               (deleteFriendRequest)="deleteFriendRequest($event)" (updateFriendRequestEmit)="updateFriendRequest($event)"></app-posts>
    <app-sharables *ngIf="tab === 'sharables'" class="px-[14px] bg-white sm:bg-[#FAFAFA]" [userId]="id"></app-sharables>
    <app-own-courses *ngIf="tab === 'coaching'" class="px-[14px] bg-white sm:bg-[#FAFAFA]"
                     [userId]="id"></app-own-courses>
    <app-own-accesories *ngIf="tab === 'accessories'" class="px-[14px] bg-white sm:bg-[#FAFAFA]"
                        [userId]="id"></app-own-accesories>
    <app-friends *ngIf="tab === 'friends'" [userId]="user['id']" [type]="typeAccounts"></app-friends>
  </div>
</div>
