import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-og-coaching',
  templateUrl: './og-coaching.component.svg',
  styleUrls: ['./og-coaching.component.scss']
})
export class OgCoachingComponent {
  @Input() color = '#77838f';
  @Input() width = 24;
  @Input() height = 17;
}
