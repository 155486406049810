<div class="fixed w-full h-full top-0 left-0 z-50 flex sm:items-center sm:justify-center bg-[#545454]">
  <div class=" relative min-h-screen sm:min-h-fit mb-[50px] flex flex-col w-full sm:w-[300px] pt-[var(--sat)] overflow-auto">
    <div class="px-4 sm:px-0">
      <div class="progress-bar">
        <div class="progress" [style.width.%]="progress$ | async"></div>
      </div>
    </div>
    <div class="relative w-full">
      <div class="w-full relative rounded-2xl overflow-hidden" [innerHTML]="currentStory.content"></div>
      <div class="absolute top-3 left-3 block w-12 h-12 border-green-native bw-1 rounded-full overflow-hidden">
        <img [src]="currentStory.avatar || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'" class="w-full h-full" [alt]="currentStory.username">
      </div>
      <button type="button" (click)="close()"
              class="absolute top-3 right-3 py-2 px-2 bg-gray-100 text-gray-800 rounded-full shadow-sm text-sm font-medium focus:outline-none cursor-pointer">
        <app-close-icon/>
      </button>
    </div>
<!--    <div class="fixed bg-[#545454] rounded-2xl bottom-0 left-0 w-full h-[95px] p-4 pb-[calc(16px+var(&#45;&#45;sat))]">-->
<!--      <div class="flex flex-between sm:justify-center">-->
<!--        <div class="w-full sm:w-[300px] relative">-->
<!--          <input class="bg-ominput-600 rounded-full w-full px-10 pr-12 py-4 text-white-->
<!--    sm:placeholder:text-gray-100 sm:bg-[#707070] appearance-none-->
<!--    focus:ring-offset-0 focus:outline-none focus:ring-1 focus:ring-omgreen-100-->
<!--    outline-omgreen-50" placeholder="Reply to {{currentStory.username}}">-->
<!--          <app-send-icon class="absolute top-3 right-5" color="#fff" [width]="30" [height]="30"></app-send-icon>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</div>
