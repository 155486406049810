<div class="relative bg-slate-100">
  <div #map id="map" class="w-full h-[300px]">
  </div>
  <div class="absolute bottom-2 right-2 flex gap-4">
    <button (click)="cancel()" class="text-[15px] font-Montserrat font-poppins font-semibold sm:text-[12px] bg-white text-[#519935] bw-1 border-[#519935] rounded-xl px-4 py-3 cursor-pointer">
      Cancel
    </button>
    <button (click)="acceptSave()" class="text-[15px] font-Montserrat font-poppins font-semibold sm:text-[12px] text-white bg-[#519935] bw-1 border-[#519935] rounded-xl px-4 py-3 cursor-pointer">
      Confirm
    </button>
  </div>
</div>
