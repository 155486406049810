import {Component, inject, Input} from '@angular/core';
import {UserInfoService} from "../../../../services/user-info/user-info.service";

@Component({
  selector: 'app-card-user-message',
  templateUrl: './card-user-message.component.html',
  styleUrls: ['./card-user-message.component.scss']
})
export class CardUserMessageComponent {
  userInfoService = inject(UserInfoService);
  @Input() set user(value: any) {
    console.log(value)
    if (value !== this._user) {
      this._user = value
      this.getName();
      this.getImage();
    }
  }

  get user(): any {
    return this._user;
  }

  private _user = {}
  @Input() isSelected=false;
  name = '';
  imageProfile = '';
  dateMessage='2024-01-11T22:52:33.540Z';
  lastMessage='Lorem ipsum dolor sit amet consectetur adipiscing, elit elementum montes nisl dignissim nam pretium, odio pulvinar tincidunt faucibus magna. Hendrerit scelerisque phasellus ante commodo netus himenaeos malesuada dapibus ac nam facilisis massa feugiat magna fermentum, sodales sociis dui blandit libero ad suscipit sollicitudin platea nisi nullam turpis sed. Laoreet conubia eleifend arcu eget etiam platea justo sed, duis condimentum magnis lacus vivamus vulputate odio sollicitudin, vel consequat mi dictumst tincidunt auctor elementum.';
  numberUnreadMessages= 0;
  getImage() {
    let img = ''
    if(this.user?.userFrom && !this.isMe(this.user.userFrom.id)){
      img = this.user.userFrom.image_profile
    } else if(this.user?.userTo && !this.isMe(this.user.userTo.id)){
      img = this.user.userTo.image_profile
    }
    this.imageProfile = img || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png';
  }
  isMe(id: string) {
    return this.userInfoService.isMe(id)
  }
  getName(){
    console.log(this.user, !this.isMe(this.user.userFrom.id))
    console.log(this.user, !this.isMe(this.user.userTo.id))
    if(this.user?.userFrom && !this.isMe(this.user.userFrom.id)){
      this.name = this.userInfoService.getNameByUser(this.user.userFrom);
    } else if(this.user?.userTo && !this.isMe(this.user.userTo.id)){
      this.name = this.userInfoService.getNameByUser(this.user.userTo);
    }
  }
  getFormattedTimeOrDate(user:any) {
    if(!user) return '';

    let date = new Date();
    if(user.message?.createDate){
      let lastMessageDate = user.message.createDate;
      date = new Date(lastMessageDate);
    }
    const today = new Date();
    const isToday =
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
    if (isToday) {
      return date.toLocaleString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true});
    } else {
      const options = { day: '2-digit', month: 'short', year: 'numeric' };
      // @ts-ignore
      return date.toLocaleDateString('en-US', options);
    }
  }
  getLastMessage(user: any) {
    if(user.message?.messageContent){
      let lastMessage = user.message.messageContent;
      if(lastMessage.includes('OG-post')) return `Sent a Post`
      if(lastMessage.includes('OG-img')) return `Sent a Photo`

      return lastMessage;
    }
  }

  protected readonly JSON = JSON;
}
