import {Component, HostListener, inject, Input, OnInit} from '@angular/core';
import {FriendsService} from "../../services/friends/friends.service";
import {FollowAndFriendService} from "../../services/follow-and-friend/follow-and-friend.service";
import {SearchAllService} from "../../services/search-all/search-all.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-friends',
  templateUrl: './friends.component.html',
  styleUrls: ['./friends.component.scss']
})
export class FriendsComponent implements OnInit {
  searchAllService = inject(SearchAllService);
  users: any[] = [];
  @Input() userId = ''
  _type = '';
  @Input() set type(value: any) {
    if (value !== this._type) {
      this._type = value;
      this.getUsers(true);
    }
  }

  get type(): any {
    return this._type;
  }
  options = [
    {label: "Friends", value: "friends"},
    {label: "Followings", value: "following"},
    {label: "Followers", value: "follower"},
    {label: "Suggested", value: "suggested"},
    {label: "Request", value: "request"},
  ]
  search = '';
  statusFetch = false;
  statusRecords = false;
  limit = 10;
  offset = 0;

  constructor(private followers: FollowAndFriendService) {
  }

  async ngOnInit() {
    await this.followers.getFollowings(true);
    await this.followers.getFollowers(true);
    // alert(this.userId);
    // if(!this.userId)
    //   await this.getUsers();
  }
  // subscriptionUsers: Subscription | undefined;
  async getUsers(isReset = false) {

    try {
      if (this.statusFetch) return;

      this.statusFetch = true;
      if (isReset) {
        this.statusRecords = false;
        this.limit = 10;
        this.offset = 0;
        this.users = [];
      }
      let filters: any = {
        limit: this.limit,
        offset: this.offset,
      }
      if (this.search) {
        filters.search = this.search;
      }
      if (this.userId) {
        filters.user = this.userId;
      }
      filters[this.type] = 1;

      let users = await this.searchAllService.getUsers(filters).toPromise() as any;
      if (users.length === this.limit) {
        this.offset += this.limit
      } else {
        this.offset += users.length;
      }
      if(users.length === 0){
        this.statusRecords = true;
      }
      this.users = [...this.users, ...users];
      this.statusFetch = false;
    } catch (e) {
      console.error(e)
    }
  }

  @HostListener('window:scroll', ['$event'])
  endScroll(): void {
    if (this.statusFetch) return;
    if (this.statusRecords) return;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    if (scrollTop + clientHeight >= scrollHeight - 100 && (this.offset && this.offset%this.limit === 0)) {
      this.getUsers()
    }
  }
}
