import {Component, EventEmitter, Input, Output} from '@angular/core';
import {OmAlertService} from "../../../om-alert.service";

@Component({
  selector: 'app-dialog-alert',
  templateUrl: './dialog-alert.component.html',
  styleUrls: ['./dialog-alert.component.scss']
})
export class DialogAlertComponent {
  config: any;
  okPress=false;
  constructor(private configService: OmAlertService) { }

  ngOnInit() {
    this.configService.customConfigSubject.subscribe(config => {
      this.config = config;
    });
  }
  onClickBtn(status=true) {
    this.configService.isOKPressedSubject.next(status);
    if (this.config?.closeOnPress) this.configService.closeDialog();
  }
}
