import {Component, inject, Input, OnInit} from '@angular/core';
import {FriendsService} from "../../../../services/friends/friends.service";
import {FollowAndFriendService} from "../../../../services/follow-and-friend/follow-and-friend.service";
import {CatalogsService} from "../../../../services/catalogs/catalogs.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-growers-type',
  templateUrl: './growers-type.component.html',
  styleUrls: ['./growers-type.component.scss']
})
export class GrowersTypeComponent implements OnInit {
  @Input() type: string = 'top';
  @Input() route: string = '/growers-home';
  @Input() title: string = 'Top Growers';
  private followAndFriends = inject(FollowAndFriendService);
  private catalogsService = inject(CatalogsService);
  private router = inject(Router);
  @Input()  classTitle: any[] = [];
  @Input() set filters(value: any) {
    if (value !== this._filters) {
      this._filters = value;
      this.getGrowers(); // Call the method that depends on the filters when a change is detected
    }
  }

  get filters(): any {
    return this._filters;
  }

  private _filters = {}
  users: any[] = [];
  typeUsers = '';
  startCount=4.6;
  constructor() {
  }
  async ngOnInit() {
    let typeUsers: any = await this.catalogsService.getTypesUsers().toPromise()
    this.typeUsers = typeUsers.filter((x: any) => x.name.toLowerCase().includes('grower') || x.name.toLowerCase().includes('influencer')).map((x: any) => x.id).join('|')
    await this.getGrowers();
  }
  async getGrowers() {
    let filters: { best_describes: string, type_product?: string, limit?: number } = {
      best_describes: this.typeUsers,
      limit: 20
    };
    filters.type_product = this.filters['category'];
    this.users = await this.catalogsService.getUsers(filters).toPromise() as any
  }
}
