import {Component, inject, Input} from '@angular/core';
import {GiftService} from "../../../../services/gift/gift.service";

@Component({
  selector: 'app-product-effects',
  templateUrl: './product-effects.component.html',
  styleUrls: ['./product-effects.component.scss']
})
export class ProductEffectsComponent {
  @Input() effects= []
  @Input() mine=false;
}
