<div class="flex items-center gap-[15px] sm:gap-[40px]  flex-wrap sm:flex-nowrap">
  <img  alt="banner" [src]="data.banner||''" class="w-[110px] h-[100px] object-cover rounded-2xl">
  <div class="w-[calc(100%-130px)]">
    <p class="text-[16px] font-Poppins font-medium cursor-pointer"  [routerLink]="'/course'" [queryParams]="{ id: data.sk }">{{ data.name }}</p>
    <div class="flex flex-wrap gap-[15px] items-center">
      <div class="flex items-center gap-1">
        <svg id="ic_schedule" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path id="Bounds" d="M0,0H24V24H0Z" fill="none"/>
          <path id="Combined_Shape" data-name="Combined Shape" d="M0,10A10,10,0,1,1,9.99,20,10.006,10.006,0,0,1,0,10Zm2,0a8,8,0,1,0,8-8A8.01,8.01,0,0,0,2,10Zm7,1V5h1.5v5.25l4.5,2.67L14.25,14.15Z" transform="translate(1.52 2)" fill="#84818a"/>
        </svg>
        <span class="text-[14px] font-Poppins">{{ totalTime }}</span>
      </div>
      <div class="flex items-center gap-1">
        <svg id="ic_stars" xmlns="http://www.w3.org/2000/svg" width="24.783" height="24" viewBox="0 0 24.783 24">
          <path id="Bounds" d="M0,0H24.783V24H0Z" fill="none"/>
          <path id="Shape" d="M10.49,20A10.265,10.265,0,0,1,0,10,10.265,10.265,0,0,1,10.49,0,10.276,10.276,0,0,1,21,10,10.276,10.276,0,0,1,10.49,20Zm.01-6.55h0L14.942,16l-1.176-4.82,3.916-3.23L12.516,7.53,10.5,3,8.484,7.54,3.318,7.96l3.917,3.23L6.058,16,10.5,13.45Z" transform="translate(2.462 2)" fill="#84818a"/>
        </svg>
        <span class="text-[14px] font-Poppins">{{ data.skill_level }}</span>
      </div>
      <div class="flex items-center gap-1">
        <svg id="ic_person" xmlns="http://www.w3.org/2000/svg" width="24.783" height="24" viewBox="0 0 24.783 24">
          <path id="Bounds" d="M0,0H24.783V24H0Z" fill="none"/>
          <path id="Shape" d="M17,16H0V14c0-.82.553-2,3.186-3A16.643,16.643,0,0,1,8.5,10a16.643,16.643,0,0,1,5.314,1C16.447,12,17,13.181,17,14v2ZM8.5,8A4.136,4.136,0,0,1,4.25,4,4.135,4.135,0,0,1,8.5,0a4.135,4.135,0,0,1,4.25,4A4.135,4.135,0,0,1,8.5,8Z" transform="translate(3.666 4)" fill="#84818a"/>
        </svg>
        <span class="text-[14px] font-Poppins">by {{ data.user.name }}</span>
      </div>
    </div>
  </div>
  <div class="px-[20px] sm:px-0">
    <p>Progress</p>
    <div class="flex items-center gap-2">
      <div class="bg-[#ECEAEE] rounded-3xl w-[calc(100vw-100px)] sm:w-[200px]">
        <div class="bg-[#87CB3C] h-[5px] rounded-3xl"
        [style.width]="progress+'%'"
        ></div>
      </div>
      <span class="text-[14px] font-Poppins">{{progress}}%</span>
    </div>
  </div>
</div>
