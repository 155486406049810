<app-sidebar-layout title="Course"  routeHeader="/coaching"  [useHeaderEdit]="true">
  <div class="sm:p-4" *ngIf="data.sk">
    <app-review (sendData)="updateCourse($event)" ></app-review>
    <div class="w-full pt-2 sm:p-4 sm:border border-[#70707031] bg-white sm:rounded-xl">
      <div class="static sm:flex">
        <div class="hidden sm:block w-full sm:w-[324px] px-2 sm:pr-4 border-r-2 z-10"
             [class]="{
        'absolute':openMenu,
        'bg-white':openMenu,
        '!block':openMenu
        }"
        >
          <div class="my-[15px] sm:mt-0 sm:mb-[30px] relative">
            <p class="px-[15px] sm:px-0 text-center sm:text-left text-[18px]  sm:text-[20px] font-semibold font-Poppins">{{title}}</p>
            <span class="material-symbols-outlined cursor-pointer absolute right-0 top-0 sm:hidden" (click)="openMenu=false">
              close
            </span>
          </div>
          <div class="h-[calc(100vh-230px)] ocultarScroll">
            <div *ngFor="let chapter of chapters; let indexChapter = index">
              <div class="w-full flex justify-between items-center h-[69px] border border-[#EAEAEA] rounded-lg pl-[10px]">
                <div>
                  <p class="text-[16px] font-Poppins font-semibold ">{{chapter.title}}</p>
                </div>
                <div>
              <span class="material-symbols-outlined cursor-pointer" (click)="chapter.is_open=!chapter.is_open">
                {{chapter.is_open?'expand_less':'keyboard_arrow_down'}}
              </span>
                </div>
              </div>

              <div *ngIf="chapter.is_open" class="transition-all duration-500">
                <div *ngFor="let itemClass of chapter.classes; let indexClass = index" class="h-[69px] flex items-center justify-between px-[10px] cursor-pointer"
                     (click)="openChapter(indexChapter,indexClass)">
                  <div class="w-[90%]">
                    <span class="group relative">
                      <div class="absolute bottom-[calc(100%+0.5rem)] left-[50%] -translate-x-[50%] hidden group-hover:block w-full">
                        <div class="bottom-full right-0 rounded bg-black px-4 py-1 text-xs text-white ">
                          {{itemClass.title}}
                          <svg class="absolute left-0 top-full h-2 w-full text-black" x="0px" y="0px" viewBox="0 0 255 255" xml:space="preserve"><polygon class="fill-current" points="0,0 127.5,127.5 255,0" /></svg>
                        </div>
                      </div>
                      <p class="text-[16px] font-Poppins truncate">{{itemClass.title}}</p>
                    </span>

                    <p class="text-[12px] font-Poppins font-light flex items-center gap-2">
                      <svg id="vuesax_linear_clock" data-name="vuesax/linear/clock" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                        <rect id="vuesax_linear_clock-2" data-name="vuesax/linear/clock" width="14" height="14" fill="none"/>
                        <g id="vuesax_linear_clock-3" data-name="vuesax/linear/clock">
                          <g id="clock">
                            <path id="Vector" d="M11.667,5.833A5.833,5.833,0,1,1,5.833,0,5.835,5.835,0,0,1,11.667,5.833Z" transform="translate(1.167 1.167)" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                            <path id="Vector-2" data-name="Vector" d="M2.38,4.474.572,3.4a1.292,1.292,0,0,1-.572-1V0" transform="translate(6.784 4.381)" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                            <path id="Vector-3" data-name="Vector" d="M0,0H14V14H0Z" fill="none" opacity="0"/>
                          </g>
                        </g>
                      </svg>
                      {{itemClass.time}}
                    </p>
                  </div>
                  <div>
                    <svg *ngIf="isActiveClass(indexChapter,indexClass)" id="vuesax_bold_video-circle" data-name="vuesax/bold/video-circle" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <rect id="vuesax_bold_video-circle-2" data-name="vuesax/bold/video-circle" width="24" height="24" fill="none"/>
                      <g id="vuesax_bold_video-circle-3" data-name="vuesax/bold/video-circle">
                        <g id="video-circle">
                          <path id="Vector" [class]="{'!fill-[#27c153]':activeChapter===indexChapter && activeClass===indexClass}" d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM12.66,8.27l-1.28-.74L10.1,6.79c-1.65-.95-3-.18-3,1.73v2.96c0,1.9,1.35,2.68,3,1.73l1.28-.74,1.28-.74c1.65-.95,1.65-2.51,0-3.46Z" transform="translate(2 2)" fill="#BBBBBB"/>
                          <path id="Vector-2" data-name="Vector" d="M0,0H24V24H0Z" fill="none" opacity="0"/>
                        </g>
                      </g>
                    </svg>
                    <svg *ngIf="!isActiveClass(indexChapter,indexClass)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <g id="vuesax_bold_lock" data-name="vuesax/bold/lock" opacity="0.2">
                        <rect id="vuesax_bold_lock-2" data-name="vuesax/bold/lock" width="24" height="24" fill="none" opacity="0.2"/>
                        <g id="vuesax_bold_lock-3" data-name="vuesax/bold/lock">
                          <path id="Vector" d="M3.26,1.63A1.63,1.63,0,1,1,1.63,0,1.63,1.63,0,0,1,3.26,1.63Z" transform="translate(10.37 14.09)" fill="#292d32"/>
                          <path id="Vector-2" data-name="Vector" d="M20,12.79v1.86c0,4.1-1.25,5.35-5.35,5.35H5.35C1.25,20,0,18.75,0,14.65V12.79C0,9.3.92,7.88,3.72,7.53V6.28C3.72,3.58,4.37,0,10,0s6.28,3.58,6.28,6.28V7.53C19.08,7.88,20,9.3,20,12.79Zm-6.98.93A3.02,3.02,0,1,0,10,16.74a3.03,3.03,0,0,0,3.02-3.02ZM5.35,7.45h9.53V6.28c0-2.93-.83-4.88-4.88-4.88S5.12,3.35,5.12,6.28V7.44h.23Z" transform="translate(2 2)" fill="#292d32"/>
                          <path id="Vector-3" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(24 24) rotate(180)" fill="none" opacity="0"/>
                        </g>
                      </g>
                    </svg>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full sm:w-[calc(100%-324px)] pl-0 sm:pr-[25px] h-[calc(100vh-175px)] ocultarScroll">
          <div class="flex items-center justify-between my-[15px] px-[10px] sm:px-[25px]">
            <span class=" sm:hidden material-symbols-outlined text-[30px] sm:text-[30px] text-[#707070] cursor-pointer" routerLink="/coaching">arrow_back</span>

            <p class="text-[18px] font-Poppins font-medium">{{chapters[activeChapter].classes[activeClass].title}}</p>
            <div class="flex gap-2 items-center">
              <span class="material-symbols-outlined sm:hidden cursor-pointer text-[30px]" (click)="openMenu=true">
                list
              </span>
              <p *ngIf="data.purchased" class="star text-center text-[30px] cursor-pointer"  [class]="{'active':(coursePurchased['reviewId']||'')!=''}" (click)="review()"></p>
              <p (click)="save()" class="hidden text-[14px] font-Poppins text-[#8D8D8D] items-center gap-2"
                 [class]="{
               'text-green2-native':data.saved
               }"
              >
                <svg class="icon w-[25px] h-[25px] sm:w-[20px] sm:h-[20px]" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                     [class]="{
                    'active':data.saved
                   }"
                >
                  <g id="vuesax_linear_archive-minus" data-name="vuesax/linear/archive-minus" opacity="0.5">
                    <rect id="vuesax_linear_archive-minus-2" data-name="vuesax/linear/archive-minus" width="20" height="20" fill="none" opacity="0.5"/>
                    <g id="vuesax_linear_archive-minus-3" data-name="vuesax/linear/archive-minus">
                      <g id="archive-minus">
                        <path id="Vector" d="M4.167,0H0" transform="translate(7.917 8.875)" fill="none" stroke="#292d32" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        <path id="Vector-2" data-name="Vector" d="M14.475,3.217V14.958c0,1.508-1.075,2.142-2.392,1.408L8.017,14.108a1.765,1.765,0,0,0-1.558,0L2.392,16.367C1.075,17.092,0,16.458,0,14.958V3.217A3.224,3.224,0,0,1,3.217,0H11.25a3.237,3.237,0,0,1,3.225,3.217Z" transform="translate(2.767 1.667)" fill="none" stroke="#292d32" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        <path id="Vector-3" data-name="Vector" d="M0,0H20V20H0Z" fill="none" opacity="0"/>
                      </g>
                    </g>
                  </g>
                </svg>
                <span class="hidden sm:block">{{data.saved?'Saved':'Save for late'}}</span>

              </p>
            </div>
          </div>
          <div class="video sm:px-[25px]">
            <video *ngIf="load" id="videoView" class="w-full sm:rounded-xl" [src]="chapters[activeChapter].classes[activeClass].url" controls>
            </video>
          </div>
          <div class="px-[25px]">
            <div class="flex gap-4 container-avatar mt-[15px]">
              <img alt="" class="w-[62px] h-[62px] rounded-full object-cover" [src]="user.image_profile || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'">
              <div class="flex flex-col justify-center avatar-info">
                <p class="text-[18px] font-Poppins font-medium leading-[1]">
                  {{ user?.name }} {{user?.lastname }}
                </p>
                <p *ngIf="user?.is_owner_verified" class="font-Poppins font-light text-[14px] mt-[5px]">Verified Grower</p>
              </div>
            </div>
            <div [innerHTML]="description" class="mt-[15px] text-[16px] text-[#00000091] font-Poppins mb-[35px]"></div>
            <div *ngIf="coursePurchased" class="flex justify-between w-[90%] ml-[5%]"
            [class]="{
            '!justify-end':coursePurchased?.history[activeChapter][activeClass].isComplete
            }"
            >
              <p *ngIf="!coursePurchased?.history[activeChapter][activeClass].isComplete" (click)="markComplete()" class="w-[152px] h-[50px] flex items-center justify-center text-[13px] font-medium font-Montserrat rounded-lg bg-[#519935] text-white cursor-pointer">
                Mark as completed
              </p>
              <p *ngIf="!isLastVideo()" (click)="nextVideo()" class="w-[152px] h-[50px] flex gap-2 items-center justify-center text-[13px] font-medium font-Montserrat rounded-lg border border-[#519935] text-[#519935] cursor-pointer">
                Next video
                <span class="material-symbols-outlined">arrow_forward</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</app-sidebar-layout>
