import {HostListener, inject, Injectable} from '@angular/core';
import {Location} from "@angular/common";
import * as rsa from 'jsrsasign';

@Injectable({
  providedIn: 'root'
})
export class DeviceInfoService {
  menuActive = false;
  location = inject(Location)
  isNavigationBarVisible: boolean = true;

  constructor() {
  }

  detectMobile(): boolean {
    const userAgent = window.navigator.userAgent.toLowerCase();
    // Comprueba si la cadena de agente de usuario contiene palabras clave comunes de dispositivos móviles
    return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
  }

  toggleMenu(val?: boolean) {
    typeof val !== 'undefined' ? this.menuActive = val : this.menuActive = !this.menuActive;
  }

  scrollTop() {
    window.scrollTo(0, 1);
  }

  getStateMenu() {
    return this.menuActive;
  }

  dataURLtoFile(dataurl: string, filename: string) {
    let arr = dataurl.split(',')
    let arrForMime = arr[0].match(/(.*?);/);
    if (!arrForMime?.length) return;

    let mime = arrForMime[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime.replace('data:', '')});
  }

  convertFileToBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  async getFileToBase64(file: File): Promise<any> {
    return await this.convertFileToBase64(file);
  }

  extractContentTypeFromBase64(base64Data: string): string {
    const match = base64Data.match(/^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    return match ? match[1] : '';
  }

  goBack() {
    this.location.back();
  }

  generateSignature(sdkKey: string, sdkSecret: string, sessionName: string, role: number, sessionKey: string, userIdentity: string) {
    const iat = Math.round(new Date().getTime() / 1000) - 30
    const exp = iat + 60 * 60 * 2
    const oHeader = {alg: 'HS256', typ: 'JWT'}

    const oPayload = {
      app_key: sdkKey,
      tpc: sessionName,
      role_type: role,
      // session_key: sessionKey,
      // user_identity: userIdentity,
      version: 1,
      iat: iat,
      exp: exp
    }

    const sHeader = JSON.stringify(oHeader)
    const sPayload = JSON.stringify(oPayload)
    const sdkJWT = rsa.KJUR.jws.JWS.sign('HS256', sHeader, sPayload, sdkSecret)
    return sdkJWT
  }

  getTypeFile(nombreArchivo: string) {
    // @ts-ignore
    const extension = nombreArchivo.split('.').pop().toLowerCase();
    if (['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(extension)) {
      return 'image';
    } else if (['mp4', 'avi', 'mkv', 'mov'].includes(extension)) {
      return 'video';
    } else {
      return '';
    }
  }

  isAndroid(): boolean {
    return /android/i.test(navigator.userAgent);
  }

  isiOS(): boolean {
    return /iphone|ipad|ipod/i.test(navigator.userAgent);
  }

  isPWA() {
    return window.matchMedia('(display-mode: standalone)').matches
      || ('standalone' in window.navigator && window.navigator.standalone);
  }

  getOS(): string {
    if (this.isAndroid()) {
      return 'Android';
    } else if (this.isiOS()) {
      return 'iOS';
    } else {
      return 'Other'; // Otros sistemas operativos
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    this.detectNavigationBarVisibility();
  }

  ngOnInit() {
    this.detectNavigationBarVisibility(); // Detectar inicialmente al cargar la página
  }

  detectNavigationBarVisibility() {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.clientHeight;
    this.isNavigationBarVisible = windowHeight > documentHeight;
  }
  fullscreenElement(){
    if (!document.fullscreenElement)
      document.documentElement.requestFullscreen().catch(err => {
        console.error(`Error al intentar activar el modo de pantalla completa: ${err.message}`);
      });
  }
  exitFullscreenElement(){
    if (document.fullscreenElement) document.exitFullscreen();
  }
}
