<div class="om-container bg-white sm:bg-[#FAFAFA] mt-0">
  <app-navbar (toggleMenu)="toggleMenu()" class="fixed z-30 w-full" [routeHeader]="routeHeader" [useHeaderEdit]="useHeaderEdit">
    <navbar-title class="font-Poppins font-semibold text-[22px]">
      {{title}}
    </navbar-title>
  </app-navbar>
  <div class="flex sm:bg-[#FAFAFA]">
    <div class="hidden sm:block fixed h-[calc(100vh-(var(--sat)))] bg-white top-[calc(var(--sat))] sm:top-[101px] sm:h-[calc(100%-101px)] overflow-auto left-0"
         [class]="{
         '!block': deviceInfo.menuActive,
         '!fixed': deviceInfo.menuActive,
         '!left-0': deviceInfo.menuActive,
         '!w-[83%]': deviceInfo.menuActive,
         '!z-40': deviceInfo.menuActive,
         }"
    >
      <app-settings-options *ngIf="!deviceInfo.detectMobile()"></app-settings-options>
      <app-side-bar  (toggleMenu)="toggleMenu()" *ngIf="deviceInfo.detectMobile()"></app-side-bar>
    </div>
    <div class="w-full bg-white sm:bg-[#FAFAFA] pt-[calc(60px+var(--sat))] sm:w-[calc(100%-236px)] sm:ml-[230px] sm:mrdsas-[386px] flex justify-start overflow-auto
          sm:pt-[101px]">
      <ng-content></ng-content>
    </div>
  </div>
</div>
