<div>
  <div class="justify-center sm:gap-8" [class]="{
    'hidden':viewMobil,
    'sm:flex':isEnableElement,
    'px-2':!isEnableElement,
  }">
    <div class="sm:w-5/12">
      <p *ngIf="isEnableElement"
         class="text-[19px] font-Montserrat font-semibold sm:text-[#000000] sm:text-[22px] sm:font-Montserrat ">OG
        Member</p>
      <div *ngIf="isEnableElement"
           class="bg-white sm:bg-[#ffffff00] flow-root rounded-3xl shadow-[rgba(0,0,0,0.1)_0px_4px_12px] p-4 px-6 mt-[15px] sm:mt-0 sm:p-0 sm:bg-none sm:rounded-none sm:shadow-none">
        <app-personal-detail-send [(ngModel)]="data.user"
                                  [disabledInput]="viewEditFormDelivery"
                                  [enableInfoAddress]="enableInfoAddress"
                                  [enableRequiredInput]="enableRequiredInput">

        </app-personal-detail-send>
        <app-delivery-address *ngIf="viewMobil" [(ngModel)]="data.address" [title]="'Delivery Address'"
                              [classTitle]="'sm:mx-0 sm:text-[22px] font-Montserrat font-semibold'"
                              [viewEditAddress]="viewEditAddress" [viewEditForm]="enableEditFormDelivery"
                              [useAddressVerified]="data.address.isVerified"
                              (openCloseEdit)="openCloseEdit($event)">

        </app-delivery-address>
        <p class="font-Poppins text-[10px] mt-2">Privacy Notice: The member providing the gift will have access to this
          address, for privacy purposes we recommend using a <a class="font-Poppins text-[10px] text-green2-native"
                                                                href="https://www.canadapost-postescanada.ca/cpc/en/support/kb/receiving/setting-preferences/how-to-use-your-flexdelivery-address">Canada
            Post Flex Delivery Address</a>
        </p>


        <button *ngIf="viewEditFormDelivery"
                class="flex sm:hidden items-center justify-center w-full h-[45px] text-[19px] bg-[#81CB52] text-white mt-[25px] rounded-2xl"
                (click)="continue()">
          Continue
        </button>

      </div>
      <p *ngIf="!viewEditFormDelivery && isPayable"
         class="hidden sm:block sm:text-[22px] font-Montserrat font-semibold sm:mt-[26px]">Payment Method</p>
      <div *ngIf="!viewEditFormDelivery">
        <form id="payment-form" *ngIf="isPayable">
          <div id="payment-element">
            <!--Stripe.js injects the Payment Element-->
          </div>

          <div id="payment-message" class="text-red-600 font-semibold mt-2">
            {{ paymentmessage }}
          </div>
        </form>
        <button *ngIf=" !isEnableElement"
                class="text-white bg-[#87D255] font-Montserrat font-bold text-[17px] rounded-2xl h-[50px] flex items-center justify-center w-[calc(100%-36px)] mx-[18px]"
                (click)="checkout()">
          {{ isPayable ? 'Checkout' : 'Continue' }}
        </button>
      </div>

    </div>
    <div
      class="hidden sm:shadow-[0px_3px_6px_#00000029] sm:rounded-[43px] sm:py-[35px] sm:px-[29px] sm:bg-white sm:flex sm:flex-col sm:justify-between sm:w-7/12">
      <div>
        <p class="sm:text-[#000000] sm:text-[24px] font-Montserrat font-medium">Order Summary</p>
        <div class="list-products sm:mt-[47px] mx-4 sm:mx-0">
          <div class="flex items-center justify-between mb-1" *ngFor="let item of data.gifts">
            <div class="flex gap-2 items-center justify-center">
              <img class="w-[56px] h-[56px] rounded-2xl  object-contain" [src]="item.images[0]">
              <div>
                <p class="sm:text-[18px] sm:text-[#000000] font-Montserrat">{{ item.name }}</p>
                <p class="sm:text-[14px] sm:text-[#AAAAAA] font-Montserrat">{{ item.genetics }}</p>
              </div>
            </div>
            <div *ngIf="item.price>0" class="flex gap-2 items-center justify-center">
              <p class="sm:text-[17px] sm:text[#AAAAAA] font-Montserrat">X1</p>
              <p class="sm:text-[16px] sm:text[#000000] font-Montserrat font-medium">
                ${{ item.price }} {{ currency }}</p>
            </div>
          </div>
          <hr class="text-[#DDDDDD] border border-[#DDDDDD] sm:mt-[16px]">
        </div>
        <div class="sm:mt-[58px]" *ngIf="isPayable">
          <p
            class="sm:text-[16px] sm:text-[#000000] font-Montserrat font-medium">{{ getSubTotal() === 0 ? 'Apply Shipping Promocode' : 'Apply Promocode' }}</p>
          <div class="relative sm:mt-[24px]">
            <input placeholder="Promotion or Discount code"
                   class="px-4 border border-[rgba(15,13,35,0.2)] rounded-[12px] w-full h-[60px]  pr-[150px]"
                   [(ngModel)]="promotionCode">
            <div class="absolute right-2 top-[8px]">
              <button
                class="sm: text-[16px] sm:w-[133px] sm:h-[44px] flex items-center justify-center bg-[#519935] text-white rounded-2xl">
                Apply Code
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="sm:mt-0">
        <app-cost-list [labelSubtotal]="'Sub total'"
                       [subTotal]="getSubTotal()"
                       [shipping]="shipping"
                       [total]="getTotal()"
                       [currency]="currency"
                       [classFont]="'font-Montserrat'"
                       [isSend]="router.url.split('?')[0].split('#')[0].includes('/gift-send')"></app-cost-list>
      </div>
      <button *ngIf="!viewEditFormDelivery"
              class="flex items-center justify-center sm:w-full sm:w-h-[80px] sm:text-[37px] bg-[#81CB52] text-white sm:mt-[42px] rounded-2xl"
              (click)="checkout(isPayable)">
        {{ isPayable ? 'Checkout' : 'Send' }}
      </button>
      <button *ngIf="viewEditFormDelivery"
              class="flex items-center justify-center sm:w-full sm:w-h-[80px] sm:text-[37px] bg-[#81CB52] text-white sm:mt-[42px] rounded-2xl"
              (click)="continue()">
        Continue
      </button>
    </div>
  </div>


  <div *ngIf="viewMobil && viewEditFormDelivery" class="sm:hidden">

    <div class="flex items-center gap-2 sm:mx-auto sticky top-[60px] z-20 bg-white sm:bg-[#ffffff00]">
      <span class=" material-symbols-outlined text-4xl my-2 cursor-pointer sm:hidden" (click)="back()">arrow_back</span>
      <p class="sm:hidden text-[19px] font-Montserrat font-bold sm:text-[#000000] sm:text-[22px] sm:font-Montserrat ">OG
        Member</p>

    </div>
    <div
      class="bg-white sm:bg-[#ffffff00] flow-root rounded-3xl shadow-[rgba(0,0,0,0.1)_0px_4px_12px] p-4 px-6 mt-[15px] sm:mt-0 sm:p-0 sm:bg-none sm:rounded-none sm:shadow-none">
      <app-personal-detail-send [(ngModel)]="data.user"
                                [disabledInput]="viewEditFormDelivery"
                                [enableInfoAddress]="enableInfoAddress"
                                [enableRequiredInput]="enableRequiredInput">

      </app-personal-detail-send>
      <app-delivery-address *ngIf="viewMobil" [(ngModel)]="data.address" [title]="'Delivery Address'"
                            [classTitle]="'sm:mx-0 sm:text-[22px] font-Montserrat font-semibold'"
                            [viewEditAddress]="viewEditAddress" [viewEditForm]="enableEditFormDelivery"
                            [useAddressVerified]="data.address.isVerified"
                            (openCloseEdit)="openCloseEdit($event)">

      </app-delivery-address>
      <p class="font-Poppins text-[10px] mt-2">Privacy Notice: The member providing the gift will have access to this
        address, for privacy purposes we recommend using a <a class="font-Poppins text-[10px] text-green2-native"
                                                              href="https://www.canadapost-postescanada.ca/cpc/en/support/kb/receiving/setting-preferences/how-to-use-your-flexdelivery-address">Canada
          Post Flex Delivery Address</a>
      </p>
      <button *ngIf="viewEditFormDelivery"
              class="flex sm:hidden items-center justify-center w-full h-[45px] text-[19px] bg-[#81CB52] text-white mt-[25px] rounded-2xl"
              (click)="continue(false)">
        Continue
      </button>

    </div>
  </div>
  <div *ngIf="viewMobil && !viewEditFormDelivery" class="sm:hidden">
    <div class="flex items-center gap-2 sm:mx-auto mb-[15px] sticky top-[60px] z-20 bg-white sm:bg-[#ffffff00]">
      <span class=" material-symbols-outlined text-4xl my-2 cursor-pointer sm:hidden" (click)="back()">arrow_back</span>
      <p class="text-[19px] font-Montserrat font-bold  sm:hidden">Order details</p>
    </div>
    <div *ngIf=" data.gifts.length>0" class="sm:hidden">
      <app-card-gift class="gift-send block w-full sm:w-[95%] sm:w-[48%] mb-[24px]" *ngFor="let item of data.gifts"
                     [data2]="item" [quantity]="item.quantity" [enableActions]="false" [enableThcCdb]="false"
                     [enableType]="false"></app-card-gift>
    </div>
    <div
      class="px-[20px] py-[17px] mt-19px sm:px-[34px] sm:py-[57px] mb-[24px] rounded-3xl bg-white  shadow-[rgba(0,0,0,0.1)_0px_4px_12px]">
      <app-cost-list [subTotal]="getSubTotal()" [shipping]="shipping" [total]="getTotal()"
                     [classFont]="'font-Montserrat'"
                     [isSend]="router.url.split('?')[0].split('#')[0].includes('/gift-send')"></app-cost-list>
    </div>


    <div class="bg-white flow-root rounded-3xl shadow-[rgba(0,0,0,0.1)_0px_4px_12px]">
      <div class="mb-[35px]">
        <div class="flex items-center justify-between px-3">
          <p class="text-[#262628] text-[15px] font-Poppins mx-[11px] mt-[16px] mb-[17px]">PAYMENT METHOD</p>
        </div>
        <form id="payment-form2" *ngIf="isPayable">
          <div id="payment-element2">
            <!--Stripe.js injects the Payment Element-->
          </div>

          <div id="payment-message2" class="text-red-600 font-semibold mt-2">
            {{ paymentmessage }}
          </div>
        </form>
        <button
          class="text-white bg-[#87D255] font-Montserrat font-bold text-[17px] rounded-2xl h-[50px] flex items-center justify-center w-[calc(100%-36px)] mx-[18px]"
          (click)="checkout()">
          {{ isPayable ? 'Checkout' : 'Continue' }}
        </button>
      </div>
    </div>
  </div>

</div>
