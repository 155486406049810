import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/components/login/login.component';
import { HomeComponent as HomeComponentAuth  } from "./components/home/home.component";
import { isAuthenticatedGuard } from './auth/guards/is-authenticated.guard';
import { isNotAuthenticatedGuard } from './auth/guards/is-not-authenticated.guard';
import {HomeComponent} from "./home/home.component";
import {RegisterHomeComponent} from "./auth/components/register/register-home/register-home.component";
import {TermsAndConditionsComponent} from "./auth/components/terms-and-conditions/terms-and-conditions.component";
import {
  ResetPasswordHomeComponent
} from "./auth/components/recovery-password/reset-password-home/reset-password-home.component";
import {ProfileComponent} from "./components/profile/profile.component";
import {SharablesComponent} from "./components/profile/parts/sharables/sharables.component";
import {NewProductComponent} from "./components/sharables/new-product/new-product.component";
import {GiftSendHomeComponent} from "./components/gift/gift-send-home/gift-send-home.component";
import {ClaimGiftComponent} from "./components/gift/claim-gift/claim-gift.component";
import {GiftCenterComponent} from "./components/gift/gift-center/gift-center.component";
import {ProfileFormComponent} from "./components/profile/settings/profile-form/profile-form.component";
import {SignInMethodComponent} from "./components/profile/settings/sign-in-method/sign-in-method.component";
import {OverviewComponent} from "./components/profile/settings/overview/overview.component";
import {NotificationsComponent} from "./components/profile/settings/notifications/notifications.component";
import {
  DesactivateAccountComponent
} from "./components/profile/settings/desactivate-account/desactivate-account.component";
import {BillingComponent} from "./components/profile/settings/billing/billing.component";
import {SupportComponent} from "./components/profile/settings/support/support.component";
import {FriendsForHomeComponent} from "./components/friends/friends-for-home/friends-for-home.component";
import {HomeMarketPlaceComponent} from "./components/market-place/home-market-place/home-market-place.component";
import {MarketSearchComponent} from "./components/market-place/market-search/market-search.component";
import {CategoryHomeComponent} from "./components/market-place/category-home/category-home.component";
import { GrowersSearchComponent } from './components/market-place/growers-search/growers-search.component';
import { GrowersHomeComponent } from './components/market-place/growers-home/growers-home.component';
import { GiftsSearchComponent } from './components/market-place/gifts-search/gifts-search.component';
import { EffectHomeComponent } from './components/market-place/effect-home/effect-home.component';
import {HomeMessagesComponent} from "./components/messages/home-messages/home-messages.component";
import {CoachingHomeComponent} from "./components/coaching/coaching-home/coaching-home.component";
import {NewCourseComponent} from "./components/coaching/new-course/new-course.component";
import {CoursesSearchComponent} from "./components/coaching/courses-search/courses-search.component";
import {CoachesComponent} from "./components/coaching/coaches/coaches.component";
import {CourseComponent} from "./components/coaching/course/course.component";
import {CourseDetailsComponent} from "./components/coaching/course-details/course-details.component";
import {HomeCommunitiesComponent} from "./components/communities/home-communities/home-communities.component";
import {NewStoryComponent} from "./components/stories/new-story/new-story.component";
import {CommunityComponent} from "./components/communities/community/community.component";
import {NewCommunityComponent} from "./components/communities/new-community/new-community.component";
import {SearchAllComponent} from "./components/searchs/search-all/search-all.component";
import {FounderRegisterComponent} from "./founders/founder-register/founder-register.component";

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponentAuth,
    canActivate:[isAuthenticatedGuard],
  },
  {
    path: 'messages',
    component: HomeMessagesComponent,
    canActivate:[isAuthenticatedGuard],
  },
  {
    path: 'friends',
    component: FriendsForHomeComponent,
    canActivate:[isAuthenticatedGuard],
  },
  {
    path: '',
    component: HomeComponent,
    canActivate:[isNotAuthenticatedGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate:[isNotAuthenticatedGuard]
  },
  {
    path: 'register',
    component: RegisterHomeComponent,
    canActivate:[isNotAuthenticatedGuard]
  },
  {
    path: 'strain-finder',
    component: HomeMarketPlaceComponent,
    canActivate:[isAuthenticatedGuard]
  },
  {
    path: 'add-story',
    component: NewStoryComponent,
    canActivate:[isAuthenticatedGuard]
  },
  {
    path: 'communities',
    component: HomeCommunitiesComponent,
    canActivate:[isAuthenticatedGuard]
  },
  {
    path: 'community',
    component: CommunityComponent,
    canActivate:[isAuthenticatedGuard]
  },
  {
    path: 'add-community',
    component: NewCommunityComponent,
    canActivate:[isAuthenticatedGuard]
  },
  {
    path: 'search-og',
    component: SearchAllComponent,
    canActivate:[isAuthenticatedGuard]
  },
  {
    path: 'coaching',
    component: CoachingHomeComponent,
    canActivate:[isAuthenticatedGuard]
  },
  {
    path: 'course',
    component: CourseComponent,
    canActivate:[isAuthenticatedGuard]
  },
  {
    path: 'course/details',
    component: CourseDetailsComponent,
    canActivate:[isAuthenticatedGuard]
  },
  {
    path: 'coaching-all',
    component: CoursesSearchComponent,
    canActivate:[isAuthenticatedGuard]
  },
  {
    path: 'mentors',
    component: CoachesComponent,
    canActivate:[isAuthenticatedGuard]
  },
  {
    path: 'categories-effects',
    component: CategoryHomeComponent,
    canActivate:[isAuthenticatedGuard]
  },
  {
    path: 'market-search',
    component: MarketSearchComponent,
    canActivate:[isAuthenticatedGuard]
  },
  {
    path: 'gifts-search',
    component: GiftsSearchComponent,
    canActivate:[isAuthenticatedGuard]
  },
  {
    path: 'growers-search',
    component: GrowersSearchComponent,
    canActivate:[isAuthenticatedGuard]
  },
  {
    path: 'growers-home',
    component: GrowersHomeComponent,
    canActivate:[isAuthenticatedGuard]
  },
  {
    path: 'forgot-password',
    component: ResetPasswordHomeComponent,
    canActivate:[isNotAuthenticatedGuard]
  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent,
    canActivate:[isNotAuthenticatedGuard]
  },
  {
    path: 'terms-and-conditions-app',
    component: TermsAndConditionsComponent,
    canActivate:[isAuthenticatedGuard]
  },
  {
    path: 'founders-club',
    component: FounderRegisterComponent,
    canActivate:[isNotAuthenticatedGuard]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate:[isAuthenticatedGuard],
  },
  {
    path: 'profile/:username',
    component: ProfileComponent,
    canActivate:[isAuthenticatedGuard],
  },
  {
    path: 'gift-center',
    component: GiftCenterComponent,
    canActivate:[isAuthenticatedGuard],
  },
  {
    path: 'sharables',
    component: SharablesComponent,
    canActivate:[isAuthenticatedGuard],
  },
  {
    path: 'add-product',
    component: NewProductComponent,
    canActivate:[isAuthenticatedGuard],
  },
  {
    path: 'product/:id',
    component: NewProductComponent,
    canActivate:[isAuthenticatedGuard],
  },
  {
    path: 'add-course',
    component: NewCourseComponent,
    canActivate:[isAuthenticatedGuard],
  },
  {
    path: 'gift-send',
    component: GiftSendHomeComponent,
    canActivate:[isAuthenticatedGuard],
  },
  {
    path: 'claim-gift',
    component: ClaimGiftComponent,
    canActivate:[isAuthenticatedGuard],
  },
  {
    path: 'settings/profile',
    component: ProfileFormComponent,
    canActivate:[isAuthenticatedGuard],
  },
  {
    path: 'settings/overview',
    component: OverviewComponent,
    canActivate:[isAuthenticatedGuard],
  },
  {
    path: 'settings/sign-in-method',
    component: SignInMethodComponent,
    canActivate:[isAuthenticatedGuard],
  },
  {
    path: 'settings/notifications',
    component: NotificationsComponent,
    canActivate:[isAuthenticatedGuard],
  },
  {
    path: 'settings/support',
    component: SupportComponent,
    canActivate:[isAuthenticatedGuard],
  },
  {
    path: 'settings/desactivate-account',
    component: DesactivateAccountComponent,
    canActivate:[isAuthenticatedGuard],
  },
  {
    path: 'settings/billing',
    component: BillingComponent,
    canActivate:[isAuthenticatedGuard],
  },
  {
    path: 'effect',
    component: EffectHomeComponent,
    canActivate:[isAuthenticatedGuard],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    urlUpdateStrategy: "deferred"
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
