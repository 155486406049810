<div class="bg-white p-4 py-6 shadow-[0px_3px_6px_#00000029] rounded-xl">
  <div class="flex gap-2 sm:gap-4 items-center">
    <h1 class="text-[25px] font-semibold font-Montserrat">Effects</h1>
    <p class="flex items-center text-green2-native text-[20px] sm:text-[15px] font-medium font-Montserrat cursor-pointer" routerLink="/categories-effects"  fragment="title-effects">
      <span class="hidden sm:block cursor-pointer">View All</span>
      <span class="material-symbols-outlined flex items-center justify-center border-[1.5px] border-green4-native w-[18px] h-[18px] pl-[1px] text-[20px] sm:text-[16px] sm:w-auto rounded-full sm:border-0">navigate_next</span>

    </p>
  </div>
  <p class="text-[13px] mb-2 font-Montserra">Find the best strain for the effect that you’re looking for.</p>
  <div class="flex gap-[20px] sm:gap-[25px] mt-[21px] ocultarScrollXNoVisible w-full">
    <ng-container *ngFor="let effect of effects">
    <div  routerLink="/effect" [queryParams]="{ type: effect.name }" class="cursor-pointer h-[175px] sm:h-[177px] rounded-xl sm:rounded-2xl relative flex items-end justify-start bg-cover bg-center" [style.background-image]="'url('+effect.img+')'">
      <div class="absolute rounded-xl sm:rounded-2xl left-0 top-0 w-full h-full bg-gradient-to-t via-transparent from-black to-transparent"></div>
      <p class="p-2  w-[204px] sm:w-[204px] z-10 font-Monserrat font-semibold text-[20px] sm:text-[15px] leading-[26px] text-white">
        {{effect.title}}
      </p>
    </div>
    </ng-container>
  </div>

</div>
