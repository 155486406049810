import {Component, EventEmitter, inject, Input, Output, ViewChild} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {DialogComponent} from "../dialog/dialog.component";
import {ActivatedRoute, Router} from "@angular/router";
import {UserInfoService} from "../../../services/user-info/user-info.service";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-product-dialog',
  templateUrl: './product-dialog.component.html',
  styleUrls: ['./product-dialog.component.scss']
})
export class ProductDialogComponent {
  sanitizer = inject(DomSanitizer);
  private http = inject(HttpClient)
  @ViewChild(DialogComponent, { static: false }) dialogComponent: DialogComponent | undefined;
  router = inject(Router);
  @Input() detailVisable=false;
  @Output() selectGift = new EventEmitter<string>();
  data:any={
    cdb: 0,
    updateDate: '',
    flavors: [],
    terpenes: [],
    grow_information: '',
    thc: 0,
    name: '',
    effects: [],
    createDate: '',
    genetics: '',
    category: '',
    images: [],
    description: '',
    id: '',
    type: '',
    amount:0,
    val_reviews:0,
    count_reviews:0,
    publish_cost: 0,
    pk: ""
  }
  closeDialog(data: any){
    this.detailVisable=false
    this.selectGift.emit('');
  }
  pressCloseButton(){
    this.dialogComponent?.closeB()
    this.selectGift.emit('');
  }
  async openProduct(id: string) {
    this.http.get('/products/general/filters', {params: {id: id}})
      .subscribe(data => {
        console.log(data)
        if(data!=null){
          this.data=data;
          this.detailVisable=true;
          this.dialogComponent?.open()
        }
      });
  }
  constructor(private userInfo: UserInfoService,private route: ActivatedRoute){}
  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      const productId = params.get('productid');
      if(productId) this.openProduct(productId)
    });
  }
}
