<div *ngIf="config" class="w-screen px-4 h-screen fixed top-0 right-0 bg-[#171725]/30 z-20 flex items-center justify-center">
  <div class="p-4 bg-white shadow-lg rounded-lg text-center w-full sm:w-[850px] relative">
    <span class="material-symbols-outlined cursor-pointer absolute top-[10px] right-[10px]" (click)="onClickBtn()">close</span>
    <p class="sm:text-[#000000] text-[25px] sm:text-[30px] my-[20px] font-Montserrat font-bold">Course</p>
    <div class="flex flex-col-reverse sm:flex-row mb-4">
      <div class="sm:w-1/2 px-[15px] sm:px-[20px]">
        <p class="block sm:hidden sm:text-[#000000] text-[16px] my-[15px] font-Poppins font-medium text-left">Payment Method</p>
        <form id="payment-form">
          <div id="payment-element" >
            <!--Stripe.js injects the Payment Element-->
          </div>
          <div id="payment-message" class="text-red-600 font-semibold mt-2">
            {{paymentmessage}}
          </div>
        </form>
        <button (click)="checkout()" class="flex sm:hidden items-center mt-[20px] justify-center w-[90%] ml-[5%] w-h-[60px] text-[25px] bg-[#81CB52] text-white sm:mt-[60px] rounded-xl"> Checkout </button>
      </div>
      <div class="sm:w-1/2">
        <div class="list-products sm:mt-[47px] mx-4 sm:mx-0">
          <div class="flex items-center justify-between mb-1" *ngFor="let item of config.data.courses">
            <div class="flex gap-2 items-center justify-center">
              <img class="w-[56px] h-[56px] rounded-2xl  object-contain" [src]="item.images[0]" alt="">
              <div>
                <p class="sm:text-[18px] sm:text-[#000000] font-Montserrat font-bold">{{item.name}}</p>
              </div>
            </div>
            <div class="flex gap-2 items-center justify-center">
              <p class="sm:text-[16px] sm:text[#000000] font-Montserrat font-medium">${{item.publish_cost}}</p>
            </div>
          </div>
          <hr class="text-[#DDDDDD] border border-[#DDDDDD] sm:mt-[16px]">
          <div class="flex justify-between mt-[15px] sm:mt-[36px] mb-[15px] sm:mb-0">
            <p class="text-[16px] font-semibold font-Montserrat">Total</p>
            <p class="text-[16px] font-semibold font-Montserrat">${{ getTotal() }}</p>
          </div>
          <button (click)="checkout()" class="hidden sm:flex items-center justify-center w-[90%] ml-[5%] sm:w-h-[80px] sm:text-[30px] bg-[#81CB52] text-white sm:mt-[60px] rounded-xl"> Checkout </button>
        </div>
      </div>
    </div>
  </div>
</div>
