import {Component, inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {RegisterForm} from "../../../interfaces";
import Swal from "sweetalert2";
import {AuthService} from "../../../services/auth.service";
import {UserInfoService} from "../../../../services/user-info/user-info.service";
import {Subscription} from "rxjs";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-register-home',
  templateUrl: './register-home.component.html',
  styleUrls: ['./register-home.component.scss']
})
export class RegisterHomeComponent implements OnInit {
  router = inject(Router);
  userInfoService = inject(UserInfoService);
  private http = inject(HttpClient);
  step = 1;
  isFounder:boolean = false;
  idFounder:string = '';
  registerForm: RegisterForm = {
    username: '',
    name: '',
    lastname: '',
    email: '',
    password: '',
    country: '',
    region: '',
    birthday: {
      day: '',
      month: '',
      year: '',
    },
    is_older: false,
    terms_conditions: false,
    location: '',
    interests: [],
    best_describes: [],
    code_verify: ''
  };
  ageLegal = 0;
  subscriptionFounders: Subscription | undefined;
  ngOnInit() {
    if(sessionStorage.getItem('form')){
      this.registerForm = JSON.parse(sessionStorage.getItem('form') || "{}");
      this.step = Number(sessionStorage.getItem('step')?.toString());
    }
    if(sessionStorage.getItem('ageLegal')){
      this.ageLegal = Number(sessionStorage.getItem('ageLegal')?.toString());
    }
    this.route.queryParams.subscribe(params => {
      const id= params['founder'];
      if(id){
        this.subscriptionFounders = this.http.get(`/founder/${id}`).subscribe({
          next: (response: any) => {
            if(response?.userId!=''){
              const token = localStorage.getItem('token');
              if (!token) {
                Swal.fire({
                  icon: 'error',
                  title: 'Founder',
                  text: 'You already have a user.',
                  customClass: {
                    container: 'dark-swal'
                  },
                  width: '300px',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  allowEnterKey: false,
                  didOpen: () => {
                    Swal.showLoading();
                    setTimeout(function () {
                      Swal.close();
                      location.replace("/");
                    }, 2000);
                  },
                })
              }
              this.subscriptionFounders?.unsubscribe();
            }else{
              this.registerForm.country='c1';
              this.registerForm.email=response.email;
              this.registerForm.name=response.firstName;
              this.registerForm.lastname=response.lastName;
              this.isFounder=true;
              this.idFounder=id;
              this.step=1
            }
            this.subscriptionFounders?.unsubscribe();
          }
        })
      }
    });
  }
  setAge(age: number) {
    this.ageLegal = age;
    sessionStorage.setItem('ageLegal', String(this.ageLegal));
  }

  private authService = inject(AuthService);

  register() {

    let data: any = Object.assign({idFounder:this.idFounder}, this.registerForm);
    data.birthday = `${data.birthday.year}-${data.birthday.month}-${data.birthday.day}`;
    data.location = 'location';
    this.authService.register(data)
      .subscribe({
        next: () => {
          this.userInfoService.getMe().then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Sign up successful',
              text: 'You’re all set up to begin your journey.',
              customClass: {
                container: 'dark-swal'
              },
              width: '300px',
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              didOpen: () => {
                Swal.showLoading();
                setTimeout(function () {
                  Swal.close();
                  location.replace("/settings/profile");
                }, 2000);
              },
            })
          })
        },
        error: (error) => {
          Swal.fire({
            icon: 'error',
            title: 'Register failed',
            text: error,
            customClass: {
              container: 'dark-swal',
              title: 'swal2-title-error'
            },
            width: '300px',
            confirmButtonColor: '#373737'
          })
        }
      })
  }

  async previousStep() {
    if (this.step == 1) {
      await this.router.navigate(['/'])
    } else {
      this.step--;
    }
  }

  receiveData(data: any) {
    Object.entries(data).forEach(entry => {
      const [key, value] = entry;
      if (this.registerForm.hasOwnProperty(key)) {
        // @ts-ignore
        this.registerForm[key] = value;
      }
      sessionStorage.setItem('form', JSON.stringify(this.registerForm));
    });
    if (this.step == 7) {
      this.register()
      sessionStorage.removeItem('ageLegal')
      sessionStorage.removeItem('step')
      sessionStorage.removeItem('form')
    } else {
      this.step++;
      sessionStorage.setItem('step', this.step.toString());
    }
  }
  constructor(private route: ActivatedRoute) { }
}
