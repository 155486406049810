<div class="register-container om-container">
  <div class="arrow-container hidden sm:flex sm:absolute sm:bg-arrow w-16 h-16 flex items-center justify-center mt-0
            sm:mt-[calc(var(--w-height)*9)] sm:ml-[calc(var(--w-width)*4.5)]">
    <ng-icon name="matArrowBack" class="mx-4 mt-[calc((var(--w-height)*2)+var(--sat))] sm:mt-0 absolute
        text-white cursor-pointer text-4xl z-10 bg-none sm:text-5xl sm:rounded-full" (click)="previousStep()"></ng-icon>
  </div>
  <div class=" md:flex h-full">
    <div class="w-1/2 hidden md:flex  items-center h-full overflow-hidden	">
      <img src="assets/home/home-desktop.png" alt="background" class="h-full w-auto">
      <img alt="logo" src="assets/logo.svg" class="w-[15vw] md:ml-[-10vw] md:mt-[1vh]">
    </div>
    <div class="w-full sm:w-1/2 md:flex justify-end h-full">
      <app-you-locate *ngIf="step === 1" [dataDefault]="{
        country: registerForm.country,
        region: registerForm.region,
        birthday: registerForm.birthday
      }" (sendData)="receiveData($event)" (sendAge)="setAge($event)" (previousStep)="previousStep()" [isFounder]="isFounder"
      >
      </app-you-locate>
      <app-create-account *ngIf="step === 2" [data]="{
          username: registerForm.username,
          email: registerForm.email,
          password: registerForm.password,
          is_older: registerForm.is_older,
          terms_conditions:registerForm.terms_conditions,
        }"
                          [isFounder]="isFounder"
                          [birthday]="registerForm.birthday.year+'-'+registerForm.birthday.month+'-'+registerForm.birthday.day"
                          [ageCountry]="ageLegal" (sendData)="receiveData($event)"
                          (previousStep)="previousStep()"></app-create-account>
      <app-otp-verification *ngIf="step === 3"
                            [dataDefault]="{email:registerForm.email, username:registerForm.username}"
                            (sendData)="receiveData($event)" (previousStep)="previousStep()"></app-otp-verification>
      <app-enable-location *ngIf="step === 4" (sendData)="receiveData($event)"
                           (previousStep)="previousStep()"></app-enable-location>
      <app-gift-and-share *ngIf="step === 5" (sendData)="receiveData($event)"
                          (previousStep)="previousStep()"></app-gift-and-share>
      <app-describes-you *ngIf="step === 6" [best_describes]="registerForm.best_describes"
                         (sendData)="receiveData($event)" (previousStep)="previousStep()"></app-describes-you>
      <app-interested-in *ngIf="step === 7" [interests]="registerForm.interests" (sendData)="receiveData($event)"
                         (previousStep)="previousStep()"></app-interested-in>
    </div>
  </div>
</div>
