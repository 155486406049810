import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.scss']
})
export class OtpVerificationComponent implements OnInit{
  @Output() sendData = new EventEmitter<any>();
  @Input() dataDefault ={
    email:'',
    username:''
  };
  @Input() title: string = 'Email Verification';
  private http = inject(HttpClient);
  private router = inject(Router);
  private code_verify_private: string = '';
  public time_new_code = 0;
  error_code=false
  repetitions= 0;
  data={
    code_verify:''
  }
  @Output() previousStep = new EventEmitter<any>();
  previous() {
    this.previousStep.emit();
  }
  ngOnInit() {
    console.error(this.dataDefault)
    this.newOTP();
  }
  newOTP() {
    this.code_verify_private = ''
    this.http.post('/auth/new-otp', { email: this.dataDefault.email, username: this.dataDefault.username }).subscribe({
      next: (response: any) => {},
      error: (error: any) => {
        this.code_verify_private = '';
      }
    });
    this.time_new_code = 60;
    this.startTimer();

  }
  onOtpChange(event: any) {
    this.data.code_verify = event;
    this.error_code = false;
    if (this.data.code_verify.length == 4) {
      this.repetitions++;
      this.http.post('/auth/check-otp', {
        email: this.dataDefault.email,
        username: this.dataDefault.username,
        code: this.data.code_verify
      }).subscribe({
        next: (response: any) => {
          const {result, token} = response
          if(result === true){
              localStorage.setItem('token', token)
              this.sendData.emit(this.data);
              return;
          }
          if(this.repetitions === 3)
            this.router.navigateByUrl('/')

          this.error_code = true;
        },
        error: (error: any) => {
          this.error_code = true;
        }
      });
    }
  }
  startTimer() {
    setTimeout(() => {
      if (this.time_new_code > 0) {
        this.time_new_code--;
        this.startTimer();
      } else {
        // El contador ha llegado a cero, puedes realizar alguna acción aquí
      }
    }, 1000); // Actualiza el contador cada 1000 ms (1 segundo)
  }
  maskEmail(email:string) {
    if (email.includes("@")) {
      const parts = email.split("@");
      const username = parts[0];
      const domain = parts[1];
      let no=(username.length/2);
      const hiddenUsername = "*".repeat(no) + username.substring(no);
      return hiddenUsername + "@" + domain;
    } else {
      return ''
    }
  }
}
