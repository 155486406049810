import {
  Component,
  inject,
  EventEmitter,
  Output,
  Input,
  OnInit,
  ViewChildren,
  QueryList,
  ElementRef
} from '@angular/core';
import {HttpClient} from "@angular/common/http";
interface acount {
  username: string;
  email: string;
  password: string;
  is_older: Boolean,
  terms_conditions: Boolean
}
@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent{
  private http = inject(HttpClient);
  @ViewChildren('username, email,password,isOlder,terms_conditions') elements: QueryList<ElementRef> | undefined;
  @Output() sendData = new EventEmitter<any>();
  @Input() birthday: string='';
  @Input() ageCountry: number=0;
  @Input() data: acount = {
    username: '',
    email: '',
    password: '',
    is_older:false,
    terms_conditions:false,
  } as acount;
  exist_user=false;
  exist_email=false;
  @Output() previousStep = new EventEmitter<any>();
  @Input() isFounder: boolean=false;
  previous() {
    this.previousStep.emit();
  }
  validateEmailUserExists() {
    this.markElementsAsTouched()
    if (this.data.email=='' || this.data.username=='' || this.data.password=='' || !this.data.is_older || !this.data.terms_conditions) return;
    this.exist_user = false;
    this.exist_email = false;
    this.http.post('/auth/find-user-email', { email: this.data.email, username: this.data.username }).subscribe({
      next: (response: any) => {
        const { username, email } = response
        this.exist_user = username;
        this.exist_email = email;
        if (!email && !username) {
          this.sendData.emit(this.data);
        }
      }
    });
  }
  markElementsAsTouched() {
    if(this.elements)
    this.elements.forEach((element: any) => {
      element.control.touched = true;

    });
  }
}
