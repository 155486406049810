import {Component, ElementRef, inject, Input} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {UserInfoService} from "../../../services/user-info/user-info.service";

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent {
  private http = inject(HttpClient);
  router = inject(Router);
  imgOpen=0;
  mine=false;
  @Input() data={
    cdb: 0,
    updateDate: '',
    flavors: [],
    terpenes: [],
    grow_information: '',
    thc: 0,
    name: '',
    effects: [],
    createDate: '',
    genetics: '',
    category: '',
    images: [],
    description: '',
    id: '',
    type: '',
    amount:0,
    val_reviews:0,
    count_reviews:0, pk: ""

  }
  sharables = [];
  ngOnInit() {
    this.getSharables()
  }

  constructor(private elementRef: ElementRef,private userInfo: UserInfoService) {
  }

  getSharables() {
    this.sharables = [];
    this.http.get('/products').subscribe({
      next: (response: any) => {
        this.sharables = response.records
      }
    });
  }
  claimGift(){

    let data={
      gifts:[{
        name: this.data.name,
        genetics: this.data.genetics,
        quantity: 1,
        price: 0,
        total: 0,
        id: this.data.id,
        images: this.data.images,
        pk:this.data.pk
      }] as IProductCart[],
      user:{
        id: this.userInfo.me().user.id,
        username: this.userInfo.me().user.username,
        name: (this.userInfo.me().user?.name || '') + ' ' + (this.userInfo.me().user?.lastname || ''),
        email: this.userInfo.me().user?.email,
        phoneNumber: (this.userInfo.me().user?.dial_code || '') + (this.userInfo.me().user?.phone || ''),
        avatar: '',
      },
      address:{
        id:"d23234",
        streetNumber: "123",
        streetName: "Blane Street",
        apartmentOrUnit: "",
        postalCode: "232",
        city: "Wacker",
        province: "Wacker",
        country: "Wacker",
        isVerified:true
      },
      message:'',
      subtotal:0,
      taxFees:0,
      delivery:7.5
    }
    localStorage.setItem('claimGift', JSON.stringify(data));
    window.location.replace('/claim-gift');
    //this.router.navigateByUrl('/claim-gift')

  }
}
