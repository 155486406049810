import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-coaching-one-to-one',
  templateUrl: './coaching-one-to-one.component.html',
  styleUrls: ['./coaching-one-to-one.component.scss']
})
export class CoachingOneToOneComponent {
  //delete
  @Input() title = 'One on One Mentorships2';
  @Input()  classTitle: any[] = [];
}
