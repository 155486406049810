import {Component, effect, ElementRef, HostListener, inject, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {GiftService} from "../../../services/gift/gift.service";
import {ActivatedRoute} from "@angular/router";
import {CatalogsService} from "../../../services/catalogs/catalogs.service";

@Component({
  selector: 'app-effect-home',
  templateUrl: './effect-home.component.html',
  styleUrls: ['./effect-home.component.scss']
})
export class EffectHomeComponent implements OnInit {
  catalogsService = inject(CatalogsService);
  @ViewChild('filterList') filterList: ElementRef | undefined;
  @ViewChild('containerFilterList') containerFilterList: ElementRef | undefined;
  private http = inject(HttpClient);
  giftService = inject(GiftService);
  showFilters = false
  sharables = [];
  userId = ''
  search = ''
  selected = 'all'
  filterTab = 'sort'
  filterType = 'Relevance'
  filters: { [key: string]: string } = {
    sort: 'relevance',
    category: 'flower',
    type: 'all',
  }
  indexEffect = -1
  isLoading = true;
  sorts: { label: string, value: string }[] = [
    {label: 'Relevance', value: 'relevance'},
    {label: 'Top Sales', value: 'top-sales'},
    {label: 'Most Recent', value: 'most-recent'},
    {label: 'Price Low to High', value: 'Price-Low-to-High'},
    {label: 'Price High to Low', value: 'Price-High-to-Low'},
  ]
  categories: { label: string, value: string }[] = [
    {label: 'All Category', value: 'all'},
  ]
  types: { label: string, value: string }[] = [
    {label: 'All Types', value: 'all'},
  ]
  effects: ICatalogView[] = [];

  constructor(private route: ActivatedRoute) {
  }

  async ngOnInit() {
    await this.getCategories()
    await this.getEffects()
    await this.getTypes()
    this.route.queryParams.subscribe(params => {
      const type = params['type'];
      const indexfindObject = this.effects.findIndex(object => object.name === type);

      if (indexfindObject !== -1) {
        this.indexEffect = indexfindObject
      }
    });
    this.isLoading = false;
    window.scrollTo(0, 1);
  }

  getEffects() {
    return new Promise<void>((resolve, reject) => {
      this.catalogsService.getAll('effects', '').subscribe({
        next: (response: any) => {
          this.effects = [...this.effects, ...response.records.map((x: any) => {
            return {
              name: x.name.toLowerCase(),
              title: x.name,
              img: x.short_image,
              banner: x.long_image,
              route: '/market-search'
            };
          })];
          resolve();
        },
        error: (err) => {
          reject(err);
        }
      });
    });
  }

  getCategories() {
    return new Promise<void>((resolve, reject) => {
      this.catalogsService.getAll('categories', '').subscribe({
        next: (response: any) => {
          this.categories = [...this.categories, ...response.records.map((x: any) => {
            return {
              value: x.name.toLowerCase(),
              label: x.name,
            };
          })];
          resolve();
        },
        error: (err) => {
          reject(err);
        }
      });
    });
  }

  getTypes() {
    return new Promise<void>((resolve, reject) => {
      this.catalogsService.getAll('product-type', '').subscribe({
        next: (response: any) => {
          this.types = [...this.types, ...response.records.map((x: any) => {
            return {
              label: x.name.toLowerCase(),
              value: x.name,
            };
          })]
          resolve();
        },
        error: (err) => {
          reject(err);
        }
      })
    });
  }

  setSort(key: string) {
    this.filterTab = key;
  }

  setFilterType(key: string) {
    this.filters[this.filterTab] = key;
  }

  getCatalogFilter() {
    let catalog: typeof this.categories = [];
    switch (this.filterTab) {
      case 'sort':
        catalog = this.sorts;
        break;
      case 'category':
        catalog = this.categories;
        break;
      case 'type':
        catalog = this.types;
        break;
    }
    return catalog;
  }

  claimgift(data: any) {
    this.giftService.claimGift(data)
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!!this.filterList && !(this.filterList as ElementRef).nativeElement.contains(event.target) && this.showFilters) {
      this.showFilters = false;
    }
  }

  protected readonly effect = effect;
  protected readonly JSON = JSON;
}
