import {Component, inject, Input} from '@angular/core';
import {Router} from "@angular/router";
import {DeviceInfoService} from "../../../services/device-info/device-info.service";

@Component({
  selector: 'app-messages-layout',
  templateUrl: './messages-layout.component.html',
  styleUrls: ['./messages-layout.component.scss']
})
export class MessagesLayoutComponent {
  router = inject(Router);
  @Input() routeHeader = '';
  @Input() cls = '';
  @Input() title = '';
  constructor(public deviceInfo: DeviceInfoService) {
  }
  ngOnInit(){
    this.deviceInfo.scrollTop();
  }
  toggleMenu(){
    this.deviceInfo.toggleMenu()
  }
  getClass() {
    let classes: {[key:string]: boolean} = {};

    const mapClassToBoolean = (cl: string) => ({ [cl]: true });

    if(this.cls){
      const arrcls = this.cls.split(' ');
      classes = Object.assign({}, ...arrcls.map(mapClassToBoolean));
    }

    return classes;
  }
}
