<div class="bg-white new-publication !px-4 hidden sm:block" [ngClass]="containnerClass">
  <p *ngIf="visibleTitle" class="text-[12px] text-[#171725] font-semibold font-Poppins profile-show px-4 py-1 title-new-post">Post Something</p>
  <hr *ngIf="visibleTitle"  class="hidden profile-show">
  <div class="container">
    <div class="flex items-center gap-4 container-input">
      <img [src]="user.image_profile || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'" class="avatar rounded-full" alt="img">
      <div class="input w-full">
        <p class="bg-[#FAFAFA] p-[calc(var(--w-width)*1)] w-full rounded-full focus-visible:outline-none cursor-pointer" (click)="openForm()" >What’s on your mind?</p>
        <div class="icon">
          <svg id="Insert_Image" data-name="Insert Image" xmlns="http://www.w3.org/2000/svg" width="21" height="20" class="cursor-pointer" (click)="openFormFile()" viewBox="0 0 21 20">
            <path id="Shape" d="M17.5,20H3.5A3.431,3.431,0,0,1,0,16.65V3.35A3.431,3.431,0,0,1,3.5,0h14A3.431,3.431,0,0,1,21,3.35v13.3A3.431,3.431,0,0,1,17.5,20ZM15.286,8.775l-4.714,4.253L15.961,19H17.5A2.431,2.431,0,0,0,20,16.65V13.071Zm-8.139,1.95h0L1.009,16.849A2.458,2.458,0,0,0,3.5,19H14.613L7.148,10.725ZM3.5,1A2.431,2.431,0,0,0,1,3.35v12.1l5.814-5.8a.5.5,0,0,1,.724.019L9.9,12.286l5.051-4.557a.5.5,0,0,1,.671,0L20,11.718V3.35A2.431,2.431,0,0,0,17.5,1Zm3.19,5.7A1.925,1.925,0,1,1,8.619,4.775,1.929,1.929,0,0,1,6.691,6.7Zm0-2.849a.925.925,0,1,0,.929.925A.928.928,0,0,0,6.691,3.85Z" transform="translate(0)" fill="#92929d"/>
          </svg>
        </div>
      </div>
    </div>
    <hr class="hidden sm:block">
    <div class="grid grid-rows-1 grid-flow-col gap-4 sm:mt-[calc(var(--w-width)*1.8)] actions">
      <div class="flex justify-center items-center gap-[calc(var(--w-width)*1)] cursor-pointer" (click)="openFormFile()">
        <svg id="vuesax_bold_video" data-name="vuesax/bold/video" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
          <g id="video">
            <path id="Vector" d="M16.167,2.433a1.874,1.874,0,0,0-2.033.308l-1.225.867C12.817,1.017,11.692,0,8.958,0h-5C1.108,0,0,1.108,0,3.958v6.667a3.679,3.679,0,0,0,3.958,3.958h5c2.733,0,3.858-1.017,3.95-3.608l1.225.867a2.293,2.293,0,0,0,1.325.483,1.516,1.516,0,0,0,.708-.175,1.881,1.881,0,0,0,.917-1.842V4.275A1.881,1.881,0,0,0,16.167,2.433ZM7.708,6.775A1.567,1.567,0,1,1,9.275,5.208,1.57,1.57,0,0,1,7.708,6.775Z" transform="translate(1.458 2.708)" fill="#4ca989"/>
            <path id="Vector-2" data-name="Vector" d="M0,0H20V20H0Z" fill="none" opacity="0"/>
          </g>
        </svg>
        <span>Video</span>
      </div>
      <div class="flex justify-center items-center gap-[calc(var(--w-width)*1)] cursor-pointer" (click)="openFormFile()">
        <svg id="vuesax_bold_gallery" data-name="vuesax/bold/gallery" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
          <g id="gallery">
            <path id="Vector" d="M.442,1.65l-.017.017A5.156,5.156,0,0,1,0,0,5.118,5.118,0,0,0,.442,1.65Z" transform="translate(1.708 14.192)" fill="#1e63d1"/>
            <path id="Vector-2" data-name="Vector" d="M3.967,1.983A1.983,1.983,0,1,1,1.983,0,1.983,1.983,0,0,1,3.967,1.983Z" transform="translate(5.517 4.683)" fill="#1e63d1"/>
            <path id="Vector-3" data-name="Vector" d="M11.825,0H4.842A4.447,4.447,0,0,0,0,4.842v6.983a5.6,5.6,0,0,0,.467,2.367,4.439,4.439,0,0,0,4.375,2.475h6.983a4.447,4.447,0,0,0,4.842-4.842V4.842A4.447,4.447,0,0,0,11.825,0Zm3.483,8.75a1.858,1.858,0,0,0-2.35,0L9.492,11.725a1.858,1.858,0,0,1-2.35,0l-.283-.233a1.851,1.851,0,0,0-2.2-.117L1.542,13.467a4.444,4.444,0,0,1-.292-1.642V4.842c0-2.35,1.242-3.592,3.592-3.592h6.983c2.35,0,3.592,1.242,3.592,3.592v4Z" transform="translate(1.667 1.667)" fill="#1e63d1"/>
            <path id="Vector-4" data-name="Vector" d="M0,0H20V20H0Z" fill="none" opacity="0"/>
          </g>
        </svg>
        <span>Photo</span>
      </div>
    </div>
  </div>
</div>
<app-dialog title="Post {{type=='news'?'Something':'Article'}}" [useTemplateHeader]="true" [classModal]="'sm:!min-w-[800px] sm:!max-w-[800px] sm:!min-h-[50vh] relative pt-[var(--sat)]'" >
  <div slot="header" class="">
  </div>
  <app-post *ngIf="showPost" (senDataPost)="save($event)" (closeDialog)="closeDialog()"></app-post>
</app-dialog>
<div *ngIf="loading" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[9999]">
  <div class="text-white text-center">
    <p class="text-xl font-bold">Publishing</p>
  </div>
</div>
