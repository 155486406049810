import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-video-courses-icon',
  templateUrl: './video-courses-icon.component.svg',
  styleUrls: ['./video-courses-icon.component.scss']
})
export class VideoCoursesIconComponent {
  @Input() color = '#131313';
  @Input() width = 24;
  @Input() height = 24;
}
