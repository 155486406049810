import {Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit{
  @ViewChildren('passwordField, passwordConfirmedField') elements: QueryList<ElementRef> | undefined;
  @Output() sendData = new EventEmitter<any>();
  @Input() data: IResetPassword = {
    email: "",
    verified: false,
    password: "",
    passwordConfirmed: "",
  }

  ngOnInit() {
    console.log(this.data, "Data in new Password")
  }

  resetPassword() {
    this.markElementsAsTouched();
    if(!this.data.password || !this.data.passwordConfirmed ||
      this.data.password !== this.data.passwordConfirmed ||
      this.data.password.length < 8) return;

    this.sendData.emit(this.data);
  }
  markElementsAsTouched() {
    if (this.elements)
      this.elements.forEach((element: any) => {
        element.control.touched = true;

      });
  }
}
