<div *ngIf="callInfo !== null && !isInCall" class="fixed top-0 left-0 bg-gray-100 w-full z-50 h-screen flex items-center justify-center">
  <audio autoplay loop>
    <source src="assets/sounds/ringtone-126505.mp3">
  </audio>
  <div class="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
    <div class="flex items-center justify-center">
      <div class="w-16 h-16 bg-blue-500 rounded-full flex items-center justify-center">
        <svg *ngIf="!isOwn()" xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24"
             stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
        </svg>
        <svg *ngIf="isOwn()" xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 5v14l11-7z"></path>
        </svg>
      </div>
    </div>
    <div class="mt-4 text-center">
      <p class="text-lg font-semibold">{{ getText() }}</p>
      <p class="text-gray-500">{{ getName() }}</p>
    </div>
    <div class="flex justify-center mt-6">
      <button *ngIf="!isOwn()" class="bg-green-500 text-white px-4 py-2 rounded-full" (click)="join()">Join</button>
      <button class="ml-4 bg-red-500 text-white px-4 py-2 rounded-full" (click)="reject()">{{ isOwn() ? 'Cancel' : 'Decline' }}</button>
    </div>
  </div>
</div>

<app-zoom *ngIf="isInCall && callInfo !== null" #zoomComponent
          [type]="callInfo.type"
          [callInfo]="callInfo"
          [userName]="getNameSession()"
          [sessionName]="callInfo.inbox_id"
          [features]="features"/>
