import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {GoogleMapsService} from "../../../services/google-maps/google-maps.service";

@Component({
  selector: 'app-g-maps',
  templateUrl: './g-maps.component.html',
  styleUrls: ['./g-maps.component.scss']
})
export class GMapsComponent implements AfterViewInit {
  @ViewChild('mapContainer', {static: false}) mapContainer: ElementRef | undefined;
  @ViewChild('searchInput', {static: false}) searchInput: ElementRef | undefined;
  @Output() accept = new EventEmitter<{ lng: number, lat: number, address: string } | null>();
  map: google.maps.Map | undefined;
  marker: google.maps.Marker | undefined;
  latLng: { lat: number, lng: number } = {lat: 53.5461, lng: -113.4938};
  address: string = '10103 104 Ave NW, Edmonton, AB T5J, Canadá';
  @Input() locationDefault: { lat: number, lng: number, address: string } | null = null;

  constructor(private googleMapsService: GoogleMapsService) {
  }

  ngAfterViewInit(): void {
    this.googleMapsService.loadGoogleMaps().then(() => {
      this.initializeMap();
      this.initializeSearchBox();
    }).catch(error => {
      console.error('Google Maps failed to load:', error);
    });
  }

  initializeMap(): void {
    if (this.locationDefault) {
      this.latLng = {lat: this.locationDefault.lat, lng: this.locationDefault.lng};
      this.address = this.locationDefault.address
    }
    this.map = new google.maps.Map(this.mapContainer?.nativeElement, {
      center: this.latLng,
      zoom: 17,
      streetViewControl: false,
    });

    this.marker = new google.maps.Marker({
      position: this.latLng,
      map: this.map,
      draggable: true
    });

    const geocoder = new google.maps.Geocoder();
    this.map.addListener('click', (event: google.maps.MapMouseEvent) => {
      this.placeMarker(event.latLng)
      const latLng = event.latLng?.toJSON();
      if (latLng) this.latLng = latLng;
    });
  }

  locateUser(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.latLng = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          this.map?.setCenter(this.latLng);
          if (this.marker) {
            this.marker.setPosition(this.latLng);
          } else {
            this.marker = new google.maps.Marker({
              position: this.latLng,
              map: this.map,
              title: 'Your location'
            });
          }
          const geocoder = new google.maps.Geocoder();
          this.geocodePosition(this.latLng, geocoder);

        },
        (error) => {
          console.error('Error al obtener la ubicación:', error);
        }
      );
    } else {
      console.error('Geolocalización no soportada por este navegador.');
    }
  }

  initializeSearchBox(): void {
    const input = this.searchInput?.nativeElement as HTMLInputElement;
    const searchBox = new google.maps.places.SearchBox(input);
    this.map?.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
    this.map?.addListener('bounds_changed', () => {
      searchBox.setBounds(this.map?.getBounds() as google.maps.LatLngBounds);
    });

    searchBox.addListener('places_changed', () => {
      const places: any = searchBox.getPlaces();
      if (places?.length === 0) {
        return;
      }
      if (this.marker) {
        this.marker.setMap(null);
      }
      const place = places[0];
      if (!place?.geometry) {
        return;
      }
      if (place.geometry.viewport) {
        this.map?.fitBounds(place.geometry.viewport);
      } else {
        this.map?.setCenter(place.geometry.location as google.maps.LatLng);
        this.map?.setZoom(17);
      }
      const latLng = place.geometry.location.toJSON();
      if (latLng) this.latLng = latLng;
      this.marker = new google.maps.Marker({
        position: place.geometry.location,
        map: this.map,
        draggable: true
      });
      this.address = place.formatted_address || '';
    });
  }

  placeMarker(location: any): void {
    if (this.marker) {
      this.marker.setPosition(location);
      this.map?.panTo(location);
      const geocoder = new google.maps.Geocoder();
      this.geocodePosition(location, geocoder);
    }
  }

  geocodePosition(position: google.maps.LatLng | google.maps.LatLngLiteral, geocoder: google.maps.Geocoder): void {
    geocoder.geocode({location: position}, (results, status) => {
      if (status === 'OK' && results && results.length > 0) {
        this.address = results[0].formatted_address;
      }
    });
  }
  async acceptSave() {
    this.accept.emit({ lng: this.latLng.lng, lat: this.latLng.lat, address: this.address });
  }

  cancel() {
    this.accept.emit(null);
  }
}
