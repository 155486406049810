import { Component } from '@angular/core';

@Component({
  selector: 'app-coaching-top-coaches',
  templateUrl: './coaching-top-coaches.component.html',
  styleUrls: ['./coaching-top-coaches.component.scss']
})
export class CoachingTopCoachesComponent {

}
