import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-my-courses',
  templateUrl: './my-courses.component.html',
  styleUrls: ['./my-courses.component.scss']
})
export class MyCoursesComponent implements OnInit, OnDestroy {
  statusFetch = false;
  is_init = true;
  private http = inject(HttpClient);
  type = 'courses';
  data: any = {
    courses: [],
    one: []
  }
  subscriptionData: Subscription | undefined;

  getData() {
    this.statusFetch = true;
    this.subscriptionData = this.http.get('/courses', {
      params: {type: 'my-courses'}
    }).subscribe({
      next: (response: any) => {
        for (const row of response.records) {
          if (row.category === 'One on One  Mentorship’s') this.data.one.push(row);
          else this.data.courses.push(row)
        }
        this.statusFetch = false;
        if (this.is_init) {
          this.is_init = false
        }
        this.subscriptionData?.unsubscribe()
      }
    });

  }

  ngOnInit() {
    this.getData();
  }

  ngOnDestroy() {
    this.subscriptionData?.unsubscribe();
  }
}
