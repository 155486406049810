import {Component, inject, NgZone, ViewChild} from '@angular/core';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {HttpClient} from "@angular/common/http";
import {DialogComponent} from "../../general/dialog/dialog.component";
import {GiftService} from "../../../services/gift/gift.service";
import {DeviceInfoService} from "../../../services/device-info/device-info.service";
import {DomSanitizer} from "@angular/platform-browser";
import {UserInfoService} from "../../../services/user-info/user-info.service";
import {Router} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {OmAlertService} from "../../../om-alert.service";

@Component({
  selector: 'app-pay-gift',
  templateUrl: './pay-gift.component.html',
  styleUrls: ['./pay-gift.component.scss']
})
export class PayGiftComponent {
  private alertService = inject(OmAlertService);
  Editor = ClassicEditor;
  http = inject(HttpClient);
  @ViewChild(DialogComponent, {static: false}) dialogComponentPay: DialogComponent | undefined;
  // @ts-ignore
  stripe: any = Stripe(environment.pkStripe)
  paymentElement: any = null;
  currency = '';
  loading = false;
  enableRequiredInput = false;
  data: any = null
  address: any = {}
  paymentmessage: string = '';
  elements: any;
  clientSecret: string = '';
  tab = 'address';
  userData: any = {
    username: '',
    name: '',
    email: '',
    phoneNumber: '',
  };

  async openForm(data: any) {
    this.tab = 'userInfo';
    this.enableRequiredInput=false;
    const delivery_address = this.userInfo.me().user.delivery_address;
    this.userData = {
      username: this.userInfo.me().user.username,
      name: (this.userInfo.me().user?.name || '') + ' ' + (this.userInfo.me().user?.lastname || ''),
      email: this.userInfo.me().user.email,
      phoneNumber: (this.userInfo.me().user?.dial_code || '') + (this.userInfo.me().user?.phone || ''),
    }
    let array = (delivery_address?.address || '').split(' ');
    let country = array[array.length - 1];
    this.address = {
      id: country.toLowerCase() === 'canada' ? ' ' : '',
      address: delivery_address?.address || '',
      lat: delivery_address?.lat || '',
      lng: delivery_address?.lng || '',
      isVerified: country.toLowerCase() === 'canada',
    };
    this.data = data;
    this.dialogComponentPay?.open();
    this.createPayment()
  }

  createPayment() {
    this.http.post('/create-payment-intent', {
      data: {
        merchId: this.data.merchId,
        personalDetail: this.userData,
        address: this.address
      },
      type: 'merch'
    }).subscribe({
      next: (response: any) => {
        const {status, clientSecret, message, dataStripe, currency} = response;
        if (status === 'error') {
          alert(message)
          this.dialogComponentPay?.closeB();
          return
        }
        this.currency = currency
        this.data.total = dataStripe.total
        this.data.shipping_cost = dataStripe.shipping_cost
        const appearance = {
          theme: 'stripe',
        };
        this.clientSecret = clientSecret;
        const elements = this.stripe.elements({locale: 'en', appearance, clientSecret});
        this.elements = elements;
        const paymentElementOptions = {
          layout: "tabs",
        };
        this.paymentElement = elements.create("payment", paymentElementOptions);
        this.paymentElement.mount("#payment-element");
      },
      error: (err) => {
        alert("Error")
      }
    });
  }

  async checkout() {

    const urlRaiz = window.location.origin;
    const {error} = await this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: urlRaiz + '/gift-center?id=' + this.data.id,
      },
    });


    if (error.type === "card_error" || error.type === "validation_error") {
      this.paymentmessage = (error.message);
    } else {
      this.paymentmessage = ("An unexpected error occurred.");
    }


  }

  changeTab(tab: string) {
    let array = (this.address.address || '').split(' ');
    let country = array[array.length - 1];
    if(tab === 'address' && (this.userData.name.trim()==='' || this.userData.email.trim()==='' || this.userData.phoneNumber.trim()==='')){
      this.enableRequiredInput=true;
      return
    }
    if(tab === 'payForm' && (this.address.address.trim()==='')){
      this.enableRequiredInput=true;
      return
    }

    if(tab === 'payForm' && country.toLowerCase() != 'canada'){
      const customConfig = {
        title: 'Can\'t continue',
        description: 'Shipping is only allowed to Canada!',
        containerClass: 'logout-dialog',
        closeOnPress: false,
        activeButtonCancel: false
      };
      this.alertService.setCustomConfig(customConfig);
      this.alertService.showCustomDialog();
      this.alertService.getIsOKPressed().subscribe(isOK => {
        if (isOK === true) {
          this.alertService.closeDialog()
        }
      });
      return
    }
    this.enableRequiredInput=false;
    if (this.paymentElement != null && tab === 'address') this.paymentElement.unmount();
    if (tab === 'payForm') this.createPayment();
    this.tab = tab;
  }

  async validatePay(clientSecret: string): Promise<[boolean, string, any]> {
    console.log('validando pago')
    const {paymentIntent} = await this.stripe.retrievePaymentIntent(clientSecret);
    if (!paymentIntent) return [false, 'Error Stripe', paymentIntent];
    switch (paymentIntent.status) {
      case "succeeded":
        return [true, '', paymentIntent];
      case "processing":
        return [false, "Your payment is processing.", paymentIntent]
      case "requires_payment_method":
        return [false, "Your payment was not successful, please try again.", paymentIntent]
      default:
        return [false, "Something went wrong.", paymentIntent]
    }
  }

  constructor(private ngZone: NgZone, private deviceInfo: DeviceInfoService, private sanitizer: DomSanitizer, private userInfo: UserInfoService, private router: Router) {
  }

}

