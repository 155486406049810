import { Injectable } from '@angular/core';
import {io} from "socket.io-client";
@Injectable({
  providedIn: 'root'
})
export class SocketService {
  socket: any;

  connect(url: string) {
    return new Promise((resolve, reject) => {
      try {
        this.socket = io(url, { transports: ["websocket"] });

        this.socket.on('connect', () => {
          resolve(true);
        });
      } catch (e) {
        reject(e)
      }
    })
  }
}
