import {Injectable, OnInit} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor() { }

  public sendNotification(title: string, options?: NotificationOptions, onclick?: Function): void {
    if (!('Notification' in window)) {
      console.log('This browser does not support desktop notification');
    } else if (Notification.permission === 'granted') {
      let notification = new Notification(title, options);
      if(typeof onclick === 'function'){
        notification.onclick = (ev) => {
          ev.preventDefault();
          onclick();
        };
      }
    } else if (Notification.permission !== 'denied' && Notification?.requestPermission) {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          new Notification(title, options);
        }
      });
    }
  }
}
