import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Subscription} from "rxjs";
import {UserInfoService} from "../../../../services/user-info/user-info.service";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {DateTime} from "luxon";

@Component({
  selector: 'app-home-sended-gift',
  templateUrl: './home-sended-gift.component.html',
  styleUrls: ['./home-sended-gift.component.scss']
})
export class HomeSendedGiftComponent implements OnInit, OnDestroy, AfterViewInit {
  userInfoService = inject(UserInfoService);
  private router = inject(Router);
  private http = inject(HttpClient);
  statusFetch = false;
  gifts: any = [];
  status = 'all';
  offset = 0
  subscriptionMerch: Subscription | undefined;
  @Input() openEvent: boolean = false;
  @Output() openEventChange = new EventEmitter<boolean>();
  @ViewChild('scrollView') scrollElement: ElementRef | undefined;

  options = [
    {label: "All", value: "all"},
    {label: "Pending Claim", value: "pendingPayment"},
    {label: "Processing", value: "paid|processing"},
    {label: "Received", value: "received"},
    {label: "On its way", value: "onTheWay"},
    {label: "Undelivered", value: "undelivered"},
    {label: "Rejected", value: "rejected"},
  ]

  ngOnInit() {
    this.getGifts(true);
  }

  ngOnDestroy() {
  }

  closeModal() {
    this.openEventChange.emit(false);
  }

  getGifts(clearData: boolean = false) {
    this.subscriptionMerch?.unsubscribe();
    if (clearData) {
      this.statusFetch = true;
      this.gifts = [];
      this.offset = 0;
    }
    if(this.offset%10 > 0 && this.offset > 0) {
      return;
    }
    this.statusFetch = true;
    this.subscriptionMerch = this.http.get(`/shipping`, {
      params: {
        ...(this.status !== 'all' ? {status: this.status} : {}),
        offset: this.offset,
        limit: 10,
        merchIdUserSended: this.userInfoService.me()?.user?.id,
        sortKey: "createDate",
        sort: "desc",
      }
    }).subscribe({
      next: (response: any) => {
        const data = response?.statusmerchs || response
        data.map((row: any) => {
          //row['user_recived']=row['user_recived'][0];
          //row['user_send']=row['user_send'][0];
          row['products'].map((product: any) => {
            product['productData'] = JSON.parse(product['productData']);
          })
          row['statusDate'] = row?.['timeline']?.[0] ? DateTime.fromMillis(row['timeline'][0]['createDate']) :  DateTime.now()
        })
        console.error(data)
        this.gifts = [...this.gifts, ...data]
        this.offset = this.gifts.length;
        this.statusFetch = false;
        this.subscriptionMerch?.unsubscribe();
        this.getTimer()
      }
    });
  }
  intervalDiff: any = null;
  diff:{[key: string]: string} = {}
  getTimer() {
    let self = this;
    this.intervalDiff = setInterval(() => {
      let giftsProcessing = this.gifts.filter((m: any) => {
        let createDate = DateTime.fromMillis(m.createDate).plus({days: 3});
        let today = DateTime.local();

        return createDate >= today && m.status === "pendingPayment";
      });

      giftsProcessing.forEach((m: any) => {
        let createDate = DateTime.fromMillis(m.createDate).plus({days: 3});
        let today = DateTime.local();
        self.diff[m.merchId] = createDate.diff(today, "hours").toFormat('hh:mm:ss');

      });
    }, 1000); // Update every second
  }

  formatDate(date: string, type = 1): string {
    const optionstype = {year: 'numeric', month: 'long', day: 'numeric'};
    const dateObject = new Date(date);
    if (type == 1) return `${dateObject.getMonth() + 1}/${dateObject.getDate()}/${dateObject.getFullYear()}`;
    // @ts-ignore
    return dateObject.toLocaleDateString('en-US', optionstype);
  }

  ngAfterViewInit() {
    // Asegúrate de que el elemento scrollElement existe antes de añadir el manejador de eventos.
    if (this.scrollElement && this.scrollElement.nativeElement) {
      this.scrollElement.nativeElement.addEventListener('scroll', this.handleScroll.bind(this));
    }
  }
  async handleScroll(e: any) {
    if (this.statusFetch) return;
    const scrollTop = e.target.scrollTop;
    const scrollHeight = e.target.scrollHeight;
    const clientHeight = e.target.clientHeight;
    if (scrollTop + clientHeight >= scrollHeight - 100) {
      this.getGifts();
    }
  }

  updateGiftStatus(data: any){
    let index = this.gifts.findIndex((g: any) => g['merchId'] === data['merchId']);
    if(index >= 0){
      this.gifts[index] =  data;
    }
  }

  getStatus(status: string) {
    switch (status) {
      case 'pendingPayment':
        return 'Pending claim';
      case 'received':
        return 'Received';
      case 'paid':
        return 'Processing';
      case 'onTheWay':
        return 'On The Way';
      case 'processing':
        return 'Processing';
      case 'rejected':
        return 'Rejected';
      case 'undelivered':
        return 'Undelivered';
      default:
        return status;
    }
  }
  getStatusName() {
    return this.options.find(x => x.value === this.status)?.label || '--'
  }
  protected readonly JSON = JSON;
}
