import {Component, inject, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {CheckoutComponent} from "../../checkout/checkout.component";
import {Location} from "@angular/common";

@Component({
  selector: 'app-claim-gift',
  templateUrl: './claim-gift.component.html',
  styleUrls: ['./claim-gift.component.scss']
})
export class ClaimGiftComponent implements OnInit {
  @ViewChild(CheckoutComponent, {static: false}) checkoutComponent: CheckoutComponent | undefined;
  router = inject(Router);
  data = {
    gifts: [] as IProductCart[],
    user: {
      id: '',
      username: '',
      name: '',
      email: '',
      phoneNumber: '',
      avatar: '',
    },
    userSend: {
      id: '',
      username: '',
      name: '',
      avatar: '',
    },
    addres: '',
    address: {
      id: '',
      address: '',
      lat: '',
      lng: '',
      isVerified: true,
    },
    message: '',
    subtotal: 0,
    total: 0,
    delivery: 0
  }

  ngOnInit() {
    const data = JSON.parse(localStorage.getItem('claimGift') || '{}')
    console.log(data)
    data.address.id = '';
    this.data = data;
    console.log(data)
  }
  back() {
    if (this.checkoutComponent?.viewEditFormDelivery) this.location.back();
    this.checkoutComponent?.setviewEditFormDelivery(true)
  }
  constructor(private location: Location) {
  }
}
