import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AuthStatus } from '../interfaces';

export const isAuthenticatedGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService)
  const authStatus = authService.authStatus()();
  return authStatus === AuthStatus.authenticated || authStatus===AuthStatus.checking;
};
