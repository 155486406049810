<div class="flex justify-between items-center">
  <p class="font-Poppins font-[600] text-[10px] text-[#171725] ">Member´s {{communityMembersCount}}</p>
  <p class="font-Poppins text-[10px] text-[#0062FF] cursor-pointer" (click)="openDialog()">SEE ALL</p>
</div>
  <div class="flex flex-wrap gap-2 mt-4">
    <img [src]="item.user.avatar" *ngFor="let item of members.slice(0, 12)"  alt="" class="rounded-full w-[45px]">
  </div>
<app-dialog #dialogMembers [title]="'Members'"
            [classHeaderDefault]="'px-1 pt-5 pb-3 text-lg'"
            [classModal]="'sm:!min-w-[200px] sm:!w-[500px] bg-black/80 sm:bg-white text-white sm:text-black sm:relative'">
  <div class="px-[10px]">
    <div *ngFor="let item of members" class="flex gap-4 mb-4 items-center">
      <img [src]="item.user.avatar" alt="" class="rounded-full w-[45px] cursor-pointer"  routerLink="/profile/{{item.user.id}}">
      <div>
        <p class="font-Poppins font-semibold text-[16px]">{{item.user.name}}</p>
        <p class="font-Poppins text-[12px]">{{item.user.username}}</p>
      </div>
    </div>
  </div>
</app-dialog>

