<div
  class="bg-white post-containner overflow-visible rounded-[10px] sm:shadow-[0px_3px_6px_#00000029] relative">
  <div *ngIf="!isNotification" class="flex items-center justify-between pt-4 mb-4 bg-white px-4">
    <div class="flex gap-2 sm:gap-4 container-avatar">
      <div class="relative">
        <img routerLink="/profile/{{postData.pk.replace('up#', '')}}" [src]="postData.avatar" alt="" class="avatar">
        <div *ngIf="postData.is_founder" class="absolute bottom-0 right-0 w-[20px] h-[20px] sm:w-[45%] sm:h-[45%] p-[2px] rounded-[15px] sm:rounded-full bg-white">
          <img src="assets/ogfc.png" alt="" class="object-cover w-full">
        </div>
      </div>
      <div class="flex flex-col justify-center avatar-info">
        <div class="font-Poppins sm:text-[18px] sm:font-medium sm:leading-[1]"
           [class]="{
           'sm:!text-[14px]':isProfile,
           'leading-[1]':postData.postLocation?.address
           }">
          <p>{{ postData.name }}</p>
          <div *ngIf="postData.postLocation?.address" class="text-xs sm:leading-[1] relative inline-block">
            <div class="absolute w-60 h-60 bg-slate-100 rounded-2xl -translate-y-1/2 z-10" id="mapLocation" #mapLocation *ngIf="showMapLocation">
              <div class="absolute top-2 right-2 z-20 bg-white rounded-full p-2" (click)="showMapLocation = false">
                <app-close-icon/>
              </div>
            </div>
            is at <div class="underline text-green-native cursor-pointer inline-block sm:leading-[1]" (click)="showMap()">{{ formatAddress() }}
            </div>
          </div>
<!--          <span *ngIf="postData.verifiedAccount" class="material-symbols-outlined verifiedAccount">-->
<!--          Done-->
<!--          </span>-->
        </div>
        <p class="font-Poppins font-light text-[10px] sm:text-[14px] sm:leading-[1] mt-[5px]"
           [class]="{'sm:!text-[12px]':isProfile}">{{ calculateDateDifference(postData.date) }}</p>
      </div>
    </div>
    <app-om-select id="select-nav" *ngIf="optionsPost?.length"
                   containerClass="!bg-white !py-0 !font-poppins !font-medium shadow-lg text-[13px]]"
                   containerItemsClass="!max-h-fit"
                   itemsClass="nav-select-item !py-1 hover:!bg-white hover:!text-green4-native"
                   color="gray" [options]="optionsPost" [(ngModel)]="option" (ngModelChange)="changeOptionPost()"
                   [buttonTemplate]="true" placeholder=""
                   class="w-[45px] sm:w-[160px] mt-3"
    >
      <ng-container ngProjectAs="buttonAction">
        <div class="flex justify-end">
          <span
            class="hidden sm:block material-symbols-outlined text-[#a9abad] cursor-pointer more-post hover:text-[#519935]">
          more_horiz
        </span>
          <span
            class="block sm:hidden material-symbols-outlined text-[#a9abad] text-[1.7rem] cursor-pointer hover:text-[#519935]">
          more_vert
        </span>
        </div>
      </ng-container>
    </app-om-select>
  </div>
  <hr class="hidden sm:hidden my-2 mx-4 border border-[#EEEEEE]">
  <div *ngIf="!isNotification && !postData.medios?.length">
    <div *ngIf="postData.postType=='news'"
       class="font-Poppins text-[#77838F] mx-4 text-[14px] sm:text-[14px] sm:hidden my-2 text-wrap-post"
       [innerHtml]="convertHashtagsToTags()"></div>
  </div>
  <div *ngIf="!isNotification && postData.postType=='news'"
     class="font-Poppins text-[#77838F] mx-4 text-[14px] sm:text-[14px] hidden sm:block my-2 text-wrap-post"
     [innerHtml]="convertHashtagsToTags()"></div>
  <app-slide-post (openFile)="showCommentDesk()" [isViewFile]="true" [filters]="postData.postFiltersByImage"
                  [files]="postData.medios" *ngIf="postData.medios.length>0 && !isNotification" [isProfile]="isProfile"
                  class="cursor-pointer"></app-slide-post>
  <p *ngIf="!isNotification && postData.postType=='article'"
     class="font-Poppins text-[#000000] mx-4 text-[14px] sm:text-[22px] hidden sm:block font-semibold my-2 my-[15px] leading-[1.2]">{{ postData.title }}</p>
  <div *ngIf="!isNotification && postData.postType=='article'"
     class="font-Poppins text-[#77838F] mx-4 text-[14px] sm:text-[14px] hidden sm:block my-2 text-wrap-post"
     [innerHtml]="convertHashtagsToTags()"></div>
  <app-reactions-bar
    *ngIf="!isNotification"
    class="block sm:hidden my-2 mx-4"
    (clickIconLike)="updateLike()"
    (clickIconSave)="updateSave()"
    (clickIconComment)="showComment()"
    (clickIconShare)="updateShare()"
    [seeLabelsReactions]="false"
    [like]="postData.like"
    [save]="postData.saved"
    [infoPost]="postData"
    [numberReactions]="{
      numberLikes:postData.postLikesCount,
      numberComments:postData.postCommentsCount,
      numberShares:postData.postShareCount,
      numberSaved:postData.postSavedCount,
  }"></app-reactions-bar>
  <hr *ngIf="!isNotification" class="hidden sm:hidden my-2 mx-4 border border-[#EEEEEE]">
  <app-reactions-bar
    *ngIf="!isNotification"
    class="hidden sm:block py-4 sm:mx-[30px]"
    (clickIconLike)="updateLike()"
    (clickIconSave)="updateSave()"
    (clickIconShare)="updateShare()"
    (clickIconComment)="showComment()"
    [seeLabelsReactions]="true"
    [like]="postData.like"
    [save]="postData.saved"
    [infoPost]="postData"
    [numberReactions]="{
      numberLikes:postData.postLikesCount,
      numberComments:postData.postCommentsCount,
      numberShares:postData.postShareCount,
      numberSaved:postData.postSavedCount,
  }"></app-reactions-bar>
  <app-liked-bar *ngIf="!isNotification" class="block sm:hidden px-4" [numberReactions]="{
      lastLikes:postData.lastLikes,
      numberLikes:postData.postLikesCount,
      numberComments:postData.postCommentsCount,
      numberShares:postData.postShareCount,
      numberSaved:postData.postSavedCount,
  }"></app-liked-bar>
  <div *ngIf="!isNotification && postData.medios?.length">
    <p class="font-Poppins text-[#333333] mx-4 mt-2 text-[14px] font-medium sm:hidden">{{ postData.name }}</p>
    <div *ngIf="postData.postType=='news'"
       class="font-Poppins text-[#77838F] mx-4 text-[14px] sm:text-[14px] sm:hidden my-2 text-wrap-post"
       [innerHtml]="convertHashtagsToTags()"></div>
  </div>
  <p *ngIf="!isNotification && postData.postType=='article'"
     class="font-Poppins text-[#000000] mx-4  text-[14px] sm:text-[20px] sm:hidden font-semibold my-2">{{ postData.title }}</p>
  <div *ngIf="!isNotification && postData.postType=='article'"
     class="font-Poppins text-[#77838F] mx-4 text-[14px] sm:text-[14px] sm:hidden my-2 text-wrap-post"
     [innerHtml]="convertHashtagsToTags()"></div>
  <app-comment [avatar]="postData.avatar"
               (clickIconLike)="updateLike()"
               (clickIconSave)="updateSave()"
               [infoPost]="postData"
               [like]="postData.like"
               [save]="postData.saved"
               [isNotification]="isNotification"
               (sendCommentPost)="addComments($event)"
               (moreCommentPost)="getComments()"
               (close)="destroyConection()"
               class="block py-4 container-comments"
               [class]="{
                '!p-0':isNotification
               }"
  >

  </app-comment>
</div>
