import {Component, inject, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {DialogComponent} from "../../../general/dialog/dialog.component";
import {UserInfoService} from "../../../../services/user-info/user-info.service";
import {ChatService} from "../../../../services/chat/chat.service";

@Component({
  selector: 'app-community-request',
  templateUrl: './community-request.component.html',
  styleUrls: ['./community-request.component.scss']
})
export class CommunityRequestComponent {
  @ViewChild('communityRequest', {static: false}) communityRequest!: DialogComponent;
  router = inject(Router);
  http = inject(HttpClient);
  chatService = inject(ChatService)
  private userInfo = inject(UserInfoService);
  data:any=null;
  title='';
  async ngOnInit() {
  }
  openRequest(sk:string){
    this.data=null
    this.title='';
    this.getData(sk)
  }
  getData(sk:string){
    this.http.get('/community/invitation-request',{
      params: { sk}
    }).subscribe({
      next: async (response: any) => {
        if (response.success) {
          if(!response.data.community.success) alert('la comunidad no existe2')
          else{
            response.data.community=response.data.community.data;
            response.data.destinationUser=response.data.destinationUser.data;
            this.data=response.data;
            if(this.data.status==='pending') this.title=this.data.type==='Request'?`${this.data.destinationUser.name} ${this.data.destinationUser.lastname} to join the community.`:`${this.data.community.user.name} ${this.data.community.user.lastname} invites you to join the community.`;
            else{
              if(this.userInfo.isMe(this.data.destinationUserId))
              this.title=this.data.status==='accepted'?`${this.data.community.user.name} ${this.data.community.user.lastname} approved your request.`:`${this.data.community.user.name} ${this.data.community.user.lastname} rejected your request.`;
              else
              this.title=this.data.status==='accepted'?`You accepted ${this.data.destinationUser.name}'s request`:`You rejected ${this.data.destinationUser.name}'s request.`;
            }
            this.communityRequest.open()
          }

        } else {
          alert('la comunidad no existe')
        }
      }
    });

  }
  updateRequest(status:string){
    this.http.post('/community/invitation-request/update', {
      pk : this.data.pk,
      sk:this.data.sk,
      status
    }).subscribe({
      next: (response: any) => {
        const {success,data: dataResponse} = response
        if (success) {
          this.chatService.sendPush(this.data.destinationUserId, {meta:dataResponse.notification.sk,message:dataResponse.notification.message}, 'community');
          this.communityRequest.closeB()
          if(['/communities','/community'].includes(this.router.url.split('?')[0]))window.location.reload();
        } else {
          alert("Error")
        }
      },
      error: (err:any) => {
        alert("Error")
      }
    });
  }
}
