import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-publication-types',
  templateUrl: './publication-types.component.html',
  styleUrls: ['./publication-types.component.scss']
})
export class PublicationTypesComponent {
  @Input() type = 'string';
  @Output() typeChange = new EventEmitter<string>();
  types: string[] = ["string", "media", "gif", "article"];

  onTypeChange(newType: string) {
    this.type = newType;
    this.typeChange.emit(this.type);
  }
}
