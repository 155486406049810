import {AfterViewInit, Component, inject} from '@angular/core';
import {CheckoutServiceService} from "../../../services/checkout-service/checkout-service.service";
import {HttpClient} from "@angular/common/http";
import {OmAlertService} from "../../../om-alert.service";
import {Router} from "@angular/router";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-pay-items',
  templateUrl: './pay-items.component.html',
  styleUrls: ['./pay-items.component.scss']
})
export class PayItemsComponent implements AfterViewInit{
  private alertService= inject(OmAlertService);
  router = inject(Router);
  http = inject(HttpClient);
  config: any;
  // @ts-ignore
  stripe:any=Stripe(environment.pkStripe)
  elements:any;
  clientSecret:string='';
  paymentmessage: string='';
  okPress=false;
  constructor(private checkoutService: CheckoutServiceService) { }
  ngAfterViewInit(){
    this.checkStatus()
  }
  ngOnInit() {
    this.checkoutService.customConfigSubject.subscribe(config => {
      this.config = config;
      if(this.config )
      this.createPayment()
    });
  }
  onClickBtn(status=true) {
    this.checkoutService.isOKPressedSubject.next(status);
    if (this.config?.closeOnPress) this.checkoutService.closeDialog();
  }
  getTotal(){
    let sum=0;
    if(this.config){
      for (const item of this.config.data.courses) {
        sum+=item.publish_cost
      }
    }
    return sum;
  }
  createPayment(){
    const total=this.getTotal()
    console.log(this.config)
    let type = 'Other';
    if(this.config.ids.courses)type='course'
    this.http.post('/create-payment-intent', {type:type,data:this.config.ids}).subscribe({
      next: (response: any) => {
        const {clientSecret} = response
        const appearance = {
          theme: 'stripe',
        };
        this.clientSecret=clientSecret;
        const elements = this.stripe.elements({ locale: 'en',appearance, clientSecret });
        this.elements=elements;
        const paymentElementOptions = {
          layout: "tabs",
        };
        const paymentElement = elements.create("payment", paymentElementOptions);
        paymentElement.mount("#payment-element");
      },
      error: (err) => {
        alert("Error")
      }
    });
  }
  async checkout(){

    const urlRaiz = window.location.origin;
    const {error} = await this.stripe.confirmPayment({
      elements:this.elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: urlRaiz+'/coaching?type=mycourse',
      },
    });


    if (error.type === "card_error" || error.type === "validation_error") {
      this.paymentmessage=(error.message);
    } else {
      this.paymentmessage=("An unexpected error occurred.");
    }


  }
  store(paymentIntent:string){
    this.http.post('/store-purchase', {paymentIntent:paymentIntent}).subscribe({
      next: (response: any) => {
        console.log(response.data)
        if (response.data.type && response.data.type==='course') {
          const courseId = (response.data.courses[0].sk|| response.data.courses[0].courseId).replace('course#','course%23');
          this.router.navigateByUrl(`/course?id=${courseId}`);
        }
        return;
      },
      error: (err) => {
        alert("Error")
      }
    });
  }
  async checkStatus() {
    if(['/gift-center','/claim-gift'].includes(this.router.url.split('?')[0])) return;
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) return;
    const { paymentIntent } = await this.stripe.retrievePaymentIntent(clientSecret);

    switch (paymentIntent.status) {
      case "succeeded":
        this.store(paymentIntent.id);
        break;
      case "processing":
        alert("Your payment is processing.");
        break;
      case "requires_payment_method":
        alert("Your payment was not successful, please try again.");
        break;
      default:
        alert("Something went wrong.");
        break;
    }
  }
}
