import {Component, inject, OnInit} from '@angular/core';
import {CatalogsService} from "../../../../services/catalogs/catalogs.service";

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  catalogsService = inject(CatalogsService);
  categories: ICategory[] =[
    // {
    //   name:'flower',
    //   title:'Flower',
    //   img:'assets/categories/flower.jpg',
    //   banner:'assets/categories/flower.jpg',
    //   route:'/market-search'
    // },
    // {
    //   name:'cbd',
    //   title:'CBD',
    //   img:'assets/categories/cbd.jpg',
    //   banner:'assets/categories/cbd.jpg',
    //   route:'/market-search'
    // },
    // {
    //   name:'edibles',
    //   title:'Edibles',
    //   img:'assets/categories/edibles.jpg',
    //   banner:'assets/categories/edibles.jpg',
    //   route:'/market-search'
    // },
    // {
    //   name:'extracts',
    //   title:'Extracts',
    //   img:'assets/categories/extracts.jpg',
    //   banner:'assets/categories/extracts.jpg',
    //   route:'/market-search'
    // },
    // {
    //   name:'clones',
    //   title:'Clones',
    //   img:'assets/categories/clones.jpg',
    //   banner:'assets/categories/clones.jpg',
    //   route:'/market-search'
    // },
    // {
    //   name:'accessories',
    //   title:'Accessories',
    //   img:'assets/categories/accessories.jpg',
    //   banner:'assets/categories/accessories.jpg',
    //   route:'#'
    // },
    // {
    //   name:'seeds',
    //   title:'Seeds',
    //   img:'assets/categories/seeds.jpg',
    //   banner:'assets/categories/seeds.jpg',
    //   route:'/market-search'
    // },
    // {
    //   name:'Grow Supply',
    //   title:'Grow Supply',
    //   img:'assets/categories/grow_supply.jpg',
    //   banner:'assets/categories/grow_supply.jpg',
    //   route:'#'
    // }
  ];
  ngOnInit() {
    this.getCategories();
  }
  getCategories() {
    this.catalogsService.getAll('categories', '').subscribe({
      next: (response: any) => {
        this.categories = response.records.map((x: any) => {
          return {
            name: x.name.toLowerCase(),
            title: x.name,
            img: x.short_image,
            banner: x.long_image,
            route: '/market-search'
          };
        })
      }
    })
  }
}
