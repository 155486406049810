import {Component, ElementRef, inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ChatService} from "../../../../services/chat/chat.service";
import {UserInfoService} from "../../../../services/user-info/user-info.service";
import {FollowAndFriendService} from "../../../../services/follow-and-friend/follow-and-friend.service";
import uitoolkit from "@zoom/videosdk-ui-toolkit";
import {DeviceInfoService} from "../../../../services/device-info/device-info.service";
// @ts-ignore
import * as interact from 'interactjs/dist/interact.js'
import {Subscription} from "rxjs";
import {ZoomComponent} from "../zoom/zoom.component";

@Component({
  selector: 'app-incomming-call',
  templateUrl: './incomming-call.component.html',
  styleUrls: ['./incomming-call.component.scss']
})
export class IncommingCallComponent implements OnInit, OnDestroy{
  @ViewChild('zoomComponent', {static: false}) video!: ZoomComponent;
  cls ='';
  callInfo: any = null;
  isJoin = false;
  isInCall = false;
  private deviceInfoService = inject(DeviceInfoService);
  private chatService = inject(ChatService);
  private userInfo = inject(UserInfoService);
  private followAndFriendService  = inject(FollowAndFriendService);
  users: any = [];
  user: any = [];
  type: string = ''
  features: any[] = [];
  sessionName: string = '';
  subscriptionConnectSocket: Subscription | undefined;
  subscriptionCall: Subscription | undefined;
  //videoElement: ElementRef | undefined;

  async ngOnInit() {
    this.users = await this.followAndFriendService.getFollowings();
    this.user = await this.userInfo.me()?.user;
    this.subscriptionConnectSocket = this.userInfo.currentUserInfo.subscribe(async data => {
      if (data?.user?.id)
        this.chatService.connection(data?.user?.id);
    });
    this.subscriptionCall = this.chatService.currentZoomCall.subscribe(data => {
      if(this.deviceInfoService.detectMobile()) return;
      this.callInfo = data;
      if(data && data.status === 'reject'){
        if(this.video)
          this.video.closeSession();

        this.callInfo = null;
        this.isInCall= false;
      }
      if(this.callInfo && data && data.status === 'accept'){
        this.features = this.callInfo.type === 'video' ? ['audio', 'video'] : ['audio'];
        this.isInCall = true;
      }
    })
    //this.videoElement = this.video.nativeElement.cloneNode(true);
  }
  ngOnDestroy() {
    if (this.subscriptionConnectSocket)
      this.subscriptionConnectSocket.unsubscribe();
    if (this.subscriptionCall)
      this.subscriptionCall.unsubscribe();
  }
  isOwn() {
     return this.user.id === this.callInfo.iduser_from;
  }
  getText() {
    return `${this.user.id === this.callInfo.iduser_from ? 'Outgoing' : 'Incoming'} ${this.callInfo.type} call`;
  }
  getName() {
    return this.isOwn() ? this.userInfo.getNameByUser(this.callInfo.userTo) : this.userInfo.getNameByUser(this.callInfo.userFrom)
  }
  getNameSession() {
    return !this.isOwn() ? this.userInfo.getNameByUser(this.callInfo.userTo) : this.userInfo.getNameByUser(this.callInfo.userFrom)
  }
  // isCall() {
  //   let callAccepts = this.chatService.calls.filter(a => a['status'] === 'accept');
  //   if(!this.isJoin && callAccepts?.length){
  //     this.isJoin = true;
  //     this.features = callAccepts[0]['type'] === 'video' ? ['audio', 'video'] : ['audio'];
  //     this.sessionName = callAccepts[0]['inbox_id'].toString()
  //     this.type = callAccepts[0]['type'].toString();
  //     //this.joinCall(, callAccepts[0]['inbox_id'].toString(), callAccepts[0]['type'].toString())
  //   }
  //   if(this.isJoin && !callAccepts?.length){
  //     this.isJoin = false;
  //     this.isInCall = false;
  //   }
  //   if(!this.isJoin && !callAccepts?.length){
  //     this.isInCall = true;
  //   }
  //   return this.chatService.calls.filter(a => a['status'] === 'pending')?.length
  // }
  // getNameCall() {
  //   let call = this.chatService.calls[0];
  //   return this.userInfo.getNameByUser(this.users.find((u: any) =>  u.id === call['iduser_from']));
  // }
  // isOwn() {
  //   let call = this.chatService.calls[0];
  //   return this.user.id === call['iduser_from'];
  // }
  // getText() {
  //   let call = this.chatService.calls[0];
  //   return `${this.user.id === call['iduser_from'] ? 'Outgoing' : 'Incoming'} ${call['type']} call`;
  // }
  join() {
    this.chatService.acceptCall(this.callInfo.iduser_to.toString(), this.callInfo.iduser_from.toString(), this.callInfo.inbox_id.toString(), this.callInfo.type.toString());
  }
  reject() {
    this.chatService.rejectCall(this.callInfo.iduser_to.toString(), this.callInfo.iduser_from.toString(), this.callInfo.inbox_id.toString(), this.callInfo.type.toString());
  }
  // joinCall(features: string[], sessionName: string, type = 'audio') {
  //   uitoolkit.closeSession(this.video.nativeElement)
  //   this.cls = `!flex items-center z-50 justify-center bg-white border-2 p-4 rounded-2xl fixed bottom-0 right-0 ${type === 'video' ? '' : 'w-full'}`
  //   let config = {
  //     videoSDKJWT: this.deviceInfoService.generateSignature(
  //       'LcDeSt1oTaOTIFMFsOqlTQ', 'hZYoL39DFx84dxJPX0bOBj3UiOXIuCjvUqag', sessionName, 0, '', ''
  //     ),
  //     sessionName: sessionName,
  //     userName: this.userInfo.getName(),
  //     features: [...features, 'users']// 'share', 'chat', 'users', 'settings'
  //   }
  //   this.video.nativeElement.style.display = "block";
  //   this.video.nativeElement.style.width = '700px';
  //
  //   uitoolkit.joinSession(this.video.nativeElement, config);
  //
  //   uitoolkit.onSessionClosed(() => {
  //     this.closeSession()
  //     this.chatService.rejectCall(this.chatService.calls[0]['iduser_to'].toString(), this.chatService.calls[0]['iduser_from'].toString(), this.chatService.calls[0]['inbox_id'].toString(), this.chatService.calls[0]['type'].toString());
  //   })
  //   window.onbeforeunload = () => {
  //     this.closeSession()
  //     this.chatService.rejectCall(this.chatService.calls[0]['iduser_to'].toString(), this.chatService.calls[0]['iduser_from'].toString(), this.chatService.calls[0]['inbox_id'].toString(), this.chatService.calls[0]['type'].toString());
  //   }
  //
  //   setTimeout(() => {
  //     interact(this.video.nativeElement)
  //       .resizable({
  //         // resize from all edges and corners
  //         edges: { left: true, right: true, bottom: true, top: true },
  //
  //         listeners: {
  //           move (event: any) {
  //             let { x, y } = event.target.dataset
  //
  //             // update the element's style
  //             event.target.style.width = event.rect.width + 'px'
  //             event.target.style.height = event.rect.height + 'px'
  //
  //             // translate when resizing from top or left edges
  //             x += event.deltaRect.left
  //             y += event.deltaRect.top
  //
  //             event.target.style.transform = `translate(${x}px, ${y}px)`
  //
  //             event.target.dataset.x = x
  //             event.target.dataset.y = y
  //           }
  //         },
  //         modifiers: [
  //           // keep the edges inside the parent
  //           interact.modifiers.restrictEdges({
  //             outer: 'parent'
  //           }),
  //
  //           // minimum size
  //           interact.modifiers.restrictSize({
  //             min: { width: 200, height: 50 },
  //             max: { width: 700, height: 800 },
  //           })
  //         ],
  //
  //         inertia: true
  //       })
  //   }, 200)
  // }
  // // Remaining code
  // closeSession() {
  //   uitoolkit.closeSession(this.video.nativeElement);
  //   // if (this.video.nativeElement.parentNode) {
  //   //   this.video.nativeElement.parentNode.replaceChild(this.videoElement, this.video.nativeElement);
  //   // }
  //   // debugger
  //   // if(this.videoElement)
  //   //   this.video.nativeElement = this.videoElement as ElementRef;
  //   this.isInCall = false;
  //   this.cls = '';
  // }
}
