<app-settings-layout>
  <div class="w-full sm:max-w-[1050px] p-6">
<!--    <div class="px-6 my-2">-->
<!--      <app-arrow-left-icon [width]="35" [height]="35" color="#00000066" class="cursor-pointer" (click)="router.navigateByUrl('/profile')"/>-->
<!--    </div>-->
    <div class="rounded-2xl flex flex-col bg-white sm:shadow-lg w-full p-4 sm:p-8">
      <h2 class="font-poppins font-medium text-[#202020] text-[36px]">Overview</h2>
      <hr class="bw-1 border-[##00000014] h-0.5 z-10 my-6">
      <div class="flex justify-between items-center">
        <div class="flex items-center gap-4">
          <img routerLink="/profile" class="inline-block h-16 w-16 rounded-full"
               [src]="user['image_profile'] || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'" alt="Profile Image">
          <div>
            <p class="font-poppins font-normal text-[#202020] text-[16px] flex justify-between">{{userInfo.getName()}}
              <span class="p-1 px-2 text-omgreen-50 font-normal font-poppins bg-[#81cb521f] rounded-lg text-[12px] hidden">PRO</span>
            </p>
            <p class="font-poppins font-normal text-[#898989] text-[14px]">{{user['email']}}</p>
          </div>
        </div>
        <div class="flex flex-col items-end hidden">
          <p class="font-poppins font-normal text-right text-[#202020] text-[14px]">$68.00 per Year</p>
          <p class="font-poppins font-normal text-right text-[#898989] text-[14px]">Autopay on Dec 09, 2021</p>
        </div>
      </div>
      <hr class="bw-1 border-[##00000014] h-0.5 z-10 my-6">
      <div class="flex flex-col gap-6">
        <div class="flex">
          <p class="font-Montserrat font-normal text-[#202020] text-[14px] w-[150px]">Company</p>
          <p class="font-Montserrat font-normal text-[#202020] text-[14px]">{{user['company'] || '--'}}</p>
        </div>
        <div class="flex">
          <p class="font-Montserrat font-normal text-[#202020] text-[14px] w-[150px]">Contact phone</p>
          <p class="font-Montserrat font-normal text-[#202020] text-[14px]">{{user['dial_code'] || '--'}} {{user['phone'] || '--'}}</p>
        </div>
        <div class="flex">
          <p class="font-Montserrat font-normal text-[#202020] text-[14px] w-[150px]">Country</p>
          <p class="font-Montserrat font-normal text-[#202020] text-[14px]">{{user['countryName'] || '--'}}</p>
        </div>
        <div class="flex">
          <p class="font-Montserrat font-normal text-[#202020] text-[14px] w-[150px]">Time Zone</p>
          <p class="font-Montserrat font-normal text-[#202020] text-[14px]">{{user['time_zone'] || '--'}}</p>
        </div>
      </div>
      <div class="bg-[#F9F9F9] rounded-lg p-4 mt-8 flex items-start justify-start gap-2">
        <app-secure-icon [width]="30" [height]="30" color="#519935"></app-secure-icon>
        <div class="flex flex-col gap-2">
          <p class="font-Montserrat font-normal text-[#202020] text-[16px]">
            Secure Your Account
          </p>
          <p class="font-Montserrat font-normal text-[#202020] text-[14px] text-[#5A5A5A]">
            Two-factor authentication adds an extra layer of security to your account. To log in, in addition you'll need to provide a 6 digit code.
            <a href="#" class="text-[#519935] underline">Learn more.</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</app-settings-layout>
