import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-search-icon',
  templateUrl: './search-icon.component.svg',
  styleUrls: ['./search-icon.component.scss']
})
export class SearchIconComponent {
  @Input() color = '#292D32';
  @Input() width = 24;
  @Input() height = 24;
}
