<div class="w-full relative shadow-sm rounded-lg">
  <div
    class=" rounded-t-2xl bg-cover bg-center w-full h-[9rem] cursor-pointer"
    [style.background-image]="'url(' + data.communityImgCover + ')'"
    [routerLink]="'/community'" [queryParams]="{ id: data.communityId }"
  ></div>
  <div class="py-5 px-3 flex flex-col gap-2 justify-between h-[160px] sm:h-auto">
    <div class="flex flex-col gap-2">
      <p class="font-semibold font-Montserrat text-[14px] cursor-pointer" [routerLink]="'/community'" [queryParams]="{ id: data.communityId }">{{ data.communityName }}</p>
      <div class="flex gap-4">
        <p class="font-normal font-Montserrat text-[14px] text-[#92929D]">{{ data.communityCategory }}</p>
        <p class="flex items-center gap-2 font-normal font-Montserrat text-[14px] text-[#92929D]">{{data.communityMembersCount}} <span class="hidden sm:block">Members</span></p>
      </div>
    </div>
    <div *ngIf="enableActions">
      <button
        *ngIf="data.isMember===1"
        class="bg-[#F1F1F5] text-[#696974] font-Montserrat font-semibold text-[12px] w-full px-4 py-2 mt-2 rounded-lg" [routerLink]="'/community'" [queryParams]="{ id: data.communityId }">
        Go to community
      </button>
      <button
        *ngIf="data.isMember===0 && (data.communityPrivacy==='Public' || isMe())"
        class="bg-[#F1F1F5] text-[#696974] font-Montserrat font-semibold text-[12px] w-full px-4 py-2 mt-2 rounded-lg" (click)="joinCommunity()">
        Join
      </button>
      <button
        *ngIf="data.isMember===0 && data.communityPrivacy!='Public' && !isMe()"
        class="bg-[#F1F1F5] text-[#696974] font-Montserrat font-semibold text-[12px] w-full px-4 py-2 mt-2 rounded-lg" (click)="applyJoin()">
        Apply to Join
      </button>
    </div>
  </div>
  <app-dialog-alert class="absolute z-[70]"></app-dialog-alert>
</div>
