import {Component, inject, OnInit} from '@angular/core';
import {CatalogsService} from "../../../../services/catalogs/catalogs.service";

@Component({
  selector: 'app-cards-effects',
  templateUrl: './cards-effects.component.html',
  styleUrls: ['./cards-effects.component.scss']
})
export class CardsEffectsComponent implements OnInit {
  effects: ICatalogView[] = [];
  catalogsService = inject(CatalogsService);

  ngOnInit() {
    this.getEffects()
  }

  getEffects() {
    this.catalogsService.getAll('effects', '').subscribe({
      next: (response: any) => {
        this.effects = [...this.effects, ...response.records.map((x: any) => {
          return {
            name: x.name.toLowerCase(),
            title: x.name,
            img: x.short_image,
            banner: x.long_image,
            route: '/market-search'
          };
        })]
      }
    })
  }
}
