<div [ngClass]="classContainer">
  <div class="flex items-center justify-between pt-4 mb-4 bg-white px-4">
    <div class="flex gap-4 container-avatar">
      <img [src]="avatar" class="avatar">
      <div class="flex flex-col justify-center avatar-info">
        <p class="username">{{name}}
<!--          <span *ngIf="verifiedAccount" class="material-symbols-outlined verifiedAccount">-->
<!--          Done-->
<!--          </span>-->
        </p>
        <p class="font-SF-Pro-Text text-[#77838F]  hidden sm:block date">{{calculateDateDifference(date)}}</p>
        <p class="font-SF-Pro-Text text-[#77838F] sm:hidden date">@{{username}}</p>
      </div>
    </div>
    <span class="hidden sm:block material-symbols-outlined text-[#a9abad] cursor-pointer more-post hover:text-[#519935]">
      more_horiz
    </span>
      <span class="block sm:hidden material-symbols-outlined text-[#a9abad] text-[1.7rem] cursor-pointer hover:text-[#519935]">
      more_vert
    </span>
  </div>
  <hr class="hidden sm:block my-2 border-[#EEEEEE]  mx-4">
  <p class="font-SF-Pro-Text sm:font-Poppins text-[#77838F] sm:text-black description-post  px-4">
    {{description}}
  </p>
  <hr class="hidden sm:block my-2 border-[#EEEEEE] mx-4">
  <app-reactions-bar
    class="block py-2 px-4"
    [seeLabelsReactions]="true"
    [numberReactions]="{
      numberLikes:10,
      numberComments:110,
      numberShares:1,
      numberSaved:5,
  }" ></app-reactions-bar>
  <app-liked-bar class="block sm:hidden px-4"></app-liked-bar>

</div>
