import {Component, inject} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {CheckoutServiceService} from "../../../services/checkout-service/checkout-service.service";
import {Location} from "@angular/common";

@Component({
  selector: 'app-course-details',
  templateUrl: './course-details.component.html',
  styleUrls: ['./course-details.component.scss']
})
export class CourseDetailsComponent {
  private checkoutService= inject(CheckoutServiceService);
  private http = inject(HttpClient);
  openMenu=false
  activeChapter=0
  activeTopic=0
  data:any={}
  title='';
  description='';
  topics:any=[
  ]
  user:any={
  }
  totalTime='';
  totalVideos=0;
  openDescription=true;
  openRequirements=true;
  openChapter(index: number, index2: number) {
    if(this.isActiveChapter(index,index2)){
      this.activeChapter=index2
      this.activeTopic=index
      this.openMenu=false
    }
  }
  constructor(private route: ActivatedRoute,private router: Router, private location: Location) {}
  ngOnInit() {
    window.scrollTo(0, 1);
    this.route.queryParams.subscribe(params => {
      if (params['id']) {
        this.getData(params['id']);
      }else{
        this.router.navigate(['/coaching']);
      }
    });
  }
  goBack() {
    this.location.back();
  }
  getData(id:string){
    this.http.get('/courses',{
      params: { id}
    }).subscribe({
      next: async (response: any) => {
        if (response.data) {
          this.title = response.data?.name;
          this.description = response.data?.description || '';
          this.topics = response.data?.chapters || [];
          this.user = response.data?.user;
          this.data = response.data;
          let totalTime=0

          for (const [index, row] of this.topics.entries()) {
            let timeTopic=0
            this.topics[index]['is_open']=false
            for (const [index1, rowClase] of row.classes.entries()) {
              this.topics[index].classes[index1]['time'] = await this.getVideoDuration(rowClase.url)
              totalTime+=this.topics[index].classes[index1]['time'] ;
              timeTopic+=this.topics[index].classes[index1]['time'];
              this.totalVideos++;
              this.topics[index].classes[index1]['time'] = this.timeFormart(this.topics[index].classes[index1]['time'])
            }
            this.topics[index]['time']= this.timeFormart(timeTopic)
          }
          this.totalTime=this.timeFormart(totalTime);
          this.topics[0].is_open=true
        } else {
          this.router.navigate(['/coaching']);
        }
      }
    });

  }
  expandAll(){
    for (const [index, row] of this.topics.entries()) {
      this.topics[index]['is_open']=true
    }
  }
  async  getVideoDuration(url: string) {
    const video = document.createElement('video');
    video.src = url;
    await new Promise<void>((resolve, reject) => {
      video.onloadedmetadata = () => resolve();
      video.onerror = error => reject(error);
      video.load();
    });
    return video.duration;
    //return this.timeFormart(video.duration)
  }
  timeFormart(duration:number){
    const durationInSeconds = Math.floor(duration);
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = durationInSeconds % 60;
    let durationString = '';
    if (hours > 0) durationString += `${hours} hour${hours > 1 ? 's' : ''} `;
    if (minutes > 0) durationString += `${minutes} minute${minutes > 1 ? 's' : ''} `;
    if(seconds > 1)  durationString += `${seconds} second${seconds > 2 ? 's' : ''}`;
    return durationString;
  }
  isLastVideo(){
    if(this.activeTopic < this.topics.length-1) return false;
    return !(this.activeChapter < this.topics[this.activeTopic].classes.length-1)
  }
  isActiveChapter(indexTopic:number, indexChapter:number){
    if(this.data.purchased) return true;
    return indexTopic == 0 && indexChapter == 0;
  }
  nextVideo(){
    if(this.activeChapter==this.topics[this.activeTopic].classes.length-1) this.openChapter(this.activeTopic+1,0)
    else this.openChapter(this.activeTopic,this.activeChapter+1)
  }
  splitTeaching() {
    let teaching=this.data.teaching||[];
    const partLength = Math.ceil(teaching.length / 2);
    const result = [];
    let start = 0;
    for (let i = 0; i < 2; i++) {
      result.push(teaching.slice(start, start + partLength));
      start += partLength;
    }
    return result;
  }
  save(){
    this.data.saved=!this.data.saved
  }
  openPay(){
    let customConfig:any={
      createPayment:true,
      data:{
        courses:[]
      },
      ids:{
        courses:[]
      }
    }
    customConfig.data.courses.push(this.data)
    customConfig.ids.courses.push({sk:this.data.sk,pk:this.data.pk})
    this.checkoutService.setCustomConfig(customConfig);
    this.checkoutService.showCustomDialog();

  }
}
