import {Component, HostListener, inject, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {Location} from "@angular/common";
import {HttpClient} from "@angular/common/http";
import {ReviewComponent} from "../../general/review/review.component";
import {PayGiftComponent} from "../pay-gift/pay-gift.component";
import {OmAlertService} from "../../../om-alert.service";
import {Subscription} from "rxjs";
import {UserInfoService} from "../../../services/user-info/user-info.service";
import {DateTime} from "luxon";

@Component({
  selector: 'app-gift-center',
  templateUrl: './gift-center.component.html',
  styleUrls: ['./gift-center.component.scss']
})
export class GiftCenterComponent implements OnInit {
  router = inject(Router);
  http = inject(HttpClient);
  userInfoService = inject(UserInfoService);
  @ViewChild(ReviewComponent, {static: false}) reviewComponent: ReviewComponent | undefined;
  @ViewChild(PayGiftComponent, {static: false}) payGiftComponent: PayGiftComponent | undefined;
  private alertService = inject(OmAlertService);
  sended = []
  statusFetch = false;
  received = []
  tab = 'received';
  status = 'all'
  time = 'This week'
  options = [
    {label: "All", value: "all"},
    {label: "Pending Claim", value: "pendingPayment"},
    {label: "Processing", value: "paid|processing"},
    {label: "Received", value: "received"},
    {label: "On its way", value: "onTheWay"},
    {label: "Undelivered", value: "undelivered"},
    {label: "Rejected", value: "rejected"},
  ]

  getStatusName() {
    return this.options.find(x => x.value === this.status)?.label || '--'
  }

  optionsTime = [
    {label: "All", value: "all"},
    {label: "This week", value: "This week"},
    {label: "Last week", value: "Last week"},
    {label: "This month", value: "This month"},
    {label: "Last month", value: "Last month"},
  ]

  getTimeName() {
    return this.optionsTime.find(x => x.value === this.time)?.label || '--'
  }

  gifts: { [key: string]: any }[] = [];

  constructor(private location: Location) {
  }

  ngOnInit() {
    window.scrollTo(0, 1);
    this.getGifts()
    this.openFormPayGift();
  }

  async openFormPayGift() {
    const payment_intent = new URLSearchParams(window.location.search).get("payment_intent");
    console.log(payment_intent)
    const id = new URLSearchParams(window.location.search).get("id");
    if (!payment_intent) return;
    this.http.post('/store-purchase', {paymentIntent: payment_intent}).subscribe({
      next: (response: any) => {
        const {status, message} = response
        if (status === 'ok') {
          const customConfig = {
            title: 'Paid gift',
            description: 'Successful payment',
            containerClass: 'logout-dialog',
            closeOnPress: false,
            activeButtonCancel: false
          };
          this.alertService.setCustomConfig(customConfig);
          this.alertService.showCustomDialog();
          this.alertService.getIsOKPressed().subscribe(isOK => {
            if (isOK === true) {
              this.alertService.closeDialog()
              this.router.navigateByUrl('/gift-center')
            }
          });

        } else {
          alert(message)
        }
      }
    });
  }

  goBack() {
    this.location.back();
  }

  review(data: any, type: string) {
    this.reviewComponent?.openForm(data, type, data['reviewId'] || '');
  }

  openStripe(data: any) {
    this.payGiftComponent?.openForm(data);
  }

  subscriptionMerch: Subscription | undefined;

  getGifts(clearData: boolean = false) {
    this.subscriptionMerch?.unsubscribe();
    if (clearData) this.gifts = [];
    this.statusFetch = true;
    this.subscriptionMerch = this.http.get(`/shipping`, {
      params: {
        ...(this.status !== 'all' ? {status: this.status} : {}),
        offset: this.gifts.length,
        limit: 10,
        merchType: this.tab === "offered" ? "giftSended" : "giftClaimed|gift",
        merchIdUserRecived: this.userInfoService.me()?.user?.id,
        sortKey: "createDate",
        sort: "desc",
      }
    }).subscribe({
      next: (response: any) => {
        const data = response?.statusmerchs || response
        data.map((row: any) => {
          //row['user_recived']=row['user_recived'][0];
          //row['user_send']=row['user_send'][0];
          row['products'].map((product: any) => {
            product['productData'] = JSON.parse(product['productData']);
          })
          row['statusDate'] = row?.['timeline']?.[0] ? DateTime.fromMillis(row['timeline'][0]['createDate']) :  DateTime.now()
        })
        console.error(data)
        this.gifts = [...this.gifts, ...data]
        this.statusFetch = false;
        this.subscriptionMerch?.unsubscribe();
        if(this.tab == "offered"){
          this.getTimer()
        }
      }
    });
  }
  intervalDiff: any = null;
  diff:{[key: string]: string} = {}
  getTimer() {
    let self = this;
    this.intervalDiff = setInterval(() => {
        let giftsProcessing = this.gifts.filter((m: any) => {
            let createDate = DateTime.fromMillis(m.createDate).plus({days: 3});
            let today = DateTime.local();

            return createDate >= today && m.status === "pendingPayment";
        });

        giftsProcessing.forEach((m: any) => {
            let createDate = DateTime.fromMillis(m.createDate).plus({days: 3});
            let today = DateTime.local();
            self.diff[m.merchId] = createDate.diff(today, "hours").toFormat('hh:mm:ss');

        });
    }, 1000); // Update every second
  }

  formatDate(date: string, type = 1): string {
    const optionstype = {year: 'numeric', month: 'long', day: 'numeric'};
    const dateObject = new Date(date);
    if (type == 1) return `${dateObject.getMonth() + 1}/${dateObject.getDate()}/${dateObject.getFullYear()}`;
    // @ts-ignore
    return dateObject.toLocaleDateString('en-US', optionstype);
  }
  rejectedGift(merchId: string) {
    this.userInfoService.changeGift({merchId, isRejected: true});
  }
  updateGift(data: any) {
    const id = data.originSk.replace('merch#', '')
    const index = this.gifts.findIndex(objet => objet['id'] === id);
    if (index != -1) this.gifts[index]['reviewId'] = data.data.sk
  }

  updateGiftStatus(data: any){
    let index = this.gifts.findIndex(g => g['merchId'] === data['merchId']);
    if(index >= 0){
      this.gifts[index] =  data;
    }
  }

  @HostListener('window:scroll', ['$event'])
  async endScroll() {
    if (this.statusFetch) return;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    if (scrollTop + clientHeight >= scrollHeight - 100) {
      this.getGifts();
    }
  }

  actionCard(item: any, action: string) {
    if (this.tab != 'offered') return
    switch (action) {
      case 'pendingPayment':
        this.openStripe(item)
        break
      case 'received':
        this.review(item, 'gift')
        break
    }
  }

  protected readonly JSON = JSON;
}
