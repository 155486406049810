import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {LabelType, Options} from "ngx-slider-v2";

@Component({
  selector: 'app-range-price',
  templateUrl: './range-price.component.html',
  styleUrls: ['./range-price.component.scss']
})
export class RangePriceComponent {
  @Input() minValue: number = 0;
  @Output() minValueChange = new EventEmitter<number>();
  @Input() maxValue: number = 500;
  @Output() maxValueChange = new EventEmitter<number>();
  @Input() initValue: number = 100;
  @Output() initValueChange = new EventEmitter<number>();
  @Input() endValue: number = 400;
  @Output() endValueChange = new EventEmitter<number>();
  @Input() type:string='price';

  options: Options = {
    floor: 0,
    ceil: 500,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '<b>Min:</b> $' + value;
        case LabelType.High:
          return '<b>Max:</b> $' + value;
        default:
          return '';
      }
    }
  };
  ngOnInit(){
    this.options.floor=this.minValue;
    this.options.ceil=this.maxValue;
    if(this.type!='price'){
      this.options.translate=(value: number, label: LabelType): string => {
        switch (label) {
          case LabelType.Low:
            return '<b>Min:</b> ' + value + '%';
          case LabelType.High:
            return '<b>Max:</b> ' + value + '%';
          default:
            return '';
        }
      }
    }
  }

  initChange() {
    this.initValueChange.emit(this.initValue)
  }

  endChange() {
    this.endValueChange.emit(this.endValue)
  }
}
