import {Component, inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {UserInfoService} from "../../../../services/user-info/user-info.service";
import {CarouselComponent} from "../../../general/carousel/carousel.component";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-coaching-feature',
  templateUrl: './coaching-feature.component.html',
  styleUrls: ['./coaching-feature.component.scss']
})
export class CoachingFeatureComponent implements OnChanges, OnDestroy {
  @ViewChild('carousel') carousel: CarouselComponent | undefined;
  @Input() title = 'Featured Courses';
  @Input() type = '';
  @Input() classTitle: any[] = [];
  data: any = [];
  user: any = null;

  private http = inject(HttpClient);
  private userInfo = inject(UserInfoService);
  statusFetch = false;
  is_init = true;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['type']) {
      this.data = [];
      this.getData();
    }
  }

  subscriptionData: Subscription | undefined;

  getData() {
    this.statusFetch = true;
    const params: any = {offset: this.data.length, limit: 8, type: this.type};
    if (this.type === '') {
      params['sortKey'] = 'val_ranked';
      params['sortType'] = 'desc';
    }
    this.subscriptionData = this.http.get('/courses', {
      params
    }).subscribe({
      next: (response: any) => {
        this.data = [...this.data, ...response.records];
        this.statusFetch = false;
        if (this.is_init) {
          this.is_init = false
        }
        this.carousel?.updatedData()
        this.subscriptionData?.unsubscribe()
      }
    });

  }

  ngOnDestroy() {
    this.subscriptionData?.unsubscribe();
  }
}
