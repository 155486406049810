import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable, computed, inject, signal} from "@angular/core";
import {Observable, catchError, map, of, tap, throwError, switchMap, interval} from "rxjs";
import {environment} from "src/environments/environment";
import {AuthStatus, CheckTokenResponse, LoginResponse, User, RegisterForm, RegisterResponse} from '../interfaces';
import {Router} from "@angular/router";
import {SocialAuthService} from "@abacritt/angularx-social-login";
import {UserInfoService} from "../../services/user-info/user-info.service";
import {ChatService} from "../../services/chat/chat.service";


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  router = inject(Router);
  chatService = inject(ChatService);
  userInfoService = inject(UserInfoService);
  private readonly baseUrl: string = environment.baseUrl;
  private http = inject(HttpClient);
  private _currentUser = signal<User | null>(null);
  private _authStatus = signal<AuthStatus>(AuthStatus.checking);

  public currentUser = computed(() => this._currentUser);
  public authStatus = computed(() => this._authStatus);

  constructor(private socialAuthService: SocialAuthService) {
    this.initAuthStatus();
  }

  private initAuthStatus(): void {
    this.checkAuthStatus().subscribe()
    interval(60000).subscribe(_ => this.checkAuthStatus().subscribe());
  }

  private setAuthentication(user: User, token: string,setAuth:boolean=true): Observable<boolean> {
    this._currentUser.set(user);
    localStorage.setItem('token', token);
    return this.userInfoService.getMe2(token).pipe(
      tap(r => {
        if(setAuth){
          localStorage.setItem('user', JSON.stringify(r));
          this.userInfoService.user = r;
          this._authStatus.set(AuthStatus.authenticated);
          this.userInfoService.changeUserInfo(r);
        }
      }),
      map(() => true),
      catchError(() => of(false))
    );
  }

  register(data: RegisterForm): Observable<boolean> {
    const url = `/auth/user`;
    return this.http.post<RegisterResponse>(url, data).pipe(
      tap(({token}) => {
        // Called method from UserInfoService
        this._authStatus.set(AuthStatus.checking);
        this.userInfoService.getMe2(token).subscribe({
          next: () => this._authStatus.set(AuthStatus.authenticated),
          error: () => this._authStatus.set(AuthStatus.notAuthenticated)
        });
      }),
      switchMap(({user, token}) => this.setAuthentication(user, token)),
      catchError(err => throwError(err.error.message || 'Try again later.'))
    );
  }

  login(email: string, password: string,setAuth:boolean=true): Observable<boolean> {
    const url = `/auth/login`;
    const body = {email, password};
    return this.http.post<LoginResponse>(url, body).pipe(
      switchMap(({user, token}) => {
        try {
          if (this._authStatus) {
            this._authStatus.set(AuthStatus.checking);
          }
        } catch (e) {
        }
        return this.setAuthentication(user, token,setAuth).pipe(
          switchMap(() => this.userInfoService.getMe2(token)),
          tap(() => {
            if(setAuth) this._authStatus.set(AuthStatus.authenticated);
          }),
          catchError(error => {
            return throwError(() => 'Incorrect user or password');
          })
        );
      }),
      catchError(err => {
        return throwError(() => 'Error occured while login');
      })
    );
  }

  loginGoogle(info: any) {
    return this.setAuthentication(info, info.idToken)
  }

  logout() {
      this.socialAuthService.signOut(true).then(r => {
      }).finally(() => {
        this.chatService.disconnect();
        localStorage.removeItem('token');
        localStorage.removeItem('fws');

        localStorage.removeItem('fw');
        localStorage.removeItem('user');
        this._currentUser.set(null);
        this._authStatus.set(AuthStatus.notAuthenticated);
        const currentUrlWithoutParams = this.router.url.split('?')[0].split('#')[0];
        const urlsGuest = ['/', '/register', '/forgot-password', '/terms-and-conditions', '/login', '/founders-club'];
        if (!urlsGuest.includes(currentUrlWithoutParams))
          this.router.navigateByUrl("/")
      });

  }

  checkAuthStatus(): Observable<boolean> {
    const url = `/auth/check-token`;
    const token = localStorage.getItem('token');
    if (!token) {
      this.logout();
      return of(false);
    }

    const headers = new HttpHeaders({'Authorization': `Bearer ${token}`});

    return this.http.get<CheckTokenResponse>(url, {headers}).pipe(
      switchMap(({user, token}) => this.setAuthentication(user, token)),
      tap(() => this._authStatus.set(AuthStatus.authenticated)),
      catchError(error => {
        this._authStatus.set(AuthStatus.notAuthenticated);
        return of(false);
      })
    );
  }

}
