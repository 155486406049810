<div class="bg-white rounded-lg friend-card">
  <div class="h-[91px] overflow-hidden relative">
    <img [src]="user['banner'] || 'assets/demo/paisaje.webp'" class="w-full rounded-t-lg" alt="">
    <div class="bg-white px-1 py-1 rounded-lg absolute top-2 right-2 cursor-pointer">
      <app-envelop-icon [width]="22" (click)="openChat()"></app-envelop-icon>
    </div>
  </div>
  <div class="rounded-b-lg flex items-end justify-end relative">
    <div class="absolute top-[-25px] left-[10px]">
      <img (click)="goToUser()"
        [src]="user['image_profile'] || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'"
        alt="" class="w-[71px] h-[71px] border-[4px] border-[#fff] rounded-full object-cover	">
    </div>
    <div class="w-[calc(100%-70px)] p-4">
      <div class="flex items-start justify-between">
        <div class="flex flex-col pl-2 pr-1" (click)="goToUser()">
          <h1 class="text-[15px] truncate max-w-[70px] font-semibold text-[#171725] font-Montserrat">{{ user['name'] || user['username'] }}</h1>
          <p class="text-[10px] text-[#696974] pt-1 truncate text-ellipsis overflow-hidden w-[60px] sm:w-[80px]">@{{ user['username'] }}</p>
        </div>
        <div class="flex items-center justify-end gap-2 pr-2 pl-1">
          <ng-container *ngIf="isFollowing">
            <p routerLink="/gift-send"
              class="text-[12px] font-Montserrat bg-[#519935] font-poppins font-medium sm:text-[10px] text-[#FFFFFF] bw-1 border-[#519935] rounded-xl px-2.5 py-1">
              Send gift</p>
            <p (click)="unfollow()"
              class="text-[12px] font-Montserrat bg-[#FFFFFF] font-poppins font-medium sm:text-[10px] text-[#519935] bw-1 border-[#519935] rounded-xl px-2.5 py-1">
              Following</p>
          </ng-container>
          <ng-container *ngIf="!isFollowing">
            <p (click)="follow()"
              class="text-[12px] font-Montserrat font-poppins font-medium sm:text-[12px] bg-[#519935] text-[#fff] bw-1 border-[#519935] rounded-xl px-2.5 py-1">
              Follow</p>
            <p
              class="text-[12px] font-Montserrat bg-[#FFFFFF] font-poppins font-medium sm:text-[12px] text-[#ED2B2A] bw-1 border-[#ED2B2A] rounded-xl px-2.5 py-1">
              Block</p>
          </ng-container>
        </div>
      </div>
      <div *ngIf="!isRequest" class="text-[#92929D] text-[12px] font-Montserrat p-2 pt-4 min-h-[50px] max-h-[50px] overflow-hidden" [innerHTML]="user.about_me.slice(0, 80) + '...'">
      </div>
    </div>
    <div class="flex items-center justify-center">
      <div class="w-[80%] shadow-lg">
      </div>
    </div>
  </div>
  <div *ngIf="isRequest"  class="flex gap-2 justify-center pb-4 ">
    <div (click)="addFriendRequest('accepted')" class="w-[40%] flex items-center justify-center h-[35px] rounded-lg cursor-pointer shadow border text-[#519935]">Accept</div>
    <div (click)="addFriendRequest('rejected')" class="w-[40%] flex items-center justify-center h-[35px] rounded-lg cursor-pointer shadow border text-[tomato]">Deny</div>
  </div>
</div>
