import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-post-add-location',
  templateUrl: './post-add-location.component.html',
  styleUrls: ['./post-add-location.component.scss']
})
export class PostAddLocationComponent {
  @Input() open: boolean = false;
  @Output() openChange = new EventEmitter<boolean>();
  onOpenChange() {
    this.openChange.emit(this.open);
  }
  @Input() location: { lng: number, lat: number, address: string } | null = null;
  @Output() locationChange = new EventEmitter<{ lng: number, lat: number, address: string } | null>()
  setAccept(data: any) {
    this.location = data;
    this.locationChange.emit(this.location)
    this.open = false;
  }
}
