<div class="sm:flex pt-[15px] sm:pt-[25px]">
    <div class="w-full  sm:w-[352px]">
      <div class="flex items-center justify-center w-full h-[263px] sm:h-[427px]">
        <img class="w-full h-full object-contain" [src]="data.images[imgOpen]" alt=""/>
      </div>
      <div *ngIf="data.images.length>1" class=" ocultarScrollXNoVisible px-[10px] sm:px-0 mb-[15px] sm:mb-0">
        <div class="inline-flex gap-2 sm:gap-0">
          <div class="flex sm:block sm:mb-4 w-[100px] h-[72px] sm:w-[100px] sm:h-[85px] rounded-2xl border border-[#707070]" *ngFor="let img of data.images; let indexOfelement=index;">
            <div class="flex items-center justify-center w-full h-full rounded-2xl cursor-pointer" (click)="imgOpen=indexOfelement">
              <img class="w-full h-full object-contain rounded-2xl" [src]="img" alt=""/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hidden sm:block sm:w-[calc(100%-352px)]">
      <div class="sm:w-[calc(100%-70px)] sm:ml-[70px] sm:shadow-[3px_3px_30px_-11px_#42445A63] sm:rounded-[25px] px-[60px] py-[25px]">
        <app-product-header class="block mt-[22px] sm:mt-0" [isInfo]="data.user.username === 'Info strain'" [name]="data.name" [val_reviews]="data.val_reviews" [count_reviews]="data.count_reviews" [genetics]="data.genetics" [type]="data.type" [thc]="data.thc" [cdb]="data.cdb"></app-product-header>
        <app-product-effects [effects]="data.effects" [mine]="mine"></app-product-effects>
        <div class="flex justify-center" *ngIf="!mine && data.user.username !== 'Info strain'">
          <button
            class="mt-7 sm:ml-10 flex justify-center items-center bg-green-native text-white rounded-full text-center w-[200px] sm:w-[304px] h-[54px] text-[17px] sm:text-[25px] font-Montserrat font-bold"
            (click)="claimGift()">
            Claim Gift
          </button>
        </div>
      </div>
    </div>
</div>
<div class="bg-[#FAFAFA] sm:bg-white rounded-t-2xl sm:rounded-none border-[0.5px] border-[#979797] sm:border-none">
  <div class="sm:hidden ">
    <app-product-header class="block " [name]="data.name" [val_reviews]="data.val_reviews" [count_reviews]="data.count_reviews" [genetics]="data.genetics" [type]="data.type" [thc]="data.thc" [cdb]="data.cdb"></app-product-header>
    <app-product-description class="block sm:hidden px-[20px] sm:px-0 mt-[20px] sm:mt-[22px]" [description]="data.description"></app-product-description>
    <app-product-effects class="block px-[20px]" [effects]="data.effects" [mine]="mine"></app-product-effects>
    <div class="flex sm:hidden justify-center my-[15px]" *ngIf="!mine">
      <button
        class="mt-7 sm:ml-10 flex justify-center items-center bg-green-native text-white rounded-full text-center w-[200px] sm:w-[304px] h-[54px] text-[17px] sm:text-[25px] font-Montserrat font-bold !m-0"
        (click)="claimGift()">
        Claim Gift
      </button>
    </div>
  </div>

  <app-product-description class="hidden sm:block px-[20px] sm:px-0 sm:mt-[22px]" [description]="data.description"></app-product-description>
  <div class="px-[20px] sm:px-0 sm:flex mt-[20px] sm:mt-[40px]">
    <app-product-grower [user]="data.user" class="block sm:hidden px-[20px]"></app-product-grower>
    <div class="sm:w-[580px] shadow-[0px_3px_6px_#00000029] px-[10px] mt-[20px] py-[20px] sm:mt-0 rounded-[15px] sm:px-[45px] sm:py-[30px] sm:rounded-[25px]">
      <app-product-grow-info [grow_information]="data.grow_information"></app-product-grow-info>
    </div>
    <div class="sm:ml-[40px] sm:w-[375px] sm:py-[30px]">
      <app-product-grower [user]="data.user" class="hidden sm:block"></app-product-grower>
      <app-product-flavours-terpenes class="block mt-5" [flavors]="data.flavors" [terpenes]="data.terpenes"></app-product-flavours-terpenes>
    </div>
  </div>

  <app-growers class="block px-[20px] sm:px-0 mt-[30px] sm:mt-[40px]" [classTitle]="['!text-[17px]','!sm:text-[20px]']"></app-growers>
  <app-reviews-list class="block mt-[30px] sm:mt-[40px] px-[20px] sm:px-0" classHeader="!mb-[0px] sm:!mb-[10px] !px-0"
                    classContainer="w-[calc(100vw-40px)] sm:w-[calc(100%-0px)] pr-[15px] sm:pr-0" [pk]="'product#'+data.id"></app-reviews-list>

  <app-cards-filter class="block mt-[30px] px-[20px] sm:px-0 sm:mt-[40px]" [classTitle]="['!text-[17px]','!sm:text-[20px]']"></app-cards-filter>
</div>
