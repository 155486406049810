import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-comment-info',
  templateUrl: './comment-info.component.html',
  styleUrls: ['./comment-info.component.scss']
})
export class CommentInfoComponent {
  @Input() comment: any = [];
  reply=false;
  calculateDateDifference(date: string): string {
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const inputDate = new Date(date)
    const currentDate = new Date();
    const timeDifferenceInMilliseconds = inputDate.getTime() - currentDate.getTime();
    const seconds = Math.floor(Math.abs(timeDifferenceInMilliseconds) / 1000);

    if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      return `${minutes}m`;
    } else if (seconds < 86400) {
      const hours = Math.floor(seconds / 3600);
      return `${hours}h`;
    } else if (seconds < 691200) {
      const days = Math.floor(seconds / 86400);
      return `${days}d`;
    } else {
      const day = inputDate.getDate();
      const month = inputDate.getMonth(); // Month is zero-based, so add 1
      const year = inputDate.getFullYear();
      return `${monthNames[month]} ${day.toString().padStart(2, '0')}, ${year}`;
    }
  }
}
