import {Component, inject, Input} from '@angular/core';
import {FollowAndFriendService} from "../../../../services/follow-and-friend/follow-and-friend.service";
import {Router} from "@angular/router";
import {UserInfoService} from "../../../../services/user-info/user-info.service";

@Component({
  selector: 'app-product-grower',
  templateUrl: './product-grower.component.html',
  styleUrls: ['./product-grower.component.scss']
})
export class ProductGrowerComponent {
    @Input()
    set user(value: any) {
        this._user = value;
        this.nameUser = this.userInfoService.getNameByUser(this._user)
        this.validIsFollowing();
    }

    get user() {
        return this._user;
    }

  private _user: any = {};
  nameUser = '';
  private followAndFriends = inject(FollowAndFriendService);
  private userInfoService = inject(UserInfoService);
  private router = inject(Router);
  isFollowing= false;
  ngOnInit() {
    this.validIsFollowing();
  }
  async validIsFollowing() {
    this.isFollowing = await this.followAndFriends.isFollowing(this.user?.id);
  }
  changeFollow()
  {
    if(this.isFollowing) this.unfollow();
    else this.follow()
  }
  async follow() {
    await this.followAndFriends.add(this.user?.id);
    await this.followAndFriends.getFollowings(true);
    await this.validIsFollowing();
  }
  async unfollow() {
    await this.followAndFriends.delete(this.user?.id);
    await this.followAndFriends.getFollowings(true);
    await this.validIsFollowing();
  }
}
