<div class="px-0 pl-0 h-full flex flex-col justify-between
      pt-[calc((var(--mw-height)*0))]
      bg-black bg-cover sm:bg-none bg-center
      sm:h-[75vh] sm:w-[36.6vw] sm:bg-[#2e2e2e] sm:mt-[calc(var(--w-height)*10)]
      sm:mr-[calc(var(--w-height)*15)] sm:rounded-t-[calc(var(--w-height)*2] overflow-hidden
      sm:px-[calc(var(--w-width)*0)] sm:py-0 sm:rounded-[2vw]">
  <div
    class="bg-[url('assets/general/cannabis1.jpg')] bg-cover bg-center md:rounded-tl-10 md:rounded-tr-10 cover3 ">
    <ng-icon name="matArrowBack" class="text-white my-4 mx-4 text-4xl inline-block sm:!hidden mt-[calc(var(--sat)+ 16px)]" (click)="previous()"></ng-icon>
  </div>
  <div
  class="w-full flex justify-center items-center flex-col pb-[calc(var(--mw-height)*3.3]">
    <div class="h-10 sm:h-10 flex items-center justify-center gap-[calc(var(--mw-height)*2)]">
      <span class="flex w-[calc(var(--mw-height)*1.7)] h-[calc(var(--mw-height)*1.7)]
            sm:w-[calc(var(--mw-height)*1.5)] sm:h-[calc(var(--mw-height)*1.5)] rounded-full"
            [class]="{'bg-green-native': currentIndex === 0, 'bg-white': currentIndex !== 0}"
            (touchend)="goToSlide(0)" (touchstart)="goToSlide(0)" (click)="goToSlide(0)"></span>
      <span class="flex w-[calc(var(--mw-height)*1.7)] h-[calc(var(--mw-height)*1.7)]
            sm:w-[calc(var(--mw-height)*1.5)] sm:h-[calc(var(--mw-height)*1.5)] bg-white rounded-full"
            [class]="{'bg-green-native': currentIndex === 1, 'bg-white': currentIndex !== 1}"
            (touchend)="goToSlide(1)" (touchstart)="goToSlide(1)" (click)="goToSlide(1)"></span>
      <span class="flex w-[calc(var(--mw-height)*1.7)] h-[calc(var(--mw-height)*1.7)]
            sm:w-[calc(var(--mw-height)*1.5)] sm:h-[calc(var(--mw-height)*1.5)] bg-white rounded-full"
            [class]="{'bg-green-native': currentIndex === 2, 'bg-white': currentIndex !== 2}"
            (touchend)="goToSlide(2)" (touchstart)="goToSlide(2)" (click)="goToSlide(2)"></span>
    </div>
    <div class="h-36">
      <p class="title-form text-center text-[calc(var(--w-height)*3)] !font-extrabold
        px-[calc(var(--mw-width)*10)]
        pt-[calc(var(--mw-width)*4)]
        sm:pt-[calc(var(--w-width)*0)]
        sm:px-[calc(var(--w-width)*5)]
        sm:mt-[calc(var(--w-width)*0)]
        sm:text-[calc(var(--w-height)*3.3)]">
        {{slides[currentIndex]?.title}}</p>
      <p class="text-white text-center mt-[calc(var(--mw-height)*3)]
        sm:mt-[calc(var(--w-height)*2)] sm:text-white
        text-[calc(var(--mw-height)*1.9)]
        mb-[calc(var(--mw-height)*4)]
        px-[calc(var(--mw-height)*5)]
        sm:px-[calc(var(--w-height)*14)]
        leading-[calc(var(--mw-height)*2.4)]
        sm:text-[calc(var(--w-height)*2)]">
        {{slides[currentIndex]?.message}}</p>
    </div>
  </div>
  <div class="w-full flex flex-col items-center gap-3
        py-[calc(var(--mw-height)*0)] px-[calc(var(--mw-height)*1)]
        pb-[calc(var(--mw-height)*2)]
        sm:pb-[calc(var(--w-height)*2)]
        sm:mt-[calc(var(--w-height)*2)]">
    <app-om-button [text]="'Skip'"
                   class="w-full"
                   [bgColor]="'#ffffff00'"
                   [height]="{xs:'7',sm:'6'}"
                   [fontSize]="{xs:'2.7',sm:'2.6'}"
                   [marginTop]="{xs:'1.5',sm:'0'}"
                   [iconPosition]="'right'"
                   [marginBottom]="{xs:'1.5',sm:'1'}"
                   [iconClass]="'arrowskip'"
                   (click)="sendDataC()">
      <img alt="flecha" [class]="'md:hidden w-[calc(var(--mw-height)*2.4)] sm:w-[calc(var(--w-height)*3.5)]'" src="assets/general/flecha.png">
      <img alt="flecha" [class]="'hidden md:block w-[calc(var(--mw-height)*3)] sm:w-[calc(var(--w-height)*3.5)]'" src="assets/general/flecha_larga.png">
    </app-om-button>
    <div class="sm:absolute sm:right-[calc(var(--w-height)*32)] sm:bottom-0 sm:mb-[calc(var(--w-height)*3)] sm:text-[calc(var(--w-height)*2)]">
      <p class="text-[calc(var(--mw-height)*1.6)] sm:text-[calc(var(--w-height)*1.85)] text-white font-semibold text-center pb-0">By using our platform, you agree to our</p>
      <p class="text-[calc(var(--mw-height)*1.6)] sm:text-[calc(var(--w-height)*1.85)] text-green-native text-center font-extrabold cursor-pointer" routerLink="/terms-and-conditions">Terms of Service.</p>
    </div>
  </div>
</div>
