import {Component, ElementRef, inject, ViewChild} from '@angular/core';
import {DeviceInfoService} from "../../../services/device-info/device-info.service";
import {data} from "autoprefixer";
import {DialogComponent} from "../../general/dialog/dialog.component";
import {UserInfoService} from "../../../services/user-info/user-info.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastService} from "../../../services/toast/toast.service";
import {FollowAndFriendService} from "../../../services/follow-and-friend/follow-and-friend.service";
import {ChatService} from "../../../services/chat/chat.service";

@Component({
  selector: 'app-new-community',
  templateUrl: './new-community.component.html',
  styleUrls: ['./new-community.component.scss']
})
export class NewCommunityComponent {
  @ViewChild('changePic', {static: false}) changePic!: DialogComponent;
  @ViewChild('inputChangePic') inputChangePic: ElementRef | undefined;
  private deviceInfo = inject(DeviceInfoService);
  router = inject(Router);
  http = inject(HttpClient);
  toastService = inject(ToastService);
  followAndFriendService = inject(FollowAndFriendService);
  chatService = inject(ChatService)
  openMenu = false
  loading = false;
  tagPeople: any[] = [];
  user: any = {}
  data: any = {
    id: '',
    communityPrivacy: 'Public',
    communityCategory: '',
    communityName: '',
    communityDescription: '',
  }
  communityImgProfile = ''
  communityImgCover = ''
  type: string = '';
  validateForm: boolean = false;
  today: string = '';
  imageChangedEvent: any = null;
  aspectRatio: number = 1
  widthImage: number = 1
  croppedImage: string | null = null;
  friends: any[] = [];
  categories = [
    {label: 'Networking', value: 'Networking'},
    {label: 'Education', value: 'Education'},
    {label: 'Partnerships', value: 'Partnerships'},
    {label: 'Events', value: 'Events'},
    {label: 'Partnerships', value: 'Partnerships'},
  ]

  constructor(private route: ActivatedRoute, private userInfo: UserInfoService) {
  }


  async ngOnInit() {
    window.scrollTo(0, 1);
    this.user = this.userInfo.me().user;
    this.friends = await this.followAndFriendService.getFollowings() as any[];
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const currentDate = new Date();
    this.today = `${currentDate.getDate()} ${monthNames[currentDate.getMonth()]} ${currentDate.getFullYear()}`;
    this.route.queryParams.subscribe(params => {
      if (params['id']) {
        this.getData(params['id']);
      }
    });
  }

  getData(id: string) {
    this.http.get('/communities', {
      params: {id}
    }).subscribe({
      next: async (response: any) => {
        if (response.data) {
          console.log(response.data)
          if (response.data.pk != 'up#' + this.user.id) this.router.navigate(['/communities']);
          this.data.id = response.data.communityId;
          this.data.communityPrivacy = response.data.communityPrivacy;
          this.data.communityCategory = response.data.communityCategory;
          this.data.communityName = response.data.communityName;
          this.data.communityDescription = response.data.communityDescription;
          this.communityImgCover = response.data.communityImgCover;
          this.communityImgProfile = response.data.communityImgProfile;
        } else {
          this.router.navigate(['/communities']);
        }
      }
    });

  }

  goBack() {
    this.deviceInfo.goBack();
  }

  imageCropped(event: any) {
    if (event.base64 != null) {
      this.croppedImage = event.base64;
    }
  }

  cropperReady() {
    if (this.croppedImage === null) return;
    const ext = this.deviceInfo.extractContentTypeFromBase64(this.croppedImage).split('/')[1];
    let name = (Math.random() * 1000).toFixed(0) + `imag.${['gif', 'png', 'jpg', 'jpeg', 'mp4'].includes(ext) ? ext : 'png'}`;
    if (this.type === 'communityImgProfile') this.communityImgProfile = this.croppedImage;
    else this.communityImgCover = this.croppedImage;
    this.updateImg(this.type === 'communityImgProfile' ? this.communityImgProfile : this.communityImgCover, this.type)
    //this.data[this.type] = this.deviceInfo.dataURLtoFile(this.croppedImage.replace('data:', ''), name)
    this.croppedImage = '';
    this.imageChangedEvent = '';
    this.changePic.closeB();
  }

  closeCropper() {
    this.croppedImage = '';
    this.imageChangedEvent = '';
    this.changePic.closeB();
  }

  selectImagePic(type: string) {
    this.type = type;
    this.aspectRatio = this.type === 'communityImgProfile' ? 1 : 19 / 4;
    this.widthImage = this.type === 'communityImgProfile' ? 120 : 500;
    this.inputChangePic?.nativeElement.click();
  }

  onChangePic() {
    this.imageChangedEvent = {target: {files: this.inputChangePic?.nativeElement.files}}
    this.changePic.open();
  }

  async save() {
    this.validateForm = true;
    if (
      this.data.communityPrivacy === '' ||
      this.data.communityCategory === '' ||
      this.data.communityName === '' ||
      this.data.communityDescription === '' ||
      this.communityImgProfile === '' ||
      this.communityImgCover === ''
    ) return;

    let formData: FormData = new FormData();
    formData.append('communityPrivacy', this.data.communityPrivacy);
    formData.append('communityCategory', this.data.communityCategory);
    formData.append('communityName', this.data.communityName);
    formData.append('communityDescription', this.data.communityDescription);


    for (const fileCommunity of [this.communityImgProfile, this.communityImgCover]) {
      const ext = this.deviceInfo.extractContentTypeFromBase64(fileCommunity).split('/')[1];
      let name = (Math.random() * 1000).toFixed(0) + `imag.${['gif', 'png', 'jpg', 'jpeg', 'mp4'].includes(ext) ? ext : 'png'}`;
      const file: any = this.deviceInfo.dataURLtoFile(fileCommunity.replace('data:', ''), name)
      formData.append(`images`, file);

    }

    this.loading = true;
    this.http.post('/community/add', formData).subscribe({
      next: async (response: any) => {
        const {success} = response
        if (success) {
          this.data.id = response.community.communityId;
          this.data.communityPrivacy = response.community.communityPrivacy;
          this.data.communityCategory = response.community.communityCategory;
          this.data.communityName = response.community.communityName;
          this.data.communityDescription = response.community.communityDescription;
          this.communityImgCover = response.community.communityImgCover;
          this.communityImgProfile = response.community.communityImgProfile;
          await this.sendInvitations();
          this.loading = false;
          await this.router.navigateByUrl('/community?id=' + response.community.communityId)

        } else {
          alert("Error")
          this.loading = false;
        }
      },
      error: (err: any) => {
        alert("Error")
        this.loading = false;
      }
    });
  }

  async update() {
    this.validateForm = true;
    if (
      this.data.id === '' ||
      this.data.communityPrivacy === '' ||
      this.data.communityCategory === '' ||
      this.data.communityName === '' ||
      this.data.communityDescription === ''
    ) return;

    let formData: FormData = new FormData();
    formData.append('idcommunity', this.data.id);
    formData.append('communityPrivacy', this.data.communityPrivacy);
    formData.append('communityCategory', this.data.communityCategory);
    formData.append('communityName', this.data.communityName);
    formData.append('communityDescription', this.data.communityDescription);

    this.loading = true;
    this.http.put('/community/update', {
      idcommunity: this.data.id,
      communityPrivacy: this.data.communityPrivacy,
      communityCategory: this.data.communityCategory,
      communityName: this.data.communityName,
      communityDescription: this.data.communityDescription,
    }).subscribe({
      next: async (response: any) => {
        const {success} = response
        if (success) {

          await this.sendInvitations();
          console.log('end update')
          this.loading = false;
          this.toastService.show('Community updated successfully');

        } else {
          alert("Error")
          this.loading = false;
        }
      },
      error: (err: any) => {
        alert("Error")
        this.loading = false;
      }
    });
  }

  updateImg(file: any, type: string) {
    if (this.data.id === '') return;
    let formData: FormData = new FormData();
    formData.append('idcommunity', this.data.id);
    formData.append('image', file);
    const ext = this.deviceInfo.extractContentTypeFromBase64(file).split('/')[1];
    let name = (Math.random() * 1000).toFixed(0) + `imag.${['gif', 'png', 'jpg', 'jpeg', 'mp4'].includes(ext) ? ext : 'png'}`;
    const fileData: any = this.deviceInfo.dataURLtoFile(file.replace('data:', ''), name)
    formData.append(`image`, fileData);
    this.http.post(type === 'communityImgProfile' ? '/community/change-img-profile' : '/community/change-img-banner', formData).subscribe({
      next: (response: any) => {
        const {success} = response
        if (success) {
          this.toastService.show('Image community updated successfully');
          this.loading = false;
        } else {
          alert("Error")
          this.loading = false;
        }
      },
      error: (err: any) => {
        alert("Error")
        this.loading = false;
      }
    });
  }

  setTagPeople(val: any) {
    this.tagPeople = val;
  }

  sendInvitations(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        for (const user of this.tagPeople) {
          await this.invitedJoin(user.id);
        }
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  invitedJoin(destinationUserId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post('/community/apply-join', {
        id: this.data.id,
        destinationUserId: destinationUserId,
        type: 'Invitation'
      }).subscribe({
        next: (response: any) => {
          const {success, data, info} = response;
          if (success) {
            this.chatService.sendPush(destinationUserId, {
              meta: data.notification.sk,
              message: data.notification.message
            }, 'community');
            resolve(data);
          } else {
            resolve(info);
          }
        },
        error: (err: any) => {
          reject(err);
        }
      });
    })
  }
}
