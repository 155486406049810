import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class StoriesService {
  http = inject(HttpClient)
  stories: any = []

  constructor() {
  }

  getStories(isUpdate: boolean = false) {
    return new Promise((resolve, reject) => {
      try {
        let stories = JSON.parse(localStorage.getItem('stories') || '[]')
        if (stories?.length && !isUpdate) {
          this.stories = stories;
          return resolve(stories);
        }

        this.http.get('/stories',).subscribe({
          next: (response: any) => {
            this.stories = response?.data?.data || response;
            localStorage.setItem("stories", JSON.stringify(this.stories));
            resolve(this.stories);
          }
        });
      } catch (e) {
        reject(e)
      }
    });
  }
}
