import {Component, inject, Input} from '@angular/core';
import {UserInfoService} from "../../../../services/user-info/user-info.service";
import {CheckoutServiceService} from "../../../../services/checkout-service/checkout-service.service";

@Component({
  selector: 'app-card-course',
  templateUrl: './card-course.component.html',
  styleUrls: ['./card-course.component.scss']
})
export class CardCourseComponent {
  private checkoutService= inject(CheckoutServiceService);
  @Input() course:any={
    category: "",
    count_reviews: 0,
    count_solded : 0,
    createDate: "",
    description: "",
    images:[],
    initial_cost:0,
    language: "Spanish",
    name:"",
    pk:"",
    publish_cost:0,
    requirements:"",
    sk: "",
    val_ranked: 0,
    val_reviews: 0,
    countUser: 0,
    user:{} as any,
    purchased:false,
  }
  constructor(private userInfo: UserInfoService){}
  getCountRainting(){
    return this.course.count_reviews>999?((this.course.count_reviews/1000)+'K'):this.course.count_reviews;

  }
  getCountUsers(){
    return this.course.count_solded >999?((this.course.count_solded / 1000)+'K') : this.course.count_solded;
  }
  isMe(){
    return this.userInfo.isMe(this.course.pk.replace('uc#',''))
  }
  openPay(){
    let customConfig:any={
      createPayment:true,
      data:{
        courses:[]
      },
      ids:{
        courses:[]
      }
    }
    customConfig.data.courses.push(this.course)
    customConfig.ids.courses.push({sk:this.course.sk,pk:this.course.pk})
    this.checkoutService.setCustomConfig(customConfig);
    this.checkoutService.showCustomDialog();

  }
}
