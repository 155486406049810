import {Component, EventEmitter, inject, NgZone, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {DialogComponent} from "../dialog/dialog.component";
import {GiftService} from "../../../services/gift/gift.service";
import {HttpClient} from "@angular/common/http";
import {DeviceInfoService} from "../../../services/device-info/device-info.service";
import {DomSanitizer} from "@angular/platform-browser";
import {UserInfoService} from "../../../services/user-info/user-info.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit, OnDestroy {
  Editor = ClassicEditor;
  http = inject(HttpClient);
  @ViewChild(DialogComponent, {static: false}) dialogComponent: DialogComponent | undefined;
  @Output() sendData = new EventEmitter<any>();
  cat_interests = []
  type: string = '';
  idReview: string = ''
  reviewData: any = {}
  loading = false;
  review = ''
  qualification = 0
  files: any = []
  data: any = {}
  errorSize = false;
  limit = 2

  async openForm(data: any, type: string, idReview: string = '') {
    this.idReview = ''
    this.reviewData = {}
    this.review = ''
    this.qualification = 0
    this.files = []
    this.data = data;
    console.log(data)
    this.type = type;
    this.idReview = idReview
    if (this.idReview) this.getReview();
    else this.dialogComponent?.open();
  }

  subscriptionInterests: Subscription | undefined

  getInterests() {
    this.cat_interests = [];
    this.subscriptionInterests = this.http.get('/common/interests').subscribe({
      next: (response: any) => {
        this.cat_interests = response.reduce((acc: any, curr: any) => {
          acc[curr.id] = curr.name;
          return acc;
        }, {});
        this.subscriptionInterests?.unsubscribe();
      }
    });
  }

  subscriptionReview: Subscription | undefined

  getReview() {
    this.subscriptionReview = this.http.get(`/reviews`, {
      params: {
        pk: this.type === 'gift' ? 'product#' + this.data.products[0].productId : this.data.sk,
        originSk: this.type === 'gift' ? 'merch#' + this.data.id : this.data.coursePurchased.sk,
        filterUser: 1
      }
    }).subscribe({
      next: (response: any) => {
        this.reviewData = response.data.reviews[0];
        this.dialogComponent?.open();
        this.subscriptionReview?.unsubscribe();
      }
    });
  }

  async handleFiles(files: FileList): Promise<void> {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (this.files.length === this.limit) {
        return;
      }

      const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
      if (file.size > maxSizeInBytes) {
        this.errorSize = true;
        continue;
      }
      const readFile = (file1: File): Promise<string | undefined> => {
        return new Promise<string | undefined>((resolve) => {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            resolve(e.target.result as string | undefined);
          };
          reader.readAsDataURL(file1);
        });
      };
      if (file.type.startsWith('image/')) {
        const resp = await readFile(file);
        if (resp !== undefined) {
          this.files.push({type: 'image', src: resp});
        }
      }
    }

  }

  onFileSelected(event: any): void {
    this.errorSize = false;
    const files: FileList = event.target.files;
    this.handleFiles(files);
  }

  subscriptionReviewSave: Subscription | undefined

  async save() {
    if (this.review == '' || this.qualification == 0) return;
    let originSk = '';
    let pk = '';
    switch (this.type) {
      case 'gift':
        pk = 'product#' + this.data.products[0].productId
        originSk = 'merch#' + this.data.id;
        break;
      case 'course':
        pk = this.data.coursePurchased.courseId
        originSk = this.data.coursePurchased.sk;
        break;
      default:
        console.log(`Option invalidate`);
        return;
    }
    let formData: FormData = new FormData();
    formData.append('pk', pk);
    formData.append('originSk', originSk);
    formData.append('type', this.type);
    formData.append('qualification', this.qualification.toString());
    formData.append('review', this.review);

    for (let item in this.files) {
      const ext = this.extractContentTypeFromBase64(this.files[item].src).split('/')[1];
      let name = this.randomName(10) + `imag${item}.${['gif', 'png', 'jpg', 'jpeg', 'mp4'].includes(ext) ? ext : 'png'}`;
      let blob = this.deviceInfo.dataURLtoFile(this.files[item].src.replace('data:', ''), name)
      if (blob)
        formData.append(`images`, blob, name);
    }
    this.loading = true;
    this.subscriptionReviewSave = this.http.post('/review', formData).subscribe({
      next: (response: any) => {
        const {success} = response
        if (success) {
          this.sendData.emit({data: response.review, originSk});
          this.dialogComponent?.closeB();
          this.loading = false;
        } else {
          alert("Error")
          this.loading = false;
        }
        this.subscriptionReviewSave?.unsubscribe();
      },
      error: (err) => {
        alert("Error")
        this.loading = false;
      }
    });
  }

  extractContentTypeFromBase64(base64Data: string): string {
    const match = base64Data.match(/^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    return match ? match[1] : '';
  }

  randomName(num: number) {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let result = "";
    let ch;
    while (result.length < num) {
      ch = characters.charAt(Math.floor(Math.random() * charactersLength));
      if (!result.includes(ch)) result += ch;
    }
    return result;
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  constructor(private ngZone: NgZone, private deviceInfo: DeviceInfoService, private sanitizer: DomSanitizer, private userInfo: UserInfoService) {
  }

  ngOnInit(): void {
    this.getInterests()
  }

  ngOnDestroy() {
    this.subscriptionInterests?.unsubscribe();
    this.subscriptionReview?.unsubscribe();
    this.subscriptionReviewSave?.unsubscribe();
  }
}
