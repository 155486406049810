import {Component, effect, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-category-banner',
  templateUrl: './category-banner.component.html',
  styleUrls: ['./category-banner.component.scss']
})
export class CategoryBannerComponent implements OnInit {

    protected readonly effect = effect;
    @Input() categories=[
    {
      name:'flower',
      title:'Flower',
      img:'assets/categories/flower.jpg',
      banner:'assets/categories/flower.jpg',
      route:'/market-search'
    },
    {
      name:'cdb',
      title:'CDB',
      img:'assets/categories/cbd.jpg',
      banner:'assets/categories/cbd.jpg',
      route:'/market-search'
    },
    {
      name:'edibles',
      title:'Edibles',
      img:'assets/categories/edibles.jpg',
      banner:'assets/categories/edibles.jpg',
      route:'/market-search'
    },
    {
      name:'extracts',
      title:'Extracts',
      img:'assets/categories/extracts.jpg',
      banner:'assets/categories/extracts.jpg',
      route:'/market-search'
    },
    {
      name:'clones',
      title:'Clones',
      img:'assets/categories/clones.jpg',
      banner:'assets/categories/clones.jpg',
      route:'/market-search'
    },
    {
      name:'accessories',
      title:'Accessories',
      img:'assets/categories/accessories.jpg',
      banner:'assets/categories/accessories.jpg',
      route:'#'
    },
    {
      name:'seeds',
      title:'Seeds',
      img:'assets/categories/seeds.jpg',
      banner:'assets/categories/seeds.jpg',
      route:'/market-search'
    },
    {
      name:'grow supply',
      title:'Grow Supply',
      img:'assets/categories/grow_supply.jpg',
      banner:'assets/categories/grow_supply.jpg',
      route:'#'
    }
  ];
    types=[
      {
        name:'sativa',
        title:'Sativa',
        img:'assets/demo/sativa-demo.jpeg',
        banner:'assets/demo/sativa-demo.jpeg',
        route:'#'
      },
      {
        name:'indica',
        title:'Indica',
        img:'assets/demo/indica-demo.jpeg',
        banner:'assets/demo/indica-demo.jpeg',
        route:'#'
      },
      {
        name:'hybrid',
        title:'Hybrid',
        img:'assets/demo/hyrid-demo.jpeg',
        banner:'assets/demo/hyrid-demo.jpeg',
        route:'#'
      },
      {
        name:'cbd',
        title:'CBD',
        img:'assets/demo/cbd-demo.jpeg',
        banner:'assets/demo/cbd-demo.jpeg',
        route:'#'
      }
    ];
    indexCategory=0
    type2=''
    constructor(private route: ActivatedRoute) {}
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const type = params['type'];
      const indexfindObject = this.categories.findIndex(object => object.name === type);

      if (indexfindObject !== -1) {
        this.indexCategory=indexfindObject
      }
      if (params['type2']) {
        this.type2=params['type2'];
      }
    });
  }

  protected readonly JSON = JSON;
}
