<div class="w-screen h-screen fixed top-0 left-0 flex justify-center items-center bg-[#0000005c] backdrop-opacity-40 z-[70]" *ngIf="visable">
  <div class=" dialog relative bg-white min-h-full min-w-full sm:min-w-[50vw] sm:min-h-[10vh] sm:max-w-[1190px] rounded-lg  sm:overflow-hidden" [class]="classModal">
    <div class="dialog-header relative">
      <ng-content select="header"></ng-content>
      <div *ngIf="!useTemplateHeader" class="flex relative min-h-[30px]" [class]="classHeaderDefault">
        <p class="font-semibold" [class]="classTitle" [innerHTML]="title"></p>
      </div>
      <div *ngIf="!useTemplateHeader" class="absolute flex items-center h-full top-0 right-4">
        <span class="material-symbols-outlined text-[1.6rem] text-[#707070] cursor-pointer" [class]="classCloseButton" (click)="closeB()">close</span>
      </div>
    </div>
    <div #divElement class="dialog-body max-h-[90vh] overflow-auto" [class]="classBody" (scroll)="endScroll()">
      <ng-content></ng-content>
    </div>
  </div>
</div>
