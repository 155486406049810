<div class="w-full px-4">
<app-register-publication (savedPost)="addPost($event)"></app-register-publication>
</div>
<div class="px-4">
  <app-publication *ngFor="let item of posts"
    class="block sm:mt-3"
    (deletePost)="deletePost($event)"
    [data]="{
      is_founder:item.is_founder===1,
      pk:item.pk,
      postId:item.postId,
      postType:item.postType,
      title:item.postTitle,
      avatar:item.avatar,
      name:item.name,
      date:item.createDate,
      description:item.postContent,
      medios:item.postUrls,
      lastLikes:item.lastLikes || [],
      postLikesCount:item.postLikesCount,
      postSavedCount:item.postSavedCount || 0,
      postShareCount:item.postShareCount || 0,
      postLocation:item.postLocation || null,
      postHashtack:item.postHashtack || 0,
      postFiltersByImage:item.postFiltersByImage || [],
      verifiedAccount:true,
      saved:item.saved,
      like:item.like,
      postCommentsCount:item.postCommentsCount,
      save:item['save']
    }">

  </app-publication>
  <app-not-found [allowBackToHome]="false" message="No posts found :(" *ngIf="!posts?.length"/>
</div>

