import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-cost-list',
  templateUrl: './cost-list.component.html',
  styleUrls: ['./cost-list.component.scss']
})
export class CostListComponent {
  @Input() classFont='font-Montserrat';
  @Input() labelSubtotal='Gift';
  @Input() currency='';
  @Input() subTotal=0;
  @Input() isSend= false;
  @Input() total=0;
  @Input() shipping=0;

  ngOnInit() {
    window.scrollTo(0, 1);
  }
}
