// click-outside.directive.ts

import {Directive, ElementRef, Output, EventEmitter, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appClickOutside]'
})
export class ClickOutsideDirective {
  @Input() excludeElementIds: string[] = []; // Nuevo parámetro para los IDs de los elementos a excluir
  @Input() excludeElementClass: string[] = [];
  @Output() appClickOutside = new EventEmitter();

  constructor(private el: ElementRef) {}

  @HostListener('document:click', ['$event'])
  public onClick(event: any): void {
    const clickedInside = this.el.nativeElement.contains(event.target);
    const isExcludedId = this.isExcludedElement(event.target.id);
    const isExcludedClass = this.isExcludedClass(event.target.classList);
    if (!clickedInside && !isExcludedId && !isExcludedClass) this.appClickOutside.emit();
  }

  isExcludedElement(id: string): boolean {
    return this.excludeElementIds.includes(id);
  }
  isExcludedClass(classList: DOMTokenList): boolean {
    for (const excludedClass of this.excludeElementClass)
      if (classList.contains(excludedClass)) return true;
    return false;
  }
}
