<app-dialog [visable]="detailVisable"  classModal="sm:w-[1000px] pt-[var(--sat)]" (close)="closeDialog($event)" [useTemplateHeader]="true">
  <header class="">
    <div class="flex sm:hidden items-center justify-between px-2 py-4">
      <span class="material-symbols-outlined text-[1.5rem] text-[#707070] cursor-pointer" (click)="pressCloseButton()">arrow_back</span>
      <p class="text-[#292D2B] text-[16px] font-poppins font-semibold">Strain Details</p>
      <img src="assets/icons-sax/shop2.svg" class="w-[16px] opacity-0 sm:opacity-100" alt=""/>
    </div>
    <div class="hidden sm:flex items-center justify-end mr-6 mt-4">
      <span class="material-symbols-outlined text-[30px] text-[#707070] cursor-pointer" (click)="pressCloseButton()">close</span>
    </div>
  </header>
  <div class="sm:max-h-[90vh] overflow-auto pb-12 px-[0px] sm:px-[60px]">
    <app-product-detail *ngIf="detailVisable" [data]="data"></app-product-detail>
  </div>
  <!--<app-detail *ngIf="detailVisable" [data]="data"></app-detail>-->
</app-dialog>
