<div class="flex gap-2 sm:gap-4 items-center mb-[20px]">
  <h1 class="text-[20px] sm:text-[25px] font-semibold font-Montserrat">Categories</h1>
  <p class="flex items-center text-green2-native mt-[3px] text-[15px] font-medium font-Montserrat"  routerLink="/categories-effects">
    <span class="hidden sm:block cursor-pointer">View All</span>
    <span class="material-symbols-outlined flex items-center justify-center border-[1.5px] border-green4-native w-[18px] h-[18px] pl-[1px] text-[20px] sm:text-[16px] sm:w-auto rounded-full sm:border-0">navigate_next</span>
  </p>
</div>
<div class="flex gap-[10px] sm:gap-[15px] w-full ocultarScrollXNoVisible">
  <ng-container *ngFor="let category of categories;">
    <div [routerLink]="category.route" [queryParams]="{ type: category.name }" class="cursor-pointer w-[105px] h-[90px]  sm:w-[133px] rounded-2xl p-2 relative flex items-end justify-start sm:h-[77px]">
      <img [src]="category.img" alt="" class="absolute right-0 top-0 w-full h-full  rounded-2xl object-cover">
      <div class="absolute rounded-2xl left-0 top-0 w-full h-full bg-gradient-to-t via-transparent from-black to-transparent"></div>
      <p class="w-[95px] sm:w-[130px] z-10 font-Monserrat font-semibold text-[15px] leading-[18px] sm:text-[16px] sm:leading-[20px] text-white">
        {{category.title}}
      </p>
    </div>
  </ng-container>
</div>
