<div class="flex items-center ml-auto sm:ml-0" (click)="openShare()">
  <svg class="icon w-[19px] h-[19x] sm:w-[26px] sm:h-[26px]" [class]="classIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21">
    <g id="Send" transform="translate(1 1)">
      <path id="Shape" d="M0,9,19,0,10,19,8,11,0,9Z" fill="none" stroke="#77838f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    </g>
  </svg>
  <label *ngIf="seeLabelsReactions" class="ml-2 hidden sm:block" [class]="classLabel">{{numberShares}} Share</label>
</div>
<!--<app-om-select id="select-nav"-->
<!--               containerClass="!bg-white !py-1 !font-poppins !font-medium shadow-lg text-[13px]]"-->
<!--               containerItemsClass="!max-h-fit w-[220px] z-60"-->
<!--               itemsClass="nav-select-item text-xs !py-1 hover:!bg-white hover:!text-green4-native"-->
<!--               [(ngModel)]="option" (ngModelChange)="openShare()"-->
<!--               color="gray" [options]="[-->
<!--                   {icon:'edit_square', label: 'Share in feed', value: 'feed'},-->
<!--                   {icon:'sms', label: 'Share DM', value: 'dm'},-->
<!--                   {icon:'groups_3', label: 'Share in a group', value: 'group'},-->
<!--                   {icon:'group', label: 'share on a friend\'s profile', value: 'friend'},-->
<!--                   ]"-->
<!--               [buttonTemplate]="true" placeholder=""-->
<!--&gt;-->
<!--  <ng-container ngProjectAs="buttonAction">-->
<!--    <div class="flex items-center mt-2 ml-auto sm:ml-0">-->
<!--      <svg class="icon w-[19px] h-[19x] sm:w-[26px] sm:h-[26px]" [class]="classIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21">-->
<!--        <g id="Send" transform="translate(1 1)">-->
<!--          <path id="Shape" d="M0,9,19,0,10,19,8,11,0,9Z" fill="none" stroke="#77838f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>-->
<!--        </g>-->
<!--      </svg>-->
<!--      <label *ngIf="seeLabelsReactions" class="ml-2 hidden sm:block" [class]="classLabel">{{numberShares}} Share</label>-->
<!--    </div>-->
<!--  </ng-container>-->
<!--</app-om-select>-->
<app-dialog #setUser title="Users"
            classHeaderDefault="p-4"
            [classModal]="'bg-black/80 sm:bg-white text-white sm:text-black sm:!min-w-[200px] sm:!w-[400px] sm:relative pt-[var(--sat)]'"
            classBody="px-4 pb-4">
  <app-om-input [useIcon]="true" color="gray"
                inputClass="!py-[10.5px] mt-1 !font-poppins !font-medium relative !bg-[#EDEDED] !text-[#6F6E6E] !placeholder:text-[#6F6E6E]"
                placeholder="Search" class="w-[60%]">
    <app-search-icon class="" color="#519935" [width]="18" [height]="18"/>
  </app-om-input>
  <label class="w-full items-start flex flex-col gap-4 mt-2 mb-2" *ngFor="let item of users" [for]="item.id">
    <div class="flex w-full justify-between gap-4 items-center cursor-pointer" >
      <div class="flex items-center gap-4">
        <div class="border-2 border-[#a0a0a0] rounded-full w-[42px] h-[42px] flex items-center justify-center relative">
          <img class="w-[40px] h-[40px] rounded-full object-cover" [src]="item.image_profile || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'">
          <span class="absolute w-[12px] h-[12px] rounded-full bottom-0 right-0 bg-[#d0d0d0]"></span>
        </div>
        <p class="text-[#3B566E] text-[16px] font-SF-Pro-Text">{{item.name}} {{item.lastname}}</p>
      </div>
      <label class="switch">
        <input type="checkbox" [(ngModel)]="shareUsers[item.id]" name="shareUsers{{item.id}}" [id]="item.id">
        <span class="sw-slider round"></span>
      </label>
    </div>
  </label>
  <button (click)="sharePost()" [disabled]="allowShare().length === 0"
    class="mt-4 sm:flex px-4 w-full !py-2 mb-2 h-fit gap-2 items-center disabled:bg-green4-native/80 justify-center font-semibold text-[16px] bg-[#519935] text-white rounded-full shadow-sm">
    Share
  </button>
</app-dialog>
<app-dialog #share title="Share post"
            classHeaderDefault="p-4"
            [classModal]="'bg-black/80 sm:bg-white text-white sm:text-black sm:!min-w-[200px] sm:!w-[400px] sm:relative'"
            classBody="px-4 pb-4">
  this post
</app-dialog>
