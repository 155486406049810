<div class="fixed z-40 inset-0 " *ngIf="openEvent">
  <div class="flex sm:items-end justify-center min-h-screen sm:pt-4 sm:px-4 sm:pb-20 text-center sm:block sm:p-0">
    <div class="fixed inset-0 transition-opacity" aria-hidden="true">
      <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>
    <span  class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <div
      class="inline-block align-bottom bg-white sm:rounded-lg text-left overflow-hidden shadow-xl w-full h-full transform transition-all sm:align-middle sm:max-w-2xl sm:w-full">
      <div class="bg-white">
        <div class="flex justify-between items-center border-b-2 px-4 pt-5 pb-4 sm:p-6 sm:pt-4 sm:pb-2">
          <h3 class="text-normal leading-6 font-bold font-Montserrat text-gray-900">
            Sended and Gift Request
          </h3>
          <app-close-icon (click)="closeModal()"></app-close-icon>
        </div>
        <div class="flex w-full px-4">
          <div class="flex items-center justify-center">
            <app-filter-icon class="pt-3 pr-2" [width]="14" [height]="14" color="#81CB52"></app-filter-icon>
            <app-om-select id="select-nav"
                           containerClass="!bg-white !py-0 !font-poppins !font-medium shadow-lg text-[13px]]"
                           containerItemsClass="!max-h-fit !w-[120px]"
                           itemsClass="nav-select-item !py-1 hover:!bg-white hover:!text-green4-native"
                           color="gray" [options]="options" [(ngModel)]="status" required
                           (ngModelChange)="getGifts(true)"
                           [default]="status" [buttonTemplate]="true" placeholder=""
                           class=" w-[100px] mt-3">
              <ng-container ngProjectAs="buttonAction">
                <div class="flex items-center w-fit">
                  <p class="text-[#131313] text-[13px] whitespace-nowrap"><span
                    class="text-[#B5B5BE]">{{ getStatusName() }}</span></p>
                  <ng-icon name="matArrowDropDown" class="text-[3.5vh] text-[#FAFAFA]"></ng-icon>
                </div>
              </ng-container>
            </app-om-select>
          </div>
        </div>
        <div #scrollView class="overflow-auto h-[calc(100vh-105px)] sm:max-h-[60vh] sm:min-h-[60vh]">
          <ng-container *ngFor="let item of gifts">
            <div *ngIf="item['products'].length>0" class="flex relative flex-col gap-4">
              <div *ngFor="let gift of item['products']"
                   class="cursor-pointer bg-white flex justify-between h-fit border-b-2">
                <div class="flex">
                  <div class="w-[80px] h-full sm:w-[60px] sm:h-full flex justify-center items-center mx-[5px] p-2">
                    <img class="w-full h-full sm:max-h-[50px] object-contain	" [src]="gift['productData']['image']"
                         [alt]="gift.productData.name">
                  </div>
                  <div class="flex justify-center items-center gap-4">
                    <img class="rounded-full h-12 overflow-hidden"
                         [src]="item['userRecived']['image_profile']"
                         alt="">
                    <div class="flex flex-col justify-center items-start">
                      <p
                        class="font-poppins text-[15px] font-semibold whitespace-nowrap overflow-hidden overflow-ellipsis w-[100px] sm:w-fit">{{ userInfoService.getNameByUser(item['userRecived']) }}</p>
                      <p class="font-SF-Pro-Text text-[14px] font-medium">{{ gift.productData.name }}</p>
                    </div>
                  </div>
                </div>
                <div class="relative flex flex-col items-end justify-between py-4 sm:py-4 px-4 w-36">
                  <p
                    class="font-Roboto hidden sm:block text-[12px] text-[#92929D] text-right">{{ formatDate(item['statusDate'], 2) }}</p>
                  <p
                    class="font-Roboto block sm:hidden text-[12px] text-[#92929D] pl-4">{{ formatDate(item['statusDate']) }}</p>
                  <app-status-gift type="sended" [merch]="item" (merchChange)="updateGiftStatus($event)"></app-status-gift>
                  <!--                <div-->
                  <!--                  class="absolute top-[42%] sm:top-[45%] right-2 font-poppins w-[60px] sm:w-[130px] text-[10px] sm:text-[15px] text-[#E6DF2E] flex gap-1 font-bold sm:font-medium sm:gap-4 flex-col-reverse sm:flex-row items-center sm:items-center justify-center">-->
                  <!--                  {{getStatus(item['status'])}}-->
                  <!--                  <div class="h-[25px] w-[25px] bg-[#E6DF2E] flex items-center justify-center rounded-full">-->
                  <!--                    <app-double-angle-icon class="block" [width]="22" [height]="22" color="#fff"></app-double-angle-icon>-->
                  <!--                  </div>-->
                  <!--                </div>-->
                </div>
              </div>
            </div>
          </ng-container>
          <p *ngIf="statusFetch">Loading gifts...</p>
        </div>
      </div>
    </div>
  </div>
</div>
