import {Component, ElementRef, EventEmitter, inject, Input, NgZone, Output, Renderer2, ViewChild} from '@angular/core';
import {DialogComponent} from "../../../general/dialog/dialog.component";
import {UserInfoService} from "../../../../services/user-info/user-info.service";
import {DeviceInfoService} from "../../../../services/device-info/device-info.service";

@Component({
  selector: 'app-comment-form',
  templateUrl: './comment-form.component.html',
  styleUrls: ['./comment-form.component.scss']
})
export class CommentFormComponent {
  private userInfo = inject(UserInfoService);
  deviceInfoService = inject(DeviceInfoService);
  @Output() clickIconLike = new EventEmitter<any>();
  @Output() clickIconSave = new EventEmitter<any>();
  @Output() sendComment = new EventEmitter<any>();
  @Output() focusedInput = new EventEmitter<boolean>();
  @Input() classAvatar: string = '';
  @ViewChild('emojiTextarea', { static: false }) emojiTextarea!: ElementRef<HTMLTextAreaElement>;
  user: any={};
  isFocused: boolean = false;
  @Input() isFocusedInput: boolean = false;
  comment='';
  files: { type: string, src?: string, name?: string }[] = [];
  limit=1;
  visibleEmojisKey=0;
  visibleGifKey=0;
  visibleEmojis=false;
  visibleGif=false;
  limitCommentsV=2;
  onFileSelected(event: any): void {
    const files: FileList = event.target.files;
    let promise = this.handleFiles(files);
  }
  async handleFiles(files: FileList): Promise<void> {
    for (let i = 0; i < files.length; i++) {
      if (this.files.length === this.limit) {
        return;
      }
      const file = files[i];
      const readFile = (file1: File): Promise<string | undefined> => {
        return new Promise<string | undefined>((resolve) => {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            resolve(e.target.result as string | undefined);
          };
          reader.readAsDataURL(file1);
        });
      };
      if (file.type.startsWith('image/')) {
        const resp = await readFile(file);
        if (resp !== undefined) {
          this.files.push({ type: 'image', src: resp });
        }
      } else if (file.type.startsWith('video/')) {
        const resp = await readFile(file);
        if (resp !== undefined) {
          this.files.push({ type: 'video', src: resp });
        }
      }
    }

  }

  onClickOutside(index:number){
    if(index!=this.visibleEmojisKey) return
    if(this.visibleEmojis) this.visibleEmojis=false;
  }
  addEmoji(emoji: any) {
    const textarea = this.emojiTextarea.nativeElement;
    const emojiText = emoji.emoji.native;
    const cursorPosIni = textarea.selectionStart;
    const cursorPosFin = textarea.selectionEnd;
    const ini = textarea.value.substring(0, cursorPosIni);
    const fin = textarea.value.substring(cursorPosFin);
    textarea.value = ini + emojiText + fin;
    const newPos = cursorPosIni + emojiText.length;
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.ngZone.run(() => {
          textarea.setSelectionRange(newPos, newPos);
          textarea.focus();
        });
      });
    });
    textarea.dispatchEvent(new Event('input'));
  }
  getGif(data: string) {
    this.visibleGif=false;
    if (this.files.length === this.limit) {
      this.removeImage(0);
    }
    this.files.push({ type: 'image', src: data});

  }
  onClickOutsideGif(index:number){
    if(index!=this.visibleGifKey) return
    if(this.visibleGif) this.visibleGif=false;
  }
  removeImage(index: number): void {
    this.files.splice(index, 1);
  }
  calculateDateDifference(date: string): string {
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const inputDate = new Date(date)
    const currentDate = new Date();
    const timeDifferenceInMilliseconds = inputDate.getTime() - currentDate.getTime();
    const seconds = Math.floor(Math.abs(timeDifferenceInMilliseconds) / 1000);

    if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      return `${minutes}m`;
    } else if (seconds < 86400) {
      const hours = Math.floor(seconds / 3600);
      return `${hours}h`;
    } else if (seconds < 691200) {
      const days = Math.floor(seconds / 86400);
      return `${days}d`;
    } else {
      const day = inputDate.getDate();
      const month = inputDate.getMonth(); // Month is zero-based, so add 1
      const year = inputDate.getFullYear();
      return `${monthNames[month]} ${day.toString().padStart(2, '0')}, ${year}`;
    }
  }
  saveComment(){
    if(this.comment=='' && this.files.length==0) return;
    const files:any=[];
    for (const object of this.files) {
      if (object.src) {
        const ext = this.deviceInfoService.extractContentTypeFromBase64(object.src).split('/')[1];
        let name = (Math.random() * 1000).toFixed(0) + `imag.${['gif', 'png', 'jpg', 'jpeg', 'mp4'].includes(ext) ? ext : 'png'}`;
        let file = this.deviceInfoService.dataURLtoFile(object.src, name);
        files.push(file)
      }
    }
    this.sendComment.emit({comment:this.comment,files:files})
    this.files=[];
    this.comment='';
    this.focusedInput.emit(false);
  }
  openEmoji(index:number){
    this.visibleEmojisKey=index;
    this.visibleEmojis=true;
    this.visibleGif=false;
  }
  openGif(index:number){
    this.visibleGifKey=index;
    this.visibleGif=true;
    this.visibleEmojis=false;
  }
  updateFocused(status:boolean) {
    if (!this.deviceInfoService.isiOS() && !this.deviceInfoService.isAndroid()) return;
    this.focusedInput.emit(status)
  }
  clearForm(){
    this.files=[];
    this.comment='';
    this.focusedInput.emit(false);
  }
  constructor(private ngZone: NgZone) {}
  ngOnInit() {
    this.user = this.userInfo.me()?.user || {image_profile:"https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png"};
  }
}
