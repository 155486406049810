<div #container [class]="[
  'om-button',
  'w-full',
  'rounded-full',
  'relative',
  classButton
]" [style]="stylesButton">
        {{ text }}
    <div [class]="'icon '+iconPosition+' '+iconClass" [style]="stylesIcon">
        <ng-content></ng-content>
    </div>
</div>
