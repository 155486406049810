import {Component, ElementRef, HostListener, inject, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {GiftService} from "../../../services/gift/gift.service";
import {UserInfoService} from "../../../services/user-info/user-info.service";
import {CatalogsService} from "../../../services/catalogs/catalogs.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-courses-search',
  templateUrl: './courses-search.component.html',
  styleUrls: ['./courses-search.component.scss']
})
export class CoursesSearchComponent implements OnInit, OnDestroy {
  router = inject(Router);
  catalogService = inject(CatalogsService);
  @ViewChild('filterList') filterList: ElementRef | undefined;
  @ViewChild('containerFilterList') containerFilterList: ElementRef | undefined;
  private http = inject(HttpClient);
  private userInfo = inject(UserInfoService);
  showFilters = false
  statusFetch = false;
  data: any[] = [];
  user: any = {};
  search = ''
  selected = ''

  options: { label: string, value: string }[] = [
    {label: 'All Courses', value: ''},
  ]

  constructor(private route: ActivatedRoute) {
  }

  resetFilter() {
    this.data = [];
    this.router.navigate(['/coaching-all'], {queryParams: {type: this.selected}});
    this.getData()
  }

  subscriptionData: Subscription | undefined;

  getData() {
    this.statusFetch = true;
    this.subscriptionData = this.http.get('/courses', {
      params: {offset: this.data.length, limit: 16, type: this.selected, search: this.search}
    }).subscribe({
      next: (response: any) => {
        this.data = [...this.data, ...response.records];
        this.statusFetch = false;
        this.subscriptionData?.unsubscribe()
      }
    });

  }

  async ngOnInit() {
    window.scrollTo(0, 1);
    await this.getCourseCategories();
    this.route.queryParams.subscribe(params => {
      if (params['type']) this.selected = params['type'];
      else this.selected = '';
    });

    this.getData();
  }

  subscriptionCategories: Subscription | undefined;

  async getCourseCategories() {
    this.subscriptionCategories = this.catalogService.getAll('courses-categories', '').subscribe({
      next: (response: any) => {
        for (const category of response.records) {
          this.options.push({
            label: category.name,
            value: category.name
          });
        }
        this.subscriptionCategories?.unsubscribe()
      }
    })
  }

  onScroll(event: Event): void {
    this.endScroll();
  }

  @HostListener('window:scroll', ['$event'])
  endScroll(): void {
    if (this.statusFetch) return;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    if (scrollTop + clientHeight >= scrollHeight - 100) {
      this.getData();
    }
  }

  ngOnDestroy() {
    this.subscriptionData?.unsubscribe();
    this.subscriptionCategories?.unsubscribe();
  }
}
