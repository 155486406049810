<div class="px-4 pl-6  w-full flex flex-col justify-between
      pt-[var(--sat)]
      bg-[#262628] bg-cover bg-[url('assets/general/wave-m.webp')] sm:bg-none bg-center
      sm:h-[60vh] sm:w-[36.6vw] sm:bg-[#2e2e2e]
      sm:mt-[calc(var(--w-height)*15)]
      sm:pt-[calc(var(--w-height)*6)]
      sm:pb-[calc(var(--w-height)*4)]
      sm:mr-[calc(var(--w-height)*15)]
      sm:px-[calc(var(--w-width)*5)]
      sm:rounded-[2vw]">
  <div>
    <ng-icon name="matArrowBack" class="text-white my-4 text-4xl inline-block sm:!hidden" routerLink="/"></ng-icon>
    <p class="title-form text-[calc(var(--mw-height)*4.5)] sm:text-[calc(var(--w-height)*4.5)]">
      Create new password</p>
    <p class="text-[#C3C3C3] mt-[calc(var(--mw-height)*1)] sm:mt-[calc(var(--mw-height)*2)] sm:text-white text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*2)]">
      Create your new password.</p>
    <div class="mt-[calc(var(--mw-height)*6)] sm:mt-[calc(var(--mw-height)*6)]">
      <app-om-input label="Create new password" placeholder="New password" id="password" type="password"
                    [(ngModel)]="data.password"  [default]="data.password" #passwordField="ngModel" required></app-om-input>
      <p *ngIf="passwordField.invalid && passwordField.touched"
         class="text-red-500 text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*1.5)]">
        Password is required.</p>
    </div>
    <div class="mt-[calc(var(--mw-height)*2)] sm:mt-[calc(var(--mw-height)*2)]">
      <app-om-input label="Confirm password" placeholder="Confirm password" id="passwordConfirmed" type="password"
                    [(ngModel)]="data.passwordConfirmed"  [default]="data.passwordConfirmed" #passwordConfirmedField="ngModel" required></app-om-input>
      <p *ngIf="passwordConfirmedField.invalid && passwordConfirmedField.touched"
         class="text-red-500 text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*1.5)]">
        Confirm password is required.</p>
    </div>
    <p *ngIf="passwordConfirmedField.valid && passwordConfirmedField.touched &&
        passwordField.valid && passwordField.touched &&
        passwordField.value !== passwordConfirmedField.value"
       class="text-red-500 text-[calc(var(--mw-height)*1.75)]
       mt-[calc(var(--mw-height)*0)]
       sm:mt-[calc(var(--w-height)*0)]
       sm:text-[calc(var(--w-height)*1.5)]">
      Passwords should be the same.</p>
    <p *ngIf="passwordConfirmedField.valid && passwordConfirmedField.touched &&
        passwordField.valid && passwordField.touched &&
        passwordField.value === passwordConfirmedField.value &&
        passwordField.value.length < 8"
       class="text-red-500 text-[calc(var(--mw-height)*1.75)]
       mt-[calc(var(--mw-height)*0)]
       sm:mt-[calc(var(--w-height)*0)]
       sm:text-[calc(var(--w-height)*1.5)]">
      Password must be 8 characters long.</p>
  </div>
  <div class="w-full items-center pb-[calc(var(--mw-height)*10)] sm:pb-0">
    <app-om-button [text]="'Continue'"
                   class="w-full"
                   [height]="{xs:'7',sm:'6'}"
                   [fontSize]="{xs:'3',sm:'2.6'}"
                   [iconPosition]="'right'"
                   (click)="resetPassword()">
      <img alt="flecha" [class]="'w-[calc(var(--mw-height)*3)] sm:w-[calc(var(--w-height)*3.5)]'" src="assets/general/flecha_larga.png">
    </app-om-button>
  </div>
</div>
