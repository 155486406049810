import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-product-header',
  templateUrl: './product-header.component.html',
  styleUrls: ['./product-header.component.scss']
})
export class ProductHeaderComponent {
    @Input() isInfo= false;
    @Input() id= ''
    @Input() name= ''
    @Input() val_reviews=0
    @Input() count_reviews=0
    @Input() genetics= ''
    @Input() type= ''
    @Input() thc= 0
    @Input() cdb= 0



}
