import {Component, Input} from '@angular/core';
import {DeviceInfoService} from "../../../services/device-info/device-info.service";

@Component({
  selector: 'app-gift-send-layout',
  templateUrl: './gift-send-layout.component.html',
  styleUrls: ['./gift-send-layout.component.scss']
})
export class GiftSendLayoutComponent {
  @Input() useHeaderEdit = false;
  @Input() routeHeader = '';
  @Input() cls = '';
  @Input() title = '';
  constructor(public deviceInfo: DeviceInfoService) {
  }
  ngOnInit(){
    this.deviceInfo.scrollTop();
  }
  toggleMenu(){
    this.deviceInfo.toggleMenu()
  }
}
