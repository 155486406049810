import {Component, inject, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {
  private router = inject(Router);
  @Input() avatar= '';
  @Input() useAvatar= false;
  @Input() dateMessage= '';
  @Input() message= '';
  @Input() position= 'left';
  @Input() postData: any= null;
  @Input() image: any= null;
  ngOnInit() {
  }
  getUrl() {
    return `${window.location.host}/home?id=${this.postData?.id}`;
  }
  gotoPost(url: string) {
    this.router.navigateByUrl(url);
  }
  getFormattedTimeOrDate(isoDate:string) {
    if(!isoDate) return '';
    const date = new Date(isoDate);
    const today = new Date();
    const isToday =
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
    if (isToday) {
      return date.toLocaleString('en-US', {hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true});
    } else {
      const options = { day: '2-digit', month: 'short', year: 'numeric' };
      // @ts-ignore
      return date.toLocaleDateString('en-US', options);
    }
  }

  protected readonly JSON = JSON;
}
