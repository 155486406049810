import {Component, ElementRef, EventEmitter, inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UserInfoService} from "../../../../services/user-info/user-info.service";
import {DeviceInfoService} from "../../../../services/device-info/device-info.service";
import {DialogComponent} from "../../../general/dialog/dialog.component";

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit {
  private userInfoService = inject(UserInfoService);
  deviceInfoService = inject(DeviceInfoService);
  @ViewChild('changePic', {static: false}) changePic!: DialogComponent;
  @ViewChild('inputChangePic') inputChangePic: ElementRef | undefined;
  @Input() status: string = 'initial';
  @Output() statusChange = new EventEmitter<string>();
  @Output() senDataPost = new EventEmitter<any[]>();
  @Output() closeDialog = new EventEmitter<any[]>();
  titlePost = '';
  description: string = '';
  user: any = {};
  settingsDesktop: boolean = false;
  filterMedia: boolean = false;
  filtersByImage: string[] = [];
  sendTo: { [key: string]: any } = {};
  settingsView: string = 'public_share';
  tagPeople: any[] = [];
  categories: string[] = [];
  location: { lng: number, lat: number, address: string } | null = null;
  openCategories = true;
  type: string = 'string';
  bannerBase64: string = '';
  files: File[] = [];
  banner: File | null | undefined = null;
  imageChangedEvent: any = null;
  aspectRatio: number = 1
  widthImage: number = 1
  croppedImage: string | null = null;
  imageSample: string = '';
  visibleGifKey = 0;
  visibleGif = false;
  limit = 5;
  gifts: { type: string, src?: string, name?: string }[] = [];
  isDivFocused: boolean = false;

  closeForm() {
    this.closeDialog.emit();
  }

  ngOnInit() {
    this.user = this.userInfoService.me()?.user
  }
  getImgProfile() {
    return this.user['image_profile'] || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'
  }
  getHashtags() {
    const hashtags = this.description.match(/#\w+/g);
    return hashtags ? hashtags : [];
  }

  clickPost() {
    if (this.type != "article") {
      this.banner = null;
      this.titlePost = ''
    } else {
      this.files = [];
    }
    let data: any = {
      title: this.titlePost,
      content: this.description || '',
      type: this.type == "article" ? "article" : 'news',
      hashtacks: this.getHashtags(),
      categories: this.categories,
      tagPeople: this.tagPeople,
      files: this.files,
      sendTo: this.sendTo,
      settingsView: this.settingsView,
    };
    if(this.location){
      data.location =  this.location;
    }
    if (this.filtersByImage.length) {
      data.filtersByImage = this.filtersByImage;
    }
    if (this.banner) data.files.push(this.banner)
    for (const object of this.gifts) {
      if (object.src && object.name) {
        let file = this.deviceInfoService.dataURLtoFile(object.src, object.name);
        data.files.push(file)
      }
    }
    this.senDataPost.emit(data);
  }

  onDivFocused() {
    this.isDivFocused = true;
  }

  onDivBlurred() {
    this.isDivFocused = false;
  }

  updateDescription(e: any) {
    this.description = e.target.innerHTML;
  }

  setType(val: string) {
    this.type = '';
    setTimeout(() => {
      this.type = val;
      switch (this.type) {
        case "article":
          this.visibleGif = false;
          this.gifts = [];
          this.status = "initial"
          this.filterMedia = false;
          this.files = []
          this.filtersByImage = [];
          break;
        case "string":
          this.visibleGif = false;
          this.gifts = [];
          this.status = "initial"
          this.filterMedia = false;
          this.files = []
          this.filtersByImage = [];
          break;
        case "gif":
          this.visibleGif = true;
          this.status = "initial"
          this.filterMedia = false;
          this.files = []
          this.filtersByImage = [];
          break;
        case "media":
          this.visibleGif = false;
          this.gifts = [];
          break;
      }
    }, 300)
  }

  setFiltersByImage(val: string[]) {
    this.filtersByImage = val;
  }

  async setMedia(val: any, replace: boolean = false) {
    this.filterMedia = true;
    this.status = 'filtering-media'
    this.statusChange.emit(this.status)
    if (replace) {
      this.files = val;
    } else {
      this.files = [...this.files, ...val]
    }
    this.imageSample = (await this.deviceInfoService.convertFileToBase64(this.files[0])) as string;
  }

  setTagPeople(val: string[]) {
    this.tagPeople = val;
  }

  setSendTo(val: { [key: string]: any }) {
    this.sendTo = val;
  }

  setSettingsView(val: string) {
    this.settingsView = val;
  }

  selectImagePic() {
    this.aspectRatio = 19 / 6;
    this.widthImage = 1400;
    this.inputChangePic?.nativeElement.click();
  }

  onChangePic() {
    this.imageChangedEvent = {target: {files: this.inputChangePic?.nativeElement.files}}
    this.changePic.open()
  }

  imageCropped(event: any) {
    if (event.base64 != null) {
      this.croppedImage = event.base64;
    }
  }

  async cropperReady() {
    if (this.croppedImage === null) return;
    const ext = this.deviceInfoService.extractContentTypeFromBase64(this.croppedImage).split('/')[1];
    let name = (Math.random() * 1000).toFixed(0) + `imag.${['gif', 'png', 'jpg', 'jpeg', 'mp4'].includes(ext) ? ext : 'png'}`;
    this.banner = this.deviceInfoService.dataURLtoFile(this.croppedImage.replace('data:', ''), name)
    if (this.banner !== undefined)
      this.bannerBase64 = (await this.deviceInfoService.convertFileToBase64(this.banner)) as string;

    this.croppedImage = '';
    this.imageChangedEvent = '';
    this.closeCropper();
  }

  closeCropper() {
    this.croppedImage = '';
    this.imageChangedEvent = '';
    this.changePic.closeB();
  }

  next() {
    this.status = 'complete-media'
    this.filterMedia = false;
  }

  onClickOutsideGif(index: number) {
    if (index != this.visibleGifKey) return
    if (this.visibleGif) this.visibleGif = false;
  }

  getGif(data: string) {
    this.visibleGif = false;
    this.gifts.push({type: 'image', src: data, name: this.randomName(10) + `imag.gif`});
  }

  randomName(num: number) {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let result = "";
    let ch;
    while (result.length < num) {
      ch = characters.charAt(Math.floor(Math.random() * charactersLength));
      if (!result.includes(ch)) result += ch;
    }
    return result;
  }

  protected readonly JSON = JSON;
}
