<div class="sm:px-[20px] pt-[10px] border-[1px] rounded-2xl">
  <p class="font-Montserrat text-[25px] font-semibold">My Courses</p>
  <div class="flex gap-6 my-4">
    <span class="cursor-pointer" (click)="type='courses'">Regular</span>
    <span class="cursor-pointer" (click)="type='one'">One on One  Mentorship’s</span>
  </div>
  <div class="mt-[20px] max-h-[365px] overflow-auto">
    <app-card-my-couser *ngFor="let item of data[type]" [data]="item" class="block mb-6"></app-card-my-couser>
    <app-not-found message="No courses found :(" *ngIf="!data[type].length" [classImg]="'max-w-[200px] !h-auto'"></app-not-found>
  </div>
</div>
