<div class="h-[74px] w-full bg-white shadow-[rgba(149,157,165,0.2)_0px_4px_6px] rounded-2xl px-[15px] flex items-center cursor-pointer"
     [class]="{
        'sm:bg-[#F2FFED]':numberUnreadMessages>0 && !isSelected,
        'sm:border-[#519934]':numberUnreadMessages>0,
        'sm:border-[1.5px]':numberUnreadMessages>0,
        'active':isSelected,
     }"
>
  <p class="relative h-[55px] w-[55px] sm:h-[42px] sm:w-[42px] rounded-full">
    <img [src]="imageProfile" alt="" class="object-cover h-[55px] w-[55px] sm:h-[42px] sm:w-[42px] rounded-full">
    <span class="absolute right-0 bottom-0 sm:hidden h-[14px] w-[14px] rounded-full bg-[#80EA4E] border-white border-[2px]"></span>
  </p>
  <div class="w-[calc(100%-155px)] sm:w-[calc(100%-42px)] pl-[15px]">
    <div class="flex items-center justify-between">
      <p class="text-[14px] sm:font-semibold font-SF-Pro-Text sm:font-Poppins w-2/3 whitespace-nowrap overflow-hidden overflow-ellipsis" [class]="{'sm:text-white':isSelected}">{{name}}</p>
      <p class="text-[12px] text-[#92929D] font-Roboto hidden sm:flex items-center justify-end" [class]="{'sm:text-white':isSelected}">
        {{getFormattedTimeOrDate(user)}}
        <span *ngIf="numberUnreadMessages>0 && !isSelected" class="w-[20px] h-[20px] flex items-center justify-center text-white text-[10px] bg-[#519935] rounded-full ml-[10px]">{{numberUnreadMessages}}</span>
      </p>
    </div>
    <p class="mt-[5px] text-[12px] sm:text-[14px] text-[#92929D] font-SF-Pro-Text sm:font-Roboto w-[210px] sm:w-full whitespace-nowrap overflow-ellipsis overflow-hidden"  [class]="{'sm:text-[#FFFFFF]':isSelected}">{{getLastMessage(user)}}</p>
  </div>
  <div class="flex items-center justify-center flex-col sm:hidden text-[12px] text-[#92929D] font-Roboto w-[100px] text-center">
    <p class="mb-[10px] text-center">{{getFormattedTimeOrDate(user)}}</p>
    <span *ngIf="numberUnreadMessages>0 && !isSelected" class="w-[25px] h-[25px] flex items-center justify-center text-white text-[14px] bg-[#519935] rounded-full">{{numberUnreadMessages}}</span>
  </div>
</div>
