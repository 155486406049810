<div class="om-container bg-white sm:bg-[#FAFAFA] mt-0">
  <app-navbar *ngIf="showNavbar" (toggleMenu)="toggleMenu()" class="fixed z-30 w-full" [routeHeader]="routeHeader" [useHeaderEdit]="useHeaderEdit">
    <router-header>
      <ng-content select="router-header"></ng-content>
    </router-header>
    <navbar-title class="font-Poppins font-semibold text-[22px]">
      {{title}}
    </navbar-title>
  </app-navbar>
  <div class="flex sm:bg-[#FAFAFA]">
    <div class="hidden duration-700 ease-out sm:block fixed h-[calc(100vh-(var(--sat)))] bg-white top-[calc(var(--sat))] sm:top-[101px] sm:h-[calc(100%-101px)] overflow-auto left-0"
         [class]="{
         '!block': deviceInfo.menuActive,
         '!fixed': deviceInfo.menuActive,
         '!left-0': deviceInfo.menuActive,
         '!w-[100%]': deviceInfo.menuActive,
         '!z-40': deviceInfo.menuActive,
         }"
    >
      <app-side-bar  (toggleMenu)="toggleMenu()"></app-side-bar>
    </div>
    <div class="w-full bg-white sm:bg-[#FAFAFA]  sm:w-[calc(100%-236px)] sm:ml-[230px] sm:mrdsas-[386px] flex justify-start  pt-[calc(60px+var(--sat))] sm:pt-[101px]" [class]="getClass()">
      <ng-content></ng-content>
    </div>
  </div>
  <app-dialog-alert class="absolute z-[70]"></app-dialog-alert>
</div>
