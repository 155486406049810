<div class="px-4 pl-6 h-full flex flex-col justify-between
      pt-[var(--sat)]
      bg-[#262628] bg-cover bg-[url('assets/general/wave-m.webp')]
      sm:bg-none bg-center
      sm:h-[80vh] sm:w-[36.6vw] sm:bg-[#2e2e2e]
      sm:mt-[calc(var(--w-height)*8)]
      sm:pt-[calc(var(--w-height)*8)]
      sm:pb-[calc(var(--w-height)*4)]
      sm:mr-[calc(var(--w-height)*15)]
      sm:px-[calc(var(--w-width)*7.8)]
      sm:rounded-[2vw]">
  <div>
    <ng-icon name="matArrowBack" class="text-white my-4 text-4xl inline-block sm:!hidden" (click)="previous()"></ng-icon>
    <p *ngIf="!data.country || data.region==''"
       class="title-form text-[calc(var(--mw-height)*4.5)] sm:text-[calc(var(--w-height)*4.5)]">
      Where are you located?</p>
    <p *ngIf="!!data.country && data.region!=''"
       class="title-form text-[calc(var(--mw-height)*4.5)] sm:text-[calc(var(--w-height)*4.5)]">
      When were you born?</p>
    <p *ngIf="data.country=='' || data.region==''"
       class="text-[#C3C3C3] mt-[calc(var(--mw-height)*1)] sm:mt-[calc(var(--mw-height)*2)] sm:text-white text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*2)]">
      Select your country and region.</p>
    <p *ngIf="data.country!='' && data.region!=''"
       class="text-[#C3C3C3] mt-[calc(var(--mw-height)*1)] sm:mt-[calc(var(--mw-height)*2)] sm:text-white text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*2)]">
      Add the date you were born</p>
    <div class="mt-[calc(var(--mw-height)*6)] sm:mt-[calc(var(--mw-height)*4.5)]">
      <label [for]="country"
             class="text-white block !pb-0 text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*2)]">Select
        your country.</label>
      <app-om-select [options]="catCountries" [(ngModel)]="data.country" required
                     (ngModelChange)="onChangeCountry(country.value)"
                     #country="ngModel" [default]="data.country" keyLabel="nameCountry" keyValue="codeCountry">
      </app-om-select>
      <p *ngIf="country.invalid && country.touched"
         class="text-red-500 text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*2)]">Country is
        required.</p>
    </div>
    <div *ngIf="getRegions().length > 0" class="mt-[calc(var(--mw-height)*2)] sm:mt-[calc(var(--mw-height)*4.5)]">
      <label [for]="region"
             class="block text-white !pb-0 text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*2)]">Select
        your region.</label>
      <app-om-select [options]="getRegions()" [(ngModel)]="data.region" required
                     #region="ngModel" [default]="data.region" keyLabel="nameRegion" keyValue="codeRegion"></app-om-select>
      <p *ngIf="region.invalid && region.touched"
         class="text-red-500 text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*2)]">Region is
        required.</p>
    </div>
    <div *ngIf="data.country!='' && (data.region!='' || getRegions().length === 0)"
         class="mt-[calc(var(--mw-height)*2)] sm:mt-[calc(var(--mw-height)*4.5)]">
      <label [for]="year"
             class="block text-white !pb-0 text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*2)]">When
        is your birthday?</label>
      <div class="flex gap-3">
        <app-om-select class="w-[calc(var(--w-width)*26)]" [options]="years" [(ngModel)]="data.birthday.year" required
                       #year="ngModel" [default]="data.birthday.year" placeholder="Year"
                       (ngModelChange)="getDaysInMonth('year',$event)"></app-om-select>

        <app-om-select class="w-[calc(var(--w-width)*35)]" [options]="months" [(ngModel)]="data.birthday.month" required
                       #month="ngModel" [default]="data.birthday.month" placeholder="Month"
                       (ngModelChange)="getDaysInMonth('month',$event)"></app-om-select>

        <app-om-select class="w-[calc(var(--mw-width)*22)]" [options]="days" [(ngModel)]="data.birthday.day" required
                       #day="ngModel" [default]="data.birthday.day" placeholder="Day"></app-om-select>
      </div>
      <p *ngIf="day.invalid && day.touched"
         class="text-red-500 text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*2)]">Birthday is
        required.</p>
    </div>
  </div>
  <div class="w-full items-center">
    <app-om-button [text]="'Continue'"
                   *ngIf="data.country!='' && (data.region!='' || getRegions().length === 0)"
                   class="w-full"
                   [height]="{xs:'7',sm:'6'}"
                   [fontSize]="{xs:'3',sm:'3'}"
                   [iconPosition]="'right'"
                   (click)="sendDataC()">
        <img alt="flecha" [class]="'w-[calc(var(--mw-height)*3)] sm:w-[calc(var(--mw-height)*3.5)]'" src="assets/general/flecha_larga.png">
      </app-om-button>
    <p
      class="text-white text-center m-[calc(var(--mw-height)*3)] text-[calc(var(--mw-height)*1.4)]
          block sm:absolute sm:w-[36.6vw] sm:right-[calc(var(--w-height)*15)] sm:bottom-0 sm:mt-[calc(var(--w-height)*3)] sm:text-[calc(var(--w-height)*2)]"
    >Have an account? <span
      class="text-green-native cursor-pointer underline" routerLink="/login">Sign in now.</span></p>
  </div>
</div>
