import {Component, inject, Input, OnInit} from '@angular/core';
import {FollowAndFriendService} from "../../../../services/follow-and-friend/follow-and-friend.service";
import {Router} from "@angular/router";
import {UserInfoService} from "../../../../services/user-info/user-info.service";
import {ChatService} from "../../../../services/chat/chat.service";

@Component({
  selector: 'app-grower-card',
  templateUrl: './grower-card.component.html',
  styleUrls: ['./grower-card.component.scss']
})
export class GrowerCardComponent implements OnInit {
  private followAndFriends = inject(FollowAndFriendService);
  private userInfoService = inject(UserInfoService);
  private chatService = inject(ChatService);
  private router = inject(Router);
  isFollowing= false;
  @Input() user: any = {};
  ngOnInit() {
    this.validIsFollowing();
  }
  countFollowers() {
    return this.userInfoService.formatFollowerCount(this.user.followersCount||0)
  }
  async validIsFollowing() {
    this.isFollowing = await this.followAndFriends.isFollowing(this.user?.id);
  }
  getName(){
    return this.userInfoService.getNameByUser(this.user)
  }
  async follow() {
    await this.followAndFriends.add(this.user?.id);
    await this.followAndFriends.getFollowings(true);
    await this.validIsFollowing();
  }
  async unfollow() {
    await this.followAndFriends.delete(this.user?.id);
    await this.followAndFriends.getFollowings(true);
    await this.validIsFollowing();
  }

  async goToMessages() {
    let user = await this.userInfoService.getById(this.user?.id)
    this.chatService.changeUser(user)
    this.router.navigateByUrl('/messages')
  }
}
