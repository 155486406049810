<div class="">
  <p class="text-black text-[17px] sm:text-[20px] font-Montserrat font-semibold mt-6 mb-[16px]">Top Effects</p>
  <div class="flex px-[25px] sm:px-0 sm:justify-start gap-16">
    <div *ngFor="let effect of effects">
      <div class="flex items-center justify-center w-[51px] h-[51px] sm:w-[76px] sm:h-[76px] p-2 sm:p-4 rounded-full shadow-[0px_5px_6px_#0000007A]">
        <img [src]="'assets/icons-sax/creative.svg'" alt=""/>
      </div>
      <p class="text-center font-Montserrat font-medium text-[11px] sm:text-[17px] mt-2">{{effect}}</p>
    </div>
  </div>
</div>
