<div class="flex items-center" *ngIf="numberReactions.lastLikes?.length">
    <div class="relative h-[calc(var(--mw-width)*10)] og-w-post-{{((numberReactions.lastLikes.length -1) * 3)}} sm:h-[calc(var(--w-width)*3)] sm:w-[calc(var(--w-width)*9)]">
    <img *ngFor="let lk of numberReactions.lastLikes;let i = index"
         [src]="lk.user?.image_profile || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'"
         class="avatar-group rounded-4xl absolute ogleft-{{i*3}} sm:left-[calc(var(--w-width)*{{3*(0.4 * i)}})]">
<!--    <img [src]="'assets/demo/avatar3.png'" class="avatar-group left-[calc(var(&#45;&#45;mw-width)*3*0.8)] sm:left-[calc(var(&#45;&#45;w-width)*3*0.4)] rounded-4xl absolute">-->
<!--    <img [src]="'assets/demo/avatar4.png'" class="avatar-group left-[calc(var(&#45;&#45;mw-width)*3*1.6)] sm:left-[calc(var(&#45;&#45;w-width)*3*0.8)] rounded-4xl absolute">-->
<!--    <img [src]="'assets/demo/avatar.png'"  class="avatar-group left-[calc(var(&#45;&#45;mw-width)*3*2.4)] sm:left-[calc(var(&#45;&#45;w-width)*3*1.2)] rounded-4xl absolute">-->
  </div>
  <div class="col-span-2 flex items-center gap-2 w-full">
    <div class="flex items-center gap-2">
    </div>
    <p *ngIf="!!numberReactions?.lastLikes?.length && numberReactions.lastLikes.length < 6" class="ml-0 sm:ml-12 font-SF-Pro-Text sm:font-Poppins text-[#77838F] sm:text-black text-[calc(var(--mw-width)*3.2)] sm:text-[calc(var(--w-width)*1.15)]"><span class="font-SF-Pro-Text sm:font-Poppins text-[#519935]"></span> liked by <span class="text-[#519935]">{{ numberReactions.lastLikes[0]?.user?.name }}</span></p>
    <p *ngIf="!!numberReactions?.lastLikes?.length && numberReactions.lastLikes.length > 5" class="ml-0 sm:ml-12 font-SF-Pro-Text sm:font-Poppins text-[#77838F] sm:text-black text-[calc(var(--mw-width)*3.2)] sm:text-[calc(var(--w-width)*1.15)]"><span class="font-SF-Pro-Text sm:font-Poppins text-[#519935]"></span> liked by <span class="text-[#519935]">{{ numberReactions.lastLikes[0]?.user?.name }}</span>  <span class="text-[#519935]"> and {{ numberReactions.numberLikes - numberReactions.lastLikes.length }} more</span></p>
  </div>
</div>
