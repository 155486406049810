import {Component, inject, OnInit, HostListener, Output, EventEmitter, Input} from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {FormBuilder} from "@angular/forms";
@Component({
  selector: 'app-describes-you',
  templateUrl: './describes-you.component.html',
  styleUrls: ['./describes-you.component.scss']
})
export class DescribesYouComponent {

  cat_best_describes: IBestDescribe[] = [];
  private http = inject(HttpClient);
  @Output() sendData = new EventEmitter<any>();
  @Input()best_describes:string[]=[];
  validate=true;
  @Output() previousStep = new EventEmitter<any>();
  previous() {
    this.previousStep.emit();
  }
  sendDataC() {
    if(this.best_describes.length==0){
      this.validate=false;
      return
    }
    this.validate=true;
    this.sendData.emit({best_describes:this.best_describes});
  }
  toggleBestDescribes(bestDescribe: string) {

    const index = this.best_describes.indexOf(bestDescribe);
    if (index === -1) {
      if (this.best_describes.length < 3) this.best_describes.push(bestDescribe);
    }
    else this.best_describes.splice(index, 1);

  }
  isboxSelected(text: string, item: string) {
    const index = this.best_describes.indexOf(text);
    if (index === -1) return false;
    else return true;
  }
  getBestDescribes() {
    this.cat_best_describes = [];
    this.http.get('/common/user-descriptions').subscribe({
      next: (response: any) => {
        this.cat_best_describes = response;
      }
    });
  }
  constructor() {
    this.getBestDescribes();
  }
}
