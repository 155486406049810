import {Component, Input} from '@angular/core';
import {DeviceInfoService} from "../../../services/device-info/device-info.service";

@Component({
  selector: 'app-sidebar2-layout',
  templateUrl: './sidebar2-layout.component.html',
  styleUrls: ['./sidebar2-layout.component.scss']
})
export class Sidebar2LayoutComponent {
  @Input() useHeaderEdit = false;
  @Input() showNavbar = true;
  @Input() routeHeader = '';
  @Input() cls = '';
  @Input() title = '';
  constructor(public deviceInfo: DeviceInfoService) {
  }
  ngOnInit(){
    this.deviceInfo.scrollTop();
  }
  toggleMenu(){
    this.deviceInfo.toggleMenu()
  }
  getClass() {
    let classes: {[key:string]: boolean} = {};
    const mapClassToBoolean = (cl: string) => ({ [cl]: true });

    if(this.cls){
      const arrcls = this.cls.split(' ');
      classes = Object.assign({}, ...arrcls.map(mapClassToBoolean));
    }
    if(this.showNavbar){
      classes['sm:pt-[0px]'] = true;
    } else {
      classes['pt-[calc(var(--sat))]'] = true;
      classes['sm:pt-[101px]'] = true;
    }

    return classes;
  }
}
