<div class="scroll-smooth !h-auto bg-white sm:bg-[#FAFAFA] mt-0">
  <div class="sticky top-0 z-20 w-full">
    <app-navbar (toggleMenu)="toggleMenu()" [useHeaderEdit]="useHeaderEdit">
      <router-header>
        <ng-content select="router-header-gift" >
        </ng-content>
      </router-header>
      <navbar-title class="font-Poppins font-semibold text-[22px]">
        {{title}}
      </navbar-title>
    </app-navbar>
  </div>
  <div class="flex sm:bg-[#FAFAFA]">
    <div class="sm:block duration-700 ease-out fixed h-full bg-white top-0 sm:top-[101px] sm:h-[calc(100%-101px)] overflow-auto sm:!left-0 sm:hidden"
         [class]="{
         '!fixed': deviceInfo.menuActive,
         '!left-[-100%]': !deviceInfo.menuActive,
         '!left-0': deviceInfo.menuActive,
         '!w-[100%]': deviceInfo.menuActive,
         '!z-40': deviceInfo.menuActive,
         }"
    >
      <app-side-bar  (toggleMenu)="toggleMenu()"></app-side-bar>
    </div>
    <div class="container-center w-full min-h-screen bg-[#FAFAFA] sm:bg-[#FAFAFA] flex justify-center pb-28">
      <div class="w-full sm:max-w-[900px] min-h-full">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
  <app-dialog-alert class="absolute z-[70]"></app-dialog-alert>
</div>
