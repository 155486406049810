import {Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {DeviceInfoService} from "../../../../services/device-info/device-info.service";
import {UserInfoService} from "../../../../services/user-info/user-info.service";
import {DialogComponent} from "../../../general/dialog/dialog.component";

@Component({
  selector: 'app-delivery-address',
  templateUrl: './delivery-address.component.html',
  styleUrls: ['./delivery-address.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DeliveryAddressComponent),
      multi: true,
    },
  ],
})
export class DeliveryAddressComponent implements OnInit {
  @ViewChild('addressDialog', {static: false}) addressDialog!: DialogComponent;
  @Input() viewEditAddress = false;
  @Input() viewEditForm = false;
  @Input() title = 'Delivery Address';
  @Input() classTitle = '';
  @Input() classField = '';
  @Output() openCloseEdit = new EventEmitter<any>();
  @Input() useAddressVerified = true;
  @Input() enableRequiredInput = false;
  location: { lng: number, lat: number, address: string } | null = null;
  full_address = 'HOME ADDRESS'
  text = 'Verified Address'
  openMap = false;
  @Input() address: any = {
    id: '',
    address: "",
    lat: "",
    lng: "",
    isVerified: true,
  };
  @Output() addressChange = new EventEmitter<any>();

  setAccept(data: any) {
    if(data!=null){
      this.location = data;
      this.address.address = data.address;
      this.address.lat = data.lat;
      this.address.lng = data.lng;
      this.address.isVerified = false;
    }
    this.openMap = false;
    this.addressDialog.closeB();
  }

  openEditAddress(openDefault:boolean=true) {
    if(openDefault){
      this.addressDialog.open()
      this.openMap = true;
    }else if(this.address?.address===''){
      this.addressDialog.open();
      this.openMap = true;
    }
  }

  ngOnInit() {
    if (!this.useAddressVerified) {
      this.viewEditAddress = true
      this.viewEditForm = true
    }
  }


  onModelChange: (value: any) => void = () => {
  };

  writeValue(value: any): void {
    this.address = value;
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  onAddressChange(newValue: IAddress) {
    this.address = newValue;
    this.onModelChange(this.address);
  }

  editAddress() {
    this.address.id = ' '
    this.viewEditForm = true;
    this.full_address = 'NEW ADDRESS'
    this.openCloseEdit.emit(this.viewEditForm);
  }

  constructor(public deviceInfo: DeviceInfoService, private userInfo: UserInfoService) {
  }

  useVerifyAddress() {
    const delivery_address = this.userInfo.me().user.delivery_address;
    this.address = {
      id: '',
      address: delivery_address?.address || '',
      lat: delivery_address?.lat || '',
      lng: delivery_address?.lng || '',
      isVerified: true,
    };
    this.viewEditForm = false;
    this.full_address = 'HOME ADDRESS'
    this.openCloseEdit.emit(this.viewEditForm);
  }
}
