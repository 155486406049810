import {Component, inject, Input} from '@angular/core';
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {ChatService} from "../../../../services/chat/chat.service";
import {UserInfoService} from "../../../../services/user-info/user-info.service";
import {OmAlertService} from "../../../../om-alert.service";

@Component({
  selector: 'app-community-popular-card',
  templateUrl: './community-popular-card.component.html',
  styleUrls: ['./community-popular-card.component.scss']
})
export class CommunityPopularCardComponent {
  router = inject(Router);
  http = inject(HttpClient);
  chatService = inject(ChatService)
  private userInfo = inject(UserInfoService);
  private alertService= inject(OmAlertService);
  @Input() data: any = {};
  @Input() enableActions: any = true;
  joinCommunity(){
    this.http.post('/community/join', {
      id : this.data.communityId,
      userCommunity:this.data.communityIdUser
    }).subscribe({
      next: (response: any) => {
        const {success} = response
        if (success) {
          this.router.navigateByUrl('/community?id='+this.data.communityId)
        } else {
          alert("Error")
        }
      },
      error: (err:any) => {
        alert("Error")
      }
    });
  }
  applyJoin(){
    this.http.post('/community/apply-join', {
      id : this.data.communityId,
      userCommunity:this.data.communityIdUser
    }).subscribe({
      next: (response: any) => {
        const {success,data,info} = response
        if (success) {
          this.chatService.sendPush(this.data.communityIdUser, {meta:data.notification.sk,message:data.notification.message}, 'community');
          this.alertService.setCustomConfig({
            title: 'Request sent',
            description:'',
            containerClass:'logout-dialog',
            closeOnPress:true,
            activeButtonCancel:false
          });
          this.alertService.showCustomDialog();
        } else if(info==='invitation-request-exists'){
          this.alertService.setCustomConfig({
            title: 'An outstanding request already exists',
            description:'',
            containerClass:'logout-dialog',
            closeOnPress:true,
            activeButtonCancel:false
          });
          this.alertService.showCustomDialog();

        }
      },
      error: (err:any) => {
        alert("Error")
      }
    });
  }
  isMe(){
    return this.userInfo.isMe(this.data.communityIdUser)
  }
}
