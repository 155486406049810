import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {ChatService} from "../../../services/chat/chat.service";
import {UserInfoService} from "../../../services/user-info/user-info.service";
import {FollowAndFriendService} from "../../../services/follow-and-friend/follow-and-friend.service";
import {DialogComponent} from "../../general/dialog/dialog.component";
import {DateTime} from "luxon";
import {DeviceInfoService} from "../../../services/device-info/device-info.service";
import {Subscription} from "rxjs";
import {CdkVirtualScrollViewport} from "@angular/cdk/scrolling";
import {SearchAllService} from "../../../services/search-all/search-all.service";

@Component({
  selector: 'app-home-messages',
  templateUrl: './home-messages.component.html',
  styleUrls: ['./home-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class HomeMessagesComponent implements OnInit, OnDestroy {
  private searchAllService = inject(SearchAllService);
  private userInfoService = inject(UserInfoService);
  deviceInfoService = inject(DeviceInfoService);
  @ViewChild('setUser', {static: false}) setUser!: DialogComponent;
  @ViewChild('containerMessage') containerMessage: ElementRef | undefined;
  @ViewChild('viewportMessages') viewportMessages: CdkVirtualScrollViewport | undefined;
  user = {id: ''};
  search = '';
  inbox = '';
  tab = 'message';
  countMessage = 0;
  countCall = 0;
  countGroups = 0
  userSelected: any = null;
  users: any = [];
  cls: string = '';
  client: any = null;
  stream: any = null;
  messagesInbox: any = []
  inboxes: any = []
  subscription: Subscription | undefined;
  subscriptionMessages: Subscription | undefined;
  subscriptionInboxes: Subscription | undefined;
  messagesInboxLoading = false;

  constructor(private chatService: ChatService, private changeDetectorRef: ChangeDetectorRef) {
  }

  async ngOnInit() {
    this.user = this.userInfoService.me();
    await this.getUsers();
    this.subscription = this.chatService.currentUser.subscribe(async data => {
      if (data !== null) {
        this.userSelected = data?.user;
        this.create();
        this.chatService.changeUser(null);
      }
    });
    this.subscriptionMessages = this.chatService.currentMessages.subscribe(async data => {
      if (data !== null) {
        console.error("Update messages currentMessages")

        let userChat = !this.isMe(this.userSelected?.userTo?.id) ? this.userSelected?.userTo?.id : this.userSelected?.userFrom?.id
        userChat = userChat || this.userSelected?.id;
        let isOfChat = true;
        data.forEach((item: any) => {
          console.log(item, item.idUserFrom, item.idUserTo, userChat)
          if (item.idUserFrom !== userChat && item.idUserTo !== userChat) {
            isOfChat = false
          }
        });
        if (isOfChat) {
          this.messagesInbox = [...this.messagesInbox, ...data];
          // this.changeDetectorRef.detectChanges();
          setTimeout(() => {
            this.scrollToBottom();
          }, 200)
        }
      }
      this.messagesInboxLoading = false;
    });
    this.subscriptionMessages = this.chatService.currentAllMessages.subscribe(async data => {
      if (data !== null) {
        console.error("Update messages currentAllMessages")

        let userChat = !this.isMe(this.userSelected?.userTo?.id) ? this.userSelected?.userTo?.id : this.userSelected?.userFrom?.id
        userChat = userChat || this.userSelected?.id;
        let isOfChat = true;
        data.forEach((item: any) => {
          console.log(item, item.idUserFrom, item.idUserTo, userChat)
          if (item.idUserFrom !== userChat && item.idUserTo !== userChat) {
            isOfChat = false
          }
        });
        if (isOfChat) {
          this.messagesInbox = [...data];
          // this.changeDetectorRef.detectChanges();
          setTimeout(() => {
            this.scrollToBottom();
          }, 200)
        }
      }
      this.messagesInboxLoading = false;
    });
    this.subscriptionInboxes = this.chatService.currentInboxes.subscribe(async data => {
      if (data !== null) {
        let newInboxes = [];
        if (Array.isArray(data) && data.some(item => item.hasOwnProperty('isNew'))) {
          this.userSelected = data[0]
          delete data[0].isNew;
          newInboxes = [...this.inboxes, ...data]
        } else {
          newInboxes = data;
        }

        console.error("Update inbox")
          this.inboxes = newInboxes
              .filter((v: any, i: any, a: any) => a.findIndex((t: any) => t.inboxId === v.inboxId) === i) // filter out duplicates
              .sort((a: any, b: any) => {
                  const dateA = a.message && a.message.createDate ? a.message.createDate : a.createDate;
                  const dateB = b.message && b.message.createDate ? b.message.createDate : b.createDate;
                  return new Date(dateB).getTime() - new Date(dateA).getTime();
              });
        console.log(this.inboxes)
        // setTimeout(()=> {
        //   this.scrollToBottom();
        // }, 500)
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription)
      this.subscription.unsubscribe();
    if (this.subscriptionMessages)
      this.subscriptionMessages.unsubscribe();
    if (this.subscriptionInboxes)
      this.subscriptionInboxes.unsubscribe();
  }

  getAvatar(msg: any) {
    if (!this.isMe(msg?.idUserFrom)) {
      return msg?.userFrom?.image_profile || msg?.image_profile
    } else {
      return msg?.userTo?.image_profile || msg?.image_profile
    }
  }

  getPostData(msg: any) {
    if (msg.messageContent.includes('OG-post:'))
      return JSON.parse(msg.messageContent.split('OG-post:')[1]);

    return null;
  }

  getImg(msg: any) {
    if (msg.messageContent.includes('OG-img:'))
      return msg.messageContent.split('OG-img:')[1];

    return null;
  }

  getPosition(msg: any) {
    if (!this.isMe(msg?.idUserFrom)) {
      return 'left';
    } else {
      return 'right'
    }
  }

  isUseAvatar(msg: any) {
    return !this.isMe(msg?.idUserFrom);
  }

  scrollToBottom() {
    if (this.viewportMessages) {
      this.viewportMessages.scrollTo({
        bottom: 0,
        behavior: 'smooth'
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(_: any) {
    if (this.viewportMessages)
      this.viewportMessages.checkViewportSize();
  }

  isMe(userId: string) {
    return this.userInfoService.isMe(userId)
  }

  formatDate(date: string | number) {
    if (typeof date === 'string')
      return DateTime.fromISO(date).toString()

    return DateTime.fromJSDate(new Date(date)).toString()
  }

  initVideo() {
    let userChat = !this.isMe(this.userSelected?.userTo?.id) ? this.userSelected?.userTo?.id : this.userSelected?.userFrom?.id
    userChat = userChat || this.userSelected?.id;
    this.chatService.createCall(userChat, (this.user as any)?.user?.id, this.userSelected.inboxId, 'video')
  }

  initAudio() {
    let userChat = !this.isMe(this.userSelected?.userTo?.id) ? this.userSelected?.userTo?.id : this.userSelected?.userFrom?.id
    userChat = userChat || this.userSelected?.id;
    this.chatService.createCall(userChat, (this.user as any)?.user?.id, this.userSelected.inboxId, 'audio')
  }

  selectUser(user: any) {
    this.messagesInbox = [];
    this.inbox = '';
    this.userSelected = user;
    this.setUser.closeB()
    this.create();
  }

  newChat() {
    this.setUser.open()
  }

  getImage() {
    let img = ''
    if (this.userSelected?.userFrom && !this.isMe(this.userSelected.userFrom.id)) {
      img = this.userSelected.userFrom.image_profile
    } else if (this.userSelected?.userTo && !this.isMe(this.userSelected.userTo.id)) {
      img = this.userSelected.userTo.image_profile
    }
    return img || this.userSelected.image_profile || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png';
  }

  getName() {
    if (this.userSelected?.userFrom && !this.isMe(this.userSelected.userFrom.id)) {
      return this.userInfoService.getNameByUser(this.userSelected.userFrom);
    } else if (this.userSelected?.userTo && !this.isMe(this.userSelected.userTo.id)) {
      return this.userInfoService.getNameByUser(this.userSelected.userTo);
    } else {
      return this.userInfoService.getNameByUser(this.userSelected);
    }
  }

  sendMessage(info: any) {
    let userChat = !this.isMe(this.userSelected?.userTo?.id) ? this.userSelected?.userTo?.id : this.userSelected?.userFrom?.id
    userChat = userChat || this.userSelected?.id;
    this.chatService.sendMessage({message: info.comment, inboxId: this.inbox, userTo: userChat})
  }

  create() {
    let userChat = !this.isMe(this.userSelected?.userTo?.id) ? this.userSelected?.userTo?.id : this.userSelected?.userFrom?.id
    userChat = userChat || this.userSelected?.id;
    if (!this.chatService.getInboxes().find((x: any) => x.idUserTo === userChat || x.idUserFrom === userChat)) {
      this.chatService.create(userChat);
    }
    this.messagesInboxLoading = true;

    setTimeout(() => {
      let chat = this.chatService.getInboxes().find((x: any) => x.idUserTo === userChat || x.idUserFrom === userChat)
      this.inbox = (chat as any)?.inboxId;
      this.chatService.join(this.inbox)
      this.chatService.history(this.inbox);
    }, 500)
  }
  async getUsers() {
    let filters: any = {
      limit: 10,
      offset: 0,
    }
    if (this.search) {
      filters.search = this.search;
    }
    if (this.userInfoService.user.id) {
      filters.user = this.userInfoService.user.id;
    }
    filters['friends'] = 1;
    filters['following'] = 1;

    let users = await this.searchAllService.getUsers(filters).toPromise() as any;
    this.users = users || [];
  }

  protected readonly JSON = JSON;
}
