<div class="container-histories relative max-w-[100vw] ">
  <div class="scroll-hidden scroll-container relative">
    <div class="content flex w-full ">
      <app-story-card class="mr-3  sm:block" [isAdd]="true"/>
      <app-story-card class="mr-3" *ngFor="let story of stories" [story]="story" (click)="showViewer =  true"/>
    </div>
  </div>
  <div class="chevrons absolute inset-y-0 left-[-20px] items-center">
    <button (click)="scrollLeft()"  class="absolute flex justify-center items-center text-[calc(var(--w-height)*3.1)] rounded-full bg-[#c5c5c5] text-white h-10 w-10">
      <span class="material-symbols-outlined">
      chevron_left
      </span>
    </button>
  </div>
  <div class="chevrons absolute inset-y-0 right-[15px] items-center">
    <button (click)="scrollRight()" class="absolute flex justify-center items-center text-[calc(var(--w-height)*3.1)] rounded-full bg-[#c5c5c5] text-white h-10 w-10">
     <span class="material-symbols-outlined">
      chevron_right
      </span>
    </button>
  </div>
</div>
<app-story-viewer *ngIf="showViewer" [(showView)]="showViewer" [objStories]="stories"></app-story-viewer>
