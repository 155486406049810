import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-coaching-categories',
  templateUrl: './coaching-categories.component.html',
  styleUrls: ['./coaching-categories.component.scss']
})
export class CoachingCategoriesComponent implements OnInit {
  @Output() updateType = new EventEmitter<any>();
  @Input() type = '';
  index = 0;
  @Input() categories = [
    {
      name: 'mycourse',
      title: 'My courses',
      img: 'assets/coaching/mycourses.png',
      banner: 'assets/coaching/mycourses.png',
    }
  ]

  constructor(private route: ActivatedRoute, private router: Router) {
  }
  changeType(type:string){
    //this.type=type;
    this.updateType.emit(type)
  }
  async ngOnInit() {
    window.scrollTo(0, 1);
    this.index = this.categories.findIndex((element) => {
      return element.name === this.type;
    });
  }

}
