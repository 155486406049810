import {Component, ElementRef, EventEmitter, HostListener, inject, OnInit, Output} from '@angular/core';
import {ChatService} from "../../../services/chat/chat.service";
import {UserInfoService} from "../../../services/user-info/user-info.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss']
})
export class NotificationCenterComponent implements OnInit {
  private router = inject(Router);
  notificationCount: number = 0;
  showNotifications: boolean = false;
  notifications : {[key:string]: any}[] = []
  private chatService = inject(ChatService);
  private userInfoService = inject(UserInfoService);
  @Output() openPublicationM = new EventEmitter<any>();
  @Output() openCommunityRequest = new EventEmitter<any>();
  constructor(private elementRef: ElementRef) {
  }
  async ngOnInit() {
    await this.reloadNotifications();
  }
  async reloadNotifications() {
    this.notifications = await this.userInfoService.getNotifications() as any;
    this.notificationCount = this.notifications.filter(o =>  !o['read'])?.length || 0;
  }
  notificationsList() {
    if (this.chatService.getNotifications()) {
      this.chatService.notifications = false;
      this.reloadNotifications();
    }
    return this.notifications;
  }
  openNotifications() {
    this.showNotifications = !this.showNotifications
  }
  async goToAction(notification: any) {
    this.userInfoService.readNotification(notification.id);
    let indexNotification = this.notifications.findIndex(x => x["id"] === notification.id)
    this.notifications[indexNotification]["read"] = true;
    this.notificationCount = this.notifications.filter(o => !o['read'])?.length || 0;
    if (['gift', 'giftSended', 'giftClaimed'].includes(notification.type)) {
      if(notification.type === 'giftSended')
        this.userInfoService.changeGift({merchId: notification.content.meta});
      else
        this.router.navigateByUrl('/gift-center?id=' + notification.content.meta);
    } else if (notification.type === 'post') {
      this.openPublicationM.emit('01HMG6J68VWDAMYAMD3KY0J8R7')
    } else if (notification.type === 'community') {
      console.log(JSON.stringify(notification))
      this.openCommunityRequest.emit({sk: notification.content.meta})
    } else if (notification.type === 'friendRequest') {
      this.router.navigateByUrl('/friends?type=request');
    } else if (notification.type === 'likedPost') {
      this.router.navigateByUrl('/home?id=' + notification.content.meta);
    } else if (notification.type === 'commentPost') {
      this.router.navigateByUrl('/home?id=' + notification.content.meta);
    } else if (notification.type === 'sharePost') {
      this.router.navigateByUrl('/home?id=' + notification.content.meta);
    } else if (notification.type === 'tagPost') {
      this.router.navigateByUrl('/home?id=' + notification.content.meta);
    } else if (notification.type === 'message') {
      let user = await this.userInfoService.getById(notification.content.meta)
      this.chatService.changeUser(user)
      this.router.navigateByUrl('/messages');
    }
  }
  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!this.elementRef?.nativeElement?.contains(event.target)) {
      this.showNotifications = false;
    }
  }
}
