import {Component, inject} from '@angular/core';
import {DeviceInfoService} from "../../../../services/device-info/device-info.service";
import {UserInfoService} from "../../../../services/user-info/user-info.service";
import {HttpClient} from "@angular/common/http";
import {OmAlertService} from "../../../../om-alert.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent {
  private deviceInfo = inject(DeviceInfoService);
  private alertService= inject(OmAlertService);
  router = inject(Router);
  http = inject(HttpClient);
  data:any ={
    name: '',
    email: '',
    phone: '',
    message: '',
    username:''
  }
  loading=false
  files:any=[]
  errorSize=false;
  limit= 3
  ngOnInit() {
    window.scrollTo(0, 1);
    this.data.email=this.userInfo.me().user.email;
    this.data.name=this.userInfo.me().user.name+' '+this.userInfo.me().user.lastname;
    this.data.phone=this.userInfo.me().user.phone;
    this.data.username=this.userInfo.me().user.username;
  }
  constructor(private userInfo: UserInfoService) {
  }
  goBack() {
    this.deviceInfo.goBack();
  }

  async handleFiles(files: FileList): Promise<void> {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (this.files.length === this.limit) {
        return;
      }

      const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
      if(file.size>maxSizeInBytes){
        this.errorSize=true;
        continue;
      }
      const readFile = (file1: File): Promise<string | undefined> => {
        return new Promise<string | undefined>((resolve) => {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            resolve(e.target.result as string | undefined);
          };
          reader.readAsDataURL(file1);
        });
      };
      if (file.type.startsWith('image/')) {
        const resp = await readFile(file);
        if (resp !== undefined) {
          this.files.push({ type: 'image', src: resp });
        }
      }
    }

  }
  deleteFile(index:number){
    this.files.splice(index, 1);
  }
  onFileSelected(event: any): void {
    this.errorSize=false;
    const files: FileList = event.target.files;
    this.handleFiles(files);
  }
  extractContentTypeFromBase64(base64Data: string): string {
    const match = base64Data.match(/^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    return match ? match[1] : '';
  }
  randomName(num:number){
    const characters= "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let result = "";
    let ch;
    while (result.length < num){
      ch = characters.charAt(Math.floor(Math.random() * charactersLength));
      if (!result.includes(ch)) result += ch;
    }
    return result;
  }
  async save() {

    if(this.data.name==='' || this.data.email==='' || this.data.message==='' || this.data.phone==='' ) return;

    let formData: FormData = new FormData();
    formData.append('name', this.data.name);
    formData.append('email', this.data.email);
    formData.append('message', this.data.message);
    formData.append('phone', this.data.phone);
    formData.append('username', this.data.username);

    for (let item in this.files){
      const ext=this.extractContentTypeFromBase64(this.files[item].src).split('/')[1];
      let name = this.randomName(10)+`imag${item}.${['gif','png','jpg','jpeg','mp4'].includes(ext) ? ext : 'png'}`;
      let blob = this.deviceInfo.dataURLtoFile(this.files[item].src.replace('data:', ''), name)
      if(blob)
        formData.append(`images`, blob,name);
    }
    this.loading=true;
    this.http.post('/support', formData).subscribe({
      next: (response: any) => {
        const {success} = response
        if (success) {
          this.loading=false;
          this.alertService.setCustomConfig({
            title: 'Message sent',
            description:'',
            containerClass:'logout-dialog',
            closeOnPress:false,
            activeButtonCancel:false
          });
          this.alertService.showCustomDialog();
          this.alertService.getIsOKPressed().subscribe(isOK => {
            if (isOK === true) {
              this.alertService.closeDialog()
              this.router.navigateByUrl('/profile/'+this.userInfo.me().user.id)
            }
          });
        } else {
          alert("Error")
          this.loading=false;
        }
      },
      error: (err:any) => {
        alert("Error")
        this.loading=false;
      }
    });
  }
}
