<div [ngClass]="classContainer">
  <div class="flex items-center justify-between pt-4 mb-4 bg-white px-4">
    <div class="flex gap-4 container-avatar">
      <img [src]="avatar" class="avatar">
      <div class="flex flex-col justify-center avatar-info">
        <p class="username">{{name}}
<!--          <span *ngIf="verifiedAccount" class="material-symbols-outlined verifiedAccount">-->
<!--          Done-->
<!--          </span>-->
        </p>
        <p class="font-poppins font-light hidden sm:block date">{{calculateDateDifference(date)}}</p>
        <p class="font-SF-Pro-Text text-[#77838F] sm:hidden description">@{{username}}</p>
      </div>
    </div>
    <span class="hidden sm:block material-symbols-outlined text-[#a9abad] cursor-pointer more-post hover:text-[#519935]">
      more_horiz
    </span>
    <span class="block sm:hidden material-symbols-outlined text-[#a9abad] text-[1.7rem] cursor-pointer hover:text-[#519935]">
      more_vert
    </span>
  </div>
  <hr class="hidden sm:block my-2 mx-4 border-[#EEEEEE]">
  <div class="relative">
    <p class="hidden sm:block px-4 py-4">{{title}}</p>

    <div class="flex justify-between gap-2  h-[500px] sm:h-[490px]">
      <div class="w-full">
        <img [src]="medios[0].url" class="h-full w-full object-cover rounded-lg">
      </div>
    </div>
  </div>
  <app-reactions-bar
    class="block py-4 px-4"
    [seeLabelsReactions]="true"
    [numberReactions]="{
      numberLikes:10,
      numberComments:110,
      numberShares:1,
      numberSaved:5,
  }" ></app-reactions-bar>
  <app-liked-bar class="block sm:hidden px-4"></app-liked-bar>
  <p class=" sm:hidden px-4 pt-4 font-medium">{{name}}</p>
  <p class=" sm:hidden px-4 pb-4">{{title}}</p>
</div>
