import {Component, inject, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {SearchAllService} from "../../../services/search-all/search-all.service";
import {Subscription} from "rxjs";
import {GiftService} from "../../../services/gift/gift.service";
import {DeviceInfoService} from "../../../services/device-info/device-info.service";

@Component({
  selector: 'app-search-all',
  templateUrl: './search-all.component.html',
  styleUrls: ['./search-all.component.scss']
})
export class SearchAllComponent implements OnDestroy, OnInit {
  deviceInfoService = inject(DeviceInfoService);
  searchAllService = inject(SearchAllService);
  giftService = inject(GiftService);
  search = ''
  users: any [] = []
  products: any [] = []
  posts: any [] = []
  limit = 5;
  offset = 0;
  subscription: Subscription | undefined;
  ngOnInit() {
    this.deviceInfoService.scrollTop()
    this.subscription = this.searchAllService.currentSearch.subscribe(async data => {
      this.search = data
      this.deviceInfoService.scrollTop()
      this.users = await this.searchAllService.getUsers({
        limit: this.limit,
        offset: this.offset,
        join: '|',
        in: 'og',
        name: this.search,
        username: this.search,
        lastname: this.search,
        about_me: this.search,
      }).toPromise() as any;
      let resPost = await this.searchAllService.getPost({
        limit: this.limit,
        offset: this.offset,
        join: '|',
        filters: JSON.stringify({
          in: 'og',
          postTitle: this.search,
          postContent: this.search,
          postHashtack: this.search,
        })
      }).toPromise() as any;
      this.posts = resPost?.posts || [];
      if(this.search!=null && this.search.trim()!='')
      this.products = await this.searchAllService.getProducts({
        limit: this.limit,
        offset: this.offset,
        join: '|',
        in: 'og',
        name: this.search,
        grow_information: this.search,
        description: this.search,
        isOwn:1
      }).toPromise() as any;

    });
  }

  ngOnDestroy() {
    // Unsubscribe to ensure no memory leaks
    if(this.subscription)
      this.subscription.unsubscribe();
  }

  claimgift(data:any){
    this.giftService.claimGift(data)
  }

  resultsNotFound() {
    return !this.posts?.length && !this.products?.length && !this.users?.length
  }
}
