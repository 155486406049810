import {Component, HostListener, inject, Input, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {RegisterPublicationComponent} from "../register-publication/register-publication.component";
import {UserInfoService} from "../../../services/user-info/user-info.service";
import {ChatService} from "../../../services/chat/chat.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-home-publications',
  templateUrl: './home-publications.component.html',
  styleUrls: ['./home-publications.component.scss']
})

export class HomePublicationsComponent implements OnInit, OnDestroy {
  @ViewChild(RegisterPublicationComponent, {static: false}) registerPublicationComponent: RegisterPublicationComponent | undefined;
  @Input() typePost = ''
  private http = inject(HttpClient);
  private userInfo = inject(UserInfoService);
  statusFetch = false;
  posts: any[] = [];
  user: any = {};
  limit = 5;
  offset = 0;
  chatService = inject(ChatService)
  ngOnChanges(changes: SimpleChanges) {
    if (changes['typePost']) {
      this.getData(true)
    }
  }
  deletePost(postId: string) {
    this.posts = this.posts.filter(post => post.postId !== postId);
  }
  subscriptionPost: Subscription | undefined
  getData(isReset = false) {
    this.statusFetch = true;
    const userId = '';
    let lastPostKey = '';
    if (this.posts.length > 0) {
      lastPostKey = this.posts[this.posts.length - 1].postId
    }
    if(isReset) {
      this.offset = 0;
      this.posts = [];
      window.scrollTo(0, 1);
    }
    this.subscriptionPost = this.http.get('/posts-filter', {
      params: {
        type: this.typePost.toLowerCase(),
        limit: this.limit,
        offset: this.offset,
        userId,
      }
    }).subscribe({
      next: (response: any) => {
        try {
          if(response.posts.length  === this.limit){
            this.offset += this.limit
          } else {
            this.offset += response.posts.length;
          }
        } catch (e) {
          console.error(e)
        }
        this.posts = [...this.posts, ...response.posts];
        this.statusFetch = false;

        this.subscriptionPost?.unsubscribe()
      }
    });

  }

  openFormPost() {
    this.registerPublicationComponent?.openForm();
  }

  addPost(post: any) {
    post['avatar'] = this.user.image_profile;
    post['name'] = this.user.name + ' ' + this.user.lastname;
    this.posts.unshift(post);
  }

  ngOnInit() {
    this.getData();
    this.user = this.userInfo.me()?.user || {image_profile: "https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png"};
  }

  ngOnDestroy() {
    this.subscriptionPost?.unsubscribe();
  }

  onScroll(event: Event): void {
    this.endScroll();
  }

  @HostListener('window:scroll', ['$event'])
  endScroll(): void {
    if (this.statusFetch) return;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    if (scrollTop + clientHeight >= scrollHeight - 100) {
      this.getData();
    }
  }

}
