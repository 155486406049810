<app-settings-layout [routeHeader]="'/profile/'+data.id" [useHeaderEdit]="true">
  <app-dialog #changePic [title]="type=='banner'?'Banner picture':'Profile picture'"
              [classHeaderDefault]="'justify-center pt-5 pb-3 text-lg'"
              [classModal]="(type=='banner'?'sm:!min-w-[800px] sm:!w-[800px]':'sm:!min-w-[400px] sm:!w-[500px]')+' bg-black/80 sm:bg-white text-white sm:text-black sm:relative'"
              classBody="">
    <div class="mx-[35px]">
      <image-cropper
        class="w-full !px-0"
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="aspectRatio"
        [resizeToWidth]="widthImage"
        [imageQuality]="50"
        format="png"
        output="base64"
        (imageCropped)="imageCropped($event)"
      ></image-cropper>
    </div>
    <div class="flex items-center justify-end gap-4 py-2 mx-[35px]">
      <button (click)="cropperReady()" class="flex justify-center w-[100px] !py-[6px] mb-2 h-fit gap-2 items-center font-Poppins font-normal bg-[#519935] text-white rounded-xl shadow-sm text-base">
        Save
      </button>
      <button (click)="closeCropper()" class="flex justify-center w-[100px] !py-[6px] border-2 border-[#DCDCDC] mb-2 h-fit gap-2 items-center font-Poppins font-normal bg-[#fff] text-[#202020] rounded-xl shadow-sm text-base">
        Discard
      </button>
    </div>
  </app-dialog>
  <app-dialog #addressDialog title="Delivery Address"
              [classHeaderDefault]="'justify-center pt-5 pb-3 text-lg'"
              [classModal]="(type=='banner'?'sm:!min-w-[800px] sm:!w-[800px]':'sm:!min-w-[500px] sm:!w-[500px]')+' bg-black/80 sm:bg-white text-white sm:text-black sm:relative'"
              classBody="">
    <app-maps-locations [locationDefault]="location" (accept)="setAccept($event)" *ngIf="openMap"></app-maps-locations>
<!--    <div class="flex items-center justify-end gap-4 py-2 mx-[35px]">-->
<!--      <button (click)="updateAddress()" class="flex justify-center w-[100px] !py-[6px] mb-2 h-fit gap-2 items-center font-Poppins font-normal bg-[#519935] text-white rounded-xl shadow-sm text-base">-->
<!--        Save-->
<!--      </button>-->
<!--      <button (click)="cancelUpdateAddress()" class="flex justify-center w-[100px] !py-[6px] border-2 border-[#DCDCDC] mb-2 h-fit gap-2 items-center font-Poppins font-normal bg-[#fff] text-[#202020] rounded-xl shadow-sm text-base">-->
<!--        Discard-->
<!--      </button>-->
<!--    </div>-->
  </app-dialog>
  <div class="w-full md:w-[calc(100%-236px)] md:max-w-[1050px]">
    <div class="px-6 my-2 sm:hidden">
      <app-arrow-left-icon [width]="35" [height]="35" color="#00000066" class="cursor-pointer" (touchend)="goBack()"/>
    </div>
    <div class=" sm:mx-10 mb-8 sm:mt-4 rounded-2xl flex flex-col bg-white sm:shadow-lg w-full sm:pb-8 overflow-hidden">
      <div class="p-4 sm:p-8">
        <h2 class="font-poppins font-medium text-[#202020] text-[22px] sm:text-[36px]">Profile</h2>
        <hr class="bw-1 border-[##00000014] h-0.5 z-10 my-6">
      </div>
      <div class="px-8 pt-0 sm:pt-8 flex flex-col gap-8 w-full ">
        <div class="flex flex-col sm:flex-row justify-between items-start gap-4">
          <p class="hidden sm:flex font-poppins text-[14px] text-[#202020] w-[150px]">Profile picture</p>
          <div class="flex flex-row sm:flex-col sm:justify-center items-center gap-4">
            <img class="inline-block h-16 w-16 rounded-full" (click)="selectImagePic('image_profile')"
                 [src]="data.image_profile" alt="Profile Image">
            <input type="file" (change)="onChangePic()" class="opacity-0 w-0 h-0" name="change-pic" accept="image/*" #inputChangePic>
            <button (click)="selectImagePic('image_profile')" class="flex px-4 !py-[10.5px] mb-2 h-fit gap-2 items-center font-normal text-xs bg-[#519935] text-white rounded-xl shadow-sm">
              Change profile pic
            </button>
          </div>
          <div class="w-full sm:w-[calc(100%-350px)]">
            <img *ngIf="data.banner" (click)="selectImagePic('banner')" class="border-2 border-[#CCEABB] border-dashed rounded-xl"
                 [src]="data.banner" alt="Profile Image">
            <div *ngIf="!data.banner" (click)="selectImagePic('banner')" class="border-2 border-[#CCEABB] border-dashed rounded-xl bg-[#F5F9F280] flex items-center justify-center gap-8 py-12">
              <div class="bg-white h-12 w-12 px-4 rounded-full flex items-center justify-center">
                <app-image-add-icon [width]="24" [height]="24" color="#99C183"></app-image-add-icon>
              </div>
              <div>
                <p class="font-poppins font-semibold text-sm text-[#99C183]">Upload Cover Image (19:6)</p>
                <p class="font-poppins font-normal text-sm text-[#99C183]">Drop your file here or browse</p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col sm:flex-row justify-between sm:items-center gap-4">
          <p class="font-poppins text-[14px] text-[#202020] w-[150px]">Full Name</p>
          <div class="flex  w-full sm:w-[calc(100%-150px)] items-center gap-4">
            <app-om-input color="white" [(ngModel)]="data.name"
                          inputClass="!bg-white !py-[9px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px]"
                          placeholder="Name"
                          class="w-[50%]">
            </app-om-input>
            <app-om-input color="white" [(ngModel)]="data.lastname"
                          inputClass="!bg-white !py-[9px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px]"
                          placeholder="Lastname"
                          class="w-[50%]">
            </app-om-input>
          </div>
        </div>
        <div class="flex flex-col sm:flex-row justify-between sm:items-center gap-4">
          <p class="font-poppins text-[14px] text-[#202020] w-[150px]">About me</p>
          <div class="flex  w-full sm:w-[calc(100%-150px)] items-center gap-4">
            <ckeditor class="w-full"
                      [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic', 'Underline', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'undo', 'redo' ] }"
                      [editor]="Editor" [(ngModel)]="data.about_me" data="<p>Hello, world!</p>"></ckeditor>
          </div>
        </div>
        <div class="flex flex-col sm:flex-row justify-between sm:items-center gap-4">
          <p class="font-poppins text-[14px] text-[#202020] w-[150px]">Company</p>
          <div class="flex  w-full sm:w-[calc(100%-150px)] items-center gap-4">
            <app-om-input color="white" [(ngModel)]="data.company"
                          inputClass="!bg-white !py-[9px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px]"
                          placeholder="Company"
                          class="w-[100%]">
            </app-om-input>
          </div>
        </div>
        <div class="flex flex-col sm:flex-row justify-between sm:items-center gap-4">
          <p class="font-poppins text-[14px] text-[#202020] w-[150px]">Company phone</p>
          <div class="flex relative w-full sm:w-[calc(100%-150px)] items-center gap-4">
            <div class="absolute w-[70px] z-10">
              <app-autocomplete [(keyword)]="dial_code" (onChange)="setCountryPhone($event)" labelTemplate="dial_code" key="dial_code"
                                [options]="countriesPhone" class="w-full"
                                inputClass="!bg-white !py-[9.5px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px]"></app-autocomplete>
            </div>
            <app-om-input color="white" [(ngModel)]="data.phone" type="number"
                          [maxlength]="10"
                          inputClass="!bg-white !pl-20 !py-[9px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px]"
                          placeholder="Company phone"
                          class="w-[100%]">
            </app-om-input>
          </div>
        </div>
        <div class="flex flex-col sm:flex-row justify-between sm:items-center gap-4">
          <p class="font-poppins text-[14px] text-[#202020] w-[150px]">Company site</p>
          <div class="flex  w-full sm:w-[calc(100%-150px)] items-center gap-4">
            <app-om-input color="white" [(ngModel)]="data.site"
                          inputClass="!bg-white !py-[9px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px]"
                          placeholder="Company site"
                          class="w-[100%]">
            </app-om-input>
          </div>
        </div>
        <div class="flex flex-col sm:flex-row justify-between sm:items-center gap-4">
          <p class="font-poppins text-[14px] text-[#202020] w-[150px]">Address</p>
          <div class="flex  w-full sm:w-[calc(100%-150px)] items-center gap-4">
            <app-om-input color="white" [(ngModel)]="address" (click)="openEditAddress()"
                          inputClass="!bg-white !py-[9px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px]"
                          placeholder="Address"
                          class="w-[100%]">
            </app-om-input>
          </div>
        </div>
        <div class="flex flex-col sm:flex-row justify-between sm:items-center gap-4">
          <p class="font-poppins text-[14px] text-[#202020] w-[150px]">Country</p>
          <div class="flex  w-full sm:w-[calc(100%-150px)] items-center gap-4 relative">
            <app-autocomplete [(keyword)]="country" (onChange)="setCountry($event)" label="nameCountry" key="codeCountry"
                              [options]="countries" class="w-full"
                              inputClass="!bg-white !py-[9.5px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px]"></app-autocomplete>
            <!--              <app-om-input color="white" [(ngModel)]="data.country"-->
            <!--                            inputClass="!bg-white !py-[9px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px]"-->
            <!--                            placeholder="Company name"-->
            <!--                            class="w-[100%]">-->
            <!--              </app-om-input>-->
          </div>
        </div>
        <div class="flex flex-col sm:flex-row justify-between sm:items-center gap-4" *ngIf="regions.length">
          <p class="font-poppins text-[14px] text-[#202020] w-[150px]">Region</p>
          <div class="flex  w-full sm:w-[calc(100%-150px)] items-center gap-4 relative">
            <app-autocomplete [(keyword)]="region" (onChange)="setRegion($event)" label="nameRegion" key="codeRegion"
                              [options]="regions" class="w-full"
                              inputClass="!bg-white !py-[9.5px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px]"></app-autocomplete>
            <!--              <app-om-input color="white" [(ngModel)]="data.country"-->
            <!--                            inputClass="!bg-white !py-[9px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px]"-->
            <!--                            placeholder="Company name"-->
            <!--                            class="w-[100%]">-->
            <!--              </app-om-input>-->
          </div>
        </div>
        <div class="flex flex-col sm:flex-row justify-between sm:items-center gap-4">
          <p class="font-poppins text-[14px] text-[#202020] w-[150px]">Language</p>
          <div class="flex  w-full sm:w-[calc(100%-150px)] items-center gap-4" *ngIf="languages?.length">
            <app-om-select id="Language" class="w-full"
                           containerClass="!bg-white !py-[4.5px] !font-poppins !font-medium bw-1 border-gray !text-[14px]"
                           containerItemsClass="!max-h-fit"
                           itemsClass="bg-white border-[#EEEEEE] !text-black hover:bg-white hover:!text-green4-native !py-1"
                           color="gray" [options]="languages" [(ngModel)]="data.language" required
                           #selectedNav="ngModel" [default]="data.language" placeholder="Please select"
            />
          </div>
        </div>
        <div class="flex flex-col sm:flex-row justify-between sm:items-center gap-4">
          <p class="font-poppins text-[14px] text-[#202020] w-[150px]">Time Zone</p>
          <div class="flex  w-full sm:w-[calc(100%-150px)] items-center gap-4 relative" *ngIf="!!timeZones?.length">
            <app-autocomplete [(keyword)]="timeZone" (onChange)="setTimeZone($event)"
                              [options]="timeZones" class="w-full"
                              inputClass="!bg-white !py-[9.5px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px]"></app-autocomplete>
            <app-chevron-down-icon class="bg-white absolute top-3 right-2" [width]="20"
                                   [height]="20"></app-chevron-down-icon>
            <!--              <app-om-select id="timeZone" class="w-full"-->
            <!--                             containerClass="!bg-white !py-[4.5px] !font-poppins !font-medium bw-1 border-gray !text-[14px]"-->
            <!--                             itemsClass="bg-white border-[#EEEEEE] !text-black hover:bg-white hover:!text-green4-native !py-1"-->
            <!--                             color="gray" [options]="timeZones" [(ngModel)]="data.time_zone" required-->
            <!--                             #selectedNav="ngModel" [default]="data.time_zone" placeholder="Please select"-->
            <!--              />-->
          </div>
        </div>
        <div class="flex flex-col sm:flex-row justify-between sm:items-center gap-4">
          <p class="font-poppins text-[14px] text-[#202020] w-[150px]">Currency</p>
          <div class="flex  w-full sm:w-[calc(100%-150px)] items-center gap-4 relative">
            <app-autocomplete [(keyword)]="currency" (onChange)="setCurrency($event)"
                              [options]="currencies" class="w-full"
                              inputClass="!bg-white !py-[9.5px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px]"></app-autocomplete>
            <app-chevron-down-icon class="bg-white absolute top-3 right-2" [width]="20"
                                   [height]="20"></app-chevron-down-icon>
            <!--              <app-om-input color="white" [(ngModel)]="data.currency"-->
            <!--                            inputClass="!bg-white !py-[9px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px]"-->
            <!--                            placeholder="Currency"-->
            <!--                            class="w-[100%]">-->
            <!--              </app-om-input>-->
          </div>
        </div>
        <div class="flex flex-col sm:flex-row justify-between sm:items-center gap-4">
          <p class="font-poppins text-[14px] text-[#202020] w-[150px]">Communication</p>
          <div class="flex  w-full sm:w-[calc(100%-150px)] items-center gap-4">
            <label for="email_communication" class="flex items-center justify-center text-sm gap-2">
              <input type="checkbox" id="email_communication" [(ngModel)]="data.email_communication" #email_communication="ngModel"
                     value="email"
                     class="all checked:!bg-[#519935] checked:border-[#519935] !border-[#898989] h-[calc(var(--mw-height)*0.8)] w-[calc(var(--mw-height)*0.8)]" required>
              Email
            </label>
            <label for="phone_communication" class="flex items-center justify-center text-sm gap-2">
              <input type="checkbox" id="phone_communication" [(ngModel)]="data.phone_communication" #phone_communication="ngModel"
                     value="phone"
                     class="all checked:!bg-[#519935] checked:border-[#519935] !border-[#898989] h-[calc(var(--mw-height)*0.8)] w-[calc(var(--mw-height)*0.8)]" required>
              Phone
            </label>
          </div>
        </div>
        <div class="flex flex-col sm:flex-row justify-between sm:items-center gap-4">
          <p class="font-poppins text-[14px] text-[#202020] w-[150px]">What best describes you?</p>
          <div class="w-full sm:w-[calc(100%-150px)]">
            <div class="grid grid-cols-2 gap-4">
                <label *ngFor="let best_describe of cat_best_describes" [for]="'describe_'+best_describe.id" class="flex items-center text-sm gap-2">
                  <input type="checkbox" [id]="'describe_'+best_describe.id"
                         [checked]="inBestDescription(best_describe.id)"
                         (change)="onCheckboxChangeBest($event,best_describe.id)"
                         [value]="best_describe.id"
                         name="best_describe[]"
                         class="all checked:!bg-[#519935] checked:border-[#519935] !border-[#898989] h-[calc(var(--mw-height)*0.8)] w-[calc(var(--mw-height)*0.8)]" >
                  {{ best_describe.name }}
                </label>
            </div>
            <p *ngIf="countTypeOfGrower()===0 && countSharables>0" class="mt-4 font-poppins text-[14px] text-red-500">You have to be a grower</p>
            <p *ngIf="countTypeOfGrower()>1" class="mt-4 font-poppins text-[14px] text-red-500">You can only choose one type of grower</p>
          </div>
        </div>
        <div class="flex flex-col sm:flex-row justify-between sm:items-center gap-4">
          <p class="font-poppins text-[14px] text-[#202020] w-[150px]">Allow Marketing</p>
          <div class="flex  w-full sm:w-[calc(100%-150px)] items-center gap-4">
            <label class="switch">
              <input type="checkbox" id="allow_marketing" [(ngModel)]="data.allow_marketing" #allow_marketing="ngModel" checked>
              <span class="sw-slider round"></span>
            </label>
          </div>
        </div>
      </div>
      <hr class="bw-1 border-[##00000014] h-0.5 z-10 my-6">
      <div class="flex items-center justify-end gap-4 px-8">
        <button class="flex px-4 !py-[10.5px] border-2 border-[#DCDCDC] mb-2 h-fit gap-2 items-center font-normal text-xs bg-[#fff] text-[#202020] rounded-xl shadow-sm">
          Discard
        </button>
        <button (click)="updateProfile()" class="flex px-4 !py-[10.5px] mb-2 h-fit gap-2 items-center font-normal text-xs bg-[#519935] text-white rounded-xl shadow-sm"
                [disabled]="(countTypeOfGrower()===0 && countSharables>0) || countTypeOfGrower()>1"
                [class]="{
                'opacity-50':(countTypeOfGrower()===0 && countSharables>0) || countTypeOfGrower()>1
                }"
        >
          Save changes
        </button>
      </div>
    </div>
  </div>
</app-settings-layout>
