<div class="px-4 pl-4 h-full flex flex-col justify-between
      pt-[var(--sat)]
      bg-black bg-cover sm:bg-none bg-center
      sm:h-[75vh] sm:w-[36.6vw] sm:bg-[#2e2e2e]
      sm:mt-[calc(var(--w-height)*10)]
      sm:pt-[calc(var(--w-height)*8)]
      sm:pb-[calc(var(--w-height)*4)]
      sm:mr-[calc(var(--w-height)*15)]
      sm:px-[calc(var(--w-width)*6)]
      sm:rounded-[2vw]">
  <ng-icon name="matArrowBack" class="text-white my-4 text-4xl inline-block sm:!hidden" (click)="previous()"></ng-icon>
  <div
    class="w-full flex justify-center items-center flex-col md:flex-col-reverse">
    <div class="relative mt-[calc(var(--mw-height)*15)] sm:mt-[calc(var(--w-height)*5)]">
      <svg class="absolute z-10 ml-[calc(48%-35px)] " height="50px" width="50px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 293.334 293.334" xml:space="preserve" fill="#000000" stroke="#000000" stroke-width="0.29333400000000004"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path style="fill:#296115;" d="M146.667,0C94.903,0,52.946,41.957,52.946,93.721c0,22.322,7.849,42.789,20.891,58.878 c4.204,5.178,11.237,13.331,14.903,18.906c21.109,32.069,48.19,78.643,56.082,116.864c1.354,6.527,2.986,6.641,4.743,0.212 c5.629-20.609,20.228-65.639,50.377-112.757c3.595-5.619,10.884-13.483,15.409-18.379c6.554-7.098,12.009-15.224,16.154-24.084 c5.651-12.086,8.882-25.466,8.882-39.629C240.387,41.962,198.43,0,146.667,0z M146.667,144.358 c-28.892,0-52.313-23.421-52.313-52.313c0-28.887,23.421-52.307,52.313-52.307s52.313,23.421,52.313,52.307 C198.98,120.938,175.559,144.358,146.667,144.358z"></path> <circle style="fill:#296115;" cx="146.667" cy="90.196" r="21.756"></circle> </g> </g> </g></svg>
      <div class="h-[calc(var(--mw-height)*30)] sm:h-[calc(var(--w-height)*25)]
         w-[calc(var(--mw-height)*30)] sm:w-[calc(var(--w-height)*25)]
         bg-[url('assets/general/mapa.webp')] bg-center bg-[length:735px_643px] sm:bg-[length:665px_573px]
          rounded-full overflow-hidden">
      </div>
    </div>
    <div>
      <p class="title-form text-center sm:text-left text-[calc(var(--w-height)*4.5)]
        pr-[calc(var(--mw-width)*0)] pt-[calc(var(--mw-width)*5)] sm:pt-[calc(var(--w-width)*0)]
        sm:pr-[calc(var(--w-width)*10)] sm:text-[calc(var(--w-height)*4.5)]">
        Location</p>
      <p class="text-white text-center sm:text-left mt-[calc(var(--mw-height)*3)]
        sm:mt-[calc(var(--w-height)*2)] sm:text-white
        text-[calc(var(--mw-height)*2.5)]
        pt-[calc(var(--mw-height)*2)]
        px-[calc(var(--mw-height)*2)]
        sm:px-[calc(var(--w-height)*0)]
        leading-[calc(var(--mw-height)*3.2)]
        sm:text-[calc(var(--w-height)*2)]">
        Enable location services to confirm that you are
        within a legalized location.</p>
    </div>
  </div>
  <div class="w-full flex flex-col items-center gap-2 pt-[calc(var(--mw-height)*3)]
        pb-[calc(var(--mw-height)*1)] px-[calc(var(--mw-height)*1)] pb-[calc(var(--mw-height)*6)] sm:mt-[calc(var(--w-height)*4)]">
    <app-om-button [text]="'Enable'"
                   class="w-full"
                   [height]="{xs:'7',sm:'6'}"
                   [fontSize]="{xs:'3',sm:'2.6'}"
                   [iconPosition]="'right'"
                   (click)="requestLocationPermission()">
      <img alt="flecha" [class]="'w-[calc(var(--mw-height)*3)] sm:w-[calc(var(--w-height)*3.5)] sm:hidden'" src="assets/general/flecha_larga.png">
    </app-om-button>
    <app-om-button [text]="'Skip'"
                   class="w-full"
                   [bgColor]="'#ffffff00'"
                   [height]="{xs:'7',sm:'6'}"
                   [fontSize]="{xs:'2.7',sm:'2.6'}"
                   [marginTop]="{xs:'1.5',sm:'0'}"
                   [iconPosition]="'right'"
                   [marginBottom]="{xs:'1.5',sm:'1.5'}"
                   [iconClass]="'arrowskip'"
                   (click)="sendDataC()">
      <img alt="flecha" [class]="'md:hidden w-[calc(var(--mw-height)*2.4)] sm:w-[calc(var(--w-height)*3.5)]'" src="assets/general/flecha.png">
      <img alt="flecha" [class]="'hidden md:block w-[calc(var(--mw-height)*3)] sm:w-[calc(var(--w-height)*3.5)]'" src="assets/general/flecha_larga.png">
    </app-om-button>
<!--    <p-->
<!--      class="text-white text-center m-[calc(var(&#45;&#45;mw-height)*3)] sm:mb-[calc(var(&#45;&#45;w-height)*6)] text-[calc(var(&#45;&#45;mw-height)*1.4)]-->
<!--          hidden sm:block sm:absolute sm:w-[36.6vw] sm:right-[calc(var(&#45;&#45;w-height)*15)] sm:bottom-0 sm:mt-[calc(var(&#45;&#45;w-height)*3)] sm:text-[calc(var(&#45;&#45;w-height)*2)]"-->
<!--    >Have an account? <span-->
<!--      class="text-green-native cursor-pointer underline" routerLink="/login">Sign in now.</span></p>-->
  </div>
</div>
