import {Component, EventEmitter, Input, Output} from '@angular/core';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-maps-locations',
  templateUrl: './maps-locations.component.html',
  styleUrls: ['./maps-locations.component.scss']
})
export class MapsLocationsComponent {
  @Input() locationDefault: { lat: number, lng: number, address: string } | null = null;
  @Output() accept = new EventEmitter<{ lng: number, lat: number, address: string } | null>();
  typeMap:string=environment.typeMap;
  setAccept($event: { lng: number; lat: number; address: string } | null) {
    this.accept.emit($event);
  }
}
