<div class="flex flex-col bg-white gap-4 pt-4 pb-4 ocultarScroll">
  <div class="flex flex-col text-[16px] text-[#000000] gap-4">
    <p class="font-poppins font-medium text-green4-native text-[14px] px-8 pt-4">Sponsored</p>
    <div class="flex flex-col gap-6">
      <div class="px-8 w-[336px]">
        <app-custom-carousel [isMini]="true" class="block w-full"></app-custom-carousel>
      </div>
<!--      <div-->
<!--        class="flex items-center h-fit px-8 w-[336px] font-poppins relative font-medium justify-start hover:text-green4-native">-->
<!--        <img src="assets/samples/sample-cannabis.png" class="w-full rounded-md" alt="test">-->
<!--        <div class="absolute w-[336px] text-[16px] leading-[1.3] text-white font-Montserrat font-bold right-0 px-12 pr-28 flex items-center justify-center bottom-8 gap-2">-->
<!--          Find the best strains for you to personalize your experience.-->
<!--        </div>-->
<!--        <div class="absolute w-[336px] right-0 px-8 flex items-center justify-center bottom-4 gap-2">-->
<!--          <span class="relative inline-flex rounded-full h-2 w-2 bg-green-native"></span>-->
<!--          <span class="relative inline-flex rounded-full h-2 w-2 bg-white"></span>-->
<!--          <span class="relative inline-flex rounded-full h-2 w-2 bg-white"></span>-->
<!--        </div>-->
<!--        <div class="absolute w-[336px] right-4 px-8 flex items-center justify-end bottom-4 gap-2">-->
<!--          <button class=" text-[9.5px] font-Montserrat font-bold rounded-full bg-green4-native px-3 py-1 text-sm text-white shadow-sm hover:bg-green4-native/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green4-native">-->
<!--            Find strains-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
<!--  <div class="flex flex-col text-[16px] text-[#000000] gap-4">-->
<!--    <p class="font-poppins font-medium text-green4-native text-[14px] px-8 pt-4">Group Chats</p>-->
<!--    <div class="flex flex-col gap-6">-->
<!--      <div-->
<!--        class="flex items-center h-fit px-8 w-[336px] font-poppins font-medium justify-start hover:text-green4-native">-->
<!--        <div class="bg-[url(assets/demo/9.jpeg)] bg-cover h-8 w-8 rounded-md mr-4"></div>-->
<!--        <p>Ontario Growers</p>-->
<!--      </div>-->
<!--      <div-->
<!--        class="flex items-center h-fit px-8 w-[336px] font-poppins font-medium justify-start hover:text-green4-native">-->
<!--        <div class="bg-[url(assets/demo/10.jpeg)] bg-cover h-8 w-8 rounded-md mr-4"></div>-->
<!--        <p>Grow Team</p>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
  <div class="flex flex-col text-[16px] text-[#000000] gap-4">
    <p class="font-poppins font-medium text-green4-native text-[14px] px-8 pt-4">Network</p>
    <div class="flex flex-col gap-6">
      <div *ngFor="let user of users" (click)="openChat(user)"
        class="flex items-center h-fit cursor-pointer px-8 w-[336px] font-poppins font-medium relative justify-start hover:text-green4-native">
        <div class="relative mr-4">
          <img [src]="user.image_profile || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'" class="h-8 w-8 rounded-full " alt="profile">
          <div *ngIf="user.is_founder===1" class="absolute bottom-0 right-0 w-[20px] h-[20px] sm:w-[45%] sm:h-[45%] p-[2px] rounded-[15px] sm:rounded-full bg-white">
            <img src="assets/ogfc.png" alt="" class="object-cover w-full">
          </div>
        </div>
        <p class="whitespace-nowrap overflow-ellipsis overflow-hidden w-[calc(100%-30px)]">{{getName(user)}}</p>
        <div class="absolute right-8">
          <span class="relative flex h-2.5 w-2.5">
            <span class="absolute inline-flex h-full w-full rounded-full bg-green-native opacity-75"></span>
            <span class="relative inline-flex rounded-full h-2.5 w-2.5 bg-green-native"></span>
          </span>
        </div>
      </div>
    </div>
  </div>
<!--  <div class="flex flex-col text-[16px] text-[#000000] gap-4">-->
<!--    <p class="font-poppins font-medium text-green4-native text-[14px] px-8 pt-4">Featured Growers</p>-->
<!--    <div class="flex flex-col gap-6">-->
<!--      <div-->
<!--        class="flex items-center h-fit px-8 w-[336px] font-poppins font-medium justify-start hover:text-green4-native">-->
<!--        <img src="assets/samples/sample-woman2.png" class="mr-4 w-8 rounded-md" alt="test">-->
<!--        <p>Scarlett Tenant</p>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
  <div class="fixed right-4 bottom-8">
    <button
      class="px-2 !py-[10.5px] gap-2 flex items-center font-Montserrat font-semibold text-xs bg-[#519935] text-white rounded-full shadow-sm"
      routerLink="/messages">
      <app-message-icon [width]="20" [height]="20" color="#fff"/>
      Messages
    </button>
  </div>
</div>
