import {Component, EventEmitter, inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {DialogComponent} from "../../../general/dialog/dialog.component";
import {FollowAndFriendService} from "../../../../services/follow-and-friend/follow-and-friend.service";
import {ChatService} from "../../../../services/chat/chat.service";
import {ToastService} from "../../../../services/toast/toast.service";

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent {
  http = inject(HttpClient);
  toastService = inject(ToastService);
  chatService = inject(ChatService);
  followAndFriendService = inject(FollowAndFriendService);
  @Output() clickIconShare = new EventEmitter<any>();
  @ViewChild('setUser', { static: false }) setUser!: DialogComponent;
  @ViewChild('share', { static: false }) share!: DialogComponent;
  @Input() infoPost: any = {};
  @Input() classLabel = '';
  @Input() classIcon = '';
  @Input() seeLabelsReactions = true;
  @Input() numberShares = 0;
  option = 'dm'
  users: any[] = [];
  shareUsers: any = {};

  openShare() {
    if(['dm','friend'].includes(this.option)){
      this.getUsers();
    }
  }
  allowShare() {
    return Object.keys(this.shareUsers).reduce((acc: boolean[], key) => {
      if (this.shareUsers[key] === true) {
        acc.push(Boolean(key));
      }
      return acc;
    }, [])
  }
  sharePost() {
    if(this.option === 'dm') {
      let post  = {
        name: this.infoPost.name || this.infoPost.username,
        description: this.infoPost.description,
        title: this.infoPost.title,
        avatar: this.infoPost.avatar,
        media: this.infoPost.medios,
        id: this.infoPost.postId,
      }
      for(let userTo in this.shareUsers){
       if(this.shareUsers[userTo]) {
         let inbox = this.chatService.getInboxes().find((x: any) => x.idUserTo === userTo || x.idUserFrom === userTo);
         if (!inbox) {
           this.chatService.create(userTo);
           setTimeout(() => {
             let inbox = this.chatService.getInboxes().find((x: any) => x.idUserTo === userTo || x.idUserFrom === userTo);
             this.chatService.sendMessage({
               message: 'OG-post:' + JSON.stringify(post),
               inboxId: (inbox as any)?.inboxId,
               userTo: userTo
             });
           }, 500)
         } else {
           this.chatService.sendMessage({
             message: 'OG-post:' + JSON.stringify(post),
             inboxId: (inbox as any)?.inboxId,
             userTo: userTo
           });
         }
       }
      }
      this.shareUsers = {};
      this.setUser.closeB();
      this.toastService.show("Dispatched");
      this.clickIconShare.emit();
      return;
    }

    this.setUser.closeB();
    this.share.open();
  }
  getUsers() {
    this.followAndFriendService.getFollowings(true).then((r: any) => {
      this.users = r || []
      this.setUser.open()
    })
  }

  protected readonly JSON = JSON;
  protected readonly Object = Object;
}
