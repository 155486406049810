import {inject, Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class FriendsService {
  http = inject(HttpClient)
  users = [];
  constructor() { }

  async getUsers() {
    if(this.users.length){
      return this.users;
    } else {
      await this.setUsers();
      return this.users;
    }
  }

  setUsers() {
    return new Promise<void>((resolve, reject) => {
      this.http.get('/users').subscribe({
        next: (response: any) => {
          this.users = response?.records || [];
          resolve();
        },
        error: (err) => {
          reject(err);
        }
      });
    });
  }
}
