<!--Starts desktop/mobile sidebar-->
<div class="absolute bg-white w-full z-20 pt-[calc(var(--sat)+12px)] sm:relative flex flex-col gap-4 sm:pt-4 h-full sm:h-[calc(100vh-101px)] ocultarScroll">
  <div class="block sm:hidden">
    <div class="flex justify-end pr-4">
      <app-close-icon color="#000" [width]="28" (click)="toggle()"/>
    </div>
    <div (click)="toggle()" routerLink="/profile/{{user.id}}"
      class="flex items-end h-fit px-6 mb-8 w-full font-poppins font-medium justify-start border-l-4 hover:border-green4-native hover:text-green4-native border-white">
      <img [src]="user.image_profile || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'" class=" mr-4 w-16 rounded-md" alt="test">
      <div>
        <p class="username">{{getName()}}</p>
        <p class="font-SF-Pro-Text text-[#77838F]">Verified Growner</p>
      </div>
    </div>
  </div>
  <div class="mb-auto">
    <div class="text-[#00000066] text-[16px] flex flex-col gap-4">
      <div routerLink="/home" (click)="toggle()"
           class="cursor-pointer flex items-center h-fit px-6 w-[230px] font-poppins font-normal justify-start border-l-4 hover:border-green4-native hover:text-green4-native border-white"
      [class]="{'!border-green4-native': (router.url.split('?'))[0] === '/home'}">
        <app-menu-block-icon [height]="24" [width]="24" class="mr-4" [color]="router.url === '/home' ? '#519935' : '#b5b5be'"></app-menu-block-icon>
        <p>Home</p>
      </div>
      <div routerLink="/gift-center" (click)="toggle()"
        class="cursor-pointer flex items-center h-fit px-6 w-[230px] font-poppins font-normal justify-start border-l-4 hover:border-green4-native hover:text-green4-native border-white"
           [class]="{'!border-green4-native': (router.url.split('?'))[0] === '/gift-center'}">
        <app-gift-icon [height]="25" [width]="25" class="mr-4" [color]="router.url === '/gift-center' ? '#519935' : '#b5b5be'"></app-gift-icon>
        <p [class]="{'text-green4-native': (router.url.split('?'))[0] === '/gift-center'}">Gifts</p>
      </div>
      <div routerLink="/strain-finder" (click)="toggle()"
        class="cursor-pointer flex items-center h-fit px-6 w-[230px] font-poppins font-normal justify-start border-l-4 hover:border-green4-native hover:text-green4-native border-white"
           [class]="{'!border-green4-native': (router.url.split('?'))[0] === '/strain-finder'}">
        <app-og-marketplace [height]="25" [width]="25" class="mr-4" [color]="router.url === '/strain-finder' ? '#519935' : '#b5b5be'"></app-og-marketplace>
        <p [class]="{'text-green4-native': (router.url.split('?'))[0] === '/strain-finder'}">Strain Finder</p>
      </div>
      <div routerLink="/growers-home" (click)="toggle()"
        class="cursor-pointer flex items-center h-fit px-6 w-[230px] font-poppins font-normal justify-start border-l-4 hover:border-green4-native hover:text-green4-native border-white"
        [class]="{'!border-green4-native': (router.url.split('?'))[0] === '/growers-home'}">
        <app-grower-icon [height]="25" [width]="25" class="mr-4" [color]="router.url === '/growers-home' ? '#519935' : '#b5b5be'"></app-grower-icon>
        <p [class]="{'text-green4-native': (router.url.split('?'))[0] === '/growers-home'}">Growers</p>
      </div>
      <div routerLink="/coaching" (click)="toggle()"
        class="cursor-pointer flex items-center h-fit px-6 w-[230px] font-poppins font-normal justify-start border-l-4 hover:border-green4-native hover:text-green4-native border-white"
        [class]="{'!border-green4-native': (router.url.split('?'))[0] === '/coaching'}">
        <app-og-coaching [width]="30" [color]="router.url === '/coaching' ? '#519935' : '#b5b5be'" class="mr-4 w-6"/>
        <p [class]="{'text-green4-native': (router.url.split('?'))[0] === '/coaching'}">Coaching</p>
      </div>
      <div routerLink="/messages" (click)="toggle()"
        class="cursor-pointer flex items-center h-fit px-6 w-[230px] font-poppins font-normal justify-start border-l-4 hover:border-green4-native hover:text-green4-native border-white"
        [class]="{'!border-green4-native': (router.url.split('?'))[0] === '/messages'}">
        <app-message-icon [width]="28" [height]="28" class="mr-4" [color]="router.url === '/messages' ? '#519935' : '#b5b5be'"></app-message-icon>
        <p [class]="{'text-green4-native': (router.url.split('?'))[0] === '/messages'}">Messages</p>
      </div>
      <div routerLink="/communities" (click)="toggle()"
        class="cursor-pointer flex items-center h-fit px-6 w-[230px] font-poppins font-normal justify-start border-l-4 hover:border-green4-native hover:text-green4-native border-white"
           [class]="{'!border-green4-native': (router.url.split('?'))[0] === '/communities'}">
        <img src="assets/icons-sax/communities.svg" class="mr-4" alt="test">
        <p [class]="{'text-green4-native': (router.url.split('?'))[0] === '/communities'}">Communities</p>
      </div>
      <div routerLink="/friends" (click)="toggle()"
        class="cursor-pointer flex items-center h-fit px-6 w-[230px] font-poppins font-normal justify-start border-l-4 hover:border-green4-native hover:text-green4-native border-white"
           [class]="{'!border-green4-native': (router.url.split('?'))[0] === '/friends'}">
        <img src="assets/icons-sax/users.svg" class="mr-4" alt="test">
        <p [class]="{'text-green4-native': (router.url.split('?'))[0] === '/friends'}">Friends</p>
      </div>
    </div>
    <div class="hidden md:flex flex-col text-[14px] text-[#77838F] gap-2">
      <p class="font-Montserrat text-green4-native text-[13px] px-6 pt-4">Communities</p>

      <div class="flex flex-col gap-4">
        <div
          *ngFor="let community of communities"
          [routerLink]="'/community'" [queryParams]="{ id: community.communityId }"
          class="cursor-pointer flex items-center h-fit px-6 w-[230px] font-poppins font-medium justify-start border-l-4 hover:border-green4-native hover:text-green4-native border-white">
          <img [src]="community.communityImgProfile" [alt]="community.communityName" class="h-8 w-8 rounded-lg mr-4">
          <p>{{ community.communityName }}</p>
        </div>
      </div>
    </div>
<!--    <div class="hidden md:flex flex-col text-[14px] text-[#77838F] gap-2">-->
<!--      <p class="font-Montserrat text-green4-native text-[13px] px-6 pt-4">Coach's Feed</p>-->
<!--      <div class="flex flex-col gap-4">-->
<!--        <div-->
<!--          class="cursor-pointer flex items-center h-fit px-6 w-[230px] font-poppins font-medium justify-start border-l-4 hover:border-green4-native hover:text-green4-native border-white">-->
<!--          <img src="assets/samples/sample-woman2.png" class="mr-4 w-8 rounded-md" alt="test">-->
<!--          <p>Scarlett Tenant</p>-->
<!--        </div>-->
<!--        <div-->
<!--          class="cursor-pointer flex items-center h-fit px-6 w-[230px] font-poppins font-medium justify-start border-l-4 hover:border-green4-native hover:text-green4-native border-white">-->
<!--          <div class="bg-[url(assets/demo/2.jpeg)] mr-4 bg-cover h-8 w-8 rounded-lg"></div>-->
<!--          <p>Emma Stones</p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
  <div class="flex flex-col text-[16px] text-green4-native gap-4 py-8 md:pt-8">
    <div routerLink="/settings/support" (click)="toggle()"
      class="cursor-pointer flex items-center h-fit px-6 w-[230px] font-poppins font-normal justify-start border-l-4 hover:border-green4-native hover:text-green4-native border-white">
      <img src="assets/icons-sax/question.svg" class="mr-4 w-6 rounded-md opacity-70" alt="test">
      <p>Support</p>
    </div>
    <div routerLink="/terms-and-conditions-app" (click)="toggle()"
      class="cursor-pointer flex items-center h-fit px-6 pl-5 w-[230px] font-poppins font-normal justify-start border-l-4 hover:border-green4-native hover:text-green4-native border-white">
      <app-secure-icon [width]="30" [height]="30" color="#131313" class="mr-5 w-6 rounded-md opacity-70"></app-secure-icon>
      <p>T&C</p>
    </div>
    <div (click)="onLogout()"
         class="cursor-pointer flex items-center h-fit px-6 w-[230px] font-poppins font-normal justify-start border-l-4 hover:border-green4-native hover:text-green4-native border-white">
      <img src="assets/icons-sax/sign-out.svg" class="mr-4 w-6 rounded-md opacity-70" alt="test">
      <p>Log Out</p>
    </div>
  </div>
</div>
<!--End desktop/mobile sidebar-->
<!--Starts desktop/mobile sidebar settings-->

<!--End desktop/mobile sidebar settings-->
