<div #container class="om-input-container relative">
  <label *ngIf="label" [for]="id">{{label}}</label>
  <input [id]="id" [type]="type" [placeholder]="placeholder" [(ngModel)]="value"
         (change)="setValue($event)" (click)="touch()" autocomplete="off"
         class="om-input w-full text-[calc(var(--mw-height)*2)] py-[calc(var(--mw-height)*1.4)]
         sm:text-[calc(var(--w-height)*1.8)] sm:py-[calc(var(--w-height)*1.25)]"
         (keyup)="onKeyup($event)" [class]="getInputClass()" [readonly]="readonly"
         (keyup.enter)="onKeyupEnter($event)"
         (keydown)="allowOnlyNumbers($event)"
         >
  <div *ngIf="useIcon" class="absolute top-0 pl-2 h-full flex items-center">
    <ng-content></ng-content>
  </div>
</div>
