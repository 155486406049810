<div class="autocomplete">
  <input type="text" (input)="search(getTarget($event)?.value || '')" [(ngModel)]="keyword"
         (keyup.enter)="onEnter()" autocomplete="none"
         [placeholder]="placeholder" (focusin)="setFocus(true)" (focusout)="setFocus(false)"
         class="om-input w-full text-[calc(var(--mw-height)*2)] py-[calc(var(--mw-height)*1.4)]
         sm:text-[calc(var(--w-height)*1.8)] sm:py-[calc(var(--w-height)*1.25)]"
         [class]="getInputClass()"
  />
  <div *ngIf="useIcon" class="absolute top-0 pl-2 h-full flex items-center">
    <ng-content></ng-content>
  </div>
  <div class="autocomplete-result" *ngIf="results.length > 0">
    <div *ngFor="let result of results" (click)="selectItem(result)" class="bg-omgreen2-600">
      {{getTemplateResult(result)}}
    </div>
  </div>
</div>
