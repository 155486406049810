import {Component, ElementRef, inject, OnInit, ViewChild} from '@angular/core';
import {UserInfoService} from "../../../services/user-info/user-info.service";
import {DialogComponent} from "../../general/dialog/dialog.component";
import {DeviceInfoService} from "../../../services/device-info/device-info.service";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {ToastService} from "../../../services/toast/toast.service";

@Component({
  selector: 'app-new-story',
  templateUrl: './new-story.component.html',
  styleUrls: ['./new-story.component.scss']
})
export class NewStoryComponent implements OnInit {
  http = inject(HttpClient);
  router = inject(Router);
  toastService = inject(ToastService);
  userInfo = inject(UserInfoService);
  deviceInfo = inject(DeviceInfoService);
  @ViewChild('changePic', {static: false}) changePic!: DialogComponent;
  @ViewChild('editContent', {static: false}) editContent!: DialogComponent;
  @ViewChild('story1', {static: false}) story1!: ElementRef;
  @ViewChild('inputChangePic') inputChangePic: ElementRef | undefined;
  user: {[key: string]: any}  = {}
  type = 'img';
  bg = ''
  img: File | undefined= {} as File
  imgPreview = ''
  font = ''
  story = ''
  fonts = [
    {label: 'Normal', value: 'font-normal'},
    {label: 'Bold', value: 'font-bold'},
    {label: 'Italic', value: 'italic'}
  ]
  imageChangedEvent: any = null;
  aspectRatio: number = 9 / 16
  widthImage: number = 400
  showBgs: boolean = false;
  showText: boolean = false;
  croppedImage: string | null = null;
  storyTemp = ''
  ngOnInit() {
    this.user = this.userInfo.getMe();
  }
  updateStory(evt: any, isTemp = false) {
    if(isTemp)
      this.storyTemp = evt.target.innerHTML;
    else
      this.story =  evt.target.innerHTML;
  }
  saveText() {
    this.story = this.storyTemp;
    this.editContent.closeB();
  }
  closeText() {
    if(this.story1?.nativeElement)
      this.story1.nativeElement.innerHTML =  ''

    this.editContent.closeB();
  }
  openText() {
    this.editContent.open();
    this.story1.nativeElement.innerHTML =  this.story;
  }
  getName() {
    return this.userInfo.getName();
  }
  getColor(type: string){
    if(type === this.type)
      return '#fff'

    if(type === this.type)
      return '#fff'

    return '#292D32';
  }
  setType(type: string) {
    this.type = type;
    this.imgPreview = '';
    this.img = {} as File;
  }
  getBg(type: string){
    if(type === this.type)
      return 'bg-[#7DB36A]'

    if(type === this.type)
      return 'bg-[#7DB36A]'

    return 'bg-[transparent]';
  }

  setBg(bg: string, hiddePop = true){
    this.bg = bg;
    this.showBgs = hiddePop;
  }
  cropperReady() {
    if (this.croppedImage === null) return;
    const ext = this.deviceInfo.extractContentTypeFromBase64(this.croppedImage).split('/')[1];
    let name = (Math.random() * 1000).toFixed(0) + `imag.${['gif', 'png', 'jpg', 'jpeg', 'mp4'].includes(ext) ? ext : 'png'}`;
    this.imgPreview = this.croppedImage;
    this.img = this.deviceInfo.dataURLtoFile(this.croppedImage.replace('data:', ''), name);
    this.croppedImage = '';
    this.imageChangedEvent = '';
    this.changePic.closeB();
  }

  closeCropper() {
    this.croppedImage = '';
    this.imageChangedEvent = '';
    this.changePic.closeB();
  }
  onChangePic() {
    this.imageChangedEvent = {target: {files: this.inputChangePic?.nativeElement.files}}
    this.changePic.open()
  }
  imageCropped(event: any) {
    if (event.base64 != null) {
      this.croppedImage = event.base64;
    }
  }
  choosePhoto() {
    if(this.type === 'text') return;

    if (this.inputChangePic?.nativeElement?.files?.length)
      this.inputChangePic.nativeElement.value = null;

    this.inputChangePic?.nativeElement.click();
  }
  showErrors = true;
  saveStory() {
    if(this.type === 'text' && !this.story){
      this.showErrors = true;
      if(this.deviceInfo.detectMobile()){
        this.toastService.show("Text is required")
      }
      return;
    }
    if(this.type === 'img' && !this.img?.name){
      this.toastService.show("Image is required")
      return;
    }
    let formData = new FormData();
    formData.append('type', this.type);
    formData.append('content', `<div class="w-full flex items-center justify-center aspect-[9/16] bg-slate-300 bg-cover rounded-2xl ${this.bg}">
    ${this.imgPreview ? '<div class="z-0 rounded-2xl absolute top-0 left-0 w-full h-full bg-gradient-to-b from-black/20 to-black/20"></div>' : ''}
    <div  class="z-0 text-white text-center font-Montserrat overflow-hidden max-h-full break-all p-4 text-[36px] pt-[calc(var(--sat)+16px)] ${this.font}"
        >${this.story}</div>
    </div>`);
    if(this.img?.name)
      formData.append('images', this.img, this.img.name);

    this.http.post('/story/add', formData).subscribe({
      next: (response: any) => {
        const {success} = response
        if (success) {
          // this.shareGiftDialog.open();
          this.router.navigateByUrl('/home')
        } else {
          alert("Error al crear producto")
        }
      }
    });
  }
}
