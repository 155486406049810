import {Component, inject, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-communities-popular',
  templateUrl: './communities-popular.component.html',
  styleUrls: ['./communities-popular.component.scss']
})
export class CommunitiesPopularComponent implements OnInit {
  http = inject(HttpClient);
  communitiesPopular: any = [];

  async ngOnInit() {
    this.communitiesPopular = await this.getData({type: 'Popular'});
  }

  async getData(filters: any = {}) {
    try {
      const response: any = await this.http.get('/communities', {
        params: filters
      }).toPromise();
      if (response.data) {
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  }
}
