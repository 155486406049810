import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input, OnChanges,
  OnInit,
  Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'app-om-select',
  templateUrl: './om-select.component.html',
  styleUrls: ['./om-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OmSelectComponent),
      multi: true,
    },
  ],
})
export class OmSelectComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() buttonTemplate: boolean = false;
  @Input() color?: string = 'black';
  @Input() containerClass?: string = '';
  @Input() containerItemsClass?: string = '';
  @Input() iconClass?: string = '';
  @Input() itemsClass?: string = '';
  selectedOption: any = {};
  bgSelect: any = {};
  showOptions = false;
  @ViewChild('optionsList') optionsList: ElementRef | undefined;
  @Input() options: typeof this.selectedOption[] = [];
  @Input() placeholder: string = 'Select option';
  @Input() id: string = 'select';
  @Input() keyLabel: string = 'label';
  @Input() keyValue: string = 'value';
  @Input() default: typeof this.selectedOption = this.selectedOption;
  // @Output() valueChange = new EventEmitter<typeof this.selectedOption>();
  value: string | null | undefined = '';
  onChange = (value: any) => { };
  onTouched = () => {};
  constructor(private elementRef: ElementRef) {
  }
  ngOnInit() {
    this.selectedOption = this.default;
    const findOption = this.options.find(object => object[this.keyValue] == this.default);
    if (findOption)  this.selectedOption= findOption;

    this.onSelectBg();
  }
  ngOnChanges(changes: SimpleChanges) {
    if(changes['default']?.currentValue !== "") {
      const findOption = this.options.find(object => object[this.keyValue] == this.default);
      if(findOption){
        this.value = '';
        this.bgSelect = {};
        this.selectedOption = findOption;
        this.onSelectBg()
      }
    }
  }
  getContainerClass():{[key: string]: boolean} {
    let obj: {[key: string]: boolean} = {
      'om-select': true,
      'om-select-ring' : this.showOptions,
      'om-select-gray': this.color === 'gray',
    };
    if(this.containerClass) {
      let splitClass = this.containerClass.split(' ');
      splitClass.forEach(s => {
        obj[s] = true;
      })
    }
    return obj;
  }
  getContainerItemsClass():{[key: string]: boolean} {
    let obj: {[key: string]: boolean} = {
      'text-[#000]': this.color === 'gray',
      '!bg-[#FFF]': this.color === 'gray'
    };
    if(this.containerItemsClass) {
      let splitClass = this.containerItemsClass.split(' ');
      splitClass.forEach(s => {
        obj[s] = true;
      })
    }
    return obj;
  }
  getIconClass():{[key: string]: boolean} {
    let obj: {[key: string]: boolean} = {};
    if(this.iconClass) {
      let splitClass = this.iconClass.split(' ');
      splitClass.forEach(s => {
        obj[s] = true;
      })
    }
    return obj;
  }
  getItemsClass():{[key: string]: boolean} {
    let obj: {[key: string]: boolean} = {};
    if (this.color === 'gray'){
       obj['!text-[#6F6E6E]']=true;
       obj['hover:!text-white']=true;
       obj['hover:!bg-[#6F6E6E]']=true;
       obj['border-[#6F6E6E]']=true;
       obj['bg-[#EDEDED]']=true;
    }
    if(this.itemsClass) {
      let splitClass = this.itemsClass.split(' ');
      splitClass.forEach(s => {
        obj[s] = true;
      })
    }
    return obj;
  }
  toggleOptions() {
    this.showOptions = !this.showOptions;
    this.onTouched()
    setTimeout(() => {
      this.calculatePosition();
    }, 50)
  }

  selectOption(option: typeof this.selectedOption) {
    this.selectedOption = option;
    this.showOptions = false;
    this.onSelectBg();
  }

  // onSelect() {
  //   if (this.keyValue == 'this')
  //     this.valueChange.emit(this.selectedOption);
  //   else
  //     this.valueChange.emit(this.selectedOption[this.keyValue]);
  // }

  // Functions to implement from ControlValueAccessor
  writeValue(value: string): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // Implement this if you want to support disabling
  }

  onSelectBg() {
    if (this.keyValue == 'this') {
      this.bgSelect = this.selectedOption;
      this.writeValue(this.selectedOption);
    } else {
      const customValue = this.selectedOption[this.keyValue] || '';
      this.bgSelect = customValue;
      this.writeValue(customValue);
    }
    // this.valueChange.emit(this.value);
    this.onChange(this.value)
  }
  calculatePosition() {
    if (this.showOptions && this.optionsList) {
      const rect = this.optionsList.nativeElement.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const dropdownHeight = rect.height;
      console.log(rect.bottom + dropdownHeight);
      if (rect.bottom + dropdownHeight > windowHeight) {
        this.optionsList.nativeElement.style.top = `-${dropdownHeight + 4}px`;
      } else {
        this.optionsList.nativeElement.style.top = '100%';
      }
    }
  }
  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.showOptions = false;
    }
  }
}
