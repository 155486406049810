  <p class="font-Poppins font-medium text-[16px]">Price Ranges</p>
  <app-range-price [(initValue)]="priceInit" [(endValue)]="priceEnd" (initValueChange)="updateFiltersSubject.next()" (endValueChange)="updateFiltersSubject.next()"></app-range-price>
  <p class="font-Poppins font-medium text-[16px] mt-6">Popular</p>
  <div class="checkbox-group flex items-center md:items-center mt-2" *ngFor="let item of popular; let i = index">
        <span>
          <input type="checkbox" id="types_gift{{i}}"  [(ngModel)]="item.selected" (ngModelChange)="updateFiltersSubject.next()" class="accent-omgreen-50">
        </span>
    <label for="types_gift{{i}}" class="label-checkbox font-poppins ml-4 !font-light !text-[14px]">
      {{ item.label }}</label>
  </div>

  <p class="font-Poppins font-medium text-[16px] mt-6">Category</p>
  <div class="checkbox-group flex items-center md:items-center mt-2" *ngFor="let category of categories; let i = index">
        <span>
          <input type="checkbox" id="types_gift{{i}}"  [(ngModel)]="category.selected" (ngModelChange)="updateFiltersSubject.next()" class="accent-omgreen-50">
        </span>
    <label for="types_gift{{i}}" class="label-checkbox font-poppins ml-4 !font-light !text-[14px]">
      {{ category.label }}</label>
  </div>
  <p class="font-Poppins font-medium text-[16px] mt-6">Type</p>
  <div class="checkbox-group flex items-center md:items-center mt-2" *ngFor="let type of types; let i = index">
    <span>
      <input type="checkbox" id="categories{{i}}"  [(ngModel)]="type.selected" (ngModelChange)="updateFiltersSubject.next()" class="accent-omgreen-50">
    </span>
    <label for="categories{{i}}" class="label-checkbox font-poppins ml-4 !font-light !text-[14px]">
      {{ type.label }}
    </label>
  </div>
  <p class="font-Poppins font-medium text-[16px] mt-6">Effects</p>
  <div class="checkbox-group flex items-center md:items-center mt-2" *ngFor="let effect of effects; let i = index">
    <span>
      <input type="checkbox" id="type_grower{{i}}"  [(ngModel)]="effect.selected" (ngModelChange)="updateFiltersSubject.next()" class="accent-omgreen-50">
    </span>
    <label for="type_grower{{i}}" class="label-checkbox font-poppins ml-4 !font-light !text-[14px]">
      {{ effect.label }}
    </label>
  </div>

  <p class="font-Poppins font-medium text-[16px] mt-6">THC</p>
  <app-range-price [minValue]="0" [maxValue]="100" [(initValue)]="thcInit" [(endValue)]="thcEnd"  (initValueChange)="updateFiltersSubject.next()" (endValueChange)="updateFiltersSubject.next()" type="porc"></app-range-price>

  <p class="font-Poppins font-medium text-[16px] mt-6">Terpenes</p>
  <div class="checkbox-group flex items-center md:items-center mt-2" *ngFor="let terpen of terpenes; let i = index">
        <span>
          <input type="checkbox" id="categories{{i}}"  [(ngModel)]="terpen.selected" (ngModelChange)="updateFiltersSubject.next()" class="accent-omgreen-50">
        </span>
    <label for="categories{{i}}" class="label-checkbox font-poppins ml-4 !font-light !text-[14px]">
      {{ terpen.label }}</label>
  </div>
  <p class="font-Poppins font-medium text-[16px] mt-6">Ratings</p>
  <div id="filter-rating" class="mt-1">
    <div *ngFor="let item of ranked; let i = index" class="checkbox-group flex items-center md:items-center mt-1" >
          <span>
            <input type="checkbox" id="rating{{i}}" [(ngModel)]="item.selected"  (ngModelChange)="updateFiltersSubject.next()"  class="accent-omgreen-50">
          </span>
      <label for="rating{{i}}" class="label-checkbox font-poppins ml-4 !font-light !text-[14px]">
        <app-star-rating [disabled]="true" [defaultRating]="item.value"></app-star-rating>
      </label>
    </div>
  </div>
<!--  <p class="font-Poppins font-medium text-[16px] mt-6">Grower Type</p>-->
<!--  <div class="checkbox-group flex items-center md:items-center mt-2" *ngFor="let typeGrower of typesGrowers; let i = index">-->
<!--        <span>-->
<!--          <input type="checkbox" id="type_grower{{i}}"  [(ngModel)]="typeGrower.selected" (ngModelChange)="updateFiltersSubject.next()" class="accent-omgreen-50">-->
<!--        </span>-->
<!--    <label for="type_grower{{i}}" class="label-checkbox font-poppins ml-4 !font-light !text-[14px]">-->
<!--      {{ typeGrower.label }}</label>-->
<!--  </div>-->
<!--  <p class="font-Poppins font-medium text-[16px] mt-8">Pickup Locations</p>-->
<!--  <app-om-select id="select-pickup"-->
<!--                 containerClass="!bg-white !py-[4px] sm:!py-[4px] !font-poppins !font-medium shadow-md mt-4 !text-[12px]"-->
<!--                 containerItemsClass="!max-h-fit"-->
<!--                 itemsClass="nav-select-item !py-1 hover:!bg-white hover:!text-green4-native"-->
<!--                 color="gray" [options]="[]" [(ngModel)]="selectedLocation" (ngModelChange)="updateFiltersSubject.next()"-->
<!--                 #selectedNav="ngModel" [default]="''" placeholder="Select ..."-->
<!--  />-->
  <div class="flex justify-center mt-8">
    <button class="py-3 px-5 text-white bg-[#81CB52] rounded-xl font-medium text-[12px]" (click)="resetFilters()">Reset Filters</button>
  </div>
