import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-shop-icon',
  templateUrl: './shop-icon.component.svg',
  styleUrls: ['./shop-icon.component.scss']
})
export class ShopIconComponent {
  @Input() color = '#77838f';
  @Input() width = 24;
  @Input() height = 24;
}
