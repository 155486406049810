<div>
  <div class="flex justify-between">
    <p class="text-[16px]" [class]="[classFont]">{{labelSubtotal}}</p>
    <p class="text-[16px]" [class]="[classFont]">${{subTotal}} {{currency}}</p>
  </div>
  <hr class="border border-[#DDDDDD] sm:mt-[10px]">
  <div class="flex justify-between mt-[15px] sm:mt-[41px] sm:mt-[30px]">
    <p class="text-[16px]" [class]="[classFont]">Shipping Fee</p>
    <p class="text-[16px]" [class]="[classFont]">${{shipping}} {{currency}}</p>
  </div>
  <p class="text-[12px] font-Montserrat" *ngIf="isSend">This amount must be paid by the recipient of your gift.</p>
  <hr class="border border-[#DDDDDD] sm:mt-[10px]">
  <div class="flex justify-between mt-[15px] sm:mt-[36px]">
    <p class="text-[16px] font-semibold" [class]="[classFont]">Total</p>
    <p class="text-[16px] font-semibold" [class]="[classFont]">${{total}} {{currency}}</p>
  </div>
</div>
