<div class="flex items-center justify-between">
  <div class="flex items-center gap-[40px] sm:max-w-[calc(100%-200px)]">
    <img  alt="banner" [src]="data.images[0]" class="w-[110px] h-[100px] object-cover rounded-2xl">
    <div>
      <p class="text-[14px] font-Poppins font-semibold">{{ data.name }}</p>
      <p class="text-[16px] font-Roboto text-[#92929D] flex gap-1 items-center my-[10px]">
        Coach: {{data.user.name}}
        <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5"><circle id="Oval" cx="2.5" cy="2.5" r="2.5" fill="#d5d5dc"/></svg>
        {{ data.count_solded }} Students
      </p>
      <div class="flex gap-[10px] items-center">
        <div class="flex items-center gap-2">
          <img *ngFor="let item of data.friends.slice(0, 3)" [src]="item.image_profile" class="h-[19px] w-[19px] rounded-full object-cover" alt="">
          <span class="h-[18px] w-[18px] flex items-center justify-center border-[0.5px] border-[#E2E2EA] rounded-full text-[7px] text-[#696974]">+ {{data.friends.length-(data.friends.slice(0, 3).length)}}</span>
        </div>
        <p class="text-[13px] font-Roboto text-[#92929D]">{{ data.friends.length }} Friends Enrolled</p>
      </div>
    </div>
  </div>
  <div class="w-[110px] h-[43px] flex items-center justify-center gap-[5px] px-[5px] sm:px-0 sm:gap-[15px] bg-[#F1F1F5] rounded-xl">
    <svg id="vuesax_linear_shopping-cart" data-name="vuesax/linear/shopping-cart" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <rect id="vuesax_linear_shopping-cart-2" data-name="vuesax/linear/shopping-cart" width="20" height="20" fill="none"/>
      <g id="shopping-cart">
        <path id="Vector" d="M3.12,1.528H13.666a2.265,2.265,0,0,1,2.3,2.459l-.441,6.126a2.35,2.35,0,0,1-2.3,2.132h-8.7A2.284,2.284,0,0,1,2.246,9.77l.678-8.136A1.511,1.511,0,0,0,1.421,0H0" transform="translate(1.634 1.634)" fill="none" stroke="#787272" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
        <path id="Vector-2" data-name="Vector" d="M2.042,1.021A1.021,1.021,0,1,1,1.021,0,1.021,1.021,0,0,1,2.042,1.021Z" transform="translate(12.253 15.929)" fill="none" stroke="#787272" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
        <path id="Vector-3" data-name="Vector" d="M2.042,1.021A1.021,1.021,0,1,1,1.021,0,1.021,1.021,0,0,1,2.042,1.021Z" transform="translate(5.718 15.929)" fill="none" stroke="#787272" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
        <path id="Vector-4" data-name="Vector" d="M0,0H9.8" transform="translate(7.352 6.535)" fill="none" stroke="#787272" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
        <path id="Vector-5" data-name="Vector" d="M0,0H19.6V19.6H0Z" fill="none" opacity="0"/>
      </g>
    </svg>
    <span *ngIf="!isMe() && !data.purchased" (click)="openPay()" class="text-[12px] font-Poppins text-[#696974] cursor-pointer">${{data.publish_cost}}</span>
    <span [routerLink]="'/course/details'" [queryParams]="{ id: data.sk }" *ngIf="!(!isMe() && !data.purchased)"  class="text-[12px] font-Poppins text-[#696974] cursor-pointer text-center">Go to course</span>
  </div>
</div>
