<div class="px-4 pl-6 h-full flex flex-col justify-between
      pt-[var(--sat)]
      bg-[#262628] bg-cover bg-black sm:bg-none bg-center
      sm:h-[80vh] sm:w-[36.6vw] sm:bg-[#2e2e2e]
      sm:mt-[calc(var(--w-height)*10)]
      sm:pt-[calc(var(--w-height)*6)]
      sm:pb-[calc(var(--w-height)*4)]
      sm:mr-[calc(var(--w-height)*15)]
      sm:pt-[calc((var(--mw-height)*5))]
      sm:px-[calc(var(--w-width)*7.8)]
      sm:rounded-[2vw]">
  <div>
    <ng-icon name="matArrowBack" class="text-white my-4 text-4xl inline-block sm:!hidden" (click)="previous()"></ng-icon>
    <p
       class="title-form text-[calc(var(--mw-height)*4.5)] sm:text-[calc(var(--w-height)*4.5)]">
      What best describes you?</p>
    <p
       class="text-[#C3C3C3] mt-[calc(var(--mw-height)*1)] sm:mt-[calc(var(--mw-height)*2)] sm:text-white text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*2)]">
      Select at least one category to improve your experience.</p>
    <div class="grid grid-cols-2 overflow-auto mt-[calc(var(--mw-height)*6)]
          gap-[calc(var(--mw-height)*1.5)] sm:gap-[calc(var(--w-height)*8)]
          sm:mt-[calc(var(--w-height)*2)]
          sm:gap-y-[calc(var(--w-height)*2)]">
<!--         [ngStyle]="getHeight([-->
<!--                    {size:6, type: 'margin-top', isMobile: true},-->
<!--                    {size:2, type: 'gap', isMobile: true},-->
<!--                    {size:6, type: 'margin-top', isMobile: false},-->
<!--                    {size:1, type: 'margin-left', isMobile: false},-->
<!--                    {size:1, type: 'margin-right', isMobile: false},-->
<!--                    {size:8, type: 'column-gap', isMobile: false},-->
<!--                    {size:3, type: 'row-gap', isMobile: false},-->
<!--                  ])">-->
      <div *ngFor="let item of cat_best_describes" (click)="toggleBestDescribes(item.id)"
           [ngClass]="{'best-active': isboxSelected(item.id,'best_describes')}"
           class="box-describes flex items-end p-2 rounded-lg cursor-pointer
              pt-[calc(var(--mw-height)*5)] gap-[calc(var(--mw-height)*2)]
              sm:pt-[calc(var(--w-height)*2)]
              sm:h-[calc(var(--w-height)*11)]">
<!--           [ngStyle]="getHeight([-->
<!--                    {size:5, type: 'padding-top', isMobile: true},-->
<!--                    {size:2, type: 'gap', isMobile: true},-->
<!--                    {size:5, type: 'padding-top', isMobile: false},-->
<!--                    {size:14, type: 'height', isMobile: false},-->
<!--                   ])">-->
        <p class="text-white text-[calc(var(--mw-height)*2.4)] sm:text-[calc(var(--w-height)*1.8)]">{{item.name}}</p>
      </div>

    </div>
    <p class="mt-2 text-[#818181] sm:text-[calc(var(--w-height)*1.5)] sm:pl-[calc(var(--w-height)*0)]" [ngClass]="{'text-red-500': !validate}">
      {{best_describes.length}} of 3</p>
  </div>
  <div class="mb-[calc(var(--mw-height)*5)] sm:mb-[calc(var(--w-height)*4)] sm:mt-[calc(var(--w-height)*4)]">
      <app-om-button [text]="'Continue'"
                     class="w-full"
                     [height]="{xs:'7',sm:'6'}"
                     [fontSize]="{xs:'3',sm:'2.6'}"
                     [iconPosition]="'right'"
                     (click)="sendDataC()">
          <img alt="flecha" [class]="'w-[calc(var(--mw-height)*3)] sm:w-[calc(var(--w-height)*3.5)]'" src="assets/general/flecha_larga.png">
      </app-om-button>
  </div>
</div>
