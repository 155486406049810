import { Component } from '@angular/core';

@Component({
  selector: 'app-desactivate-account',
  templateUrl: './desactivate-account.component.html',
  styleUrls: ['./desactivate-account.component.scss']
})
export class DesactivateAccountComponent {

}
