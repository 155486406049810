import {Component, inject, Input, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {DialogComponent} from "../../../general/dialog/dialog.component";

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss']
})
export class MembersComponent {
  @ViewChild('dialogMembers', {static: false}) dialogMembers!: DialogComponent;
  router = inject(Router);
  http = inject(HttpClient);
  @Input() communityId: any = '';
  @Input() communityMembersCount: any = 0;
  members:any =[]

  async ngOnInit() {
    this.getData();
  }
  openDialog(){
    this.dialogMembers.open()
  }
  getData(){
    this.http.get('/communities/members',{
      params: { id:this.communityId}
    }).subscribe({
      next: async (response: any) => {
        if(response.success){
          this.members=response.data
        }
      }
    });

  }
}
