<div class="w-full rounded-2xl shadow-lg">
  <div class="banner relative w-full h-[183px] bg-cover rounded-2xl"
       [style]="{'background-image': 'url(' + course.images[0]||'' + ')'}">
    <div class="w-full h-full p-[15px] rounded-2xl bg-[#0000004f]">
      <div class="flex items-center "
           [class]="{
       'justify-end': !(course.count_solded>=course.avgCourses),
       'justify-between': course.count_solded>=course.avgCourses
       }"
      >
        <span *ngIf="course.count_solded>=course.avgCourses" class="text-white bg-[#73B748] w-[82px] h-[20px] flex items-center justify-center text-[12px] rounded-2xl">Best seller</span>
        <div class="flex justify-center items-center rounded-full w-[25px] h-[25px] border-[1px] sm:border-[1px] border-white icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.858 19.32" class="w-[14px] h-[12px]">
            <g id="Like" transform="translate(0.479 0.052)">
              <path id="Shape" d="M18.327,1.531a5.225,5.225,0,0,0-7.391,0L9.929,2.538,8.922,1.531A5.226,5.226,0,0,0,1.531,8.922L2.538,9.929,9.929,17.32,17.32,9.929l1.007-1.007a5.225,5.225,0,0,0,0-7.391Z" transform="translate(0.521 0.948)"
                    fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path></g>
          </svg>
        </div>
      </div>
      <div class="user flex items-center gap-[10px] absolute w-full bottom-[-30px] sm:bottom-[-20px] left-[4px]">
        <div class="avatar relative w-[70px] sm:w-[96px]">
          <img
            routerLink="/profile/{{course.user.id}}"
            [src]="course.user['image_profile'] || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'"
            alt="" class="cursor-pointer w-[70px] h-[70px] sm:w-[96px] sm:h-[96px] border-[4px] border-[#fff] rounded-full object-cover	">
            <div *ngIf="course.user.is_founder===1 && !course.user.is_grower_verified" class="absolute bottom-0 right-0 w-[20px] h-[20px] sm:w-[35%] sm:h-[35%] p-[5px] rounded-[15px] sm:rounded-full bg-white">
              <img src="assets/ogfc.png" alt="" class="object-cover w-full">
            </div>
          <span *ngIf="course.user.is_grower_verified" class="material-symbols-outlined bg-[#81CB52] absolute bottom-0 right-0 text-white flex items-center justify-center w-[28px] h-[28px] border-[2px] border-[#fff] rounded-full">done</span>
        </div>
        <div class="w-[calc(100%-70px)] sm:w-[calc(100%-106px)]">
          <p [routerLink]="'/course/details'" [queryParams]="{ id: course.sk }" class="leading font-Montserrat font-semibold text-[17px] mt-[15px] sm:mt-[25px] text-white text-ellipsis overflow-hidden px-[10px] h-[40px] sm:h-[50px] cursor-pointer leading-[22px]">{{course.name}}</p>
          <div class="mt-[30px] sm:mt-[15px] mb-[-15px]">
            <p class="text-[17px] leading-none font-Poppins font-semibold cursor-pointer" routerLink="/profile/{{course.user.id}}">{{course.user.name}}</p>
            <p class="text-[10px] mt-[5px] leading-none font-Roboto" *ngIf="course.user.is_grower_verified">Vertified Grower</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="content h-fit pb-4 px-[20px]">

    <p class="leading-[15px] font-Roboto text-[12px] text-[#77838F] h-[55px] mt-[45px] overflow-hidden" [innerHTML]="course.description"></p>
    <div class="flex justify-between items-center px-[10px] mt-[10px]">
      <div class="flex items-center gap-1">
        <svg id="vuesax_bold_profile-2user" data-name="vuesax/bold/profile-2user" xmlns="http://www.w3.org/2000/svg" width="17.466" height="17.466" viewBox="0 0 17.466 17.466">
          <g id="profile-2user">
            <path id="Vector" d="M3.457,0a3.454,3.454,0,0,0-.087,6.906.587.587,0,0,1,.16,0H3.58A3.454,3.454,0,0,0,3.457,0Z" transform="translate(3.093 1.455)" fill="#77838e"/>
            <path id="Vector-2" data-name="Vector" d="M8.82,1.015a7.226,7.226,0,0,0-7.387,0A2.873,2.873,0,0,0,0,3.366,2.848,2.848,0,0,0,1.426,5.7a6.724,6.724,0,0,0,3.7,1.026A6.724,6.724,0,0,0,8.82,5.7a2.871,2.871,0,0,0,1.426-2.351A2.865,2.865,0,0,0,8.82,1.015Z" transform="translate(1.426 9.282)" fill="#77838e"/>
            <path id="Vector-3" data-name="Vector" d="M4.279,2.39A2.6,2.6,0,0,1,2,5.206H1.965a.34.34,0,0,0-.124.015A2.611,2.611,0,0,1,0,4.616,3.33,3.33,0,0,0,1.092,1.851,3.377,3.377,0,0,0,.531.265,2.613,2.613,0,0,1,4.279,2.39Z" transform="translate(10.268 2.952)" fill="#77838e"/>
            <path id="Vector-4" data-name="Vector" d="M5.822,2.977A2.216,2.216,0,0,1,4.556,4.709,5.137,5.137,0,0,1,2,5.276a2.6,2.6,0,0,0,.888-1.688,2.867,2.867,0,0,0-1.215-2.46A6.008,6.008,0,0,0,0,.211a5.688,5.688,0,0,1,4.876.851A2.211,2.211,0,0,1,5.822,2.977Z" transform="translate(10.181 9.096)" fill="#77838e"/>
            <path id="Vector-5" data-name="Vector" d="M0,0H17.466V17.466H0Z" transform="translate(17.466 17.466) rotate(180)" fill="none" opacity="0"/>
          </g>
        </svg>
        <span class="text-[11px] font-Roboto text-[#77838F]">{{getCountUsers()}}</span>
      </div>
      <div class="flex justify-center items-baseline">
        <span class="text-[11px] font-Roboto text-[#77838F]">{{course.val_ranked}}</span>
        <app-star-rating [defaultRating]="course.val_ranked" [disabled]="true" [styleLabel]="{
          'margin':'0 0.5px',
          'font-size':'12px'
        }"></app-star-rating>
        <span class="text-[11px] font-Roboto text-[#77838F]">({{getCountRainting()}})</span>
      </div>
      <div>
        <button class="text-[#519935] text-[12px] font-medium h-[21px] w-[86px] text-center border-[1px] border-[#519935] rounded-xl">{{course?.skill_level}}</button>
      </div>
    </div>
    <div class="flex justify-center">
      <span *ngIf="!isMe() && !course.purchased" (click)="openPay()" class="cursor-pointer bg-[#87D255] text-white h-[39px] w-[250px] flex items-center justify-center text-[17px] font-Montserrat font-bold rounded-2xl mt-[25px]">Get started ${{course.publish_cost}}</span>
      <span [routerLink]="'/course'" [queryParams]="{ id: course.sk }" *ngIf="!(!isMe() && !course.purchased)" class="cursor-pointer bg-[#87D255] text-white h-[39px] w-[250px] flex items-center justify-center text-[17px] font-Montserrat font-bold rounded-2xl mt-[25px]">Go to course</span>
    </div>
  </div>
</div>
