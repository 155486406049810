import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss']
})
export class StarRatingComponent {
  @Input() defaultRating: number = 0;
  @Input() disabled: boolean = false;
  @Input() styleLabel = {};
  @Output() defaultRatingChange: EventEmitter<number> = new EventEmitter<number>();

  rate(value: number) {
    if (!this.disabled) {
      this.defaultRating = value;
      this.defaultRatingChange.emit(this.defaultRating);
    }
  }
}
