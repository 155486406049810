<div class="rating">
  <input type="radio" id="star5" name="rating" value="5" [ngModel]="defaultRating" (click)="rate(5)" [disabled]="disabled">
  <label for="star5" [ngClass]="{
  'active':defaultRating>=5
  }" [style]="styleLabel"></label>
  <input type="radio" id="star4" name="rating" value="4" [ngModel]="defaultRating" (click)="rate(4)" [disabled]="disabled">
  <label for="star4" [ngClass]="{
  'active':defaultRating>=4
  }" [style]="styleLabel"></label>
  <input type="radio" id="star3" name="rating" value="3" [ngModel]="defaultRating" (click)="rate(3)" [disabled]="disabled">
  <label for="star3"[ngClass]="{
  'active':defaultRating>=3
  }" [style]="styleLabel"></label>
  <input type="radio" id="star2" name="rating" value="2" [ngModel]="defaultRating" (click)="rate(2)" [disabled]="disabled">
  <label for="star2" [ngClass]="{
  'active':defaultRating>=2
  }" [style]="styleLabel"></label>
  <input type="radio" id="star1" name="rating" value="1" [ngModel]="defaultRating" (click)="rate(1)" [disabled]="disabled">
  <label for="star1" [ngClass]="{
  'active':defaultRating>=1
  }" [style]="styleLabel"></label>
</div>
