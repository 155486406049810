import {Component, ElementRef, HostListener, ViewChild} from '@angular/core';

@Component({
  selector: 'app-growers-home',
  templateUrl: './growers-home.component.html',
  styleUrls: ['./growers-home.component.scss']
})
export class GrowersHomeComponent {
  @ViewChild('filterList') filterList: ElementRef | undefined;
  @ViewChild('containerFilterList') containerFilterList: ElementRef | undefined;
  showFilters = false
  sharables = [];
  userId = ''
  search = ''
  selected = 'Top'
  filterTab = 'sort'
  filterType = 'Relevance'
  filters: { [key: string]: string } = {
    sort: 'relevance',
    category: 'all',
    type: 'all',
  }
  options: { label: string, value: string }[] = [
    {label: 'Top', value: 'Top'},
    {label: 'Indoor', value: 'Indoor'},
    {label: 'Outdoor', value: 'Outdoor'},
    {label: 'Featured', value: 'Featured'},
  ]

  ngOnInit() {
    window.scrollTo(0, 1);
    this.getSharables()
  }
  getSharables() {
    this.sharables = [];
  }
  setSort(key: string) {
    this.filterTab = key;
  }
  setFilterType(key: string) {
    this.filters[this.filterTab] = key;
    this.getSharables();
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!(this.filterList as ElementRef).nativeElement.contains(event.target) && this.showFilters) {
      this.showFilters = false;
    }
  }
}
