<div class="om-container !h-auto bg-white sm:bg-[#FAFAFA] mt-0 overflow-auto">
    <div class="sticky z-10 top-0 w-full">
      <app-navbar (toggleMenu)="toggleMenu()"
      ></app-navbar>
    </div>
    <div class="flex sm:bg-[#FAFAFA]">
      <div class="hidden sm:block fixed h-full bg-white top-[calc(var(--sat))] sm:top-[101px] sm:h-[calc(100%-101px)] overflow-auto left-0"
           [class]="{
           '!block': showMenu,
           '!fixed': showMenu,
           '!left-0': showMenu,
           '!w-[83%]': showMenu,
           '!z-40': showMenu,
           }"
      >
        <app-side-bar  (toggleMenu)="toggleMenu()"
                       [class]="{
                       'sm:hidden':['/gift-send','/claim-gift'].includes(router.url)
                       }"
        ></app-side-bar>
      </div>
      <hr class="sm:hidden">
      <div class=" container-center w-full bg-white sm:bg-[#FAFAFA] pt-[calc(60px+var(--sat))] sm:w-[calc(100%-616)] sm:ml-[230px] sm:mr-[336px] flex justify-center overflow-auto
            sm:pt-[101px]"
           [class]="{
              '!min-w-[100%]': ['/add-product'].includes(router.url),
              '!pt-[--sat]': ['/add-product'].includes(router.url),
              '!pt-[calc(var(--sat))]': ['/gift-send','/claim-gift'].includes(router.url),
              '!bg-[#FAFAFA]': ['/gift-send','/claim-gift'].includes(router.url),
              '!pt-[0]': ['/profile','/gift-center'].includes(router.url) || router.url.includes('/profile'),
              'sm:!pt-[101px]': ['/add-product','/gift-center','/gift-send','/claim-gift'].includes(router.url) || router.url.includes('/profile'),
              'sm:!pt-[10px]': router.url.includes('/profile'),
              'sm:!min-w-[80%]': ['/add-product'].includes(router.url),
              'sm:!mx-0': ['/gift-send','/claim-gift'].includes(router.url),
              'sm:!overflow-auto': ['/claim-gift'].includes(router.url),
           }"
      >
        <div class="w-full max-w-[800px] pb-12 sm:pb-12"
             [class]="{
              'sm:!max-w-[1125px]': ['/add-product'].includes(router.url),
              'sm:!max-w-full': ['/gift-send','/claim-gift'].includes(router.url),

           }"
        >
          <router-outlet></router-outlet>
        </div>
      </div>
      <div *ngIf="!['/add-product','/gift-send','/claim-gift'].includes(router.url)" class="menu-right hidden sm:block fixed bg-white h-[calc(100%-101px)] overflow-auto right-0 top-[101px] ocultarScroll">
        <app-right-bar></app-right-bar>
      </div>
    </div>
</div>
<app-dialog-alert class="absolute z-[70]"></app-dialog-alert>
