import {Component, ElementRef, inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Subscription} from "rxjs";
import {DialogComponent} from "../dialog/dialog.component";

@Component({
  selector: 'app-reviews-list',
  templateUrl: './reviews-list.component.html',
  styleUrls: ['./reviews-list.component.scss']
})

export class ReviewsListComponent implements OnInit, OnDestroy {
  @ViewChild('dialogReviews', { static: false }) dialogReviews!: DialogComponent;
  @Input() pk: string = '';
  @Input() template: string = 'carousel';
  @Input() classContainer: string = 'py-[5px] w-screen sm:w-[calc(100%-10px)] px-[15px] sm:px-0';
  @Input() classHeader: string = '';
  private http = inject(HttpClient);
  reviews: any = [];
  cat_interests: IInterest[] = [];
  total: number = -1;
  statusFetch = false;

  subscriptionInterests: Subscription | undefined

  getInterests() {
    this.cat_interests = [];
    this.subscriptionInterests = this.http.get('/common/interests').subscribe({
      next: (response: any) => {
        this.cat_interests = response.reduce((acc: any, curr: any) => {
          acc[curr.id] = curr.name;
          return acc;
        }, {});
        this.subscriptionInterests?.unsubscribe();
      }
    });
  }

  subscriptionReview: Subscription | undefined

  getReviews() {
    if(this.total===this.reviews.length) return
    this.statusFetch = true;
    this.subscriptionReview = this.http.get(`/reviews`, {
      params: {
        pk: this.pk,
        offset: this.reviews.length,
        limit: 10
      }
    }).subscribe({
      next: (response: any) => {
        this.total = response.data.total;
        this.reviews = [...this.reviews, ...response.data.reviews];
        this.subscriptionReview?.unsubscribe();
        this.statusFetch = false;
      }
    });
  }
  moreReviews(){
    this.dialogReviews.open();
  }
  constructor() {
  }
  ngOnInit(): void {
    this.getInterests()
    this.getReviews()
  }
  ngOnDestroy() {
    this.subscriptionInterests?.unsubscribe();
  }
}
