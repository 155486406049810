import {inject, Injectable} from '@angular/core';
import {Socket} from 'ngx-socket-io';
import {map} from 'rxjs/operators';
import {SocketService} from "../socket/socket.service";
import {environment} from "../../../environments/environment"
import {info} from "autoprefixer";
import {NotificationService} from "../notifications/notification.service";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private sc = inject(SocketService);
  private notificationService = inject(NotificationService);

  private user = new BehaviorSubject<any>(null);
  currentUser = this.user.asObservable();
  changeUser(data: any) {
    this.user.next(data);
  }

  private messages = new BehaviorSubject<any>([]);
  currentMessages = this.messages.asObservable();
  changeMessages(data: any) {
    this.messages.next(data);
  }
  private allMessages = new BehaviorSubject<any>([]);
  currentAllMessages = this.allMessages.asObservable();
  changeAllMessages(data: any) {
    this.allMessages.next(data);
  }

  private ownInboxes = new BehaviorSubject<any>([]);
  currentInboxes = this.ownInboxes.asObservable();
  changeInboxes(data: any) {
    this.ownInboxes.next(data);
  }

  private commentPost = new BehaviorSubject<any>(null);
  currentComment = this.commentPost.asObservable();
  changeComment(data: any) {
    this.commentPost.next(data);
  }

  private zoomCall = new BehaviorSubject<any>(null);
  currentZoomCall = this.zoomCall.asObservable();
  changeZoomCall(data: any) {
    this.zoomCall.next(data);
  }

  userId: string = '';
  socket: any = null;
  inboxes: { [key: string]: string | number | Date | boolean }[] = []
  notifications: boolean = false;
  message: { [key: string]: { [key: string]: string | number | Date | boolean }[] } = {}
  connectionPending = false;
  constructor() {
  }

  connection(userId: string) {
    this.userId = userId;
    if(!this.socket && !this.connectionPending) {
      this.connectionPending = true;
      this.sc.connect(`${environment.urlSocket}?iduser=${userId}`).then(r => {
        console.log(r, "SOCKETS IS CONECTED")
        this.socket = this.sc.socket;
        this.fromEvents();
        this.connectionPending = false;
      });
    }
  }

  fromEvents() {
    this.socket.on('get-inboxes', (data: any) => {
      console.log('get-inboxes')
      this.inboxes = data
      this.changeInboxes(this.inboxes);
    });
    this.socket.on('notifications', (data: any) => {
      console.log('notifications')
      this.notifications = true
      let title = 'Notification'
      let body = 'Go to omnigram'
      switch (data?.data?.type) {
        case 'gift':
          title = 'Gift received';
          body = 'Go to omnigram to accept it';
          break
      }
      this.notificationService.sendNotification(title, {
        body: body,
        icon: 'assets/logo_black.png',
      })
    });
    this.socket.on('inbox-created', (data: any) => {
      console.log('inbox-created', data)
      let inboxId = this.inboxes.findIndex(i => i['inboxId'] === data?.inboxId)
      if(inboxId === -1){
        this.inboxes.push(data);
      } else {
        this.inboxes[inboxId] = data;
      }
      this.changeInboxes([{...data, isNew: true}]);
    });
    this.socket.on('comment', (data: any) => {
      console.log('comment')
      console.log(data);
      this.changeComment(data);
    });
    this.socket.on('call-received', (data: any) => {
      console.log('call-received')
      this.changeZoomCall({...data, status: 'pending'})
    });
    this.socket.on('call-reject', (data: any) => {
      console.log('call-reject')
      this.changeZoomCall({...data, status: 'reject'})
    });
    this.socket.on('call-accept', (data: any) => {
      console.log('call-accept')
      this.changeZoomCall({...data, status: 'accept'})
    });
    this.socket.on('message', (data: any) => {
      console.log('message')
      if(!this.message[data.pk.split('inbox#')[1]]?.length){
        this.message[data.pk.split('inbox#')[1]] = [];
      }
      if(this.message[data.pk.split('inbox#')[1]].findIndex(m =>  m['sk'] === data.sk) === -1){
        this.message[data.pk.split('inbox#')[1]].push(data)
        let inboxId = this.inboxes.findIndex(i => i['inboxId'] === data.pk.split('inbox#')[1])
        if(inboxId !== -1){
          this.inboxes[inboxId]['message'] = data;
          //this.changeInboxes(this.inboxes);
        }
        this.changeMessages([data]);
      }
    });
    this.socket.on('output-msgs', (data: any) => {
      console.log('output-msgs')
      if (data.length)
        this.message[data[0].pk.split('inbox#')[1]] = data.reverse()

      this.changeAllMessages(data.length ? this.message[data[0].pk.split('inbox#')[1]] : []);
    });
  }

  getInboxes() {
    return this.inboxes
  }

  getNotifications() {
    return this.notifications;
  }

  history(inboxId: string) {
    if(!this.socket) return;
    this.socket.emit('get-msgs-history', inboxId);
  }

  create(userTo: string) {
    if(!this.socket) return;
    this.socket.emit('create-inbox', userTo);
  }

  connectPost(postId: string) {
    if(!this.socket) return;
    this.socket.emit('room-connect', postId);
  }

  disconnectPost(postId: string) {
    if(!this.socket) return;
    this.socket.emit('room-disconnect', postId);
  }

  sendPush(userTo: string, content: { [key: string]: any }, type: string) {
    if(!this.socket) return;
    this.socket.emit('push-notification', {userTo, content, type});
  }

  join(inboxId: string) {
    if(!this.socket) return;
    this.socket.emit('join', {inbox_id: inboxId});
  }

  disconnect() {
    if(this.socket) {
      this.socket.emit('disconnectUser');
      this.socket = null;
    }
  }

  sendMessage({message, inboxId, userTo}: { [key: string]: string | number | boolean }) {
    if(!this.socket) return;
    this.socket.emit('send-msg', {message, inbox_id: inboxId, iduser_to: userTo});
  }

  createCall(iduser_to: string, iduser_from: string, inbox_id: string, type: string = 'audio') {
    if(!this.socket) return;
    this.socket.emit('call-send', {iduser_to, iduser_from, inbox_id, type});
  }

  acceptCall(iduser_to: string, iduser_from: string, inbox_id: string, type: string = 'audio') {
    if(!this.socket) return;
    this.socket.emit('call-accept', {iduser_to, iduser_from, inbox_id, type});
  }

  rejectCall(iduser_to: string, iduser_from: string, inbox_id: string, type: string = 'audio') {
    if(!this.socket) return;
    this.socket.emit('call-reject', {iduser_to, iduser_from, inbox_id, type});
  }
}
