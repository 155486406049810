import {inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {BehaviorSubject, catchError, map, Observable, throwError} from "rxjs";
import {User} from "../../auth/interfaces";

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {
  http = inject(HttpClient)
  user :any  = {}
  constructor() {
  }
  private userInfo = new BehaviorSubject<any>([]);
  currentUserInfo = this.userInfo.asObservable();

  changeUserInfo(data: any) {
    this.userInfo.next(data);
  }

  private giftReceived = new BehaviorSubject<any>(null);
  currentGift = this.giftReceived.asObservable();
  changeGift(data: any) {
    this.giftReceived.next(data);
  }

  private mySaved = new BehaviorSubject<any>([]);
  currentShowMySaved = this.mySaved.asObservable();

  changeShowMySaved(data: any) {
    this.mySaved.next(data);
  }

  me() {
    if (!localStorage.getItem('user')) {
      return this.getMe();
    }
    this.user = JSON.parse(localStorage.getItem('user') || '')
    this.changeUserInfo(this.user)
    return this.user;
  }

  getName() {
    if(this.user?.user?.name){
      return `${this.user?.user?.name} ${this.user?.user?.lastname || ''}`
    }
    return this.user?.user?.username;
  }

  getPercentComplete() {
    let completed = 0;
    this.user?.user.image_profile ? completed++ : 0;
    this.user?.user.banner ? completed++ : 0;
    this.user?.user.name ? completed++ : 0;
    this.user?.user.lastname ? completed++ : 0;
    this.user?.user.company ? completed++ : 0;
    this.user?.user.site ? completed++ : 0;
    this.user?.user?.dial_code ? completed++ : 0;
    this.user?.user.phone ? completed++ : 0;
    this.user?.user.country ? completed++ : 0;
    this.user?.user.region ? completed++ : 0;
    this.user?.user.language ? completed++ : 0;
    this.user?.user.time_zone ? completed++ : 0;
    this.user?.user.currency ? completed++ : 0;
    this.user?.user.delivery_address ? completed++ : 0;

    return parseInt(((completed * 100) / 14).toFixed(0))
  }

  getNameByUser(user: any) {
    if(user?.name){
      return `${user?.name} ${user?.lastname || ''}`
    }
    return user?.username;
  }
  formatFollowerCount(count: number): string {
    if(count >= 1e9) {
      return (count / 1e9).toFixed(1) + 'B';
    } else if(count >= 1e6){
      return (count / 1e6).toFixed(1) + 'M';
    } else if(count >= 1e3){
      return (count / 1e3).toFixed(1) + 'K';
    } else {
      return count.toString();
    }
  }
  getMe() {
    return new Promise((resolve, reject) => {
      try {
        this.http.get('/auth/me').subscribe({
          next: (response: any) => {
            localStorage.setItem('user', JSON.stringify(response));
            this.user = response;
            this.changeUserInfo(response);
            resolve(response);
          }
        })
      } catch (e) {
        reject(e)
      }
    })
  }
  getById(id: string) {
    return new Promise((resolve, reject) => {
      try {
        this.http.get('/profile/'+id).subscribe({
          next: (response: any) => {
            this.user = response;
            resolve(response);
          }
        })
      } catch (e) {
        reject(e)
      }
    })
  }

  getMe2(token: string) {
    let headers = new HttpHeaders()
    headers.append('Authorization', `Bearer ${token}`)
    return this.http.get<any>('/auth/me', { headers: headers})
  }

  updateAddress(address: any) {
    return new Promise((resolve, reject) => {
      try {
        this.http.post('/user/change-address', {address}).subscribe({
          next: (response: any) => {
            let user = JSON.parse(localStorage.getItem('user') || '');
            if(!user?.user?.delivery_address)
              user.user.delivery_address = {}

            user.user.delivery_address = response.user.delivery_address;

            localStorage.setItem('user', JSON.stringify(user));
            resolve(user);
          }
        })
      } catch (e) {
        reject(e)
      }
    })
  }

  updateNotifications(notifications: any) {
    return new Promise((resolve, reject) => {
      try {
        this.http.post('/user/notifications', {notifications}).subscribe({
          next: (response: any) => {
            let user = JSON.parse(localStorage.getItem('user') || '');
            if(!user?.user?.notifications)
              user.user.notifications = {}

            user.user.notifications = response.user.notifications;

            localStorage.setItem('user', JSON.stringify(user));
            resolve(user);
          }
        })
      } catch (e) {
        reject(e)
      }
    })
  }

  updateProfileImage(image: any) {
    return new Promise((resolve, reject) => {
      try {
        let formData = new FormData();
        formData.append('image', image);
        this.http.post('/user/change-img-profile', formData).subscribe({
          next: (response: any) => {
            let user = JSON.parse(localStorage.getItem('user') || '');
            if(!user?.user?.image_profile)
              user.user.image_profile = ''

            user.user.image_profile = response.user.image_profile;

            localStorage.setItem('user', JSON.stringify(user));
            resolve(user);
          }
        })
      } catch (e) {
        reject(e)
      }
    })
  }

  updateImage(image: any) {
    return new Promise((resolve, reject) => {
      try {
        let formData = new FormData();
        formData.append('image', image);
        this.http.post('/user/upload-file', formData).subscribe({
          next: (response: any) => {
            resolve(response.url);
          }
        })
      } catch (e) {
        reject(e)
      }
    })
  }

  updateBannerImage(image: any) {
    return new Promise((resolve, reject) => {
      try {
        let formData = new FormData();
        formData.append('image', image);
        this.http.post('/user/change-banner-profile', formData).subscribe({
          next: (response: any) => {
            let user = JSON.parse(localStorage.getItem('user') || '');
            if(!user?.user?.banner)
              user.user.banner = ''

            user.user.banner = response.user.banner;

            localStorage.setItem('user', JSON.stringify(user));
            resolve(user);
          }
        })
      } catch (e) {
        reject(e)
      }
    })
  }

  updateProfile(data: { [key: string]: string | number | boolean }) {
    return new Promise((resolve, reject) => {
      try {
        let update  = this.http.put('/user/update', data).subscribe({
          next: (response: any) => {
            let user = JSON.parse(localStorage.getItem('user') || '');
            user.user = response.user;
            localStorage.setItem('user', JSON.stringify(user));
            update?.unsubscribe();
            resolve(user);
          }
        })
      } catch (e) {
        reject(e)
      }
    })
  }

  updatePassword(password: string) {
    return new Promise((resolve, reject) => {
      try {
        this.http.post('/auth/change-password', {email: this.user.user.email, password: password}).subscribe({
          next: (response: any) => {
            console.log(response)
            resolve(true);
          }
        })
      } catch (e) {
        reject(e)
      }
    })
  }
  isMe(id: string){
    return (this.user as any)?.user?.id === id
  }

  getNotifications() {
    return new Promise((resolve, reject) => {
      try {
        this.http.get<any>('/notifications').subscribe({
          next: (response: any) => {
            resolve(response.data);
          }
        })
      } catch (e) {
        reject([])
      }
    });
  }
  readNotification(id: string) {
    return new Promise((resolve, reject) => {
      try {
        this.http.put('/notifications/', {id}).subscribe({
          next: (response: any) => {
            resolve({});
          }
        })
      } catch (e) {
        reject({})
      }
    });
  }

  async uploadVideo(file: File, chapter: any, index: number, indexClass: number, key: number) {
    const CHUNK_SIZE = 1024 * 1024; // tamaño del chunk, ejemplo 1MB
    const SIZE = file.size;
    const CHUNKS_COUNT = Math.ceil(SIZE / CHUNK_SIZE);
    let indexFinal = 1;
    chapter[index].classes[indexClass].status = 'uploading';
    for (let i = 0; i < CHUNKS_COUNT; i++) {
      const start = CHUNK_SIZE * i;
      const end = Math.min(SIZE, start + CHUNK_SIZE);

      const chunk = file.slice(start, end);

      const formData = new FormData();
      formData.append('chunk', chunk, `${i}`);
      formData.append('fileName', `${index}_${indexClass}_${file.name}`);
      formData.append('key', key.toString());

      await this.http.post<any>('/upload-video', formData).toPromise()
      console.log(CHUNKS_COUNT, i, indexFinal)
      if (i + 1 > indexFinal) {
        indexFinal = i + 1;
      }
      chapter[index].classes[indexClass].progress = parseFloat(((indexFinal * 100) / CHUNKS_COUNT).toFixed(2))
    }
    chapter[index].classes[indexClass].status = 'processing';
    let url = await this.http.put<any>('/upload-video-s3', {
      fileName: `${index}_${indexClass}_${file.name}`,
      key
    }).toPromise()
    chapter[index].classes[indexClass].url = url;
    chapter[index].classes[indexClass].status = 'finish';
    console.error(url);
  }
}
