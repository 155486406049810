import {Component, HostListener, inject, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {ReviewComponent} from "../../general/review/review.component";

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent {
  @ViewChild(ReviewComponent, { static: false }) reviewComponent: ReviewComponent | undefined;
  private http = inject(HttpClient);
  load=false;
  openMenu=false
  activeClass=0
  activeChapter=0
  data:any={}
  title='';
  description='';
  chapters:any=[
  ]
  user:any={}
  coursePurchased:any={}
  openChapter(indexChapter: number,indexClass: number) {
    if(this.isActiveClass(indexChapter,indexClass)){
      this.updateTimeVideo();
      this.load=false;
      this.updateCurrentVideo(indexChapter,indexClass);
      this.activeClass=indexClass
      this.activeChapter=indexChapter
      this.load=true;
      setTimeout(() => {
        this.loadTimeVideo();
      }, 1500);
      this.openMenu=false
    }
  }
  updateCurrentVideo(indexChapter:number,indexClass:number)
  {
    this.http.post(`/course/history/${this.data.sk.replace('course#','')}/update`, {
      type:'currentVideo',
      indexChapter,
      indexClass,
    }).subscribe({
      next: (response: any) => {
        this.coursePurchased =response.data.data;
      },
      error: (error: any) => {
        console.log(error)
      }
    });
  }
  updateTimeVideo()
  {
    const viewVideo = document.getElementById("videoView");
    if(viewVideo instanceof HTMLVideoElement){
      const time = viewVideo.currentTime.toFixed(0);
      this.http.post(`/course/history/${this.data.sk.replace('course#','')}/update`, {
        type:'time',
        indexChapter:this.activeChapter,
        indexClass:this.activeClass,
        time:parseInt(time),
        isComplete:time===this.chapters[this.activeChapter].classes[this.activeClass]['timeSeconds'].toFixed(0)

      }).subscribe({
        next: (response: any) => {
          this.coursePurchased =response.data.data;
        },
        error: (error: any) => {
          console.log(error)
        }
      });
    }

  }
  constructor(private route: ActivatedRoute,private router: Router) {}
  ngOnInit() {
    window.scrollTo(0, 1);
    this.route.queryParams.subscribe(params => {
      if (params['id']) {
        this.getData(params['id']);
      }else{
        this.router.navigate(['/coaching']);
      }
    });
  }
  getData(id:string){
    this.http.get('/courses',{
      params: { id}
    }).subscribe({
      next: async (response: any) => {
        if (response.data) {
          this.title = response.data?.name;
          this.description = response.data?.description || '';
          this.chapters = response.data?.chapters || [];
          this.user = response.data?.user;
          this.coursePurchased = response.data?.coursePurchased;
          this.data = response.data;
          for (const [indexChapter, row] of this.chapters.entries()) {
            this.chapters[indexChapter]['is_open']=false
            for (const [indexClass, rowClase] of row.classes.entries()) {
              this.chapters[indexChapter].classes[indexClass]['timeSeconds'] = await this.getVideoDuration(rowClase.url)
              this.chapters[indexChapter].classes[indexClass]['time'] = this.timeFormart(this.chapters[indexChapter].classes[indexClass]['timeSeconds']);
            }
          }
          if(this.coursePurchased){
            this.chapters[this.coursePurchased.currentVideo.indexChapter].is_open=true
            this.activeChapter=this.coursePurchased.currentVideo.indexChapter
            this.activeClass=this.coursePurchased.currentVideo.indexClass
            this.load=true
            this.loadTimeVideo()
          }else{
            this.load=true
            this.chapters[0].is_open=true
          }
        } else {
          this.router.navigate(['/coaching']);
        }
      }
    });

  }
  async  getVideoDuration(url: string) {
    const video = document.createElement('video');
    if(!url) return 0;
    video.src = url;
    await new Promise<void>((resolve, reject) => {
      video.onloadedmetadata = () => resolve();
      video.onerror = error => reject(error);
      video.load();
    });
    return video.duration;
  }
  timeFormart(duration:number){
    const durationInSeconds = Math.floor(duration);
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = durationInSeconds % 60;
    let durationString = '';
    if (hours > 0) durationString += `${hours} hour${hours > 1 ? 's' : ''} `;
    if (minutes > 0) durationString += `${minutes} minute${minutes > 1 ? 's' : ''} `;
    if(seconds > 1)  durationString += `${seconds} second${seconds > 2 ? 's' : ''}`;
    return durationString;
  }

  loadTimeVideo()
  {
    if(this.coursePurchased){
      const viewVideo = document.getElementById("videoView");
      if(viewVideo instanceof HTMLVideoElement){
        viewVideo.currentTime = this.coursePurchased.history[this.activeChapter][this.activeClass].time;
      }else{
        setTimeout(() => {
          this.loadTimeVideo();
        }, 1500);

      }
    }
  }
  isLastVideo(){
    if(this.activeChapter < this.chapters.length-1) return false;
    return !(this.activeClass < this.chapters[this.activeChapter].classes.length-1)
  }
  isActiveClass(indexChapter:number,indexClass:number){
    if(this.data.purchased) return true;
    return indexChapter == 0 && indexClass == 0;
  }
  nextVideo(){
    if(!this.data.purchased) return;
    this.updateTimeVideo();
    if(this.activeClass==this.chapters[this.activeChapter].classes.length-1) this.openChapter(this.activeChapter+1,0)
    else this.openChapter(this.activeChapter,this.activeClass+1)
  }
  save(){
    this.data.saved=!this.data.saved
  }
  markComplete(){
    const viewVideo = document.getElementById("videoView");
    if(viewVideo instanceof HTMLVideoElement){
      viewVideo.currentTime = this.chapters[this.activeChapter].classes[this.activeClass]['timeSeconds'].toFixed(0);
      this.updateTimeVideo()
      if(!this.isLastVideo()) this.nextVideo()
    }
  }
  @HostListener('window:beforeunload', ['$event'])
  public beforeUnloadHandler(event:any) {
    if(this.data.purchased) this.updateTimeVideo();
  }

  review() {
    this.reviewComponent?.openForm(this.data,'course',this.coursePurchased['reviewId']||'');
  }

  updateCourse(data: any) {
    this.coursePurchased['reviewId']=data.data.sk
  }
}
