<div class="w-[60px] h-[78px] sm:w-[180px] sm:h-[220px] bg-white card-history relative cursor-pointer">
  <span *ngIf="image=='' && !content" class="h-[44px] w-[44px] object-cover rounded-full add-history" routerLink="/add-story">
    <svg xmlns="http://www.w3.org/2000/svg" id="vuesax_linear_add" data-name="vuesax/linear/add" width="24" height="24" viewBox="0 0 24 24">
      <g id="add">
        <path id="Vector" d="M0,0H12" transform="translate(6 12)" fill="none" stroke="#519935" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
        <path id="Vector-2" data-name="Vector" d="M0,12V0" transform="translate(12 6)" fill="none" stroke="#519935" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
        <path id="Vector-3" data-name="Vector" d="M0,0H24V24H0Z" fill="none" opacity="0"/>
      </g>
    </svg>
  </span>
  <p *ngIf="image=='' && !content" class="hidden sm:block  text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*1.7)] mt-[calc(var(--w-height)*1.5)] px-[10%] text-center">
    <span class="block">Create Your</span>
    Story
  </p>
  <div *ngIf="content"  class="hidden overflow-hidden object-cover sm:block w-full h-full rounded-[10px] ">
    <div class="story-card-content" [innerHTML]="content"></div>
  </div>
  <div *ngIf="image!=''" class="flex items-end h-full object-cover w-full history sm:gbg-black-to-transparent2  ">
    <div class="info-avatar">
      <span class="avatar">
      <img [src]="avatar || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'" class="" [alt]="username">
      </span>
      <p class="username text-[calc(var(--mw-height)*1.5)] sm:text-[calc(var(--w-width)*1)]">{{ username }}</p>
    </div>
  </div>
</div>
