<div class="p-4 pt-0">
  <div class="flex items-center justify-between relative w-full pb-2 gap-4">
    <div class="w-[25%] hidden sm:block">
      <app-om-select id="select-nav"
                     containerClass="!bg-white !py-[4.5px] !font-poppins !font-medium shadow-md text-lg"
                     containerItemsClass="!max-h-fit"
                     itemsClass="nav-select-item !py-1 hover:!bg-white hover:!text-green4-native"
                     color="gray" [options]="categories" [(ngModel)]="category" required
                     (ngModelChange)="getCourses()"
                     #selectedNav="ngModel" [default]="category" placeholder="Filter"
      />
    </div>
    <div class="relative w-[calc(100%-80px)] sm:w-[calc(75%-160px)]">
      <app-om-input [useIcon]="true" color="white" [(ngModel)]="search" (change)="getCourses()"
                    inputClass="rounded-full sm:rounded-md !bg-[#fff] !text-[#77838F] sm:!bg-white !py-[12px] shadow-md !font-poppins !font-medium relative !text-md"
                    placeholder="Search">
        <app-search-icon class="hidden sm:inline-block" color="#77838F" [width]="18" [height]="18"/>
        <app-search-icon class="inline-block sm:hidden" color="#519935" [width]="18" [height]="18"/>
      </app-om-input>
    </div>
    <div class="w-fit sm:w-[160px]">
      <button  *ngIf="isMe()"
               class="p-1.5 sm:px-4 sm:!py-[10.5px] gap-2 flex items-center font-semibold text-xs bg-[#519935] text-white rounded-full sm:rounded-md shadow-sm"
               routerLink="/add-course">
        <ng-icon name="matPlus" class="text-white cursor-pointer text-lg z-10"></ng-icon>
        <span class="hidden sm:flex">New Course</span>
      </button>
    </div>
  </div>
  <app-not-found message="No courses found :(" [allowBackToHome]="false" *ngIf="!courses?.length"/>
  <app-card-course class="block mb-1 mr-[15px] w-[357px]" *ngFor="let item of courses" [course]="item"></app-card-course>
</div>
