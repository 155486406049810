
<div class="flex gap-2 mb-3">
  <img [src]="comment.avatar" class="w-[33px] h-[33px] mt-1 rounded-full" alt="avatar">
  <div>
    <div class="rounded-2xl bg-[#F0F2F6] p-2 px-4">
      <p class="font-Poppins text-[13px] font-semibold">{{comment.name}}</p>
      <p class="font-Poppins text-[13px] font-light">{{comment.commentContent}}</p>
      <div *ngIf="(comment?.commentUrls||[]).length>0">
        <img [src]="item" *ngFor="let item of comment.commentUrls" alt="" class="max-w-[200px] sm:max-w-[250px]">
      </div>
    </div>
    <div>
      <div class="flex gap-2 items-center px-2">
        <span class="font-Poppins text-[10px] text-[#777777] px-2 font-light">{{calculateDateDifference(comment.createDate)}}</span>
        <div class="flex hidden">
          <span class="font-Poppins text-[10px] cursor-pointer">I like</span>
          <span class="font-Poppins text-[10px] cursor-pointer ml-2" (click)="reply=!reply">Reply</span>
        </div>
      </div>
    </div>
  </div>
</div>
<app-comment-form *ngIf="reply" [avatar]="comment.avatar" classAvatar="w-[20px] h-[20px]"></app-comment-form>
