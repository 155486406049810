<div class="flex flex-col w-full">
  <div class="sticky top-0 bg-white z-40">
    <div class="flex justify-between items-center px-4 sm:py-4">
      <p class="hidden sm:flex font-Montserrat font-medium text-[18px]">Post Something</p>
      <app-close-icon (click)="closeForm()" class="cursor-pointer"/>
      <p class="sm:hidden font-Montserrat font-semibold text-[20px]">New Post</p>
      <p *ngIf="!filterMedia" (click)="clickPost()" class="sm:hidden font-Montserrat font-semibold text-[16px] pr-4 text-omgreen2-600">
        Post</p>
      <p *ngIf="filterMedia" (click)="next()"
         class="sm:hidden font-Montserrat font-semibold text-[16px] pr-4 text-omgreen2-600">Next</p>
    </div>
    <hr class=" hidden sm:block bw-1 border-[##00000014] h-0.5"/>
  </div>
  <app-publication-types class="flex sm:hidden" [(type)]="type" (typeChange)="setType($event)"></app-publication-types>
  <div class="p-4" *ngIf="type === 'article' && !settingsDesktop">
    <img *ngIf="bannerBase64" (click)="selectImagePic()" class="border-2 border-[#CCEABB] border-dashed rounded-xl"
         [src]="bannerBase64" alt="Profile Image">
    <div *ngIf="!bannerBase64" (click)="selectImagePic()"
         class="border-2 border-[#CCEABB] border-dashed rounded-xl bg-[#F5F9F280] flex items-center justify-center gap-8 py-12">
      <div class="bg-white h-12 w-12 px-4 rounded-full flex items-center justify-center">
        <app-image-add-icon [width]="24" [height]="24" color="#99C183"></app-image-add-icon>
      </div>
      <div>
        <p class="font-poppins font-semibold text-sm text-[#99C183]">Upload Cover Image (19:6)</p>
        <p class="font-poppins font-normal text-sm text-[#99C183]">Drop your file here or browse</p>
      </div>
    </div>
    <div class="pt-4 pb-0">
      <p class="font-poppins text-[14px] text-[#202020] w-[150px]">Title</p>
      <app-om-input color="white" placeholder="Write a title" [(ngModel)]="titlePost"
                    inputClass="!bg-white !py-[9px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px]">

      </app-om-input>
    </div>
  </div>
  <div class="p-4 flex gap-2" *ngIf="status === 'complete-media' && !filterMedia && !settingsDesktop">
    <div class="w-[100px]">
      <img [src]="imageSample" class="w-[100px] h-[100px] object-cover rounded-xl">
    </div>
    <div class="relative w-[calc(100%-100px)]">
      <div [contentEditable]="true"
           (keyup)="updateDescription($event)"
           (focus)="onDivFocused()"
           (blur)="onDivBlurred()"
           class="relative bw-1 border-[#fff] sm:border-white outline-none  sm:focus:border-white z-10 placeholder-[#1E2022] text-[#1E2022] text-[16px] font-Montserrat rounded-2xl w-full p-0 pl-10 h-36"></div>
<!--      <span *ngIf="!isDivFocused && !description" class="top-0 left-12 absolute rounded-full z-0">Write a description ...</span>-->
      <img [src]="getImgProfile()"
        class="top-0 left-2 w-8 absolute z-20 rounded-full" alt="test">
    </div>
  </div>
  <div class="px-4 pb-4 relative"
       [class]="{
        'relative': !filterMedia && deviceInfoService.detectMobile(),'hidden': (filterMedia || status === 'complete-media') && deviceInfoService.detectMobile(),
        'sm:relative': !settingsDesktop || filterMedia,'sm:hidden': settingsDesktop
        }">
    <div [contentEditable]="true"
         (keyup)="updateDescription($event)"
         (focus)="onDivFocused()"
         (blur)="onDivBlurred()"
         class="relative bw-1 border-[#707070] outline-none sm:mt-4 focus:border-omgreen2-600 z-10 placeholder-[#1E2022] text-[#1E2022] text-[16px] font-Montserrat rounded-2xl w-full p-6 pl-16 min-h-36"
         [class]="{'sm:!border-gray-200': type === 'article', 'sm:focus:!border-omgreen2-600': type === 'article'}"></div>
<!--    <span *ngIf="!isDivFocused && !description" class="top-6 sm:top-10 left-20 w-15 absolute z-0 rounded-full">Write a description ...</span>-->
<!--    <textarea [(ngModel)]="description"-->
<!--              class="bw-1 border-[#707070] sm:border-white outline-none focus:border-omgreen2-600 sm:focus:border-white z-10 placeholder-[#1E2022] text-[#1E2022] text-[16px] font-Montserrat rounded-2xl w-full p-6 pl-16"-->
<!--              [class]="{'sm:!border-gray-200': type === 'article', 'sm:focus:!border-omgreen2-600': type === 'article'}"-->
<!--              placeholder="Write a description ..." rows="5 "></textarea>-->
    <img
      [src]="getImgProfile()"
      class="top-5 sm:top-9 left-10 w-8 absolute z-20 rounded-full" alt="test">
  </div>
  <div *ngIf="type === 'gif' && visibleGif">
    <app-gif-search class="sm:absolute z-[50] sm:ml-[70px] sm:bottom-1 sm:w-[400px]" (sendGif)="getGif($event)"
                    [excludeElementClass]="['container-exclude']"
                    (appClickOutside)="onClickOutsideGif(0)"></app-gif-search>
  </div>
  <div *ngIf="gifts.length > 0">
    <img [src]="gifts[0].src" alt="gif" class="w-full">
  </div>
  <app-media-add *ngIf="type === 'media' && status !== 'complete-media' && !settingsDesktop"
                 [(filtersByImage)]="filtersByImage"
                 (filtersByImageChange)="setFiltersByImage($event)" [filesParent]="files"
                 [(filterMedia)]="filterMedia" (filterMediaChange)="filterMedia = $event"
                 (mediaChange)="setMedia($event)" (mediaSet)="setMedia($event, true)"></app-media-add>
  <div *ngIf="!settingsDesktop && status !== 'filtering-media'">
    <app-post-tag-people [(tagPeople)]="tagPeople" (tagPeopleChange)="setTagPeople($event)"></app-post-tag-people>
    <app-post-add-location [(location)]="location"></app-post-add-location>
    <app-post-categories [(open)]="openCategories" [(selected)]="categories"></app-post-categories>
  </div>
  <app-post-settings [(sendTo)]="sendTo" (sendToChange)="setSendTo($event)" [(settingsView)]="settingsView"
                     (settingsViewChange)="setSettingsView($event)"
                     class="block"
                     [class]="{
                     'block': status !== 'filtering-media','hidden': status === 'filtering-media',
                     'sm:block': settingsDesktop,'sm:hidden': !settingsDesktop
                     }"
  />
  <div class="pb-[75px]"></div>
  <div class="hidden sm:flex absolute bottom-0 w-full bg-white z-30">
    <div class="w-1/2">
      <app-publication-types *ngIf="!settingsDesktop" [(type)]="type"
                             (typeChange)="setType($event)"></app-publication-types>
    </div>
    <div class="w-1/2 flex items-center justify-end gap-4 pr-4 py-3">
      <p
        class="text-[15px] font-Montserrat bg-[#FFFFFF] font-poppins font-semibold sm:text-[12px] text-[#519935] bw-1 border-[#519935] rounded-xl px-4 py-3"
        (click)="settingsDesktop = !settingsDesktop">
        Post Settings</p>
      <p (click)="clickPost()"
         class="text-[15px] font-Montserrat font-poppins font-semibold sm:text-[12px] text-white bg-[#519935] bw-1 border-[#519935] rounded-xl px-8 py-3 cursor-pointer">
        Post</p>
    </div>
  </div>
</div>
<app-dialog #changePic [title]="'Picture'"
            [classHeaderDefault]="'justify-center pt-5 pb-3 text-lg'"
            [classModal]="(type=='banner'?'sm:!min-w-[800px] sm:!w-[800px]':'sm:!min-w-[400px] sm:!w-[500px]')+' bg-black/80 sm:bg-white text-white sm:text-black sm:relative'"
            classBody="">
  <div class="mx-[35px]">
    <image-cropper
      class="w-full !px-0"
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="true"
      [aspectRatio]="aspectRatio"
      [resizeToWidth]="widthImage"
      format="png"
      output="base64"
      (imageCropped)="imageCropped($event)"
    ></image-cropper>
  </div>
  <div class="flex items-center justify-end gap-4 py-2 mx-[35px]">
    <button (click)="cropperReady()"
            class="flex justify-center w-[100px] !py-[6px] mb-2 h-fit gap-2 items-center font-Poppins font-normal bg-[#519935] text-white rounded-xl shadow-sm text-base">
      Save
    </button>
    <button (click)="closeCropper()"
            class="flex justify-center w-[100px] !py-[6px] border-2 border-[#DCDCDC] mb-2 h-fit gap-2 items-center font-Poppins font-normal bg-[#fff] text-[#202020] rounded-xl shadow-sm text-base">
      Discard
    </button>
  </div>
</app-dialog>
<input type="file" (change)="onChangePic()" class="opacity-0 w-0 h-0" name="change-pic" accept="image/*"
       #inputChangePic>
