import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-product-flavours-terpenes',
  templateUrl: './product-flavours-terpenes.component.html',
  styleUrls: ['./product-flavours-terpenes.component.scss']
})
export class ProductFlavoursTerpenesComponent {
  @Input() flavors= []
  @Input() terpenes= []
}
