import {Component, inject} from '@angular/core';
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-reset-password-home',
  templateUrl: './reset-password-home.component.html',
  styleUrls: ['./reset-password-home.component.scss']
})
export class ResetPasswordHomeComponent {
  http = inject(HttpClient);
  router = inject(Router);
  step: number = 1;
  data: IResetPassword = {
    email: '',
    verified: false,
    password: '',
    passwordConfirmed: ''
  };

  receiveData(dataReceived: any) {
    Object.entries(dataReceived).forEach(entry => {
      const [key, value] = entry;
      if (this.data.hasOwnProperty(key)) {
        // @ts-ignore
        this.data[key] = value;
      }
      if (key === "code_verify") {
        this.data.verified = true;
      }
    });
    if (this.step === 3) {
      this.http.post('/auth/change-password', {email: this.data.email, password: this.data.password}).subscribe({
        next: (response: any) => {
          const {info} = response
          if (info === "user-updated") {
            this.router.navigateByUrl('/login')
          }
        }
      });
    } else {
      this.step++;
    }
  }

  async previousStep() {
    if (this.step == 1) {
      await this.router.navigate(['/login'])
    } else {
      this.step--;
    }
  }
}
