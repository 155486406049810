<div
  class="min-h-full w-full flex items-center justify-center bg-[url('assets/founders/bg-founders.webp')] bg-cover bg-center  px-3 md:px-16">
  <div *ngIf="paymentStatus && !msgPayment" class="bg-white shadow rounded-md w-full md:w-4xl max-w-4xl p-8 px-10 flex flex-col gap-4 mt-16 mb-16">
    <div class="flex flex-col gap-4 items-center justify-center">
      <h1 class="text-4xl">Successful Payment</h1>
      <img src="assets/founders/check.png" class="w-32" alt="payment">
<!--      <img src="assets/founders/exclamation-mark.png"  class="w-32" alt="payment">-->
      <p class="text-2xl">Welcome OmniGram Founder!</p>
      <p class="text-2xl font-semibold">You’ll receive an activation email shortly.</p>
      <p class="text-2xl">Thanks for your support</p>
    </div>
  </div>
  <div *ngIf="paymentStatus  && msgPayment" class="bg-white shadow rounded-md w-full md:w-4xl max-w-4xl p-8 px-10 flex flex-col gap-4 mt-16 mb-16">
    <div class="flex flex-col gap-4 items-center justify-center">
      <h1 class="text-4xl">Error Payment</h1>
<!--      <img src="assets/founders/check.png" class="w-32" alt="payment">-->
      <img src="assets/founders/exclamation-mark.png"  class="w-32" alt="payment">
      <p class="text-2xl">Sorry we were unable to process your payment</p>
      <p class="text-2xl font-semibold">{{msgPayment}}.</p>
    </div>
  </div>
  <div class="bg-white shadow rounded-md w-full md:w-4xl max-w-4xl p-8 px-10 flex flex-col gap-4 mt-16 mb-16" *ngIf="!paymentStatus">
    <div class="flex flex-col gap-4" *ngIf="step === 0">
      <h1 class="font-bold text-xl text-[#2C3345]">Welcome to the Founders Club! We need about two minutes of your
        time.</h1>
      <div class="font-light text-normal mt-20">
        Ready to join the OGFC? Simply complete our brief application process to see if you qualify. Whether you're a
        seasoned grower or a curious enthusiast, there's a place for you in our community. Together, let's cultivate
        something extraordinary.
      </div>
    </div>
    <div class="flex flex-col gap-4" *ngIf="step == 1">
      <h2 class="text-[#2a6368] text-mantis-700 font-semibold text-3xl ">Your Details</h2>
      <hr class="bw-1 border-[##00000014] h-0.5 z-10 my-4">

      <div class="flex flex-col gap-16">
        <div class="flex items-center gap-4">
          <p class="w-1/3">Are you 18 years of age?</p>
          <div class="flex items-center gap-4">
            <label for="18YearsAgeYes" class="custom-radio">
              <input type="radio" [(ngModel)]="data['Are you 18 years of age?']" class="text-green-native" name="age" id="18YearsAgeYes"  value="yes"> Yes
              <span class="radio-btn"></span>
            </label>
            <label for="18YearsAgeNo" class="custom-radio">
              <input type="radio" [(ngModel)]="data['Are you 18 years of age?']" name="age" id="18YearsAgeNo"  value="no"> No
              <span class="radio-btn"></span>
            </label>
          </div>
        </div>
        <div class="flex flex-col gap-4 items-center justify-center" *ngIf="data['Are you 18 years of age?'] ==='no'">
          <h1 class="text-4xl">We are sorry to hear that</h1>
          <img src="assets/founders/exclamation-mark.png"  class="w-32" alt="payment">
          <p class="text-2xl text-center">You are not yet of age to be a founder of omnigram, it will be fair to consider us in the future.</p>
          <p class="text-2xl">Thanks for your support</p>
        </div>
        <div class="flex items-center gap-4" *ngIf="data['Are you 18 years of age?'] ==='yes'">
          <p class="w-1/3">Are you in Canada?</p>
          <div class="flex items-center gap-4">
            <label for="yesInCanada" class="custom-radio">
              <input type="radio" [(ngModel)]="data['Are you in Canada?']" name="inCanada" id="yesInCanada"  value="yes"> Yes
              <span class="radio-btn"></span>
            </label>
            <label for="noInCanada" class="custom-radio">
              <input type="radio" [(ngModel)]="data['Are you in Canada?']" name="inCanada" id="noInCanada"  value="no"> No
              <span class="radio-btn"></span>
            </label>
          </div>
        </div>
        <div class="flex flex-col gap-4 items-center justify-center" *ngIf="data['Are you in Canada?'] ==='no'">
          <h1 class="text-4xl">We are sorry to hear that</h1>
          <img src="assets/founders/exclamation-mark.png"  class="w-32" alt="payment">
          <p class="text-2xl text-center">We are not yet available outside of Canada, please wait for our opening in other countries.</p>
          <p class="text-2xl">Thanks for your support</p>
        </div>
        <div class="flex items-center gap-4" *ngIf="data['Are you in Canada?'] ==='yes'">
          <p class="w-1/3">What province are you in?</p>
          <div class="flex w-1/2 items-center gap-4">
            <app-om-select id="Language" class="w-full" *ngIf="regions?.length"
                           containerClass="!bg-white !py-[4.5px] !font-poppins !font-medium bw-1 border-gray !text-[14px]"
                           containerItemsClass="!max-h-fit"
                           itemsClass="bg-white border-[#EEEEEE] !text-black hover:bg-white hover:!text-green4-native !py-1"
                           color="gray" [options]="regions" [(ngModel)]="data['What province are you in?']" required
                           #selectedNav="ngModel" [default]="data['What province are you in?']" placeholder="Please select"
            />
          </div>
        </div>

        <div class="flex items-center gap-4" *ngIf="data['What province are you in?']">
          <p class="w-1/3">Email</p>
          <div class="flex w-1/2 flex-col items-start gap-4">
            <app-om-input label="" placeholder="" type="text" color="white" class="w-full "
                          inputClass="!bg-white !py-[9px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px]"
                          [(ngModel)]="data.email" required></app-om-input>
          </div>
        </div>

        <div class="flex items-center gap-4" *ngIf="data['What province are you in?']">
          <p class="w-1/3">First Name</p>
          <div class="flex w-1/2 flex-col items-start gap-4">
            <app-om-input label="" placeholder="" type="text" color="white" class="w-full"
                          inputClass="!bg-white !py-[9px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px]"
                          [(ngModel)]="data.firstName" required></app-om-input>
          </div>
        </div>

        <div class="flex items-center gap-4" *ngIf="data['What province are you in?']">
          <p class="w-1/3">Last Name</p>
          <div class="flex w-1/2 flex-col items-start gap-4">
            <app-om-input label="" placeholder="" type="text" color="white" class="w-full"
                          inputClass="!bg-white !py-[9px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px]"
                          [(ngModel)]="data.lastName" required></app-om-input>
          </div>
        </div>

        <div class="flex items-center gap-4" *ngIf="data['What province are you in?']">
          <p class="w-1/3">Phone</p>
          <div class="flex w-1/2 flex-col items-start gap-4">
            <app-om-input label="" placeholder="" type="number" color="white" class="w-full" [minlength]="10" [maxlength]="10"
                          inputClass="!bg-white !py-[9px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px]"
                          [(ngModel)]="data.phone" required></app-om-input>
          </div>
        </div>

      </div>
    </div>
    <div class="flex flex-col gap-4" *ngIf="step === 2">
      <h2 class="text-[#2a6368] text-mantis-700 font-semibold text-3xl w-full flex-wrap">OGFC</h2>
      <hr class="bw-1 border-[##00000014] h-0.5 z-10 my-4">
      <div class="flex items-start gap-4">
        <p>Help us learn more to help you! Check all that apply:</p>
        <div class="flex flex-col min-w-2/3 items-start gap-4">
          <label class="custom-checkbox-container">
            <input type="checkbox" [(ngModel)]="data['Help us learn more to help you! Check all that apply']['Enthusiast: not interested in growing at this time']"/>
            <span class="checkmark"></span>
            Enthusiast: not interested in growing at this time
          </label>
          <label class="custom-checkbox-container">
            <input type="checkbox" [(ngModel)]="data['Help us learn more to help you! Check all that apply']['New to growing: want to learn']"/>
            <span class="checkmark"></span>
            New to growing: want to learn
          </label>
          <label class="custom-checkbox-container">
            <input type="checkbox" [(ngModel)]="data['Help us learn more to help you! Check all that apply']['Some growing experience: fairly competent']"/>
            <span class="checkmark"></span>
            Some growing experience: fairly competent
          </label>
          <label class="custom-checkbox-container">
            <input type="checkbox" [(ngModel)]="data['Help us learn more to help you! Check all that apply']['Experienced grower: who actively grows']"/>
            <span class="checkmark"></span>
            Experienced grower: who actively grows
          </label>
          <label class="custom-checkbox-container">
            <input type="checkbox" [(ngModel)]="data['Help us learn more to help you! Check all that apply']['Experienced grower: who wants to be a coach']"/>
            <span class="checkmark"></span>
            Experienced grower: who wants to be a coach
          </label>
        </div>
      </div>
      <div class="text-[#2a6368] text-mantis-700 font-semibold text-2xl py-6">
        Please rank your long-term participation on the platform from 1 to 3, with 3 being the highest level of
        participation:
      </div>
      <div class="flex items-center gap-4">
        <p class="w-1/2">Community Participation</p>
        <div class="w-4/12">
          <div class="number-input">
            <button (click)="decrementValue('communityParticipation')">-</button>
            <input [min]="1" [max]="3" type="number" [(ngModel)]="data.communityParticipation">
            <button (click)="incrementValue('communityParticipation')">+</button>
          </div>
        </div>
      </div>
      <div class="flex items-center gap-4">
        <p class="w-1/2">Grower</p>
        <div class="w-4/12">
          <div class="number-input">
            <button (click)="decrementValue('growerParticipation')">-</button>
            <input [min]="1" [max]="3" type="number" [(ngModel)]="data.growerParticipation">
            <button (click)="incrementValue('growerParticipation')">+</button>
          </div>
        </div>
      </div>
      <div class="flex items-center gap-4">
        <p class="w-1/2">Mentor / Coach</p>
        <div class="w-4/12">
          <div class="number-input">
            <button (click)="decrementValue('mentorParticipation')">-</button>
            <input [min]="1" [max]="3" type="number" [(ngModel)]="data.mentorParticipation">
            <button (click)="incrementValue('mentorParticipation')">+</button>
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-16" *ngIf="data?.['Help us learn more to help you! Check all that apply']?.['Enthusiast: not interested in growing at this time']
 || data?.['Help us learn more to help you! Check all that apply']?.['New to growing: want to learn']">
        <div class="flex items-center gap-4">
          <p class="w-1/2">Have you worked with a commercial grower?</p>
          <div class="flex items-center gap-4">
            <label for="comercialGrowYes" class="custom-radio">
              <input type="radio" name="comercialGrow" id="comercialGrowYes" [(ngModel)]="data['Have you worked with a commercial grower?']" value="yes"> Yes
              <span class="radio-btn"></span>
            </label>
            <label for="comercialGrowNo" class="custom-radio">
              <input type="radio" name="comercialGrow" id="comercialGrowNo" [(ngModel)]="data['Have you worked with a commercial grower?']" value="no"> No
              <span class="radio-btn"></span>
            </label>
          </div>
        </div>
        <div class="flex items-start gap-4">
          <p class="w-1/2">How long have you been growing?</p>
          <div class="flex flex-col items-start gap-4">
            <label for="Haventgrown" class="custom-radio">
              <input type="radio" name="Howlonghaveyoubeengrowing" id="Haventgrown" class="text-green-native"  [(ngModel)]="data['How long have you been growing?']" value="Haven't grown"> Haven't grown
              <span class="radio-btn"></span>
            </label>
            <label for="13years" class="custom-radio">
              <input type="radio"  name="Howlonghaveyoubeengrowing" [(ngModel)]="data['How long have you been growing?']" id="13years"  value="1-3 years"> 1-3 years
              <span class="radio-btn"></span>
            </label>
            <label for="45years" class="custom-radio">
              <input type="radio"  name="Howlonghaveyoubeengrowing" [(ngModel)]="data['How long have you been growing?']" id="45years"  value="4-5 years"> 4-5 years
              <span class="radio-btn"></span>
            </label>
            <label for="6masYears" class="custom-radio">
              <input type="radio"  name="Howlonghaveyoubeengrowing" [(ngModel)]="data['How long have you been growing?']" id="6masYears" value="6+ years"> 6+ years
              <span class="radio-btn"></span>
            </label>
          </div>
        </div>
        <div class="flex items-start gap-4">
          <p class="w-1/2">How many times per year do you grow?</p>
          <div class="flex flex-col items-start gap-4">
            <label for="Lessthan1" class="custom-radio">
              <input type="radio" id="Lessthan1"  [(ngModel)]="data['How many times per year do you grow?']" name="Howmanytimesperyeardoyougrow" value="Less than 1"> Less than 1
              <span class="radio-btn"></span>
            </label>
            <label for="Howmanytimesperyeardoyougrow1" class="custom-radio">
              <input type="radio" id="Howmanytimesperyeardoyougrow1"  [(ngModel)]="data['How many times per year do you grow?']" name="Howmanytimesperyeardoyougrow" value="1"> 1
              <span class="radio-btn"></span>
            </label>
            <label for="Howmanytimesperyeardoyougrow2" class="custom-radio">
              <input type="radio" id="Howmanytimesperyeardoyougrow2"  [(ngModel)]="data['How many times per year do you grow?']" name="Howmanytimesperyeardoyougrow" value="2"> 2
              <span class="radio-btn"></span>
            </label>
            <label for="Howmanytimesperyeardoyougrow3" class="custom-radio">
              <input type="radio" id="Howmanytimesperyeardoyougrow3"  [(ngModel)]="data['How many times per year do you grow?']" name="Howmanytimesperyeardoyougrow" value="3"> 3
              <span class="radio-btn"></span>
            </label>
          </div>
        </div>
        <div class="flex items-start gap-4">
          <p class="w-1/2">Do you grow (check all that apply)</p>
          <div class="flex flex-col items-start gap-4">
            <label class="custom-checkbox-container">
              <input type="checkbox" [(ngModel)]="data['Do you grow (check all that apply)']['Indoors']"/>
              <span class="checkmark"></span>
              Indoors
            </label>
            <label class="custom-checkbox-container">
              <input type="checkbox" [(ngModel)]="data['Do you grow (check all that apply)']['Outdoors']"/>
              <span class="checkmark"></span>
              Outdoors
            </label>
            <label class="custom-checkbox-container">
              <input type="checkbox" [(ngModel)]="data['Do you grow (check all that apply)']['Hydroponics']"/>
              <span class="checkmark"></span>
              Hydroponics
            </label>
            <label class="custom-checkbox-container">
              <input type="checkbox" [(ngModel)]="data['Do you grow (check all that apply)']['Aquaponics']"/>
              <span class="checkmark"></span>
              Aquaponics
            </label>
            <label class="custom-checkbox-container">
              <input type="checkbox" [(ngModel)]="data['Do you grow (check all that apply)']['Other']"/>
              <span class="checkmark"></span>
              Other
            </label>
          </div>
        </div>
        <div class="flex items-start gap-4">
          <p class="w-1/2">How many strains have you tried growing?</p>
          <div class="flex flex-col items-start gap-4">
            <label for="Howmanystrainshaveyoutriedgrowing13" class="custom-radio">
              <input type="radio" id="Howmanystrainshaveyoutriedgrowing13" [(ngModel)]="data['How many strains have you tried growing?']" name="Howmanystrainshaveyoutriedgrowing" value="1-3"> 1-3
              <span class="radio-btn"></span>
            </label>
            <label for="Howmanystrainshaveyoutriedgrowing49" class="custom-radio">
              <input type="radio" id="Howmanystrainshaveyoutriedgrowing49" [(ngModel)]="data['How many strains have you tried growing?']" name="Howmanystrainshaveyoutriedgrowing" value="4-9"> 4-9
              <span class="radio-btn"></span>
            </label>
            <label for="Howmanystrainshaveyoutriedgrowing10" class="custom-radio">
              <input type="radio" id="Howmanystrainshaveyoutriedgrowing10" [(ngModel)]="data['How many strains have you tried growing?']" name="Howmanystrainshaveyoutriedgrowing" value="10+"> 10+
              <span class="radio-btn"></span>
            </label>
          </div>
        </div>
        <div class="flex items-center gap-4">
          <p class="w-1/2">What is your favorite strain?</p>
          <div class="flex w-1/2 flex-col items-start gap-4">
            <app-om-input label="" placeholder="" id="strain12" type="text" color="white" class="w-full"
                          inputClass="!bg-white !py-[9px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px]"
                          [(ngModel)]="data['What is your favorite strain?']" required></app-om-input>
          </div>
        </div>
        <div class="flex items-start gap-4">
          <p class="w-1/2">How many grams(dry) do you produce from each plant?</p>
          <div class="flex flex-col items-start gap-4">
            <label for="hmg75" class="custom-radio">
              <input type="radio"  id="hmg75" [(ngModel)]="data['How many grams(dry) do you produce from each plant?']" name="hmg" value="<75"> <75
              <span class="radio-btn"></span>
            </label>
            <label for="hmg124" class="custom-radio">
              <input type="radio"  id="hmg124" [(ngModel)]="data['How many grams(dry) do you produce from each plant?']" name="hmg" value="76-124"> 76-124
              <span class="radio-btn"></span>
            </label>
            <label for="hmg174" class="custom-radio">
              <input type="radio"  id="hmg174" [(ngModel)]="data['How many grams(dry) do you produce from each plant?']" name="hmg" value="124-174"> 124-174
              <span class="radio-btn"></span>
            </label>
            <label for="hmg175" class="custom-radio">
              <input type="radio"  id="hmg175" [(ngModel)]="data['How many grams(dry) do you produce from each plant?']" name="hmg" value="175+"> 175+
              <span class="radio-btn"></span>
            </label>
          </div>
        </div>
        <div class="flex items-start gap-4">
          <p class="w-1/2">How many grams from each harvest?</p>
          <div class="flex flex-col items-start gap-4">
            <label for="hmp300" class="custom-radio">
              <input type="radio" id="hmp300" name="hmp" [(ngModel)]="data['How many plants from each harvest?']" value="<300"> <300
              <span class="radio-btn"></span>
            </label>
            <label for="hmp301" class="custom-radio">
              <input type="radio" id="hmp301" name="hmp" [(ngModel)]="data['How many plants from each harvest?']" value="301-499"> 301-499
              <span class="radio-btn"></span>
            </label>
            <label for="hmp500" class="custom-radio">
              <input type="radio"  id="hmp500" name="hmp" [(ngModel)]="data['How many plants from each harvest?']" value="500-699"> 500-699
              <span class="radio-btn"></span>
            </label>
            <label for="hmp700" class="custom-radio">
              <input type="radio"  id="hmp700" name="hmp" [(ngModel)]="data['How many plants from each harvest?']" value="700+"> 700+
              <span class="radio-btn"></span>
            </label>
            {{data['How many plants from each harvest?']}}
          </div>
        </div>
        <div class="flex items-center gap-4">
          <p class="w-1/2">Like other members, are you willing to share your excess grow with virtual friends to get
            feedback and experience new strains</p>
          <div class="flex items-center gap-4">
            <label for="lomyes" class="custom-radio">
              <input type="radio" id="lomyes" name="lom" [(ngModel)]="data['Like other members, are you willing to share your excess grow with virtual friends to get feedback and experience new strains']" value="yes"> Yes
              <span class="radio-btn"></span>
            </label>
            <label for="lomno" class="custom-radio">
              <input type="radio" id="lomno" name="lom" [(ngModel)]="data['Like other members, are you willing to share your excess grow with virtual friends to get feedback and experience new strains']" value="no"> No
              <span class="radio-btn"></span>
            </label>
          </div>
        </div>
        <div class="flex items-center gap-4">
          <p class="w-1/2">On a scale from 1 to 10, with 10 being the best, how would you rate your last harvest?</p>
          <div class="flex w-1/2 flex-wrap items-start gap-4">
            <div class="w-20 h-12 border-2 flex items-center justify-center rounded-md" [class]="getClass1_10(1)" (click)="set1_10(1)">1</div>
            <div class="w-20 h-12 border-2 flex items-center justify-center rounded-md" [class]="getClass1_10(2)" (click)="set1_10(2)">2</div>
            <div class="w-20 h-12 border-2 flex items-center justify-center rounded-md" [class]="getClass1_10(3)" (click)="set1_10(3)">3</div>
            <div class="w-20 h-12 border-2 flex items-center justify-center rounded-md" [class]="getClass1_10(4)" (click)="set1_10(4)">4</div>
            <div class="w-20 h-12 border-2 flex items-center justify-center rounded-md" [class]="getClass1_10(5)" (click)="set1_10(5)">5</div>
            <div class="w-20 h-12 border-2 flex items-center justify-center rounded-md" [class]="getClass1_10(6)" (click)="set1_10(6)">6</div>
            <div class="w-20 h-12 border-2 flex items-center justify-center rounded-md" [class]="getClass1_10(7)" (click)="set1_10(7)">7</div>
            <div class="w-20 h-12 border-2 flex items-center justify-center rounded-md" [class]="getClass1_10(8)" (click)="set1_10(8)">8</div>
            <div class="w-20 h-12 border-2 flex items-center justify-center rounded-md" [class]="getClass1_10(9)" (click)="set1_10(9)">9</div>
            <div class="w-20 h-12 border-2 flex items-center justify-center rounded-md" [class]="getClass1_10(10)" (click)="set1_10(10)">10</div>
          </div>
        </div>
        <div class="flex items-center gap-4">
          <p class="w-1/2">Why?</p>
          <div class="flex w-1/2 flex-col items-start gap-4">
            <app-om-input label="" placeholder="" id="strain" type="text" color="white" class="w-full"
                          inputClass="!bg-white !py-[9px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px]"
                          [(ngModel)]="data['Why?']" required></app-om-input>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-4" *ngIf="step == 3">
      <h2 class="text-[#2a6368] text-mantis-700 font-semibold text-3xl ">Experience Sharing or Mentoring</h2>
      <hr class="bw-1 border-[##00000014] h-0.5 z-10 my-4">

      <div class="flex flex-col gap-16">
        <div class="flex items-center gap-4">
          <p class="w-1/3">Have you shared your excess grows with friends?</p>
          <div class="flex w-1/2 items-center gap-4">
            <app-om-select class="w-full"
                           containerClass="!bg-white !py-[4.5px] !font-poppins !font-medium bw-1 border-gray !text-[14px]"
                           containerItemsClass="!max-h-fit"
                           itemsClass="bg-white border-[#EEEEEE] !text-black hover:bg-white hover:!text-green4-native !py-1"
                           color="gray" [options]="[{label: 'N/A', value: 'N/A'},{label: 'Yes', value: 'Yes'},{label: 'No', value: 'No'}]"
                           [(ngModel)]="data['Have you shared your excess grows with friends?']" required
                           #selectedNav="ngModel" [default]="data['Have you shared your excess grows with friends?']" placeholder="Please select"
            />
          </div>
        </div>
        <div class="flex items-center gap-4">
          <p class="w-1/3">How often?</p>
          <div class="flex w-1/2 items-center gap-4">
            <app-om-select class="w-full"
                           containerClass="!bg-white !py-[4.5px] !font-poppins !font-medium bw-1 border-gray !text-[14px]"
                           containerItemsClass="!max-h-fit"
                           itemsClass="bg-white border-[#EEEEEE] !text-black hover:bg-white hover:!text-green4-native !py-1"
                           color="gray" [options]="[{label: 'N/A', value: 'N/A'},{label: 'Once', value: 'Once'},{label: 'Most of the time', value: 'Most of the time'}, {label: 'All of the time', value: 'All of the time'}]"
                           [(ngModel)]="data['How often?']" required
                           #selectedNav="ngModel" [default]="data['How often?']" placeholder="Please select"
            />
          </div>
        </div>
        <div class="flex items-center gap-4">
          <p class="w-1/3">What do you typically do with excess product?</p>
          <div class="flex w-1/2 items-center gap-4">
            <app-om-select class="w-full"
                           containerClass="!bg-white !py-[4.5px] !font-poppins !font-medium bw-1 border-gray !text-[14px]"
                           containerItemsClass="!max-h-fit"
                           itemsClass="bg-white border-[#EEEEEE] !text-black hover:bg-white hover:!text-green4-native !py-1"
                           color="gray" [options]="[{label: 'Don\'t have any', value: 'Don\'t have any'},{label: 'Throw it away', value: 'Throw it away'},{label: 'Give it away', value: 'Give it away'}, {label: 'Other', value: 'Other'}]"
                           [(ngModel)]="data['What do you typically do with excess product?']" required
                           #selectedNav="ngModel" [default]="data['What do you typically do with excess product?']" placeholder="Please select"
            />
          </div>
        </div>
        <div class="flex items-center gap-4">
          <p class="w-1/3">Have you assisted others to learn how to grow?</p>
          <div class="flex w-1/2 items-center gap-4">
            <app-om-select class="w-full"
                           containerClass="!bg-white !py-[4.5px] !font-poppins !font-medium bw-1 border-gray !text-[14px]"
                           containerItemsClass="!max-h-fit"
                           itemsClass="bg-white border-[#EEEEEE] !text-black hover:bg-white hover:!text-green4-native !py-1"
                           color="gray" [options]="[{label: 'N/A', value: 'N/A'},{label: 'Yes', value: 'Yes'},{label: 'No', value: 'No'}]"
                           [(ngModel)]="data['Have you assisted others to learn how to grow?']" required
                           #selectedNav="ngModel" [default]="data['Have you assisted others to learn how to grow?']" placeholder="Please select"
            />
          </div>
        </div>
        <div class="flex items-center gap-4">
          <p class="w-1/3">How did they find you?</p>
          <div class="flex w-1/2 items-center gap-4">
            <app-om-select class="w-full"
                           containerClass="!bg-white !py-[4.5px] !font-poppins !font-medium bw-1 border-gray !text-[14px]"
                           containerItemsClass="!max-h-fit"
                           itemsClass="bg-white border-[#EEEEEE] !text-black hover:bg-white hover:!text-green4-native !py-1"
                           color="gray" [options]="[{label: 'N/A', value: 'N/A'},{label: 'Friends', value: 'Friends'},{label: 'Online', value: 'Online'},{label: 'Clubs', value: 'Clubs'},{label: 'Other', value: 'Other'}]"
                           [(ngModel)]="data['How did they find you?']" required
                           #selectedNav="ngModel" [default]="data['How did they find you?']" placeholder="Please select"
            />
          </div>
        </div>
        <div class="flex items-start gap-4">
          <p class="w-1/3">How did you help them?</p>
          <div class="flex w-1/2 items-center gap-4">
            <textarea [(ngModel)]="data['How did you help them?']"
                      class="w-full bw-1 rounded-lg p-4 textarea focus:ring-offset-0 focus:outline-none
                      focus:ring-1 focus:ring-omgreen-100 outline-omgreen-50"
            ></textarea>
          </div>
        </div>
        <div class="flex items-start gap-4">
          <p class="w-1/3">Can you provide us with the names and contact of at least 3 friends you think would be good Omnigram members and benefit from this platform?</p>
          <div class="flex w-1/2 items-center gap-4">
            <textarea [(ngModel)]="data['Can you provide us with the names and contact of at least 3 friends you think would be good Omnigram members and benefit from this platform?']"
                      class="w-full bw-1 rounded-lg p-4 textarea focus:ring-offset-0 focus:outline-none
                      focus:ring-1 focus:ring-omgreen-100 outline-omgreen-50"
            ></textarea>
          </div>
        </div>
        <div class="flex items-center gap-4">
          <p class="w-1/3">Do you have any experience rating cannabis?</p>
          <div class="flex w-1/2 items-center gap-4">
            <app-om-select class="w-full"
                           containerClass="!bg-white !py-[4.5px] !font-poppins !font-medium bw-1 border-gray !text-[14px]"
                           containerItemsClass="!max-h-fit"
                           itemsClass="bg-white border-[#EEEEEE] !text-black hover:bg-white hover:!text-green4-native !py-1"
                           color="gray" [options]="[{label: 'Yes', value: 'Yes'},{label: 'No', value: 'No'}]"
                           [(ngModel)]="data['Do you have any experience rating cannabis?']" required
                           #selectedNav="ngModel" [default]="data['Do you have any experience rating cannabis?']" placeholder="Please select"
            />
          </div>
        </div>
        <div class="flex items-center gap-4">
          <p class="w-1/3">Would you like to judge contests?</p>
          <div class="flex w-1/2 items-center gap-4">
            <app-om-select class="w-full"
                           containerClass="!bg-white !py-[4.5px] !font-poppins !font-medium bw-1 border-gray !text-[14px]"
                           containerItemsClass="!max-h-fit"
                           itemsClass="bg-white border-[#EEEEEE] !text-black hover:bg-white hover:!text-green4-native !py-1"
                           color="gray" [options]="[{label: 'Yes', value: 'Yes'},{label: 'No', value: 'No'}]"
                           [(ngModel)]="data['Would you like to judge contests?']" required
                           #selectedNav="ngModel" [default]="data['Would you like to judge contests?']" placeholder="Please select"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-4" *ngIf="step === 4">
      <h2 class="text-[#2a6368] text-mantis-700 font-semibold text-3xl ">More details</h2>
      <hr class="bw-1 border-[##00000014] h-0.5 z-10 my-4">
      <div class="flex items-start gap-4">
        <p>I know how to (please check all that apply)</p>
        <div class="flex flex-col min-w-2/3 items-start gap-4">
          <label class="custom-checkbox-container">
            <input type="checkbox" [(ngModel)]="data['I know how to (please check all that apply)']['Dry']" value="Dry"/>
            <span class="checkmark"></span>
            Dry
          </label>
          <label class="custom-checkbox-container">
            <input type="checkbox" [(ngModel)]="data['I know how to (please check all that apply)']['Cure']" value="Cure"/>
            <span class="checkmark"></span>
            Cure
          </label>
          <label class="custom-checkbox-container">
            <input type="checkbox" [(ngModel)]="data['I know how to (please check all that apply)']['Make Butter']" value="Make Butter"/>
            <span class="checkmark"></span>
            Make Butter
          </label>
          <label class="custom-checkbox-container">
            <input type="checkbox" [(ngModel)]="data['I know how to (please check all that apply)']['Make Rosin/ Pressing']" value="Make Rosin/ Pressing"/>
            <span class="checkmark"></span>
            Make Rosin/ Pressing
          </label>
          <label class="custom-checkbox-container">
            <input type="checkbox" [(ngModel)]="data['I know how to (please check all that apply)']['Concentrates']" value="Concentrates"/>
            <span class="checkmark"></span>
            Concentrates
          </label>
          <label class="custom-checkbox-container">
            <input type="checkbox" [(ngModel)]="data['I know how to (please check all that apply)']['Make hash']" value="Make hash"/>
            <span class="checkmark"></span>
            Make hash
          </label>
          <label class="custom-checkbox-container">
            <input type="checkbox" [(ngModel)]="data['I know how to (please check all that apply)']['Make edibles']" value="Make edibles"/>
            <span class="checkmark"></span>
            Make edibles
          </label>
          <label class="custom-checkbox-container">
            <input type="checkbox" [(ngModel)]="data['I know how to (please check all that apply)']['Others']" value="Others"/>
            <span class="checkmark"></span>
            Others
          </label>
        </div>
      </div>
      <div class="flex items-start gap-4">
        <p class="w-1/3">Please let us know if there's anything else you'd like to add</p>
        <div class="flex w-1/2 items-center gap-4">
            <textarea [(ngModel)]="data['Please let us know if there\'s anything else you\'d like to add']"
                      class="w-full bw-1 rounded-lg p-4 textarea focus:ring-offset-0 focus:outline-none
                      focus:ring-1 focus:ring-omgreen-100 outline-omgreen-50"
            ></textarea>
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-4" *ngIf="step === 5">
      <h2 class="text-[#2a6368] text-mantis-700 font-semibold text-3xl ">Almost there!</h2>
      <div class="font-semibold text-normal">
        I want to be an Omnigram Founding member. By accepting and paying my $199 one time fee, I agree to the following:
      </div>
      <hr class="bw-1 border-[##00000014] h-0.5 z-10 my-4">
      <ul class="list-disc px-8">
        <li class="pt-4">
          I will be positive and engaging in my communications with others;
        </li>
        <li class="pt-4">I will participate actively in socializing, sharing or gifting, rating, mentoring, and coaching if
          possible;
        </li>
        <li class="pt-4">I will help find new members when possible;</li>
        <li class="pt-4">I will help elevate others and help improve the content on the platform when possible; and</li>
        <li class="pt-4">I acknowledge our focus is on sharing, learning and community.</li>
      </ul>
      <div class="text-md font-bold mt-4">
        To accept this offer please proceed below by clicking next ...
      </div>
    </div>
    <div *ngIf="step === 6">
      <p
        class="sm:text-[16px] sm:text-[#000000] font-Montserrat font-medium">{{ 'Apply Promocode'}}</p>
      <div class="relative my-4">
        <input placeholder="Promotion or Discount code"
               class="px-4 border border-[rgba(15,13,35,0.2)] rounded-[12px] w-full h-[50px]  pr-[150px]"
               [(ngModel)]="promotionCode">
        <div class="absolute right-2 top-[8px]">
          <button (click)="validPromoCode()"
            class="sm: text-[14px] sm:w-[133px] sm:h-[34px] flex items-center justify-center bg-[#519935] text-white rounded-2xl">
            Apply Code
          </button>
        </div>
      </div>
      <div *ngIf="promoCodeStatus === 'accepted'" class="text-md font-bold my-4 text-omgreen2-600">
        Promocode applied, to accept this offer please proceed below by clicking accept.
      </div>
      <div *ngIf="promoCodeStatus === 'error'" class="text-md font-bold my-4 text-red-600">
        Promocode does not exist or does not apply to this record
      </div>
      <form *ngIf="payment" id="payment-form">
        <div id="payment-element">
          <!--Stripe.js injects the Payment Element-->
        </div>

        <div id="payment-message" class="text-red-600 font-semibold mt-2">
          {{ paymentmessage }}
        </div>
      </form>
    </div>
    <hr class="bw-1 border-[##00000014] h-0.5 z-10 my-4">
    <div class="flex items-center justify-end gap-8">
            <button [class]="{'opacity-0': step === 0}" (click)="previous()"
                    class="flex px-8 !py-[10px] h-fit gap-2 items-center font-normal text-lg border-2 bg-[#fff] text-[#202020] rounded-xl shadow-sm">
              Back
            </button>
      <button *ngIf="step === 6 && payment"
        (click)="checkout()"
        class="flex px-8 !py-[12px] h-fit gap-2 items-center font-normal text-lg bg-[#519935] text-white rounded-xl shadow-sm">
        Checkout
      </button>
      <button *ngIf="step === 6 && !payment"
        (click)="checkoutWithOutPayment()"
        class="flex px-8 !py-[12px] h-fit gap-2 items-center font-normal text-lg bg-[#519935] text-white rounded-xl shadow-sm">
        Accept
      </button>
      <button *ngIf="step !== 6"
        (click)="next()" [disabled]="validStep()"
        class="flex px-8 !py-[12px] h-fit gap-2 items-center font-normal text-lg bg-[#519935] text-white rounded-xl shadow-sm disabled:bg-mantis-100 disabled:text-gray-600">
        Next
      </button>
    </div>
  </div>
</div>
