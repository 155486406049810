import {Component, ElementRef, EventEmitter, Output, ViewChild} from '@angular/core';
import {TenorService} from "../../../tenor.service";

@Component({
  selector: 'app-gif-search',
  templateUrl: './gif-search.component.html',
  styleUrls: ['./gif-search.component.scss']
})
export class GifSearchComponent {
  @ViewChild('divGif', { static: true }) divGif: ElementRef | undefined;
  @Output() sendGif: EventEmitter<string> = new EventEmitter();
  query: string = '';
  gifs: any[] = [];
  list:any[]=[];
  private typingTimer: any;
  private doneTypingInterval = 500;

  constructor(private tenorService: TenorService) {}

  search(): void {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      if (this.query.trim() !== '') {
        this.tenorService.searchGifs(this.query).subscribe((response: any) => {
          this.gifs = response.results;
          this.list=this.gifs.slice(0, 6)
        });
      } else {
        this.tenorService.searchGifs('happy').subscribe((response: any) => {
          this.gifs = response.results;
          this.list=this.gifs.slice(0, 6)
        });
      }
    }, this.doneTypingInterval);
  }
  endScroll() {
    if (!this.divGif) return;
    const element = this.divGif.nativeElement;
    const scrollTop = element.scrollTop || element.scrollTop === 0 ? element.scrollTop : element.parentElement.scrollTop;
    const clientHeight = element.clientHeight || element.parentElement.clientHeight;
    const scrollHeight = element.scrollHeight || element.parentElement.scrollHeight;
    const marginOfError = 1;
    if (scrollTop + clientHeight >= scrollHeight - marginOfError) {
      console.log('¡Has llegado al final del div!');
      const size = this.list.length + 6;
      this.list = this.gifs.slice(0, size);
    }
  }
  getDataBase64URL(url:string) {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const reader = new FileReader();
          reader.onload = () => {
            const base64data = reader.result;
            resolve(base64data);
          };
          reader.readAsDataURL(blob);
        })
        .catch(error => reject());
    });
  }
  async selectGif(gif: any) {
    const data=await this.getDataBase64URL(gif.media_formats.gif.url)
    if (typeof data === 'string')this.sendGif.emit(data);
  }
  ngOnInit(): void {
    this.search()
  }
}
