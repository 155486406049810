<div>
  <div class="flex justify-between items-center">
    <p class="font-Poppins font-[600] text-[10px] text-[#171725] ">About Group</p>
  </div>
  <hr class="hidden sm:block border-[#EEEEEE] my-2">
  <div class="px-3">
    <p class="text-[#44444F] text-[10px] font-Roboto">
      {{data.communityDescription}}
    </p>
  </div>
  <hr class="hidden sm:block border-[#EEEEEE] mt-1">
  <div class="mt-4">
    <p class="font-Poppins font-[600] text-[10px] text-[#171725] ">Group Created by</p>
    <div class="flex gap-[5px] container-avatar my-4">
      <img alt="" class="w-[50px] h-[50px] rounded-full object-cover" [src]="data.user.image_profile || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'">
      <div class="flex gap-2 justify-between items-center">
        <div>
          <p class="ml-[15px] text-[12px] sm:text-[10px] font-semibold font-Poppins leading-1">{{ data.user.name }} {{data.user.lastname}}</p>
          <p class="ml-[15px] mt-[0px] text-[12px] sm:text-[10px] text-[#00000099] font-Poppins leading-1">12 April 2024</p>
        </div>
        <div class="flex gap-2 mt-[10px] sm:mt-[10px]">
          <span class="flex items-center justify-center text-[10px] font-Poppins font-bold text-[#519935] border border-[#81CB52] w-[70px] h-[29px] rounded-2xl cursor-pointer">+ Add</span>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-4">
    <p class="font-Poppins font-[600] text-[10px] text-[#171725] ">Group type</p>
    <p class="font-Poppins text-[10px]">{{ data.communityCategory }}</p>
  </div>
  <div class="mt-4">
    <div class="flex justify-between items-center">
      <p class="font-Poppins font-[600] text-[10px] text-[#171725] ">Member´s {{data.communityMembersCount}}</p>
      <p class="font-Poppins text-[10px] text-[#0062FF]">SEE ALL</p>
    </div>
    <div class="flex gap-2"></div>
  </div>
  <div class="mt-4 hidden">
    <div class="flex items-center justify-between">
      <p class="font-Poppins font-[600] text-[10px] text-[#171725] ">Nearest Events</p>
      <span class="material-symbols-outlined text-[#a9abad] cursor-pointer"> more_horiz </span>
    </div>
  </div>
  <div class="mt-4">
    <p class="font-Poppins font-[600] text-[10px] text-[#171725] ">Suggested Group</p>
    <app-mini-card-community class="block mt-2" [data]="data"></app-mini-card-community>
  </div>

</div>
