import {Component, inject, Input} from '@angular/core';
import {CheckoutServiceService} from "../../../../services/checkout-service/checkout-service.service";
import {UserInfoService} from "../../../../services/user-info/user-info.service";

@Component({
  selector: 'app-card-course2',
  templateUrl: './card-course2.component.html',
  styleUrls: ['./card-course2.component.scss']
})
export class CardCourse2Component {
  private checkoutService= inject(CheckoutServiceService);
  constructor(private userInfo: UserInfoService){}
  @Input() data:any={
    category: "",
    count_reviews: 0,
    count_solded : 0,
    createDate: "",
    description: "",
    images:[],
    initial_cost:0,
    language: "Spanish",
    name:"",
    pk:"",
    publish_cost:0,
    requirements:"",
    sk: "",
    val_ranked: 0,
    val_reviews: 0,
    countUser: 0,
    user:{} as any,
    friends:[],
    purchased:false
  }
  isMe(){
    return this.userInfo.isMe(this.data.sk.replace('uc#',''))
  }
  openPay(){
    let customConfig:any={
      createPayment:true,
      data:{
        courses:[]
      },
      ids:{
        courses:[]
      }
    }
    customConfig.data.courses.push(this.data)
    customConfig.ids.courses.push({sk:this.data.sk,pk:this.data.pk})
    this.checkoutService.setCustomConfig(customConfig);
    this.checkoutService.showCustomDialog();

  }
}
