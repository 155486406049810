import {AfterViewInit, Component, inject, Input} from '@angular/core';
import {OmAlertService} from "../../om-alert.service";
import {Router} from "@angular/router";
import {catchError, throwError} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {ChatService} from "../../services/chat/chat.service";
import {environment} from "../../../environments/environment";
import {Location} from "@angular/common";

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements AfterViewInit {
  private alertService = inject(OmAlertService);
  promotionCode = '';
  // @ts-ignore
  stripe: any = Stripe(environment.pkStripe)
  private http = inject(HttpClient);
  private chatService = inject(ChatService);
  router = inject(Router);
  @Input() shipping = 0;
  @Input() isEnableElement = true;
  @Input() isPayable = true;
  @Input() viewMobil = false;
  @Input() enable = false;
  @Input() viewEditFormDelivery = false;
  @Input() enableInfoAddress = true;
  @Input() enableEditFormDelivery = false;
  @Input() viewEditAddress = true;
  enableRequiredInput = false;
  @Input() data = {
    gifts: [] as IProductCart[],
    user: {
      id: '',
      username: '',
      name: '',
      email: '',
      phoneNumber: '',
      avatar: '',
    },
    userSend: {
      id: '',
      username: '',
      name: '',
      avatar: '',
    },
    addres: '',
    address: {
      id: '',
      address: '',
      lat: '',
      lng: '',
      isVerified: true,
    },
    message: '',
    subtotal: 0,
    total: 0,
    delivery: 0
  }
  currency = 'CAD';
  elements: any;
  clientSecret: string = '';
  paymentmessage: string = '';

  constructor(private location: Location) {
    let url = this.router.url.split('?')[0]
    if (Object.entries(JSON.parse(localStorage.getItem('claimGift') || '{}')).length === 0 && url === '/claim-gift') {
      window.location.href = '/home'
    }
  }

  ngOnInit() {
    window.scrollTo(0, 1);

  }

  ngAfterViewInit() {
    this.checkStatus()
  }

  createPayment(isDeskt: boolean) {
    let url = this.router.url.split('?')[0]
    if (url === '/claim-gift') {
      localStorage.setItem('claimGift', JSON.stringify(this.data));
    }
    this.http.post('/create-payment-intent', {
      data: {
        giftId: this.data.gifts[0].id,
        pk: this.data.gifts[0].pk.replace(/\bup#\b/g, '')
      },
      type: 'gift'
    }).subscribe({
      next: (response: any) => {
        const {clientSecret, dataStripe, status, message, currency} = response
        if (status != 'success') {
          alert(message)
          return;
        }
        this.shipping = dataStripe['shipping_cost'];
        this.currency = currency;
        const appearance = {
          theme: 'stripe',
        };
        this.clientSecret = clientSecret;
        const elements = this.stripe.elements({locale: 'en', appearance, clientSecret});
        this.elements = elements;
        const paymentElementOptions = {
          layout: "tabs",
        };
        const paymentElement = elements.create("payment", paymentElementOptions);
        if (isDeskt)
          paymentElement.mount("#payment-element");
        else
          paymentElement.mount("#payment-element2");
      },
      error: (err) => {
        alert("Error")
      }
    });
  }

  getSubTotal() {
    return this.data.gifts.reduce((acumulador, producto) => acumulador + producto.total, 0);
  }

  getTotal() {
    const sum = this.data.gifts.reduce((acumulador, producto) => acumulador + producto.total, 0);
    return sum + this.shipping;
  }

  async checkStatus() {
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {

      return;
    }

    const {paymentIntent} = await this.stripe.retrievePaymentIntent(clientSecret);

    switch (paymentIntent.status) {
      case "succeeded":
        this.store(paymentIntent.id);
        break;
      case "processing":
        alert("Your payment is processing.");
        break;
      case "requires_payment_method":
        alert("Your payment was not successful, please try again.");
        break;
      default:
        alert("Something went wrong.");
        break;
    }
  }

  async checkout(isPay = true) {
    if (!isPay) {
      this.store(null)
      return;
    }
    let url = this.router.url.split('?')[0]
    if (url === '/claim-gift') {
      const data = JSON.parse(localStorage.getItem('claimGift') || '{}');
      data.address = this.data.address;
      data.user['phoneNumber'] = this.data.user['phoneNumber'] || ''
      //localStorage.setItem('claimGift', JSON.stringify(data));
    }
    const urlRaiz = window.location.origin;
    const {error} = await this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: urlRaiz + this.router.url,
      },
    });


    if (error.type === "card_error" || error.type === "validation_error") {
      this.paymentmessage = (error.message);
    } else {
      this.paymentmessage = ("An unexpected error occurred.");
    }
  }

  store(paymentIntent: any) {

    let url = this.router.url.split('?')[0]
    const customConfig = {
      title: url === '/claim-gift' ? 'Gift claim' : 'Gift sent',
      description: url === '/claim-gift' ? '' : 'You’ve sent a Gift.',
      containerClass: 'logout-dialog',
      closeOnPress: false,
      activeButtonCancel: false
    };
    let data = {
      paymentIntent,
      isClaim: url === '/claim-gift',
      pk: this.data.gifts[0].pk.replace(/\bup#\b/g, ''),
      iduser_to: this.data.user.id,
      address_obj: this.data.address,
      address: `${this.data.address.address}`,
      type: customConfig.title === 'Gift sent' ? 'giftSended' : 'giftClaimed',
      pay_method: 'stripe',
      shipping_cost: this.shipping,
      total: this.getTotal(),
      idproducts: this.data.gifts.map(objeto => objeto.id),
      personal_detail: {
        fullname: this.data.user.name,
        email: this.data.user.email,
        phone: this.data.user.phoneNumber,
      },
      message: this.data.message

    };
    /*
    this.alertService.setCustomConfig(customConfig);
    this.alertService.showCustomDialog();
    this.alertService.getIsOKPressed().subscribe(isOK => {
      if (isOK === true) {
        this.alertService.closeDialog()
        this.router.navigateByUrl('/gift-center')
      }
    });
    */
    this.http.post('/merch/add-merch', data)
      .pipe(
        catchError((error) => {
          console.error('Error en la solicitud HTTP:', error);
          return throwError(error);
        })
      )
      .subscribe({
        next: (response: any) => {
          if (response.success) {
            if (customConfig.title === 'Gift sent') {
              this.chatService.sendPush(
                response.merch.merchIdUserRecived,
                {meta: {id: response.merch.merchId}},
                'gift');
            }
            localStorage.setItem('sendGift', JSON.stringify({}));
            localStorage.setItem('claimGift', JSON.stringify({}));
            this.alertService.setCustomConfig(customConfig);
            this.alertService.showCustomDialog();
            this.alertService.getIsOKPressed().subscribe(isOK => {
              if (isOK === true) {
                this.alertService.closeDialog()
                this.router.navigateByUrl('/gift-center')
              }
            });
          }
        }
      });
  }

  continue(isDeskt = true) {
    this.enableRequiredInput = false;
    let array = (this.data.address?.address || '').split(' ');
    let country = array[array.length - 1];

    if (this.data.user.name.trim() === '' || this.data.user.email.trim() === '' || this.data.user.phoneNumber.trim() === '') {
      this.enableRequiredInput = true;
      return;
    }
    if (country.trim().toLowerCase() !== 'canada' && country.trim().toLowerCase() !== 'canadá') {
      const customConfig = {
        title: 'Can\'t continue',
        description: 'Shipping is only allowed to Canada!',
        containerClass: 'logout-dialog',
        closeOnPress: false,
        activeButtonCancel: false
      };
      this.alertService.setCustomConfig(customConfig);
      this.alertService.showCustomDialog();
      this.alertService.getIsOKPressed().subscribe(isOK => {
        if (isOK === true) {
          this.alertService.closeDialog()
        }
      });
      return
    }
    //se valida que la direcion sea correcta para cambiar al pago
    this.viewEditFormDelivery = false
    this.enableEditFormDelivery = false
    this.createPayment(isDeskt);
  }

  setviewEditFormDelivery(status: boolean) {
    this.viewEditFormDelivery = status;
  }

  openCloseEdit(status: boolean) {
    this.enableEditFormDelivery = status;
    if (!this.viewEditFormDelivery)
      this.viewEditFormDelivery = status;
  }

  back() {
    if (this.viewEditFormDelivery) this.location.back();
    this.setviewEditFormDelivery(true)
  }
}
