import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject, OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {GiftService} from "../../../services/gift/gift.service";
import {CatalogsService} from "../../../services/catalogs/catalogs.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ProductDialogComponent} from "../../general/product-dialog/product-dialog.component";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-home-market-place',
  templateUrl: './home-market-place.component.html',
  styleUrls: ['./home-market-place.component.scss']
})
export class HomeMarketPlaceComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('filterList') filterList: ElementRef | undefined;
  @ViewChild('containerFilterList') containerFilterList: ElementRef | undefined;
  @ViewChild(ProductDialogComponent, {static: false}) productDialogComponent: ProductDialogComponent | undefined;
  @Output() selectGift = new EventEmitter<string>();

  private http = inject(HttpClient);
  router = inject(Router);
  private catalogsService = inject(CatalogsService);
  giftService = inject(GiftService);
  showFilters = false
  a = 1
  userId = ''
  search = ''
  giftId = ''
  selected = 'all'
  filterTab = 'sort'
  filterType = 'Relevance'
  filters: { [key: string]: string } = {
    sort: 'val_ranked',
    category: 'all',
    type: 'all',
  }
  sorts: { label: string, value: string }[] = [
    {label: 'Relevance', value: 'val_ranked'},
    {label: 'Top Sales', value: 'count_sold'},
    {label: 'Most Recent', value: 'createDate'},
    // {label: 'Price Low to High', value: 'Price-Low-to-High'},
    // {label: 'Price High to Low', value: 'Price-High-to-Low'},
  ]
  categories: { label: string, value: string }[] = [{label: 'All Category', value: 'all'},]
  types: { label: string, value: string }[] = [{label: 'All Types', value: 'all'},]
  isFilter = false;

  constructor(private route: ActivatedRoute) {
  }

  subscriptionQueryParams: Subscription | undefined;

  ngOnInit() {
    // const params = new URLSearchParams(window.location.search);
    // console.log(params)
    this.subscriptionQueryParams = this.route.queryParams.subscribe(params => {
      this.giftId = params['gift_id'] || '';
      this.search = params['search'] || '';
      this.filters['category'] = params['category'] || 'all'
      this.filters['type'] = params['type'] || 'all';
      this.filters['sort'] = params['sort'] || 'val_ranked';
      this.isFilter = this.filters['category'] !== "all" || this.filters['type'] !== 'all';
    })
    this.getCategories()
    this.getTypes()
  }

  ngOnDestroy() {
    this.subscriptionQueryParams?.unsubscribe()
  }

  ngAfterViewInit() {
    if (this.giftId != '') {
      this.productDialogComponent?.openProduct(this.giftId)
    }
  }

  setIdGift(id: string) {
    this.giftId = id;
    this.updateNavigation();
  }

  updateNavigation() {
    this.router.navigate(['/strain-finder'], {
      queryParams: {
        'category': this.filters['category'],
        'type': this.filters['type'],
        'sort': this.filters['sort'],
        'search': this.search,
        'gift_id': this.giftId
      },
    })
  }

  getTypes() {
    this.catalogsService.getAll('product-type', '').subscribe({
      next: (response: any) => {
        this.types = [...this.types, ...response.records.map((x: any) => {
          return {
            value: x.name.toLowerCase(),
            label: x.name,
          };
        })]
      }
    })
  }

  getCategories() {
    this.catalogsService.getAll('categories', '').subscribe({
      next: (response: any) => {
        this.categories = [...this.categories, ...response.records.map((x: any) => {
          return {
            value: x.name.toLowerCase(),
            label: x.name,
          };
        })]
      }
    })
  }

  setSort(key: string) {
    this.filterTab = key;
  }

  setFilterType(key: string) {
    this.filters[this.filterTab] = key;
    this.updateNavigation();
    //this.getSharables();
  }

  getCatalogFilter() {
    let catalog: typeof this.categories = [];
    switch (this.filterTab) {
      case 'sort':
        catalog = this.sorts;
        break;
      case 'category':
        catalog = this.categories;
        break;
      case 'type':
        catalog = this.types;
        break;
    }
    return catalog;
  }

  claimgift(data: any) {
    this.giftService.claimGift(data)
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!(this.filterList as ElementRef).nativeElement.contains(event.target) && this.showFilters) {
      this.showFilters = false;
    }
  }
}
