<div class="px-4 pl-6  w-full flex flex-col justify-between
      pt-[var(--sat)]
      bg-[#262628] bg-cover bg-[url('assets/general/wave-m.webp')] sm:bg-none bg-center
      sm:h-[55vh] sm:w-[36.6vw] sm:bg-[#2e2e2e]
      sm:mt-[calc(var(--w-height)*15)]
      sm:pt-[calc(var(--w-height)*6)]
      sm:pb-[calc(var(--w-height)*4)]
      sm:mr-[calc(var(--w-height)*15)]
      sm:px-[calc(var(--w-width)*5)]
      sm:rounded-[2vw]">
  <div>
    <ng-icon name="matArrowBack" class="text-white my-4 text-4xl inline-block sm:!hidden" routerLink="/"></ng-icon>
    <p class="title-form text-[calc(var(--mw-height)*4.5)] sm:text-[calc(var(--w-height)*4.5)]">
      Reset your password</p>
    <p class="text-[#C3C3C3] mt-[calc(var(--mw-height)*1)] sm:mt-[calc(var(--mw-height)*2)] sm:text-white text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*2)]">
      Please enter your email and we will send<br> you an OTP code in the next step to reset your password.</p>
    <div class="mt-[calc(var(--mw-height)*6)] sm:mt-[calc(var(--mw-height)*6)]">
      <app-om-input label="Email" placeholder="andrew.ainsley@gmail.com" id="email" type="email" [inputMode]="'email'"
                    [(ngModel)]="email"  [default]="email" #emailField="ngModel" required></app-om-input>
      <p *ngIf="emailField.invalid && emailField.touched"
         class="text-red-500 text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*1.5)]">
        Email is required.</p>
      <p *ngIf="emailNotExist"
         class="text-red-500 text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*1.5)]">
        Email not found.</p>
    </div>
  </div>
  <div class="w-full items-center">
    <app-om-button [text]="'Continue'"
                   class="w-full"
                   [height]="{xs:'7',sm:'6'}"
                   [fontSize]="{xs:'3',sm:'2.6'}"
                   [iconPosition]="'right'"
                   (click)="continue()">
      <img alt="flecha" [class]="'w-[calc(var(--mw-height)*3)] sm:w-[calc(var(--w-height)*3.5)]'" src="assets/general/flecha_larga.png">
    </app-om-button>
    <p
      class="text-white text-center m-[calc(var(--mw-height)*4)] mb-[calc(var(--mw-height)*2.5)] sm:mb-[calc(var(--mw-height)*1.5)] text-[calc(var(--mw-height)*1.4)]
          block sm:absolute sm:w-[36.6vw] sm:right-[calc(var(--w-height)*15)]
          sm:mr-[calc(var(--w-height)*0)]
          sm:bottom-0 sm:mb-[calc(var(--w-height)*10)] sm:text-[calc(var(--w-height)*2)]"
    >Not signed up? <span
      class="text-green-native cursor-pointer underline" (click)="openExternalUrl()" RRouterLink="/register">Join now!</span></p>
  </div>
</div>
