import {Component, inject, Input, OnInit} from '@angular/core';
import {UserInfoService} from "../../../../services/user-info/user-info.service";
import {HttpClient} from "@angular/common/http";
import {DateTime} from "luxon";

@Component({
  selector: 'app-aboute-me',
  templateUrl: './aboute-me.component.html',
  styleUrls: ['./aboute-me.component.scss']
})
export class AbouteMeComponent implements OnInit {
  private userInfoService = inject(UserInfoService);
  private http = inject(HttpClient);
  @Input() user: any = {}
  country = ''
  ngOnInit() {
  }
  isMe() {
    return this.userInfoService.isMe(this.user?.id)
  }
  showMySaved() {

  }

  protected readonly DateTime = DateTime;
}
