import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {FollowAndFriendService} from "../../../../../services/follow-and-friend/follow-and-friend.service";

@Component({
  selector: 'app-post-tag-people',
  templateUrl: './post-tag-people.component.html',
  styleUrls: ['./post-tag-people.component.scss']
})
export class PostTagPeopleComponent implements OnInit {
  @Output() peopleSelectedChange = new EventEmitter<any[]>();
  @Input() peopleSelected: any[] = [];
  @Input() classTitle: any = {
    'font-Montserrat':true,
    'font-medium':true,
    'sm:font-normal':true,
  };
  @Output() tagPeopleChange = new EventEmitter<string[]>();
  @Input() tagPeople: any = {};
  @Input() open: boolean = false;
  @Input() title: string='Tag people';
  @Output() openChange = new EventEmitter<boolean>();
  followAndFriendService = inject(FollowAndFriendService);
  friends: any[] = [];

  async ngOnInit() {
    this.friends = await this.followAndFriendService.getFollowings() as any[];
    for (const index in this.friends) {
      this.friends[index]['checked']=false;
    }
    for (const friend of this.friends) {
      this.tagPeople[friend.id]=false;
    }
  }
  onOpenChange() {
    this.openChange.emit(this.open);
  }
  onChangeSendToPeople() {
    let tags: string[] = [];
    this.friends.filter(x => x['checked']).forEach(x => {
      tags.push(x.id)
    })
    this.tagPeopleChange.emit(tags);
    this.peopleSelected = this.friends.filter(x => x['checked']);
    this.peopleSelectedChange.emit(this.peopleSelected);
  }
  getName(user: any) {
    if (!user["name"]) return user['username'];

    return `${user["name"]} ${user["lastname"]}`
  }
}
