import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-right-check-icon',
  templateUrl: './right-check-icon.component.svg',
  styleUrls: ['./right-check-icon.component.scss']
})
export class RightCheckIconComponent {
  @Input() color = '#77838f';
  @Input() width = 24;
  @Input() height = 24;
}
