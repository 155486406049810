<div class="flex gap-4 flex-col sm:p-4 mt-2 sm:mt-4 rounded-lg bg-white  w-full">
  <div class="flex flex-col gap-1 sm:gap-3">
    <p class="font-semibold font-Montserrat text-[20px] sm:text-[18px]">Featured Communities</p>
    <p class="font-normal font-Montserrat text-[14px] text-[#92929D]">Communities you might be interested in.</p>
  </div>
  <div class="flex gap-4 w-full overflow-auto ocultarScroll">
    <app-community-card *ngFor="let item of communitiesFeatured" [data]="item"
                        class="block min-w-[200px] w-1/4"></app-community-card>
  </div>
</div>
