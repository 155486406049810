import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-slide-post',
  templateUrl: './slide-post.component.html',
  styleUrls: ['./slide-post.component.scss']
})
export class SlidePostComponent {
  @ViewChild('imageContainer') imageContainer: ElementRef | undefined;
  @Output() openFile = new EventEmitter<any>();
  @Input() files:any  = [];
  @Input() filters:any  = [];
  @Input() isView  = false;
  @Input() isProfile  = false;
  @Input() isViewFile  = false;
  @Input() useSlide  = 'default';
  currentImageIndex = 0;

  touchStartX: number =0;
  touchMoveX: number =0;

  onTouchStart(event: TouchEvent): void {
    if(this.files.length==1) return
    this.touchStartX = event.touches[0].clientX;
    this.touchMoveX = 0;
  }

  onTouchMove(event: TouchEvent): void {
    if(this.files.length==1) return
    this.touchMoveX = event.touches[0].clientX - this.touchStartX;

    // Aplica la transformación para crear el efecto de desplazamiento
    const transformValue = `translateX(${this.touchMoveX}px)`;
    if(this.imageContainer) this.imageContainer.nativeElement.style.transform = transformValue;
  }

  onTouchEnd(event: TouchEvent): void {
    if(this.files.length==1) return
    // Ajusta el valor según la sensibilidad del deslizamiento
    if (this.touchMoveX > 50) {
      this.showPreviousImage();
    } else if (this.touchMoveX < -50) {
      this.showNextImage();
    } else {
      if(this.imageContainer) this.imageContainer.nativeElement.style.transform = 'translateX(0)';
    }
  }

  showPreviousImage(): void {
    this.currentImageIndex = (this.currentImageIndex - 1 + this.files.length) % this.files.length;
    if(this.imageContainer) this.imageContainer.nativeElement.style.transform = 'translateX(0)';
  }

  showNextImage(): void {
    this.currentImageIndex = (this.currentImageIndex + 1) % this.files.length;
    if(this.imageContainer) this.imageContainer.nativeElement.style.transform = 'translateX(0)';
  }
  getTypeFile(nombreArchivo:string) {
    // @ts-ignore
    const extension = nombreArchivo.split('.').pop().toLowerCase();
    if (['jpg', 'jpeg', 'png', 'gif',"bmp", "ico", "svg", "webp", "avif"].includes(extension)) {
      return 'image';
    } else if (['mp4', 'avi', 'mkv', 'mov'].includes(extension)) {
      return 'video';
    } else {
      return '';
    }
  }
  clickFile() {
    this.openFile.emit();
  }
  getClsFilter(clsA : {[key: string]: boolean} = {}, filter: ''){
    let cls: {[key: string]: boolean} = clsA
    console.log(filter)
    if(filter){
      cls[filter] = true
    } else {
      cls['normal'] = true
    }
    return cls
  }
}
