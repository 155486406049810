import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-text-icon',
  templateUrl: './text-icon.component.svg',
  styleUrls: ['./text-icon.component.scss']
})
export class TextIconComponent {
  @Input() color = '#292D32';
  @Input() width = 24;
  @Input() height = 24;
}
