<div class="relative mb-4 sm:mb-[20px]" *ngIf="categories?.[indexCategory]?.img">
  <div class="w-full rounded-2xl px-6 relative flex items-end h-[140px] bg-cover bg-[center_top_-20px] sm:h-[270px] pb-2"  [style.background-image]="'url(' + categories[indexCategory].img+ ')'">
<!--    <div class="absolute rounded-2xl left-0 top-0 w-full h-full bg-gradient-to-t from-black via-transparent to-transparent"></div>-->
    <p class="z-10 font-Monserrat font-semibold pb-4 sm:pl-4 sm:pb-[35px] text-[30px] leading-[36px] sm:text-[40px] sm:leading-[41px] text-white max-w-[446px]">
      {{categories[indexCategory].title}}
    </p>
  </div>
</div>
<div class="overflow-auto" *ngIf="categories?.[indexCategory]?.name">
    <div class="flex gap-[10px] ocultarScrollXNoVisible pb-4 sm:pb-6 flex-row sm:justify-between sm:gap-8">
    <ng-container *ngFor="let type of types;">
          <div routerLink="/market-search" [queryParams]="{ type: categories[indexCategory].name,type2:type.name }" class="cursor-pointer  relative flex items-end justify-start rounded-lg sm:rounded-[22px] border-green-native"
               [class]="{
              'border-4':type.name==type2
             }"
          >
            <div class="relative w-[120px] h-[55px] sm:min-w-[260px] sm:w-[22%] sm:h-[100px] rounded-lg sm:rounded-2xl bg-cover" [style.background-image]="'url(' + type.img + ')'">
              <div class="absolute  rounded-lg sm:rounded-2xl left-0 top-0 w-full h-full bg-gradient-to-t via-transparent from-black to-transparent"></div>
              <p class="z-10 absolute flex items-end sm:items-end  sm:justify-normal font-Monserrat font-semibold  mb-[-8px]  pl-[8px] sm:pl-0 text-[18px] sm:ml-[15px] top-0 w-full h-full p-[5px] sm:mb-[5px] text-white
                sm:pb-4 sm:text-[20px] sm:leading-[25px]">
                {{type.title}}
              </p>
            </div>
          </div>
    </ng-container>
    </div>
</div>
