import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-gift-and-share',
  templateUrl: './gift-and-share.component.html',
  styleUrls: ['./gift-and-share.component.scss']
})
export class GiftAndShareComponent implements OnInit {
  @Output() sendData = new EventEmitter<any>();
  @Output() previousStep = new EventEmitter<any>();
  currentIndex: number = 0;
  timeoutId?: number;
  slides = [
    {
      title: 'Connect',
      message: 'Forge meaningful connections with like-minded enthusiasts and growers, fostering a supportive community where you can share experiences and insights.'
    },
    {
      title: 'Learn',
      message: 'Gain access to valuable resources, expert advice, and exclusive educational content to enhance your growing skills and deepen your understanding of cannabis cultivation.'
    },
    {
      title: 'Share',
      message: 'Share your expertise, tips, and successes with others, while also receiving feedback and inspiration from fellow members. It\'s a collaborative space where everyone contributes to the collective knowledge base.'
    },
  ]
  ngOnInit() {
    this.resetTimer();
  }

  previous() {
    this.previousStep.emit();
  }
  sendDataC() {
    this.sendData.emit([]);
  }
  openInNewTab(url: string) {
    window.open(url, '_blank');
  }
  goToPrevious(): void {
    const isFirstSlide = this.currentIndex === 0;
    const newIndex = isFirstSlide
      ? this.slides.length - 1
      : this.currentIndex - 1;

    this.resetTimer();
    this.currentIndex = newIndex;
  }

  goToNext(): void {
    const isLastSlide = this.currentIndex === this.slides.length - 1;
    const newIndex = isLastSlide ? 0 : this.currentIndex + 1;

    this.resetTimer();
    this.currentIndex = newIndex;
  }
  resetTimer() {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }
    this.timeoutId = window.setTimeout(() => this.goToNext(), 3000);
  }
  goToSlide(slideIndex: number): void {
    this.resetTimer();
    this.currentIndex = slideIndex;
  }
}
