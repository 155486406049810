import {Component, Input} from '@angular/core';
import {DeviceInfoService} from "../../../services/device-info/device-info.service";

@Component({
  selector: 'app-home-other-layout',
  templateUrl: './home-other-layout.component.html',
  styleUrls: ['./home-other-layout.component.scss']
})
export class HomeOtherLayoutComponent {
  @Input() title= "";
  @Input() useHeaderEdit = false;
  constructor(public deviceInfo: DeviceInfoService) {
  }
  ngOnInit(){
    this.deviceInfo.scrollTop();
  }
  toggleMenu(){
    this.deviceInfo.toggleMenu()
  }
}
