<app-carousel class="w-full" [isStrainFinder]="true" [moveAmount]="0.23" [styleContainer]="['gap-6']">
  <header-left>
    <div class="flex gap-1 sm:gap-4 items-center mb-4">
      <h1 class="text-[25px] font-semibold font-Montserrat">Top Growers</h1>
      <p class="flex items-center text-green2-native mt-[3px] text-[15px] font-medium font-Montserrat"  routerLink="/growers-home">
        <span class="hidden sm:block cursor-pointer">View All</span>
        <span class="material-symbols-outlined flex items-center justify-center border border-green4-native w-[20px] h-[20px] sm:text-[16px] sm:w-auto rounded-full sm:border-0">navigate_next</span>
      </p>
    </div>
  </header-left>
  <header-action>
  </header-action>
  <div class="relative border-[#707070] shadow-[0px_3px_6px_#00000029] sm:shadow-none friend-card w-1/2 mr-[10px] sm:mr-[35px] sm:w-[228px] mb-1" *ngFor="let user of users">
    <div class="h-[160px] sm:h-[213px] overflow-hidden rounded-lg">
      <img [src]="user['banner'] || 'assets/demo/paisaje.webp'" class="object-cover w-full h-full rounded-t-lg" alt="">
    </div>
    <div class="flex items-center justify-between px-[15px] sm:px-0 sm:justify-normal sm:gap-4 relative mt-[10px] sm:mt-[15px]">
      <img
          [src]="user['image_profile'] || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'"
          alt="" class="w-[60px] h-[60px] sm:w-[48px] sm:h-[48px] border-[6px] border-[#fff] rounded-full object-cover	">
      <div class="" *ngIf="user['name']">
        <p class="text-[15px] font-semibold text-[#000000] font-Poppins">{{ user['name'] }}</p>
        <p class="text-[12px] text-[#000000] font-Poppins">@{{ user['username'] }}</p>
      </div>
    </div>
    <div class="flex items-center justify-center w-full gap-3 py-[30px] sm:pt-[30px] sm:pb-0">
      <p class="text-[10px] font-Montserrat bg-[#FFFFFF] font-poppins font-medium sm:text-[12px] text-[#519935] bw-1 border-[#81CB52] rounded-2xl flex justify-center items-center w-[75px] h-[24px]">
        Follow</p>
      <p class="text-[10px] font-Montserrat bg-[#87D255] font-poppins font-medium sm:text-[12px] text-[#FFFFFF] bw-1 border-[#87D255] rounded-2xl flex justify-center items-center w-[75px] h-[24px]">
        View</p>
    </div>
  </div>
</app-carousel>
