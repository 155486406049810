import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-image-gif-icon',
  templateUrl: './image-gif-icon.component.svg',
  styleUrls: ['./image-gif-icon.component.scss']
})
export class ImageGifIconComponent {
  @Input() color = '#77838f';
  @Input() width = 24;
  @Input() height = 24;
}
