import {AfterViewInit, Component, inject, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {catchError, throwError} from "rxjs";
import {Router} from "@angular/router";

@Component({
  selector: 'app-founder-register',
  templateUrl: './founder-register.component.html',
  styleUrls: ['./founder-register.component.scss']
})
export class FounderRegisterComponent implements OnInit, AfterViewInit {
  http = inject(HttpClient);
  regions: any = [];
  router = inject(Router);
  currency = 'CAD';
  elements: any;
  clientSecret: string = '';
  paymentmessage: string = '';
  promotionCode: string = '';
  // @ts-ignore
  stripe: any = Stripe(environment.pkStripe)
  data: any = {
    'Are you 18 years of age?': '',
    'Are you in Canada?': '',
    'What province are you in?': '',
    email: '',
    firstName: '',
    lastName: '',
    phone: null,
    'Help us learn more to help you! Check all that apply': {
      'Enthusiast: not interested in growing at this time': '',
      'New to growing: want to learn': '',
      'Some growing experience: fairly competent': '',
      'Experienced grower: who actively grows': '',
      'Experienced grower: who wants to be a coach': '',
    },
  communityParticipation: null,
  growerParticipation: null,
  mentorParticipation: null,
  'Have you worked with a commercial grower?': '',
  'How long have you been growing?': '',
  'How many times per year do you grow?': '',
  'Do you grow (check all that apply)': {
    'Indoors': '',
    'Outdoors': '',
    'Hydroponics': '',
    'Aquaponics': '',
    'Other': '',
  },
  'How many strains have you tried growing?': '',
  'What is your favorite strain?': '',
  'How many grams(dry) do you produce from each plant?': '',
  'How many plants from each harvest?': '',
  'Like other members, are you willing to share your excess grow with virtual friends to get feedback and experience new strains': '',
  'Why?': '',
  'Have you shared your excess grows with friends?': '',
  'How often?': '',
  'What do you typically do with excess product?': '',
  'Have you assisted others to learn how to grow?': '',
  'How did they find you?': '',
  'How did you help them?': '',
  'Can you provide us with the names and contact of at least 3 friends you think would be good Omnigram members and benefit from this platform?': '',
  'Do you have any experience rating cannabis?': '',
  'Would you like to judge contests?': '',
  'I know how to (please check all that apply)': {
    'Dry': '',
    'Cure': '',
    'Make Butter': '',
    'Make Rosin/ Pressing': '',
    'Concentrates': '',
    'Make hash': '',
    'Make edibles': '',
    'Others': '',
  },
  'Please let us know if there\'s anything else you\'d like to add': '',
  };
  step  = 0;
  ngOnInit() {
    this.getRegions()
  }
  next() {
    this.step ++;
    window.scrollTo(0,0)
    if(this.step === 6){
      this.loadStripe()
    }
  }
  previous() {
    this.step --;
    if(this.step < 0)
      this.step = 0;
  }
  loadStripe(){
    this.http.post('/create-payment-intent-anonymous', {
      data: {
        email: this.data.email,
        phone: this.data.phone,
        firstName: this.data.firstName,
        lastName: this.data.lastName,
      },
      type: 'founder'
    }).subscribe({
      next: (response: any) => {
        const {clientSecret, dataStripe, status, message, currency} = response
        if (status != 'success') {
          alert(message)
          return;
        }
        this.currency = currency;
        const appearance = {
          theme: 'stripe',
        };
        this.clientSecret = clientSecret;
        const elements = this.stripe.elements({locale: 'en', appearance, clientSecret});
        this.elements = elements;
        const paymentElementOptions = {
          layout: "tabs",
        };
        const paymentElement = elements.create("payment", paymentElementOptions);
        paymentElement.mount("#payment-element");
      },
      error: (err) => {
        alert("Error")
      }
    });
  }
  checkoutWithOutPayment() {
    sessionStorage.setItem('data', JSON.stringify(this.data));
    this.store({promocode: this.promotionCode});
  }
  store(paymentIntent: any) {
    console.log(paymentIntent)
    // let url = this.router.url.split('?')[0]
    // const customConfig = {
    //   title: url === '/claim-gift' ? 'Gift claim' : 'Gift sent',
    //   description: url === '/claim-gift' ? '' : 'You’ve sent a Gift.',
    //   containerClass: 'logout-dialog',
    //   closeOnPress: false,
    //   activeButtonCancel: false
    // };
    this.data = JSON.parse(sessionStorage.getItem('data') || "");
    let data = {
      paymentIntent,
      content: this.data,
      email: this.data.email,
      phone: this.data.phone,
      firstName: this.data.firstName,
      lastName: this.data.lastName,
    };
    // /*
    // this.alertService.setCustomConfig(customConfig);
    // this.alertService.showCustomDialog();
    // this.alertService.getIsOKPressed().subscribe(isOK => {
    //   if (isOK === true) {
    //     this.alertService.closeDialog()
    //     this.router.navigateByUrl('/gift-center')
    //   }
    // });
    // */
    this.http.post('/founders/add', data)
      .pipe(
        catchError((error) => {
          console.error('Error en la solicitud HTTP:', error);
          return throwError(error);
        })
      )
      .subscribe({
        next: (response: any) => {
          sessionStorage.removeItem('data')
          if(response.byPromocode){
            if(response.success){
              this.paymentStatus = 'success';
            } else {
              this.paymentStatus = 'error';
              this.msgPayment = response.error;
            }
          }
        }
      });
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.checkStatus()
    }, 100)
  }
  msgPayment = '';
  paymentStatus = '';
  async checkStatus() {
    try {
      const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );

      if (!clientSecret) {
        return;
      }

      this.stripe.retrievePaymentIntent(clientSecret).then((r: any) => {

        const {paymentIntent} = r;

          this.paymentStatus = paymentIntent.status;
        switch (paymentIntent.status) {
          case "succeeded":
            this.store(paymentIntent.id);
            break;
          case "processing":
            this.msgPayment ="Your payment is processing.";
            break;
          case "requires_payment_method":
            this.msgPayment ="Your payment was not successful, please try again.";
            break;
          default:
            this.msgPayment ="Something went wrong.";
            break;
        }
      }).catch((e: any) => console.log(e))
    } catch (e) {
      console.error(e)
    }
  }
  async checkout(isPay = true) {
    if (!isPay) {
      this.store(null)
      return;
    }
    sessionStorage.setItem('data', JSON.stringify(this.data));
    const urlRaiz = window.location.origin;
    const {error} = await this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: urlRaiz + this.router.url,
      },
    });


    if (error.type === "card_error" || error.type === "validation_error") {
      this.paymentmessage = (error.message);
    } else {
      this.paymentmessage = ("An unexpected error occurred.");
    }
  }
  async getRegions() {
    let response: any = await this.http.get(`/common/regions/01HC9HM6KDH102TNNKKHJH4FE4`).toPromise();
    console.error(response)
    this.regions = response.map((r: any) => {
      return {label: r.nameRegion, value: r.nameRegion}
    })
    console.warn(this.regions)
  }

  decrementValue(key: string) {
    this.data[key]--;
    if(this.data[key] < 1)
      this.data[key] = 1;
  }

  incrementValue(key: string) {
    this.data[key]++;
    if(this.data[key] > 3)
      this.data[key] = 3;
  }

  set1_10(value: number) {
    this.data['On a scale from 1 to 10, with 10 being the best, how would you rate your last harvest?'] = value;
  }
  getClass1_10(value: number) {
    if(value === this.data['On a scale from 1 to 10, with 10 being the best, how would you rate your last harvest?']){
      return {'bg-mantis-100': 1, 'border-mantis-400': 1};
    }
    return {};
  }
  validStep() {
    switch (this.step){
      case 1:
        return !this.data['Are you 18 years of age?'] ||
        !this.data['Are you in Canada?'] ||
          !this.data['What province are you in?'] ||
          !this.data.email || !this.data.firstName || !this.data.lastName || !this.data.phone;
      case 2:
        break;
      case 3:
        break;
      case 4:
        break;
      case 5:
        break;
    }
    return false;
  }
  payment = true;
  promoCodeStatus = '';
  validPromoCode() {
    if(!this.promotionCode){
      this.promoCodeStatus = 'error';
      return;
    }
    this.http.post('/valid-promocode', {code: this.promotionCode})
      .pipe(
        catchError((error) => {
          console.error('Error en la solicitud HTTP:', error);
          return throwError(error);
        })
      )
      .subscribe({
        next: (response: any) => {
          if(response.success){
            this.payment = false;
            this.promoCodeStatus = 'accepted';
          } else {
            this.promoCodeStatus = 'error';
          }
        }
      });
  }
  protected readonly JSON = JSON;
}

