import {Component, OnChanges, inject, SimpleChanges, ViewChild, ElementRef, HostListener, OnInit} from '@angular/core';
import {DeviceInfoService} from "../../../services/device-info/device-info.service";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {ToastService} from "../../../services/toast/toast.service";
import {UserInfoService} from "../../../services/user-info/user-info.service";

@Component({
  selector: 'app-home-communities',
  templateUrl: './home-communities.component.html',
  styleUrls: ['./home-communities.component.scss']
})
export class HomeCommunitiesComponent implements OnInit {
  @ViewChild('inputField') inputField!: ElementRef;
  private deviceInfo = inject(DeviceInfoService);
  router = inject(Router);
  http = inject(HttpClient);
  toastService = inject(ToastService);
  typingTimer: any;
  statusFetch = false;
  search = '';
  type = '';
  showFilters = false;
  communities: any = [];
  categories: { label: string, value: string }[] = [
    {label: 'All', value: ''},
    {label: 'Networking', value: 'Networking'},
    {label: 'Education', value: 'Education'},
    {label: 'Partnerships', value: 'Partnerships'},
    {label: 'Events', value: 'Events'},
    {label: 'News', value: 'News'},
  ]

  constructor(private route: ActivatedRoute, private userInfo: UserInfoService) {
  }

  async ngOnInit() {
    this.communities = await this.getData({type: 'search', value: this.type, offset: 0, limit: 2});
  }

  async getData(filters: any = {}) {
    try {
      const response: any = await this.http.get('/communities', {
        params: filters
      }).toPromise();
      if (response.data) {
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  }

  async changeType(type: string, useModel:boolean=false) {
    if(!useModel) this.type = this.type == type ? '' : type;
    this.communities = [];
    this.communities = await this.getData({
      type: this.type === 'communityCategory' ? 'communityCategory' : 'search',
      value: this.type,
      offset: 0,
      limit: 8,
      search: this.search
    });
  }

  async onInputChange(event: any) {
    this.communities = await this.getData({
      type: this.type === 'communityCategory' ? 'communityCategory' : 'search',
      value: this.type,
      offset: 0,
      limit: 8,
      search: this.search
    });
  }

  @HostListener('window:scroll', ['$event'])
  async endScroll() {
    try {
      if (this.statusFetch) return;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
      if (scrollTop + clientHeight >= scrollHeight - 100) {
        this.statusFetch = true;
        const data = await this.getData({
          type: this.type === 'communityCategory' ? 'communityCategory' : 'search',
          value: this.type,
          offset: this.communities.length,
          limit: 2,
          search: this.search
        });
        this.communities = [...this.communities, ...data];
        this.statusFetch = false;
      }
    } catch (e) {
      this.statusFetch = false;
      console.log('error get data')
    }

  }
}
