import {Component, inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {UserInfoService} from "../../services/user-info/user-info.service";
import {HttpClient} from "@angular/common/http";
import {FollowAndFriendService} from "../../services/follow-and-friend/follow-and-friend.service";
import {PostsComponent} from "./parts/posts/posts.component";
import {DomSanitizer} from "@angular/platform-browser";
import {Subscription} from "rxjs";
import {OmAlertService} from "../../om-alert.service";
import {ChatService} from "../../services/chat/chat.service";
import {Location} from "@angular/common";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  @ViewChild(PostsComponent, {static: false}) postsComponent: PostsComponent | undefined;
  router = inject(Router);
  private alertService = inject(OmAlertService);
  private http = inject(HttpClient);
  private sanitizer = inject(DomSanitizer)
  private followAndFriends = inject(FollowAndFriendService);
  chatService = inject(ChatService)
  isFollowing: boolean = false;
  isMyFriend: boolean = false;
  friendRequestReceived: any = {};
  friendRequestSend: any = {};
  followersCount = '';
  followingsCount = '';
  tab: string = 'posts'
  typeAccounts: string = ''
  user: { [key: string]: string | number | any } = {image_profile: '', banner: '', name: '', lastname: '', is_founder:0}

  constructor(private route: ActivatedRoute, private userInfo: UserInfoService, private location: Location) {
  }

  id: string = '';
  type: string = 'posts';
  userSubscription: Subscription | undefined;
  alertSubscription: Subscription | undefined;
  loading = false

  async ngOnInit() {

    window.scrollTo(0, 1);
    // 'bank' is the name of the route parameter
    this.userSubscription = this.route.params.subscribe(
      async (params: Params) => {
        this.loading = true;
        this.tab = 'posts';
        this.id = params['username'];
        if (this.id === undefined)
          this.router.navigateByUrl('/profile/' + this.userInfo.me().user.id)


        await this.getProfileInfo();
        if (!this.isMe()) {
          await this.validIsFollowing();
        }
        this.loading = false;
      })
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe()
    this.subscriptionProfile?.unsubscribe();
    this.alertSubscription?.unsubscribe();
  }

  setTab(tab: string) {
    this.tab = tab;
    if (tab === 'posts' && this.postsComponent) {
      this.postsComponent.setTypeView(false)
    }
  }

  subscriptionProfile: Subscription | undefined;

  getProfileInfo() {
    return new Promise((resolve, reject) => {
      try {
        this.subscriptionProfile = this.http.get(`/profile/${this.id}`).subscribe({
          next: (response: any) => {
            this.user = response?.user || {};
            this.friendRequestSend = response.friendRequestSend;
            this.friendRequestReceived = response.friendRequestReceived;
            this.followersCount = this.followAndFriends.formatFollowerCount(this.user['countFollowers'])
            this.followingsCount = this.followAndFriends.formatFollowerCount(this.user['countFollowing'])
            // this.user['countPost'] = response?.countPost || 0;
            // this.user['countSharables'] = response?.countSharables || 0;
            // this.user['countCoaching'] = response?.countCoaching || 0;
            // this.user['countAccessories'] = response?.countAccessories || 0;
            // this.user['countFriends'] = response?.countFriends || 0;
            this.isMyFriend = response?.isMyFriend || false;
            resolve(response);
          }
        })
      } catch (e) {
        reject(e)
      }
    })
  }

  getName() {
    if (!this.user["name"]) return this.user['username'];

    return `${this.user["name"]} ${this.user["lastname"]}`
  }

  getAboutMe() {
    return this.sanitizer.bypassSecurityTrustHtml(this.user['about_me']);
  }

  isMe() {
    return this.userInfo.isMe(this.id)
  }

  goBack() {
    this.location.back();
  }

  async validIsFollowing() {
    this.isFollowing = await this.followAndFriends.isFollowing(this.id);
  }

  async follow() {
    await this.followAndFriends.add(this.id);
    await this.followAndFriends.getFollowings(true);
    await this.validIsFollowing();
  }

  async unfollow() {
    await this.followAndFriends.delete(this.id);
    await this.followAndFriends.getFollowings(true);
    await this.validIsFollowing();
  }

  newPost() {
    this.postsComponent?.operNewPost()
  }

  addFriendRequest() {
    const customConfig = {
      title: 'Request sent',
      description: '',
      containerClass: 'logout-dialog',
      closeOnPress: false,
      activeButtonCancel: false
    };
    this.http.post('/friends/request', {idUserFriend: this.id}).subscribe({
      next: (response: any) => {
        const {success, data, info} = response
        this.getProfileInfo();
        if (success) {
          this.alertService.setCustomConfig(customConfig);
          this.alertService.showCustomDialog();

          this.alertSubscription = this.alertService.getIsOKPressed().subscribe(isOK => {
            if (isOK === true) {
              this.alertService.closeDialog();
              this.alertSubscription?.unsubscribe();
            }
          });
          if (info === 'request-exits') this.chatService.sendPush(this.id, {message: this.userInfo.me().user.name + ' sent a friend request'}, 'friendRequest');
        }
        console.log(response)
      },
      error: (err) => {
        alert("Error")
      }
    });

  }

  deleteFriendRequest(message: string) {
    const customConfig = {
      title: message,
      description: '',
      containerClass: 'logout-dialog',
      labelCancel: 'Do not delete',
      closeOnPress: false,
      activeButtonCancel: true
    };
    this.alertService.setCustomConfig(customConfig);
    this.alertService.showCustomDialog();
    this.alertSubscription = this.alertService.getIsOKPressed().subscribe(isOK => {
      if (isOK === true) {
        this.http.post('/friends/delete', {idUserFriend: this.id}).subscribe({
          next: (response: any) => {
            this.getProfileInfo();
            this.alertService.closeDialog();
            this.alertSubscription?.unsubscribe();
          },
          error: (err) => {
            this.alertSubscription?.unsubscribe();
          }
        });
      } else if (isOK === false) {
        this.alertService.closeDialog();
        this.alertSubscription?.unsubscribe();
      }
    });

  }

  updateFriendRequest(status: string) {
    const customConfig = {
      title: status === 'accepted' ? 'Accepted friendship' : 'Rejected friendship',
      description: '',
      containerClass: 'logout-dialog',
      closeOnPress: false,
      activeButtonCancel: false
    };
    this.http.post('/friends/request/update', {idUserFriend: this.id, status}).subscribe({
      next: (response: any) => {
        const {success, data} = response
        this.getProfileInfo();
        if (success) {
          this.alertService.setCustomConfig(customConfig);
          this.alertService.showCustomDialog();
          //this.chatService.sendPush(this.user?.id, {message:this.userInfo.me().user.name+(status==='accepted'?' accepted your friendship request':' rejected your friendship request')}, 'friendRequest');
          this.alertSubscription = this.alertService.getIsOKPressed().subscribe(isOK => {
            if (isOK === true) {
              this.alertService.closeDialog();
              this.alertSubscription?.unsubscribe();
            }
          });


        }
      },
      error: (err) => {
        alert("Error")
      }
    });

  }
  async openChat() {
    let user = await this.userInfo.getById(this.id)
    this.chatService.changeUser(user)
    this.router.navigateByUrl('/messages')
  }
}
