import {Component, inject, OnInit} from '@angular/core';
import {OmAlertService} from "../../../om-alert.service";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {catchError, throwError} from "rxjs";
import {Location} from "@angular/common";
import {DeviceInfoService} from "../../../services/device-info/device-info.service";
import {ChatService} from "../../../services/chat/chat.service";
import {UserInfoService} from "../../../services/user-info/user-info.service";

@Component({
  selector: 'app-gift-send-home',
  templateUrl: './gift-send-home.component.html',
  styleUrls: ['./gift-send-home.component.scss']
})
export class GiftSendHomeComponent implements OnInit {
  private alertService = inject(OmAlertService);
  private userInfo = inject(UserInfoService);
  deviceInfo = inject(DeviceInfoService)
  chatService = inject(ChatService)
  private http = inject(HttpClient);
  router = inject(Router);
  tapActive = 'list-gifts';
  data = {
    gifts: [] as IProductCart[],
    user: {
      id: '',
      username: '',
      name: '',
      email: '',
      phoneNumber: '',
      avatar: '',
    },
    userSend: {
      id: '',
      username: '',
      name: '',
      avatar: '',
    },
    addres: '',
    address: {
      id: '',
      address: '',
      lat: '',
      lng: '',
      isVerified: true,
    },
    message: '',
    subtotal: 0,
    total: 0,
    delivery: 0
  }
  shipping = 7.50;
  movilPay = false;
  deskPay = false;

  ngOnInit() {
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      localStorage.setItem('sendGift', JSON.stringify({}));
    }
    if (Object.entries(JSON.parse(localStorage.getItem('sendGift') || '{}')).length != 0) {
      this.data = JSON.parse(localStorage.getItem('sendGift') || '{}')
      this.deskPay = true;
      this.tapActive = 'sumary';
    }
    if (Object.entries(JSON.parse(localStorage.getItem('sendGift') || '{}')).length === 0 && clientSecret) {
      window.location.href = '/gift-send'
    }
  }

  setGifts(data: IProductCart[]) {
    if (data.length == 0) return;
    this.data.gifts = data;
    this.tapActive = 'sumary'
  }

  setUser(data: any) {
    this.data.user = data.user;
    this.data.address = data.address;
    this.tapActive = 'sumary'
  }

  getSubTotal() {
    const sum = this.data.gifts.reduce((acumulador, producto) => acumulador + producto.total, 0);
    return sum;
  }

  getTotal() {
    const sum = this.data.gifts.reduce((acumulador, producto) => acumulador + producto.total, 0);
    return sum + this.shipping;
  }

  nextPay() {
    if (this.data.message == '') return;
    this.movilPay = true;
  }

  isError = false;

  nextPay2() {
    this.isError = true;
    if (this.data.message == '' || this.data.user.id == '') return;
    localStorage.setItem('sendGift', JSON.stringify(this.data));
    this.deskPay = true;
  }

  checkout() {
    const customConfig = {
      title: this.router.url === '/claim-gift' ? 'Gift claim' : 'Gift sent',
      description: this.router.url === '/claim-gift' ? '' : 'You’ve sent a Gift.',
      containerClass: 'logout-dialog',
      closeOnPress: false,
      activeButtonCancel: false
    };
    let data = {
      paymentIntent: null,
      isClaim: this.router.url === '/claim-gift',
      pk: this.data.gifts[0].pk.replace(/\bup#\b/g, ''),
      iduser_to: this.data.user.id,
      address_obj: this.data.address,
      address: `${this.data.address.address}`,
      type: customConfig.title === 'Gift sent' ? 'giftSended' : 'giftClaimed',
      pay_method: 'paypal',
      shipping_cost: this.shipping,
      total: this.getTotal(),
      idproducts: this.data.gifts.map(objeto => objeto.id),
      personal_detail: {
        fullname: this.data.user.name,
        email: this.data.user.email,
        phone: this.data.user.phoneNumber,
        card_name: '--',
        card_number: '--',
        expire: '--',
        cvc: '--'
      },
      message: this.data.message

    };
    /*
    this.alertService.setCustomConfig(customConfig);
    this.alertService.showCustomDialog();
    this.alertService.getIsOKPressed().subscribe(isOK => {
      if (isOK === true) {
        this.alertService.closeDialog()
        this.router.navigateByUrl('/gift-center')
      }
    });
    */
    this.http.post('/merch/add-merch', data)
      .pipe(
        catchError((error) => {
          console.error('Error en la solicitud HTTP:', error);
          return throwError(error);
        })
      )
      .subscribe({
        next: (response: any) => {
          if (response.success) {
            console.log(response)

            if (customConfig.title === 'Gift sent') {
              console.log("Enrto aqui")
              this.chatService.sendPush(
                response.merch.merchIdUserRecived,
                {
                  meta: {
                    id: response.merch.merchId
                  }
                },
                'gift');
            }
            this.alertService.setCustomConfig(customConfig);
            this.alertService.showCustomDialog();
            this.alertService.getIsOKPressed().subscribe(isOK => {
              if (isOK === true) {
                this.alertService.closeDialog()
                this.router.navigateByUrl('/gift-center')
              }
            });
          }

        }
      });


  }

  regresar() {
    if (this.tapActive == 'sumary' && this.data.user.id == '') this.tapActive = 'list-gifts';
    if (this.tapActive == 'sumary' && this.data.user.id != '' && this.data.message != '' && this.deskPay == true) this.deskPay = false;
    else if (this.tapActive == 'sumary' && this.data.user.id != '') this.data.user.id = '';
  }

  regresar2() {
    if (this.tapActive == 'list-gifts') this.router.navigateByUrl('/home')
    if (this.tapActive == 'sumary' && this.data.user.id == '') this.tapActive = 'list-gifts';
    else if (this.tapActive == 'sumary' && this.data.user.id != '' && this.movilPay == false) this.data.user.id = '';
    else if (this.tapActive == 'sumary' && this.data.user.id != '' && this.data.message != '') this.movilPay = false;
  }

  protected readonly Event = Event;
}
