import {AfterViewInit, Component, ElementRef, HostListener, inject, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {GiftService} from "../../../services/gift/gift.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CatalogsService} from "../../../services/catalogs/catalogs.service";
import {ProductDialogComponent} from "../../general/product-dialog/product-dialog.component";

@Component({
  selector: 'app-gifts-search',
  templateUrl: './gifts-search.component.html',
  styleUrls: ['./gifts-search.component.scss']
})
export class GiftsSearchComponent implements OnInit, AfterViewInit {
  router = inject(Router);
  catalogsService = inject(CatalogsService);
  @ViewChild('filterList') filterList: ElementRef | undefined;
  @ViewChild('containerFilterList') containerFilterList: ElementRef | undefined;
  @ViewChild(ProductDialogComponent, {static: false}) productDialogComponent: ProductDialogComponent | undefined;
  private http = inject(HttpClient);
  giftService = inject(GiftService);
  showFilters = false
  sharables: any = [];
  statusFetch = false;
  limit = 50;
  offset = 0;
  userId = ''
  search = ''
  selected = 'All'
  filterTab = 'sort'
  filterType = 'Relevance'
  giftId = ''
  filters: { [key: string]: string } = {
    sort: 'relevance',
    category: 'all',
    type: 'all',
  }
  filtersLeft: Partial<IFiltersGifts> = {};
  categories: { label: string, value: string }[] = [{label: 'All Category', value: 'all'},]

  ngOnInit() {
    window.scrollTo(0, 1);
    this.getCategories()
    this.getSharables()
  }

  getCategories() {
    this.catalogsService.getAll('categories', '').subscribe({
      next: (response: any) => {
        this.categories = [...this.categories, ...response.records.map((x: any) => {
          return {
            value: x.name.toLowerCase(),
            label: x.name,
          };
        })]
      }
    })
  }

  setFilters(filters: Partial<IFiltersGifts>) {
    this.filtersLeft = {};
    this.filtersLeft = Object.fromEntries(
      Object.entries(filters).filter(
        ([key, val]) => (typeof val === 'number' && val > -1) || (typeof val === 'string' && val !== '') || (Array.isArray(val) && val.length > 0)
      )
    );
    this.setFiltersUrl();
    this.getSharables(true);
  }

  setFiltersUrl() {
    const filtersUrl: any = {...this.filtersLeft}
    const preparedParams: any = {};
    Object.entries(filtersUrl).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        preparedParams[key] = JSON.stringify(value);
      } else {
        preparedParams[key] = String(value);
      }
    });
    preparedParams['giftId'] = this.giftId;
    this.router.navigate(['/gifts-search'], {
      queryParams: preparedParams,
    })
  }

  getSharables(isReset = false) {
    if (isReset) {
      this.limit = 50;
      this.offset = 0;
      this.sharables = [];
    }

    this.statusFetch = true;
    let newFilters: { [key: string]: any } = {...this.filters, ...this.filtersLeft};
    delete newFilters['limit']
    delete newFilters['offset']
    delete newFilters['selectedLocation']
    delete newFilters['typesGrowers']
    delete newFilters['popular']
    delete newFilters['popular']
    if (newFilters['category'] === 'all')
      delete newFilters['category'];
    if (newFilters['type'] === 'all')
      delete newFilters['type'];
    if (newFilters.hasOwnProperty('categories')) {
      newFilters['category'] = newFilters['categories'].join("|")
      delete newFilters['categories'];
    }
    if (newFilters.hasOwnProperty('effects')) {
      newFilters['effect'] = newFilters['effects'].join("|")
      delete newFilters['effects'];
    }
    if (newFilters.hasOwnProperty('types')) {
      newFilters['type'] = newFilters['types'].join("|")
      delete newFilters['types'];
    }
    if (newFilters.hasOwnProperty('priceInit') && newFilters.hasOwnProperty('priceEnd')) {
      newFilters['publish_cost'] = `[${newFilters['priceInit']} ${newFilters['priceEnd']}]`
      delete newFilters['priceInit'];
      delete newFilters['priceEnd'];
    }
    if (newFilters.hasOwnProperty('thcInit') && newFilters.hasOwnProperty('thcEnd')) {
      newFilters['thc'] = `[${newFilters['thcInit']} ${newFilters['thcEnd']}]`
      delete newFilters['thcInit'];
      delete newFilters['thcEnd'];
    }
    console.log(newFilters)
    this.http.get('/products/general/filters', {
      params: {
        ...newFilters,
        limit: this.limit,
        offset: this.offset,
        sortKey: 'updateDate',
        sort: 'desc',
      }
    }).subscribe({
      next: (response: any) => {
        let records = response?.records || response;
        try {
          if (records.length === this.limit) {
            this.offset += this.limit
          } else {
            this.offset += records.length;
          }
        } catch (e) {
          console.error(e)
        }
        this.sharables = [...this.sharables, ...records];
        this.statusFetch = false;
      }
    });
  }

  goToProfile(id: string) {
    console.log('/profile/' + id)
    this.router.navigateByUrl('/profile/' + id);
  }

  claimgift(data: any) {
    this.giftService.claimGift(data)
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    try {
      if (!(this.filterList as ElementRef).nativeElement.contains(event.target) && this.showFilters) {
        this.showFilters = false;
      }
    } catch (e) {
    }
  }

  @HostListener('window:scroll', ['$event'])
  endScroll(): void {
    if (this.statusFetch) return;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    if (scrollTop + clientHeight >= scrollHeight - 100) {
      this.getSharables()
    }
  }

  setIdGift(id: string) {
    console.log(id)
    this.giftId = id;
    this.setFiltersUrl();
  }

  ngAfterViewInit() {
    if (this.giftId != '') {
      this.productDialogComponent?.openProduct(this.giftId)
    }
  }

  constructor(private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      for (const [key, value] of Object.entries(params)) {
        try {
          this.filtersLeft[key as keyof IFiltersGifts] = JSON.parse(value);
        } catch (e) {
          if (key === 'priceInit' || key === 'priceEnd' || key === 'thcEnd' || key === 'thcInit') {
            // @ts-ignore
            this.filtersLeft[key as keyof IFiltersGifts] = Number(value);
          } else if (key === 'giftId') {
            this.giftId = value.toString();
          }
        }
      }
      console.log(this.filtersLeft)
    });
  }
}
