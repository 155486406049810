import {Component, ElementRef, EventEmitter, HostListener, inject, Output, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {DeviceInfoService} from "../../../services/device-info/device-info.service";

@Component({
  selector: 'app-gift-list',
  templateUrl: './gift-list.component.html',
  styleUrls: ['./gift-list.component.scss']
})
export class GiftListComponent {
  private http = inject(HttpClient);
  deviceInfo = inject(DeviceInfoService)
  @ViewChild('filterList') filterList: ElementRef | undefined;
  @ViewChild('containerFilterList') containerFilterList: ElementRef | undefined;
  @Output() sendData = new EventEmitter<any>();
  showFilters = false
  sharables = [];
  productSelect: IProductCart[]=[];
  search = ''
  filterTab = 'sort'
  filters: { [key: string]: string } = {
    sort: '',
    category: '',
    type: '',
    userAuth:'1'
  }
  sorts: { label: string, value: string }[] = [
    {label: 'None', value: ''},
    {label: 'Top Sales', value: 'top-sales'},
    {label: 'Most Recent', value: 'most-recent'},
    {label: 'Price Low to High', value: 'Price-Low-to-High'},
    {label: 'Price High to Low', value: 'Price-High-to-Low'},
  ]
  category: { label: string, value: string }[] = [
    {label: 'All Category', value: ''},
    {label: 'Flower', value: 'flower'},
    {label: 'CBD', value: 'cdb'},
    {label: 'Edibles', value: 'edibles'},
    {label: 'Extracts', value: 'extracts'},
    {label: 'Clones', value: 'clones'},
    {label: 'Vapes', value: 'vapes'},
    {label: 'Seeds', value: 'seeds'},
  ]
  types: { label: string, value: string }[] = [
    {label: 'All Types', value: ''},
    {label: 'Sativa', value: 'sativa'},
    {label: 'Hybrid', value: 'hybrid'},
    {label: 'Indica', value: 'indica'},
  ]

  ngOnInit() {
    this.getSharables()
  }

  constructor(private elementRef: ElementRef) {
  }
  getSharables() {
    this.sharables = [];
    this.http.get('/products/me/', {
      params: {...this.filters, search: this.search}
    }).subscribe({
      next: (response: any) => {
        this.sharables = response?.data || response
      }
    });
  }

  getCatalogFilter() {
    let catalog: typeof this.category = [];
    switch (this.filterTab) {
      case 'sort':
        catalog = this.sorts;
        break;
      case 'category':
        catalog = this.category;
        break;
      case 'type':
        catalog = this.types;
        break;
    }
    return catalog;
  }

  setSort(key: string) {
    this.filterTab = key;
  }

  setFilterType(key: string) {
    this.filters[this.filterTab] = key;
    this.getSharables();
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!(this.filterList as ElementRef).nativeElement.contains(event.target) && this.showFilters) {
      this.showFilters = false;
    }
  }
  getData(data:IProductCart){
    this.productSelect = this.productSelect.filter((row) => row.id != data.id);
    if(data.quantity>0) this.productSelect.push(data);
  }
  sendDataB(){
    this.sendData.emit(this.productSelect);
  }

  protected readonly dispatchEvent = dispatchEvent;
}
