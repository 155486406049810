<app-clear-layout title="All Products" routeHeader="/strain-finder" [useHeaderEdit]="true">
  <div class="flex sm:bg-[#FAFAFA] w-full min-h-screen">
    <div class="hidden duration-700 ease-out sm:block fixed sm:w-[236px] h-[calc(100vh-(var(--sat)))] top-[calc(var(--sat))] sm:top-[101px] sm:h-[calc(100%-160px)] overflow-auto left-0 sm:px-[30px] sm:mt-[40px]">
      <app-filters-products [filtersDefault]="filtersLeft" (filters)="setFilters($event)"></app-filters-products>
    </div>
    <div class="w-full sm:w-[calc(100%-260px)] sm:ml-[230px] sm:mrdsas-[386px] px-4 sm:px-[20px]">
      <span class="sm:hidden material-symbols-outlined text-[30px] sm:text-[30px] text-[#707070] mx-4 mt-2 cursor-pointer" routerLink="/strain-finder">arrow_back</span>
      <div class="px-4 w-full">
        <div class="flex pt-6 items-center justify-between relative w-full gap-2">
          <div class="w-[180px] hidden sm:block">
            <app-om-select id="select-nav"
                           containerClass="!bg-white !py-[4.5px] sm:!py-[12px] !font-poppins !font-medium shadow-md !text-[16px]"
                           containerItemsClass="!max-h-[150px]"
                           itemsClass="nav-select-item !py-1 hover:!bg-white hover:!text-green4-native"
                           color="gray" [options]="categories" [(ngModel)]="filters['category']" required (ngModelChange)="getSharables(true)"
                           #selectedNav="ngModel" [default]="filters['category']" placeholder="Filter"
            />
          </div>
          <div class="relative w-[calc(100%-20px)] sm:w-[calc(100%-360px)]">
            <app-om-input [useIcon]="true" color="white" [(ngModel)]="search"
                          inputClass="rounded-full placeholder:!text-sm sm:rounded-md !bg-[#fff] !text-[#77838F] sm:!bg-white !py-[5.5px] sm:!py-[12px] shadow-md !font-poppins !font-medium relative placeholder:!text-lg !text-[16px]"
                          placeholder="Search">
              <app-search-icon class="hidden sm:inline-block" color="#77838F" [width]="18" [height]="18"/>
              <app-search-icon class="inline-block sm:hidden" color="#519935" [width]="18" [height]="18"/>
            </app-om-input>
          </div>
          <div class="w-fit sm:w-[160px]">
            <button
              class="p-1.5 w-full sm:px-4 sm:!py-[14px] gap-2 flex items-center font-semibold text-[14px] bg-[#519935] text-white rounded-full sm:rounded-md shadow-sm"
              routerLink="/add-product">
              <ng-icon name="matPlus" class="text-white cursor-pointer text-lg z-10"></ng-icon>
              <span class="hidden sm:flex">New Product</span>
            </button>
          </div>
        </div>
        <app-not-found message="No products found with applied filters :(" *ngIf="!sharables?.length"/>
        <div class="sm:grid grid-cols-3 gap-4 mt-[20px] sm:mt-[30px]">
          <ng-container *ngFor="let item of sharables; let i = index">
            <app-card-mini class="block mb-4 sm:mb-[10px]" (selectGift)="setIdGift($event)" [isStrainFinder]="true" [useTemplateFooter]="true" [mine]="true" [data]="item" [btndetailVisable]="false">
              <footer class="block w-full px-[10px] sm:px-[14px]">
                <div class="flex justify-between holis">
                  <div class="flex gap-4 container-avatar">
                    <div class="relative">
                      <img [src]="item.user?.image_profile || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'" (touchend)="goToProfile(item.user.id)" class="cursor-pointer w-[35px] h-[35px] sm:w-[50px] sm:h-[50px] rounded-full object-cover " alt="   ">
                      <div *ngIf="item.user.is_founder===1" class="absolute bottom-0 right-0 w-[20px] h-[20px] sm:w-[45%] sm:h-[45%] p-[2px] rounded-[15px] sm:rounded-full bg-white">
                        <img src="assets/ogfc.png" alt="" class="object-cover w-full">
                      </div>
                    </div>
                    <div class="flex flex-col justify-center">
                      <p class="text-[14px] text-[#FFFFFF] font-bold truncate max-w-[90px]">{{ item.user.name||item.user.username }}</p>
                      <p class="text-[12px] text-[#FFFFFF]">{{item.user.region}} Grower</p>
                    </div>
                  </div>
                  <div class="flex items-center gap-2">
                    <button class="text-[15px] font-semibold sm:text-[13px] text-[#FFFFFF] flex justify-center items-center w-fit px-6 h-[35px] rounded-full bg-[#81CB52]" (click)="claimgift(item)">{{item.publish_cost ? 'Add to Cart' : 'Claim'}}</button>
                  </div>
                </div>
              </footer>
            </app-card-mini>
            <app-custom-carousel class="inline-table col-span-3 my-[10px] sm:my-[20px]" *ngIf="i==8"></app-custom-carousel>
            <app-custom-carousel class="inline-table col-span-3 mt-[10px] sm:my-[20px]" *ngIf="i==17"></app-custom-carousel>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <app-product-dialog (selectGift)="setIdGift($event)"></app-product-dialog>

</app-clear-layout>
