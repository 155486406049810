import {Component, inject, Input, OnDestroy, OnInit} from '@angular/core';
import {UserInfoService} from "../../../../services/user-info/user-info.service";
import {Subscription} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {DateTime} from "luxon";
import {Router} from "@angular/router";

@Component({
  selector: 'app-home-received-gift',
  templateUrl: './home-received-gift.component.html',
  styleUrls: ['./home-received-gift.component.scss']
})
export class HomeReceivedGiftComponent implements OnInit, OnDestroy {
  subscriptionGift: Subscription | undefined;
  private userInfoService = inject(UserInfoService);
  private router = inject(Router);
  private http = inject(HttpClient);
  merch: any = {}
  productData: any = {}
  @Input() isOpen = false;
  isOpenReject = false;
  isOpenWait = false;
  intervalDiff: any = null;
  name = ""
  messageReject = "Thanks, but I’m not looking for a new experience right now."
  lastsStatus =  ""
  ngOnInit() {
    this.subscriptionGift = this.userInfoService.currentGift.subscribe(async data => {
      if (data?.merchId)
        this.http.get('/shipping/', {
          params: {merchId: data?.merchId}
        }).subscribe({
          next: (resp: any) => {
            if (resp?.[0]) {
              console.log(resp?.[0]);
              this.merch = resp[0]
              console.log(this.merch.products[0].productData)
              this.productData = JSON.parse(this.merch.products[0].productData);
              this.lastsStatus = this.merch.timeline[0].merchStatus || "";
              this.name = this.userInfoService.getNameByUser(this.merch.userSended);
              this.getTimer();
              if(data?.isRejected){
               this.isOpenReject = true;
                return;
              }
              this.isOpen = true;
            }
          }
        })
    });
  }

  ngOnDestroy() {
    if (this.intervalDiff)
      clearInterval(this.intervalDiff)
  }

  diff: string | null = ""

  getTimer() {
    this.intervalDiff = setInterval(() => {
      let createDate = DateTime.fromMillis(this.merch.createDate).plus({days: 3});
      let today = DateTime.local();
      this.diff = createDate.diff(today, "hours").toFormat('hh:mm:ss');
    }, 1000); // Update every second
  }

  accept() {
    this.router.navigateByUrl('/gift-center?id=' + this.merch.merchId);
  }

  closeModal() {
    this.isOpen = false;
    this.isOpenReject = false;
    this.isOpenWait = false;
    if (this.intervalDiff)
      clearInterval(this.intervalDiff)

    this.merch = null;
    this.userInfoService.changeGift(null);
  }
  rejectModal() {
    this.isOpen = false;
    this.isOpenReject = true;
  }
  waitModal() {
    this.isOpen = false;
    this.isOpenWait = true;
  }
  gotoGifts() {
    this.closeModal();
    this.router.navigateByUrl('/gift-center');
  }
  subsUpdateStatus: Subscription | undefined;
  updateStatus() {
    const body: any = {
      iduser_to: this.merch.merchIdUserRecived,
      idmerch: this.merch.merchId,
      iduser_send: this.merch.merchIdUserSended,
      status: "rejected",
      message: this.messageReject,
      type: this.merch.merchType
    };
    this.subsUpdateStatus = this.http.post('/merch/rejected', body).subscribe({
      next: (r) => {
        this.closeModal();
        this.router.navigateByUrl('/gift-center');
        this.subsUpdateStatus?.unsubscribe();
      },
      error: (e) => {
        console.log(e)
        this.subsUpdateStatus?.unsubscribe();
      }
    });
  }
}
