import {Component, inject, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {UserInfoService} from "../../../../services/user-info/user-info.service";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {
  router = inject(Router);
  http = inject(HttpClient);
  userInfo = inject(UserInfoService);
  user: any = {};
  country = '';
  ngOnInit() {
    this.user = this.userInfo.me()?.user;
  }
}
