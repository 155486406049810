import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-trash-icon',
  templateUrl: './trash-icon.component.svg',
  styleUrls: ['./trash-icon.component.scss']
})
export class TrashIconComponent {
  @Input() color = '#77838f';
  @Input() hover = '#77838f';
  @Input() width = 24;
  @Input() height = 24;
  isHovering = false;
}
