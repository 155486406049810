import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {UserInfoService} from "../../../../services/user-info/user-info.service";
import {ActivatedRoute, Router} from "@angular/router";
import {RegisterPublicationComponent} from "../../../publications/register-publication/register-publication.component";
import {DeviceInfoService} from "../../../../services/device-info/device-info.service";
import {CommentComponent} from "../../../publications/comment/comment.component";
import {PublicationComponent} from "../../../publications/publication/publication.component";
import {ChatService} from "../../../../services/chat/chat.service";

@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.scss']
})
export class PostsComponent implements OnInit {
  @ViewChild('onMedia', {static: false}) mediaPublication: PublicationComponent | undefined;
  @ViewChild(RegisterPublicationComponent, {static: false}) registerPublicationComponent: RegisterPublicationComponent | undefined;
  @Output() addFriendRequest = new EventEmitter<any>();
  @Output() deleteFriendRequest = new EventEmitter<string>();
  @Output() updateFriendRequestEmit = new EventEmitter<string>();
  private http = inject(HttpClient);
  private route = inject(ActivatedRoute);
  private userInfo = inject(UserInfoService);
  private router = inject(Router)
  chatService = inject(ChatService)
  deviceInfoService = inject(DeviceInfoService);
  @Input() userProfile: any = {}
  type = 'posts'
  @Output() typeChange = new EventEmitter<string>();
  posts: any[] = [];
  postsMedia: any[] = [];
  postUrls: string[] = [];
  id: string = '';
  selected = 'All'
  statusFetch = false;
  statusFetchChangeType = false;
  user: any = {};
  @Input() userId = ''
  @Input() isMyFriend: boolean = false;
  @Input() friendRequestReceived: any = {};
  @Input() friendRequestSend: any = {};
  options: { label: string, value: string }[] = [
    {label: 'All', value: 'All'},
    {label: 'Articles', value: 'Articles'},
    {label: 'Posts', value: 'Posts'},
    {label: 'Photos', value: 'Photos'},
    {label: 'Videos', value: 'Videos'},
  ]
  limit = 10;
  offset = 0;
  limitMedia = 10;
  offsetMedia = 0;

  deletePost(postId: string) {
    this.posts = this.posts.filter(post => post.postId !== postId);
  }

  getData() {
    this.statusFetch = true;
    const userId = this.userId;
    let lastPostKey = '';
    this.http.get('/posts-filter', {
      params: {
        type: 'profile',
        limit: this.limit,
        offset: this.posts.length,
        userId,
        lastPostKey,
      }
    }).subscribe({
      next: (response: any) => {
        try {
          if (response.posts.length === this.limit) {
            this.offset += this.limit
          } else {
            this.offset += response.posts.length;
          }
        } catch (e) {
          console.error(e)
        }
        this.posts = [...this.posts, ...response.posts];
        this.statusFetch = false;
      }
    });

  }

  getMedia() {
    return new Promise((resolve, reject) => {
      try {
        const userId = this.userId;
        this.http.get('/posts-filter', {
          params: {
            type: 'profileMedia',
            limit: this.limitMedia,
            offset: this.offsetMedia,
            userId,
          }
        }).subscribe({
          next: (response: any) => {
            try {
              if (response.posts.length === this.limitMedia) {
                this.offsetMedia += this.limitMedia
              } else {
                this.offsetMedia += response.posts.length;
              }
            } catch (e) {
              console.error(e)
            }
            this.postsMedia = [...this.postsMedia, ...response.posts];
            let lastMedia = this.postsMedia.slice(0, 10)
            this.postUrls = []
            lastMedia.forEach(x => {
              this.postUrls.push(x.postUrls[0])
            })
            resolve(true)
          }
        });
      } catch (e) {
        reject(false)
      }
    })

  }

  addPost(post: any) {
    this.statusFetch = true;
    post['avatar'] = this.user.image_profile;
    post['name'] = this.user.name + ' ' + this.user.lastname;
    this.posts.unshift(post);

    setTimeout(() => {
      this.statusFetch = false;
    }, 2000);
  }

  ngOnInit() {
    this.id = this.route.snapshot.params['username'];
    this.user = this.userInfo.me()?.user || {image_profile: "https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png"};
    this.getData();
    this.getMedia();
  }

  onScroll(event: Event): void {
    this.endScroll();
  }

  @HostListener('window:scroll', ['$event'])
  endScroll(): void {
    if (this.statusFetch || this.statusFetchChangeType) return;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    if (scrollTop + clientHeight >= scrollHeight - 100) {
      this.type === 'posts' ? this.getData() : this.getMedia();
    }
  }

  isMe() {
    return this.userInfo.isMe(this.id)
  }

  operNewPost() {
    this.registerPublicationComponent?.openForm()
  }

  async setTypeView(evt: any) {
    this.type = evt ? 'media' : 'posts'
    this.typeChange.emit(this.type)
    this.statusFetchChangeType = true
    this.limitMedia = 10;
    this.offsetMedia = 0;
    this.postsMedia = [];
    await this.getMedia();
    this.statusFetchChangeType = false;
  }

  showCommentDesk(item: any) {
    this.mediaPublication?.openInMedia({
      pk: item.pk,
      postId: item.postId,
      postType: item.postType,
      avatar: item.avatar,
      name: item.name,
      date: item.createDate,
      description: item.postContent,
      medios: item.postUrls,
      lastLikes: item.lastLikes || [],
      verifiedAccount: true,
      like: item.like,
      postLikesCount: item.postLikesCount,
      postCommentsCount: item.postCommentsCount,
      postLocation: item.postLocation || null,
      postHashtack: item.postHashtack,
      postFiltersByImage: item.postFiltersByImage,
      save: item['save']

    });
  }

  async openChat() {
    let user = await this.userInfo.getById(this.id)
    this.chatService.changeUser(user)
    this.router.navigateByUrl('/messages')
  }

  clickAddFriendRequest() {
    this.addFriendRequest.emit();
  }

  clickDeleteFriendRequest(message:string) {
    this.deleteFriendRequest.emit(message);
  }
  updateFriendRequest(status:string){
    this.updateFriendRequestEmit.emit(status)
  }
}

