<div *ngIf="infoPost?.comments?.length<=limitCommentsV && !isNotification" class="w-full">
  <div class="px-4">
    <app-comments-list [comments]="infoPost.comments"></app-comments-list>
  </div>

</div>
<app-comment-form *ngIf="!isNotification" class="hidden sm:block" (click)="openForm()"
                  (sendComment)="saveComment($event)"></app-comment-form>
<app-dialog #dialogComment
            title="<span class='hidden sm:block'>Post by {{infoPost.name}}</span><span class='sm:hidden'>Comments</span>"
            *ngIf="!isNotification"
            (close)="closeComentDialog()"
            (endContent)="endDialog()"
            [classHeaderDefault]="'justify-center pt-5 pb-3 text-lg'"
            [classCloseButton]="'text-3xl'"
            [classModal]="'pt-[var(--sat)] sm:pt-0 !min-h-[100%] sm:!min-h-max sm:!min-w-[850px] sm:!w-[850px] sm:!max-h-[90%] sm:relative'"
            [classBody]="'max-h-[calc(100vh-100px-var(--sat))] sm:max-h-[calc(100vh-155px)]'"

>
  <div class="">
    <hr class="hidden sm:block mb-2 mx-4 border border-[#EEEEEE]">
    <div class="hidden sm:flex gap-4 container-avatar px-4 mt-2">
      <img [src]="infoPost.avatar" class="avatar" alt="">
      <div class="flex flex-col justify-center avatar-info">
        <p class="username sm:text-[18px] sm:font-medium sm:leading-[1]">{{ infoPost.name }}
          <span *ngIf="infoPost.verifiedAccount" class="material-symbols-outlined verifiedAccount">
          Done
          </span>
        </p>
        <p
          class="font-Poppins font-light text-[10px] sm:text-[14px] sm:leading-[1] mt-[5px]">{{ calculateDateDifference(infoPost.date) }}</p>
      </div>
    </div>
    <p
      class="font-Poppins text-[#000000] mx-4 text-[14px] sm:text-[18px] hidden sm:block font-semibold my-2">{{ infoPost.title }}</p>
    <p class="hidden sm:block font-Poppins text-[#77838F] mx-4 text-[14px] sm:text-[14px] my-2"
       [innerHtml]="convertHashtagsToTags()"></p>
    <app-slide-post class="hidden sm:block" [filters]="infoPost.postFiltersByImage" [files]="infoPost.medios"
                    *ngIf="infoPost.medios.length>0" [isView]="true" [isProfile]="infoPost"
                    [useSlide]="'post'"></app-slide-post>
    <div class="p-4 hidden sm:block">
      <app-reactions-bar
        (clickIconLike)="clickIconLike.emit()"
        (clickIconSave)="clickIconSave.emit()"
        (clickIconShare)="clickIconShare.emit()"
        id="comment-reactions"
        class="block py-3 sm:py-0 border-t border-b border-[#EEEEEE]"
        [like]="like"
        [save]="save"
        [infoPost]="infoPost"
        [seeLabelsReactions]="true"
        [numberReactions]="{
      numberLikes:infoPost.postLikesCount,
      numberComments:infoPost.postCommentsCount,
      numberShares:infoPost.postShareCount,
      numberSaved:infoPost.postSavedCount,
    }"></app-reactions-bar>
    </div>
    <div class="px-2 sm:px-4 min-h-[calc(100vh-100px-var(--sat))] sm:min-h-max"
    [class]="{
    'hidden':isFocusedInput
    }">
      <p *ngIf="infoPost.postCommentsCount>infoPost.comments.length" class="text-center">
        <span class="bg-[#c5c5c570] cursor-pointer px-2 py-1 rounded-2xl" (click)="getMoreComments()">
          More comments
        </span>
      </p>
      <app-comments-list [comments]="infoPost.comments"></app-comments-list>
    </div>
  </div>
  <div class="fixed sm:sticky bottom-1 left-0 z-30 w-full bg-white"
       [class]="{
    '!sticky':isFocusedInput,
    }"
  >
    <hr class="my-2 mx-4 border border-[#EEEEEE]">
    <div class="flex justify-center sm:justify-normal sm:mx-14" *ngIf="visibleEmojis">
      <emoji-mart [darkMode]="false" [showPreview]="false" class="sm:absolute z-[50] sm:right-0 sm:bottom-0"
                  (emojiClick)="addEmoji($event)" [excludeElementIds]="['btn-emojis','text-post1']"
                  (appClickOutside)="onClickOutside(1)"></emoji-mart>
    </div>
    <div class="flex justify-center sm:justify-normal sm:mx-14" *ngIf="visibleGif">
      <app-gif-search class="sm:absolute z-[50] sm:right-0 sm:bottom-1 sm:w-[75%]" (sendGif)="getGif($event)"
                      [excludeElementIds]="['btn-gif']" (appClickOutside)="onClickOutsideGif(1)"></app-gif-search>
    </div>
    <div class="flex items-center gap-2 mx-4 mt-2">
      <div *ngFor="let item of files; let i = index" class="relative">
        <span class="material-symbols-outlined absolute top-1 right-1 text-[1rem] cursor-pointer rounded-full bg-white"
              (click)="removeImage(i)">close</span>
        <img *ngIf="item.type === 'image'" [src]="item.src" class="h-[90px] object-cover rounded-2xl" alt="img">
        <video *ngIf="item.type === 'video'" class="h-[90px] object-cover rounded-2xl" controls>
          <source [src]="item.src" type="video/mp4">
          Tu navegador no soporta el tag de video.
        </video>
      </div>
    </div>
    <app-comment-form (sendComment)="saveComment($event)"
                      [isFocusedInput]="isFocusedInput"
                      (focusedInput)="updateFocusedInput($event)"></app-comment-form>
  </div>

</app-dialog>

<app-dialog #dialogCommentDesk title="" [useTemplateHeader]="true"
            [classModal]="(infoPost.medios.length>0?'sm:!min-w-full sm:min-h-full':'sm:!min-w-[400px] sm:min-h-[70vh]')+' bg-black/80 sm:bg-white text-black sm:relative pt-2 sm:pt-0' "
            [classBody]="infoPost.medios.length>0?'h-[100vh] !max-h-[100vh]':'h-[100vh] !max-h-[100vh] sm:h-[70vh] sm:!max-h-[70vh]'">
  <span class="material-symbols-outlined absolute top-6 right-6 text-3xl text-[#707070] cursor-pointer"
        (click)="dialogCommentDesk?.closeB()">close</span>
  <div class="flex h-full">
    <app-slide-post [filters]="infoPost.postFiltersByImage" [files]="infoPost.medios" *ngIf="infoPost.medios.length>0"
                    [isView]="true" [isProfile]="infoPost" [isViewFile]="true" [useSlide]="'post'"
                    class="block w-[calc(100%-420px)] h-full"></app-slide-post>

    <div class="w-[420px] pt-6 h-full overflow-auto">
      <div class="h-[calc(100%-90px)] overflow-auto">
        <div class="flex gap-4 container-avatar px-4 mt-2">
          <img [src]="infoPost.avatar" class="avatar" alt="img">
          <div class="flex flex-col justify-center avatar-info">
            <p class="username sm:text-[18px] sm:font-medium sm:leading-[1]">{{ infoPost.name }}
              <span *ngIf="infoPost.verifiedAccount" class="material-symbols-outlined verifiedAccount">
          Done
          </span>
            </p>
            <p
              class="font-Poppins font-light text-[10px] sm:text-[12px] text-[#777777]  sm:leading-[1] mt-2">{{ calculateDateDifference(infoPost.date) }}</p>
          </div>
        </div>
        <p
          class="font-Poppins text-[#000000] mx-6 text-[14px] sm:text-[18px] hidden sm:block font-semibold my-3">{{ infoPost.title }}</p>
        <p class="font-Poppins text-[#77838F] mx-6 text-[14px] sm:text-[14px] my-2 mb-0"
           [innerHtml]="convertHashtagsToTags()"></p>
        <div class="p-4">
          <app-reactions-bar
            id="comment-reactions0"
            class="block py-3 sm:py-0 border-t border-b border-[#EEEEEE]"
            (clickIconLike)="clickIconLike.emit()"
            (clickIconSave)="clickIconSave.emit()"
            (clickIconShare)="clickIconShare.emit()"
            [like]="like"
            [save]="save"
            [infoPost]="infoPost"
            [seeLabelsReactions]="true"
            [classLabels]="'!text-[11px]'"
            [classIcons]="'sm:!h-[16px] sm:!w-[16px]'"
            [numberReactions]="{
          numberLikes:infoPost.postLikesCount,
      numberComments:infoPost.postCommentsCount,
          numberShares:infoPost.postShareCount,
      numberSaved:infoPost.postSavedCount,
        }"></app-reactions-bar>
        </div>
        <div class="px-4">
          <p *ngIf="infoPost.postCommentsCount>infoPost.comments.length" class="text-center">
            <span class="bg-[#c5c5c570] cursor-pointer px-2 py-1 rounded-2xl" (click)="getMoreComments()">
              More comments
            </span>
          </p>
          <app-comments-list [comments]="infoPost.comments"></app-comments-list>
        </div>
      </div>

      <div class=" w-full bg-white">
        <hr class="my-2 mx-4 border border-[#EEEEEE]">
        <div class="flex justify-center sm:justify-normal sm:mx-14" *ngIf="visibleEmojis">
          <emoji-mart [darkMode]="false" [showPreview]="false" class="sm:absolute z-[50] sm:right-0 sm:bottom-0"
                      (emojiClick)="addEmoji($event)" [excludeElementIds]="['btn-emojis','text-post2']"
                      (appClickOutside)="onClickOutside(2)"></emoji-mart>
        </div>
        <div class="flex justify-center sm:justify-normal sm:mx-14" *ngIf="visibleGif">
          <app-gif-search class="sm:absolute z-[50] sm:right-0 sm:bottom-1 sm:w-[75%]" (sendGif)="getGif($event)"
                          [excludeElementIds]="['btn-gif']" (appClickOutside)="onClickOutsideGif(2)"></app-gif-search>
        </div>
        <div class="flex items-center gap-2 mx-4 mt-2">
          <div *ngFor="let item of files; let i = index" class="relative">
            <span
              class="material-symbols-outlined absolute top-1 right-1 text-[1rem] cursor-pointer rounded-full bg-white"
              (click)="removeImage(i)">close</span>
            <img *ngIf="item.type === 'image'" [src]="item.src" class="h-[180px] object-cover rounded-2xl" alt="img">
            <video *ngIf="item.type === 'video'" class="h-[90px] object-cover rounded-2xl" controls>
              <source [src]="item.src" type="video/mp4">
              Tu navegador no soporta el tag de video.
            </video>
          </div>
        </div>
        <app-comment-form (sendComment)="saveComment($event)"></app-comment-form>
      </div>
    </div>
  </div>

</app-dialog>

