<app-sidebar-layout cls="relative sm:px-24 w-full sm:max-w-[1350px]" title="Categories" routeHeader="goBack"
                    [useHeaderEdit]="true">
  <div class="px-4  w-full m-auto">
    <span class=" sm:hidden material-symbols-outlined text-[30px] sm:text-[30px] text-[#707070] mt-4 cursor-pointer"
          (touchend)="goBack()">arrow_back</span>

    <div class="px-[10px] sm:px-0 mt-[15px] sm:mt-[25px]">
      <h1 class="sm:hidden text-[25px] sm:text-[30px] font-semibold font-Montserrat my-[15px]" id="title-caregories">
        Categories</h1>
      <ng-container *ngFor="let categories of categoriesObjsOrder">
        <div class="flex justify-between w-full">
          <ng-container *ngFor="let category of categories;">
            <a [routerLink]="category.route"
                 [queryParams]="{ type: category.name, type2 : 'all' }"
                 class="cursor-pointer w-[47%] h-[82px]  sm:w-[45%] 2xl:w-[48%] sm:h-[200px] 2xl:h-[280px] mb-[20px] sm:mb-[30px] rounded-2xl p-2 relative flex items-end justify-start bg-cover bg-center bg-[url('assets/categories/flower.jpg')]"
                 [style]="{'background-image': 'url(' + category.img + ')'}"
            >
              <div
                class="absolute rounded-2xl left-0 top-0 w-full h-full bg-gradient-to-t via-transparent from-black to-transparent"></div>
              <p
                class="w-full sm:w-[80%] pb-[5px] sm:pb-[5px] pl-[10px] sm:pl-[10px] z-10 font-Monserrat font-semibold text-[20px] leading-[28px] sm:text-[28px] sm:leading-[35px] text-white">
                {{ category.title }}
              </p>
            </a>
          </ng-container>
        </div>
      </ng-container>

      <h1 class="text-[25px] sm:text-[30px] font-semibold font-Montserrat my-[15px]" id="title-effects">Effects</h1>

      <ng-container *ngFor="let effect of effects">
        <div routerLink="/effect" [queryParams]="{ type: effect.name }"
             class="cursor-pointer w-full h-[100px] sm:h-[180px] 2xl:h-[220px] mb-[15px] sm:mb-[25px] rounded-2xl p-2 relative flex items-end justify-start bg-cover bg-center"
             [style.background-image]="'url('+effect.banner+')'">
          <div
            class="absolute rounded-2xl left-0 top-0 w-full h-full bg-gradient-to-t via-transparent from-black to-transparent"></div>
          <p
            class="w-full sm:w-[80%] pb-[15px] sm:pb-[30px] pl-[10px] sm:pl-[30px] z-10 font-Monserrat font-semibold text-[25px] leading-[24px] sm:text-[35px] sm:leading-[40px] text-white">
            {{ effect.title }}
          </p>
        </div>
      </ng-container>


    </div>

  </div>
</app-sidebar-layout>
