<div class="flex flex-col gap-2 sm:gap-4 py-4 px-4 w-full relative sm:px-0 min-h-screen">
  <div class="flex items-center w-full gap-4 px-4 justify-between">
    <div class="w-[calc(100%-190px)]">
      <app-om-input [useIcon]="true" color="white" [(ngModel)]="search" (ngModelChange)="getUsers(true)"
                    inputClass="rounded-full sm:rounded-md !bg-[#fff] !text-[#77838F] sm:!text-[#77838F] sm:!bg-white !py-[5.5px] shadow-md !font-poppins !font-medium relative !text-lg"
                    placeholder="">
        <app-search-icon class="hidden sm:inline-block" color="#77838F" [width]="18" [height]="18"/>
        <app-search-icon class="inline-block sm:hidden" color="#519935" [width]="18" [height]="18"/>
      </app-om-input>
    </div>
    <div class="w-[190px]">
      <app-om-select id="s"
                     containerClass="!py-0 !w-full !font-poppins !font-medium shadow-lg text-[13px]]"
                     containerItemsClass="!max-h-fit"
                     itemsClass="nav-select-item !py-1 bg-white !text-[#777] hover:!bg-white hover:!text-green-native sm:hover:!text-green-native"
                     color="gray" [options]="options" [(ngModel)]="type" required (ngModelChange)="getUsers(true)"
                     [default]="type" [buttonTemplate]="true" placeholder=""
                     class="sm:block w-[190px]">
        <ng-container ngProjectAs="buttonAction">
            <div class="flex items-center justify-between gap-2 w-[190px] bg-[#519935] py-2.5 px-4 rounded-xl">
              <p class="text-[#fff] text-[13px]">Sort by: <span class="text-[#fff]">{{type}}</span> </p>
              <app-chevron-down-icon color="#fff" [width]="20" [height]="20"></app-chevron-down-icon>
            </div>
        </ng-container>
      </app-om-select>
    </div>
  </div>
  <app-loading *ngIf="statusFetch"></app-loading>
  <app-not-found message="No users found :(" *ngIf="!users?.length"></app-not-found>
  <div class="grid grid-cols-1 sm:grid-cols-2 px-4 gap-8" *ngIf="users?.length">
    <app-friend-card *ngFor="let user of users" [user]="user" [isRequest]="type==='request'" (resetList)="getUsers(true)"/>
  </div>
</div>
