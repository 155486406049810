import {Component, ElementRef, EventEmitter, inject, Input, NgZone, Output, ViewChild} from '@angular/core';
import {ChatService} from "../../../../services/chat/chat.service";
import {UserInfoService} from "../../../../services/user-info/user-info.service";
import {DeviceInfoService} from "../../../../services/device-info/device-info.service";

@Component({
  selector: 'app-form-message',
  templateUrl: './form-message.component.html',
  styleUrls: ['./form-message.component.scss']
})
export class FormMessageComponent {
  userInfoService = inject(UserInfoService);
  deviceInfoService = inject(DeviceInfoService);
  @Output() clickIconLike = new EventEmitter<any>();
  @Output() clickIconSave = new EventEmitter<any>();
  @Output() sendComment = new EventEmitter<any>();
  @Input() avatar: string = '';
  @Input() classAvatar: string = '';
  @ViewChild('emojiTextarea', { static: false }) emojiTextarea!: ElementRef<HTMLTextAreaElement>;
  isFocused: boolean = false;
  comment='';
  files: { type: string, src?: string, name?: string }[] = [];
  limit=1;
  visibleEmojisKey=0;
  visibleGifKey=0;
  visibleEmojis=false;
  visibleGif=false;
  limitCommentsV=2;
  onFileSelected(event: any): void {
    const files: FileList = event.target.files;
    for (let f = 0; f < files.length; f++) {
      this.userInfoService.updateImage(files[f]).then(r => {
        let prefix = files[f].type.startsWith('image/') ? 'img' : 'video' ;
        this.sendComment.emit({comment:`OG-${prefix}:${r}`})
      })
    }
    this.files=[];
    //let promise = this.handleFiles(files);
  }
  async handleFiles(files: FileList): Promise<void> {
    for (let i = 0; i < files.length; i++) {
      if (this.files.length === this.limit) {
        return;
      }
      const file = files[i];
      const readFile = (file1: File): Promise<string | undefined> => {
        return new Promise<string | undefined>((resolve) => {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            resolve(e.target.result as string | undefined);
          };
          reader.readAsDataURL(file1);
        });
      };

      if (file.type.startsWith('image/')) {
        const resp = await readFile(file);
        if (resp !== undefined) {
          this.files.push({ type: 'image', src: resp });
        }
      } else if (file.type.startsWith('video/')) {
        const resp = await readFile(file);
        if (resp !== undefined) {
          this.files.push({ type: 'video', src: resp });
        }
      }
    }

  }

  onClickOutside(index:number){
    if(index!=this.visibleEmojisKey) return
    if(this.visibleEmojis) this.visibleEmojis=false;
  }
  addEmoji(emoji: any) {
    const textarea = this.emojiTextarea.nativeElement;
    const emojiText = emoji.emoji.native;
    const cursorPosIni = textarea.selectionStart;
    const cursorPosFin = textarea.selectionEnd;
    const ini = textarea.value.substring(0, cursorPosIni);
    const fin = textarea.value.substring(cursorPosFin);
    textarea.value = ini + emojiText + fin;
    const newPos = cursorPosIni + emojiText.length;
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.ngZone.run(() => {
          textarea.setSelectionRange(newPos, newPos);
          textarea.focus();
        });
      });
    });
    textarea.dispatchEvent(new Event('input'));
  }
  getGif(data: string) {
    this.visibleGif=false;
    if (this.files.length === this.limit) {
      this.removeImage(0);
    }
    this.userInfoService.updateImage(this.deviceInfoService.dataURLtoFile(data, `gif${new Date().getTime()}.gif`)).then(r => {
      this.sendComment.emit({comment:`OG-img:${r}`})
    })
    this.files = [];
  }
  onClickOutsideGif(index:number){
    if(index!=this.visibleGifKey) return
    if(this.visibleGif) this.visibleGif=false;
  }
  removeImage(index: number): void {
    this.files.splice(index, 1);
  }
  saveComment(){
    if(this.comment=='' && this.files.length==0) return;
    this.sendComment.emit({comment:this.comment})
    this.files=[];
    this.comment='';
  }
  openEmoji(index:number){
    this.visibleEmojisKey=index;
    this.visibleEmojis=true;
  }
  openGif(index:number){
    this.visibleGifKey=index;
    this.visibleGif=true;
  }
  constructor(private ngZone: NgZone) {}
}
