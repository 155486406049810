<app-clear-layout  title="All Growers" routeHeader="/growers-home"  [useHeaderEdit]="true">
  <div class="flex sm:bg-[#FAFAFA] w-full">
    <div class="hidden duration-700 ease-out sm:block fixed sm:w-[236px] h-[calc(100vh-(var(--sat)))] top-[calc(var(--sat))] sm:top-[101px] sm:h-[calc(100%-101px)] overflow-auto left-0 sm:px-[20px] sm:mt-[40px]">
      <app-filters-growers></app-filters-growers>
    </div>
    <div class="w-full sm:w-[calc(100%-260px)] sm:ml-[230px] sm:mrdsas-[386px] px-4 sm:px-[20px]">
      <div>
        <span class=" sm:hidden material-symbols-outlined text-[30px] sm:text-[30px] !text-[#707070] mt-4 cursor-pointer" routerLink="/growers-home">arrow_back</span>

        <div class="flex pt-2 items-center justify-between relative w-full pb-4 gap-4">
          <div class="w-[180px] hidden sm:block">
            <app-om-select id="select-nav2"
                           containerClass="!bg-white !py-[4.5px] sm:!py-[8px] !font-poppins !font-medium shadow-md !text-lg"
                           containerItemsClass="!max-h-fit"
                           itemsClass="nav-select-item !py-1 hover:!bg-white hover:!text-green4-native"
                           color="gray" [options]="options" [(ngModel)]="selected" required
                           #selectedNav="ngModel" [default]="selected" placeholder="Filter"
            />
          </div>
          <div class="relative w-full sm:w-[calc(100%-180px)]">
            <app-om-input [useIcon]="true" color="white" [(ngModel)]="search"
                          [id]="'search-market'"
                          inputClass="rounded-full sm:rounded-md !bg-[#fff] sm:!bg-[#fff] !text-[#77838F] sm:!bg-white !py-[5.5px] sm:!py-[10px] shadow-md !font-poppins !font-medium relative placeholder:!text-lg !text-lg"
                          placeholder="Search">
              <app-search-icon class="hidden sm:inline-block" color="#77838F" [width]="18" [height]="18"/>
              <app-search-icon class="inline-block sm:hidden" color="#519935" [width]="18" [height]="18"/>
            </app-om-input>
            <div class="absolute bg-[#d0d0d0] rounded-full p-1 right-[10px] sm:right-2 top-1 sm:top-2 sm:hidden">
              <div class="relative" #filterList>
                <app-filters-icon [width]="24" [height]="24" (click)="showFilters = !showFilters"></app-filters-icon>
                <div #containerFilterList *ngIf="showFilters" class="absolute bg-white z-20 right-0 bw-1 border-[#d0d0d0] top-[28px] w-[75vw] h-[70vh] py-[14px] px-[20px] rounded-[10px] overflow-auto">
                  <app-filters-growers></app-filters-growers>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="sm:grid sm:grid-cols-3 gap-[20px] mt-[20px] sm:mt-[30px]">
          <ng-container *ngFor="let user of users let i = index">
            <app-grower-card class="block w-full mb-[25px] sm:mb-4 sm:my-0" [user]="user" ></app-grower-card>
            <app-custom-carousel class="inline-table sm:col-span-3 my-[10px] sm:my-[20px]" *ngIf="i==5"></app-custom-carousel>
            <app-custom-carousel class="inline-table sm:col-span-3 mt-[10px] sm:my-[20px]" *ngIf="i==11"></app-custom-carousel>
          </ng-container>
        </div>
      </div>
    </div>
  </div>


</app-clear-layout>
