import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent {
  @Input() allowNews: boolean = false;
  @Input() color: string = "white";
  @Input() key: string = "";
  @Input() label: string = "";
  @Input() labelTemplate = "";
  @Input() inputClass: string = "white";
  @Input() placeholder: string = "";
  @Input() useIcon: boolean = false;
  @Input() options: any[] = [];
  results: string[] = [];
  @Input() keyword = '';
  @Output() keywordChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  focus = false;

  search(keyword: string) {
    this.results = this.options.filter(option => {
      if(typeof option === 'string'){
        return option.toLowerCase().includes(keyword.toLowerCase())
      } else if(this.labelTemplate) {
        return option[this.labelTemplate].toLowerCase().includes(keyword.toLowerCase())
      } else {
        return option[this.label].toLowerCase().includes(keyword.toLowerCase())
      }
    });
  }
  template(strings: any, ...keys: any) {
    return (values: any) => {
      let result = strings[0];
      keys.forEach((key: string, i: number) => {
        result += values[key] + strings[i + 1];
      });
      return result;
    };
  }
  onEnter(){
    if(this.results?.length === 1){
      this.keyword = ''
      this.keywordChange.emit(this.results[0]);
      this.onChange.emit(this.results[0]);
    } else if(this.allowNews){
      this.keywordChange.emit(this.keyword);
      this.onChange.emit(this.keyword);
      this.keyword = ''
    }
  }
  selectItem(item: any) {
    this.keyword = '';
    this.keywordChange.emit(item);
    this.onChange.emit(item);
    this.results = [];
  }
  getTemplateResult(result: any) {
    if(this.label && !this.labelTemplate){
      return result[this.label];
    } else if(!this.label && this.labelTemplate){
      const resultTemplate = this.template`${'labelTemplate'}`
      return resultTemplate(result);
    } else return result;
  }
  getTarget(evt: Event): HTMLInputElement | null {
    return <HTMLInputElement>(evt?.target || {value: ''} as HTMLInputElement)
  }

  getInputClass():{[key: string]: boolean} {
    let obj: {[key: string]: boolean} = {
      'om-input-gray': this.color === 'gray',
      'om-input-white': this.color === 'white',
      'placeholder:!text-white/80': this.color === 'black',
    };
    if(this.inputClass) {
      let splitClass = this.inputClass.split(' ');
      splitClass.forEach(s => {
        obj[s] = true;
      })
    }
    if(this.useIcon) {
      obj['icon-input'] = true;
    }
    return obj;
  }
  setInitialItems() {
    if(this.focus)
      this.results = this.options.slice(0, 5);
  }
  setFocus(val: boolean){
    this.focus = val;
    if(val){
      this.setInitialItems()
    } else {
      setTimeout(() => {
        this.results = [];
      }, 500)
    }
  }
}
