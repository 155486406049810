<app-home-sended-gift *ngIf="showList" [(openEvent)]="showList"></app-home-sended-gift>
<div class="w-full min-h-full">
  <div class="flex items-center px-4 justify-between relative w-full pb-2 gap-4">
    <div class="w-[25%] hidden sm:block">
      <app-om-select id="select-nav"
                     containerClass="!bg-white !py-[4.5px] !font-poppins !font-medium shadow-md text-lg"
                     containerItemsClass="!max-h-fit"
                     itemsClass="nav-select-item !py-1 hover:!bg-white hover:!text-green4-native"
                     color="gray" [options]="categories" [(ngModel)]="filters['category']" required
                     (ngModelChange)="getSharables()"
                     #selectedNav="ngModel" [default]="filters['category']" placeholder="Filter"
      />
    </div>
    <div class="relative w-full">
      <app-om-input [useIcon]="true" color="white" [(ngModel)]="search" (change)="getSharables()"
                    inputClass="rounded-full sm:rounded-md !bg-[#fff] !text-[#77838F] sm:!bg-white !py-[12px] shadow-md !font-poppins !font-medium relative !text-md"
                    placeholder="Search">
        <app-search-icon class="hidden sm:inline-block" color="#77838F" [width]="18" [height]="18"/>
        <app-search-icon class="inline-block sm:hidden" color="#519935" [width]="18" [height]="18"/>
      </app-om-input>
      <div class="absolute bg-[#d0d0d0] rounded-full p-1 right-[-42px] sm:right-2 top-1">
        <div class="relative" #filterList>
          <app-filters-icon [width]="24" [height]="24" (click)="showFilters = !showFilters"></app-filters-icon>
          <div #containerFilterList *ngIf="showFilters"
               class="absolute bg-white rounded-sm z-20 right-0 bw-1 border-[#d0d0d0] top-[28px]">
            <div class="py-2 px-4 border-[#d0d0d0] border-b flex gap-2 sm:gap-4 ">
              <div (click)="setSort('sort')"
                   class="bg-[#fff] flex w-fit rounded-full items-center bw-1 border-[#efefef] gap-2 font-poppins text-[12px] py-1 px-3 sm:px-4"
                   [class]="{'!bg-[#EFEFEF]': filterTab === 'sort'}">
                Sort
                <app-chevron-up-icon *ngIf="filterTab === 'sort'" [width]="16" [height]="16"/>
                <app-chevron-down-icon *ngIf="filterTab !== 'sort'" [width]="16" [height]="16"/>
              </div>
              <div (click)="setSort('category')"
                   class="bg-[#fff] flex w-fit rounded-full items-center bw-1 border-[#efefef] gap-2 font-poppins text-[12px] py-1 px-3 sm:px-4"
                   [class]="{'!bg-[#EFEFEF]': filterTab === 'category'}">
                Category
                <app-chevron-up-icon *ngIf="filterTab === 'category'" [width]="16" [height]="16"/>
                <app-chevron-down-icon *ngIf="filterTab !== 'category'" [width]="16" [height]="16"/>
              </div>
              <div (click)="setSort('type')"
                   class="bg-[#fff] flex w-fit rounded-full items-center bw-1 border-[#efefef] gap-2 font-poppins text-[12px] py-1 px-3 sm:px-4"
                   [class]="{'!bg-[#EFEFEF]': filterTab === 'type'}">
                Type
                <app-chevron-up-icon *ngIf="filterTab === 'type'" [width]="16" [height]="16"/>
                <app-chevron-down-icon *ngIf="filterTab !== 'type'" [width]="16" [height]="16"/>
              </div>
            </div>
            <div class="py-2 px-4 border-[#d0d0d0] border-b flex gap-4 " *ngFor="let item of getCatalogFilter()"
                 (click)="setFilterType(item.value)">
              <div
                [class]="{'!text-[#000]': filters[filterTab] === item.value, '!font-medium ': filters[filterTab] === item.value}"
                class="flex rounded-full items-center justify-between w-full gap-2 font-poppins text-[#75838D] font-normal text-[14px] py-1 px-1">
                {{ item.label }}
                <app-right-check-icon *ngIf="filters[filterTab] === item.value" [width]="18" [height]="18"
                                      color="#000"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-fit sm:min-w-[210px] flex gap-4 items-center justify-center">
      <button  *ngIf="isMe()"
        class="p-1.5 sm:px-4 sm:!py-[10.5px] gap-2 flex items-center font-semibold text-xs bg-[#519935] text-white rounded-full sm:rounded-md shadow-sm"
        routerLink="/add-product">
        <ng-icon name="matPlus" class="text-white cursor-pointer text-lg z-10"></ng-icon>
        <span class="hidden sm:flex">New Product</span>
      </button>
      <button  *ngIf="isMe()"
        class="p-1.5 sm:px-4 sm:!py-[10.5px] gap-2 flex items-center font-semibold text-xs bg-amber-400 text-white rounded-full sm:rounded-md shadow-sm"
        (click)="showList = true;">
        <ng-icon name="matChecklistRtl" class="text-white cursor-pointer text-lg z-10"></ng-icon>
      </button>
    </div>
  </div>
  <div class="min-h-[500px]">
    <app-not-found message="No products found, create one to view :)" [allowBackToHome]="false" *ngIf="!sharables?.length"/>
    <div class="flex flex-wrap justify-center	gap-4 mt-4" *ngIf="sharables.length>0">
      <app-card-mini class="block w-[95%] sm:w-[48%]" [useTemplateFooter]="!!username" [btndetailVisable]="true"
                     [mine]="isMe()" *ngFor="let item of sharables" [isStrainFinder]="true"
                     [data]="item"></app-card-mini>

    </div>
  </div>
</div>
