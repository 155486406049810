import {Component, ElementRef, EventEmitter, HostListener, inject, Input, Output} from '@angular/core';
import {Subscription} from "rxjs";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-status-gift',
  templateUrl: './status-gift.component.html',
  styleUrls: ['./status-gift.component.scss']
})
export class StatusGiftComponent {
  http = inject(HttpClient);
  @Input() merch: any = {};
  @Output() merchChange = new EventEmitter<any>();
  @Input() type: string = "";
  isOpen = false;
  constructor(private elementRef: ElementRef) {
  }
  changeStatus(status: string){
    let merchUpdated = { ...this.merch };
    merchUpdated['status'] =  status === 'on-the-way' ?  'onTheWay' : status;
    this.merchChange.emit(merchUpdated)
  }
  getStatus(status: string) {
    switch (status) {
      case 'pendingPayment':
        return 'Pending claim';
      case 'received':
        return 'Received';
      case 'paid':
        return 'Processing';
      case 'onTheWay':
        return 'On The Way';
      case 'processing':
        return 'Processing';
      case 'rejected':
        return 'Rejected';
      case 'undelivered':
        return 'Undelivered';
      default:
        return status;
    }
  }

  subsUpdateStatus: Subscription | undefined;
  updateStatus(status: string) {
    const body: any = {
      iduser_to: this.merch.merchIdUserRecived,
      idmerch: this.merch.merchId,
      iduser_send: this.merch.merchIdUserSended,
      status: status,
      type: this.merch.merchType
    };
    this.subsUpdateStatus = this.http.post(`/merch/${status}`, body).subscribe({
      next: (r) => {
        this.changeStatus(status);
        this.isOpen = false;
        this.subsUpdateStatus?.unsubscribe();
      },
      error: (e) => {
        console.log(e)
        this.subsUpdateStatus?.unsubscribe();
      }
    });
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }
  protected readonly open = open;
}
