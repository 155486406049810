<div class="px-4 py-6 sm:py-2 flex justify-evenly w-full">
  <div class="rounded-full p-2.5 shadow-[0px_2px_4px_#00000029]" [class]="{'bg-[#519935]': type === 'string'}"
       (click)="onTypeChange('string')">
    <app-edit-icon [width]="34" [height]="34" [color]="type === 'string' ? '#fff' : '#707070'"></app-edit-icon>
  </div>
  <div class="rounded-full p-2.5 shadow-[0px_2px_4px_#00000029]" [class]="{'bg-[#519935]': type === 'media'}"
       (click)="onTypeChange('media')">
    <app-camera-icon [width]="34" [height]="34" [color]="type === 'media' ? '#fff' : '#707070'"></app-camera-icon>
  </div>
  <div class="rounded-full p-2.5 shadow-[0px_2px_4px_#00000029]" [class]="{'bg-[#519935]': type === 'gif'}"
       (click)="onTypeChange('gif')">
    <app-image-gif-icon [width]="34" [height]="34" [color]="type === 'gif' ? '#fff' : '#707070'"></app-image-gif-icon>
  </div>
  <div class="rounded-full p-2.5 shadow-[0px_2px_4px_#00000029]" [class]="{'bg-[#519935]': type === 'article'}"
       (click)="onTypeChange('article')">
    <app-history-i-icon [width]="34" [height]="34"
                        [color]="type === 'article' ? '#fff' : '#707070'"></app-history-i-icon>
  </div>
</div>
