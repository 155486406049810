import {Component, computed, inject, OnInit, ViewChild} from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import {LocationService} from "../../services/location/location.service";
import {HomePublicationsComponent} from "../publications/home-publications/home-publications.component";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  typePost = 'Lastest'
  @ViewChild(HomePublicationsComponent, { static: false }) homePublicationsComponent: HomePublicationsComponent | undefined;
  showMenu = false;
  private authService = inject(AuthService);

  public user= computed(()=>this.authService.currentUser);
  location: GeolocationPosition | null = null;

  constructor(private locationService: LocationService) {}
  onLogout(){
    this.authService.logout()
  }
  openFormPost(){
    this.homePublicationsComponent?.openFormPost();
  }
  toggleMenu() {
    this.showMenu = !this.showMenu;
  }
  ngOnInit(){
    window.scrollTo(0, 1);
    // this.requestLocationPermission();
  }
  requestLocationPermission() {
    this.locationService.getLocation()
      .then((position) => {
        this.location = position;
      })
      .catch((error) => {
        console.error('Error getting location:', error);
      });
  }
}
