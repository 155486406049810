import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-grower-banner',
  templateUrl: './grower-banner.component.html',
  styleUrls: ['./grower-banner.component.scss']
})
export class GrowerBannerComponent {
  @Input() title = 'Growers';
  @Input() img = 'assets/growers/banner.png';
}
