import {Component, inject, Input, OnDestroy, OnInit} from '@angular/core';
import {SearchAllService} from "../../../../services/search-all/search-all.service";
import {CatalogsService} from "../../../../services/catalogs/catalogs.service";
import {UserInfoService} from "../../../../services/user-info/user-info.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-own-courses',
  templateUrl: './own-courses.component.html',
  styleUrls: ['./own-courses.component.scss']
})
export class OwnCoursesComponent implements OnInit, OnDestroy {
  @Input() userId = ''
  searchAllService = inject(SearchAllService);
  userInfoService = inject(UserInfoService);
  private catalogsService = inject(CatalogsService);
  categories: { label: string, value: string }[] = [{label: 'All Category', value: 'all'},]

  isMe() {
    return this.userInfoService.isMe(this.userId)
  }

  search = ''
  category = 'all'
  limit = 10;
  offset = 0;
  courses: any[] = [];

  async ngOnInit() {
    this.getCategories();
    this.getCourses();
  }

  ngOnDestroy() {
    this.suscriptionCourses?.unsubscribe();
    this.suscriptionCategory?.unsubscribe();
  }

  suscriptionCourses: Subscription | undefined;

  getCourses() {
    this.suscriptionCourses = this.searchAllService.getCourses({
      limit: this.limit,
      offset: this.offset,
      userId: this.userId,
      type: 'profile',
      ...(this.search ? {
        search: this.search,
      } : {}),
      ...(this.category !== 'all' ? {
        category: this.category
      } : {}),
    }).subscribe((r: any) => {
      this.courses = r;
      this.suscriptionCourses?.unsubscribe()
    })
  }

  suscriptionCategory: Subscription | undefined;

  getCategories() {
    this.suscriptionCategory = this.catalogsService.getAll('courses-categories', '').subscribe({
      next: (response: any) => {
        this.categories = [...this.categories, ...response.records.map((x: any) => {
          return {
            value: x.name.toLowerCase(),
            label: x.name,
          };
        })]
        this.suscriptionCategory?.unsubscribe();
      }
    })
  }
}
