import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FollowAndFriendService} from "../../../../services/follow-and-friend/follow-and-friend.service";
import {ChatService} from "../../../../services/chat/chat.service";
import {UserInfoService} from "../../../../services/user-info/user-info.service";
import {OmAlertService} from "../../../../om-alert.service";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-friend-card',
  templateUrl: './friend-card.component.html',
  styleUrls: ['./friend-card.component.scss']
})
export class FriendCardComponent implements OnInit {
  private router = inject(Router)
  private alertService= inject(OmAlertService);
  private http = inject(HttpClient);
  private followAndFriends = inject(FollowAndFriendService);
  private chatService = inject(ChatService);
  private userInfoService = inject(UserInfoService);
  @Output() resetList = new EventEmitter<string>();
  @Input() isRequest = false;
  isFollowing= false;
  @Input() user : any = {banner: '', image_profile: '', name: '', lastname: '', username: '', description: '', id: ''};
  ngOnInit() {
    this.validIsFollowing();
  }
  constructor( private userInfo: UserInfoService){}
  async openChat() {
    let user = await this.userInfoService.getById(this.user['id'])
    this.chatService.changeUser(user)
    this.router.navigateByUrl('/messages')
  }
  goToUser(){
    this.router.navigateByUrl('/profile/'+this.user?.id)
  }
  async validIsFollowing() {
    this.isFollowing = await this.followAndFriends.isFollowing(this.user?.id);
  }
  async follow() {
    await this.followAndFriends.add(this.user?.id);
    await this.followAndFriends.getFollowings(true);
    await this.followAndFriends.getFollowers(true);
    await this.validIsFollowing();
  }
  async unfollow() {
    await this.followAndFriends.delete(this.user?.id);
    await this.followAndFriends.getFollowings(true);
    await this.followAndFriends.getFollowers(true);
    await this.validIsFollowing();
  }
  addFriendRequest(status:string) {
    const customConfig= {
      title: status==='accepted'?'Accepted friendship':'Rejected friendship',
      description:'',
      containerClass:'logout-dialog',
      closeOnPress:false,
      activeButtonCancel:false
    };
    this.http.post('/friends/request/update', {idUserFriend:this.user?.id,status}).subscribe({
      next: (response: any) => {
        const {success,data}=response
        if(success){
          this.alertService.setCustomConfig(customConfig);
          this.alertService.showCustomDialog();
          //this.chatService.sendPush(this.user?.id, {message:this.userInfo.me().user.name+(status==='accepted'?' accepted your friendship request':' rejected your friendship request')}, 'friendRequest');
          this.resetList.emit("reset");
          this.alertService.getIsOKPressed().subscribe(isOK => {
            if (isOK === true) this.alertService.closeDialog()
          });


        }
        console.log(response)
      },
      error: (err) => {
        alert("Error")
      }
    });

  }

}
