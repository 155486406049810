<div class="relative">
  <div class="w-full rounded-2xl relative h-[170px] sm:h-[275px] bg-cover bg-center">
    <img src="assets/demo/bannerSF.png" alt="" class="w-full h-full rounded-2xl object-cover">
    <div class="absolute rounded-2xl left-0 top-0 w-full h-full bg-gradient-to-r from-black to-transparent"></div>
    <p class="absolute top-0 h-full px-6 flex items-center z-10 font-Monserrat font-bold text-[20px] leading-[26px] sm:text-[30px] sm:leading-[36px] text-white max-w-[446px]">
      Find the best strains for you to personalize your cannabis experience
    </p>
  </div>
  <button class="absolute right-4 bottom-4 px-4 py-2 text-[14px] bg-[#519935] text-white rounded-lg font-bold">Find strains</button>
</div>
