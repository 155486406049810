<div class="flex gap-2 sm:gap-4 items-center">
  <h1 class="text-[20px] font-semibold font-Montserrat">All Products</h1>
  <p class="flex items-center text-green2-native text-[20px] sm:text-[15px] font-medium font-Montserrat cursor-pointer" routerLink="/gifts-search"  fragment="title-effects">
    <span class="hidden sm:block cursor-pointer">View All</span>
    <span class="material-symbols-outlined flex items-center justify-center border-[1.5px] border-green4-native w-[18px] h-[18px] pl-[1px] text-[20px] sm:text-[16px] sm:w-auto rounded-full sm:border-0">navigate_next</span>
  </p>
</div>
<app-not-found message="No products found with applied filters :(" *ngIf="!sharables?.length"/>
<div class="flex flex-wrap justify-center sm:justify-normal	sm:gap-2 mt-8">
  <app-card-mini class="block sm:mr-[10px] w-[90%] sm:w-[385px] mb-[15px] sm:pb-[20px]" (selectGift)="selectedGift($event)" [isStrainFinder]="true" [useTemplateFooter]="true" [mine]="true" *ngFor="let item of sharables" [data]="item" [btndetailVisable]="false">
    <footer class="block w-full px-[9px] sm:px-[14px]">
      <div class="flex justify-between">
        <div class="flex gap-4 container-avatar">
          <div class="relative">
            <img [src]="item.user?.image_profile || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'" class="w-[35px] h-[35px] sm:w-[50px] sm:h-[50px] rounded-full object-cover " alt="   ">
            <div *ngIf="item.user.is_founder===1" class="absolute bottom-0 right-0 w-[20px] h-[20px] sm:w-[45%] sm:h-[45%] p-[2px] rounded-[15px] sm:rounded-full bg-white">
              <img src="assets/ogfc.png" alt="" class="object-cover w-full">
            </div>
          </div>
          <div class="flex flex-col justify-center">
            <p class="text-[14px] text-[#FFFFFF] font-bold  truncate max-w-[90px]">{{ item.user.name||item.user.username }}</p>
            <p class="text-[12px] text-[#FFFFFF]">{{item.user.region}} Grower</p>
          </div>
        </div>
        <div class="flex items-center gap-2">
          <button class="text-[15px] font-semibold sm:text-[13px] text-[#FFFFFF] flex justify-center items-center w-fit px-6 h-[35px] rounded-full bg-[#81CB52]" (click)="claimgift(item)">{{item.publish_cost ? 'Add to Cart' : 'Claim'}}</button>
        </div>
      </div>
    </footer>
  </app-card-mini>
</div>
