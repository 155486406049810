import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FollowAndFriendService {
  http = inject(HttpClient)
  followers: any = []
  followings: any = []

  constructor() {
  }
  countFollowers(): Observable<string> {
    return of(this.formatFollowerCount(this.followers.length));
  }
  countFollowings(): Observable<string> {
    return of(this.formatFollowerCount(this.followings.length));
  }
  formatFollowerCount(count: number): string {
    if(count >= 1e9) {
      return (count / 1e9).toFixed(1) + 'B';
    } else if(count >= 1e6){
      return (count / 1e6).toFixed(1) + 'M';
    } else if(count >= 1e3){
      return (count / 1e3).toFixed(1) + 'K';
    } else {
      return count.toString();
    }
  }

  add(idUserToFollower: string) {
    return new Promise((resolve, reject) => {
      try {
        this.http.post('/follow/add', {idUserToFollower}).subscribe({
          next: response => {
            resolve(response);
          }
        });
      } catch (e) {
        reject(e)
      }
    });
  }

  delete(idUserToFollower: string) {
    return new Promise((resolve, reject) => {
      try {
        this.http.post('/follow/unfollow', {idUserToFollower}).subscribe({
          next: response => {
            resolve(response);
          }
        });
      } catch (e) {
        reject(e)
      }
    });
  }

  getFollowers(isUpdate: boolean = false) {
    return new Promise((resolve, reject) => {
      try {
        let fw = JSON.parse(localStorage.getItem('fw') || '[]')
        if (fw?.length && !isUpdate) {
          this.followers = fw;
          return resolve(fw);
        }
        this.http.get('/follow/followers',).subscribe({
          next: (response: any) => {
            this.followers = response?.data || response;
            if(!Array.isArray(this.followers)){
              this.followers = [];
            }
            localStorage.setItem("fw", JSON.stringify(this.followers));
            resolve(this.followers);
          }
        });
      } catch (e) {
        reject(e)
      }
    });
  }

  getFollowings(isUpdate: boolean = false) {
    return new Promise((resolve, reject) => {
      try {
        let fws = JSON.parse(localStorage.getItem('fws') || '[]')
        if (fws?.length && !isUpdate) {
          this.followings = fws;
          return resolve(fws);
        }

        this.http.get('/follow/followings',).subscribe({
          next: (response: any) => {
            this.followings = response?.data || response;
            if(!Array.isArray(this.followings)){
              this.followings = [];
            }
            localStorage.setItem("fws", JSON.stringify(this.followings));
            resolve(this.followings);
          }
        });
      } catch (e) {
        reject(e)
      }
    });
  }

  async isFollowing(idUserFollowing: string) {
    let follows = await this.getFollowings();
    return (follows as any).findIndex((f: any) => f?.id === idUserFollowing) !== -1;
  }
}
