import {Component, computed, inject} from '@angular/core';
import {AuthService} from "../../auth/services/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {
  showMenu = false;
  router = inject(Router);
  private authService = inject(AuthService);

  public user= computed(()=>this.authService.currentUser);

  onLogout(){
    this.authService.logout()
  }
  toggleMenu() {
    this.showMenu = !this.showMenu;
  }
}
