<app-sidebar-layout title="Product Information" [useHeaderEdit]="true" cls="pt-[var(--sat)+12px]">
  <router-header>
    <span class="material-symbols-outlined text-[30px] sm:text-[30px] text-[#707070] cursor-pointer mr-[10px]"
          (click)="goBack()">arrow_back</span>
  </router-header>
  <div *ngIf="isLoading" class="bg-white sm:shadow-sm m-0 sm:mx-12 px-6 pb-6 sm:my-8 sm:p-6 rounded-md relative w-full sm:max-w-5xl flex items-center">
    <div class="loading">
      <div class="spinner"></div>
      <span>Loading Product Information</span>
    </div>
  </div>
  <div class="bg-white sm:shadow-sm m-0 sm:mx-12 px-6 pb-6 sm:my-8 sm:p-6 rounded-md relative w-full sm:max-w-5xl" *ngIf="!isLoading">
    <div class="flex flex-col sm:flex-row items-start justify-start">
      <div class="sm:hidden">
        <ng-icon name="matArrowBack" class="sm:mt-0 absolute sm:flex left-2
        text-black cursor-pointer text-4xl z-10 bg-none sm:text-3xl sm:rounded-full"
                 (click)="router.navigateByUrl('/profile')"></ng-icon>
        <p class="font-poppins w-[82vw] font-semibold text-[#202020] text-center sm:hidden text-[18px] pt-2">Product
          details</p>
      </div>
      <div class="sm:pl-0">
        <p class="font-poppins font-semibold text-[#202020] text-[18px] pt-10 sm:pt-0">{{productEdit?.pk ? 'Edit' : 'New'}} Product</p>
        <p class="font-poppins font-normal my-2 text-[#898989] text-[14px]">Please provide details of this Strain.</p>
      </div>
    </div>
    <div class="flex flex-wrap sm:flex-nowrap w-full sm:pr-12 lg:pr-48 gap-4 items-start">
      <div class="mt-4 w-full sm:w-1/2">
        <label for="category" class="font-poppins font-medium text-[#202020] text-[14px] mb-2 block">Product
          Category</label>
        <app-om-select id="category"
                       containerClass="!bg-white !py-[4.5px] !font-poppins !font-medium bw-1 border-gray !text-[14px]"
                       containerItemsClass="!max-h-fit"
                       itemsClass="bg-white border-[#EEEEEE] !text-black hover:bg-white hover:!text-green4-native !py-1"
                       color="gray" [options]="category" [(ngModel)]="data.category" required
                       (ngModelChange)="updateProducts()"
                       #categorySelect="ngModel" [default]="data.category" placeholder="Select Product Category"
        />
        <p *ngIf="categorySelect.invalid && categorySelect.touched"
           class="text-red-500 text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*1.5)]">
          Category is required.</p>
      </div>
      <div class="mt-4 w-full sm:w-1/2">
        <label for="category" class="font-poppins font-medium text-[#202020] text-[14px] mb-2 block">Product
          Type</label>
        <app-om-select id="type"
                       containerClass="!bg-white !py-[4.5px] !font-poppins !font-medium bw-1 border-gray !text-[14px]"
                       containerItemsClass="!max-h-fit"
                       itemsClass="bg-white border-[#EEEEEE] text-black hover:bg-white hover:!text-green4-native !py-1"
                       color="gray" [options]="types" [(ngModel)]="data.type" required
                       (ngModelChange)="updateProducts()"
                       #typeSelect="ngModel" [default]="data.type" placeholder="Select Product Type"
        />
        <p *ngIf="data.category === 'flower' && typeSelect.invalid && typeSelect.touched"
           class="text-red-500 text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*1.5)]">
          Type is required.</p>
      </div>
    </div>
    <div class="flex w-full sm:pr-12 lg:pr-48 gap-4 items-center">
      <div class="mt-4 w-full">
        <label for="category" class="font-poppins font-medium text-[#202020] !text-[14px] mb-2 block">Product
          Name</label>
        <div class="relative">
          <app-autocomplete (click)="touched.push('name')" *ngIf="productsFilters?.length && data.category === 'flower'"
                            [(keyword)]="product" (onChange)="setProduct($event)" label="name"
                            [options]="productsFilters"
                            inputClass="!bg-white !py-[9.5px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px]"></app-autocomplete>
          <app-search-icon class="bg-white absolute top-3 right-2" [width]="20"
                           [height]="20"></app-search-icon>
        </div>
        <app-om-input (click)="touched.push('name')" *ngIf="!productsFilters?.length" color="white"
                      [(ngModel)]="data.name"
                      inputClass="!bg-white !py-[9px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px]"
                      placeholder="Product name"
                      class="w-[60%]">
        </app-om-input>
        <p *ngIf="touched.includes('name') && !data.name"
           class="text-red-500 text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*1.5)]">
          Name is required.</p>
      </div>
    </div>
    <div class="flex w-full sm:pr-12 lg:pr-48 gap-4 items-center">
      <div class="mt-4 w-full">
        <label for="category" class="font-poppins font-medium text-[#202020] text-[14px] mb-2 block">Product
          Description</label>
        <ckeditor (click)="touched.push('description')"
                  [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic', 'Underline', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'undo', 'redo' ] }"
                  [editor]="Editor" [(ngModel)]="data.description" data="<p>Hello, world!</p>"></ckeditor>
        <p *ngIf="touched.includes('description') && !data.description"
           class="text-red-500 text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*1.5)]">
          Description is required.</p>
      </div>
    </div>
    <div class="flex w-full sm:pr-12 lg:pr-48 gap-4 items-center">
      <div class="mt-4 w-full">
        <label for="thc" class="font-poppins font-medium text-[#202020] text-[14px] mb-4 sm:mb-2 block">THC %</label>
        <div class="flex items-center justify-center">
          <div class="w-full md:w-1/2">
            <div class="relative">
              <input type="range" [(ngModel)]="data.thc" appRange rangeType='default100' id="thc" name='thc'>
              <div class="absolute text-white top-0.5 font-poppins w-0 h-0"
                   [style]="'left: calc('+(data.thc + (data.thc > 90 ?  -10 : data.thc > 75 ? -8 : data.thc > 61 ? -6 : data.thc > 50 ? -4 : data.thc > 31 ? -3 : data.thc > 19 ? -1 : data.thc > 9 ? 2 : 4))+'%);'">
                {{ data.thc }}%
              </div>
            </div>
            <div class="flex justify-between">
              <span class="text-[14px] font-poppins font-medium text-[#202020]">0%</span>
              <span class="text-[14px] font-poppins font-medium text-[#202020]">100%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex w-full sm:pr-12 lg:pr-48 gap-4 items-center">
      <div class="mt-4 w-full">
        <label for="cdb" class="font-poppins font-medium text-[#202020] text-[14px] mb-4 sm:mb-2 block">CBD %</label>
        <div class="flex items-center justify-center">
          <div class="w-full md:w-1/2">
            <div class="relative">
              <input type="range" [(ngModel)]="data.cdb" appRange rangeType='default100' id="cdb" name='cdb'>
              <div class="absolute text-white top-0.5 font-poppins w-0 h-0"
                   [style]="'left: calc('+(data.cdb + (data.cdb > 90 ?  -10 : data.cdb > 75 ? -8 : data.cdb > 61 ? -6 : data.cdb > 50 ? -4 : data.cdb > 31 ? -3 : data.cdb > 19 ? -1 : data.cdb > 9 ? 2 : 4))+'%);'">
                {{ data.cdb }}%
              </div>
            </div>
            <div class="flex justify-between">
              <span class="text-[14px] font-poppins font-medium text-[#202020]">0%</span>
              <span class="text-[14px] font-poppins font-medium text-[#202020]">100%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex sm:pr-12 lg:pr-48 gap-4">
      <div class="flex w-full items-center"
           [class]="{'!w-1/3': data.category === 'flower'}">
        <div class="mt-4 w-full">
          <label for="amount" class="font-poppins font-medium text-[#202020] !text-[14px] mb-2 block">Amount
            <span *ngIf="data.category === 'flower'">(Total for gift : {{ (data.amount || 0) }})</span></label>

          <div *ngIf="data.category === 'flower' else elseBlock">
            <app-om-select id="amount"
                           containerClass="!bg-white !py-[4.5px] !font-poppins !font-medium bw-1 border-gray !text-[14px]"
                           containerItemsClass="!max-h-fit"
                           itemsClass="bg-white border-[#EEEEEE] text-black hover:bg-white hover:!text-green4-native !py-1"
                           color="gray" [options]="amounts" [(ngModel)]="data.amount" required (ngModelChange)="setForPublic($event)"
                           #selectAmount="ngModel" [default]="data.amount" placeholder="Select amount"
            />
            <p *ngIf=" selectAmount.invalid && selectAmount.touched"
               class="text-red-500 text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*1.5)]">
              Amount is required.</p>
          </div>
          <ng-template #elseBlock>
            <app-om-input color="white" #selectAmount="ngModel" [(ngModel)]="data.amount"
                          inputClass="!bg-white !py-[9.5px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] placeholder:!text-[14px] !rounded-r-none "
                          placeholder="e.g. 10"
                          class="w-[100%]">
            </app-om-input>
            <p *ngIf=" selectAmount.invalid && selectAmount.touched"
               class="text-red-500 text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*1.5)]">
              Amount is required.</p>
          </ng-template>
        </div>
      </div>
      <div class="flex w-1/3 items-center" *ngIf="data.category === 'flower'">
        <div class="mt-4 w-full">
          <label for="category" class="font-poppins font-medium text-[#202020] !text-[14px] mb-2 block">For friends
            (1 pinch per gift) <b *ngIf="productEdit?.pk">Total: {{productEdit.amount_friends}} available</b></label>
          <app-om-input color="white" #amountFriends='ngModel' [(ngModel)]="data.amount_friends"
                        [readonly]="data.amount === 0" (ngModelChange)="updateAmount('friends', $event)"
                        [disabled]="data.amount === 0" type="number"
                        inputClass="!bg-white !py-[9px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px]"
                        placeholder="Amount Friends e.g. 5" required
                        class="w-[60%]">
          </app-om-input>
          <p *ngIf=" amountFriends.invalid && amountFriends.touched"
             class="text-red-500 text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*1.5)]">
            Amount friends is required.</p>
        </div>
      </div>
      <div class="flex w-1/3 items-center" *ngIf="data.category === 'flower'">
        <div class="mt-4 w-full">
          <label for="category" class="font-poppins font-medium text-[#202020] !text-[14px] mb-2 block">For public gifts
            (1 pinch per gift) <b *ngIf="productEdit?.pk">Total: {{productEdit.amount_gifts}} available</b></label>
          <app-om-input color="white" #amountGifts='ngModel' [(ngModel)]="data.amount_gifts"
                        [readonly]="data.amount === 0" (ngModelChange)="updateAmount('gifts', $event)"
                        [disabled]="data.amount === 0" type="number"
                        inputClass="!bg-white !py-[9px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px]"
                        placeholder="Amount Gifts e.g. 5" required
                        class="w-[60%]">
          </app-om-input>
          <p *ngIf=" amountGifts.invalid && amountGifts.touched"
             class="text-red-500 text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*1.5)]">
            Amount gifts is required.</p>
        </div>
      </div>
    </div>
    <p *ngIf="data.category === 'flower' && data.amount && parseInt(data.amount) !== (parseInt(data.amount_friends) + parseInt(data.amount_gifts))"
       class="text-red-500 text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*1.5)]">
      Amount does not agree.</p>
    <div class="flex flex-wrap sm:flex-nowrap w-full sm:pr-12 lg:pr-48 gap-4 items-start">
      <div class="mt-4 w-full sm:w-1/2">
        <label for="flavors"
               class="font-poppins font-medium text-[#202020] !text-[14px] mb-2 block">Flavors</label>
        <div class="relative">
          <app-autocomplete [options]="getFlavorsName()" (onChange)="setFlavor($event)" [(keyword)]="flavor"
                            inputClass="!bg-white !py-[9.5px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px]"></app-autocomplete>
          <app-search-icon class="bg-white absolute top-3 right-2" [width]="20"
                           [height]="20"></app-search-icon>
        </div>
        <div class="flex flex-wrap py-4" *ngFor="let flavorItem of data.flavors; let indexF = index">
          <div class="flex items-center justify-center gap-2 relative">
            <div class="rounded-full bg-[#81cb524f] p-2">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                <path
                  d="M40-440v-80h240v80H40Zm270-154-84-84 56-56 84 84-56 56Zm130-86v-240h80v240h-80Zm210 86-56-56 84-84 56 56-84 84Zm30 154v-80h240v80H680Zm-200 80q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm198 134-84-84 56-56 84 84-56 56Zm-396 0-56-56 84-84 56 56-84 84ZM440-40v-240h80v240h-80Z"/>
              </svg>
            </div>
            <p class="font-poppins text-[14px] font-medium">{{ flavorItem }}</p>
            <app-trash-icon (click)="deleteItem('flavors', indexF)" [width]="18" [height]="18" color="#a0a0a0" hover="tomato" class="cursor-pointer"></app-trash-icon>
          </div>
        </div>
      </div>
      <div class="mt-4 w-full sm:w-1/2">
        <label for="Effects"
               class="font-poppins font-medium text-[#202020] !text-[14px] mb-2 block">Effects</label>
        <div class="relative">
          <app-autocomplete [options]="getEffectsName()" (onChange)="setEffect($event)" [(keyword)]="effect"
                            inputClass="!bg-white !py-[9.5px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px]"></app-autocomplete>
          <app-search-icon class="bg-white absolute top-3 right-2" [width]="20"
                           [height]="20"></app-search-icon>
        </div>
        <div class="flex flex-wrap py-4" *ngFor="let effectItem of data.effects; let indexF = index">
          <div class="flex items-center justify-center gap-2">
            <div class="rounded-full bg-[#81cb524f] p-2">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                <path
                  d="M40-440v-80h240v80H40Zm270-154-84-84 56-56 84 84-56 56Zm130-86v-240h80v240h-80Zm210 86-56-56 84-84 56 56-84 84Zm30 154v-80h240v80H680Zm-200 80q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm198 134-84-84 56-56 84 84-56 56Zm-396 0-56-56 84-84 56 56-84 84ZM440-40v-240h80v240h-80Z"/>
              </svg>
            </div>
            <p class="font-poppins text-[14px] font-medium">{{ effectItem }}</p>
            <app-trash-icon (click)="deleteItem('effects', indexF)" [width]="18" [height]="18" color="#a0a0a0" hover="tomato" class="cursor-pointer"></app-trash-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="flex w-full sm:pr-12 lg:pr-48 gap-0 sm:gap-4 items-center">
      <div class="mt-4 w-full sm:w-[49%]">
        <label for="terpenes"
               class="font-poppins font-medium text-[#202020] !text-[14px] mb-2 block">Terpenes</label>
        <div class="relative">
          <app-autocomplete [(keyword)]="terpene" (onChange)="setTerpene($event)" [options]="getTerpenesName()"
                            inputClass="!bg-white !py-[9.5px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px]"></app-autocomplete>
          <app-search-icon class="bg-white absolute top-3 right-2" [width]="20"
                           [height]="20"></app-search-icon>
        </div>
        <div class="flex flex-wrap py-4" *ngFor="let terpItem of data.terpenes; let indexF = index">
          <div class="flex items-center justify-center gap-2">
            <div class="rounded-full bg-[#81cb524f] p-2">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                <path
                  d="M40-440v-80h240v80H40Zm270-154-84-84 56-56 84 84-56 56Zm130-86v-240h80v240h-80Zm210 86-56-56 84-84 56 56-84 84Zm30 154v-80h240v80H680Zm-200 80q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm198 134-84-84 56-56 84 84-56 56Zm-396 0-56-56 84-84 56 56-84 84ZM440-40v-240h80v240h-80Z"/>
              </svg>
            </div>
            <p class="font-poppins text-[14px] font-medium">{{ terpItem }}</p>
            <app-trash-icon (click)="deleteItem('terpenes', indexF)" [width]="18" [height]="18" color="#a0a0a0" hover="tomato" class="cursor-pointer"></app-trash-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="flex w-full sm:pr-12 lg:pr-48 gap-4 items-center">
      <div class="mt-4 w-full">
        <label for="grow-information" class="font-poppins font-medium text-[#202020] text-[14px] mb-2 block">Grow
          Information</label>
        <ckeditor (click)="touched.push('growInformation')"
                  [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic', 'Underline', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'undo', 'redo' ] }"
                  [editor]="Editor" [(ngModel)]="data.grow_information" id="grow-information"
                  data="<p>Hello, world!</p>"></ckeditor>
        <p *ngIf="touched.includes('growInformation') && !data.grow_information"
           class="text-red-500 text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*1.5)]">
          Grow Information is required.</p>
      </div>
    </div>
    <div class="flex w-full sm:pr-12 lg:pr-48 overflow-auto gap-4 items-center">
      <div class="mt-4 w-full">
        <p class="font-poppins font-semibold text-[#202020] text-[14px]">Product Photos & Video</p>
        <p class="font-poppins font-normal my-2 text-[#898989] text-[14px]">Please provide at least three photos
          of your
          gift</p>
        <div class="flex flex-wrap gap-4">
          <div class="w-[140px] h-[180px] relative" *ngFor="let i of [0,1,2,3,4]; let track = index">
            <div class="absolute cursor-pointer top-1 right-1 w-6 h-6 flex items-center justify-center bg-white rounded-full">
              <app-trash-icon *ngIf="data.imagesPreview[i]" (click)="deleteImage(i)" [width]="18" [height]="18"
                              color="#a0a0a0" hover="tomato"
                              ></app-trash-icon>
            </div>
            <img *ngIf="data.imagesPreview[i]" (click)="selectImagePic(i)"
                 class="border-2 border-[#CCEABB] border-dashed rounded-xl"
                 [src]="data.imagesPreview[i]" alt="img1">
            <div *ngIf="!data.imagesPreview[i]" (click)="selectImagePic(i)"
                 class="border-2 border-green-native gap-2 flex overflow-hidden items-center rounded-lg py-8 px-8 h-full">
              <svg [class]="{'opacity-0': data.file1?.name}" id="vuesax_bold_gallery"
                   data-name="vuesax/bold/gallery"
                   xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <g id="gallery">
                  <path id="Vector"
                        d="M.442,1.65l-.017.017A5.156,5.156,0,0,1,0,0,5.118,5.118,0,0,0,.442,1.65Z"
                        transform="translate(1.708 14.192)" fill="#000"/>
                  <path id="Vector-2" data-name="Vector"
                        d="M3.967,1.983A1.983,1.983,0,1,1,1.983,0,1.983,1.983,0,0,1,3.967,1.983Z"
                        transform="translate(5.517 4.683)" fill="#000"/>
                  <path id="Vector-3" data-name="Vector"
                        d="M11.825,0H4.842A4.447,4.447,0,0,0,0,4.842v6.983a5.6,5.6,0,0,0,.467,2.367,4.439,4.439,0,0,0,4.375,2.475h6.983a4.447,4.447,0,0,0,4.842-4.842V4.842A4.447,4.447,0,0,0,11.825,0Zm3.483,8.75a1.858,1.858,0,0,0-2.35,0L9.492,11.725a1.858,1.858,0,0,1-2.35,0l-.283-.233a1.851,1.851,0,0,0-2.2-.117L1.542,13.467a4.444,4.444,0,0,1-.292-1.642V4.842c0-2.35,1.242-3.592,3.592-3.592h6.983c2.35,0,3.592,1.242,3.592,3.592v4Z"
                        transform="translate(1.667 1.667)" fill="#000"/>
                  <path id="Vector-4" data-name="Vector" d="M0,0H20V20H0Z" fill="none"
                        opacity="0"/>
                </g>
              </svg>
              <ng-icon [class]="{'opacity-0': data.file1?.name}" name="matPlus"
                       class="text-green4-native cursor-pointer text-lg z-10"></ng-icon>
              <p [class]="{'opacity-0': data.file1?.name}" class="text-green4-native text-[18px]">
                New</p>
            </div>
          </div>
        </div>
        <p class="font-poppins font-normal my-2 text-[#898989] text-[14px]">Main photo or Video</p>
      </div>
    </div>
    <input type="file" (change)="onChangePic()" id="inputChangePic" class="opacity-0 w-0 h-0" name="change-pic"
           accept="image/*" #inputChangePic>

    <div class="flex w-full flex-col sm:pr-12 lg:pr-48 gap-4 items-center" *ngIf="!['flower', 'seeds'].includes(data.category)">
      <div class="w-full flex-col gap-4 pt-4">
        <label for="initialCost" class="font-poppins font-medium text-[#202020] !text-[14px] mb-0 block">Initial
          Cost</label>
        <div class="relative w-full">
          <div class="absolute top-0 left-0 bw-1 rounded-l-lg z-20 p-[11px]">
            <app-price-icon [width]="18" [height]="18"></app-price-icon>
          </div>
          <app-om-input id="initialCost" color="white" #initialCost='ngModel' [(ngModel)]="data.initial_cost"
                        (keyuptext)="changePublishCost($event)"
                        inputClass="!bg-white !py-[9px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px] !pl-14"
                        placeholder="Initial cost" required="true"
                        class="w-[60%]">
          </app-om-input>
          <p *ngIf=" initialCost.invalid && initialCost.touched"
             class="text-red-500 text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*1.5)]">
            Initial cost is required.</p>
        </div>
        <span class="text-[12px]">Initial cost is required before taxes and a 20% commission.</span>
      </div>
      <div class="w-full flex-col gap-4 pt-4">
        <label for="initialCost" class="font-poppins font-medium text-[#202020] !text-[14px] mb-0 block">Publish
          Cost</label>
        <div class="relative w-full">
          <div class="absolute top-0 left-0 bw-1 rounded-l-lg z-20 p-[11px]">
            <app-price-icon [width]="18" [height]="18"></app-price-icon>
          </div>
          <app-om-input id="cost" color="white" [(ngModel)]="data.publish_cost" [readonly]="true"
                        inputClass="!bg-white !py-[9px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px] !pl-14"
                        placeholder="Publish cost"
                        class="w-[60%]">
          </app-om-input>
        </div>
      </div>
    </div>
    <div class="w-[170px] mt-2">
      <button
        class="px-4 !py-[10.5px] gap-2 flex items-center font-semibold text-xs bg-[#519935] text-white rounded-md shadow-sm"
        (click)="save()">
        Create Product
      </button>
    </div>
  </div>

  <app-dialog #changePic [title]="'Crop picture'"
              [classHeaderDefault]="'justify-center pt-5 pb-3 text-lg'"
              [classModal]="'sm:!min-w-[800px] sm:!w-[800px] bg-black/80 sm:bg-white text-white sm:text-black sm:relative'"
              classBody="">
    <div class="mx-[35px]">
      <image-cropper
        class="w-full !px-0"
        [resizeToWidth]="widthImage"
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="aspectRatio"
        format="png"
        output="base64"
        (imageCropped)="imageCropped($event)"
      ></image-cropper>
    </div>
    <div class="flex items-center justify-end gap-4 py-2 mx-[35px]">
      <button (click)="cropperReady()"
              class="flex justify-center w-[100px] !py-[6px] mb-2 h-fit gap-2 items-center font-Poppins font-normal bg-[#519935] text-white rounded-xl shadow-sm text-base">
        Save
      </button>
      <button (click)="closeCropper()"
              class="flex justify-center w-[100px] !py-[6px] border-2 border-[#DCDCDC] mb-2 h-fit gap-2 items-center font-Poppins font-normal bg-[#fff] text-[#202020] rounded-xl shadow-sm text-base">
        Discard
      </button>
    </div>
  </app-dialog>
  <app-dialog #shareGiftDialog title="Share Gift"
              [classHeaderDefault]="'justify-center pt-5 pb-3 text-lg'"
              [classModal]="'sm:!min-w-[400px] sm:!w-[500px] bg-black/80 sm:bg-white text-white sm:text-black sm:relative'"
              classBody="">
    <div class="mx-[35px] pb-12 flex flex-col gap-4" *ngIf="showUsers">
      <app-om-input [useIcon]="true" color="gray" [(ngModel)]="search" (ngModelChange)="getUsers()"
                    inputClass="!py-[10.5px] mt-1 !font-poppins !font-medium relative"
                    placeholder="Search" class="w-[100%]">
        <app-search-icon class="" color="#519935" [width]="18" [height]="18"/>
      </app-om-input>
      <label class="w-full items-start flex flex-col gap-4 mt-2 mb-2" *ngFor="let item of users" [for]="item.id">
        <div class="flex w-full justify-between gap-4 items-center cursor-pointer">
          <div class="flex items-center gap-4">
            <div
              class="border-2 border-[#a0a0a0] rounded-full w-[42px] h-[42px] flex items-center justify-center relative">
              <img class="w-[40px] h-[40px] rounded-full object-cover"
                   [src]="item.image_profile || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'">
              <span class="absolute w-[12px] h-[12px] rounded-full bottom-0 right-0 bg-[#d0d0d0]"></span>
            </div>
            <p class="text-[#3B566E] text-[16px] font-SF-Pro-Text">{{ item.name }} {{ item.lastname }}</p>
          </div>
          <label class="switch">
            <input type="checkbox" [(ngModel)]="shareUsers[item.id]" name="shareUsers{{item.id}}" [id]="item.id">
            <span class="sw-slider round"></span>
          </label>
        </div>
      </label>
      <button (click)="shareGift()" [disabled]="allowShare().length === 0"
              class="mt-4 sm:flex px-4 w-full !py-2 mb-2 h-fit gap-2 items-center disabled:bg-green4-native/80 justify-center font-semibold text-[16px] bg-[#519935] text-white rounded-full shadow-sm">
        Share
      </button>
    </div>
    <div class="mx-[35px] pb-12 flex flex-col gap-4" *ngIf="!showUsers">
      <div class="shadow p-6 rounded-xl bw-1 hover:border-green-native flex gap-4" (click)="showUsers = true"><img
        src="assets/icons-sax/users.svg" class="mr-4" alt="test"> Share with friends
      </div>
      <div class="shadow p-6 rounded-xl bw-1 hover:border-green-native flex gap-4" routerLink="/strain-finder">
        <app-og-marketplace [width]="24" color="#77838f"/>
        Go to Strain Finder
      </div>
      <div class="shadow p-6 rounded-xl bw-1 hover:border-green-native flex gap-4" routerLink="/profile/{{user.id}}">
        <app-menu-block-icon [width]="24" color="#77838f"/>
        Go to Profile
      </div>
    </div>
    <!--    <div class="flex items-center justify-end gap-4 py-2 mx-[35px]">-->
    <!--      <button (click)="updateAddress()"-->
    <!--              class="flex justify-center w-[100px] !py-[6px] mb-2 h-fit gap-2 items-center font-Poppins font-normal bg-[#519935] text-white rounded-xl shadow-sm text-base">-->
    <!--        Save-->
    <!--      </button>-->
    <!--      <button (click)="cancelUpdateAddress()"-->
    <!--              class="flex justify-center w-[100px] !py-[6px] border-2 border-[#DCDCDC] mb-2 h-fit gap-2 items-center font-Poppins font-normal bg-[#fff] text-[#202020] rounded-xl shadow-sm text-base">-->
    <!--        Discard-->
    <!--      </button>-->
    <!--    </div>-->
  </app-dialog>
</app-sidebar-layout>
