import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-reset-your-password',
  templateUrl: './reset-your-password.component.html',
  styleUrls: ['./reset-your-password.component.scss']
})
export class ResetYourPasswordComponent implements OnInit {
  http = inject(HttpClient);
  @Input() emailDefault: string = ''
  @Output() sendData = new EventEmitter<any>();
  @ViewChildren('emailField') elements: QueryList<ElementRef> | undefined;
  email: string = '';
  emailNotExist: boolean = false;

  ngOnInit() {
    this.email = this.emailDefault;
  }

  openExternalUrl() {
    window.open('https://omnigram.ca/founders-club/', '_blank');
  }
  continue() {
    this.markElementsAsTouched()
    if (this.email == '') return;
    this.emailNotExist = false;
    this.http.post('/auth/find-user-email', {email: this.email, username: 'forgot-password'}).subscribe({
      next: (response: any) => {
        const {email} = response
        this.emailNotExist = !email;
        if (email) {
          this.sendData.emit({
            email: this.email
          });
        }
      }
    });
  }

  markElementsAsTouched() {
    if (this.elements)
      this.elements.forEach((element: any) => {
        element.control.touched = true;

      });
  }
}
