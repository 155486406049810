import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {LocationService} from "../../../../services/location/location.service";

@Component({
  selector: 'app-enable-location',
  templateUrl: './enable-location.component.html',
  styleUrls: ['./enable-location.component.scss']
})
export class EnableLocationComponent {
  location: GeolocationPosition | null = null;

  constructor(private locationService: LocationService) {}

  @Output() sendData = new EventEmitter<any>();
  @Output() previousStep = new EventEmitter<any>();
  previous() {
    this.previousStep.emit();
  }
  sendDataC() {
    this.sendData.emit([]);
  }
  requestLocationPermission() {
    this.locationService.getLocation()
      .then((position) => {
        this.location = position;
      })
      .catch((error) => {
        console.error('Error getting location:', error);
      }).finally(() => {
        this.sendDataC()
    });
  }
}
