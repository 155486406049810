import {inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {UserInfoService} from "../user-info/user-info.service";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class GiftService {
  http = inject(HttpClient)

  constructor(private router: Router, private userInfo: UserInfoService) {
  }

  private isValidAddress = new BehaviorSubject<any>(null);
  currentAddress = this.isValidAddress.asObservable();

  changeAddress(data: any) {
    this.isValidAddress.next(data);
  }

  claimGift(data: any) {
    const delivery_address = this.userInfo.me().user.delivery_address;
    let array = (delivery_address?.address || '').split(' ');
    let country = array[array.length - 1];
    const address = {
      id: country.toLowerCase() === 'canada' ? ' ' : '',
      address: delivery_address?.address || '',
      lat: delivery_address?.lat || '',
      lng: delivery_address?.lng || '',
      isVerified: country.toLowerCase() === 'canada',
    }
    const claimData = {
      gifts: [
        {
          name: data.name,
          genetics: data.genetics,
          quantity: 1,
          price: data?.publish_cost || 0,
          total: data?.publish_cost || 0,
          id: data.id,
          images: data.images,
          pk: data.pk
        } as IProductCart,
      ],
      user: {
        id: this.userInfo.me().user.id,
        username: this.userInfo.me().user.username,
        name: (this.userInfo.me().user?.name || '') + ' ' + (this.userInfo.me().user?.lastname || ''),
        email: this.userInfo.me().user?.email,
        phoneNumber: (this.userInfo.me().user?.dial_code || '') + (this.userInfo.me().user?.phone || ''),
        avatar: '',
      },
      address: address,
      message: '',
      subtotal: data?.publish_cost || 0,
      taxFees: 0,
      delivery: 7.5,
    };

    localStorage.setItem('claimGift', JSON.stringify(claimData));
    this.router.navigateByUrl('/claim-gift');
  }

  gift(id: string) {
    return new Promise<void>((resolve, reject) => {
      this.http.get(`/merch/received/gift/${id}`, {})
        .subscribe({
          next: (response: any) => {
            const data = response?.statusmerchs || response
            data.map((row: any) => {
              row['products'].map((product: any) => {
                product['productData'] = JSON.parse(product['productData']);
              })
            })
            resolve(data);
          },
          error: (err) => {
            reject(err);
          }
        });
    });
  }
}
