<div class="slider relative" *ngIf="slides?.length > 0">
  <div>
    <div (click)="goToPrevious()" class="leftArrow !z-30  hidden sm:block">
      <span class="material-symbols-outlined text-[30px] bg-[#ffffff69] rounded-full">chevron_left</span>
    </div>
    <div (click)="goToNext()" class="rightArrow !z-30 hidden sm:block">
      <span class="material-symbols-outlined text-[30px] bg-[#ffffff69] rounded-full">chevron_right</span>
    </div>
  </div>
  <div class="slide relative">
    <div class="w-full rounded-2xl relative h-[170px] sm:h-[275px] bg-cover bg-center"
      [class]="{
      '!h-[150px]': isMini,
      }">
      <img [src]="getCurrentSlideUrl()" alt="" class="w-full h-full rounded-2xl object-cover">
      <div class="absolute rounded-2xl left-0 top-0 w-full h-full {{clsDiv}}"></div>
      <p class="absolute top-0 h-full px-10 flex items-center z-10 font-Monserrat font-bold text-[20px] leading-[26px] sm:text-[30px] sm:leading-[36px] text-white max-w-[446px]"
        [class]="{
        '!w-[336px]': isMini,
        '!leading-[1.3]': isMini,
        '!text-[16px]': isMini,
        }">
        {{ getCurrentSlideText() }}
      </p>
    </div>
    <button *ngIf="enableBtn" class="absolute right-4 bottom-4 sm:bottom-6 px-4 py-2 sm:px-5 sm:py-2 text-[14px] bg-[#519935] sm:text-[20px] text-white rounded-lg sm:rounded-[20px] font-bold"
     [class]="{
     '!text-[7px]': isMini,
     '!font-Montserrat': isMini,
     '!font-bold': isMini,
     '!rounded-full': isMini,
     '!bg-green4-native': isMini,
     '!px-2': isMini,
     '!py-1': isMini,
     '!text-xs': isMini,
     '!text-white': isMini,
     }">Find strains</button>
  </div>

  <div class="flex justify-center gap-3 absolute bottom-2 w-full">
    <div *ngFor="let i of slides; let indexOfelement=index;" class="text-green4-native sm:text-[30px] cursor-pointer" [class]="{
    'text-green4-native':currentIndex==indexOfelement,
    'text-white':currentIndex!=indexOfelement,
    }" (click)="goToSlide(indexOfelement)">
      <span class="relative inline-flex rounded-full h-3 w-3 bg-slate-200"
      [class]="{
      '!w-2': isMini,
      '!h-2': isMini,
      'bg-green-native': currentIndex === indexOfelement
      }"></span>
    </div>
  </div>
</div>
