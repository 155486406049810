<div class="mx-4 sm:mx-0">
  <app-om-input [useIcon]="true" color="white" [(ngModel)]="search" (change)="getUsers()"
                inputClass="rounded-full sm:rounded-md !bg-[#fff] !text-[#77838F] sm:!bg-white !py-[5.5px] shadow-md !font-poppins !font-medium relative !text-lg"
                placeholder="Search profile">
    <app-search-icon class="hidden sm:inline-block" color="#77838F" [width]="18" [height]="18"/>
    <app-search-icon class="inline-block sm:hidden" color="#519935" [width]="18" [height]="18"/>
  </app-om-input>
  <div>
    <p class="text-[#77838F] text-[14px] font-SF-Pro-Text mt-5 mb-0 sm:hidden">Friends</p>
    <app-carousel class="sm:hidden">
      <header>
      </header>
      <div class="w-[90px] mb-2" *ngFor="let item of users" >
        <div class="flex flex-col justify-center items-center cursor-pointer" (click)="sendDataB(item)">
          <div class="border-2 border-[#87D254] rounded-full w-[62px] h-[62px] flex items-center justify-center relative">
            <img class="w-[53px] h-[53px] rounded-full object-cover" [src]="item.image_profile || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'" alt="avatar">
            <span class="absolute w-[19px] h-[19px] rounded-full bottom-0 right-0 bg-[#80EA4E]"></span>
          </div>
          <p class="w-[65px] truncate text-[#3B566E] text-[16px] font-SF-Pro-Text">{{item.name}}</p>
        </div>
      </div>
    </app-carousel>
    <p class="text-[#77838F] text-[14px] font-SF-Pro-Text mt-[22px] mb-[9px]">Suggestions</p>
    <div>
      <div *ngFor="let item of users"  (click)="sendDataB(item)" class="flex items-center bg-white h-[82px] rounded-2xl mb-[16px] p-[14px] cursor-pointer shadow-[0px_3px_6px_#00000029]" [class]="{'!bg-gray-50': !item?.delivery_address?.streetName}">
        <img class="w-[54px] h-[54px] rounded-full object-cover" [src]="item.image_profile || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'" alt="avatar">
        <div class="ml-[14px]">
          <p class="text-[#1E2022] text-[14px] font-SF-Pro-Text font-medium">{{item.name}}</p>
          <p class="text-[#77838F] text-[14px] font-SF-Pro-Text font-medium">@{{item.username}}</p>
          <p class="text-[#519935] text-[12px] font-Montserrat" *ngIf="!item?.delivery_address?.streetName">This user's address is not verified.</p>
        </div>
      </div>
    </div>
  </div>
</div>
