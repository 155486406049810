import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  getLocation(): Promise<GeolocationPosition> {
    return new Promise((resolve, reject) => {
      try {
        if ('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition(
            (position) => resolve(position),
            (error) => {
              reject(error)
            }
          );
        } else {
          reject('Geolocation is not supported in this browser.');
        }
      } catch (e) {
        reject(e);
      }
    });
  }
}
