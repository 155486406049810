import {Component, ElementRef, inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import uitoolkit from "@zoom/videosdk-ui-toolkit";
// @ts-ignore
import * as interact from 'interactjs/dist/interact.js'
import {DeviceInfoService} from "../../../../services/device-info/device-info.service";
import {ChatService} from "../../../../services/chat/chat.service";
import {UserInfoService} from "../../../../services/user-info/user-info.service";
@Component({
  selector: 'app-zoom',
  templateUrl: './zoom.component.html',
  styleUrls: ['./zoom.component.scss']
})
export class ZoomComponent implements OnInit {
  @ViewChild('video', {static: false}) video!: ElementRef;
  cls ='';
  isJoin = false;
  isInCall = false;
  @Input() callInfo: any = null;
  @Input() type = 'video';
  @Input() userName = 'test';
  @Input() sessionName = 'video-session';
  @Input() features: any = [];
  private deviceInfoService = inject(DeviceInfoService);
  private chatService = inject(ChatService);
  private userInfo = inject(UserInfoService);
  ngOnInit() {
    console.log(
    this.type,
    this.userName,
    this.sessionName,
    this.features,
      this.callInfo,
    )
    setTimeout(() => {
      this.initSession()
    }, 200)
  }
  offSession() {
    this.isJoin = false;
    this.chatService.rejectCall(this.callInfo.iduser_to.toString(), this.callInfo.iduser_from.toString(), this.callInfo.inbox_id.toString(), this.callInfo.type.toString());
  }
  initSession() {
    try {
      if (
        !this.type ||
        !this.userName ||
        !this.sessionName
      ) return;

      try {
        uitoolkit.closeSession(this.video.nativeElement)
      } catch (e) {
        console.log(e)
      }
      this.cls = `!flex items-center z-50 justify-center bg-white border-2 p-4 rounded-2xl fixed bottom-0 right-0 ${this.type === 'video' ? '' : 'w-full'}`
      let config = {
        videoSDKJWT: this.deviceInfoService.generateSignature(
          'LcDeSt1oTaOTIFMFsOqlTQ', 'hZYoL39DFx84dxJPX0bOBj3UiOXIuCjvUqag', this.sessionName, 0, '', ''
        ),
        sessionName: this.sessionName,
        userName: this.userName,
        features: [...this.features, 'users']// 'share', 'chat', 'users', 'settings'
      }
      this.video.nativeElement.style.display = "block";
      this.video.nativeElement.style.width = this.deviceInfoService.detectMobile() ? '100%' : '700px';

      uitoolkit.joinSession(this.video.nativeElement, config);
      this.isJoin =  true;
      uitoolkit.onSessionClosed(this.offSession.bind(this))
      window.onbeforeunload = () => {
        this.closeSession()
        this.chatService.rejectCall(this.callInfo.iduser_to.toString(), this.callInfo.iduser_from.toString(), this.callInfo.inbox_id.toString(), this.callInfo.type.toString());
      }

      setTimeout(() => {
        interact(this.video.nativeElement)
          .resizable({
            // resize from all edges and corners
            edges: { left: true, right: true, bottom: true, top: true },

            listeners: {
              move (event: any) {
                let { x, y } = event.target.dataset

                // update the element's style
                event.target.style.width = event.rect.width + 'px'
                event.target.style.height = event.rect.height + 'px'

                // translate when resizing from top or left edges
                x += event.deltaRect.left
                y += event.deltaRect.top

                event.target.style.transform = `translate(${x}px, ${y}px)`

                event.target.dataset.x = x
                event.target.dataset.y = y
              }
            },
            modifiers: [
              // keep the edges inside the parent
              interact.modifiers.restrictEdges({
                outer: 'parent'
              }),

              // minimum size
              interact.modifiers.restrictSize({
                min: { width: 200, height: 50 },
                max: { width: 700, height: 800 },
              })
            ],

            inertia: true
          })
      }, 200)
    } catch(e) {
      console.log(e)
    }
  }

  closeSession() {
    try {
      uitoolkit.offSessionClosed(this.offSession.bind(this))
      if(!this.isJoin) return;
      if(this.video.nativeElement?.childNodes?.length)
        uitoolkit.closeSession(this.video.nativeElement);

      this.isInCall = false;
      this.isJoin = false;
      this.cls = '';
    } catch (e){
      console.log(e)
    }
  }
}
