  <p class="font-Montserrat font-semibold text-[17px] sm:text-[20px] ">Meet your Grower</p>
  <div class="mt-[25px] sm:mt-[15px]">
    <div class="flex gap-4 container-avatar">
      <img [src]="user?.image_profile||'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'" class="w-[75px] h-[75px] rounded-full object-cover" />
      <div class="flex flex-col justify-center sm:justify-between avatar-info sm:mt-[5px]">
        <p class="ml-[15px] sm:ml-[20px] text-[17px] sm:text-[20px]] font-semibold font-Montserrat leading-1 truncate max-w-[100px]">{{nameUser}}</p>
        <p class="ml-[15px] sm:ml-[20px] text-[14px] sm:text-[15px] text-[#00000099] font-Poppins leading-1 sm:mt-[-8px] hidden">Verified Grower</p>
        <div class="flex gap-2 mt-[10px] sm:mt-[10px]" *ngIf="user.username !== 'Info strain'">
          <span class="flex items-center justify-center text-[14px] font-Montserrat font-bold text-[#519935] border border-[#81CB52] w-[90px] h-[29px] rounded-full cursor-pointer" (click)="changeFollow()" >{{isFollowing?'Folllowing':'Follow'}}</span>
          <span class="flex items-center justify-center text-[14px] text-white font-Montserrat font-bold  bg-[#81CB52] w-[90px] h-[29px] rounded-full cursor-pointer" routerLink="/profile/{{user?.id}}">View</span>
        </div>
      </div>
    </div>
  </div>
