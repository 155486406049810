import {Injectable} from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toastStatus = new Subject<{ show: boolean, message: string }>();

  show(message: string) {
    this.toastStatus.next({show: true, message});
  }

  hide() {
    this.toastStatus.next({show: false, message: ''});
  }
}
