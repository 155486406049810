<div *ngIf="data.user">
  <div class="mt-[15px] ml-[15px] flex justify-between sm:block">
    <div class="flex gap-4 container-avatar">
      <img [src]="data.user.image_profile||'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'" class="w-[79px] h-[79px] rounded-full object-cover" alt="" />
      <div class="flex flex-col justify-center">
        <p class="text-[12px] sm:text-[18px] font-medium font-Montserrat text-[#202020]">{{ data.user.name }} {{data.user.lastname}}</p>
        <p class="text-[12px] sm:text-[16px] text-[#47464A] font-Poppins">{{ cat_interests[data.user.interests[0]] }}</p>
        <p class="flex items-center gap-2">
          <span class="text-[#202020] font-Poppins font-medium text-[14px]">{{data.qualification}}</span>
          <app-star-rating [disabled]="true" [defaultRating]="data.qualification" [styleLabel]="{'font-size':'16px', 'margin':'0px'}"></app-star-rating>
        </p>
      </div>
    </div>
  </div>
  <div class="sm:mx-6 mt-[15px]">
    <p class="text-[12px] sm:text-[18px] font-Poppins text-[#47464A]" [innerHTML]="data.review"></p>
  </div>
  <div class="mt-1 flex gap-2 overflow-auto mb-4 sm:mx-6" >
    <img *ngFor="let file of data.images" alt="" [src]="file" class="h-[90px] rounded">
  </div>
</div>

