import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SearchAllService {
  private http = inject(HttpClient)
  constructor() {
  }
  private search = new BehaviorSubject<any>(null);
  currentSearch = this.search.asObservable();

  changeSearch(data: any) {
    this.search.next(data);
  }

  getUsers(filters: {[key: string]: any}) {
    return this.http.get('/users/search', {params: filters});
  }
  getPost(filters: {[key: string]: any}) {
    return this.http.get('/posts-filter', {params: filters});
  }
  getProducts(filters: {[key: string]: any}) {
    return this.http.get('/products/general/filters', {params: filters});
  }
  getCourses(filters: {[key: string]: any}) {
    return this.http.get('/courses/search', {params: filters});
  }
  getFeatured(filters: {[key: string]: any}) {
    return this.http.get('/feature', {params: filters});
  }
}
