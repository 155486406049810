<app-sidebar-layout title="Communities" [useHeaderEdit]="true">
  <div class="p-4 w-full">
    <div class="flex pt-6 pb-4 items-center justify-between relative w-full gap-2">
      <div class="w-[160px] sm:w-[180px]">
        <app-om-select id="select-nav"
                       containerClass="!bg-white !py-[4.5px] sm:!py-[12px] !font-poppins !font-medium shadow-md !text-[16px]"
                       containerItemsClass="!max-h-[150px]"
                       itemsClass="nav-select-item !py-1 hover:!bg-white hover:!text-green4-native"
                       color="gray" [options]="categories" [(ngModel)]="type" required
                       (ngModelChange)="changeType('',true)"
                       #selectedNav="ngModel" [default]="type" placeholder="Filter"
        />
      </div>
      <div class="relative w-[calc(100%-20px)] sm:w-[calc(100%-360px)]">
        <app-om-input color="white" [(ngModel)]="search"
                      (ngModelChange)="onInputChange($event)"
                      inputClass="rounded-full placeholder:!text-sm sm:rounded-md !bg-[#fff] !text-[#77838F] sm:!bg-white !py-[5.5px] sm:!py-[10px] shadow-md !font-poppins !font-medium relative placeholder:!text-lg !text-lg"
                      placeholder="Search">
        </app-om-input>
        <app-search-icon class="absolute right-4 top-3 cursor-pointer" (click)="onInputChange('')" color="#77838F"
                         [width]="24" [height]="24"/>
      </div>
      <div class="w-fit sm:w-[200px]">
        <button
          class="p-1.5 w-full sm:px-4 sm:!py-[14px] gap-2 flex items-center font-semibold text-[14px] bg-[#519935] text-white rounded-full sm:rounded-md shadow-sm"
          routerLink="/add-community">
          <ng-icon name="matPlus" class="text-white cursor-pointer text-lg z-10"></ng-icon>
          <span class="hidden sm:flex">Create Community</span>
        </button>
      </div>
    </div>
    <app-communities-featured *ngIf="type==='' && search===''"></app-communities-featured>
    <div *ngIf="type==='' && search===''">
      <div class="flex gap-4 sm:py-4 mt-4 sm:mt-12 rounded-lg w-full overflow-auto ocultarScroll">
        <div class="pb-24 min-w-[200px] w-1/5 relative cursor-pointer" (click)="changeType('Networking')">
          <div
            class="bg-[url('assets/communities/categories/networking.png')] rotate-180 rounded-lg bg-cover bg-center w-full h-40"></div>
          <div
            class="absolute bottom-4 left-[calc(50%-64px)] rounded-2xl flex flex-col items-center justify-center gap-4 w-32 h-32 bg-[#E9F3FB]">
            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33">
              <path id="Shape"
                    d="M16.5,33A16.5,16.5,0,0,1,4.833,4.833,16.5,16.5,0,0,1,28.168,28.168,16.393,16.393,0,0,1,16.5,33Zm-1.443-8.375a26.94,26.94,0,0,0-3.156.344,9.02,9.02,0,0,0,3.156,4.589Zm3,.006v4.855A9.289,9.289,0,0,0,21.1,24.97,26.714,26.714,0,0,0,18.057,24.632Zm5.983,1.039A17.842,17.842,0,0,1,22.8,28.444a13.572,13.572,0,0,0,2.973-2.128A17.122,17.122,0,0,0,24.04,25.672Zm-15.079,0a17.021,17.021,0,0,0-1.729.644A13.545,13.545,0,0,0,10.2,28.444,17.756,17.756,0,0,1,8.96,25.672ZM25.465,18a29.19,29.19,0,0,1-.611,4.783,19.572,19.572,0,0,1,2.913,1.157A13.508,13.508,0,0,0,29.918,18ZM3.083,18a13.5,13.5,0,0,0,2.151,5.94,19.57,19.57,0,0,1,2.913-1.157A29.192,29.192,0,0,1,7.535,18H3.083Zm14.974,0v3.628a29.644,29.644,0,0,1,3.876.443A26.239,26.239,0,0,0,22.46,18Zm-7.518,0a26.233,26.233,0,0,0,.528,4.071,29.761,29.761,0,0,1,3.99-.448V18ZM27.286,8.381a20.416,20.416,0,0,1-2.632.987A28.473,28.473,0,0,1,25.465,15h4.453A13.458,13.458,0,0,0,27.286,8.381Zm-5.557,1.675a29.786,29.786,0,0,1-3.672.406V15h4.4a25.553,25.553,0,0,0-.732-4.945Zm-10.457,0A25.549,25.549,0,0,0,10.539,15h4.518V10.467A29.816,29.816,0,0,1,11.272,10.056ZM5.713,8.381h0A13.457,13.457,0,0,0,3.083,15H7.535a28.539,28.539,0,0,1,.811-5.632,20.488,20.488,0,0,1-2.632-.987Zm9.344-4.939a8.1,8.1,0,0,0-2.812,3.735,27.13,27.13,0,0,0,2.812.287Zm3,.072V7.457a27.09,27.09,0,0,0,2.7-.281A8.335,8.335,0,0,0,18.057,3.514ZM22.8,4.555a17,17,0,0,1,.935,1.958c.454-.136.9-.287,1.332-.45A13.537,13.537,0,0,0,22.8,4.555Zm-12.593,0A13.563,13.563,0,0,0,7.937,6.063c.419.159.867.311,1.332.45A17,17,0,0,1,10.2,4.555Z"
                    transform="translate(0 0)" fill="#50b5ff"/>
            </svg>
            <p class="font-light text-[#44444F] font-Poppins">Networking</p>
          </div>
        </div>
        <div class="pb-24 min-w-[200px] w-1/5 relative cursor-pointer" (click)="changeType('Education')">
          <div
            class="bg-[url('assets/communities/categories/education.png')] rounded-lg bg-cover bg-center w-full h-40"></div>
          <div
            class="absolute bottom-4 left-[calc(50%-64px)] rounded-2xl flex flex-col items-center justify-center gap-4 w-32 h-32 bg-[#EDF4E7]">
            <svg id="Component_icon_ic_Watch_Video" data-name="Component/icon/ic_Watch Video"
                 xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
              <g id="ic_Watch_Video" data-name="ic_Watch Video">
                <circle id="Oval" cx="18" cy="18" r="18" fill="none"/>
                <g id="Group_35" data-name="Group 35" transform="translate(1.5 3)">
                  <path id="Combined_Shape" data-name="Combined Shape"
                        d="M6,30a6.007,6.007,0,0,1-6-6V6A6.007,6.007,0,0,1,6,0H27a6.007,6.007,0,0,1,6,6V24a6.007,6.007,0,0,1-6,6ZM3,6V24a3,3,0,0,0,3,3H27a3,3,0,0,0,3-3V6a3,3,0,0,0-3-3H6A3,3,0,0,0,3,6Zm7.883,16.9a2.386,2.386,0,0,1-.382-1.3V8.394a2.393,2.393,0,0,1,3.692-2.011L24.406,12.99a2.394,2.394,0,0,1,0,4.021L14.192,23.617a2.392,2.392,0,0,1-3.309-.713ZM13.5,20.491,21.992,15,13.5,9.509Z"
                        fill="#82c43c"/>
                </g>
              </g>
            </svg>
            <p class="font-light text-[#44444F] font-Poppins">Education</p>
          </div>
        </div>
        <div class="pb-24 min-w-[200px] w-1/5 relative cursor-pointer" (click)="changeType('Partnerships')">
          <div
            class="bg-[url('assets/communities/categories/partnerships.png')] rounded-lg bg-cover bg-center w-full h-40"></div>
          <div
            class="absolute bottom-4 left-[calc(50%-64px)] rounded-2xl flex flex-col items-center justify-center gap-4 w-32 h-32 bg-[#FAEFE8]">
            <svg id="Working_at" data-name="Working at" xmlns="http://www.w3.org/2000/svg" width="36" height="36"
                 viewBox="0 0 36 36">
              <rect id="Rectangle_24" data-name="Rectangle 24" width="36" height="36" fill="none"/>
              <g id="Group_25" data-name="Group 25" transform="translate(1.5 4.5)">
                <path id="Combined_Shape" data-name="Combined Shape"
                      d="M4.5,28.5A4.505,4.505,0,0,1,0,24V9A4.505,4.505,0,0,1,4.5,4.5H9A4.505,4.505,0,0,1,13.5,0h6A4.505,4.505,0,0,1,24,4.5h4.5A4.505,4.505,0,0,1,33,9V24a4.505,4.505,0,0,1-4.5,4.5Zm24-3A1.5,1.5,0,0,0,30,24V9a1.5,1.5,0,0,0-1.5-1.5H27.75v18Zm-3.75,0V7.5H8.25v18ZM3,9V24a1.5,1.5,0,0,0,1.5,1.5h.75V7.5H4.5A1.5,1.5,0,0,0,3,9ZM21,4.5A1.5,1.5,0,0,0,19.5,3h-6A1.5,1.5,0,0,0,12,4.5Z"
                      fill="#ff974a"/>
              </g>
            </svg>
            <p class="font-light text-[#44444F] font-Poppins">Partnerships</p>
          </div>
        </div>
        <div class="pb-24 min-w-[200px] w-1/5 relative cursor-pointer" (click)="changeType('Events')">
          <div
            class="bg-[url('assets/communities/categories/events.png')] rounded-lg bg-cover bg-center w-full h-40"></div>
          <div
            class="absolute bottom-4 left-[calc(50%-64px)] rounded-2xl flex flex-col items-center justify-center gap-4 w-32 h-32 bg-[#FAEFF6]">
            <svg xmlns="http://www.w3.org/2000/svg" width="33.002" height="33.004" viewBox="0 0 33.002 33.004">
              <g id="Component_icon_ic_Starred" data-name="Component/icon/ic_Starred" transform="translate(-0.001 0)">
                <path id="Shape"
                      d="M25.5,33a1.489,1.489,0,0,1-.827-.256L16.5,27.3,8.334,32.748A1.491,1.491,0,0,1,7.506,33a1.517,1.517,0,0,1-1.145-.527,1.471,1.471,0,0,1-.345-1.189l1.394-9.757-6.97-6.97a1.493,1.493,0,0,1-.362-1.536,1.473,1.473,0,0,1,1.211-1.01l9.665-1.381L15.123.909a1.5,1.5,0,0,1,2.758,0l4.168,9.725,9.666,1.381a1.5,1.5,0,0,1,.848,2.546l-6.97,6.97,1.394,9.757a1.47,1.47,0,0,1-.344,1.189A1.518,1.518,0,0,1,25.5,33Zm-9-9a1.493,1.493,0,0,1,.832.252l6.208,4.139-1.025-7.179a1.5,1.5,0,0,1,.424-1.273l5.378-5.378-7.53-1.076a1.508,1.508,0,0,1-1.166-.894L16.5,5.308,13.38,12.591a1.508,1.508,0,0,1-1.166.894l-7.53,1.076,5.378,5.378a1.508,1.508,0,0,1,.425,1.273L9.461,28.391l6.209-4.139A1.493,1.493,0,0,1,16.5,24Z"
                      fill="#ff9ad5"/>
              </g>
            </svg>
            <p class="font-light text-[#44444F] font-Poppins">Events</p>
          </div>
        </div>
        <div class="pb-24 min-w-[200px] w-1/5 relative cursor-pointer" (click)="changeType('News')">
          <div
            class="bg-[url('assets/communities/categories/news.png')] rounded-lg bg-cover bg-center w-full h-40"></div>
          <div
            class="absolute bottom-4 left-[calc(50%-64px)] rounded-2xl flex flex-col items-center justify-center gap-4 w-32 h-32 bg-[#F5EFFB]">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
              <path id="Shape"
                    d="M24,30H6a6.007,6.007,0,0,1-6-6V6A6.007,6.007,0,0,1,6,0H24a6.007,6.007,0,0,1,6,6V24A6.006,6.006,0,0,1,24,30ZM6,3A3,3,0,0,0,3,6V24a3,3,0,0,0,3,3H24a3,3,0,0,0,3-3V6a3,3,0,0,0-3-3Zm9,21H7.5a1.5,1.5,0,0,1,0-3H15a1.5,1.5,0,0,1,0,3Zm7.5-7.5H7.5a1.5,1.5,0,0,1,0-3h15a1.5,1.5,0,1,1,0,3Zm0-7.5H7.5a1.5,1.5,0,0,1,0-3h15a1.5,1.5,0,1,1,0,3Z"
                    transform="translate(0 0)" fill="#a461d8"/>
            </svg>
            <p class="font-light text-[#44444F] font-Poppins">News</p>
          </div>
        </div>
      </div>
    </div>
    <app-communities-popular *ngIf="type==='' && search===''"></app-communities-popular>
    <div class="flex flex-col bg-white gap-4 sm:p-4 rounded-lg w-full overflow-auto ocultarScroll"
         [class]="{
        'mt-12':(type==='' && search==='')
      }"
    >
      <div class="flex flex-col gap-1 sm:gap-3">
        <p *ngIf="type==='' && search===''" class="font-semibold font-Montserrat text-[20px] sm:text-[18px]">All
          Communities</p>
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-4 gap-6 gap-y-4">
        <app-community-card *ngFor="let item of communities" [data]="item" class="block w-full"></app-community-card>
      </div>
      <app-not-found *ngIf="communities.length===0" message="No communities found :(" linkRouter=""
                     labelLink=""></app-not-found>
    </div>
  </div>
</app-sidebar-layout>
