<div #divGif class="relative h-[220px] overflow-auto w-full sm:w-full bg-white pb-4 pt-1 px-2 border rounded-2xl" (scroll)="endScroll()">
  <input class="sticky top-0 w-full border px-2 rounded-3xl" [(ngModel)]="query" placeholder="Search Tenor..." (input)="search()" />
  <div class="flex flex-wrap">
    <div class="w-1/3 sm:w-1/3 h-[140px] flex justify-center items-center" *ngFor="let gif of list">
      <img class="max-w-full max-h-full"  src="{{gif.media_formats.gif.url}}" alt="{{ gif.title }}" (click)="selectGif(gif)">
    </div>
  </div>
</div>
<div class="flex justify-end bg-white p-[10px]">
  <img src="https://www.gstatic.com/tenor/web/attribution/PB_tenor_logo_grey_horizontal.png" class="h-[15px]" alt="via tenor">
</div>
