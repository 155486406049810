import {Component, ElementRef, EventEmitter, inject, Input, NgZone, Output, ViewChild} from '@angular/core';
import {DialogComponent} from '../../general/dialog/dialog.component';
import {HttpClient} from "@angular/common/http";
import {DeviceInfoService} from "../../../services/device-info/device-info.service";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { DomSanitizer } from '@angular/platform-browser';
import {UserInfoService} from "../../../services/user-info/user-info.service";

@Component({
  selector: 'app-register-publication',
  templateUrl: './register-publication.component.html',
  styleUrls: ['./register-publication.component.scss']
})

export class RegisterPublicationComponent {
  @Input() containnerClass  = '';
  @Input() communityId  = '';
  @Input() visibleTitle  = false;
  @Output() savedPost: EventEmitter<any> = new EventEmitter();
  @ViewChild('emojiTextarea', { static: false }) emojiTextarea!: ElementRef<HTMLTextAreaElement>;
  @ViewChild(DialogComponent, { static: false }) dialogComponent: DialogComponent | undefined;
  @ViewChild('fileInput') fileInput: ElementRef | undefined;
  Editor = ClassicEditor;
  http = inject(HttpClient);
  files: { type: string, src: string }[] = [];
  imageChangedEvent: any = '';
  croppedImage: any = '';
  user: any = {image_profile: ''};
  errorSize=false;
  limit=5;
  visibleEmojis=false;
  visibleGif=false;
  loading=false;
  textPost='';
  titlePost='';
  type='news';
  fileAcceptType: string = this.type !== 'article' ? 'image/*,video/mp4' : 'image/*';
  showPost = true;
  openForm(){
    this.changeType('news');
    this.showPost = true;
    this.dialogComponent?.open();
  }
  changeType(type='article')
  {
    this.type=type;
    this.titlePost='';
    this.textPost='';
    this.files=[];
    this.errorSize=false;
    this.limit=type=='article'?1:5;
  }
  openFormFile(){
    this.changeType('news');
    this.showPost = true;
    this.dialogComponent?.open();
    this.fileInput?.nativeElement.click();
  }
  onFileSelected(event: any): void {
    this.errorSize=false;
    const files: FileList = event.target.files;
    this.handleFiles(files);
  }
  onDrop(event: any): void {
    event.preventDefault();
    this.errorSize=false;
    const files: FileList = event.dataTransfer.files;
    this.handleFiles(files);
  }

  onDragOver(event: any): void {
    event.preventDefault();
  }

  async handleFiles(files: FileList): Promise<void> {
    if(this.type=='article') this.files=[];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (this.files.length === this.limit) {
        return;
      }
      if(this.files[0]){
        if(this.files[0].type=='gif') this.removeImage(0)
      }

      const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
      if(file.size>maxSizeInBytes){
        this.imageChangedEvent='';
        this.errorSize=true;
        continue;
      }
      if(this.type=='article'){
        if (file.type.startsWith('image/')) this.imageChangedEvent = { target: { files: [file] } }
        return;
      }
      const readFile = (file1: File): Promise<string | undefined> => {
        return new Promise<string | undefined>((resolve) => {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            resolve(e.target.result as string | undefined);
          };
          reader.readAsDataURL(file1);
        });
      };
      if (file.type.startsWith('image/')) {
        const resp = await readFile(file);
        if (resp !== undefined) {
          this.files.push({ type: 'image', src: resp });
        }
      } else if (file.type.startsWith('video/')) {
        const resp = await readFile(file);
        if (resp !== undefined) {
          this.files.push({ type: 'video', src: resp });
        }
      }
    }

  }
  removeImage(index: number): void {
    this.files.splice(index, 1);
  }
  onClickOutside(){
    if(this.visibleEmojis)
    this.visibleEmojis=false;
  }
  onClickOutsideGif(){
    if(this.visibleGif)
    this.visibleGif=false;
  }
  addEmoji(emoji: any) {
    const textarea = this.emojiTextarea.nativeElement;
    const emojiText = emoji.emoji.native;
    const cursorPosIni = textarea.selectionStart;
    const cursorPosFin = textarea.selectionEnd;
    const ini = textarea.value.substring(0, cursorPosIni);
    const fin = textarea.value.substring(cursorPosFin);
    textarea.value = ini + emojiText + fin;
    const newPos = cursorPosIni + emojiText.length;
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.ngZone.run(() => {
          textarea.setSelectionRange(newPos, newPos);
          textarea.focus();
        });
      });
    });
    textarea.dispatchEvent(new Event('input'));
  }
  getGif(data: string) {
    this.visibleGif=false;
    this.files=[];
    this.files.push({ type: 'gif', src: data });

  }

  async save(data:any) {
    /*
    let data={
      title:this.titlePost,
      content:this.textPost,
      type:this.type,
      hashtacks:[],
    };}
    */
    console.log(data)

    if(data.type=='article'){
      if(data.title=='' || data.content=='' || data.files.length!=1) return;
    } else if(data.content=='' && !data.files.length) return;
    //if(1===1) return
    let formData: FormData = new FormData();
    formData.append('content', data.content);
    formData.append('type', data.type);
    formData.append('title', data.title);
    formData.append('settingsView', data.settingsView);
    formData.append('sendTo', Object.keys(data.sendTo)[0]);
    if(data.location)
      formData.append('location', JSON.stringify(data.location));
    if(this.communityId!='')
      formData.append('communityId', this.communityId);
    for (let item of data.hashtacks) formData.append(`hashtacks`, item);
    for (let item of data.categories) formData.append(`categories`, item);
    for (let item of data.tagPeople) formData.append(`tagPeople`, item);
    if(data.filtersByImage) for (let item of data.filtersByImage) formData.append(`filtersByImage`, item || '');
    for (let file of data.files) {
      const ext = file.type.split('/')[1];
      const name = this.randomName(10) + `imag${ext ? '.' + ext : '.png'}`;
      formData.append('images', file, name);
    }
    this.loading=true;
    //let data = await fetch('https://omnigram-api.camezit.mx/product/add-edit', requestOptions)
    this.http.post('/post/add', formData).subscribe({
      next: (response: any) => {
        const {success,post} = response
        console.log(post)
        if (success) {
          post.createDate=new Date(post.createDate).toISOString();
          this.savedPost.emit(post);
          this.dialogComponent?.closeB();
          this.showPost = false;
          this.loading=false;
        } else {
          alert("Error")
          this.loading=false;
        }
      },
      error: (err) => {
        console.log(err)
        //this.loading=false;
      }
    });
  }
  closeDialog(){
    this.dialogComponent?.closeB();
    this.showPost = false;
  }
  private base64toBlob(base64Data: string): Blob {
    let data=base64Data.split(',')
    const sliceSize = 512;
    const byteCharacters = atob(data[1]);
    const byteArrays: Uint8Array[] = [];
    const contentType = this.extractContentTypeFromBase64(base64Data);
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) byteNumbers[i] = slice.charCodeAt(i);
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }
  randomName(num:number){
    const characters= "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let result = "";
    let ch;
    while (result.length < num){
      ch = characters.charAt(Math.floor(Math.random() * charactersLength));
      if (!result.includes(ch)) result += ch;
    }
    return result;
  }
  private extractContentTypeFromBase64(base64Data: string): string {
    const match = base64Data.match(/^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    return match ? match[1] : '';
  }
  imageCropped(event: ImageCroppedEvent) {
    if (event.base64 != null) {
      this.croppedImage = event.base64;
    }
  }
  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    this.files=[];
    this.files.push({ type: 'image', src: this.croppedImage });
    this.croppedImage='';
    this.imageChangedEvent='';
  }
  cropperCancel() {
    this.croppedImage='';
    this.imageChangedEvent='';
  }
  constructor(private ngZone: NgZone, private deviceInfo: DeviceInfoService,private sanitizer: DomSanitizer,private userInfo: UserInfoService) {}
  ngOnInit(){
    this.user = this.userInfo.me()?.user || {image_profile:""};
    //this.changeType()
    //this.openForm()
    //setTimeout(() => {
    //  this.dialogComponent?.open();
    //}, 500)
  }

  protected readonly ClassicEditor = ClassicEditor;
}
