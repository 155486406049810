import { Component, HostListener, Input, OnInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
@Component({
  selector: 'app-om-button',
  templateUrl: './om-button.component.html',
  styleUrls: ['./om-button.component.scss']
})
export class OmButtonComponent implements OnInit {
  //@Input() padding: {movil:,desktop:''};
  @Input() fontSize={xs:'2.5',sm:'2.5'};
  @Input() height={xs:'3.5',sm:'5.5'};
  @Input() marginTop={xs:'0',sm:''};
  @Input() marginBottom={xs:'0',sm:'0'};
  @Input() iconClass: string = '';


  @Input() color: string='white';
  @Input() bgColor: string='#81CB52';
  @Input() classButton: string='';
  @Input() text: string='';
  @Input() iconPosition: 'left' | 'right'|'center' = 'left';
  @Input() stylesButton: { [klass: string]: any; }={};
  @Input() stylesIcon: { [klass: string]: any; }={};

  @ViewChild('container', { static: true }) container: ElementRef;
  constructor(private renderer: Renderer2, private el: ElementRef) {
    this.container= {} as ElementRef;
  }

  ngOnInit() {
    this.updateStyles();
  }
  private updateStyles() {
    const wh = window.innerWidth >= 640 ? '--w-height' :'--mw-height';
    const typeScreem = window.innerWidth >= 640 ? 'sm' : 'xs';
    const fontSize = `calc(var(${wh}) * ${this.fontSize[typeScreem]})`;
    const height = `calc(var(${wh}) * ${this.height[typeScreem]})`;
    const marginTop = `calc(var(${wh}) * ${this.marginTop[typeScreem]})`;
    const marginBottom = `calc(var(${wh}) * ${this.marginBottom[typeScreem]})`;
    this.container.nativeElement.style.color = this.color;
    this.container.nativeElement.style.backgroundColor = this.bgColor;
    this.container.nativeElement.style.fontSize = fontSize;
    this.container.nativeElement.style.height = height;
    this.container.nativeElement.style.marginTop = marginTop;
    this.container.nativeElement.style.marginBottom = marginBottom;
  }


  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.updateStyles();
  }
}
