<app-dialog title="">
  <div class="px-[40px] pb-[25px]" *ngIf="type!='' && idReview===''">
    <p class="font-Montserrat text-[20px] font-semibold my-2 z-30 absolute top-[10px]">Write a Review</p>
    <hr class="border border-[#DDDDDD] my-2 mb-8">
    <div *ngIf="type==='gift'" class="flex items-center gap-8 rounded-2xl sm:rounded-2xl bg-white  shadow-[0px_3px_6px_#00000029] px-[20px] p-[10px] ">
      <img [src]="data.products[0].productData.image" alt="" class="h-[120px]">
      <div>
        <p class="font-Montserrat font-semibold text-[20px]">{{data.products[0].productData.name}}</p>
        <p class="font-Montserrat font-light text-[14px]">By {{data['user_send'].name+' '+data['user_send'].lastname}}</p>
      </div>
    </div>
    <div *ngIf="type==='course'" class="flex items-center gap-[40px] sm:rounded-2xl bg-white  shadow-[0px_3px_6px_#00000029] px-[20px] p-[10px]">
      <img  alt="banner" [src]="data.images[0]" class="h-[140px]  rounded-2xl">
      <div>
        <p class="text-[14px] font-Poppins font-semibold">{{ data.name }}</p>
        <p class="text-[16px] font-Roboto text-[#92929D] flex gap-1 items-center my-[10px]">
          Coach: {{data.user.name}}
        </p>
      </div>
    </div>
    <p class="font-Montserrat text-[16px] font-semibold my-4 mt-4">Add rating *</p>
    <app-star-rating [(defaultRating)]="qualification" [styleLabel]="{'font-size':'35px'}" class="block my-[10px]"></app-star-rating>
    <p class="font-Montserrat text-[16px] font-semibold my-4 mt-4">Add photos</p>
    <input type="file" #fileInput (change)="onFileSelected($event)"
           accept="image/*" style="display:none">
    <div class="flex gap-2">
      <div class="max-w-[356px] h-[148px] relative" *ngFor="let item of files; let i = index">
        <img [src]="item.src" class="max-w-full max-h-full  object-contain rounded-lg" alt="">
        <span (click)="deleteFile(i)" class="absolute top-[10px] right-[10px] material-symbols-outlined bg-black text-white rounded-full p-[5px] cursor-pointer">delete</span>
      </div>
      <div *ngIf="files.length!=limit" (click)="fileInput.click()" class="bg-[#cceabb1a] border-[#CCEABB] border-[1px] w-[356px] h-[148px] flex justify-center items-center flex-col rounded-lg cursor-pointer">
          <span  class="w-[82px] h-[82px] flex justify-center items-center bg-white rounded-full">
            <svg xmlns="http://www.w3.org/2000/svg" width="33.959" height="30.361" viewBox="0 0 33.959 30.361">
              <path id="Vector" d="M29.328,18.217v4.554h4.631v3.036H29.328v4.554H26.241V25.807H21.61V22.771h4.631V18.217ZM29.341,0a1.52,1.52,0,0,1,1.531,1.507V15.181H27.785V3.036H3.087V24.287L18.523,9.108l4.631,4.554v4.295L18.523,13.4,7.451,24.289H18.523v3.036H1.531a1.546,1.546,0,0,1-1.083-.442A1.5,1.5,0,0,1,0,25.818V1.507A1.506,1.506,0,0,1,.452.445,1.557,1.557,0,0,1,1.531,0ZM9.262,6.072a3.114,3.114,0,0,1,2.183.889,3,3,0,0,1,0,4.294,3.124,3.124,0,0,1-4.366,0,3,3,0,0,1,0-4.294,3.114,3.114,0,0,1,2.183-.889Z" fill="#99c183"/>
            </svg>
          </span>
          <p class="text-[#99C183] mt-[10px]">Click here to upload</p>


      </div>
    </div>
    <p class="font-light font-Poppins text-sm text-[#77838F]" [class]="{'text-red-900':errorSize}">Size (5mb)</p>
    <p class="font-Montserrat text-[16px] font-semibold my-4 mt-4">Review *</p>
    <ckeditor
      class=""
      [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic', 'Underline', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'undo', 'redo' ] }"
      [editor]="Editor" [(ngModel)]="review" data=""></ckeditor>
    <div class="flex justify-center">
      <button (click)="save()" class="w-[90%] flex items-center mx-auto justify-center sm:w-full sm:h-[44px] sm:text-[20px] bg-[#81CB52] text-white sm:mt-[25px] rounded-xl">Add a review</button>
    </div>
  </div>
  <p *ngIf="idReview!=''" class="px-[10px] font-Montserrat text-[20px] font-semibold my-2 z-30 absolute top-[10px]">{{type==='gift' ? (data.products[0].productData.name) : data.name}} review</p>
  <app-review-card *ngIf="idReview!=''" [data]="reviewData" [cat_interests]="cat_interests"></app-review-card>
</app-dialog>
<div *ngIf="loading" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
  <div class="text-white text-center">
    <p class="text-xl font-bold">Saving</p>
  </div>
</div>
