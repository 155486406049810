import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject, Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import {DeviceInfoService} from "../../../../../services/device-info/device-info.service";
import {DialogComponent} from "../../../../general/dialog/dialog.component";
import {OmAlertService} from "../../../../../om-alert.service";

interface File2 {
  type: string;
  name: string;
  size: number;
  base64: string;
}

@Component({
  selector: 'app-media-add',
  templateUrl: './media-add.component.html',
  styleUrls: ['./media-add.component.scss']
})
export class MediaAddComponent implements OnInit {
  deviceInfoService = inject(DeviceInfoService);
  private alertService = inject(OmAlertService);
  @Input() noAction: boolean = false;
  @Input() filesParent: File[] = [];
  @Input() filtersByImage: string[] = [];
  @ViewChild("carousel", {static: true}) carousel: ElementRef | undefined;
  @ViewChild("file") file: ElementRef | null | undefined;
  @Output() mediaChange = new EventEmitter<File[]>();
  @Output() mediaSet = new EventEmitter<File[]>();
  @Output() filtersByImageChange = new EventEmitter<string[]>();
  @Input() filterMedia: boolean = true;
  @Output() filterMediaChange = new EventEmitter<boolean>();
  /*For Cropper*/
  @ViewChild('changePic', { static: false }) changePic!: DialogComponent;
  @ViewChild('inputChangePic') inputChangePic: ElementRef | undefined;
  imageChangedEvent: any = null;
  aspectRatio: number = 1
  widthImage: number = 1
  croppedImage: string | null = null;
  filters = [
    'normal',
    // 'nashville',
    // 'lofi',
    // 'xpro2',
    // 'earlybird',
    // 'hefe',
    // 'valencia',
    // 'inkwell',
    // 'moon',
    // 'juno',
    // 'ludwig',
    'xpro2',
    'amaro',
    'mayfair',
    'rise',
    'hudson',
    'sierra',
    'willow',
    'sutro',
    'toaster',
    'brannan',
    'inkwell',
    'walden',
    'hefe',
    'brooklyn',
    'stinson',
    'year1977',
  ]
  files: File[] = [];
  filesString: File2[] = [];
  touchStartX = 0;
  currentIndex = 0;

  constructor(private renderer: Renderer2) {
  }

  async ngOnInit() {
    setTimeout(() => {
      this.openInputOptions()
    });
    for (const f of this.filesParent) {
      const base64Data = await this.deviceInfoService.getFileToBase64(f);
      let f2 = {type: f.type, name: f.name, size: f.size, base64: base64Data}
      this.filesString = [...this.filesString, f2];
    }
  }
  openInputOptions(){
    if(this.noAction) return;

    this.file?.nativeElement.click();
  }
  getJSONImages() {
    return this.filesString.length;
  }

  async onMediaChange(newType: any) {
    const customConfig = {
      title: 'Can\'t continue',
      description: 'The maximum file size is 5mb!',
      containerClass: 'logout-dialog',
      closeOnPress: false,
      activeButtonCancel: false
    };

    if(newType.target?.files.length===0) return;
    for (const file of newType.target?.files) {
      if((5 * 1024 * 1024)<file.size){
        this.alertService.setCustomConfig(customConfig);
        this.alertService.showCustomDialog();
        this.alertService.getIsOKPressed().subscribe(isOK => {
          if (isOK === true) {
            this.alertService.closeDialog()
          }
        });
        return
      }
    }
    this.mediaChange.emit(newType.target?.files);
    for (const f of newType.target.files) {
      const base64Data = await this.deviceInfoService.getFileToBase64(f);
      let f2 = {type: f.type, name: f.name, size: f.size, base64: base64Data}
      this.filesString = [...this.filesString, f2];
    }
    setTimeout(() => {
      if (typeof this.carousel === 'undefined') return;
      this.renderer.listen(this.carousel.nativeElement, 'touchstart', (event) => this.onTouchStart(event));
      this.renderer.listen(this.carousel.nativeElement, 'touchmove', (event) => this.onTouchMove(event));
      this.renderer.listen(this.carousel.nativeElement, 'touchend', () => this.onTouchEnd());
    }, 500)
    this.files = [...this.files, ...newType.target?.files];
    this.filtersByImage = [...this.filtersByImage, 'normal'];
    this.filtersByImageChange.emit(this.filtersByImage);
    if (this.file?.nativeElement)
      this.file.nativeElement.value = ''
  }
  setFilter(val: string){
    this.filtersByImage[this.currentIndex] = val;
    this.filtersByImageChange.emit(this.filtersByImage);
  }

  onTouchStart(event: TouchEvent) {
    this.touchStartX = event.touches[0].clientX;
  }


  onTouchMove(event: TouchEvent) {
    const touchX = event.touches[0].clientX;
    const deltaX = touchX - this.touchStartX;
    if (deltaX > 50) {
      this.prevSlide();
      this.touchStartX = touchX;
    } else if (deltaX < -50) {
      this.nextSlide();
      this.touchStartX = touchX;
    }
  }
  deleteImg() {
    this.filesString.splice(this.currentIndex, 1);
    this.files.splice(this.currentIndex, 1);
    this.filtersByImage.splice(this.currentIndex, 1);
    this.goToSlide(this.currentIndex);
    this.mediaSet.emit(this.files)
  }
  goToSlide(index: number) {
    this.currentIndex = index;
    this.touchStartX = this.currentIndex * 400;
  }

  onTouchEnd() {
    // Puedes realizar acciones adicionales al finalizar el gesto táctil si es necesario
  }

  prevSlide() {
    this.currentIndex = (this.currentIndex - 1 + this.filesString.length) % this.filesString.length;
  }

  nextSlide() {
    this.currentIndex = (this.currentIndex + 1) % this.filesString.length;
  }
}
