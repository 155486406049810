import {Component, inject, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {FollowAndFriendService} from "../../../../services/follow-and-friend/follow-and-friend.service";
import {Router} from "@angular/router";
import {FriendsService} from "../../../../services/friends/friends.service";
import {HttpClient} from "@angular/common/http";
import {Subscription} from "rxjs";
import {SearchAllService} from "../../../../services/search-all/search-all.service";
import {UserInfoService} from "../../../../services/user-info/user-info.service";
import {ChatService} from "../../../../services/chat/chat.service";

@Component({
  selector: 'app-coaching-grow-courses',
  templateUrl: './coaching-grow-courses.component.html',
  styleUrls: ['./coaching-grow-courses.component.scss']
})
export class CoachingGrowCoursesComponent implements OnChanges, OnDestroy {
  private followAndFriends = inject(FollowAndFriendService);
  private userInfoService = inject(UserInfoService);
  private chatService = inject(ChatService);
  searchAllService = inject(SearchAllService);
  private router = inject(Router);
  private http = inject(HttpClient);
  @Input() type = '';
  @Input() classTitle: any[] = [];
  users: any[] = [];
  startCount = 4.6;

  constructor(private friends: FriendsService) {
  }


  async validIsFollowing(id: string) {
    let userIndex = this.users.findIndex((x: any) => x.id === id);
    this.users[userIndex].isFollowing = await this.followAndFriends.isFollowing(id);
  }

  async follow(id: string) {
    await this.followAndFriends.add(id);
    await this.followAndFriends.getFollowings(true);
    this.validIsFollowing(id);
  }

  async unfollow(id: string) {
    await this.followAndFriends.delete(id);
    await this.followAndFriends.getFollowings(true);
    this.validIsFollowing(id);
  }

  async goToMessages(id: string) {
    let user = await this.userInfoService.getById(id)
    this.chatService.changeUser(user)
    this.router.navigateByUrl('/messages')
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['type']) {
      this.users = [];
      this.getData();
    }
  }

  subscriptionData: Subscription | undefined;

  async getData() {
    let filters: any = {
      limit: 10,
      offset: this.users.length,
      type: this.type,
      sortKey:'courses_ranked',
      sort:'desc'
    }
    filters['coach'] = 1;
    this.users = await this.searchAllService.getUsers(filters).toPromise() as any;
    for (const index in this.users) {
      this.users[index]['isFollowing'] = await this.followAndFriends.isFollowing(this.users[index]['id']);
    }
  }

  ngOnDestroy() {
    this.subscriptionData?.unsubscribe();
  }
}
