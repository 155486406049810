import {Component, EventEmitter, inject, Output} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {FriendsService} from "../../../services/friends/friends.service";
import {UserInfoService} from "../../../services/user-info/user-info.service";

@Component({
  selector: 'app-gift-recipient',
  templateUrl: './gift-recipient.component.html',
  styleUrls: ['./gift-recipient.component.scss']
})
export class GiftRecipientComponent {
  private http = inject(HttpClient);
  search='';
  users:any =[];
  @Output() sendData = new EventEmitter<any>();
  ngOnInit() {
    window.scrollTo(0, 1);
    this.getUsers()
  }
  constructor(private friends: FriendsService,private userInfo: UserInfoService) {
  }
  sendDataB(user:any){
    const userD = { ...user };
    userD['avatar']=userD.image_profile
    let data={
      user:userD,
      //address:userD.address
      address:{
        id:"  ",
        streetNumber:user.delivery_address.streetNumber,
        streetName:user.delivery_address.streetName,
        apartmentOrUnit:user.delivery_address.apartmentOrUnit,
        postalCode:user.delivery_address.postalCode,
        city:user.delivery_address.city,
        province:user.delivery_address.province,
        country:user.delivery_address.country,
          isVerified:true
      },
    }
    //delete data.user.address
    this.sendData.emit(data);
  }
  async getUsers(){
    this.users = (await this.friends.getUsers()).filter((x:any)=>x.id!=this.userInfo.user.user.id);
  }

    protected readonly JSON = JSON;
}
