<app-card-mini class="block w-full" [data]="data"
               [enableFooter]="false"
               [enableStarRating]="true"
               [enableLike]="false"
               [enableType]="enableType"
               [enableThcCdb]="enableThcCdb"
               [enableColumRight]="true">
  <detail-more>
    <div class="number flex items-center justify-center sm:px-[10px]">
      <span *ngIf="enableActions" class="material-symbols-outlined text-[#519935] cursor-pointer" (click)="minusQuantity()">remove</span>
      <input type="number" class="flex items-center text-center  w-[35px] h-[26px] sm:w-[45px] sm:h-[36px]" [ngClass]="{'green':quantity>0, 'font-semibold':quantity>0, 'opacity-100':quantity>0}" [(ngModel)]="quantity" disabled/>
      <span *ngIf="enableActions" class="material-symbols-outlined text-[#519935] cursor-pointer" (click)="addQuantity()">add</span>
    </div>
  </detail-more>
</app-card-mini>
