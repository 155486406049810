<app-sidebar-layout title="Coaching" [useHeaderEdit]="true">
  <div class="w-full px-2 sm:px-4">
    <div class="w-full justify-between gap-2 items-center flex pt-4">
      <div class="w-[160px] sm:w-[180px]">
        <app-om-select id="select-nav"
                       containerClass="!bg-white !py-[4.5px] sm:!py-[12px] !font-poppins !font-medium shadow-md !text-[16px]"
                       containerItemsClass="!max-h-[150px]"
                       itemsClass="nav-select-item !py-1 hover:!bg-white hover:!text-green4-native"
                       color="gray" [options]="categories" [(ngModel)]="type" required
                       (ngModelChange)="changeType('',true)"
                       #selectedNav="ngModel" [default]="type" placeholder="Filter"
        />
      </div>
      <div class="relative w-[calc(100%-160px)] sm:w-[calc(100%-360px)]">
        <app-om-input color="white"
                      [(ngModel)]="search"
                      (ngModelChange)="onInputChange($event)"
                      inputClass="rounded-full placeholder:!text-sm sm:rounded-md !bg-[#fff] !text-[#77838F] sm:!bg-white !py-[5.5px] sm:!py-[10px] shadow-md !font-poppins !font-medium relative placeholder:!text-lg !text-lg"
                      placeholder="Search Courses">
        </app-om-input>
        <app-search-icon class="absolute right-4 top-2 cursor-pointer" (click)="onInputChange('')"
        color="#77838F"
        [width]="24" [height]="24"/>
      </div>
      <button
        class="hidden p-1.5 sm:px-4 sm:!py-[10.5px] gap-2 sm:flex items-center font-semibold text-xs bg-[#519935] text-white rounded-full sm:rounded-md shadow-sm"
        routerLink="/add-course">
        <ng-icon name="matPlus" class="text-white cursor-pointer text-lg z-10"></ng-icon>
        <span class="">New Course</span>
      </button>
      <button
        class="flex justify-center sm:hidden p-1.5 sm:px-4 sm:!py-[10.5px] gap-2 items-center font-semibold text-xs bg-[#519935] text-white rounded-full shadow-sm"
        (click)="openDialog()">
        <ng-icon name="matPlus" class="text-white cursor-pointer text-lg z-10"></ng-icon>
      </button>
    </div>
    <app-custom-carousel *ngIf="type!='all' && search===''" [enableBtn]="false" clsDiv="bg-gradient-to-r from-gray-800/30 to-gray-800/30"
                         text="Find the best courses and coaches to elevate your growing potential."
                         class="block my-[33px] sm:mt-[33px]"></app-custom-carousel>
    <app-coaching-categories *ngIf="type!='all' && search===''" [categories]="categories" [type]="type"
                             (updateType)="updateType($event)"></app-coaching-categories>
    <app-my-courses *ngIf="type!='all' && type==='mycourse' && search===''" class="block mt-[20px]"></app-my-courses>

    <app-coaching-feature *ngIf="type!='all' && type!='' && search===''" [title]="type==='mycourse'?'Recommended Courses':type+' Courses'"
                          [type]="type==='mycourse'?'':type" class="block mt-[20px]"></app-coaching-feature>

    <app-coaching-trending *ngIf="type!='all' && search===''" [type]="type==='mycourse'?'':type"
                           class="block mt-[20px]"></app-coaching-trending>
    <!--
    <app-coaching-feature [title]="'Grow Courses'" class="block mt-[20px]"></app-coaching-feature>
    -->
    <app-coaching-grow-courses *ngIf="type!='all' && search===''" class="block mt-[20px]"
                               [type]="type==='mycourse'?'':type"></app-coaching-grow-courses>
    <app-custom-carousel [enableBtn]="false" *ngIf="type!='all' && search===''" clsDiv="bg-gradient-to-r from-gray-800/30 to-gray-800/30"
                         text="Find the best courses and coaches to elevate your growing potential."
                         class="block my-[33px] sm:mt-[33px]"></app-custom-carousel>
    <app-coaching-feature *ngIf="type!='all' && type!=='mycourse' && search===''" [title]="'One on One Mentorships'"
                          [type]="'One on One Mentorship’s'" class="block mt-[20px]"></app-coaching-feature>
    <h1 class="text-[20px] sm:text-[25px] font-semibold font-Montserrat  mt-[20px]"
        [class]="{
          'hidden':search!='' || type==='all'
        }"
    >More </h1>
    <div class="sm:grid grid-cols-3 xl:grid-cols-4 gap-4  mt-[20px]">
      <app-card-course class="block w-full  mb-[25px]" *ngFor="let item of data" [course]="item"></app-card-course>
    </div>
  </div>
</app-sidebar-layout>
