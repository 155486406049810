import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'app-personal-detail-send',
  templateUrl: './personal-detail-send.component.html',
  styleUrls: ['./personal-detail-send.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PersonalDetailSendComponent),
      multi: true,
    },
  ],
})
export class PersonalDetailSendComponent {
  @Input() disabledInput=false;
  @Input() enableRequiredInput=false;
  @Input() classField='';
  @Input() enableInfoAddress=true;
  @Input() user:any={
    id:'',
    username:'',
    name:'',
    email:'',
    phoneNumber:'',
    avatar:'',
  };
  @Output() userChange = new EventEmitter<any>();

  onModelChange: (value: any) => void = () => {};

  writeValue(value: any): void {
    this.user = value;
  }
  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {}

  onUserChange(newValue: any) {
    this.user = newValue;
    this.onModelChange(this.user);
  }
}
