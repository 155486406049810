<div class="card-sharable-mini bg-white rounded-2xl border relative">
  <div class="cursor-pointer">
    <div *ngIf="!btndetailVisable && !isInfo"
         class="flex justify-center items-center rounded-full w-[25px] h-[25px] border-[1px] sm:border-[1px] border-[#81CB52] icon z-30 cursor-pointer"
         (click)="updateLike()"
         [class]="{
          'active':data.like
       }">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.858 19.32" class="w-[14px] h-[12px] cursor-pointer"
           *ngIf="!btndetailVisable">
        <g id="Like" transform="translate(0.479 0.052)">
          <path id="Shape"
                d="M18.327,1.531a5.225,5.225,0,0,0-7.391,0L9.929,2.538,8.922,1.531A5.226,5.226,0,0,0,1.531,8.922L2.538,9.929,9.929,17.32,17.32,9.929l1.007-1.007a5.225,5.225,0,0,0,0-7.391Z"
                transform="translate(0.521 0.948)"
                fill="none" stroke="#519935" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
        </g>
      </svg>
    </div>
    <div *ngIf="btndetailVisable && mine"
         class="flex justify-center items-center rounded-full w-[25px] h-[25px] border-[1px] sm:border-[1px] border-[#81CB52] icon z-30">
      <app-edit-icon [width]="14" [height]="14" color="#519935" routerLink="/product/{{data.id}}"></app-edit-icon>
    </div>
    <div class="flex items-center">
      <div class="w-[130px] h-[102px] sm:w-[150px] sm:h-[177px] relative flex justify-center items-center mx-[5px]">
        <img class="w-full h-full sm:max-h-[100px] object-contain	" alt="product" [src]="data.images[0]"
             (click)="openDialog()">
        <div *ngIf="data.publish_cost"
             class="absolute pr-[10px] w-full flex items-center justify-center -left-[5px] bottom-1">
          <p
            class="w-full text-[14px] text-center font-Montserrat font-medium text-white  bg-[#000000]/70 rounded-r-full px-6 py-2 capitalize">
            $ {{ data.publish_cost }}</p>
        </div>
      </div>
      <div class="p-4 px-2 pt-8 info sm:w-[calc(100%-150px)] h-full relative" (click)="openDialog()">
        <p class="text-[15px] font-Montserrat font-bold text-black w-full h-5 overflow-hidden">{{ data.name }}</p>
        <p class="text-[12px] font-Montserrat text-[#9A9291] my-1"
           [class]="{'hidden':isStrainFinder}">{{ data.genetics }}</p>
        <span *ngIf="enableType"
              class="text-[11px] font-Montserrat font-semibold text-black border border-[#70707069] rounded-full px-2 h-[18px] capitalize">{{ data.type }}</span>
        <div *ngIf="(!data.effects?.length && !data.terpenes?.length) || (!data.thc && !data.cdb)"
             [innerHTML]="getDescription()" class="w-full h-11 overflow-hidden"></div>
        <p *ngIf="enableThcCdb" class="flex gap-4 my-2">
          <span class="text-[12px] font-Montserrat text-[#9A9291] font-semibold">THC {{ data.thc }}%</span>
          <span class="text-[12px] font-Montserrat text-[#9A9291] font-semibold">CBD {{ data.cdb }}%</span>
        </p>
        <div class="effects hidden sm:flex items-center gap-4 w-[150px] overflow-auto ocultarScrollXNoVisible"
             [class]="{
            'hidden':!isStrainFinder,
            'flex':isStrainFinder
          }"
        >
          <p class="text-[12px] font-Montserrat text-[#9A9291] flex items-center gap-1" *ngFor="let item of data.effects">
            <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 -960 960 960" width="16">
              <path
                d="M40-440v-80h240v80H40Zm270-154-84-84 56-56 84 84-56 56Zm130-86v-240h80v240h-80Zm210 86-56-56 84-84 56 56-84 84Zm30 154v-80h240v80H680Zm-200 80q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm198 134-84-84 56-56 84 84-56 56Zm-396 0-56-56 84-84 56 56-84 84ZM440-40v-240h80v240h-80Z"></path>
            </svg>
            {{ item }}
          </p>
          <p class="text-[12px] font-Montserrat text-[#9A9291] flex items-center gap-1"
             *ngFor="let item of data.terpenes">
            <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 -960 960 960" width="16">
              <path
                d="M40-440v-80h240v80H40Zm270-154-84-84 56-56 84 84-56 56Zm130-86v-240h80v240h-80Zm210 86-56-56 84-84 56 56-84 84Zm30 154v-80h240v80H680Zm-200 80q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm198 134-84-84 56-56 84 84-56 56Zm-396 0-56-56 84-84 56 56-84 84ZM440-40v-240h80v240h-80Z"></path>
            </svg>
            {{ item }}
          </p>
          <div *ngIf="enableStarRating && !isInfo" class="flex sm:hidden items-center mt-1"
               [class]="{
            'hidden':isStrainFinder,
          }"
          >
            <p class="text-[12px] text-[#9A9291] font-SF-Pro-Text">{{ data.val_ranked }} </p>
            <app-star-rating [disabled]="true" [defaultRating]="data.val_ranked"></app-star-rating>
            <p class="text-[12px] text-[#9A9291] font-SF-Pro-Text">({{ data.count_reviews }})</p>
          </div>
        </div>
        <div *ngIf="enableStarRating && !isInfo" class="flex items-center mt-1">
          <p class="text-[12px] text-[#9A9291] font-SF-Pro-Text">{{ data.val_ranked }} </p>
          <app-star-rating [disabled]="true" [defaultRating]="data.val_ranked"></app-star-rating>
          <p class="text-[12px] text-[#9A9291] font-SF-Pro-Text">({{ data.count_reviews }})</p>
        </div>
      </div>
      <ng-content select="detail-more"></ng-content>
    </div>
    <hr class="sm:hidden" [class]="{
    'hidden':isStrainFinder
  }">
    <div class="effects flex sm:hidden items-center justify-center gap-4 my-2" [class]="{
    'hidden':isStrainFinder
  }">
      <p class="text-[12px] font-Montserrat text-[#9A9291] flex items-center gap-1" *ngFor="let item of data.effects">
        <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 -960 960 960" width="16">
          <path
            d="M40-440v-80h240v80H40Zm270-154-84-84 56-56 84 84-56 56Zm130-86v-240h80v240h-80Zm210 86-56-56 84-84 56 56-84 84Zm30 154v-80h240v80H680Zm-200 80q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm198 134-84-84 56-56 84 84-56 56Zm-396 0-56-56 84-84 56 56-84 84ZM440-40v-240h80v240h-80Z"></path>
        </svg>
        {{ item }}
      </p>
      <p class="text-[12px] font-Montserrat text-[#9A9291] flex items-center gap-1" *ngFor="let item of data.terpenes">
        <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 -960 960 960" width="16">
          <path
            d="M40-440v-80h240v80H40Zm270-154-84-84 56-56 84 84-56 56Zm130-86v-240h80v240h-80Zm210 86-56-56 84-84 56 56-84 84Zm30 154v-80h240v80H680Zm-200 80q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm198 134-84-84 56-56 84 84-56 56Zm-396 0-56-56 84-84 56 56-84 84ZM440-40v-240h80v240h-80Z"></path>
        </svg>
        {{ item }}
      </p>
      <div *ngIf="enableStarRating && !isInfo" class="flex sm:hidden items-center sm:mt-1"
           [class]="{
    'hidden':isStrainFinder
  }">
        <p class="text-[12px] text-[#9A9291] font-SF-Pro-Text">{{ data.val_reviews }} </p>
        <app-star-rating [disabled]="true" [defaultRating]="data.val_reviews"></app-star-rating>
        <p class="text-[12px] text-[#9A9291] font-SF-Pro-Text">({{ data.count_reviews }})</p>
      </div>
    </div>
  </div>
  <div *ngIf="enableFooter"
       class="footer flex gap-4 sm:gap-0 justify-center sm:justify-around sm:bg-[#000000C3] rounded-b-2xl sm:py-3"
       [class]="{
    'bg-[#000000C3]':isStrainFinder,
    'py-3':isStrainFinder
  }"
  >
    <ng-content select="footer"></ng-content>
    <app-om-button *ngIf="!useTemplateFooter" class="w-[145px] hidden sm:block" [text]="'View details'"
                   [bgColor]="'#ffffff00'"
                   [height]="{xs:'4',sm:'4'}" (click)="openDialog()"
                   [stylesButton]="{'font-size':'15px','padding':'0px','border':'1px solid #81cb52',}"></app-om-button>
    <div class="w-full flex items-center justify-between px-4" *ngIf="useTemplateFooter && btndetailVisable">
      <app-om-button class="w-[145px] " [text]="'View details'"
                     [bgColor]="'#ffffff00'"
                     [height]="{xs:'4',sm:'4'}" (click)="openDialog()"
                     [stylesButton]="{'font-size':'15px','padding':'0px','border':'1px solid #81cb52',}"></app-om-button>
      <div>
        <p class="font-semibold text-xs text-white">Available: {{ data.amount }}</p>
      </div>
    </div>
    <app-om-button *ngIf="useTemplateFooter && !mine" class="w-[145px] sm:w-[145px]"
                   [text]="data.publish_cost ? 'Add to Cart' : 'Claim Gift'"
                   [height]="{xs:'4',sm:'4'}"
                   [stylesButton]="{'font-size':'15px','padding':'0px'}"
                   (click)="claimGift()"
    ></app-om-button>
  </div>

</div>
<app-dialog [visable]="detailVisable" [classModal]="'sm:w-[1000px]'" (close)="closeDialog($event)"
            [useTemplateHeader]="true">
  <header class="pt-[var(--sat)]">
    <div class="flex sm:hidden items-center justify-between px-2 py-4">
      <span class="material-symbols-outlined text-[1.5rem] text-[#707070] cursor-pointer" (click)="pressCloseButton()">arrow_back</span>
      <p class="text-[#292D2B] text-[16px] font-poppins font-semibold">Strain Details</p>
      <img src="assets/icons-sax/shop2.svg" class="w-[16px] opacity-0 sm:opacity-100" alt=""/>
    </div>
    <div class="hidden sm:flex items-center justify-end mr-6 mt-4">
      <span class="material-symbols-outlined text-[30px] text-[#707070] cursor-pointer" (click)="pressCloseButton()">close</span>
    </div>
  </header>
  <div class="sm:max-h-[90vh] overflow-auto pb-12 px-[0px] sm:px-[60px]">
    <app-product-detail *ngIf="detailVisable" [data]="data"></app-product-detail>
  </div>
  <!--<app-detail *ngIf="detailVisable" [data]="data"></app-detail>-->
</app-dialog>
