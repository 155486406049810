import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsService {
  private apiKey: string = environment.gMapsApiKey ;
  private scriptLoaded = false;
  private scriptLoadingPromise: Promise<void> | null = null;

  constructor() { }

  loadGoogleMaps(): Promise<void> {
    if (this.scriptLoaded) {
      return Promise.resolve();
    }

    if (this.scriptLoadingPromise) {
      return this.scriptLoadingPromise;
    }

    this.scriptLoadingPromise = new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        this.scriptLoaded = true;
        resolve();
      };
      script.onerror = (error: any) => reject(error);
      document.body.appendChild(script);
    });

    return this.scriptLoadingPromise;
  }
}
