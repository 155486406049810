import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent {
  @Input() avatar: string = '';
  @Input() username: string = '';
  @Input() title: string = '';
  @Input() description: string = '';
  /*
  @Input() userDescription: string = '';
  */
  @Input() date: string = '';
  @Input() verifiedAccount: boolean = false;
  @Input() seeLabelsReactions: boolean = false;
  @Input() medios: IMedio[] = [];
  @Input() hashtags: string[] = [];
  @Input() numberReactions= {
    numberLikes:0,
    numberComments:0,
    numberShares:0,
    numberSaved:0,
  };

}

