<div *ngIf="isFocusedInput" class="flex gap-2 mx-8 mb-4">
  <div class="cursor-pointer rounded-xl border flex w-1/2 items-center justify-center h-[40px] font-semibold text-[#D34A2D] border-[#D34A2D]" (touchend)="clearForm()">Cancel</div>
  <div class="cursor-pointer rounded-xl border flex w-1/2 items-center justify-center h-[40px] font-semibold text-[#519935] border-[#519935]" (touchend)="saveComment()">Save</div>
</div>
<div class="w-full relative">
  <div class="flex justify-center sm:justify-normal sm:mx-14" *ngIf="visibleEmojis">
    <emoji-mart [darkMode]="false" [showPreview]="false" class="sm:absolute z-[50] sm:ml-[70px] sm:bottom-0" (emojiClick)="addEmoji($event)" [excludeElementClass]="['container-exclude']"  (appClickOutside)="onClickOutside(0)"></emoji-mart>
  </div>
  <div class="flex justify-center sm:justify-normal sm:mx-14" *ngIf="visibleGif">
    <app-gif-search  class="sm:absolute z-[50] sm:ml-[70px] sm:bottom-1 sm:w-[400px]" (sendGif)="getGif($event)" [excludeElementClass]="['container-exclude']" (appClickOutside)="onClickOutsideGif(0)"></app-gif-search>
  </div>
</div>
<div class="flex w-full px-3" (appClickOutside)="isFocused = false" (focus)="isFocused = true">
  <img [src]="user.image_profile || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'" class="w-[30px] h-[30px] rounded-full mr-3 mt-1" [class]="classAvatar" alt="">
  <div  #inputContainer class="relative w-full px-4 py-2 justify-between input bg-[#FAFAFA] rounded-[10px] font-poppins cursor-pointer"
        [class]="{
          'flex': (!comment.trim() && files.length==0 && !isFocused),
          'rounded-full': (!comment.trim() && files.length==0 && !isFocused),
          '!rounded-lg': isFocusedInput,
          '!block': isFocusedInput,
          '!h-[25vh]': isFocusedInput
        }"
  >
    <textarea #emojiTextarea [(ngModel)]="comment"
              class="input-comment
              container-exclude
              w-[calc(100%-110px)]
              focus-visible:outline-none
              input
              bg-[#FAFAFA]
              resize-none"
              [class]="{
                'w-full': (comment.trim() || files.length>0 || isFocused || isFocusedInput),
                'h-[calc(100%-20px)]':isFocusedInput,
              }"
              rows="1"
              placeholder="Write your comment..."
              (focus)="updateFocused(true)"
              (keydown.enter)="saveComment()"
    >
    </textarea>
    <div class="flex items-center gap-2 justify-end"
         [class]="{
                'justify-between': (comment.trim() || files.length>0 || isFocused),
              }"
    >
      <div class="flex items-center gap-2">
        <div class="icon cursor-pointer" *ngIf="files.length==0">
          <input type="file" #fileInput (change)="onFileSelected($event)" multiple style="display:none">
          <svg (click)="fileInput.click()" id="Insert_Image" data-name="Insert Image" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 21 20">
            <path id="Shape" d="M17.5,20H3.5A3.431,3.431,0,0,1,0,16.65V3.35A3.431,3.431,0,0,1,3.5,0h14A3.431,3.431,0,0,1,21,3.35v13.3A3.431,3.431,0,0,1,17.5,20ZM15.286,8.775l-4.714,4.253L15.961,19H17.5A2.431,2.431,0,0,0,20,16.65V13.071Zm-8.139,1.95h0L1.009,16.849A2.458,2.458,0,0,0,3.5,19H14.613L7.148,10.725ZM3.5,1A2.431,2.431,0,0,0,1,3.35v12.1l5.814-5.8a.5.5,0,0,1,.724.019L9.9,12.286l5.051-4.557a.5.5,0,0,1,.671,0L20,11.718V3.35A2.431,2.431,0,0,0,17.5,1Zm3.19,5.7A1.925,1.925,0,1,1,8.619,4.775,1.929,1.929,0,0,1,6.691,6.7Zm0-2.849a.925.925,0,1,0,.929.925A.928.928,0,0,0,6.691,3.85Z" transform="translate(0)" fill="#92929d"/>
          </svg>
        </div>
        <div class="icon cursor-pointer" >
          <svg class="container-exclude" width="20" height="20" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg" (click)="openEmoji(0)">
            <defs>
              <style>.cls-1{fill:#2e2f42;}</style>
            </defs>
            <g data-name="Layer 2" id="Layer_2">
              <g id="Export">
                <path class="cls-1" d="M64,3A61,61,0,1,1,3,64,61.06,61.06,0,0,1,64,3m0-3a64,64,0,1,0,64,64A64,64,0,0,0,64,0Z"/>
                <path class="cls-1" d="M85.57,61.33a1.5,1.5,0,0,1-1.5-1.5v-9a1.5,1.5,0,0,1,3,0v9A1.51,1.51,0,0,1,85.57,61.33Z"/>
                <path class="cls-1" d="M42.43,61.33a1.51,1.51,0,0,1-1.5-1.5v-9a1.5,1.5,0,0,1,3,0v9A1.5,1.5,0,0,1,42.43,61.33Z"/>
                <path class="cls-1" d="M64,94.81c-.93,0-1.87,0-2.84-.1a40.4,40.4,0,0,1-20.23-7.22A1.5,1.5,0,1,1,42.6,85a37.39,37.39,0,0,0,18.72,6.69A37.93,37.93,0,0,0,85.41,85a1.5,1.5,0,1,1,1.7,2.46A41.09,41.09,0,0,1,64,94.81Z"/>
              </g>
            </g>
          </svg>
        </div>
        <div class="icon cursor-pointer" *ngIf="files.length==0">
          <svg class="container-exclude"  (click)="openGif(0)" width="20" height="20" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><defs><style>.a{fill:none;stroke:#000000;stroke-linecap:round;stroke-linejoin:round;}</style></defs><path class="a" d="M40.5,42.5H7.5a2,2,0,0,1-2-2V7.5a2,2,0,0,1,2-2h33a2,2,0,0,1,2,2v33A2,2,0,0,1,40.5,42.5Z"/><line class="a" x1="25.21" y1="17.03" x2="25.21" y2="30.97"/><path class="a" d="M20.82,21.7A4.66,4.66,0,0,0,15.91,17,4.84,4.84,0,0,0,11.5,22V26.3A4.67,4.67,0,0,0,16.16,31h0a4.66,4.66,0,0,0,4.66-4.67H16.16"/><line class="a" x1="29.53" y1="17.03" x2="36.5" y2="17.03"/><line class="a" x1="29.53" y1="24" x2="34.06" y2="24"/><line class="a" x1="29.53" y1="17.03" x2="29.53" y2="30.97"/></svg>
        </div>
      </div>

      <div class="icon flex items-center cursor-pointer"
           [class]="{
           'hidden':isFocusedInput
           }"
      >
        <span class="material-symbols-outlined text-[#92929d]"  (click)="saveComment()">send</span>
      </div>
    </div>
  </div>
</div>
<div class="flex items-center gap-2 mx-4 mt-2 pl-[40px]">
  <div *ngFor="let item of files; let i = index" class="relative">
    <span class="material-symbols-outlined absolute top-1 right-1 text-[1rem] cursor-pointer rounded-full bg-white" (click)="removeImage(i)">close</span>
    <img *ngIf="item.type === 'image'" [src]="item.src" class="h-[180px] object-cover rounded-2xl" alt="">
    <video *ngIf="item.type === 'video'" class="h-[90px] object-cover rounded-2xl" controls>
      <source [src]="item.src" type="video/mp4">
      Tu navegador no soporta el tag de video.
    </video>
  </div>
</div>

