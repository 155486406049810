<div class="scroll-smooth !h-auto bg-white sm:bg-[#FAFAFA] mt-0 w-full">
  <div class="sticky top-0 z-20 w-full sm:inline-block">
    <app-navbar (toggleMenu)="toggleMenu()" [filterPost]="false" [filterSearch]="false" [bottomNavigation]="false" [useHeaderEdit]="useHeaderEdit">
      <navbar-title class="font-Poppins font-semibold text-[22px]">
        {{title}}
      </navbar-title>
    </app-navbar>
  </div>
  <div class="flex sm:bg-[#FAFAFA]">
    <div class="sm:block duration-700 ease-out fixed h-full bg-white top-0 sm:top-[101px] sm:h-[calc(100%-101px)] overflow-auto sm:!left-0"
         [class]="{
         '!fixed': deviceInfo.menuActive,
         '!left-[-100%]': !deviceInfo.menuActive,
         '!left-0': deviceInfo.menuActive,
         '!w-[100%]': deviceInfo.menuActive,
         '!z-40': deviceInfo.menuActive,
         }"
    >
      <app-side-bar  (toggleMenu)="toggleMenu()"></app-side-bar>
    </div>
    <div class="container-center w-full bg-white sm:bg-[#FAFAFA] sm:w-[calc(100%-616)] sm:ml-[230px] sm:mr-[336px] flex justify-center overflow-x-hidden pb-20">
      <div class="w-full sm:max-w-[800px]">
        <ng-content></ng-content>
      </div>
    </div>
    <div class="menu-right hidden sm:block fixed bg-white h-[calc(100%-101px)] overflow-auto right-0 top-[101px] ocultarScroll">
      <app-right-bar></app-right-bar>
    </div>
  </div>
  <app-dialog-alert class="absolute z-[70]"></app-dialog-alert>
</div>
