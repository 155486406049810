<div class="w-full min-h-full">
  <div class="sm:ml-4 flex px-4 sm:pr-4 sm:pl-2 items-center justify-between relative w-full pb-2 gap-4">
    <div class="relative w-[calc(100%-80px)] sm:w-[calc(100%-160px)]">
      <app-om-input [useIcon]="true" color="white" [(ngModel)]="search" (change)="getSharables()"
                    inputClass="rounded-full sm:rounded-md !bg-[#fff] !text-[#77838F] sm:!bg-white !py-[12px] shadow-md !font-poppins !font-medium relative !text-md"
                    placeholder="Search">
        <app-search-icon class="hidden sm:inline-block" color="#77838F" [width]="18" [height]="18"/>
        <app-search-icon class="inline-block sm:hidden" color="#519935" [width]="18" [height]="18"/>
      </app-om-input>
    </div>
    <div class="w-fit sm:w-[160px]">
      <button *ngIf="isMe()"
        class="p-1.5 sm:px-4 sm:!py-[10.5px] gap-2 flex items-center font-semibold text-xs bg-[#519935] text-white rounded-full sm:rounded-md shadow-sm"
        routerLink="/add-product">
        <ng-icon name="matPlus" class="text-white cursor-pointer text-lg z-10"></ng-icon>
        <span class="hidden sm:flex">New Product</span>
      </button>
    </div>
  </div>
  <div class="min-h-[500px]">
    <app-not-found message="No products found :(" [allowBackToHome]="false" *ngIf="!sharables?.length"/>
    <div class="flex flex-wrap justify-center	gap-4 mt-4" *ngIf="sharables.length>0">
      <app-card-mini class="block w-[95%] sm:w-[48%]" [useTemplateFooter]="!!username" [btndetailVisable]="true"
                     [mine]="isMe()" *ngFor="let item of sharables" [isStrainFinder]="true"
                     [data]="item"></app-card-mini>

    </div>
  </div>
</div>
