import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {
  @Input() message: string = ''
  @Input() classImg: any = '';
  @Input() allowBackToHome: boolean = true
  @Input() linkRouter: string='/home';
  @Input() labelLink: string='Back to Home';
}
