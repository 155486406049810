<div class="flex py-2  border-[#70707080] justify-between"
     [class]="{
      'border-t-[1px]':title==='Tag people',
      'px-4':title==='Tag people'
     }"
>
  <p class="text-[16px] text-[#1E2022]" [class]="classTitle">{{title}}</p>
  <app-chevron-right-icon *ngIf="!open" color="#707070" (click)="open = !open"/>
  <app-chevron-down-icon *ngIf="open" color="#707070" (click)="open = !open"/>
</div>
<div *ngIf="open">
  <div class="flex gap-4 items-center justify-between px-4 my-2" *ngFor="let user of friends;let indexUser =index">
    <div class="flex gap-2 items-center justify-between">
      <img
        [src]="user['image_profile'] || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'"
        class="top-5 left-10 w-8 z-20 rounded-full" alt="test">
      <p>{{getName(user)}}</p>
    </div>
    <label class="switch">
      <input type="checkbox" id="allow_marketing" (change)="onChangeSendToPeople()" [(ngModel)]="user['checked']" name="tagPeople{{user['id']}}">
      <span class="sw-slider round"></span>
    </label>
  </div>
</div>
