import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-card-my-couser',
  templateUrl: './card-my-couser.component.html',
  styleUrls: ['./card-my-couser.component.scss']
})
export class CardMyCouserComponent {
  totalTime='';
  totalVideos=0
  completeVideos=0
  progress='0'
  @Input() data:any={

  }
  async  getVideoDuration(url: string) {
    if(!url) return 0;
    const video = document.createElement('video');
    video.src = url;
    await new Promise<void>((resolve, reject) => {
      video.onloadedmetadata = () => resolve();
      video.onerror = error => reject(error);
      video.load();
    });
    return video.duration;
  }
  timeFormart(duration:number){
    const durationInSeconds = Math.floor(duration);
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = durationInSeconds % 60;
    let durationString = '';
    if (hours > 0) durationString += `${hours} hour${hours > 1 ? 's' : ''} `;
    if (minutes > 0) durationString += `${minutes} minute${minutes > 1 ? 's' : ''} `;
    if(seconds > 1)  durationString += `${seconds} second${seconds > 2 ? 's' : ''}`;
    return durationString;
  }
  async ngAfterViewInit() {
    this.progress=this.data.progress;
    let totalTime = 0
    for (const [index, row] of this.data.chapters.entries()) {
      let timeTopic = 0
      this.data.chapters[index]['is_open'] = false
      for (const [index1, rowClase] of row.classes.entries()) {
        this.data.chapters[index].classes[index1]['time'] = await this.getVideoDuration(rowClase.url)
        totalTime += this.data.chapters[index].classes[index1]['time'];
        timeTopic += this.data.chapters[index].classes[index1]['time'];
        this.totalVideos++;
        this.data.chapters[index].classes[index1]['time'] = this.timeFormart(this.data.chapters[index].classes[index1]['time'])
      }
      this.data.chapters[index]['time'] = this.timeFormart(timeTopic)
    }
    this.totalTime = this.timeFormart(totalTime);
  }
}
