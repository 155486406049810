<div class="relative" (click)="isOpen= true">
  <div class="flex items-center justify-end gap-1" *ngIf="merch['status'] === 'pendingPayment'">
    <p class="text-xs text-amber-400 w-28 font-semibold">{{ getStatus(merch['status']) }}</p>
    <div class="bg-amber-400 min-w-8 min-h-8 h-8 w-8 rounded-full flex items-center justify-center">
      <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="18px"
           fill="#fff">
        <path
          d="M160-240v-320 13-173 480Zm0-400h640v-80H160v80Zm307 480H160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v210q-36-25-78-37.5T716-560q-54 0-104 21t-88 59H160v240h283q3 21 9 41t15 39Zm320-25 28-28-75-75v-112h-40v128l87 87ZM721-80q-84 0-142.5-58T520-280q0-84 58.5-142T721-480q83 0 141 58.5T920-280q0 83-58 141.5T721-80Z"/>
      </svg>
    </div>
  </div>
  <div class="flex items-center justify-center gap-2"
       *ngIf="['rejected', 'undelivered'].includes(merch['status'])">
    <p class="text-xs text-red-600 font-semibold">{{ getStatus(merch['status']) }}</p>
    <div class="bg-red-600 min-w-8 min-h-8 h-8 w-8 rounded-full flex items-center justify-center">
      <app-close-icon color="#fff" [height]="18" [width]="18"/>
    </div>
  </div>
  <div class="flex items-center justify-center gap-2" *ngIf="['processing', 'paid'].includes(merch['status'])">
    <p class="text-xs text-blue-500 font-semibold">{{ getStatus(merch['status']) }}</p>
    <div class="bg-blue-500 min-w-8 min-h-8 h-8 w-8 rounded-full flex items-center justify-center">
      <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="18px"
           fill="#fff">
        <path
          d="M160-160v-80h110l-16-14q-52-46-73-105t-21-119q0-111 66.5-197.5T400-790v84q-72 26-116 88.5T240-478q0 45 17 87.5t53 78.5l10 10v-98h80v240H160Zm400-10v-84q72-26 116-88.5T720-482q0-45-17-87.5T650-648l-10-10v98h-80v-240h240v80H690l16 14q49 49 71.5 106.5T800-482q0 111-66.5 197.5T560-170Z"/>
      </svg>
    </div>
  </div>
  <div class="flex items-center justify-center gap-2" *ngIf="merch['status'] === 'onTheWay'">
    <p class="text-xs text-blue-800 font-semibold w-20">{{ getStatus(merch['status']) }}</p>
    <div class="bg-blue-800 min-w-8 min-h-8 h-8 w-8 rounded-full flex items-center justify-center">
      <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="18px"
           fill="#fff">
        <path
          d="M240-160q-50 0-85-35t-35-85H40v-440q0-33 23.5-56.5T120-800h560v160h120l120 160v200h-80q0 50-35 85t-85 35q-50 0-85-35t-35-85H360q0 50-35 85t-85 35Zm0-80q17 0 28.5-11.5T280-280q0-17-11.5-28.5T240-320q-17 0-28.5 11.5T200-280q0 17 11.5 28.5T240-240ZM120-360h32q17-18 39-29t49-11q27 0 49 11t39 29h272v-360H120v360Zm600 120q17 0 28.5-11.5T760-280q0-17-11.5-28.5T720-320q-17 0-28.5 11.5T680-280q0 17 11.5 28.5T720-240Zm-40-200h170l-90-120h-80v120ZM360-540Z"/>
      </svg>
    </div>
  </div>
  <div class="flex items-center justify-center gap-2" *ngIf="merch['status'] === 'received'">
    <p class="text-xs text-green-500 font-semibold">{{ getStatus(merch['status']) }}</p>
    <div class="bg-green-500 min-w-8 min-h-8 h-8 w-8 rounded-full flex items-center justify-center">
      <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="18px"
           fill="#fff">
        <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/>
      </svg>
    </div>
  </div>
  <div #changeStatusElement *ngIf="isOpen" class="shadow-2xl absolute -translate-x-1/2 z-50 w-44 -top-8 bg-white overflow-hidden rounded-2xl">
    <div *ngIf="type=='sended' && merch['status'] === 'onTheWay'" (click)="updateStatus('undelivered')" class="flex items-center justify-between border-b-2 px-4 py-1 hover:bg-slate-100 gap-2"
    >
      <p class="text-xs text-red-600 font-semibold">Undelivered</p>
      <div class="bg-red-600 min-w-8 min-h-8 h-8 w-8 rounded-full flex items-center justify-center">
        <app-close-icon color="#fff" [height]="18" [width]="18"/>
      </div>
    </div>
    <div *ngIf="type=='sended' && merch['status'] === 'paid'" (click)="updateStatus('on-the-way')" class="flex items-center justify-between border-b-2 px-4 py-1 hover:bg-slate-100 gap-2">
      <p class="text-xs text-blue-800 font-semibold w-20">On The Way</p>
      <div class="bg-blue-800 min-w-8 min-h-8 h-8 w-8 rounded-full flex items-center justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="18px"
             fill="#fff">
          <path
            d="M240-160q-50 0-85-35t-35-85H40v-440q0-33 23.5-56.5T120-800h560v160h120l120 160v200h-80q0 50-35 85t-85 35q-50 0-85-35t-35-85H360q0 50-35 85t-85 35Zm0-80q17 0 28.5-11.5T280-280q0-17-11.5-28.5T240-320q-17 0-28.5 11.5T200-280q0 17 11.5 28.5T240-240ZM120-360h32q17-18 39-29t49-11q27 0 49 11t39 29h272v-360H120v360Zm600 120q17 0 28.5-11.5T760-280q0-17-11.5-28.5T720-320q-17 0-28.5 11.5T680-280q0 17 11.5 28.5T720-240Zm-40-200h170l-90-120h-80v120ZM360-540Z"/>
        </svg>
      </div>
    </div>
    <div *ngIf="type=='received' && merch['status'] === 'onTheWay'" (click)="updateStatus('received')" class="flex items-center justify-between border-b-2 px-4 py-1 hover:bg-slate-100 gap-2">
      <p class="text-xs text-green-500 font-semibold">Received</p>
      <div class="bg-green-500 min-w-8 min-h-8 h-8 w-8 rounded-full flex items-center justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="18px"
             fill="#fff">
          <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/>
        </svg>
      </div>
    </div>
    <div class="py-2 px-4 font-Montserrat font-sm" *ngIf="type == 'received' && merch['status'] === 'paid'">
      Shipment pending, please wait
    </div>
<!--    <div class="py-2 px-4 font-Montserrat font-sm" *ngIf="type == 'sended' && merch['status'] === 'paid'">-->
<!--      Mailing of envelopes in process, please wait-->
<!--    </div>-->
  </div>
</div>
