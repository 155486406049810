  <div class="relative">
    <input #searchInput id="search-input" class="pl-4 w-[50%] mt-[12px] h-[40px] border focus:outline-none border-[rgba(15,13,35,0.2)] focus:border-[#519935] rounded-[6px] text-[#07122E] font-DM-Sans" type="text" placeholder="Buscar ubicación..." />
    <div #mapContainer class="w-full h-[300px]"></div>
    <span class="material-symbols-outlined cursor-pointer absolute text-[#519935] bottom-[10px] left-[10px] bg-white rounded-lg text-[35px] w-[40px] h-[40px] flex items-center justify-center" (click)="locateUser()">explore</span>
    <div class="absolute bottom-2 right-2 flex gap-4">
      <button (click)="cancel()" class="text-[15px] font-Montserrat font-poppins font-semibold sm:text-[12px] bg-white text-[#519935] bw-1 border-[#519935] rounded-xl px-4 py-3 cursor-pointer">
        Cancel
      </button>
      <button (click)="acceptSave()" class="text-[15px] font-Montserrat font-poppins font-semibold sm:text-[12px] text-white bg-[#519935] bw-1 border-[#519935] rounded-xl px-4 py-3 cursor-pointer">
        Confirm
      </button>
    </div>
  </div>
