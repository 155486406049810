import {Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {map} from "rxjs/operators";
import {Observable, Subscription, timer} from "rxjs";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-story-viewer',
  templateUrl: './story-viewer.component.html',
  styleUrls: ['./story-viewer.component.scss']
})
export class StoryViewerComponent implements OnInit, OnDestroy {
  @Input() showView = true;
  @Output() showViewChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  private sanitizer  = inject(DomSanitizer)
  @Input() objStories : any [] = [];
  stories: any[] = [];
  currentStory: any = '';
  progress$: Observable<number> | undefined;
  private storySub?: Subscription;

  ngOnInit() {
    this.stories = [...this.stories,  ...this.objStories.flatMap(os =>  os.stories )];
    console.log(this.stories);
    const storyTimer$ = timer(0, 3000);

    this.storySub = storyTimer$.pipe(
      map(tick => this.stories[tick % this.stories.length])
    ).subscribe(story => {
      let newStory: any  = {
        avatar: "",
        type: "",
        image: "",
        content: "",
        username: "",
      }
      let info = this.objStories.find(x => x.stories.find((y: any) => y.pk === story.pk))
      newStory.avatar = info?.user?.image_profile
      newStory.type = story?.storyType
      //newStory.content = story?.storyContent
      newStory.image = story?.storyUrls?.length ? story.storyUrls[0] : ''
      if(newStory.image) {
        let contentTemp = story?.storyContent;
        contentTemp = contentTemp.replace(/rounded-2xl "/g, "\" style=\"background-image:url('"+newStory.image+"')\"");
        newStory.content = this.sanitizer.bypassSecurityTrustHtml(contentTemp);
        console.log(newStory.content)
      } else {
        newStory.content = story?.storyContent;
      }
      newStory.username = info?.user?.name || info?.user?.username
      console.log(newStory)
      this.currentStory = newStory

    });

    this.progress$ = timer(0, 30).pipe(map(t => (t % 100) + 1));
  }
  close() {
    this.showViewChange.emit(false);
  }
  ngOnDestroy() {
    if (this.storySub) {
      this.storySub.unsubscribe();
    }
  }
}
