import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {UserInfoService} from "../../../../../services/user-info/user-info.service";

@Component({
  selector: 'app-post-settings',
  templateUrl: './post-settings.component.html',
  styleUrls: ['./post-settings.component.scss']
})
export class PostSettingsComponent implements OnInit {
  userInfoService = inject(UserInfoService);
  user: {[key: string]: any} = {};
  @Input() sendTo: {[key: string]: any} = {};
  @Output() sendToChange  = new EventEmitter<{[key: string]: any}>();
  @Input() settingsView: string = 'public_share';
  @Output() settingsViewChange = new EventEmitter<string>();
  ngOnInit() {
    this.user = this.userInfoService.me()?.user;
    this.sendTo[this.user['id']] = true;
  }
  getName(){
    return this.userInfoService.getName();
  }
  onSettingsViewChange(){
    this.settingsViewChange.emit(this.settingsView);
  }
  onSendToChange(){
    this.sendToChange.emit(this.sendToChange);
  }

  protected readonly JSON = JSON;
}
