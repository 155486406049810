import {Component, inject, HostListener, ViewChild, ElementRef} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import Swal from 'sweetalert2'
import {GoogleLoginProvider, SocialAuthService} from "@abacritt/angularx-social-login";
import {UserInfoService} from "../../../services/user-info/user-info.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  authService = inject(AuthService);
  userInfoService = inject(UserInfoService);
  router = inject(Router);
  loginForm: ILogin = {
    email: '',
    password: '',
    remember: false
  }
  wHeight = 0;
  isMobile: boolean;

  openExternalUrl() {
    window.open('https://omnigram.ca/founders-club/', '_blank');
  }

  ngOnInit() {
    this.socialAuthService.authState.subscribe((user) => {
      this.authService.loginGoogle(user);
      this.router.navigateByUrl('/home')
    });
    if(localStorage.getItem("infoLogin")){
      let infoLogin = JSON.parse(localStorage.getItem("infoLogin") || "{}");
      this.loginForm.email = infoLogin.email;
      this.loginForm.password = infoLogin.password;
      this.loginForm.remember = true;
    }
    if(this.isMobile){
      this.wHeight = window.innerHeight;
    }else{
      if(window.innerWidth>window.innerHeight){
        this.wHeight = (window.innerWidth-((window.innerWidth-window.innerHeight)*1.1));
      }
      else {
        this.wHeight = window.innerHeight;
      }
    }
  }

  constructor(private socialAuthService: SocialAuthService) {
    this.isMobile = this.detectMobile();
  }
  private detectMobile(): boolean {
    const userAgent = window.navigator.userAgent.toLowerCase();
    // Comprueba si la cadena de agente de usuario contiene palabras clave comunes de dispositivos móviles
    return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
  }

  login() {
    if(!this.loginForm) {
      return;
    }
    const { email, password } = this.loginForm;
    this.authService.login(email, password,false)
      .subscribe({
        next: () => {
          if(this.loginForm.remember){
            localStorage.setItem("infoLogin", JSON.stringify(this.loginForm));
          } else {
            localStorage.removeItem("infoLogin");
          }
          location.replace("/home");
        },
        error: (error) => {
          Swal.fire({
            icon: 'error',
            title: 'Sing in failed',
            text: 'Email or password is incorrect',
            customClass: {
              container: 'dark-swal',
              title:'swal2-title-error'
            },
            width:'300px',
            confirmButtonColor:'#373737'
          })
        }
      })
  }
  loginWithGoogle(): void {
    this.socialAuthService.authState.subscribe((user) => {
      this.authService.loginGoogle(user);
      this.router.navigateByUrl('/home')
    });
  }
}
