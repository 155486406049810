import {Component, ElementRef, inject, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {UserInfoService} from "../../../services/user-info/user-info.service";
import {CatalogsService} from "../../../services/catalogs/catalogs.service";
import {DeviceInfoService} from "../../../services/device-info/device-info.service";
import {DialogComponent} from "../../general/dialog/dialog.component";
import {FollowAndFriendService} from "../../../services/follow-and-friend/follow-and-friend.service";
import {SearchAllService} from "../../../services/search-all/search-all.service";

@Component({
  selector: 'app-new-product',
  templateUrl: './new-product.component.html',
  styleUrls: ['./new-product.component.scss']
})
export class NewProductComponent implements OnInit {
  http = inject(HttpClient);
  router = inject(Router);
  private deviceInfo = inject(DeviceInfoService);
  private searchAllService = inject(SearchAllService);
  private catalogService = inject(CatalogsService);
  private userInfo = inject(UserInfoService);
  private route = inject(ActivatedRoute);
  index = 0;
  isLoading = false;
  touched: any[] = [];
  @ViewChildren('categorySelect, typeSelect,selectAmount,amountFriends,amountGifts, initialCost') elements: QueryList<ElementRef> | undefined;
  @ViewChild('changePic', {static: false}) changePic!: DialogComponent;
  @ViewChild('inputChangePic') inputChangePic: ElementRef | undefined;
  @ViewChild('shareGiftDialog', {static: false}) shareGiftDialog!: DialogComponent;
  user: any = {};
  data: any = {
    name: '',
    category: '',
    type: '',
    description: '',
    thc: 0,
    cdb: 0,
    genetics: 'Ice Cream Cake',
    amount: '',
    amount_friends: null,
    amount_gifts: null,
    initial_cost: null,
    publish_cost: null,
    flavors: [],
    effects: [],
    terpenes: [],
    grow_information: '',
    images: [],
    imagesCatalog: [],
    file1: '',
    file2: '',
    file3: '',
    file4: '',
    file5: '',
    imagesPreview: [],
    imagesFiles: [],
  };
  selected = 'flower'
  terpene = ''
  effect = ''
  flavor = ''
  Editor = ClassicEditor;
  imageChangedEvent: any = null;
  aspectRatio: number = 5 / 6
  widthImage: number = 300
  croppedImage: string | null = null;
  category: { label: string, value: string }[] = [
    {label: 'Flower', value: 'flower'},
    {label: 'CBD', value: 'cdb'},
    {label: 'Edibles', value: 'edibles'},
    {label: 'Extracts', value: 'extracts'},
    {label: 'Clones', value: 'clones'},
    {label: 'Vapes', value: 'vapes'},
    {label: 'Seeds', value: 'seeds'},
  ]
  types: { label: string, value: string }[] = [
    {label: 'Sativa', value: 'sativa'},
    {label: 'Hybrid', value: 'hybrid'},
    {label: 'Indica', value: 'indica'},
    {label: 'CBD', value: 'cbd'},
  ]
  showUsers = false;

  users: any = [];
  shareUsers: any = [];
  genetics: { [key: string]: any }[] = [
    {label: 'Wedding Cake', value: 'Wedding Cake'},
    {label: 'Triangle Mints', value: 'Triangle Mints'},
    {label: 'Ice Cream Cake', value: 'Ice Cream Cake'},
    {label: 'Wedding Crasher', value: 'Wedding Crasher'},
    {label: 'Animal Mints', value: 'Animal Mints'},
  ]
  flavors: { [key: string]: any }[] = [
    {label: 'Vanilla', value: 'Vanilla'},
    {label: 'Pepper', value: 'Pepper'},
    {label: 'Sweet', value: 'Sweet'},
    {label: 'Chocolate', value: 'Chocolate'},
  ]
  effects: { [key: string]: any }[] = [
    {label: 'Relaxed', value: 'Relaxed'},
    {label: 'Aroused', value: 'Aroused'},
    {label: 'Hungry', value: 'Hungry'},
    {label: 'Dizzy', value: 'Dizzy'},
    {label: 'Anxious', value: 'Anxious'},
    {label: 'Paranoid', value: 'Paranoid'},
  ]
  terpenes: { [key: string]: any }[] = [
    {label: 'Caryophyllene', value: 'Caryophyllene'},
    {label: 'Limonene', value: 'Limonene'},
    {label: 'Myrcene', value: 'Myrcene'},
  ]
  amounts: { label: string, value: string }[] = [
    {label: '1 pinch', value: '1'},
    {label: '2 pinch', value: '2'},
    {label: '3 pinch', value: '3'},
    {label: '4 pinch', value: '4'},
    {label: '5 pinch', value: '5'},
  ]
  id = "";
  product = "";
  productEdit: any = {};
  products: any[] = []
  productsFilters: any[] = []

  ngOnInit() {
    window.scrollTo(0, 1);
    this.id = this.route.snapshot.params['id'];
    this.getProduct();
    this.user = this.userInfo.me().user;
    this.getFlavors();
    this.getEffects();
    this.getTerpenes();
    this.getCategories();
    this.getUsers();
    this.getProducts();
  }

  setForPublic(amount: number) {
    this.data.amount_gifts = amount;
    this.data.amount_friends = 0;
  }
  updateAmount(type: string, amount: number){
    if(type === 'gifts'){
      if(amount >= this.data.amount){
        this.data.amount_friends = 0;
        setTimeout(() => {
          this.data.amount_gifts = this.data.amount;
        }, 10)
      } else {
        this.data.amount_friends = this.data.amount - amount;
      }

    } else {
      if(amount >= this.data.amount){
        this.data.amount_gifts = 0;
        setTimeout(() => {
          this.data.amount_friends = this.data.amount;
        }, 10)
      } else {
        this.data.amount_gifts = this.data.amount - amount;
      }
    }
  }
  getProduct() {
    if(!this.id) return;

    this.isLoading = true;
    this.http.get('/products/one/'+this.id, ).subscribe({
      next: (response: any) => {
        this.productEdit = response;
        this.data.category = this.productEdit.category;
        this.data.type = this.productEdit.type;
        this.data.name = this.productEdit.name;
        this.product = this.productEdit.name;
        this.data.description = this.productEdit.description || '';
        this.data.grow_information = this.productEdit.grow_information || '';
        this.data.thc = this.productEdit.thc || 0;
        this.data.cdb = this.productEdit.cdb || 0;
        this.data.flavors = this.productEdit.flavors || [];
        this.data.effects = this.productEdit.effects || [];
        this.data.terpenes = this.productEdit.terpenes || [];
        this.data.imagesCatalog = this.productEdit.images || [];
        this.data.imagesPreview =  this.productEdit.images.map((x: any) => {
          //let base64 : any = await this.deviceInfo.getBase64FromS3Url(x);
          //console.warn(base64)
          return x//this.deviceInfo.dataURLtoFile(base64.replace('data:', ''), this.deviceInfo.getNameFromS3Url(x))
        })
        this.isLoading = false;
      }
    });
  }
  updateProducts() {
    this.productsFilters = [...this.products]
    if (this.data.category !== '') {
      this.productsFilters = this.productsFilters.filter(p => p.category === this.data.category)
    }
    if (this.data.type !== '') {
      this.productsFilters = this.productsFilters.filter(p => p.type === this.data.type)
    }
  }

  getProducts() {
    this.catalogService.getProducts().subscribe({
      next: (response: any) => {
        this.products = response;
      }
    })
  }

  setProduct(info: any) {
    if (info?.name) {
      this.data.name = info.name;
      this.product = info.name;
      this.data.description = info.description || '';
      this.data.thc = info.thc || 0;
      this.data.cdb = info.cdb || 0;
      this.data.flavors = info.flavors || [];
      this.data.effects = info.effects || [];
      this.data.terpenes = info.terpenes || [];
      this.data.imagesCatalog = info.images || [];
    }
  }

  shareGift() {
    this.router.navigateByUrl('/strain-finder').then(r => r)
  }

  allowShare() {
    return Object.keys(this.shareUsers).reduce((acc: boolean[], key) => {
      if (this.shareUsers[key] === true) {
        acc.push(Boolean(key));
      }
      return acc;
    }, [])
  }
  search = ''
  async getUsers() {
    let filters: any = {
      limit: 10,
      offset: 0,
    }
    if (this.search) {
      filters.search = this.search;
    }
    if (this.userInfo.user.id) {
      filters.user = this.userInfo.user.id;
    }
    filters['friends'] = 1;
    filters['following'] = 1;

    let users = await this.searchAllService.getUsers(filters).toPromise() as any;
    this.users = users || [];

    this.shareGiftDialog.open();
  }

  async save() {
    this.markElementsAsTouched();
    this.touched = ['name', 'description', 'growInformation']
    if (this.data.amount === 0) return;
    if (['flower'].includes(this.data.category) && parseInt(this.data.amount) > 0 && (parseInt(this.data.amount)) !== (parseInt(this.data.amount_friends) + parseInt(this.data.amount_gifts))) return;

    let newData = Object.assign({}, this.data);
    delete newData.file1;
    delete newData.file2;
    delete newData.file3;
    delete newData.file4;
    delete newData.file5;
    delete newData.images;
    delete newData.imagesFiles;
    delete newData.imagesPreview;
    if (this.data.category === 'flower') {
      delete newData.initial_cost;
      delete newData.publish_cost;
    }
    let formData: FormData = new FormData();
    for (let item in newData) {
      if (['flavors', 'effects', 'terpenes', 'imagesCatalog'].includes(item)) {
        // let array = [];
        // array.push(this.data[item])
        this.data[item].forEach((x: string, index: number) => {
          formData.append(`${item}[${index}]`, x);
        })
      } else {
        formData.append(item, this.data[item]);
      }
    }
    for (let i = 0; i < 6; i++) {
      if (this.data.imagesFiles[i]?.name) {
        formData.append(`images`, this.data.imagesFiles[i], this.data.imagesFiles[i].name);
      }
    }
    if(this.productEdit?.pk){
      formData.append("idproduct", this.productEdit.id);
      this.productEdit.images.forEach((x: string)=> {
        formData.append("urlImages", x);
      })
    }
    //let data = await fetch('https://omnigram-api.camezit.mx/product/add-edit', requestOptions)
    this.http.post('/product/add-edit', formData).subscribe({
      next: (response: any) => {
        const {success} = response
        if (success) {
          this.shareGiftDialog.open();
          // this.router.navigateByUrl('/profile/'+this.user.id)
        } else {
          alert("Error al crear producto")
        }
      }
    });
  }

  onChangePic() {
    this.imageChangedEvent = {target: {files: this.inputChangePic?.nativeElement.files}}
    this.changePic.open()
  }

  selectImagePic(index: number) {
    if(!this.data.images[index]?.name && this.data.imagesPreview[index]) return;
    this.index = index
    if (this.inputChangePic?.nativeElement?.files?.length)
      this.inputChangePic.nativeElement.value = null;

    this.inputChangePic?.nativeElement.click();
  }

  imageCropped(event: any) {
    if (event.base64 != null) {
      this.croppedImage = event.base64;
    }
  }

  cropperReady() {
    if (this.croppedImage === null) return;
    const ext = this.deviceInfo.extractContentTypeFromBase64(this.croppedImage).split('/')[1];
    let name = (Math.random() * 1000).toFixed(0) + `imag.${['gif', 'png', 'jpg', 'jpeg', 'mp4'].includes(ext) ? ext : 'png'}`;
    this.data.imagesPreview[this.index] = this.croppedImage;
    this.data.imagesFiles[this.index] = this.deviceInfo.dataURLtoFile(this.croppedImage.replace('data:', ''), name);
    this.croppedImage = '';
    this.imageChangedEvent = '';
    this.changePic.closeB();
  }

  closeCropper() {
    this.croppedImage = '';
    this.imageChangedEvent = '';
    this.changePic.closeB();
  }

  constructor() {
  }

  getFlavors() {
    this.catalogService.getAll('flavors', '').subscribe({
      next: (response: any) => {
        this.flavors = response.records;
      }
    })
  }

  getFlavorsName() {
    return this.flavors.map(x => x['name']);
  }

  setFlavor(name: string) {
    this.data.flavors.push(name);
    this.flavor = '';
  }

  getEffects() {
    this.catalogService.getAll('effects', '').subscribe({
      next: (response: any) => {
        this.effects = response.records;
      }
    })
  }

  getEffectsName() {
    return this.effects.map(x => x['name']);
  }

  setEffect(name: string) {
    this.data.effects.push(name);
    this.effect = '';
  }

  getTerpenes() {
    this.catalogService.getAll('terpenes', '').subscribe({
      next: (response: any) => {
        this.terpenes = response.records;
      }
    })
  }

  getCategories() {
    this.catalogService.getAll('categories', '').subscribe({
      next: (response: any) => {
        this.category = response.records.map((x: any) => {
          return {
            label: x['name'],
            value: x['name'].toLowerCase(),
          }
        });
      }
    })
  }

  getTerpenesName() {
    return this.terpenes.map(x => x['name']);
  }

  setTerpene(name: string) {
    this.data.terpenes.push(name);
    this.terpene = '';
  }

  goBack() {
    this.deviceInfo.goBack();
  }

  changePublishCost(cost: any) {
    this.data.publish_cost = Number(cost) * 1.2
  }

  markElementsAsTouched() {
    if (this.elements)
      this.elements.forEach((element: any) => {
        element.control.touched = true;

      });
  }

  deleteImage(index: number) {
    if(this.productEdit.images) {
      let indexProduct = this.productEdit.images.findIndex((x: string) => x === this.data.imagesPreview[index]);
      this.productEdit.images.splice(indexProduct, 1)
    }
    this.data.imagesPreview.splice(index, 1);
    this.data.imagesFiles.splice(index, 1);
  }
  deleteItem(key: string, index: number){
    this.data[key].splice(index, 1)
  }

  protected readonly parseInt = parseInt;
}
