import {Component, HostListener, inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {CatalogsService} from "../../../services/catalogs/catalogs.service";
import {Subscription} from "rxjs";
import {OmAlertService} from "../../../om-alert.service";

@Component({
  selector: 'app-coaching-home',
  templateUrl: './coaching-home.component.html',
  styleUrls: ['./coaching-home.component.scss']
})
export class CoachingHomeComponent implements OnInit, OnDestroy {
  private http = inject(HttpClient);
  private alertService = inject(OmAlertService);
  catalogService = inject(CatalogsService);
  type = '';
  search = '';
  statusFetch: boolean = false;
  protected data: any = [];
  categories = [
    {
      name: 'all',
      title: 'All',
      label: 'All',
      value: 'all',
      img: '',
      banner: ''
    },
    {
      name: 'mycourse',
      title: 'My courses',
      label: 'My courses',
      value: 'mycourse',
      img: 'assets/coaching/mycourses.png',
      banner: 'assets/coaching/mycourses.png',
    }
  ]

  constructor(private route: ActivatedRoute) {
  }

  async ngOnInit() {
    window.scrollTo(0, 1);
    await this.getCategories();
    this.getData(true);
  }

  subscriptionData: Subscription | undefined;

  getData(reset: boolean = false) {
    if (this.statusFetch) return;
    if (reset) this.data = [];
    this.statusFetch = true;
    this.subscriptionData = this.http.get('/courses', {
      params: {
        offset: this.data.length,
        limit: 20,
        type: (this.type === 'all' || this.type === 'mycourse' ? '' : (this.search==''?'':this.type)),
        search: this.search
      }
    }).subscribe({
      next: (response: any) => {
        this.data = [...this.data, ...response.records];
        this.statusFetch = false;
        this.subscriptionData?.unsubscribe()
      }
    });

  }

  subscriptionCategories: Subscription | undefined;

  async getCategories() {
    this.subscriptionCategories = this.catalogService.getAll('courses-categories', '').subscribe({
      next: (response: any) => {
        for (const category of response.records) {
          if (category.name != 'One on One Mentorship’s')
            this.categories.push({
              name: category.name,
              title: category.name,
              label: category.name,
              value: category.name,
              img: category.long_image,
              banner: category.long_image,
            });
        }
        this.type = this.categories[2].name;
        this.subscriptionCategories?.unsubscribe();
      }
    })
  }

  ngOnDestroy() {
    this.subscriptionCategories?.unsubscribe();
    this.subscriptionData?.unsubscribe();
  }

  openDialog() {
    const customConfig = {
      title: 'To create a course, please use the desktop version.',
      description: '',
      containerClass: 'logout-dialog',
      closeOnPress: false,
      activeButtonCancel: false
    };
    this.alertService.setCustomConfig(customConfig);
    this.alertService.showCustomDialog();
    this.alertService.getIsOKPressed().subscribe(isOK => {
      if (isOK === true) {
        this.alertService.closeDialog();
      }
    });
  }

  updateType($event: any) {
    this.type = $event;
  }

  async changeType(type: string, useModel: boolean = false) {
    if (!useModel) this.type = this.type == type ? '' : type;
    if (this.type === 'mycourse') this.search = '';
    this.getData(true);
  }

  async onInputChange(event: any) {
    if (this.type === 'mycourse' && this.search != '') this.type = 'all';
    this.getData(true);
  }

  @HostListener('window:scroll', ['$event'])
  endScroll(): void {
    if (this.statusFetch) return;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    if (scrollTop + clientHeight >= scrollHeight - 100) {
      this.getData();
    }
  }
}
