import {Component, EventEmitter, inject, OnInit, Output} from '@angular/core';
import {AuthService} from "../../../auth/services/auth.service";
import {OmAlertService} from "../../../om-alert.service";
import {Router} from "@angular/router";
import {DeviceInfoService} from "../../../services/device-info/device-info.service";
import {UserInfoService} from "../../../services/user-info/user-info.service";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
  @Output() toggleMenu = new EventEmitter<any>();
  http = inject(HttpClient)
  router = inject(Router);
  authService = inject(AuthService);
  userInfo = inject(UserInfoService);
  private alertService = inject(OmAlertService);
  user: any = {};
  communities: any = [];

  constructor(private deviceInfo: DeviceInfoService) {
  }

  onLogout() {
    this.toggle();
    const customConfig = {
      description: 'Are you sure you want to log out?',
      labelConfirm: 'Sign out',
      labelCancel: 'Keep me signed in',
      containerClass: 'logout-dialog',
      closeOnPress: false
    };

    this.alertService.setCustomConfig(customConfig);
    this.alertService.showCustomDialog(); // Mostrar el diálogo y esperar respuesta
    this.alertService.getIsOKPressed().subscribe(isOK => {
      if (isOK === true) {
        this.alertService.closeDialog()
        this.authService.logout()

      } else if (isOK === false) {
        this.alertService.closeDialog()
      }
    });
  }

  toggle() {
    if (this.deviceInfo.detectMobile()) {
      this.toggleMenu.emit();
    }
  }

  async ngOnInit() {
    this.user = this.userInfo.me().user;
    let communities = [];
    communities = await this.getCommunities({
      type: 'userIn',
      userId: '',
      offset: 0,
      limit: 10
    });
    if (communities.length == 0) communities = await this.getCommunities({
      type: 'search',
      offset: 0,
      limit: 10,
      search: ''
    });
    this.communities = this.getRandomElements(communities)
  }

  getName() {
    return this.userInfo.getName();
  }

  async getCommunities(params: any) {
    try {
      const response: any = await this.http.get('/communities', {
        params: params
      }).toPromise();
      if (response.data) {
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }

  }

  getRandomElements<T>(arr: T[], count: number = 2): T[] {
    if (arr.length < 3) return arr;
    let randomIndices = new Set<number>();
    while (randomIndices.size < count)
      randomIndices.add(Math.floor(Math.random() * arr.length));
    return Array.from(randomIndices).map(index => arr[index]);
  }
}
