import {Component, inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PostsComponent} from "../../profile/parts/posts/posts.component";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {FollowAndFriendService} from "../../../services/follow-and-friend/follow-and-friend.service";
import {UserInfoService} from "../../../services/user-info/user-info.service";
import {PostCommunityComponent} from "../parts/post-community/post-community.component";
import {OmAlertService} from "../../../om-alert.service";
import {ChatService} from "../../../services/chat/chat.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-community',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.scss']
})
export class CommunityComponent implements OnInit, OnDestroy{
  @ViewChild(PostCommunityComponent, {static: false}) postsComponent: PostCommunityComponent | undefined;
  private followAndFriends = inject(FollowAndFriendService);
  private alertService = inject(OmAlertService);
  chatService = inject(ChatService)
  router = inject(Router);
  http = inject(HttpClient);
  isFollowing: boolean = false;
  tab: string = 'posts'
  communityPostsCount: number = 0;
  user: { [key: string]: string | number | any } = {image_profile: '', banner: '', name: '', lastname: ''}
  data: any = {}

  constructor(private route: ActivatedRoute, private userInfo: UserInfoService) {
  }
  subscriptionQueryParams: Subscription | undefined;
  subscriptionUrl: Subscription | undefined;
  async ngOnInit() {
    window.scrollTo(0, 1);
    this.updateData()
  }
  updateData(){
    this.subscriptionQueryParams=this.route.queryParams.subscribe(async params => {
      console.log('----------')
      if (params['id']) {
        let resetPost = this.data?.communityId != '';
        await this.getData(params['id']);
        if(resetPost) this.postsComponent?.reset(params['id'])
      }
      //this.subscriptionQueryParams?.unsubscribe()
    });
  }
  updateCountPost(countPosts: number) {
    this.communityPostsCount = countPosts;
    this.getData(this.data.communityId)
  }
  subscriptionData: Subscription | undefined;

  async getData(id: string) {
    const response: any = await this.http.get('/communities', {
      params: { id }
    }).toPromise();

    // Continua con el manejo de la respuesta
    if (response.data) {
      this.data = response.data;
      this.validIsFollowing()
    } else {
      this.router.navigate(['/communities']);
    }
  }
  subscriptionJoinCommunity: Subscription | undefined;
  joinCommunity() {
    this.subscriptionJoinCommunity=this.http.post('/community/join', {
      id: this.data.communityId,
      userCommunity: this.data.communityIdUser
    }).subscribe({
      next: (response: any) => {
        const {success} = response
        if (success) {
          window.location.reload();
        } else {
          alert("Error")
        }
        this.subscriptionJoinCommunity?.unsubscribe()
      },
      error: (err: any) => {
        alert("Error")
      }
    });
  }
  subscriptionDisJoinCommunity: Subscription | undefined;
  disJoinCommunity() {
    this.subscriptionDisJoinCommunity=this.http.put('/community/disengage', {
      id: this.data.communityId,
      userCommunity: this.data.communityIdUser
    }).subscribe({
      next: (response: any) => {
        const {success} = response
        if (success) {
          this.router.navigateByUrl('/communities')
        } else {
          alert("Error")
        }
        this.subscriptionDisJoinCommunity?.unsubscribe()
      },
      error: (err: any) => {
        alert("Error")
      }
    });
  }
  subscriptionApplyJoin: Subscription | undefined;
  applyJoin() {
    this.subscriptionApplyJoin=this.http.post('/community/apply-join', {
      id: this.data.communityId,
      userCommunity: this.data.communityIdUser
    }).subscribe({
      next: (response: any) => {
        const {success, data, info} = response
        if (success) {
          this.chatService.sendPush(this.data.communityIdUser, {
            meta: data.notification.sk,
            message: data.notification.message
          }, 'community');
          this.alertService.setCustomConfig({
            title: 'Request sent',
            description: '',
            containerClass: 'logout-dialog',
            closeOnPress: true,
            activeButtonCancel: false
          });
          this.alertService.showCustomDialog();
        } else if (info === 'invitation-request-exists') {
          this.alertService.setCustomConfig({
            title: 'An outstanding request already exists',
            description: '',
            containerClass: 'logout-dialog',
            closeOnPress: true,
            activeButtonCancel: false
          });
          this.alertService.showCustomDialog();

        }
        this.subscriptionApplyJoin?.unsubscribe()
      },
      error: (err: any) => {
        alert("Error")
      }
    });
  }

  setTab(tab: string) {
    this.tab = tab;
  }

  getProfileInfo() {
    const user = this.user
    this.user = {user, ...this.userInfo.me().user}
  }

  isMe() {
    return this.userInfo.isMe(this.data.communityIdUser)
  }

  newPost() {
    this.postsComponent?.operNewPost()
  }

  async validIsFollowing() {
    this.isFollowing = await this.followAndFriends.isFollowing(this.data.communityIdUser);
  }

  async follow() {
    console.log(this.data.communityIdUser)
    await this.followAndFriends.add(this.data.communityIdUser);
    await this.followAndFriends.getFollowings(true);
    this.validIsFollowing();
  }

  async unfollow() {
    await this.followAndFriends.delete(this.data.communityIdUser);
    await this.followAndFriends.getFollowings(true);
    this.validIsFollowing();
  }

  goToMessages() {
    this.router.navigateByUrl('messages')
  }
  ngOnDestroy() {
    this.subscriptionData?.unsubscribe();
    this.subscriptionQueryParams?.unsubscribe();
    this.subscriptionJoinCommunity?.unsubscribe();
    this.subscriptionDisJoinCommunity?.unsubscribe();
    this.subscriptionApplyJoin?.unsubscribe();
    this.subscriptionUrl?.unsubscribe();
  }
}
