<div class="sm:w-[900px] mx-auto min-h-full">
  <div class="sticky top-[55px] z-30 bg-white sm:bg-[#ffffff00] flex items-center gap-1">
    <div class="flex items-center gap-2 mx-3.5 sm:ml-0 sm:hidden">
      <span class=" material-symbols-outlined text-4xl my-2 cursor-pointer" (click)="deviceInfo.goBack()">arrow_back</span>
    </div>
    <p class="text-[#000000] text-[19px] sm:text-[22px] font-Montserrat font-semibold px-4 sm:px-3.5">Choose Gift</p>
  </div>
  <div class="flex items-center justify-center sm:justify-between w-full pb-2 gap-4 mt-[15px]">
    <div class="w-[calc(25%+160px)] hidden sm:block">
      <app-om-select id="select-nav"
                     containerClass="!bg-white !py-[4.5px] !font-poppins !font-medium shadow-md text-lg"
                     containerItemsClass="!max-h-fit"
                     itemsClass="nav-select-item !py-1 hover:!bg-white hover:!text-green4-native"
                     color="gray" [options]="category" [(ngModel)]="filters['category']" required
                     (ngModelChange)="getSharables()"
                     #selectedNav="ngModel" [default]="filters['category']" placeholder="Filter"
      />
    </div>
    <div class="flex justify-between gap-4 pb-2 sm:pb-0 items-center sm:block relative w-[calc(100%-30px)] sm:w-[calc(75%-160px)]">
      <div class="w-full">
        <app-om-input [useIcon]="true" color="white" [(ngModel)]="search" (change)="getSharables()"
                      inputClass="rounded-full sm:rounded-md !bg-[#fff] !text-[#77838F] sm:!bg-white !py-[5.5px] shadow-md !font-poppins !font-medium relative !text-lg"
                      placeholder="Search">
          <app-search-icon class="hidden sm:inline-block" color="#77838F" [width]="18" [height]="18"/>
          <app-search-icon class="inline-block sm:hidden" color="#519935" [width]="18" [height]="18"/>
        </app-om-input>
      </div>
      <div class="flex items-center justify-center w-[30px] h-[30px] sm:absolute bg-[#d0d0d0] rounded-full p-1 right-[-42px] sm:right-2 top-1">
        <div class="relative" #filterList>
          <app-filters-icon [width]="24" [height]="24" (click)="showFilters = !showFilters"></app-filters-icon>
          <div #containerFilterList *ngIf="showFilters" class="absolute bg-white rounded-sm z-20 right-0 bw-1 border-[#d0d0d0] top-[28px]">
            <div class="py-2 px-4 border-[#d0d0d0] border-b flex gap-2 sm:gap-4 ">
              <div (click)="setSort('sort')" class="bg-[#fff] flex w-fit rounded-full items-center bw-1 border-[#efefef] gap-2 font-poppins text-[12px] py-1 px-3 sm:px-4"
                   [class]="{'!bg-[#EFEFEF]': filterTab === 'sort'}">
                Sort
                <app-chevron-up-icon *ngIf="filterTab === 'sort'" [width]="16" [height]="16"/>
                <app-chevron-down-icon *ngIf="filterTab !== 'sort'" [width]="16" [height]="16"/>
              </div>
              <div (click)="setSort('category')" class="bg-[#fff] flex w-fit rounded-full items-center bw-1 border-[#efefef] gap-2 font-poppins text-[12px] py-1 px-3 sm:px-4"
                   [class]="{'!bg-[#EFEFEF]': filterTab === 'category'}">
                Category
                <app-chevron-up-icon *ngIf="filterTab === 'category'" [width]="16" [height]="16"/>
                <app-chevron-down-icon *ngIf="filterTab !== 'category'" [width]="16" [height]="16"/>
              </div>
              <div (click)="setSort('type')" class="bg-[#fff] flex w-fit rounded-full items-center bw-1 border-[#efefef] gap-2 font-poppins text-[12px] py-1 px-3 sm:px-4"
                   [class]="{'!bg-[#EFEFEF]': filterTab === 'type'}">
                Type
                <app-chevron-up-icon *ngIf="filterTab === 'type'" [width]="16" [height]="16"/>
                <app-chevron-down-icon *ngIf="filterTab !== 'type'" [width]="16" [height]="16"/>
              </div>
            </div>
            <div class="py-2 px-4 border-[#d0d0d0] border-b flex gap-4 " *ngFor="let item of getCatalogFilter()" (click)="setFilterType(item.value)" >
              <div [class]="{'!text-[#000]': filters[filterTab] === item.value, '!font-medium ': filters[filterTab] === item.value}"
                   class="flex rounded-full items-center justify-between w-full gap-2 font-poppins text-[#75838D] font-normal text-[14px] py-1 px-1">
                {{ item.label }}
                <app-right-check-icon *ngIf="filters[filterTab] === item.value" [width]="18" [height]="18" color="#000"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-wrap justify-center sm:justify-between	gap-2 sm:gap-4 sm:mt-4" *ngIf="sharables.length>0">
    <app-card-gift class="gift-send block w-[95%] sm:w-[48%]" *ngFor="let item of sharables" [data2]="item" (sendData)="getData($event)" [disabledAdd]="productSelect.length>0"></app-card-gift>
  </div>
  <div class="flex justify-center" *ngIf="sharables.length==0">


    <app-not-found message="No gifts found :(" linkRouter="/add-product" labelLink="New Gift"></app-not-found>


  </div>
  <div class="fixed bottom-0 left-0 z-30 w-full flex items-center justify-center">
    <div class="shadow-lg border-t-[1px] flex items-center justify-between sm:justify-center bg-white w-full px-4 sm:px-0 py-4 sm:py-1">
      <div class="w-full sm:max-w-[900px] flex justify-end">
        <button class="rounded-full w-full sm:w-fit sm:flex px-4 !py-[10.5px] my-2 gap-2 items-center font-semibold text-xs bg-[#519935] text-white sm:rounded-md shadow-sm" (click)="sendDataB()">Continue</button>
      </div>
    </div>
  </div>
<!--  <div class="flex justify-end pr-3 sm:p-0 mb-8 sm:mb-4">-->
<!--  </div>-->
</div>
