export const environment = {
  production: false,
  //baseUrl:'https://omnigram-api.camezit.mx',
  baseUrl:'https://og-api.xyz-apps.link',
  //baseUrlAuth:'https://omnigram-api.camezit.mx',
  baseUrlAuth:'https://og-api.xyz-apps.link',
  baseUrlCommon:'https://og-api.xyz-apps.link',
  tenor:'AIzaSyA2v4VYmgZp329K8pVVmtJqucZo6SR2O7Q',
  mapboxToken: 'pk.eyJ1IjoibWFyY2tyb3NlcyIsImEiOiJjbGVza29wYjIxNzA1M3BudnhqMnAxMTlsIn0.N_NKa5VbtJq-X_f3_DLbIg',
  gMapsApiKey: 'AIzaSyCDotDjHMyh1smziBAzcTEBcTeO1NQ9Xzc',
  typeMap:'google',
  urlSocket: 'https://og-sc.xyz-apps.link',
  tenorclientkey:'desarrollo',
  pkStripe:'pk_test_51OYycvDHKcC8F1BRbwwMhftjVv9zmGSvlfc5f5K9FVAiLDipoornF5ivPpoUWD7VAOT85sKCrfpVoE9Ko9kjdz6D00vIOCha1T',
};
