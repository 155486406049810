<app-home-layout>
  <div class="p-4 min-h-screen">
    <div class="w-full" *ngIf="users?.length">
      <p class="font-poppins font-semibold text-[#202020] text-[18px] pt-10 sm:pt-0">Users</p>
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <app-friend-card *ngFor="let user of users" [user]="user"/>
      </div>
    </div>
    <div class="w-full" *ngIf="posts?.length">
      <p class="font-poppins font-semibold text-[#202020] text-[18px] pt-10 sm:pt-0">Post</p>
      <div class="grid grid-cols-1">
        <app-publication *ngFor="let item of posts"
                         class="block sm:mt-2"
                         [isProfile]="true"
                         [data]="{
                       pk:item.pk,
      postId:item.postId,
      postType:item.postType,
      avatar:item.avatar,
      name:item.name,
      date:item.createDate,
      description:item.postContent,
      medios:item.postUrls,
      lastLikes:item.lastLikes || [],
      verifiedAccount:true,
      like:item.like,
      postLikesCount:item.postLikesCount,
      postCommentsCount:item.postCommentsCount,
      postLocation:item.postLocation || null,
      postHashtack:item.postHashtack,
      postFiltersByImage:item.postFiltersByImage,
      save:item['save']

    }"/>
      </div>
    </div>
    <div class="w-full" *ngIf="products?.length">
      <p class="font-poppins font-semibold text-[#202020] text-[18px] pt-10 sm:pt-0">Marketplace</p>
      <div class="flex ocultarScroll gap-4">
        <app-card-mini class="block w-[85%] mr-[22px] sm:mr-0 sm:w-[385px]" [btndetailVisable]="false" [isStrainFinder]="true" [useTemplateFooter]="true" [mine]="true" *ngFor="let item of products" [data]="item">
          <footer class="block w-full px-[10px] sm:px-[14px]">
            <div class="flex justify-between holis">
              <div class="flex gap-4 container-avatar">
                <img [src]="item.user.image_profile || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'" class="w-[35px] h-[35px] sm:w-[50px] sm:h-[50px] rounded-full object-cover " alt="">
                <div class="flex flex-col justify-center">
                  <p class="text-[14px] text-[#FFFFFF] font-bold truncate max-w-[90px]">{{ item.user.name||item.user.username }}</p>
                  <p class="text-[12px] text-[#FFFFFF]">{{item.user.region}} Grower</p>
                </div>
              </div>
              <div class="flex items-center gap-2">
                <button class="text-[15px] font-semibold sm:text-[13px] text-[#FFFFFF] flex justify-center items-center w-fit px-6 h-[35px] rounded-full bg-[#81CB52]" (click)="claimgift(item)">{{item.publish_cost ? 'Add to Cart' : 'Claim'}}</button>
              </div>
            </div>
          </footer>
        </app-card-mini>
      </div>
    </div>
    <app-not-found message="No results have been found :(" *ngIf="resultsNotFound()"/>
  </div>
</app-home-layout>
