<div class="px-4 pl-6 h-full flex flex-col justify-between
      pt-[var(--sat)]
      bg-black bg-cover sm:bg-none bg-center
      sm:h-[75vh] sm:w-[36.6vw] sm:bg-[#2e2e2e]
      sm:mt-[calc(var(--w-height)*10)]
      sm:pt-[calc(var(--w-height)*10)]
      sm:pb-[calc(var(--w-height)*4)]
      sm:mr-[calc(var(--w-height)*15)]
      sm:px-[calc(var(--w-width)*6)]
      sm:rounded-[2vw]">
  <div>
    <ng-icon name="matArrowBack" class="text-white my-4 text-4xl inline-block sm:!hidden" (click)="previous()"></ng-icon>
    <p class="title-form text-[calc(var(--mw-height)*4.5)] pr-[calc(var(--mw-width)*10)]
        sm:pr-[calc(var(--w-width)*10)] sm:text-[calc(var(--w-height)*4.5)]">
      {{title}}</p>
    <p class="text-[#C3C3C3] mt-[calc(var(--mw-height)*1)] sm:mt-[calc(var(--mw-height)*2)] sm:text-white text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*2)]">
      We have sent an OTP code to your email {{maskEmail(dataDefault.email)}}. Enter the OTP code to verify</p>
    <div class="mt-[calc(var(--mw-height)*6)] sm:mt-[calc(var(--mw-height)*4.5)]">
      <div class="form-group mt-[calc(var(--mw-height)*8)]">
        <ng-otp-input #i class="w-full" inputmode="numeric"
                      [config]="{length:4,inputClass:'om-input-otp',containerClass:'otp',allowNumbersOnly:true}"
                      (onInputChange)="onOtpChange($event)">
        </ng-otp-input>
      </div>
      <p *ngIf="time_new_code!=0" class="text-center text-white text-sm mt-text-[calc(var(--mw-height)*6)]
                  text-[calc(var(--mw-height)*1.54)] sm:text-[calc(var(--w-height)*2)]
                   leading-[calc(var(--w-height)*4)] sm:leading-[calc(var(--w-height)*6)]0">
        Didn´t receive email?
        <br> You can resend code in
        <span class="text-green-native">{{time_new_code}}</span> s.</p>
      <p *ngIf="time_new_code==0" class="text-center text-white text-sm mt-8 text-green-native cursor-pointer" (click)="newOTP()">
        Resend code</p>
      <p *ngIf="error_code" class="text-center text-red-500 text-[calc(var(--mw-height)*1.75)] sm:text-[calc(var(--w-height)*2)]">
        OTP code invalid</p>
    </div>
  </div>
<!--  <div class="w-full items-center">-->
<!--    <p-->
<!--      class="text-white text-center m-[calc(var(&#45;&#45;mw-height)*3)] text-[calc(var(&#45;&#45;mw-height)*1.4)]-->
<!--          block sm:absolute sm:w-[36.6vw] sm:right-[calc(var(&#45;&#45;w-height)*15)] sm:bottom-0 sm:mb-[calc(var(&#45;&#45;mw-height)*7)]-->
<!--          sm:text-[calc(var(&#45;&#45;w-height)*2)]"-->
<!--    >Have an account? <span-->
<!--      class="text-green-native cursor-pointer underline" routerLink="/login">Sign in now.</span></p>-->
<!--  </div>-->
</div>
