<app-clear-layout>
  <input type="file" (change)="onChangePic()" class="opacity-0 w-0 h-0" name="change-pic" accept="image/*"
         #inputChangePic>
  <div class="w-full sm:p-6">
    <div class="flex justify-between px-[20px] sm:px-[10px]">
      <app-arrow-left-icon [width]="35" [height]="35" color="#00000066" class="cursor-pointer" (click)="goBack()"/>
      <span class="material-symbols-outlined sm:hidden cursor-pointer text-[30px]"
            (click)="openMenu=true"> settings </span>
    </div>
    <div class="flex w-full sm:gap-4">
      <div class="hidden sm:block rounded-2xl bg-white w-full sm:w-[400px]"
           [class]="{
        'absolute':openMenu,
        'bg-white':openMenu,
        '!block':openMenu,
        'z-50':openMenu,
        'p-3':openMenu,
        'top-0':openMenu
        }">
        <div class="flex justify-between items-center">
          <h2 class="font-poppins font-medium text-[#202020] text-[22px] pb-4">Your community</h2>
          <span class="hidden sm:block  material-symbols-outlined">settings</span>
          <span class="material-symbols-outlined cursor-pointer sm:hidden" (click)="openMenu=false">
              close
            </span>
        </div>
        <div class="flex gap-[5px] container-avatar my-4">
          <img alt="" class="w-[50px] h-[50px] rounded-full object-cover"
               [src]="user.image_profile || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'">
          <div class="flex gap-2 justify-between items-center">
            <div>
              <p
                class="ml-[15px] text-[18px] sm:text-[20px] font-semibold font-Poppins leading-1">{{ user.name }} {{ user.lastname }}</p>
            </div>
          </div>
        </div>
        <hr>
        <div class="flex flex-col gap-2 mt-4">
          <p class="font-poppins font-medium text-[16px] text-[#202020]"
             [class]="{
                'text-[#E83C3C]':validateForm && data.communityName.trim()===''
              }"
          >
            Community name
          </p>
          <div class="flex w-full items-center gap-4">
            <app-om-input color="white"
                          [(ngModel)]="data.communityName"
                          [inputClass]="(validateForm && data.communityName.trim()===''?'border-[#E83C3C] focus:!border-none !shadow-none !outline-none':'')+' !bg-white !py-[9px] bw-1 border-gray !font-poppins !font-medium relative !text-[14px] !text-[#000] placeholder:!text-[14px]'"
                          placeholder="Community name"
                          class="w-[100%]"
            >
            </app-om-input>
          </div>
        </div>
        <div class="flex flex-col gap-2 mt-4">
          <p class="font-poppins font-medium text-[16px] text-[#202020]">Choose privacy settings</p>
          <div class="flex w-full items-center gap-4">
            <app-om-select class="w-full"
                           containerClass="!bg-white !py-[4.5px] !font-poppins !font-medium bw-1 border-gray !text-[14px]"
                           containerItemsClass="!max-h-fit"
                           itemsClass="bg-white border-[#EEEEEE] !text-black hover:bg-white hover:!text-green4-native !py-1"
                           color="gray" [options]="[
                           {label:'Public',value:'Public'},
                           {label:'Privacy',value:'Privacy'}
                           ]" [(ngModel)]="data.communityPrivacy"
                           #selectedNav="ngModel" [default]="data.communityPrivacy" placeholder="Please select"
            />
          </div>
        </div>
        <div class="flex flex-col gap-2 mt-4">
          <app-post-tag-people (peopleSelectedChange)="setTagPeople($event)" class="block w-full pr-[12px]"
                               [classTitle]="{'font-poppins':true, 'font-medium':true }"
                               title="Invite friends (optional)"></app-post-tag-people>
        </div>
        <div class="flex flex-col gap-2 mt-4">
          <p class="font-poppins font-medium text-[16px] text-[#202020]"
             [class]="{
                'text-[#E83C3C]':validateForm && data.communityCategory.trim()===''
              }"
          >Category</p>
          <div class="flex w-full items-center gap-4">
            <app-om-select class="w-full"
                           [containerClass]="'!bg-white !py-[4.5px] !font-poppins !font-medium bw-1 border-gray !text-[14px] '+(validateForm && data.communityCategory.trim()===''?'border-[#E83C3C] focus:!border-none focus:!shadow-none':'')"
                           containerItemsClass="!max-h-fit"
                           itemsClass="bg-white border-[#EEEEEE] !text-black hover:bg-white hover:!text-green4-native !py-1"
                           color="gray" [options]="categories" [(ngModel)]="data.communityCategory"
                           #selectedNav="ngModel" [default]="data.communityCategory"
                           placeholder="Please select category"
            />
          </div>
        </div>
        <div class="flex flex-col gap-2 mt-4">
          <p class="font-poppins font-medium text-[16px]  text-[#202020]"
             [class]="{
                'text-[#E83C3C]':validateForm && data.communityDescription.trim()===''
              }"
          >About Group</p>
          <div class="flex w-full items-center gap-4">
            <textarea placeholder="How can we help you?" [(ngModel)]="data.communityDescription"
                      class="w-full bw-1 rounded-lg p-4 textarea focus:ring-offset-0
    focus:outline-none
    focus:ring-1
    focus:ring-omgreen-100
    outline-omgreen-50"
                      [class]="{
                        'border-[#E83C3C]':validateForm && data.communityDescription.trim()==='',
                        'focus:!border-none':validateForm && data.communityDescription.trim()==='',
                        'focus:!shadow-none':validateForm && data.communityDescription.trim()===''
                      }"
            ></textarea>
          </div>
        </div>
        <button *ngIf="data.id===''" (click)="save()"
                class="mt-[15px] text-[20px] px-4 !py-[10.5px] mb-2 h-fit gap-2 text-center items-center font-semibold text-xs bg-[#519935] text-white rounded-md shadow-sm w-[90%] ml-[5%]">
          Create Community
        </button>
        <button *ngIf="data.id!=''" (click)="update()"
                class="mt-[15px] text-[20px] px-4 !py-[10.5px] mb-2 h-fit gap-2 text-center items-center font-semibold text-xs bg-[#519935] text-white rounded-md shadow-sm w-[90%] ml-[5%]">
          Update Community
        </button>
      </div>
      <div class="px-0 w-full sm:w-[calc(100%-400px)] sm:max-w-[1200px] sm:px-[25px]">
        <div class="h-screen top-0 z-20 sm:z-0 sm:w-full w-full sm:h-auto xs:bg-white sm:bg-[#FAFAFA]">
          <div class="bg-white rounded-b-[calc(var(--w-width)*2)] h-auto z-30">
            <div class="relative">
              <div class="cover-page relative">
                <div *ngIf="communityImgCover===''" (click)="selectImagePic('communityImgCover')"
                     class="bg-[#cceabb1a] border-[#CCEABB] border-[1px] w-full h-full flex justify-center items-center flex-col rounded-lg cursor-pointer sm:h-[200px]"
                     [class]="{
                        'border-[#E83C3C]':validateForm
                    }"
                >
                  <span class="w-[82px] h-[82px] flex justify-center items-center bg-white rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" width="33.959" height="30.361" viewBox="0 0 33.959 30.361">
                      <path id="Vector"
                            d="M29.328,18.217v4.554h4.631v3.036H29.328v4.554H26.241V25.807H21.61V22.771h4.631V18.217ZM29.341,0a1.52,1.52,0,0,1,1.531,1.507V15.181H27.785V3.036H3.087V24.287L18.523,9.108l4.631,4.554v4.295L18.523,13.4,7.451,24.289H18.523v3.036H1.531a1.546,1.546,0,0,1-1.083-.442A1.5,1.5,0,0,1,0,25.818V1.507A1.506,1.506,0,0,1,.452.445,1.557,1.557,0,0,1,1.531,0ZM9.262,6.072a3.114,3.114,0,0,1,2.183.889,3,3,0,0,1,0,4.294,3.124,3.124,0,0,1-4.366,0,3,3,0,0,1,0-4.294,3.114,3.114,0,0,1,2.183-.889Z"
                            fill="#99c183" [class]="{
                        '!fill-[#E83C3C]':validateForm
                    }"/>
                    </svg>
                  </span>
                  <p class="text-[#99C183] mt-[10px]"
                     [class]="{
                        'text-[#E83C3C]':validateForm
                    }"
                  >
                    Click here to upload
                  </p>
                </div>
                <img *ngIf="communityImgCover!=''" [src]="communityImgCover" alt="">
                <div *ngIf="communityImgCover!=''"
                     class="bg-gradient-to-b sm:bg-gradient-to-t absolute top-0 from-gray-900 sm:via-transparent to-transparent sm:rounded-b-[2rem] h-full w-full"></div>
                <span *ngIf="communityImgCover!=''" (click)="selectImagePic('communityImgCover')"
                      class="absolute top-[10px] right-[10px] cursor-pointer flex material-symbols-outlined text-[15px] p-auto bg-[#87D255] text-white h-[30px] w-[30px] rounded-xl justify-center items-center">edit_square</span>
              </div>
              <div *ngIf="communityImgCover!=''" class="flex absolute bottom-[20px] gap-2 right-0 pr-4 ">
                <p
                  class="text-[9px] bg-[#87D255] font-poppins font-medium sm:text-[12px] text-[#FFFFFF] rounded-2xl px-4 py-3">
                  Join Group</p>
              </div>
            </div>
            <div class="flex relative items-start mt-[-90px] sm:mt-[-93px]  ml-4">
              <div class="relative">
                <div *ngIf="communityImgProfile===''" (click)="selectImagePic('communityImgProfile')"
                     class="bg-white border-[#CCEABB] border-[1px] w-[100px] h-[100px] sm:w-[160px] sm:h-[160px]  sm:border-[5px] sm:border-[#87D255] rounded-3xl flex justify-center items-center flex-col rounded-lg cursor-pointer"
                     [class]="{
                        '!border-[#E83C3C]':validateForm
                    }"
                >
                  <span class="w-[82px] h-[82px] flex justify-center items-center bg-white rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" width="33.959" height="30.361" viewBox="0 0 33.959 30.361">
                      <path id="Vector"
                            d="M29.328,18.217v4.554h4.631v3.036H29.328v4.554H26.241V25.807H21.61V22.771h4.631V18.217ZM29.341,0a1.52,1.52,0,0,1,1.531,1.507V15.181H27.785V3.036H3.087V24.287L18.523,9.108l4.631,4.554v4.295L18.523,13.4,7.451,24.289H18.523v3.036H1.531a1.546,1.546,0,0,1-1.083-.442A1.5,1.5,0,0,1,0,25.818V1.507A1.506,1.506,0,0,1,.452.445,1.557,1.557,0,0,1,1.531,0ZM9.262,6.072a3.114,3.114,0,0,1,2.183.889,3,3,0,0,1,0,4.294,3.124,3.124,0,0,1-4.366,0,3,3,0,0,1,0-4.294,3.114,3.114,0,0,1,2.183-.889Z"
                            fill="#99c183" [class]="{
                        '!fill-[#E83C3C]':validateForm
                    }"/>
                    </svg>
                  </span>
                  <p class="text-[#99C183] mt-[10px] text-center"
                     [class]="{
                        'text-[#E83C3C]':validateForm
                    }"
                  >Click here to upload</p>
                </div>
                <img *ngIf="communityImgProfile!=''" [src]="communityImgProfile" alt=""
                     class="w-[100px] h-[100px] sm:w-[160px] sm:h-[160px]  sm:border-[5px] sm:border-[#87D255] rounded-3xl object-cover	">
                <span *ngIf="communityImgProfile!=''" (click)="selectImagePic('communityImgProfile')"
                      class="z-30 absolute bottom-[10px] right-[10px] cursor-pointer flex material-symbols-outlined text-[15px] p-auto bg-[#87D255] text-white h-[30px] w-[30px] rounded-xl justify-center items-center">edit_square</span>
              </div>
              <div class="ml-4 sm:ml-6 mt-6 sm:mt-5 sm:leading-none	">
                <p
                  class="text-[#FAFAFB] font-SF-Pro-Text text-[16px] font-medium sm:font-Poppins sm:font-semibold sm:text-[#FAFAFB] sm:text-[22px]">{{ data.communityName }}</p>
                <p
                  class="text-[#D5D5DC] font-SF-Pro-Text sm:font-Montserrat sm:text-[#D5D5DC] text-[12px] sm:text-[14px] mt-[5px] sm:mt-[10px]">
                  @{{ data.communityName.replaceAll(' ', '').substring(0, 12) }}</p>
              </div>
            </div>
            <hr class="sm:hidden mx-3">
            <div class="nav-profile relative z-20">
              <p class="cursor-pointer flex flex-col items-center justify-center !min-w-min	 sm:!min-w-[74px]">
                <span class="quantity font-semibold sm:block">10,3K</span>
                <span class="">Posts</span>
              </p>
              <p class="cursor-pointer flex flex-col items-center justify-center !min-w-min	 sm:!min-w-[74px]">
                <span class="quantity font-semibold sm:block">23K</span>
                <span class="">Members</span>
              </p>
              <p class="cursor-pointer flex flex-col items-center justify-center !min-w-min	 sm:!min-w-[74px]">
                <span class="quantity font-semibold sm:block">103</span>
                <span class="">Media</span>
              </p>
              <p class="hidden cursor-pointer flex flex-col items-center justify-center !min-w-min	 sm:!min-w-[74px]">
                <span class="quantity font-semibold sm:block">13</span>
                <span class="">Events</span>
              </p>

              <button
                class="hidden sm:flex px-4 !py-[10.5px] mb-2 h-fit gap-2 items-center font-semibold text-xs bg-[#519935] text-white rounded-md shadow-sm">
                <ng-icon name="matPlus" class="text-white cursor-pointer text-lg z-10"></ng-icon>
                New Post
              </button>
            </div>
          </div>
          <div class="">
            <div class="flex justify-end">
              <article class="hidden sm:block side w-[30%]">
                <section class="bg-white rounded-2xl pt-2 mt-4">
                  <div>
                    <div class="flex justify-between items-center">
                      <p class="font-Poppins font-[600] text-[10px] text-[#171725] ">About Group</p>
                    </div>
                    <hr class="hidden sm:block border-[#EEEEEE] my-2">
                    <div class="px-3">
                      <p class="text-[#44444F] text-[10px] font-Roboto">
                        {{ data.communityDescription }}
                      </p>
                    </div>
                    <hr class="hidden sm:block border-[#EEEEEE]">
                    <div>
                      <p class="font-Poppins font-[600] text-[10px] text-[#171725] ">Group Created by</p>
                      <div class="flex gap-[5px] container-avatar my-4">
                        <img alt="" class="w-[50px] h-[50px] rounded-full object-cover"
                             [src]="user.image_profile || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'">
                        <div class="flex gap-2 justify-between items-center">
                          <div>
                            <p class="ml-[15px] text-[12px] sm:text-[10px] font-semibold font-Poppins leading-1">
                              {{ user.name }} {{ user.lastname }}</p>
                            <p
                              class="ml-[15px] mt-[0px] text-[12px] sm:text-[10px] text-[#00000099] font-Poppins leading-1">
                              {{ today }}</p>
                          </div>
                          <div class="flex gap-2 mt-[10px] sm:mt-[10px]">
                            <span
                              class="flex items-center justify-center text-[10px] font-Poppins font-bold text-[#519935] border border-[#81CB52] w-[70px] h-[29px] rounded-2xl cursor-pointer">+ Add</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <p class="font-Poppins font-[600] text-[10px] text-[#171725] ">Group type</p>
                      <p class="font-Poppins text-[10px]">{{ data.communityCategory }}</p>
                    </div>


                  </div>

                </section>

              </article>
              <article class="w-full sm:w-[68%] sm:px-4">
              </article>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="loading" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div class="text-white text-center">
      <p class="text-xl font-bold">Saving ...</p>
    </div>
  </div>
  <app-dialog #changePic [title]="type=='banner'?'Banner picture':'Profile picture'"
              [classHeaderDefault]="'justify-center pt-5 pb-3 text-lg'"
              [classModal]="(type=='banner'?'sm:!min-w-[800px] sm:!w-[800px]':'sm:!min-w-[400px] sm:!w-[500px]')+' bg-black/80 sm:bg-white text-white sm:text-black sm:relative'"
              classBody="">
    <div class="mx-[35px]">
      <image-cropper
        class="w-full !px-0"
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="aspectRatio"
        [resizeToWidth]="widthImage"
        [imageQuality]="50"
        format="png"
        output="base64"
        (imageCropped)="imageCropped($event)"
      ></image-cropper>
    </div>
    <div class="flex items-center justify-end gap-4 py-2 mx-[35px]">
      <button (click)="cropperReady()"
              class="flex justify-center w-[100px] !py-[6px] mb-2 h-fit gap-2 items-center font-Poppins font-normal bg-[#519935] text-white rounded-xl shadow-sm text-base">
        Ok
      </button>
      <button (click)="closeCropper()"
              class="flex justify-center w-[100px] !py-[6px] border-2 border-[#DCDCDC] mb-2 h-fit gap-2 items-center font-Poppins font-normal bg-[#fff] text-[#202020] rounded-xl shadow-sm text-base">
        Discard
      </button>
    </div>
  </app-dialog>
  <app-dialog-alert class="absolute z-[70]"></app-dialog-alert>
</app-clear-layout>
