import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-grower-icon',
  templateUrl: './grower-icon.component.svg',
  styleUrls: ['./grower-icon.component.scss']
})
export class GrowerIconComponent {
  @Input() color = '#292D32';
  @Input() width = 24;
  @Input() height = 24;
}
