import {Component, EventEmitter, HostListener, inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {GiftService} from "../../../../services/gift/gift.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-all-products',
  templateUrl: './all-products.component.html',
  styleUrls: ['./all-products.component.scss']
})
export class AllProductsComponent implements OnInit, OnDestroy {
  @Input() set filters(value: any) {
    if (value !== this._filters) {
      this._filters = value;
      this.limit = 10;
      this.offset = 0;
      this.sharables = [];
      this.getSharables(); // Call the method that depends on the filters when a change is detected
    }
  }

  get filters(): any {
    return this._filters;
  }

  private _filters = {}
  @Input() category = 'flower'
  private http = inject(HttpClient);
  giftService = inject(GiftService);
  sharables: any = [];
  statusFetch = false;
  limit = 10;
  offset = 0;
  private search: any;
  @Output() selectGift = new EventEmitter<string>();
  subscriptionData: Subscription | undefined;

  ngOnInit() {
    window.scrollTo(0, 1);
    this.getSharables()
  }

  ngOnDestroy() {
    this.subscriptionData?.unsubscribe()
  }

  getSharables() {
    this.statusFetch = true;
    let newFilters: { [key: string]: any } = {...this.filters};
    delete newFilters['limit']
    delete newFilters['offset']
    if (newFilters['category'] === 'all')
      delete newFilters['category'];
    if (newFilters['type'] === 'all')
      delete newFilters['type'];
    if (newFilters['effect'] === 'all')
      delete newFilters['effect'];
    if (!newFilters['search'])
      delete newFilters['search'];
    if (!newFilters['sortKey'])
      delete newFilters['sortKey'];
    this.subscriptionData = this.http.get('/products/general/filters', {
      params: {
        ...newFilters,
        limit: this.limit,
        offset: this.offset,
        sortKey: this.filters['sortKey'],
        sort: 'desc',
      }
    }).subscribe({
      next: (response: any) => {
        let records = response?.records || response;
        try {
          if (records.length === this.limit) {
            this.offset += this.limit
          } else {
            this.offset += records.length;
          }
        } catch (e) {
          console.error(e)
        }
        this.sharables = [...this.sharables, ...records];
        this.statusFetch = false;
        this.subscriptionData?.unsubscribe()
      }
    });
  }

  @HostListener('window:scroll', ['$event'])
  endScroll(): void {
    if (this.statusFetch) return;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    if (scrollTop + clientHeight >= scrollHeight - 100) {
      this.getSharables()
    }
  }

  claimgift(data: any) {
    this.giftService.claimGift(data)
  }

  selectedGift($event: string) {
    this.selectGift.emit($event);
  }
}
