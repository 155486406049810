import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {NgControl} from "@angular/forms";
import * as ranges from './ranges';

const SLIDER_TYPE = 'input';
const SLIDER_RANGE = 'range';
const MIN_VALUE = '0';
const SLIDER_CLASS = 'slider';

@Directive({
    selector: '[appRange]'
})
export class RangeDirective implements OnInit {
    @Input('rangeType') rangeType: any = 'default';
    position = 0;
    currentValue = 0;
    values = [];

    rangeElement: any;

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private control: NgControl) {
    }

    ngOnInit() {
        // @ts-ignore
      this.values = ranges[this.rangeType];
        this.rangeElement = this.createSliderElement();

        this.renderer.listen(this.rangeElement, 'input', this.handleRangeInput.bind(this));
        // @ts-ignore
      this.control.control.valueChanges.subscribe(this.handleControlValueChanges.bind(this));
    }

    createSliderElement() {
        const range = this.renderer.createElement(SLIDER_TYPE);
        this.renderer.setAttribute(range, 'type', SLIDER_RANGE);
        this.renderer.setAttribute(range, 'min', MIN_VALUE);
        this.renderer.setAttribute(range, 'max', (this.values.length - 1).toString());
        this.renderer.addClass(range, SLIDER_CLASS);
        this.renderer.setAttribute(range, 'value', this.position.toString());
        this.elementRef.nativeElement.style = 'display:none';
        this.renderer.appendChild(this.renderer.parentNode(this.elementRef.nativeElement), range);

        return range;
    }

    handleRangeInput(event: any) {
        let position = +event.target.value;
        this.updatePositionAndControlValue(position);
    }

    handleControlValueChanges(val: any) {
        if (this.currentValue !== val) {
            this.currentValue = val;
            this.position = val;
            this.rangeElement.value = this.position;
        }
    }

    updatePositionAndControlValue(position: string | number) {
        this.currentValue = +this.calculateSliderValueFromPosition(position).toFixed(0);
        // @ts-ignore
      this.control.control.setValue(this.currentValue);
    }

    calculateSliderValueFromPosition(position: any): number {
        return this.values[position];
    }
}
