<app-sidebar2-layout title="Community" [useHeaderEdit]="true" [routeHeader]="'/communities'">
  <div *ngIf="data.communityImgCover"
       class="h-screen top-0 z-20 sm:z-0 sm:w-full w-full sm:h-auto xs:bg-white sm:bg-[#FAFAFA]">
    <div class="bg-white rounded-b-[calc(var(--w-width)*2)] h-auto z-30">
      <div class="">
        <div class="cover-page relative">
          <span
            class="material-symbols-outlined cursor-pointer text-white text-4xl absolute z-10 top-[10px] left-[10px] sm:hidden"
            routerLink="/home">arrow_left_alt</span>
          <img [src]="data.communityImgCover" class="!h-[170px] sm:!h-auto" alt="">
          <div
            class="bg-gradient-to-b sm:bg-gradient-to-t absolute top-0 from-gray-900 sm:via-transparent to-transparent sm:rounded-b-[2rem] h-full w-full"></div>
          <span *ngIf="isMe()"
                class="absolute top-[10px] right-[10px] cursor-pointer flex material-symbols-outlined text-[15px] p-auto bg-[#87D255] text-white h-[30px] w-[30px] rounded-xl justify-center items-center"
                [routerLink]="'/add-community'" [queryParams]="{ id: data.communityId }"
          >edit_square</span>
        </div>
        <div class="flex absolute bottom-[20px] gap-2 right-0 pr-4 ">
          <p *ngIf="data.isMember===1"
             class="text-[9px] bg-[#87D255] font-poppins font-medium sm:text-[12px] text-[#FFFFFF] rounded-2xl px-4 py-3 cursor-pointer z-10"
             (click)="disJoinCommunity()">Leave Group</p>
          <p
            *ngIf="data.isMember===0 && (data.communityPrivacy==='Public' || isMe())"
            class="text-[9px] bg-[#87D255] font-poppins font-medium sm:text-[12px] text-[#FFFFFF] rounded-2xl px-4 py-3 cursor-pointer z-10"
            (click)="joinCommunity()">
            Join
          </p>
          <p
            *ngIf="data.isMember===0 && data.communityPrivacy!='Public' && !isMe()"
            class="text-[9px] bg-[#87D255] font-poppins font-medium sm:text-[12px] text-[#FFFFFF] rounded-2xl px-4 py-3 cursor-pointer z-10"
            (click)="applyJoin()">
            Apply to Join
          </p>
        </div>
      </div>
      <div class="flex relative items-start mt-[-90px] sm:mt-[-93px]  ml-4">
        <div class="relative">
          <img [src]="data.communityImgProfile" alt=""
               class="w-[100px] h-[100px] sm:w-[160px] sm:h-[160px]  sm:border-[5px] sm:border-[#87D255] rounded-3xl object-cover	">
        </div>
        <div class="ml-4 sm:ml-6 mt-6 sm:mt-5 sm:leading-none	">
          <p
            class="text-[#FAFAFB] font-SF-Pro-Text text-[16px] font-medium sm:font-Poppins sm:font-semibold sm:text-[#FAFAFB] sm:text-[22px]">{{ data.communityName }}</p>
          <p
            class="text-[#D5D5DC] font-SF-Pro-Text sm:font-Montserrat sm:text-[#D5D5DC] text-[12px] sm:text-[14px] mt-[5px] sm:mt-[10px]">
            @{{ data.communityName.replaceAll(' ', '').substring(0, 12) }}</p>
        </div>
      </div>
      <hr class="sm:hidden mx-3">
      <div class="nav-profile relative z-5">
        <p class="cursor-pointer flex flex-col items-center justify-center !min-w-min	 sm:!min-w-[74px]">
          <span class="quantity font-semibold sm:block">{{ communityPostsCount }}</span>
          <span class="">Posts</span>
        </p>
        <p class="cursor-pointer flex flex-col items-center justify-center !min-w-min	 sm:!min-w-[74px]">
          <span class="quantity font-semibold sm:block">{{ data.communityMembersCount }}</span>
          <span class="">Members</span>
        </p>
        <p class="cursor-pointer flex flex-col items-center justify-center !min-w-min	 sm:!min-w-[74px]">
          <span class="quantity font-semibold sm:block">{{ data.communityMediaCount }}</span>
          <span class="">Media</span>
        </p>
        <p class="hidden cursor-pointer flex flex-col items-center justify-center !min-w-min	 sm:!min-w-[74px]">
          <span class="quantity font-semibold sm:block">{{ data.communityEventsCount }}</span>
          <span class="">Events</span>
        </p>

        <button
          *ngIf="data.isMember===1"
          (click)="newPost()"
          class="hidden sm:flex px-4 !py-[10.5px] mb-2 h-fit gap-2 items-center font-semibold text-xs bg-[#519935] text-white rounded-md shadow-sm">
          <ng-icon name="matPlus" class="text-white cursor-pointer text-lg z-10"></ng-icon>
          New Post
        </button>
      </div>
    </div>
    <div class="block px-[14px] bg-white sm:bg-[#FAFAFA]">
      <div class="flex justify-end">
        <article class="hidden sm:block side w-[30%]">
          <section class="bg-white rounded-2xl pt-2 mt-4">
            <div>
              <div class="flex justify-between items-center">
                <p class="font-Poppins font-[600] text-[10px] text-[#171725] ">About Group</p>
              </div>
              <hr class="hidden sm:block border-[#EEEEEE] my-2">
              <div class="px-3">
                <p class="text-[#44444F] text-[10px] font-Roboto">
                  {{ data.communityDescription }}
                </p>
              </div>
              <hr class="hidden sm:block border-[#EEEEEE] mt-1">
              <div class="mt-4">
                <p class="font-Poppins font-[600] text-[10px] text-[#171725] ">Group Created by</p>
                <div class="flex gap-[5px] container-avatar my-4">
                  <img alt="" class="w-[50px] h-[50px] rounded-full object-cover"
                       [src]="data.user.image_profile || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'">
                  <div class="flex gap-2 justify-between items-center">
                    <div>
                      <p
                        class="ml-[15px] text-[12px] sm:text-[10px] font-semibold font-Poppins leading-1">{{ data.user.name }} {{ data.user.lastname }}</p>
                    </div>
                    <div class="flex gap-2 mt-[10px] sm:mt-[10px]">
                      <ng-container *ngIf="isFollowing">
                        <p (click)="unfollow()"
                           class="text-[10px] font-Montserrat bg-[#FFFFFF] font-poppins font-medium sm:text-[12px] text-[#519935] bw-1 border-[#81CB52] rounded-2xl flex justify-center items-center w-[75px] h-[24px] cursor-pointer">
                          Following</p>
                        <p (click)="goToMessages()"
                           class="text-[10px] font-Montserrat bg-[#87D255] font-poppins font-medium sm:text-[12px] text-[#FFFFFF] bw-1 border-[#87D255] rounded-2xl flex justify-center items-center w-[75px] h-[24px] cursor-pointer">
                          Message</p>
                      </ng-container>
                      <ng-container *ngIf="!isFollowing">
                        <p (click)="follow()"
                           class="text-[10px] font-Montserrat bg-[#FFFFFF] font-poppins font-medium sm:text-[12px] text-[#519935] bw-1 border-[#81CB52] rounded-2xl flex justify-center items-center w-[75px] h-[24px] cursor-pointer">
                          Follow</p>
                        <p (click)="goToMessages()"
                           class="text-[10px] font-Montserrat bg-[#87D255] font-poppins font-medium sm:text-[12px] text-[#FFFFFF] bw-1 border-[#87D255] rounded-2xl flex justify-center items-center w-[75px] h-[24px] cursor-pointer">
                          Message</p>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-4">
                <p class="font-Poppins font-[600] text-[10px] text-[#171725] ">Group type</p>
                <p class="font-Poppins text-[10px]">{{ data.communityCategory }}</p>
              </div>
              <div class="mt-4">
                <app-members [communityId]="this.data.communityId"
                             [communityMembersCount]="data.communityMembersCount"></app-members>

              </div>
              <div class="mt-4 hidden">
                <div class="flex items-center justify-between">
                  <p class="font-Poppins font-[600] text-[10px] text-[#171725] ">Nearest Events</p>
                  <span class="material-symbols-outlined text-[#a9abad] cursor-pointer"> more_horiz </span>
                </div>
              </div>
              <div class="mt-4">
                <p class="font-Poppins font-[600] text-[10px] text-[#171725] ">Suggested Group</p>
                <app-mini-card-community class="block mt-2" [data]="data"></app-mini-card-community>
              </div>

            </div>
          </section>

        </article>
        <article class="w-full sm:w-[68%] sm:px-4">
          <app-post-community (updateCountPost)="updateCountPost($event)" *ngIf="data.isMember===1" [communityId]="data.communityId" class=""></app-post-community>
          <p *ngIf="data.isMember===0" class="font-Poppins text-[18px] ml-4">Join the community to view the posts.</p>
        </article>
      </div>


    </div>
  </div>
  <app-dialog-alert class="absolute z-[70]"></app-dialog-alert>
</app-sidebar2-layout>
