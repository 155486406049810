import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent {
  @ViewChild('myVideo') myVideo: ElementRef | undefined;
  @Output() openFile = new EventEmitter<any>();
  @Input() videoUrl: any = '';
  @Input() classFilter: any = '';
  @Input() classVideo:any = [];
  controlsVisible: boolean = false;
  icon='play_arrow'
  getClassVideo(){
    return [...this.classVideo,...[this.classFilter]]
  }

  ngAfterViewInit() {
    const video = this.myVideo?.nativeElement;
    video.addEventListener('play', () => {
      this.controlsVisible = true;
      this.icon='pause';
    });
    video.addEventListener('pause', () => {
      this.controlsVisible=false;
      this.icon='play_arrow';
    });
  }
  togglePlay() {
    const video = this.myVideo?.nativeElement;

    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  }
  setControlsVisibility(visible: boolean) {
    this.controlsVisible = visible;
  }
  preventClickPropagation(event: Event) {
    this.openFile.emit()
    event.preventDefault();
  }
}
