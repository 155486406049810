<div #carousel class="carousel-media">
  <div *ngFor="let file of filesString; let i = index" [ngClass]="{ 'active': i === currentIndex }"
       [style.transform]="'translateX(-' + ((currentIndex) * 100) + '%)'">
    <img *ngIf="file.type.includes('image')" src="{{ file.base64 }}" alt="Slide {{ i + 1 }}"
         class="w-full rounded-xl h-[50vh] object-cover {{filtersByImage[currentIndex]}}">
    <video *ngIf="file.type.includes('video')" autoplay controls
           class="w-full rounded-xl h-[50vh] object-cover {{filtersByImage[currentIndex]}}" >
      <source src="{{ file.base64 }}">
    </video>
  </div>
  <div *ngIf="filesString.length > 1" class="indicators w-full">
    <span *ngFor="let image of filesString; let i = index" [class.active]="i === currentIndex"
          (click)="goToSlide(i)"></span>
  </div>
  <div *ngIf="filterMedia"
       class="bg-white/90 rounded-full bw-1 border-[#00000029] p-1.5 shadow-[0px_2px_4px_#00000029] absolute left-4 top-4"
       (click)="deleteImg()">
    <app-close-icon [width]="24" [height]="24"></app-close-icon>
  </div>
  <div *ngIf="filterMedia"
       class="bg-white rounded-full bw-1 border-[#00000029] p-3 shadow-[0px_2px_4px_#00000029] absolute left-4 bottom-4">
    <app-cut-icon [width]="20" [height]="20" color="#519935"></app-cut-icon>
  </div>
  <div *ngIf="filterMedia"
       class="bg-white rounded-full bw-1 border-[#00000029] p-1.5 shadow-[0px_2px_4px_#00000029] absolute right-4 bottom-4"
       (click)="openInputOptions()">
    <app-camera-icon [width]="30" [height]="30"></app-camera-icon>
  </div>
</div>
<p *ngIf="filterMedia" class="font-Montserrat p-4 pb-0 font-semibold text-[14px] text-[#646464]">Filters</p>
<div *ngIf="filesString.length && filterMedia" class="flex flex-nowrap gap-4 p-4 overflow-auto">
  <div *ngFor="let filter of filters" class="w-36 flex-1" (click)="setFilter(filter)">
    <p
      class="font-Montserrat font-semibold text-center text-[12px] text-[#000] w-36">{{ filter.toUpperCase().charAt(0) }}{{ filter.slice(1) }}</p>
    <video *ngIf="files[currentIndex].type.includes('video')"
           class="w-36 h-36 rounded-xl object-cover {{filter}} {{filtersByImage[currentIndex] === filter ?  'border-4 border-green4-native' : ''}}"
           autoplay>
      <source src="{{ filesString[currentIndex].base64 }}">
    </video>
    <img *ngIf="files[currentIndex].type.includes('image')"
         class="w-36 h-36 rounded-xl object-cover {{filter}} {{filtersByImage[currentIndex] === filter ?  'border-4 border-green4-native' : ''}}"
         [src]="filesString[currentIndex].base64" alt="{{filter}}">
  </div>
</div>
<input type="file" accept="image/*,video/mp4, video/x-matroska, video/quicktime" multiple #file (change)="onMediaChange($event)"
       class="opacity-100 w-0 h-0 overflow-hidden">
