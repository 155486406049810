import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-with-images',
  templateUrl: './with-images.component.html',
  styleUrls: ['./with-images.component.scss']
})
export class WithImagesComponent {
  @Input() classContainer: string = '';
  @Input() avatar: string = '';
  @Input() username: string = '';
  @Input() name: string = '';
  @Input() userDescription: string = '';
  @Input() date: string = '';
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() verifiedAccount: boolean = false;
  @Input() medios: IMedio[] = [];
  calculateDateDifference(date: string): string {
    const inputDate = new Date(date)
    const currentDate = new Date();
    const timeDifferenceInMilliseconds = inputDate.getTime() - currentDate.getTime();
    const seconds = Math.floor(Math.abs(timeDifferenceInMilliseconds) / 1000);

    if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      return `${minutes}m`;
    } else if (seconds < 86400) {
      const hours = Math.floor(seconds / 3600);
      return `${hours}h`;
    } else if (seconds < 604800) {
      const days = Math.floor(seconds / 86400);
      return `${days}d`;
    } else {
      const day = inputDate.getDate();
      const month = inputDate.getMonth() + 1; // Month is zero-based, so add 1
      const year = inputDate.getFullYear();
      return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
    }
  }
}
