import { Component,OnInit,HostListener  } from '@angular/core';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit{
  wHeight = 0;
  wWidth = 0;
  isMobile: boolean;
  ngOnInit() {
    if(this.isMobile){
      this.wHeight = window.innerHeight;
    }else{
      if(window.innerWidth>window.innerHeight){
        this.wHeight = (window.innerWidth-((window.innerWidth-window.innerHeight)*1.1));
      }
      else {
        this.wHeight = window.innerHeight;
      }
    }
  }
  openExternalUrl() {
    window.open('https://omnigram.ca/founders-club/', '_blank');
  }

  getProp(prop: string) {
    return getComputedStyle(document.documentElement).getPropertyValue(prop)
  }
  private detectMobile(): boolean {
    const userAgent = window.navigator.userAgent.toLowerCase();
    // Comprueba si la cadena de agente de usuario contiene palabras clave comunes de dispositivos móviles
    return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
  }
  getHeight(arr: {size: number, type: string,isMobile:boolean}[]) {
    let obj: { [klass: string]: any; } = {};
    arr.forEach(x => {
      if(x.isMobile && window.innerWidth<window.innerHeight){
        let nameStyle = `${x.type}.px`;
        obj[nameStyle] = this.wHeight * x.size/100
      }else if(!x.isMobile && window.innerWidth>window.innerHeight){
        let nameStyle = `${x.type}.px`;
        //obj[nameStyle] = (this.wWidth-((this.wWidth-this.wHeight)*1.1)) * x.size/99
        obj[nameStyle] = this.wHeight * x.size/120
      }
    });

    return obj;
  }
  getPropertie(prop: string){
    console.log(document.documentElement.style.getPropertyValue(prop))
    return document.documentElement.style.getPropertyValue(prop);
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    if(this.isMobile){
      this.wHeight = window.innerHeight;
    }else{
      if(window.innerWidth>window.innerHeight){
        this.wHeight = (window.innerWidth-((window.innerWidth-window.innerHeight)*1.1));
      }
      else {
        this.wHeight = window.innerHeight;
      }
    }
  }
  constructor() {
    this.isMobile = this.detectMobile();
  }
}
