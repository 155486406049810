import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {DeviceInfoService} from "../../../../services/device-info/device-info.service";

@Component({
  selector: 'app-card-gallery',
  templateUrl: './card-gallery.component.html',
  styleUrls: ['./card-gallery.component.scss']
})
export class CardGalleryComponent {
  deviceInfoService = inject(DeviceInfoService);
  @Input() data:string[]  = [];
  @Output() seeAllMedia = new EventEmitter<any>();
  seeAll() {
    this.seeAllMedia.emit(true)
  }
  getTypeFile(name: string) {
    return this.deviceInfoService.getTypeFile(name)
  }
}
