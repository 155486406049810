import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-post-categories',
  templateUrl: './post-categories.component.html',
  styleUrls: ['./post-categories.component.scss']
})
export class PostCategoriesComponent implements OnInit {
  @Output() selectedChange = new EventEmitter<string[]>();
  @Input() selected: string[] = [];
  @Input() open: boolean = false;
  @Output() openChange = new EventEmitter<boolean>();
  search: string = '';
  filtered: string[] = [];
  cannabisHashtags = [
    'CannabisCommunity',
    '420Life',
    'Marijuana',
    'CannabisCulture',
    'WeedLovers',
    'HighSociety',
    'StonerLife',
    'THC',
    'CBD',
    'MedicalMarijuana',
    'CannabisMedicine',
    'CannabisPhotography',
    'Weedstagram',
    'CannabisNews',
    'Sativa',
    'Indica',
    'HybridStrain',
    'GrowYourOwn',
    'CannabisCures',
    'HighTimes',
    'CannabisArt',
    'BudtenderLife',
    'CannabisBusiness',
    'SmokeWeedEveryday',
    'GreenThumb',
  ];

  ngOnInit() {
    this.filtered = [...this.cannabisHashtags];
  }
  onOpenChange(newOpen: boolean) {
    this.open = newOpen;
    this.openChange.emit(this.open);
  }
  selectedCategory(category: string) {
    if (this.selected.includes(category)) {
      this.selected = this.selected.filter(item => item !== category);
    } else {
      this.selected.push(category);
    }
    this.selectedChange.emit(this.selected);
  }

  filterCategories(val: string) {
    this.filtered = val ? this.cannabisHashtags.filter(item => item.toLowerCase().includes(val.toLowerCase())) : this.cannabisHashtags;
  }
}
