import {Component, Input} from '@angular/core';
import {UserInfoService} from "../../../services/user-info/user-info.service";

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent {
  imgOpen = 0;
  mine = false;
  @Input() data: any = {
    cdb: 0,
    updateDate: '',
    flavors: [],
    terpenes: [],
    grow_information: '',
    thc: 0,
    name: '',
    effects: [],
    createDate: '',
    genetics: '',
    category: '',
    images: [],
    description: '',
    id: '',
    type: '',
    amount: 0,
    val_reviews: 0,
    count_reviews: 0, pk: ""

  }

  claimGift() {
    const delivery_address = this.userInfo.me().user.delivery_address;
    let array = (delivery_address?.address || '').split(' ');
    let country = array[array.length - 1];
    const address = {
      id: country.toLowerCase() === 'canada' ? ' ' : '',
      address: delivery_address?.address || '',
      lat: delivery_address?.lat || '',
      lng: delivery_address?.lng || '',
      isVerified: country.toLowerCase() === 'canada',
    };
    let data = {
      gifts: [{
        name: this.data.name,
        genetics: this.data.genetics,
        quantity: 1,
        price: this.data.publish_cost,
        total: this.data.publish_cost,
        id: this.data.id,
        images: this.data.images,
        pk: this.data.pk
      }] as IProductCart[],
      user: {
        id: this.userInfo.me().user.id,
        username: this.userInfo.me().user.username,
        name: (this.userInfo.me().user?.name || '') + ' ' + (this.userInfo.me().user?.lastname || ''),
        email: this.userInfo.me().user?.email,
        phoneNumber: (this.userInfo.me().user?.dial_code || '') + (this.userInfo.me().user?.phone || ''),
        avatar: '',
      },
      address: address,
      message: '',
      subtotal: this.data.publish_cost || 0,
      taxFees: 0,
      delivery: 7.5
    }
    localStorage.setItem('claimGift', JSON.stringify(data));
    window.location.replace('/claim-gift');
    //this.router.navigateByUrl('/claim-gift')

  }

  constructor(private userInfo: UserInfoService) {
  }

  ngOnInit() {
    this.mine = this.userInfo.user.user.id === this.data.pk.replace('up#', '')
  }
}
