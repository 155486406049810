import {Component, inject, Input, OnInit} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-story-card',
  templateUrl: './story-card.component.html',
  styleUrls: ['./story-card.component.scss']
})
export class StoryCardComponent implements OnInit {
  private sanitizer  = inject(DomSanitizer)
  @Input() story: any = {};
  @Input() isAdd: boolean = false;
  @Input() image: string = '';
  @Input() avatar: string = '';
  @Input() username: string = '';
  @Input() content: any = '';
  @Input() type: string = '';

  ngOnInit() {
    this.avatar = this.story?.user?.image_profile
    this.type = this.story?.storyType
    //this.content = this.story?.storyContent
    this.image = this.story?.storyUrls?.length ? this.story?.storyUrls[0] : '';
    if(this.image) {
      let contentTemp = this.story?.storyContent;
      contentTemp = contentTemp.replace(/rounded-2xl "/g, "\" style=\"background-image:url('"+this.image+"')\"");
      this.content = this.sanitizer.bypassSecurityTrustHtml(contentTemp);
      console.log(this.content)
    } else {
      this.content = this.story?.storyContent;
    }
    this.username = this.story?.user?.name || this.story?.user?.username
  }

  protected readonly navigator = navigator;
}
