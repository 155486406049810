import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-without-image',
  templateUrl: './without-image.component.html',
  styleUrls: ['./without-image.component.scss']
})
export class WithoutImageComponent {
  @Input() classContainer: string = '';
  @Input() avatar: string = '';
  @Input() date: string = '';
  @Input() username: string = '';
  @Input() name: string = '';
  @Input() description: string = '';
  @Input() userDescription: string = '';
  @Input() verifiedAccount: boolean = false;
  @Input() seeLabelsReactions: boolean = false;
  @Input() numberReactions= {
    numberLikes:0,
    numberComments:0,
    numberShares:0,
    numberSaved:0,
  };
  calculateDateDifference(date: string): string {
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const inputDate = new Date(date)
    const currentDate = new Date();
    const timeDifferenceInMilliseconds = inputDate.getTime() - currentDate.getTime();
    const seconds = Math.floor(Math.abs(timeDifferenceInMilliseconds) / 1000);

    if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      return `${minutes}m`;
    } else if (seconds < 86400) {
      const hours = Math.floor(seconds / 3600);
      return `${hours}h`;
    } else if (seconds < 691200) {
      const days = Math.floor(seconds / 86400);
      return `${days}d`;
    } else {
      const day = inputDate.getDate();
      const month = inputDate.getMonth() + 1; // Month is zero-based, so add 1
      const year = inputDate.getFullYear();
      return `${monthNames[month]} ${day.toString().padStart(2, '0')},${year}`;
    }
  }
}
