import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-history-i-icon',
  templateUrl: './history-i-icon.component.svg',
  styleUrls: ['./history-i-icon.component.scss']
})
export class HistoryIIconComponent {
  @Input() color = '#77838f';
  @Input() width = 24;
  @Input() height = 24;
}
