<app-sidebar-layout [title]="index!=null?options[index].label:''" routeHeader="/coaching"  [useHeaderEdit]="true">
  <div *ngIf="index!=null" class="px-4 sm:px-[70px] w-full">
    <span class=" sm:hidden material-symbols-outlined text-[30px] sm:text-[30px] text-[#707070] mt-4 cursor-pointer" routerLink="/strain-finder">arrow_back</span>
    <div class="flex pt-2 items-center justify-between relative w-full pb-4 gap-4">
      <div class="w-[210px]">
        <app-om-select id="select-nav"
                       containerClass="!bg-white !py-[4.5px] sm:!py-[8px] !font-poppins !font-medium shadow-md !text-lg"
                       containerItemsClass="!max-h-fit"
                       itemsClass="nav-select-item !py-1 hover:!bg-white hover:!text-green4-native"
                       color="gray" [options]="options" [(ngModel)]="selected" required
                       #selectedNav="ngModel" [default]="selected" placeholder="Filter"
                       (ngModelChange)="setFilters()"
        />
      </div>
      <div class="relative w-[calc(100%-200px)] sm:w-[calc(100%-210px)]">
        <app-om-input [useIcon]="true" color="white" [(ngModel)]="search"
                      (ngModelChange)="resetFilter()"
                      [id]="'search-market'"
                      inputClass="rounded-full sm:rounded-md !bg-[#fff] !text-[#77838F] sm:!bg-white !py-[5.5px] sm:!py-[10px] shadow-md !font-poppins !font-medium relative placeholder:!text-lg !text-lg"
                      placeholder="Search">
          <app-search-icon class="hidden sm:inline-block" color="#77838F" [width]="18" [height]="18"/>
          <app-search-icon class="inline-block sm:hidden" color="#519935" [width]="18" [height]="18"/>
        </app-om-input>
      </div>
    </div>
    <app-grower-banner class="block mt-[18px] sm:mt-[20px]" [title]="options[index].label"></app-grower-banner>
    <div class="mt-[20px] sm:mt-[40px] hidden sm:block">
      <p class="text-[20px] sm:text-[25px] font-semibold font-Montserrat mb-[14px]">Categories</p>
      <div class="flex gap-6">
        <div *ngFor="let cat of options"
             (click)="setFilters(cat.value)" class="cursor-pointer w-[205px] h-[100px] border-2 border-[#519935] rounded-xl flex items-end p-2" [class]="{'bg-[#519935]': cat.value===selected}">
          <p class="text-[20px] font-semibold font-Montserrat leading-none" [class]="{'text-white': cat.value===selected}">{{cat.label}}
          </p>
        </div>
      </div>
    </div>
    <app-not-found message="No courses found :(" *ngIf="!couches?.length"></app-not-found>
    <div class="sm:grid sm:grid-cols-3 gap-[20px] mt-[20px] sm:mt-[30px]">
      <ng-container *ngFor="let user of couches let i = index">
        <app-grower-card class="block w-full mb-[25px] sm:mb-4 sm:my-0" [user]="user" ></app-grower-card>
        <app-custom-carousel class="inline-table sm:col-span-3 my-[10px] sm:my-[20px]" *ngIf="i==5"></app-custom-carousel>
        <app-custom-carousel class="inline-table sm:col-span-3 mt-[10px] sm:my-[20px]" *ngIf="i==11"></app-custom-carousel>
      </ng-container>
    </div>
  </div>
</app-sidebar-layout>
