import {Component, ElementRef, EventEmitter, inject, Input, NgZone, Output, ViewChild} from '@angular/core';
import {DialogComponent} from "../../general/dialog/dialog.component";
import {DomSanitizer} from "@angular/platform-browser";
import {DeviceInfoService} from "../../../services/device-info/device-info.service";

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent {
  private sanitizer = inject(DomSanitizer)
  deviceInfo = inject(DeviceInfoService);
  @Output() clickIconLike = new EventEmitter<any>();
  @Output() clickIconSave = new EventEmitter<any>();
  @Output() clickIconShare = new EventEmitter<any>();
  @Output() sendCommentPost = new EventEmitter<any>();
  @Output() moreCommentPost = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();
  @Input() infoPost: any = {};
  @Input() avatar: string = '';
  @Input() isNotification = false;
  @Input() save = false;
  @Input() like = false;
  @ViewChild('dialogComment', {static: false}) dialogComment!: DialogComponent;
  @ViewChild('dialogCommentDesk', {static: false}) dialogCommentDesk!: DialogComponent;
  @ViewChild('emojiTextarea', {static: false}) emojiTextarea!: ElementRef<HTMLTextAreaElement>;
  files: { type: string, src?: string, name?: string }[] = [];
  limit = 1;
  visibleEmojisKey = 0;
  visibleGifKey = 0;
  visibleEmojis = false;
  visibleGif = false;
  isFocusedInput = false;
  limitCommentsV = 2;

  onClickOutside(index: number) {
    if (index != this.visibleEmojisKey) return
    if (this.visibleEmojis) this.visibleEmojis = false;
  }

  addEmoji(emoji: any) {
    const textarea = this.emojiTextarea.nativeElement;
    const emojiText = emoji.emoji.native;
    const cursorPosIni = textarea.selectionStart;
    const cursorPosFin = textarea.selectionEnd;
    const ini = textarea.value.substring(0, cursorPosIni);
    const fin = textarea.value.substring(cursorPosFin);
    textarea.value = ini + emojiText + fin;
    const newPos = cursorPosIni + emojiText.length;
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.ngZone.run(() => {
          textarea.setSelectionRange(newPos, newPos);
          textarea.focus();
        });
      });
    });
    textarea.dispatchEvent(new Event('input'));
  }

  getGif(data: string) {
    this.visibleGif = false;
    if (this.files.length === this.limit) {
      this.removeImage(0);
    }
    this.files.push({type: 'image', src: data});

  }

  onClickOutsideGif(index: number) {
    if (index != this.visibleGifKey) return
    if (this.visibleGif) this.visibleGif = false;
  }

  removeImage(index: number): void {
    this.files.splice(index, 1);
  }

  calculateDateDifference(date: string): string {
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const inputDate = new Date(date)
    const currentDate = new Date();
    const timeDifferenceInMilliseconds = inputDate.getTime() - currentDate.getTime();
    const seconds = Math.floor(Math.abs(timeDifferenceInMilliseconds) / 1000);

    if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      return `${minutes}m`;
    } else if (seconds < 86400) {
      const hours = Math.floor(seconds / 3600);
      return `${hours}h`;
    } else if (seconds < 691200) {
      const days = Math.floor(seconds / 86400);
      return `${days}d`;
    } else {
      const day = inputDate.getDate();
      const month = inputDate.getMonth(); // Month is zero-based, so add 1
      const year = inputDate.getFullYear();
      return `${monthNames[month]} ${day.toString().padStart(2, '0')}, ${year}`;
    }
  }

  convertHashtagsToTags() {
    let hashtags = '';
    (this.infoPost.postHashtack ?? []).forEach((x: string) => {
      hashtags += `<a href="/search-og?hashtag=${x}" class="text-green2-native">#${x}</a> `;
    })
    return this.sanitizer.bypassSecurityTrustHtml(`${this.infoPost.description} ${hashtags}`);
  }

  openForm(enableLimit: boolean = true) {
    this.isFocusedInput=false;
    if ((this.infoPost.comments.length > this.limitCommentsV && enableLimit) || !enableLimit){
      this.dialogComment?.open();
      if (this.deviceInfo.isiOS() || this.deviceInfo.isAndroid())
      this.deviceInfo.fullscreenElement();
    }
  }

  openFormDesk() {
    this.dialogCommentDesk?.open();
  }

  isOpenDialog() {
    if (this.dialogComment) return this.dialogComment.visable
    return false;
  }

  saveComment(data: any) {
    this.sendCommentPost.emit(data)
  }

  endDialog() {
    console.log('get more ccomments')
  }

  closeComentDialog() {
    this.files = [];
    this.close.emit(false);
    this.deviceInfo.exitFullscreenElement();
  }

  buttonScroll() {

    this.dialogComment.scrollToBottom();
  }

  constructor(private ngZone: NgZone) {
  }

  getMoreComments() {
    this.moreCommentPost.emit()
  }

  updateFocusedInput(status: boolean) {
    if (!this.deviceInfo.isiOS() && !this.deviceInfo.isAndroid()) return;
    this.isFocusedInput = status;
    setTimeout(() => {
      this.buttonScroll();
    }, 600);
  }
}
