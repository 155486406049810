
<div class="flex gap-2 sm:gap-4 items-center mb-[20px]">
  <h1 class="text-[20px] sm:text-[25px] font-semibold font-Montserrat">Categories</h1>

</div>
<div class="flex gap-[10px] sm:gap-[15px] w-full ocultarScroll">
  <ng-container *ngFor="let category of categories;">
    <div (click)="changeType(category.name)" class="cursor-pointer w-[150px] sm:w-[350px] h-[100px]  sm:min-w-[266px] rounded-2xl p-2 relative flex items-end justify-start sm:h-[130px]"
    [class]="{
    'border-[5px]': type===category.name,
    'border-[#519935]': type===category.name,
    'hidden': 'all'===category.name
    }">
      <img [src]="category.img" alt="" class="absolute right-0 top-0 w-full h-full  rounded-[10px] object-cover">
      <div class="absolute rounded-[10px] left-0 top-0 w-full h-full bg-gradient-to-t via-transparent from-black to-transparent"></div>
      <p class="w-[120px] sm:w-[250px] z-10 font-Monserrat font-semibold text-[15px] leading-[18px] sm:text-[16px] sm:leading-[20px] text-white">
        {{category.title}}
      </p>
    </div>
  </ng-container>
</div>
