<div class="video-container h-full" >
  <video #myVideo [class]="getClassVideo()" (click)="preventClickPropagation($event)" [controls]="controlsVisible" [src]="videoUrl">
    <source [src]="videoUrl" type="video/mp4">
    Tu navegador no soporta el elemento de video.
  </video>
  <button (click)="togglePlay()" class="hidden bg-black rounded-full justify-center items-center h-[60px] w-[60px]">
    <span class="material-symbols-outlined text-[2.5rem] text-white">
      {{icon}}
    </span>
  </button>
</div>
