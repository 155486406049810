<app-sidebar-layout title="Growers" routeHeader="/strain-finder"  [useHeaderEdit]="true">
  <div class="px-4 sm:px-[70px] w-full">
    <div class="flex pt-2 items-center justify-between relative w-full pb-4 gap-4">
      <div class="w-[180px]">
        <app-om-select id="select-nav"
                       containerClass="!bg-white !py-[4.5px] sm:!py-[8px] !font-poppins !font-medium shadow-md !text-lg"
                       containerItemsClass="!max-h-fit"
                       itemsClass="nav-select-item !py-1 hover:!bg-white hover:!text-green4-native"
                       color="gray" [options]="options" [(ngModel)]="selected" required
                       #selectedNav="ngModel" [default]="selected" placeholder="Filter"
        />
      </div>
      <div class="relative w-[calc(100%-80px)] sm:w-[calc(100%-180px)]">
        <app-om-input [useIcon]="true" color="white" [(ngModel)]="search"
                      [id]="'search-market'"
                      inputClass="rounded-full sm:rounded-md !bg-[#fff] !text-[#77838F] sm:!bg-white !py-[5.5px] sm:!py-[10px] shadow-md !font-poppins !font-medium relative placeholder:!text-lg !text-lg"
                      placeholder="Search">
          <app-search-icon class="hidden sm:inline-block" color="#77838F" [width]="18" [height]="18"/>
          <app-search-icon class="inline-block sm:hidden" color="#519935" [width]="18" [height]="18"/>
        </app-om-input>
        <div class="absolute bg-[#d0d0d0] rounded-full p-1 right-[10px] sm:right-2 top-1 sm:top-2 sm:hidden">
          <div class="relative" #filterList>
            <app-filters-icon [width]="24" [height]="24" (click)="showFilters = !showFilters"></app-filters-icon>
            <div #containerFilterList *ngIf="showFilters" class="absolute bg-white z-20 right-0 bw-1 border-[#d0d0d0] top-[28px] w-[75vw] h-[70vh] py-[14px] px-[20px] rounded-[10px] overflow-auto">
              <app-filters-growers></app-filters-growers>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-grower-banner class="block mt-[18px] sm:mt-[20px]"></app-grower-banner>
    <app-growers-type class="block mt-[20px] sm:mt-[40px]" route="/growers-search"></app-growers-type>
    <app-growers-type class="block mt-[20px] sm:mt-[40px]" title="Featured" route="/growers-search"></app-growers-type>
    <app-custom-carousel class="block mt-[20px] sm:mt-[42px]"></app-custom-carousel>
    <app-growers-type class="block mt-[20px] sm:mt-[20px]" title="Indoor" type="indoor" route="/growers-search"></app-growers-type>
    <app-growers-type class="block mt-[20px] sm:mt-[20px]" title="Outdoor" type="outdoor" route="/growers-search"></app-growers-type>
    <app-growers-type class="block mt-[20px] sm:mt-[20px]" title="Trending Local" type="outdoor" route="/growers-search"></app-growers-type>
    <app-growers-type class="block mt-[20px] sm:mt-[20px]" title="Recently Added" type="outdoor" route="/growers-search"></app-growers-type>
  </div>
</app-sidebar-layout>
