import {Component, effect, ElementRef, HostListener, inject, OnInit, ViewChild} from '@angular/core';
import {DeviceInfoService} from "../../../services/device-info/device-info.service";
import {CatalogsService} from "../../../services/catalogs/catalogs.service";

@Component({
  selector: 'app-category-home',
  templateUrl: './category-home.component.html',
  styleUrls: ['./category-home.component.scss']
})
export class CategoryHomeComponent implements OnInit {
  catalogsService = inject(CatalogsService)
  deviceInfoService = inject(DeviceInfoService)
  @ViewChild('filterList') filterList: ElementRef | undefined;
  @ViewChild('containerFilterList') containerFilterList: ElementRef | undefined;
  showFilters = false
  sharables = [];
  userId = ''
  search = ''
  selected = 'All'
  filterTab = 'sort'
  filterType = 'Relevance'
  filters: { [key: string]: string } = {
    sort: 'relevance',
    category: 'all',
    type: 'all',
  }
  categoriesObjsOrder: any = [];
  categoriesObjs: ICatalogView[] = [];
  effects: ICatalogView[] = [];
  categories: { label: string, value: string }[] = [
    {label: 'All Category', value: 'all'},
  ]
  types: { label: string, value: string }[] = [
    {label: 'All Types', value: 'all'},
    {label: 'Sativa', value: 'sativa'},
    {label: 'Hybrid', value: 'hybrid'},
    {label: 'Indica', value: 'indica'},
    {label: 'CBD', value: 'cbd'},
  ]

  ngOnInit() {
    this.getCategories()
    this.getEffects()
    this.getSharables()
    setTimeout(() => {
      this.deviceInfoService.scrollTop()
    })
  }

  getSharables() {
    this.sharables = [];
  }
  goBack() {
    this.deviceInfoService.goBack();
  }
  getCategories() {
    this.catalogsService.getAll('categories', '').subscribe({
      next: (response: any) => {
        this.categoriesObjs = [...this.categoriesObjs, ...response.records.map((x: any) => {
          return {
            name: x.name.toLowerCase(),
            title: x.name,
            img: x.short_image,
            banner: x.long_image,
            route: '/market-search'
          };
        })]
        this.categories = [...this.categories, ...response.records.map((x: any) => {
          return {
            value: x.name.toLowerCase(),
            label: x.name,
          };
        })]
        this.categoriesObjsOrder = this.getCategoriesOrder();
      }
    })
  }

  getEffects() {
    this.catalogsService.getAll('effects', '').subscribe({
      next: (response: any) => {
        this.effects = [...this.effects, ...response.records.map((x: any) => {
          return {
            name: x.name.toLowerCase(),
            title: x.name,
            img: x.short_image,
            banner: x.long_image,
            route: '/market-search'
          };
        })]
      }
    })
  }

  getCategoriesOrder() {
    return this.categoriesObjs.reduce((result, value, index, array) => {
      if (index % 2 === 0) {
        // @ts-ignore
        result.push(array.slice(index, index + 2));
      }
      return result;
    }, []);

  }

    @HostListener('document:click', ['$event'])
    onClickOutside(event: Event) {
        const targetElement = event.target as HTMLElement;
        if (this.showFilters && this.filterList && !this.filterList.nativeElement.contains(targetElement)) {
            this.showFilters = false;
        }
    }

  protected readonly effect = effect;
}
