import {Component, ElementRef, HostListener, inject, ViewChild} from '@angular/core';
import {FriendsService} from "../../../services/friends/friends.service";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-growers-search',
  templateUrl: './growers-search.component.html',
  styleUrls: ['./growers-search.component.scss']
})
export class GrowersSearchComponent {
  @ViewChild('filterList') filterList: ElementRef | undefined;
  @ViewChild('containerFilterList') containerFilterList: ElementRef | undefined;
  showFilters = false
  users=[];
  userId = ''
  search = ''
  selected = 'Top'
  filterTab = 'sort'
  filterType = 'Relevance'
  filters: { [key: string]: string } = {
    sort: 'relevance',
    category: 'all',
    type: 'all',
  }
  options: { label: string, value: string }[] = [
    {label: 'Top', value: 'Top'},
    {label: 'Indoor', value: 'Indoor'},
    {label: 'Outdoor', value: 'Outdoor'},
    {label: 'Featured', value: 'Featured'},
  ]





  category: { label: string, value: string }[] = [
    {label: 'All Category', value: 'all'},
    {label: 'Flower', value: 'flower'},
    {label: 'CBD', value: 'cdb'},
    {label: 'Edibles', value: 'edibles'},
    {label: 'Extracts', value: 'extracts'},
    {label: 'Clones', value: 'clones'},
    {label: 'Vapes', value: 'vapes'},
    {label: 'Seeds', value: 'seeds'},
  ]
  types: { label: string, value: string }[] = [
    {label: 'All Types', value: 'all'},
    {label: 'Sativa', value: 'sativa'},
    {label: 'Hybrid', value: 'hybrid'},
    {label: 'Indica', value: 'indica'},
    {label: 'CBD', value: 'cbd'},
  ]
  sorts: { label: string, value: string }[] = [
    {label: 'Relevance', value: 'relevance'},
    {label: 'Top Sales', value: 'top-sales'},
    {label: 'Most Recent', value: 'most-recent'},
    {label: 'Price Low to High', value: 'Price-Low-to-High'},
    {label: 'Price High to Low', value: 'Price-High-to-Low'},
  ]
  private http = inject(HttpClient);
  sharables=[]
  async ngOnInit() {
    window.scrollTo(0, 1);
    this.users = await this.friends.getUsers();
    this.users=[...this.users,...this.users,...this.users]
  }
  constructor(private friends: FriendsService) {
  }
  getSharables() {
    this.sharables = [];
    this.http.get('/products/general', {
      params: {...this.filters, search: this.search}
    }).subscribe({
      next: (response: any) => {
        this.sharables = response?.records || response
      }
    });
  }
  setSort(key: string) {
    this.filterTab = key;
  }
  setFilterType(key: string) {
    this.filters[this.filterTab] = key;
    this.getSharables();
  }
  getCatalogFilter() {
    let catalog: typeof this.category = [];
    switch (this.filterTab) {
      case 'sort':
        catalog = this.sorts;
        break;
      case 'category':
        catalog = this.category;
        break;
      case 'type':
        catalog = this.types;
        break;
    }
    return catalog;
  }
  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!(this.filterList as ElementRef).nativeElement.contains(event.target) && this.showFilters) {
      this.showFilters = false;
    }
  }
}
