import {Component, inject, Input, OnInit} from '@angular/core';
import {SearchAllService} from "../../../../services/search-all/search-all.service";
import {ActivatedRoute} from "@angular/router";
import {UserInfoService} from "../../../../services/user-info/user-info.service";

@Component({
  selector: 'app-own-accesories',
  templateUrl: './own-accesories.component.html',
  styleUrls: ['./own-accesories.component.scss']
})
export class OwnAccesoriesComponent implements  OnInit {
  private searchAllService = inject(SearchAllService);
  private route = inject(ActivatedRoute);
  private userInfoService = inject(UserInfoService);
  @Input() userId = ''
  sharables: any[] = []
  username:string = '';
  id:string = '';
  limit = 10;
  offset = 0;
  search = ''
  ngOnInit() {
    window.scrollTo(0, 1);
    this.id = this.route.snapshot.params['username'];
    this.username = this.route.snapshot.params['username'];
    this.getSharables()
  }
  async getSharables() {
    this.sharables = [];
    this.sharables = await this.searchAllService.getProducts({
      limit: this.limit,
      offset: this.offset,
      in: 'og',
      user: this.userId,
      ...(this.search ? {
        name: this.search,
        description: this.search,
      } : {}),
      category: 'accessories',
      isOwn: true
    }).toPromise() as any;
  }

  isMe(){
    return this.userInfoService.isMe(this.id)
  }
}
