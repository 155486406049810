<ng-content select="header"></ng-content>
<div class="flex justify-between">
  <div><ng-content select="header-left"></ng-content></div>
  <div class="hidden sm:flex items-center gap-6 py-2">
    <ng-content select="header-action" ></ng-content>
    <div class="flex items-center justify-center gap-1">
      <span *ngIf="enableArrow" class="material-symbols-outlined p-1 rounded-full text-black bg-[#0000000f] flex items-center justify-center w-[30px] text-[25px] h-[30px] cursor-pointer" (click)="moveLeft()"
            [class]="{
              'cursor-not-allowed':isAtStart,
              'opacity-25':isAtStart,
            }">chevron_left</span>
      <span *ngIf="enableArrow" class="material-symbols-outlined p-1 rounded-full text-black bg-[#0000000f] flex items-center justify-center w-[30px] text-[25px] h-[30px] cursor-pointer" (click)="moveRight()"
            [class]="{
              'cursor-not-allowed':isAtEnd,
              'opacity-25':isAtEnd,
            }">chevron_right</span>
    </div>
  </div>
</div>
<div id="carousell" class="w-full overflow-auto ocultarScrollXNoVisible  scroll-container"
     [class]="{
     'mt-0':isStrainFinder,
     'mt-4':!isStrainFinder,
     }"
     (scroll)="onScroll($event)"
>
  <div id="content" class="container" [class]="styleContainer" style="display: -webkit-inline-box;">
    <ng-content></ng-content>
  </div>
</div>
