import {
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {RegisterPublicationComponent} from "../../../publications/register-publication/register-publication.component";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute} from "@angular/router";
import {UserInfoService} from "../../../../services/user-info/user-info.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-post-community',
  templateUrl: './post-community.component.html',
  styleUrls: ['./post-community.component.scss']
})
export class PostCommunityComponent implements OnInit, OnDestroy{
  @ViewChild(RegisterPublicationComponent, {static: false}) registerPublicationComponent: RegisterPublicationComponent | undefined;
  private http = inject(HttpClient);
  private route = inject(ActivatedRoute);
  private userInfo = inject(UserInfoService);
  posts: any[] = [];
  totalPost: number = 0;
  id: string = '';
  selected = 'All'
  statusFetch = false;
  user: any = {};
  @Input() userId = ''
  @Input() communityId = ''
  @Output() updateCountPost: EventEmitter<number> = new EventEmitter();
  options: { label: string, value: string }[] = [
    {label: 'All', value: 'All'},
    {label: 'Articles', value: 'Articles'},
    {label: 'Posts', value: 'Posts'},
    {label: 'Photos', value: 'Photos'},
    {label: 'Videos', value: 'Videos'},
  ]
  limit = 5;
  offset = 0;
  reset(newId:string){
    this.posts= [];
    this.totalPost= 0;
    this.offset= 0;
    this.communityId= newId;
    this.id= '';
    this.selected = 'All'
    this.statusFetch = false;
    this.user= {};
    this.id = this.route.snapshot.params['username'];
    this.user = this.userInfo.me()?.user || {image_profile: "https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png"};
    this.getData();
  }
  subscriptionDataPost: Subscription | undefined;
  getData() {
    this.statusFetch = true;
    const userId = this.userId;
    let lastPostKey = '';
    if (this.posts.length > 0) {
      lastPostKey = this.posts[this.posts.length - 1].postId
    }
    this.subscriptionDataPost=this.http.get('/posts-filter', {
      params: {
        type: 'community',
        limit: this.limit,
        offset: this.offset,
        userId,
        lastPostKey,
        communityId: this.communityId
      }
    }).subscribe({
      next: (response: any) => {
        try {
          if (response.posts.length === this.limit) {
            this.offset += this.limit
          } else {
            this.offset += response.posts.length;
          }
        } catch (e) {
          console.error(e)
        }
        this.totalPost=response.total;
        this.updateCountPost.emit(response.total);
        this.posts = [...this.posts, ...response.posts];
        this.statusFetch = false;
        this.subscriptionDataPost?.unsubscribe()
      }
    });

  }

  addPost(post: any) {
    post['avatar'] = this.user.image_profile;
    post['name'] = this.user.name + ' ' + this.user.lastname;
    this.posts.unshift(post);
    this.updateCountPost.emit(this.totalPost+1);
  }

  ngOnInit() {
    this.user = this.userInfo.me()?.user || {image_profile: "https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png"};
    this.getData();
  }

  onScroll(event: Event): void {
    this.endScroll();
  }

  @HostListener('window:scroll', ['$event'])
  endScroll(): void {
    if (this.statusFetch) return;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    if (scrollTop + clientHeight >= scrollHeight - 100) {
      this.getData();
    }
  }

  isMe() {
    return this.userInfo.isMe(this.id)
  }

  operNewPost() {
    this.registerPublicationComponent?.openForm()
  }
  ngOnDestroy() {
    this.subscriptionDataPost?.unsubscribe()
  }
}
