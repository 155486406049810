<app-dialog title="" classTitle="font-Montserrat text-[20px] font-semibold my-2" classModal="sm:!min-w-[650px] pt-[var(--sat)]">
  <div class="px-[40px] pb-[25px] h-[85vh] sm:h-auto relative flex flex-col justify-between sm:block" *ngIf="data!=null">
    <div>
      <div>
        <div class="after:mt-4 after:block after:h-1 after:w-full after:rounded-lg after:bg-gray-200 relative">
          <div class="after:mt-4 after:block after:h-1 after:w-full after:rounded-lg after:bg-[#519935] absolute bottom-0"
               [class]="{
                 'w-1/2':tab==='address',
                 'w-full':tab==='payForm',
                 }"></div>
          <ol class="grid grid-cols-3 text-sm font-medium text-gray-500">
            <li class="relative flex justify-start text-[#519935]">
                <span class="absolute -bottom-[1.75rem] start-0 rounded-full bg-[#519935] text-white">
                  <svg (click)="changeTab('userInfo')" class="cursor-pointer h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                       viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"/>
                  </svg>
                </span>
              <span (click)="changeTab('userInfo')" class="cursor-pointer"> Contact Information </span>
              <svg (click)="changeTab('userInfo')" class="cursor-pointer size-6 sm:hidden"
                   xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                   stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2"/>
              </svg>
            </li>

            <li class="relative flex justify-center" [class]="{'text-[#519935]':tab==='address'||tab==='payForm'}">
                <span class="absolute -bottom-[1.75rem] left-1/2 -translate-x-1/2 rounded-full text-white bg-gray-600"
                      [class]="{'!bg-[#519935]':tab==='address'||tab==='payForm'}">
                  <svg (click)="changeTab('address')" class="cursor-pointer h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                       viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"/>
                  </svg>
                </span>
              <span (click)="changeTab('address')" class="cursor-pointer text-center">Delivery Address </span>
              <svg (click)="changeTab('address')" class="cursor-pointer mx-auto size-6 sm:hidden"
                   xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                   stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
              </svg>
            </li>

            <li class="relative flex justify-end" [class]="{'text-[#519935]':tab==='payForm'}">
                <span class="absolute -bottom-[1.75rem] end-0 rounded-full text-white bg-gray-600"
                      [class]="{'!bg-[#519935]':tab==='payForm'}">
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                    />
                  </svg>
                </span>
              <span class=""> Payment </span>
              <svg class="size-6 sm:hidden" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                   stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"/>
              </svg>
            </li>
          </ol>
        </div>
      </div>
      <div class="mt-[30px]">
        <app-personal-detail-send
          *ngIf="tab==='userInfo'"
          [(ngModel)]="userData"
          [disabledInput]="true"
          classField="!mt-0 sm:!mt-0 mb-[15px] sm:mb-[15px]"
          [enableRequiredInput]="enableRequiredInput"
          [enableInfoAddress]="true">
        </app-personal-detail-send>
        <app-delivery-address
          *ngIf="tab==='address'"
          [(ngModel)]="address"
          [classTitle]="'!hidden'"
          [enableRequiredInput]="enableRequiredInput"
          [useAddressVerified]="false"
          [viewEditAddress]="true"
          classField="!mt-0 sm:!mt-0 mb-[15px] sm:mb-[15px]"
          class="block  mx-0"></app-delivery-address>
        <div *ngIf="tab==='payForm'" class="mb-[25px]">
          <div>
            <div class="list-products sm:mt-[47px] mx-4 sm:mx-0">
              <div class="flex items-center justify-between mb-1" *ngFor="let item of data.products">
                <div class="flex gap-2 items-center justify-center">
                  <img class="w-[56px] h-[56px] rounded-2xl  object-contain" [src]="item.productData.image">
                  <div>
                    <p class="sm:text-[18px] sm:text-[#000000] font-Montserrat">{{ item.productData.name }}</p>
                    <p class="sm:text-[14px] sm:text-[#AAAAAA] font-Montserrat">{{ item.productData.type }}</p>
                  </div>
                </div>
              </div>
              <hr class="text-[#DDDDDD] border border-[#DDDDDD] sm:mt-[16px]">
            </div>
          </div>
          <div class="sm:mt-2">
            <app-cost-list [labelSubtotal]="'Sub total'" [subTotal]="0" [shipping]="data.shipping_cost"
                           [total]="data.total" [classFont]="'font-Montserrat'" [currency]="currency"></app-cost-list>
          </div>
        </div>
        <form *ngIf="tab==='payForm'" id="payment-form">
          <div id="payment-element">
            <!--Stripe.js injects the Payment Element-->
          </div>
          <div id="payment-message" class="text-red-600 font-semibold mt-2">
            {{ paymentmessage }}
          </div>
        </form>
      </div>
      <p *ngIf="tab==='address'" class="font-Poppins text-[10px] mt-2">Privacy Notice: The member providing the gift will have access to this address, for privacy purposes we recommend using a <a  class="font-Poppins text-[10px] text-green2-native" href="https://www.canadapost-postescanada.ca/cpc/en/support/kb/receiving/setting-preferences/how-to-use-your-flexdelivery-address">Canada Post Flex Delivery Address</a>
      </p>
    </div>
    <div class=" sm:mt-6">
      <button *ngIf="tab!='payForm'"
              class="text-white bg-[#87D255] font-Montserrat font-bold text-[17px] rounded-2xl h-[50px] flex items-center justify-center w-full"
              (click)="changeTab(tab==='userInfo'?'address':'payForm')">
        Continue
      </button>
      <button *ngIf="tab==='payForm'"
              class="text-white bg-[#87D255] font-Montserrat font-bold text-[17px] rounded-2xl h-[50px] flex items-center justify-center w-full"
              (click)="checkout()">
        Checkout
      </button>
    </div>
  </div>
</app-dialog>
<div *ngIf="loading" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
  <div class="text-white text-center">
    <p class="text-xl font-bold">Saving</p>
  </div>
</div>
