<div class="flex px-4 py-2 border-t-[1px]  border-[#70707080] justify-between">
  <p class="font-Montserrat font-medium sm:font-normal text-[16px] text-[#1E2022]">Post to</p>
</div>
<div class="flex gap-4 items-center justify-between px-4">
  <div class="flex gap-2 items-center justify-between">
    <img
      [src]="user['image_profile'] || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'"
      class="top-5 left-10 w-8 z-20 rounded-full" alt="test">
    <p>{{getName()}}</p>
  </div>

  <label class="switch">
    <input type="checkbox" id="allow_marketing" [(ngModel)]="sendTo[user['id']]" name="sendTo{{user['id']}}" checked (change)="onSendToChange()">
    <span class="sw-slider round"></span>
  </label>
</div>
<div class="flex px-4 py-2 justify-between pt-4">
  <p class="font-Montserrat font-medium sm:font-normal text-[16px] text-[#1E2022]">Post Settings</p>
</div>
<div class="flex gap-4 items-center justify-between px-4 py-1">
  <div class="flex gap-2 items-center justify-between pl-2">
    Public share
  </div>
  <label class="switch">
    <input type="radio" id="public_share" value="public_share" [(ngModel)]="settingsView" name="settingsView" (change)="onSettingsViewChange()">
    <span class="sw-slider round"></span>
  </label>
</div>
<div class="flex gap-4 items-center justify-between px-4 py-1">
  <div class="flex gap-2 items-center justify-between pl-2">
    Only friends
  </div>
  <label class="switch">
    <input type="radio" id="only_friends" value="only_friends" [(ngModel)]="settingsView" name="settingsView" (change)="onSettingsViewChange()">
    <span class="sw-slider round"></span>
  </label>
</div>
<div class="flex gap-4 items-center justify-between px-4 py-1">
  <div class="flex gap-2 items-center justify-between pl-2">
    Private
  </div>
  <label class="switch">
    <input type="radio" id="private" value="private" [(ngModel)]="settingsView" name="settingsView" (change)="onSettingsViewChange()">
    <span class="sw-slider round"></span>
  </label>
</div>
