// tenor.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class TenorService {
  private apiKey = environment.tenor;
  private clientkey = environment.tenorclientkey;
  private apiUrl = 'https://tenor.googleapis.com/v2/search';

  constructor(private http: HttpClient) {}

  searchGifs(query: string): Observable<any> {
    const params = new HttpParams()
      .set('q', query)
      .set('key', this.apiKey)
      .set('clientkey', this.clientkey)
      .set('limit', '120'); // Puedes ajustar la cantidad de GIFs que deseas obtener

    return this.http.get(this.apiUrl, { params });
  }
}
