<app-sidebar-layout title="Effects" routeHeader="/strain-finder" [useHeaderEdit]="true">
  <app-loading *ngIf="isLoading"></app-loading>
  <div class="px-4 w-full" *ngIf="!isLoading">
    <span class=" sm:hidden material-symbols-outlined text-[30px] sm:text-[30px] text-[#707070] mt-4 cursor-pointer"
          routerLink="/strain-finder">arrow_back</span>
    <div class="flex pt-6 items-center justify-between relative w-full gap-2">
      <div class="w-[180px] hidden sm:block">
        <app-om-select id="select-nav"
                       containerClass="!bg-white !py-[4.5px] sm:!py-[12px] !font-poppins !font-medium shadow-md !text-[16px]"
                       containerItemsClass="!max-h-[150px]"
                       itemsClass="nav-select-item !py-1 hover:!bg-white hover:!text-green4-native"
                       color="gray" [options]="categories" [(ngModel)]="filters['category']" required
                       #selectedNav="ngModel" [default]="filters['category']" placeholder="Filter"
        />
      </div>
      <div class="relative w-[calc(100%-20px)] sm:w-[calc(100%-360px)]">
        <app-om-input [useIcon]="true" color="white" [(ngModel)]="search"
                      inputClass="rounded-full placeholder:!text-sm sm:rounded-md !bg-[#fff] text-[#77838F] sm:!bg-white !py-[5.5px] sm:!py-[12px] shadow-md !font-poppins !font-medium relative placeholder:!text-lg !text-[16px]"
                      placeholder="Search">
          <app-search-icon class="hidden sm:inline-block" color="#77838F" [width]="18" [height]="18"/>
          <app-search-icon class="inline-block sm:hidden" color="#519935" [width]="18" [height]="18"/>
        </app-om-input>
        <div class="absolute bg-[#d0d0d0] rounded-full p-1 right-[5px] sm:right-2 top-1 sm:top-2">
          <div class="relative" #filterList>
            <app-filters-icon [width]="24" [height]="24" (click)="showFilters = !showFilters"></app-filters-icon>
            <div #containerFilterList *ngIf="showFilters" class="absolute bg-white rounded-sm z-20 right-0 bw-1 border-[#d0d0d0] top-[28px]">
              <div class="py-2 px-4 border-[#d0d0d0] border-b flex gap-2 sm:gap-4 ">
                <div (click)="setSort('sort')" class="bg-[#fff] flex w-fit rounded-full items-center bw-1 border-[#efefef] gap-2 font-poppins text-[12px] py-1 px-3 sm:px-4"
                     [class]="{'!bg-[#EFEFEF]': filterTab === 'sort'}">
                  Sort
                  <app-chevron-up-icon *ngIf="filterTab === 'sort'" [width]="16" [height]="16"/>
                  <app-chevron-down-icon *ngIf="filterTab !== 'sort'" [width]="16" [height]="16"/>
                </div>
                <div (click)="setSort('category')" class="bg-[#fff] flex w-fit rounded-full items-center bw-1 border-[#efefef] gap-2 font-poppins text-[12px] py-1 px-3 sm:px-4"
                     [class]="{'!bg-[#EFEFEF]': filterTab === 'category'}">
                  Category
                  <app-chevron-up-icon *ngIf="filterTab === 'category'" [width]="16" [height]="16"/>
                  <app-chevron-down-icon *ngIf="filterTab !== 'category'" [width]="16" [height]="16"/>
                </div>
                <div (click)="setSort('type')" class="bg-[#fff] flex w-fit rounded-full items-center bw-1 border-[#efefef] gap-2 font-poppins text-[12px] py-1 px-3 sm:px-4"
                     [class]="{'!bg-[#EFEFEF]': filterTab === 'type'}">
                  Type
                  <app-chevron-up-icon *ngIf="filterTab === 'type'" [width]="16" [height]="16"/>
                  <app-chevron-down-icon *ngIf="filterTab !== 'type'" [width]="16" [height]="16"/>
                </div>
              </div>
              <div class="py-2 px-4 border-[#d0d0d0] border-b flex gap-4 " *ngFor="let item of getCatalogFilter()" (click)="setFilterType(item.value)" >
                <div [class]="{'!text-[#000]': filters[filterTab] === item.value, '!font-medium ': filters[filterTab] === item.value}"
                     class="flex rounded-full items-center justify-between w-full gap-2 font-poppins text-[#75838D] font-normal text-[14px] py-1 px-1">
                  {{ item.label }}
                  <app-right-check-icon *ngIf="filters[filterTab] === item.value" [width]="18" [height]="18" color="#000"/>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="w-fit sm:w-[160px]">
        <button
          class="p-1.5 w-full sm:px-4 sm:!py-[14px] gap-2 flex items-center font-semibold text-[14px] bg-[#519935] text-white rounded-full sm:rounded-md shadow-sm"
          routerLink="/add-product">
          <ng-icon name="matPlus" class="text-white cursor-pointer text-lg z-10"></ng-icon>
          <span class="hidden sm:flex">New Product</span>
        </button>
      </div>
    </div>
    <div class="relative  my-4 sm:mt-[33px] sm:mb-[20px]">
      <div class="w-full rounded-2xl relative flex items-end sm:items-end h-[140px] sm:h-[275px]">
        <img [src]="effects?.[indexEffect]?.banner?? ''" class="h-full w-full rounded-2xl object-cover" alt="">
        <p
          class="absolute z-10 font-Monserrat font-semibold text-[35px] sm:text-[50px] sm:leading-[36px] pl-[15px] pt-[10px] sm:pt-0 sm:pl-[50px] mb-4 sm:mb-[60px] text-white ">
          {{ effects?.[indexEffect]?.title ?? '' }}
        </p>
      </div>
    </div>
    <div class="flex gap-[10px] sm:gap-[35px] ocultarScrollXNoVisible">
      <ng-container *ngFor="let effect of effects;">
        <div routerLink="/effect" [queryParams]="{ type: effect.name }"
             class="cursor-pointer  relative flex items-end justify-start rounded-[12px]  sm:rounded-[22px] border-green-native"
             [class]="{
              'border-4':effects[indexEffect].name==effect.name
             }"
        >
          <div class="relative w-[120px] h-[55px]  sm:w-[224px] sm:h-[84px]">
            <img [src]="effect.banner" class="h-full w-full rounded-lg sm:rounded-2xl object-cover " alt=""
            >
            <div
              class="absolute  rounded-lg sm:rounded-2xl left-0 top-0 w-full h-full bg-gradient-to-t via-transparent from-black to-transparent"></div>
            <p
              class="z-10 absolute flex items-center sm:items-end justify-center sm:justify-normal font-Monserrat font-semibold text-[15px] sm:text-[26px] sm:ml-[15px] top-0 w-full h-full p-[5px] sm:mb-[5px] text-white">
              {{ effect.title }}
            </p>
          </div>
        </div>
      </ng-container>
    </div>

    <app-cards-filter class="block mt-[33px] sm:mt-[33px]" [filters]="{
    category: this.filters['category'], type: this.filters['type'], effect: effects?.[indexEffect]?.name?? 'all', sortKey: 'createDate', limit: 15
    }"></app-cards-filter>
    <app-cards-filter title="Trending" class="block mt-[33px] sm:mt-[49px]" [filters]="{
    category: this.filters['category'], type: this.filters['type'], effect: effects?.[indexEffect]?.name?? 'all', sortKey: 'createDate', limit: 15
    }"></app-cards-filter>
    <app-growers class="block mt-[33px] sm:mt-[33px]" [filters]="{
    category: this.filters['category'], type: this.filters['type'], effect: effects?.[indexEffect]?.name?? 'all'
    }"></app-growers>
    <app-custom-carousel class="block mt-[33px] sm:mt-[33px]"></app-custom-carousel>
    <app-cards-filter class="block pt-[33px]" title="Recently Added" [filters]="{
    category: this.filters['category'], type: this.filters['type'], effect: effects?.[indexEffect]?.name?? 'all', sortKey: 'createDate', sort: 'desc', limit: 15
    }"/>
    <app-cards-filter class="block pt-[33px]" title="High THC" [filters]="{
    category: this.filters['category'], type: this.filters['type'], effect: effects?.[indexEffect]?.name?? 'all', sortKey: 'thc', sort: 'desc', limit: 15
    }"/>
    <app-all-products class="block mt-[33px] mb-4" [filters]="{
    category: this.filters['category'], type: this.filters['type'], effect: effects?.[indexEffect]?.name?? 'all', sortKey: 'createDate', limit: 10
    }"></app-all-products>
  </div>
</app-sidebar-layout>
