<app-carousel class="w-full" [isStrainFinder]="true" [moveAmount]="0.33">
  <header class="flex justify-between sm:justify-normal gap-2 sm:gap-4 items-center mb-[10px] sm:mb-[-10px]">
    <h1 class="text-[20px] sm:text-[25px] font-semibold font-Montserrat" [class]="classTitle">{{title}}</h1>
    <p class="flex items-center text-green2-native mt-[3px] text-[15px] font-medium font-Montserrat" routerLink="/coaching-all">
      <span class="sm:hidden ">View All</span>
      <span class="sm:hidden material-symbols-outlined  items-center justify-center border-green4-native w-[18px] h-[18px] ml-[-2px] text-[18px] sm:text-[16px]  sm:w-auto rounded-full border-0">navigate_next</span>
    </p>
  </header>
  <header-action>
    <span class="block text-[#519935] sm:text-black cursor-pointer font-semibold" routerLink="/coaching-all">See All</span>
  </header-action>
  <app-card-course class="block mb-1 mr-[15px] w-[357px]" *ngFor="let item of [1,2,3,4,5,6,7,8,9,10]"></app-card-course>
</app-carousel>
