import {
  Component,
  inject,
  EventEmitter,
  Output,
  OnInit,
  Input,
  ViewChildren,
  QueryList,
  ElementRef
} from '@angular/core';
import {AuthService} from "../../../services/auth.service";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import Swal from "sweetalert2";
interface youLocate {
  country: string,
  region: string,
  birthday: {
    day: string,
    month: string,
    year: string,
  }
}
@Component({
  selector: 'app-you-locate',
  templateUrl: './you-locate.component.html',
  styleUrls: ['./you-locate.component.scss']
})
export class YouLocateComponent implements OnInit{
  @Output() sendData = new EventEmitter<any>();
  @Output() sendAge = new EventEmitter<any>();
  @ViewChildren('day') elements: QueryList<ElementRef> | undefined;
  @Input() dataDefault:youLocate={
    country: '',
    region: '',
    birthday: {
      year: '',
      month: '',
      day: '',
    }
  }
  @Input() isFounder:boolean = false;
  authService = inject(AuthService);
  years: { value: string, label: string }[] = Array.from({ length: 81 }, (_, index) => (new Date().getFullYear() - index).toString()).map((x: string) => {return {value: x, label: x}});
  router = inject(Router);
  http = inject(HttpClient);
  data: youLocate = {
    country: '',
    region: '',
    birthday: {
      year: '',
      month: '',
      day: '',
    }
  } as youLocate;
  catCountries: ICountry[] = [];
  days: { value: string, label: string }[] = [];
  months: {value: string, label: string}[] = [
    {value: "01", label: "January"},
    {value: "02", label: "February"},
    {value: "03", label: "March"},
    {value: "04", label: "April"},
    {value: "05", label: "May"},
    {value: "06", label: "June"},
    {value: "07", label: "July"},
    {value: "08", label: "August"},
    {value: "09", label: "September"},
    {value: "10", label: "October"},
    {value: "11", label: "November"},
    {value: "12", label: "December"},
  ]
  @Output() previousStep = new EventEmitter<any>();
  previous() {
    this.previousStep.emit();
  }
  async ngOnInit() {
    await this.getContries();
    this.data = this.dataDefault;
  }
  constructor(){
  }
  gettypeOf(a: any) {
    return typeof a;
  }
  onChangeCountry(value: string){
    this.data.region = '';
  }
  getAgeCountry() {
    if (!this.data.country) return 0;
    const country = this.catCountries.find((objeto: ICountry) => objeto.codeCountry === this.data.country);
    if (country) return country.legalAgeCountry;
    return 0;
  }
  getRegions() {
    // @ts-ignore
    if (!this.data.country) return [];
    const country = this.catCountries.find((objeto: ICountry) => objeto.codeCountry === this.data.country);
    if (country) return country.regions;
    return [];
  }
  async getContries() {
    this.http.get('/common/countries').subscribe({
      next: (response: any) => {
          this.catCountries = (response as ICountry[]).sort((a, b) => a.nameCountry.localeCompare(b.nameCountry));
          if(this.isFounder) this.catCountries=[this.catCountries[0]];
        this.catCountries.forEach((cc, index) => {
          this.http.get(`/common/regions/${cc.pk}`).subscribe({
            next: (response: any) => {
              this.catCountries[index].regions = response;
            }
          });
        });
        console.log(response);
      }
    });
  }
  getAge() {
    const birthDate = new Date(`${this.data.birthday.year}-${this.data.birthday.month}-${this.data.birthday.day}`);
    const currentDate = new Date();
    return currentDate.getFullYear() - birthDate.getFullYear() - (
        (currentDate.getMonth() < birthDate.getMonth() ||
            (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())) ? 1 : 0
    );
  }
  getDaysInMonth(type:string,value:any) {
    if(type=='month') this.data.birthday.month=value
    else this.data.birthday.year=value
    const { birthday } = this.data;
    if (!birthday.year || !birthday.month) this.days = [];
    let year = parseInt(birthday.year, 10);
    let month = parseInt(birthday.month, 10);
    if (month < 1 || month > 12) this.days = [];
    const lastDateOfMonth = new Date(year, month, 0);
    const numberOfDays = lastDateOfMonth.getDate();
    this.days = Array.from({ length: numberOfDays }, (_, index) => (index + 1 < 10 ? '0' : '') + (index + 1).toString()).map((x: string) => {return {value: x, label: x}});

  }
  markElementsAsTouched() {
    if(this.elements)
      this.elements.forEach((element: any) => {
        console.log(element)
        if(element.control)
          element.control.touched = true;

      });
  }
  sendDataC() {
    this.markElementsAsTouched()
    if(this.getAgeCountry()>this.getAge()){
      Swal.fire({
        icon: 'error',
        title: 'You must be at least '+this.getAgeCountry()+' years old',
        text: '',
        customClass: {
          container: 'dark-swal',
          title: 'swal2-title-error'
        },
        confirmButtonColor: '#373737'
      })
      return;
    }
    if(this.data.birthday.day!=''){
      this.sendData.emit(this.data);
      this.sendAge.emit(this.getAgeCountry())
      this.sendAge.emit(this.getAgeCountry())
    }
  }
}
