<div class="bg-[#519935] flex items-center p-2.5 px-2.5 rounded-lg relative cursor-pointer" (click)="openNotifications()">
  <div *ngIf="notificationCount" class="absolute w-5 h-5 p-1 bg-[tomato] flex items-center justify-center rounded-full -top-1 -right-1 text-white font-Montserrat  text-xs">{{notificationCount}}</div>
  <ng-icon name="matNotificationsNone" class="text-[24px] text-white"></ng-icon>
  <div class="w-72 bg-white absolute top-12 -translate-x-1/2 max-h-52 ocultarScroll rounded-lg shadow-md divide-y divide-gray-200 overflow-auto"
    [class]="{'hidden': !showNotifications}">
    <div *ngFor="let not of notificationsList()" class="px-4 py-3 flex items-center" (click)="goToAction(not)">
      <img class="h-8 w-8 rounded-full" [src]="not['userFrom'].image_profile" alt="Avatar">
      <p class="ml-4 text-sm text-gray-700">{{not['content'].message}}</p>
      <div><div *ngIf="!not['read']" class="h-3 w-3 bg-green4-native rounded-full"></div></div>
    </div>
  </div>
</div>
