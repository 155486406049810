import {Component, EventEmitter, inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DialogComponent} from '../../general/dialog/dialog.component';
import {ActivatedRoute, Router} from "@angular/router";
import {UserInfoService} from "../../../services/user-info/user-info.service";
import {DomSanitizer} from "@angular/platform-browser";
import {GiftService} from "../../../services/gift/gift.service";
import {Subscription} from "rxjs";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-card-mini',
  templateUrl: './card-mini.component.html',
  styleUrls: ['./card-mini.component.scss']
})
export class CardMiniComponent implements OnInit {
  sanitizer = inject(DomSanitizer);
  giftService = inject(GiftService);
  http = inject(HttpClient);
  @ViewChild(DialogComponent, {static: false}) dialogComponent: DialogComponent | undefined;
  router = inject(Router);
  @Output() onBotonClick = new EventEmitter<void>();
  @Input() detailVisable = false;
  @Input() btndetailVisable = true;
  @Input() useTemplateFooter = false;
  @Input() enableFooter = true;
  @Input() isStrainFinder = false;
  @Input() isInfo = false;
  @Input() enableStarRating = true;
  @Input() enableLike = true;
  @Input() enableColumRight = false;
  @Input() enableType = true;
  @Input() enableThcCdb = true;
  @Input() mine = true;
  @Input() data = {
    cdb: 0,
    updateDate: '',
    flavors: [],
    terpenes: [],
    grow_information: '',
    thc: 0,
    name: '',
    effects: [],
    createDate: '',
    genetics: '',
    category: '',
    images: [],
    description: '',
    id: '',
    type: '',
    amount: 0,
    val_reviews: 0,
    val_ranked: 0,
    count_reviews: 0,
    publish_cost: 0,
    pk: "",
    like: false
  }
  @Output() selectGift = new EventEmitter<string>();


  ngOnInit() {
    this.enableThcCdb = !!this.data.thc || !!this.data.cdb;
  }
  subscriptionUpdateLike: Subscription | undefined
  updateLike() {
    this.subscriptionUpdateLike = this.http.post('/like', {
      'idproduct': this.data.id,
      'idcomment': '',
      'iduserPost': '',
      'val': (!this.data.like ? '1' : '0')
    }).subscribe({
      next: (response: any) => {
        const {success} = response
        if (!success) {
          alert("Error")
        }else{
          this.data.like = !this.data.like;
        }
      }
    });
  }

  getDescription() {
    return this.sanitizer.bypassSecurityTrustHtml(this.data.description)
  }

  protected readonly Input = Input;

  closeDialog(data: any) {
    this.detailVisable = false
    this.selectGift.emit('');
  }

  openDialog() {
    this.detailVisable = true;
    this.selectGift.emit(this.data.id);
  }

  pressCloseButton() {
    this.dialogComponent?.closeB()
  }

  claimGift() {
    this.giftService.claimGift(this.data)
  }

  constructor(private userInfo: UserInfoService) {
  }

}
