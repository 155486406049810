<!--Starts mobile navbar-->
<div class="">
  <app-home-received-gift></app-home-received-gift>
  <app-pay-items></app-pay-items>
  <!--  <img src="assets/icons-sax/menu.svg"  class="w-8" alt="menu">-->
  <div
    class="flex sm:hidden items-stretch justify-between bg-white pt-[calc(var(--sat)+12px)] py-4 px-4"
    [class]="{
      'hidden': ['/add-product'].includes(router.url) || router.url.includes('/profile'),
      '!flex': router.url.includes('settings/profile') && deviceInfo.detectMobile(),
    }">
    <app-hamburger-menu (touchend)="toggle()" class="mt-1"/>
    <div class="w-full pl-3 pb-1 z-10 flex items-center" *ngIf="filterPost">
      <app-om-select id="select-nav-mobile"
                     containerClass="!py-1.5 !text-[calc(var(--mw-height)*1.7)]"
                     containerItemsClass="!max-h-fit"
                     itemsClass="nav-select-item !text-[calc(var(--mw-height)*1.7)] !py-1 hover:!bg-white hover:!text-green4-native"
                     iconClass="text-[calc(var(--mw-height)*2.5)]" (ngModelChange)="updateType($event)"
                     color="gray" [options]="options" [(ngModel)]="typePost" required
                     #selectedNav="ngModel" [default]="typePost" placeholder="Filter"
      />
    </div>
    <div class="w-full absolute left-0 flex items-center justify-center h-[30px] ">
      <img src="assets/logo_black.png" alt="logo" class="w-14 absolute ml-4 mb-[5px]" routerLink="/home">
    </div>
    <div #rightOptionsMobile class="flex items-center justify-center gap-2">
      <ng-content select="right-options-mobile"></ng-content>
    </div>
    <div *ngIf="rightOptionsMobile.childNodes.length === 0" class="flex items-center justify-center gap-2 z-30">
      <div class="bw-1 p-0.5 border-black rounded-full">
        <app-bell-icon color="#000" [width]="20"/>
      </div>
      <div class="bw-1 p-0.5 border-black rounded-full" routerLink="/messages">
        <app-envelop-icon color="#000" [width]="20"/>
      </div>
    </div>
  </div>
  <div *ngIf="bottomNavigation" class="fixed sm:hidden bottom-0 left-0 z-30 w-full flex items-center justify-center"
       [class]="{
        'hidden': ['/add-product'].includes(router.url),
      }">
    <div class="shadow-lg border-t-[1px] flex items-center justify-between bg-white w-full px-8 py-4">
      <img src="assets/icons-sax/home.svg" (touchstart)="goToAny('/home')">
      <app-og-marketplace [width]="24" color="#77838f" (touchstart)="goToAny('/strain-finder')"/>
      <div class="bg-[#87D255]/30 rounded-full p-2" (click)="openFormPost()">
        <img src="assets/icons-sax/add.svg">
      </div>
      <app-og-coaching [width]="30" color="#77838f" (touchstart)="goToAny('/coaching')"/>
      <img src="assets/icons-sax/user.svg" (touchstart)="goToAny('/profile/'+user?.id)" alt="profile">
    </div>
  </div>
</div>
<!--End mobile navbar-->
<!--Starts desktop navbar-->
<div class="hidden sm:flex items-stretch justify-between bg-white">
  <div class="flex items-center px-6 w-[250px]">
    <img src="assets/logo_black.png" alt="logo" class="w-20 mx-4 my-3 ml-12" routerLink="/home">
  </div>
  <div *ngIf="useHeaderEdit " class="hidden sm:flex items-center sm:w-[315px] px-[0px] pb-[5px] mr-auto">
    <span *ngIf="routeHeader!=''" class="material-symbols-outlined text-[30px] sm:text-[30px] text-[#707070] cursor-pointer mr-[10px]" (touchend)="goTo()" (click)="goTo()">arrow_back</span>
    <ng-content select="router-header" >
    </ng-content>
    <ng-content select="navbar-title" >
    </ng-content>
  </div>
  <div class="flex items-center gap-6 w-[60%]" *ngIf="filterPost">
    <div class="w-[130px]">
      <app-om-select id="select-nav"
                     containerClass="!py-[6.5px] !font-poppins !font-medium"
                     containerItemsClass="!max-h-fit"
                     itemsClass="nav-select-item !py-1 hover:!bg-white hover:!text-green4-native"
                     (ngModelChange)="updateType($event)"
                     color="gray" [options]="options" [(ngModel)]="typePost" required
                     #selectedNav="ngModel" [default]="typePost" placeholder="Filter"
      />
    </div>
    <app-om-input *ngIf="filterSearch" [useIcon]="true" color="gray"
                  inputClass="!py-[10.5px] !font-poppins !font-medium relative"
                  [(ngModel)]="search" (keyEnter)="goToSearch($event)"
                  placeholder="Search" class="w-[60%]">
      <app-search-icon color="#77838F" [width]="18" [height]="18"/>
    </app-om-input>
  </div>
  <div #rightOptions id="rightOptions" class="flex items-center px-8 gap-4">
    <ng-content select="[right-options]"></ng-content>
  </div>
  <div *ngIf="rightOptions.childNodes.length === 0" class="flex items-center px-8 gap-4">
    <app-notification-center (openPublicationM)="openPublication($event)" (openCommunityRequest)="openRequestC($event)"></app-notification-center>
    <img routerLink="/profile/{{user?.id}}" class="inline-block h-12 w-12 border-2 border-green3-native rounded-full"
         [src]="user.image_profile || 'https://tmpbucket11.s3.us-east-2.amazonaws.com/user/01HC4BMMTJP695T5D8ZC08RZ0R/882imag.png'" alt="Profile Image">
  </div>
</div>
<app-publication [isNotification]="true"></app-publication>
<app-product-dialog></app-product-dialog>
<app-community-request></app-community-request>
<!--End desktop navbar-->
